import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function GuidedTour(props: any) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 132 135.94'
            {...props}
        >
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M74.1 129.53c0 4.43-1.96 6.39-6.37 6.4-1.42 0-2.83.02-4.25 0-3.02-.07-5.23-2.3-5.3-5.33-.01-.4 0-.8 0-1.19 0-40.97.02-81.94-.03-122.91 0-3.25 1.17-5.43 4.28-6.49h7.43c3.11 1.05 4.29 3.23 4.28 6.49-.05 41.02-.03 82.03-.03 123.04zM130.12 72.16c-3.14 3.24-6.32 6.44-9.47 9.67-1.07 1.09-2.33 1.78-3.87 1.78-11.5.02-23 0-34.66 0V51.76h1.4c10.79 0 21.59.02 32.38-.02 2.05 0 3.65.7 5.02 2.22 3.07 3.43 6.22 6.78 9.31 10.19 2.38 2.61 2.34 5.46-.12 8zM49.84 19.95v31.82h-7.38c-8.76 0-17.52-.03-26.28.02-2.1.01-3.74-.69-5.14-2.25-3.07-3.43-6.21-6.79-9.31-10.19-2.34-2.57-2.31-5.43.08-7.9 3.18-3.27 6.38-6.51 9.56-9.77 1.14-1.17 2.5-1.77 4.14-1.77h33.58c.22 0 .43.02.74.04z'
                    strokeWidth={0}
                />
            </g>
        </svg>
    );
}

export default GuidedTour;
