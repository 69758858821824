import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

export default class Group extends JTOObject {
    public static _TYPE: string = 'Group';

    public name: JTOProperty<string>;
    public color: JTOProperty<string>;

    constructor(parent?: JTOElement | null) {
        super(Group._TYPE, parent);

        this.name = new JTOProperty<string>('name', this);
        this.color = new JTOProperty<string>('color', this);
    }
}
