import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';
import FileSuper from './FileSuper';

export default class File extends FileSuper {
    public static _TYPE: string = 'File';
    public link: JTOProperty<string>;
    public position: JTOProperty<number>;
    // @ts-ignore

    constructor(parent?: JTOElement | null) {
        super(File._TYPE, parent);

        this.type = new JTOProperty('type', this);
        this.link = new JTOProperty('link', this);
        this.position = new JTOProperty('position', this);
    }
}
