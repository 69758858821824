import {
    View,
    Text,
    Pressable,
    ActivityIndicator,
    Image,
    Platform,
    Alert,
    PermissionsAndroid,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_REGULAR, getColor } from '../utils/theme';
import {
    fileToBase64,
    getOrientationFromUri,
    goBackTo,
    height,
    resizeAndFixRotation,
    width,
} from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import { MpInput } from '../base/MpInput';
import { useState } from 'react';
import User from '../object/User';
import {
    CameraIcon,
    DocumentIcon,
    PhotoIcon,
    TrashIcon,
    UserIcon,
    XMarkIcon,
} from 'react-native-heroicons/solid';
import { post } from '../utils/http';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import DocumentPicker from 'react-native-document-picker';
//import RNFS from 'react-native-fs';
import { ProfilFileElementView } from '../component/ProfilFileElementView';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import { PastilleView } from '../component/PastilleView';
//import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import { isImage } from '../component/FileElementView';
import { applyResult } from '../utils/applyResult';
import { PostFileModal } from '../component/modal/PostFileModal';

export const ProfilVisitCardModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const [alias, setAlias] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [visitCardProfil, setVisitCardProfil] = useState<any>(undefined);
    const [openModal, setOpenModal] = useState(false);

    const uploadFile = async () => {
        if (visitCardProfil) {
            setLoading(true);
            let file_data = await fileToBase64(visitCardProfil);
            post('/mobile/visitCard/upload', {
                type: visitCardProfil.type,
                data: file_data,
            })
                .then((res) => {
                    setLoading(false);
                    if (HTTPUtils.isSuccess(res)) {
                        applyResult(
                            HTTPUtils.getResult(res),
                            User._instance,
                            true,
                        );
                        goBackTo(
                            props.navigation,
                            'Tab',
                            'ProfilVisitCardModal',
                        );
                        ToastManager.instance.addToast(
                            new Toast(
                                t('profile_edit_success'),
                                5000,
                                'success',
                            ),
                        );
                    } else {
                        setError(t(HTTPUtils.getError(res)) ?? '');
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setError(t('went_wrong_no_internet') ?? '');
                });
        } else {
            setError(t('select_file') ?? '');
        }
    };

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('profile_visit_card')}
                line={1}
                backButton={true}
                navigation={props.navigation}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                rounded={(User._instance.avatar.get() ?? '') !== ''}
                logo={User._instance.avatar.get() ?? ''}
                fallback={
                    <UserIcon
                        color={'white'}
                        size={35}
                    />
                }
            >
                <View
                    style={{
                        marginHorizontal: 20,
                    }}
                >
                    {loading ? (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    height: height() * 0.5,
                                },
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View>
                            <Text
                                style={{
                                    marginTop: 15,
                                    color: getColor('text'),
                                    fontFamily: FONT_REGULAR,
                                }}
                            >
                                {t('visit_card_profil_explain')}
                            </Text>
                            {visitCardProfil ? (
                                <View
                                    style={{
                                        position: 'relative',
                                        marginTop: 15,
                                        width: '100%',
                                        height: width() * 0.5,
                                        marginBottom: 15,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Image
                                        source={{
                                            uri: URL.createObjectURL(
                                                visitCardProfil,
                                            ),
                                        }}
                                        style={{
                                            width: '100%',
                                            height: width() * 0.5,
                                            borderRadius: 15,
                                            borderColor: getColor('border'),
                                            borderWidth: 1,
                                            position: 'absolute',
                                        }}
                                    />

                                    {!isImage(
                                        URL.createObjectURL(visitCardProfil),
                                    ) && (
                                        <View
                                            style={{
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Text>{t('cannot_preview')}</Text>
                                        </View>
                                    )}

                                    <View
                                        style={{
                                            position: 'absolute',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '95%',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Pressable
                                            style={{
                                                bottom: 8,
                                            }}
                                            onPress={async () => {
                                                setVisitCardProfil(undefined);
                                            }}
                                        >
                                            <PastilleView
                                                color={getColor('error')}
                                                size={45}
                                                icon={
                                                    <TrashIcon
                                                        size={30}
                                                        color='white'
                                                    />
                                                }
                                            />
                                        </Pressable>
                                    </View>
                                </View>
                            ) : (
                                <View
                                    style={{
                                        position: 'relative',
                                        marginTop: 15,
                                        width: '100%',
                                        borderRadius: 15,
                                        borderColor: getColor('border'),
                                        borderWidth: 1,
                                        height: width() * 0.5,
                                        marginBottom: 15,
                                        backgroundColor: getColor('card'),
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: getColor('hint'),
                                            padding: 10,
                                        }}
                                    >
                                        {t('select_profile_visit_card')}
                                    </Text>
                                </View>
                            )}

                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Pressable
                                    onPress={async () => {
                                        setOpenModal(true);
                                    }}
                                    style={{
                                        marginRight: 12,
                                    }}
                                >
                                    <PastilleView
                                        size={40}
                                        color={getColor('secondary')}
                                        icon={
                                            <DocumentIcon
                                                size={25}
                                                color={'white'}
                                            />
                                        }
                                    />
                                </Pressable>
                            </View>

                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.textError,
                                        {
                                            marginTop: 10,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}

                            <View
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <CartoucheButton
                                    onPress={() => {
                                        uploadFile();
                                    }}
                                    title={t('add')}
                                    height={80}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </Header>
            <PostFileModal
                open={openModal}
                onClose={(files) => {
                    setOpenModal(false);
                    setVisitCardProfil(files[0]);
                    setAlias(files[0].name);
                }}
                single={true}
            />
        </View>
    );
};
