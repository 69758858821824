import { t } from 'i18next';

import JTOObject from '../../../utils/jto/JTOObject';
import JTOSimple from '../../../utils/jto/JTOSimple';
import JTOProperty from '../../../utils/jto/JTOProperty';
import JTORelProperty from '../../../utils/jto/JTORelProperty';
import JTOList from '../../../utils/jto/JTOList';
import JTOElement from '../../../utils/jto/JTOElement';
import JTOListFetch from '../../JTOListFetch';
import {
    formatCompressRemainTime,
    formatRemainTime,
} from '../../../utils/utils';
import ChatGroup from './ChatGroupTiny';
import FileMessage from '../../FileMessage';
import User from '../../User';
import UserTiny from '../global/UserTiny';
import ChatGroupTiny from './ChatGroupTiny';

export class MessageTiny extends JTOObject {
    public content: JTOProperty<string>;
    public date: JTOProperty<string>;

    public userSend: JTOSimple<UserTiny>;
    public receiver: JTOSimple<UserTiny | ChatGroupTiny>;

    constructor(parent?: JTOElement | null) {
        super('Message', parent);

        this.content = new JTOProperty('content', this);
        this.date = new JTOProperty('date', this);

        this.userSend = new JTOSimple(UserTiny, ['send'], this);
        this.receiver = new JTOSimple<UserTiny | ChatGroupTiny>(
            [ChatGroupTiny, UserTiny],
            ['receive'],
            this,
        );
    }

    public isSending(): boolean {
        return this.getJTOIdentifier() === undefined;
    }

    public displayMessageDate(translate: any): string {
        return formatCompressRemainTime(this.date.get() ?? '', translate);
    }

    public getReceiver(): UserTiny | ChatGroupTiny {
        return this.receiver.get() ?? new UserTiny();
    }

    public isMe(): boolean {
        return (
            (this.getJTOParent()?.getJTOParent()?.getJTOType() === 'User' &&
                this.getReceiver().getJTOIdentifier() !==
                    User._instance.getJTOIdentifier()) ||
            (this.getJTOParent()?.getJTOParent()?.getJTOType() ===
                'ChatGroup' &&
                this.userSend.get()?.getJTOIdentifier() ===
                    User._instance.getJTOIdentifier())
        );
    }

    public getHim(): UserTiny | ChatGroupTiny {
        let user: UserTiny | ChatGroupTiny = this.getSender();
        if (!this.isMe()) {
            user = this.getReceiver();
        }
        return user;
    }

    public getSender(): UserTiny {
        return this.userSend.get() ?? new UserTiny();
    }

    public getContent(
        func: (key: string) => string | undefined,
    ): string | undefined {
        if (this.content.get() === '') {
            return func('share_a_file');
        }
        return this.content.get();
    }
}
