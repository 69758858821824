import { View, Text, Pressable, ActivityIndicator } from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_REGULAR, getColor } from '../utils/theme';
import { goBackTo, height, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import { useState } from 'react';
import User from '../object/User';
import { XMarkIcon } from 'react-native-heroicons/solid';
import { post } from '../utils/http';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
//import Keychain from 'react-native-keychain';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import { CommonActions } from '@react-navigation/native';
//import { storage } from '../../App';
import UserConnect from '../object/compressed/chat/UserConnect';
import UserChat from '../object/compressed/chat/UserChat';
import AsyncStorage from '@react-native-async-storage/async-storage';
import JTOInstance from '../utils/jto/JTOInstance';

export const DeleteUserModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const deleteMyself = () => {
        if (!loading) {
            setLoading(true);
            setError('');
            post('/user/deletemyself', {})
                .then((res) => {
                    setLoading(false);
                    if (HTTPUtils.isSuccess(res)) {
                        JTOInstance.applyResult(HTTPUtils.getResult(res), true);
                        ToastManager.instance.addToast(
                            new Toast(
                                t('delete_account_success'),
                                5000,
                                'success',
                            ),
                        );
                        /*Keychain.setInternetCredentials(
                            'happlyevent.access_token',
                            'happlyevent',
                            'del',
                        );
                        Keychain.setInternetCredentials(
                            'happlyevent.refresh_token',
                            'happlyevent',
                            'del',
                        );*/
                        //
                        //storage.setMap('user', {});
                        User._instance = new User();
                        User._instanceChat = new UserChat();
                        User._instanceConnect = new UserConnect();
                        AsyncStorage.removeItem('event');

                        props.navigation.dispatch(
                            CommonActions.reset({
                                index: 0,
                                routes: [{ name: 'BeforeLogin' }],
                            }),
                        );
                    } else {
                        setError(t(HTTPUtils.getError(res)) ?? '');
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setError(t('went_wrong_no_internet') ?? '');
                });
        }
    };

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('delete_account')}
                line={1}
                backButton={true}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: 15,
                    }}
                >
                    {loading ? (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    height: height() * 0.5,
                                },
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View
                            style={{
                                paddingHorizontal: 20,
                            }}
                        >
                            <Text
                                style={{
                                    paddingVertical: 30,
                                    textAlign: 'center',
                                    fontFamily: FONT_REGULAR,
                                }}
                            >
                                {t('delete_account_confirm')}
                            </Text>
                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.textError,
                                        {
                                            marginTop: 10,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}

                            <View
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <CartoucheButton
                                    onPress={() => {
                                        deleteMyself();
                                    }}
                                    color={getColor('error')}
                                    title={t('delete_account')}
                                    height={80}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
