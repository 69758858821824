import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function Show(props: any) {
    return (
        <svg
            viewBox='0 0 142.96 142.45'
            {...props}
        >
            <path
                fill='currentColor'
                d='M173.79.05c-.46-.04-.92-.02-1.38-.02-55.79,0-111.57,0-167.36-.03C2.74,0,1,.64,0,2.8v1.73c1.32,3.51,4.44,2.81,7.37,2.88v2.38c0,31.29,0,62.59,0,93.88,0,.75-.03,1.51.09,2.25.47,3.05,3.46,4.3,5.99,2.44,2.43-1.79,4.86-3.59,7.88-4.16,2.03-.38,4.1-.58,6.17-.65,3.17-.11,6.35-.02,9.52-.03,4.17-.02,5.61-2.05,4.27-6-.13-.37-.2-.77-.33-1.3h95.58c-.21.69-.42,1.32-.6,1.97-.91,3.13.63,5.25,3.92,5.32,3.06.06,6.12,0,9.18.02,1.38,0,2.77.01,4.15.12,3.18.25,6.05,1.37,8.66,3.17.9.62,1.74,1.35,2.69,1.9,2.14,1.23,4.76.1,5.31-2.29.19-.83.2-1.72.2-2.58,0-31.35,0-62.7,0-94.05v-2.42c1.35,0,2.49.06,3.63,0,2.17-.13,3.68-1.62,3.71-3.59.04-1.94-1.47-3.5-3.61-3.72ZM142.29,80.77c-.88,2-1.78,4.01-2.46,6.09-.46,1.43-1.12,1.9-2.67,1.9-23.39-.06-46.77-.04-70.16-.04-9.01,0-18.02-.03-27.02.03-1.24,0-1.76-.39-2.23-1.53-1.97-4.73-4.07-9.41-6.15-14.1-.34-.77-.69-1.33.28-2.03,6.07-4.33,10.43-10.25,14.64-16.25,6.48-9.22,11.88-19.09,16.92-29.15.28-.56.59-1.1.88-1.63,16.39,7.42,32.53,7.4,48.76.02,9.19,17.17,17.76,34.83,33.2,47.93-1.28,2.81-2.67,5.77-3.98,8.76Z'
            />
        </svg>
    );
}

export default Show;
