import JTOElement from './JTOElement';

export default class JTOContainer {
    public id: string;
    public elem: JTOElement;
    public func: () => void;
    constructor(id: string, elem: JTOElement, func: () => void) {
        this.elem = elem;
        this.id = id;
        this.func = func;
    }
}
