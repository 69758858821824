import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import { post } from '../utils/http';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActivityIndicator,
    Pressable,
    Text,
    TextInput,
    View,
} from 'react-native';
import React from 'react';
import {
    CheckCircleIcon,
    EyeIcon,
    EyeSlashIcon,
} from 'react-native-heroicons/solid';
import { MpInput } from '../base/MpInput';
import { GeSpinner } from '../component/GeSpinner';
import User from '../object/User';
import { styles } from '../utils/styles';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { IModalProps } from './IModalProps';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
//import { ScrollView } from 'react-native-gesture-handler';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { applyResult } from '../utils/applyResult';

export const PasswordModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const isChangePassword = User._instance!.passwordInit.get() === true;

    const [success, setSuccess] = useState(false);

    const [password, setPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const scrollRef = useRef<any>(null);

    if (success) {
        return (
            <View style={styles.page}>
                <Header
                    modal={true}
                    title={t('password')}
                    line={1}
                    after={
                        <View
                            style={{
                                height: 10,
                            }}
                        ></View>
                    }
                    backButton={true}
                    navigation={props.navigation}
                    logo={User._instance.getEvent()?.logo.get() ?? ''}
                >
                    <View
                        style={[
                            styles.box,
                            {
                                marginTop: 20,
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingHorizontal: 20,
                            },
                        ]}
                    >
                        <CheckCircleIcon
                            color={getColor('success')}
                            size={width() * 0.2}
                        />
                        <Text style={[styles.h3, marginTop(20)]}>
                            {t('password')}
                        </Text>

                        <Text
                            style={[
                                styles.text,
                                { textAlign: 'center' },
                                marginTop(25),
                            ]}
                        >
                            {isChangePassword
                                ? t('password_changed')
                                : t('password_init')}
                        </Text>

                        <View
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 25,
                            }}
                        >
                            <CartoucheButton
                                height={80}
                                color={getColor('success')}
                                title={t('finish')}
                                onPress={() => {
                                    props.navigation?.navigate('home');
                                }}
                            />
                        </View>
                    </View>
                </Header>
            </View>
        );
    }

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('password')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: -40,
                    }}
                    ref={scrollRef}
                    // @ts-ignore
                    automaticallyAdjustKeyboardInsets={true}
                >
                    <View
                        style={[
                            styles.box,
                            {
                                marginTop: 60,
                                paddingHorizontal: 20,
                            },
                        ]}
                    >
                        <Text
                            style={[
                                styles.h3,
                                { textAlign: 'center' },
                                marginBottom(25),
                            ]}
                        >
                            {isChangePassword
                                ? t('change_password')
                                : t('password')}
                        </Text>
                        {!isChangePassword && (
                            <Text
                                style={[{ textAlign: 'center' }, styles.text]}
                            >
                                {t('password_init_description')}
                            </Text>
                        )}
                        {loading ? (
                            <View style={[styles.boxCenter, marginTop(50)]}>
                                <GeSpinner color={getColor('primary')} />
                            </View>
                        ) : (
                            <View style={[styles.box, marginTop(30)]}>
                                <MpInput
                                    rightOnPress={() => {
                                        setShowPassword(!showPassword);
                                    }}
                                    rightIcon={
                                        showPassword ? (
                                            <EyeIcon color={getColor('hint')} />
                                        ) : (
                                            <EyeSlashIcon
                                                color={getColor('hint')}
                                            />
                                        )
                                    }
                                    secureTextEntry={!showPassword}
                                    autoCapitalize='none'
                                    keyboardType={'default'}
                                    textContentType='password'
                                    placeholder={
                                        isChangePassword
                                            ? (t('old_password') as string)
                                            : (t('password') as string)
                                    }
                                    style={styles.input}
                                    value={password}
                                    defaultValue={password}
                                    onChangeText={(text) => {
                                        setPassword(text);
                                    }}
                                    selectionColor={getColor('primary')}
                                />
                                {isChangePassword && (
                                    <View style={[styles.box, marginTop(15)]}>
                                        <MpInput
                                            rightOnPress={() => {
                                                setShowPassword(!showPassword);
                                            }}
                                            rightIcon={
                                                showPassword ? (
                                                    <EyeIcon
                                                        color={getColor('hint')}
                                                    />
                                                ) : (
                                                    <EyeSlashIcon
                                                        color={getColor('hint')}
                                                    />
                                                )
                                            }
                                            autoCapitalize='none'
                                            keyboardType={'default'}
                                            textContentType='password'
                                            placeholder={
                                                t('new_password') as string
                                            }
                                            style={styles.input}
                                            secureTextEntry={!showPassword}
                                            value={newPassword}
                                            defaultValue={newPassword}
                                            onChangeText={(text) => {
                                                setNewPassword(text);
                                            }}
                                            selectionColor={getColor('primary')}
                                        />
                                    </View>
                                )}
                                {message !== '' && (
                                    <Text
                                        style={[
                                            styles.textError,
                                            marginTop(25),
                                        ]}
                                    >
                                        {message}
                                    </Text>
                                )}
                                <View
                                    style={{
                                        marginTop: 40,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <CartoucheButton
                                        height={80}
                                        title={
                                            isChangePassword
                                                ? t('change')
                                                : t('init')
                                        }
                                        onPress={() => {
                                            setMessage('');
                                            setSuccess(false);
                                            setLoading(true);
                                            if (
                                                User._instance!.passwordInit.get() ===
                                                true
                                            ) {
                                                post('/user/password/change', {
                                                    email: User._instance!.email.get(),
                                                    new_password: newPassword,
                                                    old_password: password,
                                                })
                                                    .then((res) => {
                                                        setLoading(false);
                                                        if (
                                                            HTTPUtils.isSuccess(
                                                                res,
                                                            )
                                                        ) {
                                                            setSuccess(true);
                                                            User._instance?.passwordInit.set(
                                                                true,
                                                            );
                                                            applyResult(
                                                                res.data,
                                                                User._instance!,
                                                            );
                                                        } else {
                                                            setMessage(
                                                                t(
                                                                    HTTPUtils.getError(
                                                                        res,
                                                                    ),
                                                                ) ?? '',
                                                            );
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        setLoading(false);
                                                        setMessage(
                                                            t(
                                                                'went_wrong_no_internet',
                                                            ) ?? '',
                                                        );
                                                    });
                                            } else {
                                                post('/user/password/set', {
                                                    email: User._instance!.email.get(),
                                                    password,
                                                })
                                                    .then((res) => {
                                                        setLoading(false);
                                                        if (
                                                            HTTPUtils.isSuccess(
                                                                res,
                                                            )
                                                        ) {
                                                            User._instance?.passwordInit.set(
                                                                true,
                                                            );
                                                            setSuccess(true);
                                                            applyResult(
                                                                res.data,
                                                                User._instance!,
                                                            );
                                                        } else {
                                                            setMessage(
                                                                t(
                                                                    HTTPUtils.getError(
                                                                        res,
                                                                    ),
                                                                ) ?? '',
                                                            );
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        setLoading(false);
                                                        setMessage(
                                                            t(
                                                                'went_wrong_no_internet',
                                                            ) ?? '',
                                                        );
                                                    });
                                            }
                                        }}
                                    />
                                </View>
                                <Text
                                    style={[
                                        styles.text,
                                        { textAlign: 'center' },
                                        marginTop(25),
                                        {
                                            paddingBottom:
                                                10 + NAVIGATION_BAR_HEIGHT,
                                        },
                                    ]}
                                >
                                    {t('password_requirement')}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            </Header>
        </View>
    );
};
