import { Text, View } from 'react-native';
import React from 'react';
import { FONT_BOLD, FONT_MEDIUM, getColor } from '../utils/theme';
import { width } from '../utils/utils';

export interface INavBarSlotProps {
    focused: boolean;
    iconFocused: JSX.Element;
    iconUnfocused: JSX.Element;
    text: string;
    badge?: boolean;
}

export const NavBarSlot = (props: INavBarSlotProps) => {
    return (
        <View
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                width: width() / 5,
            }}
        >
            <View
                style={{
                    position: 'relative',
                }}
            >
                {props.focused ? props.iconFocused : props.iconUnfocused}
                {(props.badge ?? false) && (
                    <View
                        style={{
                            height: 12,
                            width: 12,
                            borderWidth: 1,
                            borderColor: getColor('card'),
                            right: 0,
                            borderRadius: 50,
                            top: 0,
                            position: 'absolute',
                            backgroundColor: getColor('primary'),
                        }}
                    ></View>
                )}
            </View>
            <View
                style={{
                    height: 25,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Text
                    numberOfLines={1}
                    style={{
                        fontFamily: props.focused ? FONT_BOLD : FONT_MEDIUM,
                        paddingTop: 2,
                        fontSize: 13,
                        color: props.focused
                            ? getColor('primaryHint')
                            : getColor('hint'),
                    }}
                    adjustsFontSizeToFit
                >
                    {props.text}
                </Text>
            </View>
        </View>
    );
};
