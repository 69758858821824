import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
import { ArrowRightOnRectangleIcon } from 'react-native-heroicons/solid';
import { ArrowLeftOnRectangleIcon } from 'react-native-heroicons/solid';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { PastilleView } from '../component/PastilleView';
import Event from '../object/Event';
import User from '../object/User';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { styles } from '../utils/styles';
import { FONT_MEDIUM, getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import { IModalProps } from './IModalProps';
import { registerAdd } from './SubEventModal';
import useJTOState from '../utils/jto/useJTOState';
export const MenuEventModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const actionList = [] as any;

    const [loadingRegister, setLoadingRegister] = useState(false);

    const event = useJTOState((props as any).route.params.event);
    if (
        event.hasRegister.get()?.want.get() === true &&
        event.registrable.get() === true
    ) {
        actionList.push({
            label: 'unsubscribe',
            color: getColor('error'),
            icon: (
                <ArrowRightOnRectangleIcon
                    size={22}
                    fill={'white'}
                />
            ),
            onClick: (navigation: any) => {
                navigation.navigate('RegisterDeleteModal', {
                    event,
                });
            },
        });
    }
    if (
        event.hasRegister.get()?.want.get() !== true &&
        event.registrable.get() === true
    ) {
        actionList.push({
            label: 'register',
            color: getColor('error'),
            icon: (
                <ArrowLeftOnRectangleIcon
                    size={22}
                    fill={'white'}
                />
            ),
            onClick: (navigation: any) => {
                registerAdd(event, t, setLoadingRegister);
            },
        });
    }

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('event')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                {!loadingRegister ? (
                    <View
                        style={{
                            marginTop: 15,
                        }}
                    >
                        {actionList.map((action: any, index: number) => {
                            return (
                                <Pressable
                                    key={index}
                                    style={[
                                        index !== 0 && {
                                            borderTopWidth: 1,
                                            borderColor: getColor('border'),
                                        },
                                        {
                                            flexDirection: 'row',
                                            marginHorizontal: 20,
                                            padding: 10,
                                            alignItems: 'center',
                                            backgroundColor: getColor('card'),
                                        },
                                        index === 0 && {
                                            borderTopLeftRadius: 15,
                                            borderTopRightRadius: 15,
                                        },
                                        index === actionList.length - 1 && {
                                            borderBottomLeftRadius: 15,
                                            borderBottomRightRadius: 15,
                                        },
                                    ]}
                                    onPress={(e) => {
                                        e.stopPropagation();
                                        action.onClick(props.navigation);
                                    }}
                                >
                                    <PastilleView
                                        size={32}
                                        color={getColor('secondary')}
                                        icon={action.icon}
                                    />
                                    <Text
                                        adjustsFontSizeToFit
                                        style={[
                                            {
                                                marginLeft: 10,
                                                color: 'black',
                                                fontFamily: FONT_MEDIUM,
                                                fontSize: 16,
                                                flex: 1,
                                            },
                                        ]}
                                    >
                                        {t(action.label)}
                                    </Text>
                                </Pressable>
                            );
                        })}
                        {actionList.length === 0 && (
                            <View
                                style={{
                                    width: width(),
                                    height: height() * 0.4,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>{t('no_action')}</Text>
                            </View>
                        )}
                    </View>
                ) : (
                    <View
                        style={{
                            marginTop: 15,
                            height: height() * 0.3,
                            width: width(),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <GeSpinner color={getColor('primary')} />
                    </View>
                )}
            </Header>
        </View>
    );
};
