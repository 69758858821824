import JTOElement from './JTOElement';
import JTOProperty from './JTOProperty';
/**
 * JTOProperty is the class that contains simple variable in JTOObject
 */
export default class JTORelProperty<T> extends JTOProperty<T> {
    JTORelProperty = true;

    public constructor(
        field: string[] | string | null = null,
        JTOParent: JTOElement | null = null,
        overrideOnUndefined: boolean = true,
        value?: T,
    ) {
        super(field, JTOParent, overrideOnUndefined, value);
    }

    public override applyDataPartiel(
        data: { [key: string]: any },
        first: boolean,
    ): boolean {
        // return the result
        return false;
    }
    public override toJson() {
        return this.get();
    }
}
