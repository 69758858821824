import { HTTPUtils } from 'mp-ts-http';
import { get, getRestore, post, postRefresh } from '../utils/http';
import { useEffect, useState } from 'react';
import {
    ActivityIndicator,
    Linking,
    StyleProp,
    StyleSheet,
    View,
} from 'react-native-web';
import React from 'react';
import { styles } from '../utils/styles';
import { APP_EVENT_ID, APP_NAME, getColor } from '../utils/theme';
import { IPageProps } from './IPageProps';
import User from '../object/User';
import i18next from 'i18next';
import { getDeviceLang, height } from '../utils/utils';
import { GeSpinner } from '../component/GeSpinner';
import { ContentCartouche } from '../component/ContentCartouche';
import { useTranslation } from 'react-i18next';
import { Header } from '../component/Header';
import Logo from '../svg/Logo';
import { CommonActions } from '@react-navigation/native';
import { QueryInstance } from 'mp-ts-query';
//import { storage } from '../../App';
import { BEFORE_LOGO } from '../utils/config';
import { applyResult } from '../utils/applyResult';
import Event from '../object/Event';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const FirstLoadingPage = (props: IPageProps) => {
    const [loadingUser, setLoadingUser] = useState(true);
    const [loadingLang, setLoadingLang] = useState(true);
    const [loadingLink, setLoadingLink] = useState(true);
    const [loadingFetchLink, setLoadingFetchLink] = useState(false);

    const [url, setUrl] = useState<null | string>(null);

    const { t } = useTranslation();
    User.translation = t;

    useEffect(() => {
        if (!props.navigation.isFocused()) {
            setLoadingUser(false);
            setLoadingLang(false);
            setLoadingLink(false);
            setLoadingFetchLink(false);
        }
    }, [props.navigation.isFocused()]);

    const restoreSession = async (id: null | string) => {
        setLoadingUser(true);
        const refreshToken = await QueryInstance.getRefreshToken()();
        if (
            refreshToken !== '' &&
            refreshToken !== null &&
            refreshToken !== undefined &&
            refreshToken !== 'del'
        ) {
            let userStorage = localStorage.getItem('user');

            let eventStorage = localStorage.getItem('event');

            let userJSON = userStorage ? JSON.parse(userStorage) : null;

            let eventJSON = eventStorage ? JSON.parse(eventStorage) : null;

            if (!userJSON || !eventJSON) {
                setLoadingUser(false);
            } else {
                if (
                    userJSON !== null &&
                    userJSON !== undefined &&
                    Object.keys(userJSON).length !== 0
                ) {
                    User._instance.event = id ?? '';
                    User._instance?.login(userJSON as any);
                    let storage = localStorage.getItem(
                        '/user/event/get?id=' + id,
                    );
                    let storageJSON = storage ? JSON.parse(storage) : null;
                    if (!storageJSON) {
                        setLoadingUser(false);
                    } else {
                        if (storageJSON !== null && storageJSON !== undefined) {
                            if (HTTPUtils.isSuccess(storageJSON)) {
                                User._instance!.event = id ?? '';
                                applyResult(
                                    HTTPUtils.getResult(storageJSON),
                                    User._instance!,
                                    true,
                                );
                                User._instance!.loadEvent();
                                setLoadingUser(false);
                            } else {
                                setLoadingUser(false);
                            }
                        } else {
                            setLoadingUser(false);
                        }
                    }
                }
            }
        } else {
            setLoadingUser(false);
        }
    };

    useEffect(() => {
        Linking.getInitialURL()
            .then((res) => {
                setLoadingLink(false);
                setUrl(res);

                if (res !== null) {
                    if (res.includes('/sharing?param=')) {
                        //get params
                        const data = res.split('/sharing?param=')[1];
                        if (data) {
                            const dataJson = JSON.parse(
                                decodeURIComponent(data),
                            );
                            setLoadingLink(true);

                            const goodData = JSON.parse(
                                decodeURIComponent(dataJson),
                            );

                            if (
                                goodData &&
                                (goodData.type === 'event' ||
                                    goodData.type === 'sub_event')
                            ) {
                                const eventId = goodData.id;

                                User._instance.event = eventId;
                                AsyncStorage.setItem('event', eventId + '')

                                    .then((act) => {
                                        get('/user/event/get', {
                                            id: eventId + '',
                                        })
                                            .then((res) => {
                                                if (HTTPUtils.isSuccess(res)) {
                                                    applyResult(
                                                        HTTPUtils.getResult(
                                                            res,
                                                        ),
                                                        User._instance!,
                                                        true,
                                                    );
                                                    User._instance!.loadEvent();
                                                    if (
                                                        User._instance.getEvent()
                                                    ) {
                                                        setLoadingLink(false);
                                                        props.navigation.dispatch(
                                                            CommonActions.reset(
                                                                {
                                                                    index: 0,
                                                                    routes: [
                                                                        {
                                                                            name: 'Tab',
                                                                        },
                                                                    ],
                                                                },
                                                            ),
                                                        );
                                                    }

                                                    if (
                                                        goodData.type ===
                                                        'sub_event'
                                                    ) {
                                                        //open sub event modal
                                                        const subEvent =
                                                            new Event();

                                                        //get sub event data
                                                        get('/event/get', {
                                                            id:
                                                                goodData.id_sub +
                                                                '',
                                                        })
                                                            .then((res) => {
                                                                if (
                                                                    HTTPUtils.isSuccess(
                                                                        res,
                                                                    )
                                                                ) {
                                                                    const subEventData =
                                                                        HTTPUtils.getResult(
                                                                            res,
                                                                        );

                                                                    subEvent.applyData(
                                                                        subEventData,
                                                                    );
                                                                    setLoadingLink(
                                                                        false,
                                                                    );
                                                                    props.navigation.push(
                                                                        'SubEventModal',
                                                                        {
                                                                            event: subEvent,
                                                                            notif: true,
                                                                        },
                                                                    );
                                                                }
                                                            })
                                                            .catch((err) => {
                                                                setLoadingLink(
                                                                    false,
                                                                );
                                                            });
                                                    }
                                                }
                                            })
                                            .catch((err) => {
                                                setLoadingLink(false);
                                                // NO OP
                                            });
                                    })
                                    .catch((err) => {
                                        setLoadingLink(false);
                                    });
                            }
                        }
                    } else if (res.includes('//action=event')) {
                        // NOTHING
                    } else if (res.includes('//action=invite')) {
                        const params = res.replace('winteruniversity://', '');

                        const token = params
                            .split('accessToken=')[1]
                            .split('&user=')[0];
                        const user = params
                            .split('user=')[1]
                            .split('&event=')[0];
                        const event = params.split('event=')[1];

                        if (token !== undefined) {
                            setLoadingFetchLink(true);
                            setLoadingUser(true);
                            post(
                                '/invite/logintoken',
                                {
                                    token,
                                    user,
                                    event,
                                },
                                true,
                            )
                                .then((resUser) => {
                                    if (HTTPUtils.isSuccess(resUser)) {
                                        User._instance?.login(
                                            HTTPUtils.getResult(resUser),
                                        );
                                        getRestore(
                                            '/user/event/get',
                                            { event },
                                            (result: any) => {
                                                if (
                                                    HTTPUtils.isSuccess(result)
                                                ) {
                                                    const oldEvent =
                                                        User._instance!.event;
                                                    User._instance!.event =
                                                        event ?? '';

                                                    localStorage.setItem(
                                                        'event',
                                                        event + '',
                                                    );
                                                    applyResult(
                                                        HTTPUtils.getResult(
                                                            result,
                                                        ),
                                                        User._instance!,
                                                        true,
                                                    );
                                                    User._instance!.loadEvent();
                                                    setLoadingUser(false);
                                                } else {
                                                    setLoadingUser(false);
                                                }
                                            },
                                            (err: any) => {
                                                setLoadingUser(false);
                                            },
                                            () => {
                                                // TODO
                                            },
                                        );
                                        setLoadingFetchLink(false);
                                    } else {
                                        setLoadingFetchLink(false);
                                    }
                                })
                                .catch((err) => {
                                    setLoadingFetchLink(false);
                                });
                        }
                    }
                }
            })
            .catch((err) => {
                setLoadingLink(false);
                setUrl(null);
            });
        let storageEvent = localStorage.getItem('event');
        if (storageEvent) {
            setLoadingUser(true);
            restoreSession(storageEvent);
        } else {
            setLoadingUser(false);
        }

        let storageLang = localStorage.getItem('lang');
        if (
            storageLang === undefined ||
            storageLang === null ||
            storageLang === ''
        ) {
            const deviceLang = getDeviceLang().substr(0, 2);
            i18next
                .changeLanguage(deviceLang)
                .then(() => {
                    setLoadingLang(false);
                })
                .catch((err) => {
                    setLoadingLang(false);
                });
        } else {
            i18next
                .changeLanguage(storageLang)
                .then(() => {
                    setLoadingLang(false);
                })
                .catch((err) => {
                    setLoadingLang(false);
                });
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (
                !loadingLink &&
                !loadingFetchLink &&
                !loadingLang &&
                !loadingUser
            ) {
                if (
                    User._instance.getJTOIdentifier() === undefined ||
                    User._instance.getJTOIdentifier() === null ||
                    User._instance.getJTOIdentifier() === ''
                ) {
                    props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'BeforeLogin' }],
                        }),
                    );
                } else {
                    if (User._instance.getEvent() === null) {
                        const refreshToken =
                            await QueryInstance.getRefreshToken()();
                        const accessToken =
                            await QueryInstance.getAccessToken()();
                        if (
                            refreshToken !== '' &&
                            refreshToken !== null &&
                            refreshToken !== undefined &&
                            refreshToken !== 'del' &&
                            accessToken !== '' &&
                            accessToken !== null &&
                            accessToken !== undefined &&
                            accessToken !== 'del'
                        ) {
                            props.navigation.dispatch(
                                CommonActions.reset({
                                    index: 0,
                                    routes: [{ name: 'Event' }],
                                }),
                            );
                        } else {
                            props.navigation.dispatch(
                                CommonActions.reset({
                                    index: 0,
                                    routes: [{ name: 'BeforeLogin' }],
                                }),
                            );
                        }
                    } else {
                        props.navigation.dispatch(
                            CommonActions.reset({
                                index: 0,
                                routes: [{ name: 'Tab' }],
                            }),
                        );
                    }
                }
            }
        })();
    }, [loadingLang, loadingUser, loadingLink, loadingFetchLink]);

    return (
        <View style={[styles.page]}>
            <Header
                title={APP_NAME}
                beforeLogo={BEFORE_LOGO}
                fallback={
                    <Logo
                        height={50}
                        width={50}
                    />
                }
                fallbackColor={'white'}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                full
                logo={''}
            >
                <View
                    style={{
                        flex: 1,
                        height: height(),
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <GeSpinner color={getColor('ge')} />
                </View>
            </Header>
        </View>
    );
};
