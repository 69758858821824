import React, { useEffect, useState } from 'react';

import SvgLoading1 from '../svg/Loading1';
import SvgLoading2 from '../svg/Loading2';
import SvgLoading3 from '../svg/Loading3';
import SvgLoading4 from '../svg/Loading4';
import SvgLoading5 from '../svg/Loading5';
import SvgLoading6 from '../svg/Loading6';
import SvgLoading7 from '../svg/Loading7';
import { getColor } from '../utils/theme';

interface IGeSpinnerProps {
    size?: number;
    color?: string;
}

export const GeSpinner = (props: IGeSpinnerProps) => {
    const { size = 70, color = getColor('primary') } = props;

    const svgComponents = [
        SvgLoading1,
        SvgLoading2,
        SvgLoading3,
        SvgLoading4,
        SvgLoading5,
        SvgLoading6,
        SvgLoading7,
    ];

    const [opacities, setOpacities] = useState(
        Array(svgComponents.length).fill(0),
    );

    useEffect(() => {
        const animate = () => {
            svgComponents.forEach((_, index) => {
                setTimeout(() => {
                    setOpacities((prevOpacities) =>
                        prevOpacities.map((opacity, i) =>
                            i === index ? 1 : 0,
                        ),
                    );
                }, 250 * index);
            });
        };

        const interval = setInterval(animate, 250 * svgComponents.length);

        animate();

        return () => clearInterval(interval);
    }, [svgComponents.length]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                height: size,
                justifyContent: 'center',
                alignItems: 'center',
                width: size, // Ensuring that width and height are equal to prop size
                position: 'relative', // Allow absolute positioning of SVGs
            }}
        >
            {svgComponents.map((SvgComponent, index) => (
                <div
                    key={index}
                    style={{
                        opacity: opacities[index],
                        width: size,
                        height: size,
                        position: 'absolute', // Overlay SVGs
                        display: 'flex',
                        justifyContent: 'center', // Center SVG in the view
                        alignItems: 'center',
                        transition: 'opacity 0.5s', // Smooth transition for opacity
                    }}
                >
                    <SvgComponent
                        fill={color}
                        height={size}
                        width={size}
                    />
                </div>
            ))}
        </div>
    );
};

export default GeSpinner;
