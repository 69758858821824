import * as React from 'react';
import {
    Dimensions,
    Image,
    InteractionManager,
    Pressable,
    RefreshControl,
    ScrollView,
    StyleSheet,
    View,
} from 'react-native';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import { useTranslation } from 'react-i18next';
import { Text } from 'react-native';
import User from '../object/User';
import {
    PencilIcon,
    Cog6ToothIcon,
    UserIcon,
    CalendarDaysIcon,
    TrashIcon,
    NoSymbolIcon,
    NewspaperIcon,
    ChatBubbleOvalLeftEllipsisIcon,
} from 'react-native-heroicons/solid';
import { PastilleView } from '../component/PastilleView';
import { SocialNetworkView } from '../component/SocialNetworkView';
import { Header } from '../component/Header';
import { GeSpinner } from '../component/GeSpinner';
import { ToastManager } from '../component/toast/ToastManager';
import { HTTPUtils } from 'mp-ts-http';
import { Toast } from '../component/toast/Toast';
import { styles } from '../utils/styles';
import { GroupView } from '../component/GroupView';
import { FileElementView } from '../component/FileElementView';
import { CartoucheButton } from '../component/CartoucheButton';
import { SERVER_URL } from '../utils/constants';
import { getRestore } from '../utils/http';
import { LevelFocusView } from '../component/LevelFocusView';
import GenericAchivement from '../component/achivement/GenericAchivement';
import useJTOState from '../utils/jto/useJTOState';
import { applyResult } from '../utils/applyResult';
import { CustomTextWithLinks } from '../component/CustomTextWithLinks';
import { Use } from 'react-native-svg';
import { CloseAllButton } from '../component/CloseAllButton';

export function ProfilePage(props: any) {
    const { t } = useTranslation();

    const [loadingHim, setLoadingHim] = React.useState(true);
    const [notIn, setNotIn] = React.useState(false);

    const userMy = useJTOState(User._instance!);
    const userId = (
        ((props as any).route?.params?.user ?? User._instance) as User
    ).getJTOIdentifier();
    const user = useJTOState(
        userId === User._instance.getJTOIdentifier()
            ? User._instance
            : new User(),
    );
    user.event = User._instance.event;

    const userParams = (props as any).route?.params?.user;

    const [refreshing, setRefreshing] = React.useState(false);

    const reset = () => {
        user.getEvent()?.getInvite(user)?.profilFileList.reset();
        user.getEvent()?.getInvite(user)?.myEventList.reset();
        user.getEvent()?.getInvite(user)?.user.set(null);
        user.getEvent()?.getInvite(user)?.myPostList.reset();
        user.getEvent()?.getInvite(user)?.groupList.reset();
        for (const invite of user.inviteList.getList()) {
            invite.user.set(null);
            //invite.event.set(null)
        }
    };

    const fetchAll = () => {
        setLoadingHim(true);
        getRestore(
            '/user/event/him',
            {
                user: userId,
                event: User._instance.getEvent()?.getJTOIdentifier() + '',
            },
            (res: any) => {
                if (HTTPUtils.isSuccess(res)) {
                    applyResult(HTTPUtils.getResult(res), user, true);
                    setLoadingHim(false);
                } else {
                    if (HTTPUtils.getError(res) === 'no_permission') {
                        setNotIn(true);
                    } else {
                        ToastManager.instance.addToast(
                            new Toast(
                                t(HTTPUtils.getError(res)),
                                5000,
                                'error',
                            ),
                        );
                    }
                    setLoadingHim(false);
                }
            },
            (err: any) => {
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet'), 5000, 'error'),
                );
                setLoadingHim(false);
            },
            (res: any) => {
                reset();
                // TODO
            },
        );
    };

    const onRefresh = () => {
        // setRefreshing(false);
        fetchAll();
    };

    React.useEffect(() => {
        user.avatar.set(
            (
                ((props as any).route?.params?.user ?? User._instance) as User
            ).avatar.get(),
        );
        user.firstname.set(
            (
                ((props as any).route?.params?.user ?? User._instance) as User
            ).firstname.get(),
        );
        user.lastname.set(
            (
                ((props as any).route?.params?.user ?? User._instance) as User
            ).lastname.get(),
        );
        InteractionManager.runAfterInteractions(() => {
            try {
                setRefreshing(false);
                fetchAll();
            } catch (e) {}
        });
        return () => {
            InteractionManager.runAfterInteractions(() => {
                try {
                    reset();
                } catch (e) {}
            });
        };
    }, []);

    const isMe = userId === User._instance.getJTOIdentifier();
    let showFile = false;

    let fileContent: JSX.Element;
    if (loadingHim) {
        const invite = user.getEvent()?.getInvite(user);
        if ((invite?.profilFileList.getList().length ?? 0) === 0) {
            fileContent = (
                <View
                    style={{
                        height:
                            height() -
                            TAB_HEIGHT -
                            STATUS_BAR_HEIGHT -
                            NAVIGATION_BAR_HEIGHT -
                            150,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <GeSpinner color={getColor('primary')} />
                </View>
            );
        } else {
            fileContent = (
                <View
                    style={{
                        marginTop: height() * 0.15,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <GeSpinner color={getColor('primary')} />
                </View>
            );
        }
        showFile = true;
    } else {
        const invite = user.getEvent()?.getInvite(user);
        if (invite === undefined || invite === null) {
            showFile = false;
            fileContent = <></>;
        } else if (invite.profilFileList.getList().length === 0) {
            if (isMe) {
                showFile = true;
                fileContent = (
                    <View
                        style={{
                            marginTop: 50,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 20,
                                fontFamily: FONT_MEDIUM,
                                color: getColor('text'),
                                textAlign: 'center',
                            }}
                        >
                            {t('no_file')}
                        </Text>
                        <Pressable
                            style={[
                                {
                                    backgroundColor: getColor('secondary'),
                                    paddingVertical: 10,
                                    paddingHorizontal: 14,
                                    borderRadius: 10,
                                },
                                { marginTop: 20 },
                            ]}
                            onPress={() => {
                                props.navigation.navigate('ProfilFileModal');
                            }}
                        >
                            <Text style={styles.buttonText}>
                                {t('add_file')}
                            </Text>
                        </Pressable>
                    </View>
                );
            } else {
                showFile = false;
                fileContent = <></>;
            }
        } else {
            showFile = true;
            fileContent = (
                <View
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        marginTop: 10,
                        marginHorizontal: -7.5,
                    }}
                >
                    {user
                        .getEvent()
                        ?.getInvite(user)!
                        .profilFileList.getList()
                        .map((file, index) => (
                            <FileElementView
                                navigation={props.navigation}
                                key={file.getJTOIdentifier()}
                                file={file}
                                canAct={isMe}
                            />
                        ))}
                </View>
            );
        }
    }

    return (
        <View
            style={{
                flex: 1,
                width: '100%',
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                title={user.getDisplayName()}
                logo={user.avatar.get() ?? ''}
                fallback={
                    <UserIcon
                        color={'white'}
                        size={35}
                    />
                }
                line={1}
                onPresslogo={() => {
                    if ((user.avatar.get() ?? '') !== '') {
                        props.navigation.navigate('PostPictureModal', {
                            uri: SERVER_URL + '/image/' + user.avatar.get(),
                        });
                    }
                }}
                backButton={userParams !== undefined}
                modal={userParams !== undefined}
                full
                navigation={props.navigation}
                rounded={(user.avatar.get() ?? '') !== ''}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
            >
                {user.nothing === true ? (
                    <View
                        style={{
                            height: height(),
                            width: width(),
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View style={styles.boxCenter}>
                            <Text
                                style={{
                                    marginBottom: 0,
                                    textAlign: 'center',
                                    fontFamily: FONT_REGULAR,
                                    marginHorizontal: 25,
                                }}
                            >
                                {t('no_profil_please_login')}
                            </Text>
                            <Pressable
                                style={[
                                    {
                                        backgroundColor: getColor('secondary'),
                                        paddingVertical: 10,
                                        paddingHorizontal: 14,
                                        borderRadius: 10,
                                    },
                                    {
                                        backgroundColor: getColor('secondary'),
                                    },
                                    { marginTop: 20 },
                                ]}
                                onPress={() => {
                                    props.navigation.navigate('Login');
                                }}
                            >
                                <Text style={styles.buttonText}>
                                    {t('login')}
                                </Text>
                            </Pressable>
                        </View>
                    </View>
                ) : notIn ? (
                    <View
                        style={{
                            height: height(),
                            width: width(),
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Text>{t('not_in_event')}</Text>
                    </View>
                ) : loadingHim ? (
                    <View
                        style={{
                            height: height(),
                            width: width(),
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <GeSpinner color={getColor('primary')} />
                    </View>
                ) : (
                    <ScrollView
                        // @ts-ignore
                        refreshControl={
                            <RefreshControl
                                progressViewOffset={HEADER_BONUS_HEIGHT}
                                colors={[getColor('primary')]}
                                refreshing={refreshing}
                                onRefresh={onRefresh}
                            />
                        }
                        style={{
                            flex: 1,
                        }}
                    >
                        <View
                            style={{
                                width: '100%',
                                position: 'relative',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: getColor('primary'),
                                paddingTop: 0,
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    paddingTop: 10,
                                }}
                            >
                                {user.nothing === false && (
                                    <Pressable
                                        style={{
                                            justifyContent: 'center',
                                            marginHorizontal: 10,
                                            alignItems: 'center',
                                            marginBottom: 10,
                                        }}
                                        onPress={() => {
                                            props.navigation.navigate(
                                                'PostProfilePage',
                                                {
                                                    user,
                                                },
                                            );
                                        }}
                                    >
                                        <PastilleView
                                            color={getColor('secondary')}
                                            size={55}
                                            icon={
                                                <NewspaperIcon
                                                    height={35}
                                                    width={35}
                                                    fill={'white'}
                                                />
                                            }
                                        />
                                        <Text
                                            style={{
                                                color: 'white',
                                                marginTop: 5,
                                                fontFamily: FONT_BOLD,
                                                fontSize: 14,
                                            }}
                                        >
                                            {user.isMe()
                                                ? t('profile.post')
                                                : t('profile.him_post')}
                                        </Text>
                                    </Pressable>
                                )}
                                {user.nothing === false && !isMe && (
                                    <Pressable
                                        style={{
                                            justifyContent: 'center',
                                            marginHorizontal: 10,
                                            alignItems: 'center',
                                            marginBottom: 10,
                                        }}
                                        onPress={() => {
                                            props.navigation.navigate(
                                                'MessagePage',
                                                {
                                                    user,
                                                },
                                            );
                                        }}
                                    >
                                        <PastilleView
                                            color={getColor('secondary')}
                                            size={55}
                                            icon={
                                                <ChatBubbleOvalLeftEllipsisIcon
                                                    height={35}
                                                    width={35}
                                                    fill={'white'}
                                                />
                                            }
                                        />
                                        <Text
                                            style={{
                                                color: 'white',
                                                marginTop: 5,
                                                fontFamily: FONT_BOLD,
                                                fontSize: 14,
                                            }}
                                        >
                                            {t('profile.contact')}
                                        </Text>
                                    </Pressable>
                                )}
                                {user.nothing === false && isMe && (
                                    <Pressable
                                        style={{
                                            justifyContent: 'center',
                                            marginHorizontal: 10,
                                            alignItems: 'center',
                                            marginBottom: 10,
                                        }}
                                        onPress={() => {
                                            props.navigation.navigate(
                                                'MenuMyProfileModal',
                                            );
                                        }}
                                    >
                                        <PastilleView
                                            color={getColor('secondary')}
                                            size={55}
                                            icon={
                                                <PencilIcon
                                                    height={32}
                                                    width={32}
                                                    fill={'white'}
                                                />
                                            }
                                        />
                                        <Text
                                            style={{
                                                color: 'white',
                                                marginTop: 5,
                                                fontFamily: FONT_BOLD,
                                                fontSize: 14,
                                            }}
                                        >
                                            {t('edit')}
                                        </Text>
                                    </Pressable>
                                )}
                                {isMe && (
                                    <Pressable
                                        style={{
                                            marginHorizontal: 10,
                                            marginBottom: 10,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        onPress={() => {
                                            props.navigation.navigate('Param');
                                        }}
                                    >
                                        <PastilleView
                                            color={getColor('secondary')}
                                            size={55}
                                            icon={
                                                <Cog6ToothIcon
                                                    height={40}
                                                    width={40}
                                                    fill={'white'}
                                                />
                                            }
                                        />
                                        <Text
                                            style={{
                                                color: 'white',
                                                marginTop: 5,
                                                fontFamily: FONT_BOLD,
                                                fontSize: 14,
                                            }}
                                        >
                                            {t('parameters')}
                                        </Text>
                                    </Pressable>
                                )}
                                {!User._instance.nothing && !user.isMe() && (
                                    <Pressable
                                        style={{
                                            marginHorizontal: 10,
                                            justifyContent: 'center',
                                            marginBottom: 10,
                                            alignItems: 'center',
                                        }}
                                        onPress={() => {
                                            props.navigation.navigate(
                                                'BlockUserModal',
                                                {
                                                    user,
                                                    act:
                                                        user.blockMe.get() ===
                                                        null
                                                            ? 'block'
                                                            : 'unblock',
                                                },
                                            );
                                        }}
                                    >
                                        <PastilleView
                                            color={getColor('secondary')}
                                            size={55}
                                            icon={
                                                <NoSymbolIcon
                                                    height={40}
                                                    width={40}
                                                    fill={'white'}
                                                />
                                            }
                                        />
                                        <Text
                                            style={{
                                                color: 'white',
                                                marginTop: 5,
                                                fontFamily: FONT_BOLD,
                                                fontSize: 14,
                                            }}
                                        >
                                            {user.blockMe.get() === null
                                                ? t('block')
                                                : t('unblock')}
                                        </Text>
                                    </Pressable>
                                )}
                            </View>
                        </View>

                        <View
                            style={{
                                marginTop: 20,
                            }}
                        ></View>
                        <LevelFocusView
                            user={user}
                            navigation={props.navigation}
                        />
                        {
                            <View>
                                {user.description.get() !== undefined &&
                                    user.description.get() !== '' && (
                                        <View style={profilStyle.container}>
                                            <Text style={[profilStyle.title]}>
                                                {user.isMe()
                                                    ? t('my_bio')
                                                    : t('bio')}
                                            </Text>
                                            <CustomTextWithLinks
                                                content={
                                                    user.description.get() ?? ''
                                                }
                                                style={{
                                                    color: getColor('text'),
                                                    fontFamily: FONT_REGULAR,
                                                }}
                                            />
                                        </View>
                                    )}

                                {user.hasNetwork() && (
                                    <View>
                                        <Text
                                            style={[
                                                profilStyle.title,
                                                {
                                                    paddingHorizontal: 20,
                                                    marginTop: 15,
                                                    marginBottom: 10,
                                                },
                                            ]}
                                        >
                                            {!user.isMe()
                                                ? t('social_network')
                                                : t('my_social_network')}
                                        </Text>
                                        <SocialNetworkView user={user} />
                                    </View>
                                )}

                                {(user
                                    .getEvent()
                                    ?.getInvite(user)
                                    ?.groupList.getList().length ?? 0) > 0 && (
                                    <View>
                                        <Text
                                            style={[
                                                profilStyle.title,
                                                {
                                                    paddingHorizontal: 20,
                                                    marginTop: 15,
                                                    marginBottom: 10,
                                                },
                                            ]}
                                        >
                                            {user.isMe()
                                                ? t('my_groups')
                                                : t('groups')}
                                        </Text>

                                        <View
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                paddingHorizontal: 20,
                                                flexDirection: 'row',
                                                marginBottom: -10,
                                            }}
                                        >
                                            {user
                                                .getEvent()
                                                ?.getInvite(user)
                                                ?.groupList.map((group) => {
                                                    return (
                                                        <GroupView
                                                            group={group}
                                                            key={group.getJTOIdentifier()}
                                                        />
                                                    );
                                                })}
                                        </View>
                                    </View>
                                )}
                                <View>
                                    <View
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            paddingHorizontal: 20,
                                        }}
                                    >
                                        <Text
                                            style={[
                                                profilStyle.title,
                                                {
                                                    marginTop: 15,
                                                    marginBottom: 10,
                                                    flex: 1,
                                                },
                                            ]}
                                        >
                                            {t('profile_visit_card')}
                                        </Text>
                                        {user.visitCard.get() !== undefined &&
                                            user.visitCard.get() !== '' && (
                                                <View
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <Pressable
                                                        style={{
                                                            paddingRight: 20,
                                                        }}
                                                        onPress={(e) => {
                                                            e.stopPropagation();
                                                            props.navigation.navigate(
                                                                'ProfilVisitCardDelModal',
                                                                {
                                                                    id:
                                                                        props.file?.getJTOIdentifier() ??
                                                                        '',
                                                                    alias:
                                                                        props.file?.getAlias() ??
                                                                        '',
                                                                },
                                                            );
                                                        }}
                                                    >
                                                        <PastilleView
                                                            size={35}
                                                            color={getColor(
                                                                'error',
                                                            )}
                                                            icon={
                                                                <TrashIcon
                                                                    fill={
                                                                        'white'
                                                                    }
                                                                    size={28}
                                                                />
                                                            }
                                                        />
                                                    </Pressable>

                                                    <Pressable
                                                        onPress={(e) => {
                                                            e.stopPropagation();
                                                            props.navigation.navigate(
                                                                'ProfilVisitCardModal',
                                                                {
                                                                    id:
                                                                        props.file?.getJTOIdentifier() ??
                                                                        '',
                                                                    alias:
                                                                        props.file?.getAlias() ??
                                                                        '',
                                                                },
                                                            );
                                                        }}
                                                    >
                                                        <PastilleView
                                                            size={35}
                                                            color={getColor(
                                                                'secondary',
                                                            )}
                                                            icon={
                                                                <PencilIcon
                                                                    fill={
                                                                        'white'
                                                                    }
                                                                    size={25}
                                                                />
                                                            }
                                                        />
                                                    </Pressable>
                                                </View>
                                            )}
                                    </View>
                                    {user.visitCard.get() !== undefined &&
                                    user.visitCard.get() !== '' ? (
                                        <Pressable
                                            onPress={(e) => {
                                                e.stopPropagation();
                                                props.navigation.navigate(
                                                    'PostPictureModal',
                                                    {
                                                        uri:
                                                            SERVER_URL +
                                                            '/file/' +
                                                            user.visitCard.get(),
                                                    },
                                                );
                                            }}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: 5,
                                            }}
                                        >
                                            <Image
                                                source={{
                                                    uri:
                                                        SERVER_URL +
                                                        '/file/' +
                                                        user.visitCard.get(),
                                                }}
                                                style={{
                                                    width: width() - 40,
                                                    height:
                                                        ((width() - 40) * 55) /
                                                        85,
                                                    borderRadius: 20,
                                                }}
                                            />
                                        </Pressable>
                                    ) : (
                                        <View
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginVertical: 50,
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontSize: 20,
                                                    fontFamily: FONT_MEDIUM,
                                                    color: getColor('text'),
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {t('no_visit_card')}
                                            </Text>
                                            <Pressable
                                                style={[
                                                    {
                                                        backgroundColor:
                                                            getColor(
                                                                'secondary',
                                                            ),
                                                        paddingVertical: 10,
                                                        paddingHorizontal: 14,
                                                        borderRadius: 10,
                                                    },
                                                    { marginTop: 20 },
                                                ]}
                                                onPress={() => {
                                                    props.navigation.navigate(
                                                        'ProfilVisitCardModal',
                                                    );
                                                }}
                                            >
                                                <Text style={styles.buttonText}>
                                                    {t('add_visit_card')}
                                                </Text>
                                            </Pressable>
                                        </View>
                                    )}
                                </View>

                                {showFile && (
                                    <View>
                                        <View style={profilStyle.container}>
                                            <Text style={profilStyle.title}>
                                                {user.isMe()
                                                    ? t('my_files')
                                                    : t('files')}
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                paddingHorizontal: 20,
                                                marginTop: -15,
                                                marginBottom: 20,
                                            }}
                                        >
                                            {fileContent}
                                        </View>
                                    </View>
                                )}
                            </View>
                        }
                    </ScrollView>
                )}
            </Header>
            {userParams !== undefined && (
                <CloseAllButton
                    onPress={() => {
                        props.navigation.popToTop();
                    }}
                />
            )}
        </View>
    );
}

export const profilStyle = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        marginTop: 15,
    },
    title: {
        color: getColor('text'),
        fontFamily: FONT_MEDIUM,
        fontSize: 18,
        marginBottom: 4,
    },
});
