import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

import Event from './Event';
import Group from './Group';
import JTOListFetch from './JTOListFetch';
import Post from './Post';
import ProfilFile from './ProfilFile';
import RoleEvent from './RoleEvent';
import User from './User';
import EventTiny from './compressed/event/EventTiny';

export default class Invite extends JTOObject {
    public static _TYPE: string = 'Invite';

    public event: JTOSimple<Event>;
    public user: JTOSimple<User>;
    public vote: JTORelProperty<string>;
    public want: JTORelProperty<boolean>;
    public invited: JTORelProperty<boolean>;
    public roleEvent: JTOList<RoleEvent>;
    public groupList: JTOList<Group>;
    public myEventList: JTOList<EventTiny>;
    public myPostList: JTOListFetch<Post>;
    public reaction: JTORelProperty<number>;

    public profilFileList: JTOList<ProfilFile>;

    constructor(parent?: JTOElement | null) {
        super(Invite._TYPE, parent);

        this.event = new JTOSimple<Event>(Event, 'has_invite', this);
        this.user = new JTOSimple<User>(User, 'be_part', this);
        this.roleEvent = new JTOList<RoleEvent>(
            RoleEvent,
            'has_role_event',
            this,
        );
        this.myPostList = new JTOListFetch<Post>(
            '/post/my',
            Post,
            'has_post',
            this,
            (a: Post, b: Post) =>
                b.date.get()?.localeCompare(a.date.get() ?? '') ?? 0,
        );
        this.vote = new JTORelProperty<string>('has_vote.vote', this);

        this.reaction = new JTORelProperty<number>('react.type', this);

        this.want = new JTORelProperty<boolean>('has_register.want', this);
        this.invited = new JTORelProperty<boolean>(
            'has_register.invited',
            this,
        );

        this.groupList = new JTOList<Group>(Group, 'has_member', this);

        this.myEventList = new JTOList<EventTiny>(
            EventTiny,
            'has_register',
            this,
            (a, b) => {
                if (
                    a.startDatetime.get() === undefined &&
                    b.startDatetime.get() === undefined &&
                    a.endDatetime.get() === undefined &&
                    b.endDatetime.get() === undefined
                ) {
                    return 0;
                }
                if (
                    a.startDatetime.get() === null &&
                    b.startDatetime.get() === null &&
                    a.endDatetime.get() === null &&
                    b.endDatetime.get() === null
                ) {
                    return 0;
                }

                if (
                    a.startDatetime.get() === undefined &&
                    a.endDatetime.get() === undefined
                )
                    return 1;
                if (
                    b.startDatetime.get() === undefined &&
                    b.endDatetime.get() === undefined
                )
                    return 1;

                if (
                    a.startDatetime.get() === null &&
                    a.endDatetime.get() === null
                )
                    return -1;
                if (
                    b.startDatetime.get() === null &&
                    b.endDatetime.get() === null
                )
                    return -1;

                let aDate: any;
                let bDate: any;

                if (
                    a.startDatetime.get() !== undefined &&
                    a.startDatetime.get() !== null
                ) {
                    aDate = a.startDatetime.get();
                } else {
                    aDate = a.endDatetime.get();
                }

                if (
                    b.startDatetime.get() !== undefined &&
                    b.startDatetime.get() !== null
                ) {
                    bDate = b.startDatetime.get();
                } else {
                    bDate = b.endDatetime.get();
                }

                return aDate.localeCompare(bDate);
            },
        );
        this.profilFileList = new JTOList<ProfilFile>(
            ProfilFile,
            'has_profilfile',
            this,
        );
    }

    public getDisplayName(): string {
        return this.user.get()?.getDisplayName() ?? 'Joe Doe';
    }
}
