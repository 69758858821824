import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function SvgLoading1(props: any) {
    return (
        <svg
            viewBox='0 0 4668.000000 2326.000000'
            {...props}
        >
            <path
                d='M17489 20452c-2346-1538-4377-2871-4515-2961l-249-164 80-74c44-42 143-128 220-193 2020-1698 5917-2675 11210-2811 761-20 2380-3 2778 29l87 7 3314 3465c1823 1906 3313 3466 3312 3467s-74-6-162-17c-2222-271-4638-138-7014 386-1639 362-3379 958-4638 1588-83 42-153 76-154 76-2-1-1923-1260-4269-2798z'
                transform='matrix(.1 0 0 -.1 0 2326)'
            />
        </svg>
    );
}
export default SvgLoading1;
