import {
    View,
    Text,
    Pressable,
    FlatList,
    Image,
    Platform,
    Alert,
    PermissionsAndroid,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import {
    fileToBase64,
    getOrientationFromUri,
    goBackTo,
    height,
    resizeAndFixRotation,
    width,
} from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import Post from '../object/Post';
import { useEffect, useState } from 'react';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { TextInput } from 'react-native';
import { MpInput } from '../base/MpInput';
import { post } from '../utils/http';
import User from '../object/User';
import { HTTPUtils } from 'mp-ts-http';
import { getErrorMessage } from 'mp-ts-error';
import DocumentPicker from 'react-native-document-picker';
//import RNFS from 'react-native-fs';
import { SERVER_URL } from '../utils/constants';
import { FileElementView } from '../component/FileElementView';
import {
    CameraIcon,
    DocumentIcon,
    PhotoIcon,
    TrashIcon,
} from 'react-native-heroicons/solid';
//import { BorderlessButton, ScrollView } from 'react-native-gesture-handler';
import Event from '../object/Event';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import { PastilleView } from '../component/PastilleView';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
//import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import JTOInstance from '../utils/jto/JTOInstance';
import useJTOState from '../utils/jto/useJTOState';
import { isImageFile } from '../component/message/BottomMessagePage';
import { PostFileModal } from '../component/modal/PostFileModal';

export const PostModal = (props: IModalProps) => {
    const { t } = useTranslation();

    // get Event object from props
    const event = useJTOState((props as any).route.params.event as Event);

    // get Post object from props
    const postObj = useJTOState(
        ((props as any).route.params.post ?? new Post()) as Post,
    );

    const comment = (props as any).route.params.comment as boolean;

    // declare a state list for the files

    const [fileList, setFileList] = useState([] as any[]);

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [openModal, setOpenModal] = useState(false);

    const uploadFile = async (idPost: string) => {
        setLoading(true);

        for (const file of fileList) {
            let file_data = await fileToBase64(file);
            post('/mobile/post/upload', {
                file_type: file.type,
                file_data: file_data,
                file_name: file.name,
                id_post: idPost + '',
            })
                .then((res) => {
                    if (HTTPUtils.isSuccess(res)) {
                        User._instance.getEvent()?.postList.preventBlock();
                        User._instance
                            .getEvent()
                            ?.getMyInvite()
                            ?.myPostList.preventBlock();
                        JTOInstance.applyResult(HTTPUtils.getResult(res), true);
                        User._instance.getEvent()?.postList.remainBlock();
                        User._instance
                            .getEvent()
                            ?.getMyInvite()
                            ?.myPostList.remainBlock();
                    } else {
                        setError(
                            t(HTTPUtils.getError(HTTPUtils.getError(res))) ??
                                '',
                        );
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setError(t('went_wrong_no_internet') ?? '');
                });
        }
    };

    const sendPost = () => {
        setLoading(true);
        let params = {};
        let url;
        if (comment) {
            url = '/post/comment/create';
            params = {
                id_event: event.getJTOIdentifier(),
                content: message,
                id_post: postObj.getJTOIdentifier(),
            };
        } else {
            url = '/post/create';
            params = {
                id_event: event.getJTOIdentifier(),
                content: message,
            };
        }
        post(url, params)
            .then(async (res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    const eventList = JTOInstance.getAllJTOStateList();
                    const postList = JTOInstance.getAllJTOStateList();

                    for (const eventI of eventList) {
                        if (eventI instanceof Event) {
                            eventI.postList.preventBlock();
                        }
                    }
                    for (const postI of postList) {
                        if (postI instanceof Post) {
                            postI.commentList.preventBlock();
                        }
                    }

                    JTOInstance.applyResult(HTTPUtils.getResult(res), true);

                    for (const eventI of eventList) {
                        if (eventI instanceof Event) {
                            eventI.postList.remainBlock();
                        }
                    }
                    for (const postI of postList) {
                        if (postI instanceof Post) {
                            postI.commentList.remainBlock();
                        }
                    }

                    const idNewPost = HTTPUtils.getResult(res).p1[0]._id_str;
                    if (fileList.length > 0) {
                        await uploadFile(idNewPost);
                    }

                    goBackTo(
                        props.navigation,
                        [
                            'Tab',
                            'SubEventModal',
                            'PostCommentModal',
                            'PostEventPage',
                        ],
                        'PostModal',
                    );

                    ToastManager.instance.addToast(
                        new Toast(t('post_sent'), 5000, 'success'),
                    );
                } else {
                    setError(
                        t(HTTPUtils.getError(HTTPUtils.getError(res))) ?? '',
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(t('went_wrong_no_internet') ?? '');
            });
    };

    return (
        <View
            /*bounces={false}
            bouncesZoom={false}
            alwaysBounceHorizontal={false}
            alwaysBounceVertical={false}*/
            // @ts-ignore
            automaticallyAdjustKeyboardInsets={true}
            keyboardDismissMode={'on-drag'}
            style={[
                styles.page,
                {
                    backgroundColor: getColor('background'),
                    height: '100%',
                },
            ]}
        >
            <Header
                modal={true}
                line={1}
                navigation={props.navigation}
                backButton={true}
                title={comment ? t('new_comment') : t('new_post')}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
            >
                {loading ? (
                    <View
                        style={[
                            styles.boxCenter,
                            {
                                height: height() * 0.5,
                            },
                        ]}
                    >
                        <GeSpinner color={getColor('primary')} />
                    </View>
                ) : (
                    <View
                        style={[
                            styles.box,
                            {
                                paddingHorizontal: 20,
                                flex: 1,
                                marginTop: 20,
                                height: '100%',
                            },
                        ]}
                    >
                        <MpInput
                            value={message}
                            defaultValue={message}
                            onChangeText={(e) => {
                                setMessage(e);
                            }}
                            center={false}
                            style={styles.inputBig}
                            placeholder={t('post_message') ?? ''}
                            multiline={true}
                            numberOfLines={4}
                        />
                        <View
                            style={{
                                marginTop: 10,
                                flexDirection: 'row',
                            }}
                        >
                            <Pressable
                                onPress={async () => {
                                    setOpenModal(true);
                                }}
                            >
                                <PastilleView
                                    color={getColor('secondary')}
                                    size={45}
                                    icon={
                                        <DocumentIcon
                                            color={'white'}
                                            size={25}
                                        />
                                    }
                                />
                            </Pressable>
                        </View>

                        <View
                            style={{
                                marginTop: 10,
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                marginHorizontal: -5,
                            }}
                        >
                            {fileList.map((item, index) => {
                                let content;
                                if (
                                    item.type !== undefined &&
                                    item.type.split('/').length > 0 &&
                                    item.type.split('/')[0] === 'image'
                                ) {
                                    content = (
                                        <View key={'postfile' + item[3]}>
                                            <Image
                                                source={{
                                                    uri: URL.createObjectURL(
                                                        item,
                                                    ),
                                                }}
                                                style={{
                                                    margin: 5,
                                                    width: 100,
                                                    height: 100,
                                                    borderRadius: 20,
                                                    borderWidth: 1,
                                                    borderColor:
                                                        getColor('border'),
                                                }}
                                            />
                                            <Pressable
                                                style={{
                                                    position: 'absolute',
                                                    bottom: 12,
                                                    right: 12,
                                                }}
                                                onPress={async () => {
                                                    setFileList(
                                                        fileList.filter(
                                                            (elem) =>
                                                                elem.name !==
                                                                item.name,
                                                        ),
                                                    );
                                                }}
                                            >
                                                <PastilleView
                                                    size={35}
                                                    color={getColor('error')}
                                                    icon={
                                                        <TrashIcon
                                                            size={22}
                                                            color='white'
                                                        />
                                                    }
                                                />
                                            </Pressable>
                                        </View>
                                    );
                                } else {
                                    content = (
                                        <View key={'postfile' + item[3]}>
                                            <FileElementView
                                                filename={item[0]}
                                                fileuri={item[2]}
                                            />
                                            <Pressable
                                                style={{
                                                    position: 'absolute',
                                                    bottom: 12,
                                                    right: 12,
                                                }}
                                                onPress={async () => {
                                                    setFileList(
                                                        fileList.filter(
                                                            (elem) =>
                                                                elem[1] !==
                                                                item[1],
                                                        ),
                                                    );
                                                }}
                                            >
                                                <PastilleView
                                                    size={35}
                                                    color={getColor('error')}
                                                    icon={
                                                        <TrashIcon
                                                            size={22}
                                                            color='white'
                                                        />
                                                    }
                                                />
                                            </Pressable>
                                        </View>
                                    );
                                }
                                return content;
                            })}
                        </View>

                        {error !== '' && (
                            <Text
                                style={[
                                    styles.textError,
                                    {
                                        marginTop: 10,
                                    },
                                ]}
                            >
                                {error}
                            </Text>
                        )}

                        <View
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                        >
                            <CartoucheButton
                                onPress={() => {
                                    sendPost();
                                }}
                                title={t('send')}
                                height={80}
                            />
                        </View>
                    </View>
                )}
            </Header>

            <PostFileModal
                open={openModal}
                onClose={(files) => {
                    setOpenModal(false);
                    setFileList([...fileList, ...files]);
                }}
            />
        </View>
    );
};
