import { CommonActions } from '@react-navigation/native';
import { t } from 'i18next';
import { HTTPUtils } from 'mp-ts-http';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    View,
    Pressable,
    ScrollView,
    // @ts-ignore
    // @ts-ignore
    refreshControl,
    Text,
    StyleSheet,
} from 'react-native';
import React from 'react';
/*import {
    CodeField,
    Cursor,
    useBlurOnFulfill,
    useClearByFocusCell,
} from 'react-native-confirmation-code-field';*/
import {
    MagnifyingGlassIcon,
    FunnelIcon,
    TrashIcon,
} from 'react-native-heroicons/solid';
import { MpInput } from '../base/MpInput';
import { CartoucheButton } from '../component/CartoucheButton';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { HeaderSimplePage } from '../component/HeaderSimplePage';
import { InviteView } from '../component/InviteView';
import { PastilleView } from '../component/PastilleView';
import { Toast } from '../component/toast/Toast';
import { ToastManager } from '../component/toast/ToastManager';
import { IModalProps } from '../modal/IModalProps';
import User from '../object/User';
import Logo from '../svg/Logo';
import { post } from '../utils/http';
import {
    STATUS_BAR_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { styles } from '../utils/styles';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { getColor, FONT_BOLD } from '../utils/theme';
import { height, width, isCloseToBottom } from '../utils/utils';
import { nextLogin } from './LoginPage';

const stylesInput = StyleSheet.create({
    root: { flex: 1, padding: 20 },
    title: { textAlign: 'center', fontSize: 30 },
    codeFieldRoot: { marginTop: 20, marginBottom: 10 },
    cell: {
        width: 40,
        height: 40,
        lineHeight: 33,
        color: getColor('text'),
        fontSize: 24,
        borderWidth: 2,
        borderRadius: 10,
        borderColor: '#00000030',
        textAlign: 'center',
    },
    focusCell: {
        borderColor: '#000',
    },
});

export const ValidatePage = (props: IModalProps) => {
    const [code, setCode] = useState('');
    /*const ref = useBlurOnFulfill({ value: code, cellCount: 6 });
    const [propsInput, getCellOnLayoutHandler] = useClearByFocusCell({
        value: code,
        setValue: setCode,
    });*/
    const [message, setMessage] = useState<string>('');

    const { t: trans } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    const scrollRef = useRef<any>(null);

    const scrollToBottom = () => {
        /*scrollRef.current.scrollToEnd({ animated: true });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 50);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 100);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 150);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 200);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 250);*/
    };

    const onLogin = () => {
        setLoading(true);
        setMessage('');
        post(
            '/user/login_action',
            {
                codeConnexion: code,
                identifier: User._email,
            },
            true,
        )
            .then((response) => {
                if (HTTPUtils.isSuccess(response)) {
                    User._instance = new User();
                    const data = HTTPUtils.getResult(response);
                    User._instance?.login(data);

                    nextLogin(props.navigation, setMessage, setLoading, trans);
                } else {
                    setLoading(false);
                    setMessage(trans(HTTPUtils.getError(response)) ?? '');
                }
            })
            .catch((err) => {
                setLoading(false);
                setMessage(trans('went_wrong_no_internet') ?? '');
            });
    };

    return (
        <View
            style={{
                width: '100%',
                height: height(),
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                title={trans('code_page_title')}
                line={1}
                fallback={
                    <Logo
                        height={50}
                        width={50}
                    />
                }
                fallbackColor={'white'}
                onBack={() => {
                    props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'BeforeLogin' }],
                        }),
                    );
                    return true;
                }}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                logo={User._instance.getEvent()?.logo.get() ?? ''}
                backButton={true}
                navigation={props.navigation}
            >
                <View
                    ref={scrollRef}
                    // @ts-ignore
                    // @ts-ignore
                    automaticallyAdjustKeyboardInsets={true}
                >
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1,
                            marginRight: width() * 0.15,
                            marginLeft: width() * 0.15,
                            width: width() * 0.7,
                        }}
                    >
                        <HeaderSimplePage
                            color={getColor('ge')}
                            title={trans('code_page_title')}
                            description={trans('code_page_description')}
                        />

                        {loading ? (
                            <View style={[styles.boxCenter, marginTop(75)]}>
                                <GeSpinner color={getColor('ge')} />
                            </View>
                        ) : (
                            <View
                                style={[
                                    styles.box,
                                    {
                                        width: width(),
                                    },
                                ]}
                            >
                                <View
                                    style={{
                                        paddingHorizontal: width() * 0.1,
                                        paddingVertical: width() * 0.1,
                                    }}
                                >
                                    <MpInput
                                        autoCapitalize='none'
                                        value={code}
                                        onFocus={() => {
                                            // Scroll to bottom
                                            //scrollToBottom();
                                        }}
                                        onChangeText={(text) => {
                                            setCode(text);
                                        }}
                                        defaultValue={code}
                                        keyboardType={'numeric'}
                                        textContentType='password'
                                        placeholder={t('code') as string}
                                        style={styles.input}
                                        selectionColor={getColor('ge')}
                                    />

                                    {/*<CodeField
                                        {...props}
                                        // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
                                        value={code}
                                        onChangeText={setCode}
                                        cellCount={6}
                                        rootStyle={stylesInput.codeFieldRoot}
                                        keyboardType='number-pad'
                                        onFocus={() => {
                                            scrollToBottom();
                                        }}
                                        textContentType='oneTimeCode'
                                        renderCell={({
                                            index,
                                            symbol,
                                            isFocused,
                                        }) => (
                                            <Text
                                                key={index}
                                                style={[
                                                    stylesInput.cell,
                                                    isFocused &&
                                                        stylesInput.focusCell,
                                                ]}
                                                onLayout={getCellOnLayoutHandler(
                                                    index,
                                                )}
                                            >
                                                {symbol ||
                                                    (isFocused ? (
                                                        <Cursor />
                                                    ) : null)}
                                            </Text>
                                        )}
                                                    />*/}
                                    {message !== '' && (
                                        <Text
                                            style={[
                                                styles.textError,
                                                marginTop(25),
                                            ]}
                                        >
                                            {message}
                                        </Text>
                                    )}
                                </View>

                                <CartoucheButton
                                    onPress={() => {
                                        onLogin();
                                    }}
                                    color={getColor('ge')}
                                    title={trans('connection')}
                                    height={80}
                                />

                                <Pressable
                                    onPress={() => {
                                        post(
                                            '/user/login_no_password',
                                            {
                                                identifier: User._email,
                                            },
                                            true,
                                        ).then((res) => {
                                            setLoading(false);
                                            if (HTTPUtils.isSuccess(res)) {
                                                setMessage('');
                                                ToastManager.instance.addToast(
                                                    new Toast(
                                                        t('new_code_sent') ??
                                                            '',
                                                        5000,
                                                        'success',
                                                    ),
                                                );
                                            } else {
                                                setMessage(
                                                    t(
                                                        HTTPUtils.getError(res),
                                                    ) ?? '',
                                                );
                                            }
                                        });
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                textAlign: 'center',
                                                marginTop: 35,
                                                color: getColor('ge'),
                                            },
                                        ]}
                                    >
                                        {trans('resend_code')}
                                    </Text>
                                </Pressable>
                            </View>
                        )}
                    </View>
                </View>
            </Header>
        </View>
    );
};
