import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function Exhibition(props: any) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 120.9 120.89'
            {...props}
        >
            <defs></defs>
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M120.15 76.73c-6.98-13.01-16.8-23.31-29.63-30.62-13.93-7.94-28.74-10.51-44.44-6.45-10.88 2.81-20.32 8.31-28.63 15.79C10.19 61.98 4.2 69.51 0 78.39v1.89C4.12 89 9.98 96.42 17.07 102.89c8.44 7.68 18.05 13.34 29.18 16.13 3.22.8 6.56 1.13 9.85 1.68.35.06.69.13 1.03.2h6.84c.26-.07.52-.18.78-.2 4.38-.29 8.67-1.07 12.82-2.47 19.16-6.47 32.98-19.21 42.73-36.66.9-1.61.69-3.23-.17-4.84zm-9.8 3.25c-7.12 11.26-16.09 20.59-28.26 26.36-17.03 8.07-33.56 6.56-49.38-3.38-9.23-5.8-16.35-13.72-22.07-22.92-.21-.33-.25-1-.05-1.32 7.17-11.47 16.38-20.77 28.72-26.68 6.49-3.11 14.04-4.82 23.06-4.81 10.84.11 21.82 4.74 31.56 12.61 6.52 5.26 11.85 11.59 16.3 18.67.3.48.51.85.12 1.47zM65.17 5.09v18.16c0 2.97-1.99 5.08-4.73 5.08s-4.71-2.12-4.72-5.09v-9.2-8.96C55.73 2.11 57.71 0 60.45 0s4.71 2.11 4.72 5.09zM42.15 27.38c-.13 2.25-1.62 4.09-3.83 4.61-2.1.5-4.3-.54-5.26-2.62-1.38-2.95-2.67-5.94-4-8.92-1.05-2.37-2.12-4.73-3.15-7.11-1.16-2.66-.21-5.37 2.23-6.48 2.5-1.14 5.17-.03 6.39 2.67 2.33 5.19 4.65 10.39 6.95 15.6.31.7.45 1.48.68 2.23zM95.62 11.16c-.22.75-.36 1.53-.67 2.23-2.29 5.21-4.6 10.41-6.93 15.61-1.22 2.72-3.88 3.81-6.39 2.68-2.45-1.1-3.43-3.81-2.26-6.46 2.34-5.32 4.7-10.62 7.09-15.92.98-2.17 3.09-3.2 5.31-2.73 2.18.47 3.7 2.33 3.85 4.59zM120.63 29.36c-.07 1.4-.53 2.59-1.54 3.51-3.52 3.19-7.03 6.4-10.6 9.53-2.04 1.79-4.91 1.56-6.65-.4-1.73-1.95-1.59-4.82.42-6.66 3.48-3.18 6.99-6.32 10.51-9.45 1.53-1.36 3.32-1.56 5.16-.72 1.77.81 2.58 2.32 2.71 4.18zM19.02 42.03c-1.72 1.91-4.59 2.15-6.58.41-3.61-3.16-7.16-6.39-10.7-9.64-1.51-1.39-1.86-3.36-1.1-5.19.74-1.79 2.39-2.89 4.47-3.16 1 .48 2.16.77 2.97 1.46 3.61 3.11 7.14 6.31 10.64 9.53 1.94 1.79 2.01 4.68.29 6.59z'
                />
                <path
                    fill='currentColor'
                    d='M60.48 56.16c-12.82-.02-23.19 10.32-23.21 23.14-.02 12.82 10.32 23.19 23.14 23.21 12.82.02 23.19-10.32 23.21-23.14.02-12.83-10.32-23.19-23.14-23.21zm-.12 36.9c-7.57-.03-13.66-6.2-13.64-13.79.02-7.62 6.22-13.73 13.88-13.67 7.56.06 13.63 6.24 13.58 13.85-.05 7.57-6.22 13.65-13.82 13.61z'
                />
            </g>
        </svg>
    );
}

export default Exhibition;
