import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function Panel(props) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 141.98 120.38'
            {...props}
        >
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M29.77 75.41c-.15 13.34-.08 26.69-.04 40.03 0 1.59.31 3.19.49 4.91h-4.78c-4.06 0-8.13-.11-12.19.02-6.7.22-12.2-4.27-13.1-10.64-.01-.08-.1-.15-.15-.22V83.17c.6-2.02.95-4.16 1.84-6.04 3.42-7.27 9.18-11.45 17.24-12.03 4.03-.28 8.1-.05 12.26-.05-.09.39-.2.73-.25 1.08-.47 3.09-1.29 6.18-1.32 9.28zM103.97 115.61c0 3.21-1.53 4.74-4.75 4.74H42.83c-3.4 0-4.88-1.49-4.88-4.89 0-13.44-.03-26.87.01-40.31.02-10.07 6.34-18.05 16.08-20.33 1.47-.34 3.02-.47 4.53-.47 8.27-.05 16.53-.07 24.8-.01 9.91.07 17.86 6.42 20.09 16.02.37 1.6.49 3.29.5 4.94.04 13.44.02 26.88.02 40.32zM95.6 24.51c-.02 13.6-11.13 24.76-24.65 24.75-13.54-.01-24.63-11.16-24.64-24.76C46.31 11.04 57.5-.05 71.03 0c13.5.05 24.59 11.11 24.57 24.51zM141.9 107.98c-.08 6.62-4.78 11.91-11.2 12.23-6.31.31-12.64.07-18.79.07.11-3.08.29-6.07.3-9.05.03-11.41 0-22.82.02-34.22 0-3.85-.27-7.66-1.54-11.33-.06-.16-.04-.35-.07-.64.45-.03.85-.09 1.24-.09 3.74.03 7.5-.11 11.22.16 10.48.75 18.64 9.37 18.82 19.88.13 7.67.11 15.33.01 23zM46.09 41.18c.11 10.15-8.19 18.57-18.36 18.61-10.1.05-18.4-8.22-18.46-18.39-.06-10.1 8.22-18.4 18.38-18.43 10.12-.03 18.33 8.08 18.44 18.21zM132.65 41.33c.03 10.11-8.23 18.41-18.39 18.46-10.05.06-18.34-8.23-18.43-18.41-.08-10.1 8.19-18.4 18.37-18.41 10.16-.01 18.42 8.21 18.45 18.36z'
                    strokeWidth={0}
                />
            </g>
        </svg>
    );
}

export default Panel;
