import {
    HomeIcon as HomeIconSolid,
    UserIcon as UserIconSolid,
    CalendarDaysIcon as CalendarDaysIconSolid,
    NewspaperIcon as NewspaperIconSolid,
    ChatBubbleBottomCenterIcon as ChatBubbleBottomCenterIconSolid,
    ChatBubbleLeftIcon as ChatBubbleLeftIconSolid,
    ChatBubbleOvalLeftIcon as ChatBubbleOvalLeftIconSolid,
} from 'react-native-heroicons/solid';
import {
    HomeIcon,
    UserIcon,
    CalendarIcon,
    NewspaperIcon,
    ChatBubbleBottomCenterIcon,
    ChatBubbleLeftIcon,
    ChatBubbleOvalLeftIcon,
} from 'react-native-heroicons/outline';
import { HomePage } from '../pages/HomePage';
import { PlanningPage } from '../pages/PlanningPage';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NAVIGATION_BAR_HEIGHT, TAB_HEIGHT } from '../utils/screen';
import { FONT_BOLD, FONT_MEDIUM, getColor } from '../utils/theme';
import { NavBarSlot } from './NavBarSlot';
import { useTranslation } from 'react-i18next';
import { PostPage } from '../pages/PostPage';
import React, { useEffect, useRef, useState } from 'react';
import { ChatPage } from '../pages/ChatPage';
import { ProfilePage } from '../pages/ProfilePage';
import User from '../object/User';
import { getRestore, postRefresh } from '../utils/http';
import { HTTPUtils } from 'mp-ts-http';
//import { storage } from '../../App';
import { CommonActions } from '@react-navigation/native';
import { AppState, Linking, Text, View } from 'react-native';
import { height, width } from '../utils/utils';
import Event from '../object/Event';
import { GLOBAL_CONFIG } from '../utils/config';
import useJTOState from '../utils/jto/useJTOState';
import { applyResult } from '../utils/applyResult';
import EventTiny from '../object/compressed/event/EventTiny';

const Tab = createBottomTabNavigator();

export interface ITabNavigationProps {
    navigation: any;
}

export const TabNavigation = (props: ITabNavigationProps) => {
    const { t } = useTranslation();

    const event = useJTOState(User._instance.getEvent()!);

    const [make, setMake] = useState(false);
    const appState = useRef(AppState.currentState);

    useEffect(() => {
        postRefresh('/user/loginrefresh', {})
            .then((res) => {
                if (HTTPUtils.isSuccess(res)) {
                    User._instance?.login(HTTPUtils.getResult(res), false);
                    delete res.result.accessToken;
                    delete res.result.refreshToken;
                    localStorage.setItem('user', JSON.stringify(res.result));
                } else {
                    props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'BeforeLogin' }],
                        }),
                    );
                }
            })
            .catch((err) => {
                props.navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [{ name: 'BeforeLogin' }],
                    }),
                );
            });

        getRestore(
            '/user/event/get',
            { id: event.getJTOIdentifier() },
            (result: any) => {
                if (HTTPUtils.isSuccess(result)) {
                    applyResult(
                        HTTPUtils.getResult(result),
                        User._instance!,
                        true,
                    );
                    User._instance!.loadEvent();
                }
            },
            (err: any) => {
                // NO ERROR
            },
            () => {
                // TODO
            },
        );
        User._badgeList.reset();
        User._badgeList.refetch(
            {
                id_event: User._instance.getEvent()?.getJTOIdentifier() ?? '',
            },
            () => {
                User._badgeList.setComplite(true);
            },
        );
    }, []);

    const nextOrCurrentEvent: EventTiny[] = [];
    for (const subEvent of event.mySubEventList.getList()) {
        if (subEvent.isNow()) {
            nextOrCurrentEvent.push(subEvent);
        }
    }
    if (nextOrCurrentEvent.length === 0) {
        // get the closest event
        let subEventFound: EventTiny | null = null;
        for (const subEvent of event.mySubEventList.getList()) {
            if (subEvent.isAfterNow() && subEvent.isNotSondage()) {
                if (
                    subEvent.getBetterDate() !== '' &&
                    subEvent.getBetterDate() !== undefined &&
                    subEvent.getBetterDate() !== null
                ) {
                    if (subEventFound === null) {
                        subEventFound = subEvent;
                    } else {
                        if (
                            new Date(
                                subEventFound.getBetterDate() ?? '',
                            ).getTime() >
                            new Date(subEvent.getBetterDate() ?? '').getTime()
                        ) {
                            subEventFound = subEvent;
                        }
                    }
                }
            }
        }
        if (subEventFound !== null) {
            nextOrCurrentEvent.push(subEventFound);
        }
    }

    const [open, setOpen] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);

    useEffect(() => {
        if (nextOrCurrentEvent.length > 0 && !open) {
            setOpen(true);
            setTimeout(() => {
                /*storage.getBool(
                    'welcome' + event.getJTOIdentifier(),
                    (err, value) => {
                        if (
                            err ||
                            value === null ||
                            value === undefined ||
                            value === false
                        ) {
                            props.navigation.navigate('WelcomeModal', {});
                            storage.setBool(
                                'welcome' + event.getJTOIdentifier(),
                                true,
                            );
                        } else {
                            // props.navigation.navigate('WelcomeModal', {});
                        }
                    },
                );*/
            }, 500);
        }
    }, [nextOrCurrentEvent, open]);

    useEffect(() => {
        if (nextOrCurrentEvent.length > 0 && !openPassword) {
            setOpenPassword(true);
            if (
                (User._instance.passwordInit.get() ?? false) === false &&
                event.needPasswordForConnection.get() === true
            ) {
                props.navigation.navigate('PasswordModal', {});
            }
        }
    }, [nextOrCurrentEvent, openPassword]);

    const handleDeepLink = (deepLinkUrl: any) => {
        if (deepLinkUrl !== null && !make) {
            setMake(true);
            if (typeof deepLinkUrl === 'object') {
                deepLinkUrl = deepLinkUrl.url;
            }

            if (deepLinkUrl.includes('//action=event')) {
                const params = deepLinkUrl.replace('winteruniversity://', '');
                const eventId = params.split('id=')[1];

                const eventObject = new Event();
                eventObject.setJTOIdentifier(parseInt(eventId, 10));
                props.navigation.push('SubEventModal', {
                    event: eventObject,
                });
            }
        }
    };

    useEffect(() => {
        if (appState.current !== 'active') {
            setMake(false);
        }
    }, [appState]);

    useEffect(() => {
        // Add the event listener
        const sub = Linking.addEventListener('url', handleDeepLink);

        // Remove the event listener when the component is unmounted or paused
        return () => {
            try {
                sub.remove();
                Linking.removeSubscription(sub);
            } catch (err) {
                // NO ERROR
            }
        };
    }, [appState]);

    Linking.getInitialURL().then((res) => {
        handleDeepLink(res);
    });

    return (
        <View
            style={{
                display: 'flex',
                flex: 1,
                width: '100%',
                overflow: 'hidden',
                flexDirection: 'column',
            }}
        >
            <Tab.Navigator
                initialRouteName={'home'}
                sceneContainerStyle={{
                    display: 'flex',
                    flex: 1,
                    width: '100%',
                    overflow: 'hidden',
                    flexDirection: 'column',
                }}
                screenOptions={{
                    tabBarShowLabel: false,
                    tabBarStyle: {
                        borderTopColor: getColor('border'),
                        borderTopWidth: 1,
                        elevation: 0,
                        height: TAB_HEIGHT,
                        position: 'relative',
                        backgroundColor: getColor('card'),
                    },
                }}
            >
                <Tab.Screen
                    options={{
                        headerShown: false,
                        tabBarIcon: ({ focused }) => (
                            <NavBarSlot
                                focused={focused}
                                iconFocused={
                                    <CalendarDaysIconSolid
                                        color={getColor('primaryHint')}
                                    />
                                }
                                iconUnfocused={
                                    <CalendarIcon color={getColor('hint')} />
                                }
                                text={t('planning')}
                            />
                        ),
                        title: t('planning'),
                    }}
                    name={'planning'}
                    component={PlanningPage}
                />
                <Tab.Screen
                    options={{
                        headerShown: false,
                        tabBarIcon: ({ focused }) => (
                            <NavBarSlot
                                focused={focused}
                                iconFocused={
                                    <NewspaperIconSolid
                                        color={getColor('primaryHint')}
                                    />
                                }
                                iconUnfocused={
                                    <NewspaperIcon color={getColor('hint')} />
                                }
                                text={t('timeline')}
                            />
                        ),
                        title: t('timeline'),
                    }}
                    name={'mur'}
                    component={PostPage}
                />
                <Tab.Screen
                    options={{
                        headerShown: false,
                        tabBarIcon: ({ focused }) => (
                            <NavBarSlot
                                focused={focused}
                                iconFocused={
                                    <HomeIconSolid
                                        color={getColor('primaryHint')}
                                    />
                                }
                                iconUnfocused={
                                    <HomeIcon color={getColor('hint')} />
                                }
                                text={t('home')}
                            />
                        ),
                        title: t('home'),
                    }}
                    name={'home'}
                    component={HomePage}
                />
                {GLOBAL_CONFIG.message && (
                    <Tab.Screen
                        options={{
                            headerShown: false,
                            tabBarIcon: ({ focused }) => (
                                <NavBarSlot
                                    focused={focused}
                                    iconFocused={
                                        <ChatBubbleOvalLeftIconSolid
                                            color={getColor('primaryHint')}
                                        />
                                    }
                                    iconUnfocused={
                                        <ChatBubbleOvalLeftIcon
                                            color={getColor('hint')}
                                        />
                                    }
                                    badge={
                                        (User._instance.generalNewMessage.get() ??
                                            0) > 0
                                    }
                                    text={t('messages')}
                                />
                            ),
                            title: t('messages'),
                        }}
                        name={'messages'}
                        component={ChatPage}
                    />
                )}
                <Tab.Screen
                    options={{
                        headerShown: false,
                        tabBarIcon: ({ focused }) => (
                            <NavBarSlot
                                focused={focused}
                                iconFocused={
                                    <UserIconSolid
                                        color={getColor('primaryHint')}
                                    />
                                }
                                iconUnfocused={
                                    <UserIcon color={getColor('hint')} />
                                }
                                text={t('profile')}
                            />
                        ),
                        title: t('profile'),
                    }}
                    name={'profile'}
                    component={ProfilePage}
                />
            </Tab.Navigator>
        </View>
    );
};
