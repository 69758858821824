import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_MEDIUM, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Keychain from 'react-native-keychain';
import Post from '../object/Post';
import { useEffect } from 'react';
import User from '../object/User';
import { Header } from '../component/Header';
import { PastilleView } from '../component/PastilleView';
import { HeartIcon, TrashIcon, UserIcon } from 'react-native-heroicons/solid';
import { CAN_COEUR, POST_DELETE } from '../object/PermissionEvent';
import useJTOState from '../utils/jto/useJTOState';

export const MenuPostModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const post = useJTOState((props as any).route.params.post as Post);

    const actionList = [
        {
            label: 'see_profile',
            color: getColor('primary'),
            icon: (
                <UserIcon
                    color={'white'}
                    size={22}
                />
            ),
            onClick: (navigation: any) => {
                if (
                    post.inivite.get()?.user.get() !== null &&
                    post.inivite.get()?.user.get() !== undefined
                ) {
                    props.navigation.push('ProfilePage', {
                        user:
                            post.inivite.get()?.user.get()?.toUser() ??
                            new User(),
                    });
                }
            },
        },
    ];
    if (
        (post.inivite.get()?.user.get()?.isMe() ?? false) ||
        (User._instance.getEvent()?.hasPermission(POST_DELETE) ?? false)
    ) {
        actionList.push({
            label: 'delete',
            color: getColor('error'),
            icon: (
                <TrashIcon
                    color={'white'}
                    size={22}
                />
            ),
            onClick: (navigation: any) => {
                navigation.navigate('PostDeleteModal', {
                    post,
                });
            },
        });
    }
    if (
        (User._instance.getEvent()?.hasPermission(CAN_COEUR) ?? false) &&
        !post.isFavorite.get()
    ) {
        actionList.push({
            label: 'favorites',
            color: getColor('error'),
            icon: (
                <HeartIcon
                    color={'white'}
                    size={21}
                />
            ),
            onClick: (navigation: any) => {
                navigation.navigate('PostFavoriteModal', {
                    post,
                });
            },
        });
    }
    if (
        (User._instance.getEvent()?.hasPermission(CAN_COEUR) ?? false) &&
        post.isFavorite.get()
    ) {
        actionList.push({
            label: 'remove_favorites',
            color: getColor('error'),
            icon: (
                <HeartIcon
                    color={'white'}
                    size={21}
                />
            ),
            onClick: (navigation: any) => {
                navigation.navigate('PostRemoveFavoriteModal', {
                    post,
                });
            },
        });
    }

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('profile.post')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: 15,
                    }}
                >
                    {actionList.map((action, index) => {
                        return (
                            <Pressable
                                key={index}
                                style={[
                                    index !== 0 && {
                                        borderTopWidth: 1,
                                        borderColor: getColor('border'),
                                    },
                                    {
                                        flexDirection: 'row',
                                        marginHorizontal: 20,
                                        padding: 10,
                                        alignItems: 'center',
                                        backgroundColor: getColor('card'),
                                    },
                                    index === 0 && {
                                        borderTopLeftRadius: 15,
                                        borderTopRightRadius: 15,
                                    },
                                    index === actionList.length - 1 && {
                                        borderBottomLeftRadius: 15,
                                        borderBottomRightRadius: 15,
                                    },
                                ]}
                                onPress={(e) => {
                                    e.stopPropagation();
                                    action.onClick(props.navigation);
                                }}
                            >
                                <PastilleView
                                    size={32}
                                    color={getColor('secondary')}
                                    icon={action.icon}
                                />
                                <Text
                                    adjustsFontSizeToFit
                                    style={[
                                        {
                                            marginLeft: 10,
                                            color: 'black',
                                            fontFamily: FONT_MEDIUM,
                                            fontSize: 16,
                                            flex: 1,
                                        },
                                    ]}
                                >
                                    {t(action.label)}
                                </Text>
                            </Pressable>
                        );
                    })}
                </View>
            </Header>
        </View>
    );
};
