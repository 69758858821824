import {
    View,
    Text,
    Pressable,
    TextInput,
    ActivityIndicator,
    Image,
    Platform,
    PermissionsAndroid,
    Alert,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import {
    fileToBase64,
    getOrientationFromUri,
    goBackTo,
    height,
    resizeAndFixRotation,
    width,
} from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Keychain from 'react-native-keychain';
import { MpInput } from '../base/MpInput';
import { useState } from 'react';
import User from '../object/User';
import {
    CameraIcon,
    PhotoIcon,
    TrashIcon,
    XMarkIcon,
} from 'react-native-heroicons/solid';
import { post } from '../utils/http';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import { GeSpinner } from '../component/GeSpinner';
//import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
//import RNFS from 'react-native-fs';
import { PastilleView } from '../component/PastilleView';
import ChatGroup from '../object/ChatGroup';
import JTOInstance from '../utils/jto/JTOInstance';
import useJTOState from '../utils/jto/useJTOState';
import { isImageFile } from '../component/message/BottomMessagePage';
import { PostFileModal } from '../component/modal/PostFileModal';

export const GroupPictureModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const group = useJTOState((props as any).route.params.group as ChatGroup);

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [openModal, setOpenModal] = useState(false);

    const [file, setFile] = useState<File | null>(null);

    /*const openImageLib = async () => {
        const result = await launchImageLibrary({
            mediaType: 'photo',
        });
        if ((result.assets?.length ?? 0) > 0) {
            const asset = result.assets![0];
            setImageUrl(asset.uri ?? '');

            let uri = asset.uri ?? '';

            if (Platform.OS === 'ios') {
                uri = decodeURIComponent(asset.uri ?? '');
            }

            let fileData: any;
            if (isImageFile(uri)) {
                try {
                    const rotation = await getOrientationFromUri(uri);
                    const fixedImageUri = await resizeAndFixRotation(
                        uri,
                        rotation,
                    );
                    fileData = await RNFS.readFile(fixedImageUri, 'base64');
                } catch (e) {
                    fileData = await RNFS.readFile(uri, 'base64');
                }
            } else {
                fileData = await RNFS.readFile(uri, 'base64');
            }

            setImageType(asset.type ?? '');
            setImageData(fileData);
        }
    };

    const openCamera = async () => {
        const grandted =
            Platform.OS === 'ios' ||
            (await PermissionsAndroid.request(
                PermissionsAndroid.PERMISSIONS.CAMERA,
                {
                    title: t('camera_permission_button_title') ?? '',
                    message: t('camera_permission_button_message') ?? '',
                    buttonPositive: t('camera_permission_button_ok') ?? '',
                    buttonNegative: t('camera_permission_button_cancel') ?? '',
                    buttonNeutral: t('camera_permission_button_neutral') ?? '',
                },
            ));
        if (!grandted) {
            Alert.alert(t('camera_permission_refused'));
        } else {
            const result = await launchCamera({
                mediaType: 'photo',
            });
            if ((result.assets?.length ?? 0) > 0) {
                const asset = result.assets![0];
                setImageUrl(asset.uri ?? '');

                let uri = asset.uri ?? '';

                if (Platform.OS === 'ios') {
                    uri = decodeURIComponent(asset.uri ?? '');
                }

                let fileData: any;
                if (isImageFile(uri)) {
                    try {
                        const rotation = await getOrientationFromUri(uri);
                        const fixedImageUri = await resizeAndFixRotation(
                            uri,
                            rotation,
                        );
                        fileData = await RNFS.readFile(fixedImageUri, 'base64');
                    } catch (e) {
                        fileData = await RNFS.readFile(uri, 'base64');
                    }
                } else {
                    fileData = await RNFS.readFile(uri, 'base64');
                }
                setImageType(asset.type ?? '');
                setImageData(fileData);
            }
        }
    };
*/
    const changeFirstname = async () => {
        setLoading(true);
        setError('');
        if (file == null || file == undefined) {
            setError('select_profile_picture');
            setLoading(false);
            return;
        }

        let file_data = await fileToBase64(file);
        post('/mobile/chatgroup/upload', {
            id_group: group.getJTOIdentifier(),
            data: file_data,
            type: file?.type,
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    JTOInstance.applyResult(HTTPUtils.getResult(res), true);

                    goBackTo(
                        props.navigation,
                        'MessagePage',
                        'GroupPictureModal',
                    );

                    ToastManager.instance.addToast(
                        new Toast(t('group_edit_success'), 5000, 'success'),
                    );
                } else {
                    setError(t(HTTPUtils.getError(res)) ?? '');
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(t('went_wrong_no_internet') ?? '');
            });
    };

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('picture')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginHorizontal: 20,
                    }}
                >
                    {loading ? (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    height: height() * 0.5,
                                },
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View>
                            {file ? (
                                <View
                                    style={{
                                        position: 'relative',
                                        marginTop: 15,
                                        width: '100%',
                                        height: width() * 0.5,
                                        marginBottom: 15,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Image
                                        source={{
                                            uri: URL.createObjectURL(file),
                                        }}
                                        style={{
                                            width: '100%',
                                            height: width() * 0.5,
                                            borderRadius: 15,
                                            borderColor: getColor('border'),
                                            borderWidth: 1,
                                            position: 'absolute',
                                        }}
                                    />

                                    <View
                                        style={{
                                            position: 'absolute',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '95%',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Pressable
                                            style={{
                                                bottom: 8,
                                            }}
                                            onPress={async () => {
                                                setFile(null);
                                            }}
                                        >
                                            <PastilleView
                                                color={getColor('error')}
                                                size={45}
                                                icon={
                                                    <TrashIcon
                                                        size={30}
                                                        color='white'
                                                    />
                                                }
                                            />
                                        </Pressable>
                                        <Pressable
                                            style={[
                                                {
                                                    backgroundColor:
                                                        getColor('secondary'),
                                                    paddingVertical: 10,
                                                    paddingHorizontal: 14,
                                                    borderRadius: 10,
                                                },
                                                { bottom: 10 },
                                            ]}
                                            onPress={async () => {
                                                setOpenModal(true);
                                            }}
                                        >
                                            <Text style={styles.buttonText}>
                                                {t('choose')}
                                            </Text>
                                        </Pressable>
                                    </View>
                                </View>
                            ) : (
                                <View
                                    style={{
                                        position: 'relative',
                                        marginTop: 15,
                                        width: '100%',
                                        borderRadius: 15,
                                        borderColor: getColor('border'),
                                        borderWidth: 1,
                                        height: width() * 0.5,
                                        marginBottom: 15,
                                        backgroundColor: getColor('card'),
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: getColor('hint'),
                                            padding: 10,
                                        }}
                                    >
                                        {t('select_profile_picture')}
                                    </Text>

                                    <Pressable
                                        style={styles.button}
                                        onPress={async () => {
                                            setOpenModal(true);
                                        }}
                                    >
                                        <Text style={styles.buttonText}>
                                            {t('choose')}
                                        </Text>
                                    </Pressable>
                                </View>
                            )}

                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.textError,
                                        {
                                            marginTop: 10,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}

                            <View
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <CartoucheButton
                                    onPress={() => {
                                        changeFirstname();
                                    }}
                                    title={t('change')}
                                    height={80}
                                />
                            </View>
                        </View>
                    )}
                    <PostFileModal
                        open={openModal}
                        onClose={(files) => {
                            setOpenModal(false);
                            setFile(files[0]);
                        }}
                        single={true}
                    />
                </View>
            </Header>
        </View>
    );
};
