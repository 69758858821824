import { Dimensions, Platform, StatusBar } from 'react-native';
import React from 'react';
import { EdgeInsets } from 'react-native-safe-area-context';
import { width } from './utils';

export const SCREEN_HEIGHT = Dimensions.get('screen').height; // device height
export let STATUS_BAR_HEIGHT = StatusBar.currentHeight || 24;
export const WINDOW_HEIGHT = Dimensions.get('window').height;
export let NAVIGATION_BAR_HEIGHT = 0;
if (Platform.OS === 'web') {
    NAVIGATION_BAR_HEIGHT = 0;
    STATUS_BAR_HEIGHT = 10;
} else if (Platform.OS === 'ios') {
    NAVIGATION_BAR_HEIGHT = 0;
} else {
    NAVIGATION_BAR_HEIGHT = SCREEN_HEIGHT - WINDOW_HEIGHT - STATUS_BAR_HEIGHT; // navigation bar height
}

export const TAB_HEIGHT = 80;

export const initScreen = (inset: EdgeInsets) => {
    if (Platform.OS === 'ios') {
        NAVIGATION_BAR_HEIGHT = inset.bottom;
        STATUS_BAR_HEIGHT = inset.top;
    }
};

export const HEADER_BONUS_HEIGHT = width() * (3 / 90);
