import { Linking, Pressable, ScrollView, Text, View } from 'react-native';
import User from '../object/User';

import { styles } from '../utils/styles';
import { useEffect, useState } from 'react';
import { HTTPUtils } from 'mp-ts-http';
import { getErrorMessage } from 'mp-ts-error';
import Event from '../object/Event';
import { Image } from 'react-native';
import React from 'react';
import {
    FONT_BOLD,
    FONT_MEDIUM,
    FONT_REGULAR,
    HEART_COLOR,
    getColor,
} from '../utils/theme';
import { EventTopPresentation } from '../component/EventTopPresentation';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import {
    CalendarDaysIcon,
    EnvelopeIcon,
    HeartIcon,
    PhoneIcon,
    UserIcon,
} from 'react-native-heroicons/solid';
import { TabNavigation } from '../component/TabNavigation';
import { Header } from '../component/Header';
import { t } from 'i18next';
import { ContactView } from '../component/ContactView';
import { GeSpinner } from '../component/GeSpinner';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import Contact from '../object/Contact';
import { PastilleView } from '../component/PastilleView';
import { height, width } from '../utils/utils';
import Bonheur from '../svg/Bonheur';
import { getRestore } from '../utils/http';
import useJTOState from '../utils/jto/useJTOState';
import { applyResult } from '../utils/applyResult';
import EventTiny from '../object/compressed/event/EventTiny';
import { CartoucheButton } from '../component/CartoucheButton';
import InterestPointTiny from '../object/compressed/event/InterestPointTiny';
import JTOListFetch from '../utils/jto/JTOListFetch';
import Post from '../object/Post';
import { FavoriteItem } from '../component/FavoriteItem';

export const FavoritePage = (props: any) => {
    const event = useJTOState(User._instance.getEvent()!);

    const favoriteList = useJTOState(
        new JTOListFetch<EventTiny | Post>('/event/favorite/list', [
            EventTiny,
            Post,
        ]),
    );

    useEffect(() => {
        favoriteList.refetch({
            id: event.getJTOIdentifier(),
        });
    }, []);

    return (
        <View
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                backButton={true}
                title={t('favorites')}
                line={1}
                navigation={props.navigation}
                logo={event.logo.get() ?? ''}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
            >
                {favoriteList.isLoading() ? (
                    <View
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: NAVIGATION_BAR_HEIGHT + TAB_HEIGHT,
                            justifyContent: 'center',
                        }}
                    >
                        <GeSpinner color={getColor('primary')} />
                    </View>
                ) : favoriteList.getList().length == 0 ? (
                    <View
                        style={{
                            flex: 1,
                            zIndex: 500,
                        }}
                        // @ts-ignore
                        showsHorizontalScrollIndicator={false}
                        // @ts-ignore
                        showsVerticalScrollIndicator={false}
                    >
                        <View
                            style={{
                                height: 30,
                            }}
                        ></View>

                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingHorizontal: 20,
                                height:
                                    height() -
                                    TAB_HEIGHT -
                                    STATUS_BAR_HEIGHT -
                                    NAVIGATION_BAR_HEIGHT -
                                    150,
                                backgroundColor: getColor('background'),
                            }}
                        >
                            <Text
                                style={{
                                    color: getColor('text'),
                                    fontSize: 16,
                                    fontFamily: FONT_REGULAR,
                                    textAlign: 'center',
                                }}
                            >
                                {t('no_favorite')}
                            </Text>
                        </View>
                    </View>
                ) : (
                    <View
                        style={{
                            flex: 1,
                            marginTop: -30,
                            zIndex: 500,
                        }}
                        // @ts-ignore
                        showsHorizontalScrollIndicator={false}
                        // @ts-ignore
                        showsVerticalScrollIndicator={false}
                    >
                        <View
                            style={{
                                height: 60,
                            }}
                        ></View>

                        {favoriteList.getList().length > 0 && (
                            <View
                                style={{
                                    marginHorizontal: 0,
                                    marginBottom: 10,
                                }}
                            >
                                <View>
                                    {favoriteList
                                        .getList()
                                        .map((item, index) => {
                                            return (
                                                <View
                                                    style={{
                                                        marginBottom: 20,
                                                    }}
                                                >
                                                    <FavoriteItem
                                                        key={index}
                                                        item={item}
                                                        navigation={
                                                            props.navigation
                                                        }
                                                    />
                                                </View>
                                            );
                                        })}
                                </View>
                            </View>
                        )}

                        <View
                            style={{
                                height: STATUS_BAR_HEIGHT,
                            }}
                        />
                    </View>
                )}
            </Header>
        </View>
    );
};
function useJTOList<T>(arg0: never[]): [any, any] {
    throw new Error('Function not implemented.');
}
