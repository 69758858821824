import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function Signing(props) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 121.98 121.9'
            {...props}
        >
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M121.98 47.45v3.57c-.31 1.05-.51 2.16-.95 3.16-.54 1.22-1.1 2.53-1.99 3.48-2.56 2.76-5.12 5.58-8 7.98-5.17 4.32-12.71 3.95-17.62-.69-3.09-2.91-6.03-5.98-9.04-8.98-8.79-8.78-17.57-17.56-26.35-26.35-6.22-6.22-6.21-14.32 0-20.54 1.63-1.63 3.25-3.27 4.89-4.88 5.64-5.54 13.89-5.64 19.52-.06 11.89 11.79 23.7 23.67 35.54 35.52 2.05 2.05 3.33 4.51 3.81 7.38.02.14.13.27.2.41zM94.18 73.92c-.23.5-.4.89-.59 1.27-4.16 8.16-8.33 16.31-12.47 24.48-4.25 8.38-11.05 13.09-20.32 14.5-11.76 1.79-23.5 3.73-35.25 5.61-4.07.65-8.13 1.46-12.22 1.9-2.35.25-4.81.54-7.26-.58.33-.37.58-.67.84-.93 13.42-13.42 26.84-26.84 40.24-40.27.58-.58.99-.73 1.8-.35 4.88 2.33 10.72 1.28 14.64-2.56 3.86-3.78 5-9.67 2.83-14.62-2.16-4.95-7.17-8.08-12.53-7.84-9.55.43-15.39 10.14-11.31 18.81.1.21.18.43.2.47L.85 115.74c-.8-1.77-1.03-3.71-.73-5.69 1.24-8.03 2.52-16.06 3.79-24.09 1.38-8.69 2.74-17.39 4.19-26.08 1.35-8.04 5.57-14.27 12.72-18.14 8.82-4.77 17.84-9.17 26.78-13.72.1-.05.22-.07.49-.16 1.5 3.61 4.22 6.18 6.86 8.82 9.75 9.73 19.48 19.49 29.23 29.22 3.03 3.02 5.83 6.32 10 8.01z'
                    strokeWidth={0}
                />
            </g>
        </svg>
    );
}

export default Signing;
