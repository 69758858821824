import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
/*import Animated, {
    Easing,
    interpolate,
    interpolateColor,
    runOnJS,
    useAnimatedStyle,
    useSharedValue,
    withRepeat,
    withTiming,
} from 'react-native-reanimated';*/
import { getColor } from '../utils/theme';

export const ProgressLine = ({ progressDuration = 60000 }) => {
    const [width, setWidth] = useState(0);
    const [first, setFirst] = useState(true);
    //const animationValueFirst = useSharedValue(0);
    //const animationValueAfter = useSharedValue(0);

    // Get the current second of the minute
    const currentSecond = new Date().getSeconds();

    // Calculate the initial progress based on the current second
    const initialProgress = 1 - currentSecond / 60;

    useEffect(() => {
        /*animationValueFirst.value = withTiming(
            1,
            {
                duration: progressDuration * initialProgress,
                easing: Easing.linear,
            },
            (finished) => {
                if (finished) {
                    animationValueAfter.value = withRepeat(
                        withTiming(1, {
                            duration: progressDuration,
                            easing: Easing.linear,
                        }),
                        -1,
                    );
                    runOnJS(setFirst)(false);
                }
            },
        );*/
    }, [/*animationValueFirst, */ progressDuration]);

    const onLayout = (event: any) => {
        setWidth(event.nativeEvent.layout.width);
    };

    const successColor = getColor('success');
    const errorColor = getColor('error');

    // FIRST
    /*const animatedStyleFirst = useAnimatedStyle(() => {
        const backgroundColor = interpolateColor(
            animationValueFirst.value,
            [0, 1],
            [successColor, errorColor],
        );

        const progressWidth = interpolate(
            animationValueFirst.value,
            [0, 1],
            [width * initialProgress, 0],
        );

        return {
            width: progressWidth,
            backgroundColor,
        };
    });

    // AFTER
    const animatedStyleAfter = useAnimatedStyle(() => {
        const backgroundColor = interpolateColor(
            animationValueAfter.value,
            [0, 1],
            [successColor, errorColor],
        );

        const progressWidth = interpolate(
            animationValueAfter.value,
            [0, 1],
            [width, 0],
        );

        return {
            width: progressWidth,
            backgroundColor,
        };
    });*/

    return (
        <View
            style={styles.container}
            onLayout={onLayout}
        >
            {/*<Animated.View
                style={[
                    styles.progress,
                    first ? animatedStyleFirst : animatedStyleAfter,
                ]}
            />*/}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        height: 5,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        borderRadius: 20,
    },
    progress: {
        height: '100%',
    },
});
