import { HTTPUtils } from 'mp-ts-http';

import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

import { getRestore, post } from '../utils/http';
import Answer from './Answer';
import InviteTiny from './compressed/global/InviteTiny';
import Contact from './Contact';
import EventType from './EventType';
import File from './File';
import Group from './Group';
import Invite from './Invite';
import JTOListFetch from './JTOListFetch';
import OpenQuestion from './OpenQuestion';
import Post from './Post';
import Question from './Question';
import User from './User';
import Response from './Response';
import DirectQuestion from './DirectQuestion';
import { applyResult } from '../utils/applyResult';
import EventTiny, { compareEventTiny } from './compressed/event/EventTiny';
import InterestType from './InterestType';
import InterestPointTiny from './compressed/event/InterestPointTiny';
import Stand from './Stand';
import FormRegistration from './FormRegistration';

export const compareEvent = (a: Event, b: Event, reverse: number = 1) => {
    if (
        a.startDatetime.get() === undefined &&
        b.startDatetime.get() === undefined &&
        a.endDatetime.get() === undefined &&
        b.endDatetime.get() === undefined
    ) {
        return 0 * reverse;
    }
    if (
        a.startDatetime.get() === null &&
        b.startDatetime.get() === null &&
        a.endDatetime.get() === null &&
        b.endDatetime.get() === null
    ) {
        return 0 * reverse;
    }

    if (
        a.startDatetime.get() === undefined &&
        a.endDatetime.get() === undefined
    )
        return 1 * reverse;
    if (
        b.startDatetime.get() === undefined &&
        b.endDatetime.get() === undefined
    )
        return 1 * reverse;

    if (a.startDatetime.get() === null && a.endDatetime.get() === null)
        return -1 * reverse;
    if (b.startDatetime.get() === null && b.endDatetime.get() === null)
        return -1 * reverse;

    let aDate: any;
    let bDate: any;

    if (a.startDatetime.get() !== undefined && a.startDatetime.get() !== null) {
        aDate = a.startDatetime.get();
    } else {
        aDate = a.endDatetime.get();
    }

    if (b.startDatetime.get() !== undefined && b.startDatetime.get() !== null) {
        bDate = b.startDatetime.get();
    } else {
        bDate = b.endDatetime.get();
    }

    return aDate.localeCompare(bDate) * reverse;
};

export default class Event extends JTOObject {
    public static _TYPE: string = 'Event';

    public static _instance: Event | null = null;

    public name: JTOProperty<string>;
    public intervenant: JTOProperty<string>;
    public logo: JTOProperty<string>;
    public description: JTOProperty<string>;

    public primaryColor: JTOProperty<string>;
    public secondaryColor: JTOProperty<string>;
    public cardColor: JTOProperty<string>;
    public borderColor: JTOProperty<string>;
    public successColor: JTOProperty<string>;
    public errorColor: JTOProperty<string>;
    public warningColor: JTOProperty<string>;
    public infoColor: JTOProperty<string>;

    public root: JTOProperty<boolean>;

    public duration: JTOProperty<number>;
    public registrable: JTOProperty<boolean>;

    public longitude: JTOProperty<string>;
    public video: JTOProperty<string>;
    public latitude: JTOProperty<string>;

    public fileList: JTOList<File>;
    public advertList: JTOList<File>;

    public postList: JTOListFetch<Post>;
    public inviteList: JTOList<Invite>;
    public hasRegisterWant: JTOListFetch<InviteTiny>;
    public trombinoscopeList: JTOListFetch<InviteTiny>;
    public levelList: JTOListFetch<InviteTiny>;
    public groupList: JTOListFetch<Group>;

    public subEventList: JTOList<EventTiny>;
    public mySubEventList: JTOListFetch<EventTiny>;
    public contactList: JTOList<Contact>;

    public cgv: JTOList<File>;

    public eventType: JTOSimple<EventType>;
    public dirigeant: JTOSimple<User>;
    public hasVote: JTOSimple<Invite>;
    public hasRegister: JTOSimple<Invite>;
    public hasResponse: JTOSimple<Response>;

    public startDatetime: JTOProperty<string>;
    public endDatetime: JTOProperty<string>;

    public nameAddress: JTOProperty<string>;
    public eventTypeAlias: JTOProperty<string>;
    public address: JTOProperty<string>;
    public zipcode: JTOProperty<string>;
    public city: JTOProperty<string>;
    public country: JTOProperty<string>;
    public defaultPasswordForConnection: JTOProperty<string>;
    public needPasswordForConnection: JTOProperty<boolean>;

    public showMail: JTOProperty<boolean>;

    public word: JTOProperty<string>;
    public wordName: JTOProperty<string>;
    public questionList: JTOList<Question>;
    public directQuestionList: JTOList<DirectQuestion>;
    public questionRealTimeList: JTOList<OpenQuestion | Question>;
    public hasRealTimeStarted: JTOProperty<boolean>;
    public hasRealTimeEnded: JTOProperty<boolean>;
    public currentQuestion: JTOSimple<OpenQuestion>;
    public questionCurrentRealTime: number | undefined;
    public currentDirectQuestion: JTOSimple<DirectQuestion>;
    public questionCurrent: number;
    public myScore: number;

    private _paidEvent: JTOProperty<boolean>;
    private _price: JTOProperty<number>;
    private _codeGSM: JTOProperty<string>;

    public stands: JTOListFetch<Stand>;

    public dateFavorite: JTOProperty<string>;

    public isFavorite: JTOProperty<boolean>;

    public typeAlias: JTOProperty<string>;

    public interestType: JTOSimple<InterestType>;
    public form: JTOSimple<FormRegistration>;

    public isInterest: JTOProperty<boolean>;

    public interestList: JTOListFetch<InterestPointTiny>;

    public hasInterest: JTOProperty<boolean>;

    public hasFavorite: JTOProperty<boolean>;

    constructor(parent?: JTOElement | null) {
        super(Event._TYPE, parent);

        this.hasInterest = new JTOProperty<boolean>('has_interest', this);

        this.intervenant = new JTOProperty<string>('intervenant', this);

        this.hasFavorite = new JTOProperty<boolean>('has_favorite', this);

        this.typeAlias = new JTOProperty<string>('type_alias', this);
        this.interestType = new JTOSimple<InterestType>(
            InterestType,
            'has_interest_type',
            this,
        );

        this._paidEvent = new JTOProperty<boolean>('paid_event', this);
        this._price = new JTOProperty<number>('price', this);
        this._codeGSM = new JTOProperty<string>('code_gsm', this);
        this.form = new JTOSimple<FormRegistration>(
            FormRegistration,
            'has_form_registration',
            this,
        );

        this.stands = new JTOListFetch<Stand>(
            '/event/exposant/list',
            Stand,
            'has_stand',
            this,
            (a: Stand, b: Stand) => a.standName.localeCompare(b.standName),
        );

        this.isInterest = new JTOProperty<boolean>('is_interest', this);

        this.cgv = new JTOList<File>(File, 'has_cgv', this);

        this.isFavorite = new JTOProperty<boolean>('is_favorite', this);

        this.eventTypeAlias = new JTOProperty<string>('eventtype_alias', this);
        this.nameAddress = new JTOProperty<string>('name_address', this);
        this.address = new JTOProperty<string>('address', this);
        this.zipcode = new JTOProperty<string>('zipcode', this);
        this.city = new JTOProperty<string>('city', this);
        this.country = new JTOProperty<string>('country', this);

        this.name = new JTOProperty<string>('name', this);
        this.logo = new JTOProperty<string>('logo', this);

        this.primaryColor = new JTOProperty<string>('primary_color', this);
        this.secondaryColor = new JTOProperty<string>('secondary_color', this);
        this.cardColor = new JTOProperty<string>('card_color', this);
        this.borderColor = new JTOProperty<string>('border_color', this);
        this.successColor = new JTOProperty<string>('success_color', this);
        this.errorColor = new JTOProperty<string>('error_color', this);
        this.warningColor = new JTOProperty<string>('warning_color', this);
        this.infoColor = new JTOProperty<string>('info_color', this);

        this.description = new JTOProperty<string>('description', this);

        this.defaultPasswordForConnection = new JTOProperty<string>(
            'default_password_for_connection',
            this,
        );
        this.needPasswordForConnection = new JTOProperty<boolean>(
            'need_password_for_connection',
            this,
        );

        this.startDatetime = new JTOProperty<string>('start_datetime', this);
        this.endDatetime = new JTOProperty<string>('end_datetime', this);

        this.root = new JTOProperty<boolean>('root', this);

        this.duration = new JTOProperty<number>('duration', this);
        this.registrable = new JTOProperty<boolean>('registrable', this);

        this.longitude = new JTOProperty<string>('longitude', this);
        this.latitude = new JTOProperty<string>('latitude', this);
        this.video = new JTOProperty<string>('video', this);

        this.hasVote = new JTOSimple<Invite>(Invite, 'has_vote', this);
        this.hasRegister = new JTOSimple<Invite>(Invite, 'has_register', this);

        this.hasResponse = new JTOSimple<Response>(
            Response,
            'has_response',
            this,
        );

        this.showMail = new JTOProperty('show_mail', this);

        this.word = new JTOProperty('word', this);
        this.wordName = new JTOProperty('word_name', this);
        this.dirigeant = new JTOSimple(User, 'has_dirigeant', this);

        this.postList = new JTOListFetch<Post>(
            '/post/list',
            Post,
            'got_post',
            this,
            (a: Post, b: Post) =>
                b.date.get()?.localeCompare(a.date.get() ?? '') ?? 0,
        );
        this.inviteList = new JTOList<Invite>(Invite, 'has_invite', this);
        this.hasRegisterWant = new JTOListFetch<InviteTiny>(
            '/event/register/listwant',
            InviteTiny,
            'has_register',
            this,
        );
        this.trombinoscopeList = new JTOListFetch<InviteTiny>(
            '/event/trombinoscope/list',
            InviteTiny,
            'has_trombinoscope',
            this,
        );
        this.levelList = new JTOListFetch<InviteTiny>(
            '/event/level',
            InviteTiny,
            'has_invite',
            this,
        );
        this.groupList = new JTOListFetch<Group>(
            '/group/list',
            Group,
            'has_group',
            this,
        );
        this.fileList = new JTOList<File>(File, 'has_file', this);
        this.advertList = new JTOList<File>(
            File,
            'has_advert',
            this,
            (a: File, b: File) => {
                return (a.position.get() ?? 0) - (b.position.get() ?? 0);
            },
        );
        this.subEventList = new JTOList<EventTiny>(
            EventTiny,
            'has_sub_event',
            this,
            (a, b) => {
                return compareEventTiny(a, b, 1);
            },
        );

        this.interestList = new JTOListFetch<InterestPointTiny>(
            '/event/interest/list',
            InterestPointTiny,
            'has_sub_event',
            this,
        );

        this.mySubEventList = new JTOListFetch<EventTiny>(
            '/event/invite/planning',
            EventTiny,
            'has_sub_event',
            this,
            (a, b) => {
                if (
                    a.startDatetime.get() === undefined &&
                    b.startDatetime.get() === undefined &&
                    a.endDatetime.get() === undefined &&
                    b.endDatetime.get() === undefined
                ) {
                    return 0;
                }
                if (
                    a.startDatetime.get() === null &&
                    b.startDatetime.get() === null &&
                    a.endDatetime.get() === null &&
                    b.endDatetime.get() === null
                ) {
                    return 0;
                }

                if (
                    a.startDatetime.get() === undefined &&
                    a.endDatetime.get() === undefined
                )
                    return 1;
                if (
                    b.startDatetime.get() === undefined &&
                    b.endDatetime.get() === undefined
                )
                    return 1;

                if (
                    a.startDatetime.get() === null &&
                    a.endDatetime.get() === null
                )
                    return -1;
                if (
                    b.startDatetime.get() === null &&
                    b.endDatetime.get() === null
                )
                    return -1;

                let aDate: any;
                let bDate: any;

                if (
                    a.startDatetime.get() !== undefined &&
                    a.startDatetime.get() !== null
                ) {
                    aDate = a.startDatetime.get();
                } else {
                    aDate = a.endDatetime.get();
                }

                if (
                    b.startDatetime.get() !== undefined &&
                    b.startDatetime.get() !== null
                ) {
                    bDate = b.startDatetime.get();
                } else {
                    bDate = b.endDatetime.get();
                }

                return aDate.localeCompare(bDate);
            },
        );

        this.eventType = new JTOSimple<EventType>(
            EventType,
            'has_event_type',
            this,
        );

        this.dateFavorite = new JTOProperty<string>('favorite_date', this);

        this.contactList = new JTOList<Contact>(Contact, 'has_contact', this);
        this.hasRegister = new JTOSimple<Invite>(Invite, 'has_register', this);
        this.questionList = new JTOList<Question>(
            Question,
            'has_question',
            this,
            (a: OpenQuestion | Question, b: OpenQuestion | Question) => {
                return (a.position.get() ?? 0) - (b.position.get() ?? 0);
            },
        );

        this.directQuestionList = new JTOList<DirectQuestion>(
            DirectQuestion,
            'has_direct_question',
            this,
            (a: DirectQuestion, b: DirectQuestion) => {
                return (a.position.get() ?? 0) - (b.position.get() ?? 0);
            },
        );

        this.questionRealTimeList = new JTOList<OpenQuestion | Question>(
            [OpenQuestion, Question],
            'has_open_question',
            this,
            (a: OpenQuestion | Question, b: OpenQuestion | Question) => {
                return (a.position.get() ?? 0) - (b.position.get() ?? 0);
            },
        );
        this.hasRealTimeStarted = new JTOProperty<boolean>(
            'real_time_started',
            this,
        );
        this.hasRealTimeEnded = new JTOProperty<boolean>(
            'real_time_ended',
            this,
        );
        this.currentQuestion = new JTOSimple<OpenQuestion>(
            OpenQuestion,
            'has_current_question',
            this,
        );

        this.currentDirectQuestion = new JTOSimple<DirectQuestion>(
            DirectQuestion,
            'current_direct_question',
            this,
        );

        this.questionCurrentRealTime = undefined;
        this.questionCurrent = 0;
        this.myScore = 0;
    }

    public isShowMail(): boolean {
        return this.showMail.get() ?? true;
    }

    public isRootEvent(): boolean {
        return this.root !== undefined && this.root.get() === true;
    }

    public getInvite(user: User): Invite | null {
        let invite: Invite | null = null;
        let i = 0;
        while (invite === null && i < this.inviteList.size()) {
            if (
                this.inviteList.get(i).user.get()?.getJTOIdentifier() ===
                user.getJTOIdentifier()
            ) {
                invite = this.inviteList.get(i);
            }
            i++;
        }
        return invite;
    }

    public getMyInvite(user: User = User._instance): Invite | null {
        let invite: Invite | null = null;
        let i = 0;
        while (invite === null && i < this.inviteList.size()) {
            if (
                this.inviteList.get(i).user.get()?.getJTOIdentifier() ===
                user.getJTOIdentifier()
            ) {
                invite = this.inviteList.get(i);
            }
            i++;
        }
        return invite;
    }

    public get paidEvent(): boolean {
        return this._paidEvent.get() ?? false;
    }

    public set paidEvent(value: boolean) {
        this._paidEvent.set(value);
    }

    public get price(): number {
        return this._price.get() ?? 0;
    }

    public set price(value: number) {
        this._price.set(value);
    }

    public fetchRole() {
        getRestore(
            '/event/roleevent/my',
            {
                id: this.getJTOIdentifier(),
            },
            (res: any) => {
                if (HTTPUtils.isSuccess(res)) {
                    applyResult(HTTPUtils.getResult(res), this, true);
                } else {
                    // TODO
                }
            },
            (error: any) => {
                // TODO
            },
            () => {
                this.getMyInvite()?.roleEvent.setList([]);
            },
        );
    }

    public hasPermission(permission: number[] | number): boolean {
        let res: boolean = false;

        if (!User._instance.isAuth()) {
            res = false;
        } else if (User._instance.root.get()) {
            res = true;
        } else {
            if (typeof permission === 'number') {
                permission = [permission];
            }
            let countGood: number = 0;
            for (const p of permission) {
                for (const role of this.getMyInvite()?.roleEvent.getList() ??
                    []) {
                    if (role.has(p)) {
                        countGood++;
                        break;
                    }
                }
            }
            if (countGood === permission.length) {
                res = true;
            }
        }
        return res;
    }

    public getStartDate(): Date | null {
        try {
            let res: Date | null = null;
            res = new Date(this.startDatetime.get()!);
            if (isNaN(res.getTime())) {
                res = new Date(this.endDatetime.get() ?? '');
            }
            return res;
        } catch (e) {
            return new Date();
        }
    }

    public getEndDate(): Date | null {
        try {
            let res: Date | null = null;
            res = new Date(this.endDatetime.get()!);
            if (isNaN(res.getTime())) {
                res = new Date(this.startDatetime.get() ?? '');
            }
            return res;
        } catch (e) {
            return new Date();
        }
    }

    sendAnswer() {
        const answerList: number[] = [];
        this.questionList.getList().forEach((question) => {
            if (question.myAnswer instanceof Answer) {
                answerList.push(question.myAnswer.getJTOIdentifier());
            }
            // answerList.push(question.getJTOIdentifier());
        });
        post('/response/add', {
            quizId: this.getJTOIdentifier(),
            answerList,
            eventId: User._instance.getEvent()?.getJTOIdentifier(),
            userId: User._instance.getJTOIdentifier(),
        })
            .then((res) => {
                this.applyData(res);
            })
            .catch(() => {
                // TODO
            });
    }

    isNow(): boolean {
        const startTime = new Date(
            this.getStartDate() ?? this.getBetterDate() ?? '',
        );
        const endTime = new Date(
            this.getEndDate() ?? this.getBetterDate() ?? '',
        );
        const isNow =
            new Date(startTime).getTime() < new Date().getTime() &&
            new Date(endTime).getTime() > new Date().getTime();
        return isNow;
    }

    isAfterNow(): boolean {
        const startTime = new Date(
            this.getStartDate() ?? this.getBetterDate() ?? '',
        );
        return startTime.getTime() > new Date().getTime();
    }

    isNotSondage(): boolean {
        return (this.eventType.get()?.name.get() ?? '') !== 'Sondage';
    }

    isNotQuiz(): boolean {
        return (this.eventType.get()?.name.get() ?? '') !== 'Quiz';
    }

    isNotNuage(): boolean {
        return (this.eventType.get()?.name.get() ?? '') !== 'Nuage';
    }

    isBeforeNow(): boolean {
        const endTime = new Date(
            this.getEndDate() ?? this.getBetterDate() ?? '',
        );
        return endTime.getTime() < new Date().getTime();
    }

    getDiff(): number {
        const startTime = new Date(
            this.getStartDate() ?? this.getBetterDate() ?? '',
        );
        return startTime.getTime() - new Date().getTime();
    }

    getNextDate(date: Date) {
        // Get the event that is the next one after the given date
        let res: Date | null = null;
        let i = 0;
        while (res === null && i < this.subEventList.size()) {
            if (this.subEventList.get(i).getBetterDate() !== null) {
                if (
                    new Date(this.subEventList.get(i).getBetterDate()!) > date
                ) {
                    res = new Date(this.subEventList.get(i).getBetterDate()!);
                }
            }
            i++;
        }
        return res;
    }

    getBetterDate() {
        if (
            this.startDatetime.get() !== null &&
            this.startDatetime.get() !== undefined
        ) {
            return this.startDatetime.get();
        } else {
            return this.endDatetime.get();
        }
    }

    displayDuration(lang: string): string {
        let res: string = '';

        const start = new Date(this.getStartDate() ?? '');
        const end = new Date(this.getEndDate() ?? '');

        if (
            end.getDate() === start.getDate() &&
            end.getMonth() === start.getMonth() &&
            end.getFullYear() === start.getFullYear()
        ) {
            res =
                start.toLocaleTimeString(lang, {
                    hour: 'numeric',
                    minute: 'numeric',
                }) +
                ' - ' +
                end.toLocaleTimeString(lang, {
                    hour: 'numeric',
                    minute: 'numeric',
                });
        }
        // Check if end is not valid and start
        else if (!isNaN(end.getTime()) && !isNaN(start.getTime())) {
            res =
                start.toLocaleString(lang, {
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                }) +
                ' - ' +
                end.toLocaleString(lang, {
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                });
        }
        // Check if end
        else if (!isNaN(end.getTime())) {
            res = end.toLocaleString(lang, {
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            });
        }
        // Check if end
        else if (!isNaN(start.getTime())) {
            res = start.toLocaleString(lang, {
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            });
        }

        return res;
    }

    public displayDate(key: string) {
        let str = '';

        const eventStart = new Date(
            this.getStartDate() ?? this.getBetterDate() ?? '',
        );

        const eventEnd = new Date(
            this.getEndDate() ?? this.getBetterDate() ?? '',
        );

        if (
            eventStart.getDate() === eventEnd.getDate() &&
            eventStart.getFullYear() === eventEnd.getFullYear() &&
            eventStart.getMonth() === eventEnd.getMonth()
        ) {
            str = eventStart.toLocaleString(key, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
        } else {
            if (eventStart.getMonth() === eventEnd.getMonth()) {
                str =
                    eventStart.toLocaleString(key, {
                        day: 'numeric',
                    }) +
                    ' - ' +
                    eventEnd.toLocaleString(key, {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    });
            } else if (eventStart.getFullYear() === eventEnd.getFullYear()) {
                str =
                    eventStart.toLocaleString(key, {
                        day: 'numeric',
                        month: 'long',
                    }) +
                    ' - ' +
                    eventEnd.toLocaleString(key, {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    });
            } else {
                str =
                    eventStart.toLocaleString(key, {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    }) +
                    ' - ' +
                    eventEnd.toLocaleString(key, {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    });
            }
        }
        /*
        
            eventStart.toLocaleString(
                        t('date') as any,
                        {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                        },
                    )}
                    {!(eventStart.getDate() === eventEnd.getDate()&&
                    eventStart.getFullYear() === eventEnd.getFullYear()&&
                    eventStart.getMonth() === eventEnd.getMonth())&&
                        " - "+eventEnd.toLocaleString(
                            t('date') as any,
                            {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                            },
                        )
                    }

        */
        return str;
    }

    getAddress() {
        let address = '';

        if (this.address.get() !== undefined) {
            address += this.address.get() + ' ';
        }
        if (this.zipcode.get() !== undefined) {
            address += this.zipcode.get() + ' ';
        }
        if (this.city.get() !== undefined) {
            address += this.city.get() + ' ';
        }
        if (this.country.get() !== undefined) {
            address += this.country.get();
        }

        return address;
    }

    getNameAddress() {
        return this.nameAddress.get() ?? '';
    }

    onClose() {
        (this.questionCurrent as number) += 1;
        this.notifyViewNow();
    }

    resetQuiz() {
        this.questionCurrent = 0;
        for (const question of this.questionList.getList()) {
            question.myAnswer = null;
        }
        this.myScore = 0;
    }

    getBeforeQuestion(): OpenQuestion | null {
        const positionInit = this.currentQuestion.get()?.position.get() ?? 0;
        for (const question of this.questionRealTimeList.getList()) {
            if (question.position.get() === positionInit - 1) {
                return question as any;
            }
        }
        return null;
    }
    getAfterQuestion(): OpenQuestion | null {
        const positionInit = this.currentQuestion.get()?.position.get() ?? 0;
        for (const question of this.questionRealTimeList.getList()) {
            if (question.position.get() === positionInit + 1) {
                return question as any;
            }
        }
        return null;
    }
    getActuQuestion(): OpenQuestion | null {
        const positionInit = this.currentQuestion.get()?.position.get() ?? 0;
        for (const question of this.questionRealTimeList.getList()) {
            if (question.position.get() === positionInit) {
                return question as any;
            }
        }
        return null;
    }
    getLastQuestion(): OpenQuestion | null {
        const positionInit = this.currentQuestion.get()?.position.get() ?? 0;
        for (const question of this.questionRealTimeList.getList()) {
            if (
                question.position.get() ===
                this.questionRealTimeList.getList().length
            ) {
                return question as any;
            }
        }
        return null;
    }

    getBeforeDirectQuestion(): OpenQuestion | null {
        const positionInit =
            this.currentDirectQuestion.get()?.position.get() ?? 0;
        for (const question of this.directQuestionList.getList()) {
            if (question.position.get() === positionInit - 1) {
                return question as any;
            }
        }
        return null;
    }
    getAfterDirectQuestion(): OpenQuestion | null {
        const positionInit =
            this.currentDirectQuestion.get()?.position.get() ?? 0;
        for (const question of this.directQuestionList.getList()) {
            if (question.position.get() === positionInit + 1) {
                return question as any;
            }
        }
        return null;
    }
    getActuDirectQuestion(): OpenQuestion | null {
        const positionInit =
            this.currentDirectQuestion.get()?.position.get() ?? 0;
        for (const question of this.directQuestionList.getList()) {
            if (question.position.get() === positionInit) {
                return question as any;
            }
        }
        return null;
    }
    getLastDirectQuestion(): OpenQuestion | null {
        const positionInit =
            this.currentDirectQuestion.get()?.position.get() ?? 0;
        for (const question of this.directQuestionList.getList()) {
            if (
                question.position.get() ===
                this.directQuestionList.getList().length
            ) {
                return question as any;
            }
        }
        return null;
    }

    canBeSurvey(): boolean {
        return this.isNotNuage() && this.isNotSondage() && this.isNotQuiz();
    }

    isFavoriteNow() {
        return this.isFavorite.get();
    }

    asAbout() {
        return this.asDescription() || this.asAddress() || this.asDate();
    }

    asDescription() {
        return (
            this.description.get() !== undefined &&
            this.description.get() !== ''
        );
    }

    asAddress() {
        return this.getNameAddress() !== '' || this.getAddress().trim() !== '';
    }

    asDate() {
        return isValidDate(new Date(this.getBetterDate() ?? 'undefined'));
    }
}
function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d as any);
}
