import JTOElement from './JTOElement';
import JTOInstance from './JTOInstance';
import JTOUtils from './JTOUtils';
import JTOField from './JTOField';

export default abstract class JTOObject extends JTOElement {
    public static _TYPE: string = 'JTOObject';

    /**
     * Variable JTOIdentifier contains the identifier of the object
     * @type {any}
     */
    private JTOIdentifier: any;

    /**
     * Variable JTOType contains the type of the object
     * @type {string}
     */
    private JTOType: string;

    /**
     * Variable create indicates if the object is inflated or not
     * create goes true when the object receives its first data
     * @type {boolean}
     */
    private create: boolean;

    /**
     * Constructor of JTOObject
     * @param JTOType the string that identifies the type of the object defined at _TYPE in your JTOObject class
     * @param JTOParent the parent of this object, null if this object is the root
     * @param JTOIdentifier optionnal parameter, the identifier of the object
     */
    constructor(
        JTOType: string,
        JTOParent: JTOElement | null = null,
        JTOIdentifier?: any,
    ) {
        super(JTOParent);

        this.JTOIdentifier = JTOIdentifier;
        this.JTOType = JTOType;

        // if there is an identifier the object is already created
        this.create = JTOIdentifier !== undefined && JTOIdentifier != null;
    }
    /**
     * Set the JTOIdentifier of this JTOObject
     * @param JTOIdentifier the new JTOIdentifier
     */
    public setJTOIdentifier(JTOIdentifier: any) {
        this.JTOIdentifier = JTOIdentifier;
        this.setCreate(JTOIdentifier !== undefined && JTOIdentifier != null);
    }
    /**
     * Get the JTOIdentifier of this JTOObject
     * @returns {any} the JTOIdentifier of this JTOObject
     */
    public override getJTOIdentifier(): any {
        return this.JTOIdentifier;
    }

    /**
     * Set the JTOType of this JTOObject
     * @param JTOType the new JTOType
     */
    public setJTOType(JTOType: string) {
        this.JTOType = JTOType;
    }
    /**
     * Get the JTOType of this JTOObject
     * @returns {any} the JTOType of this JTOObject
     */
    public override getJTOType(): string {
        return this.JTOType;
    }

    /**
     * Get the create flag
     * @returns {boolean} the create flag
     */
    public isCreate(): boolean {
        return this.create;
    }

    /**
     * Set the create flag
     * @param create the new create flag
     */
    public setCreate(create: boolean) {
        this.create = create;
    }

    public equals(data: { [key: string]: any }): boolean {
        let res = false;
        if (data === null || data === undefined) {
            res = false;
        } else if (data instanceof JTOObject) {
            if (JTOInstance.isDeepEqual()) {
                res =
                    this.getJTOIdentifier() === data.getJTOIdentifier() &&
                    this.getJTOType() === data.getJTOType();
            } else {
                res = this.getJTOIdentifier() === data.getJTOIdentifier();
            }
        } else if (typeof data === 'object') {
            if (!this.isCreate()) {
                if (JTOInstance.isDeepEqual()) {
                    res =
                        this.getJTOType() === data[JTOInstance.getTypeField()];
                } else {
                    res = true;
                }
            } else if (JTOInstance.isDeepEqual()) {
                res =
                    this.getJTOIdentifier() ===
                        data[JTOInstance.getIdentifierField()] &&
                    this.getJTOType() === data[JTOInstance.getTypeField()];
            } else {
                res =
                    this.getJTOIdentifier() ===
                    data[JTOInstance.getIdentifierField()];
            }
        }
        return res;
    }

    public override applyDataPartiel(
        data: { [key: string]: any },
        first: boolean,
    ): boolean {
        // boolean that indicates if the object has changed

        let res = false;

        // check if the json correspond to the object
        const applyData = this.equals(data);
        if (applyData) {
            // get the identifier of the json source
            const JTOIdentifier = data[JTOInstance.getIdentifierField()];

            // check if the identifier exists
            if (JTOIdentifier !== undefined) {
                // create the object with this identifier
                res = true;
                this.setCreate(true);
                this.setJTOIdentifier(JTOIdentifier);
            }
        }

        if (first && this.getJTOParent() instanceof JTOField) {
            const parent = this.getJTOParent() as JTOField;

            const json: any = {};
            json[parent.getField()] = data;
            parent.applyDataPartiel(json, true);
        }

        if (applyData) {
            this.passToChild(data);
        }

        // Make the update
        if (res) {
            this.notifyView();
        }

        return res;
    }

    private passToChild(data: { [key: string]: any }) {
        let res = false;
        const list = this.getJTOElementList(false);
        // number of child
        const len = list.length;
        for (let i = 0; i < len; i++) {
            // for each child
            const jtoElement: JTOElement = list[i];
            // apply the data to the child
            res = jtoElement.applyDataPartiel(data, true) || res;
        }
        return res;
    }

    /**
     * Get all JTOObject in the object
     * @returns {JTOObject[]}
     */
    public getJTOObjectList(recursively: boolean): JTOObject[] {
        const listJTOObject: JTOObject[] = [this];
        const listJTOElement: JTOElement[] =
            this.getJTOElementList(recursively);
        for (const jtoElement of listJTOElement) {
            if (jtoElement instanceof JTOObject) {
                listJTOObject.push(jtoElement as JTOObject);
            }
        }
        return listJTOObject;
    }
    /**
     * Apply data to the object and its child
     * return true if their is any change in the hierarchy
     * @param data the json souce
     * @returns {boolean} true if their is any change in the object or in the child
     */
    public override applyData(data: { [key: string]: any }): boolean {
        let res = false;

        if (data instanceof Array) {
            for (const json of data) {
                res = this.applyData(json) || res;
            }
        } else {
            let allObject: JTOObject[] = this.getJTOObjectList(true);
            const allJson: { [key: string]: any }[] =
                JTOUtils.getAllJsonObject(data);

            for (const json of allJson) {
                for (const child of allObject) {
                    if (child.equals(json)) {
                        res = child.applyDataPartiel(json, true) || res;
                    }
                }
            }

            allObject = this.getJTOObjectList(true);

            for (const child of allObject) {
                const list = child.getJTORelPropertyList();
                for (const rel of list) {
                    // get the identifier of the child and the parent
                    const parentId =
                        child
                            .getJTOParent()
                            ?.getJTOParent()
                            ?.getJTOIdentifier() ?? '';
                    const parent = child.getJTOParent()?.getJTOParent();

                    // check if the parent is in the json
                    if (
                        parentId !== '' &&
                        parent !== null &&
                        parent !== undefined
                    ) {
                        // check if the parent is in the json
                        for (const json of allJson) {
                            if ((parent as JTOObject).equals(json)) {
                                // get the list of the child
                                let listFinal =
                                    json[rel.getField().split('.')[0]];

                                if (
                                    listFinal !== undefined &&
                                    listFinal !== null
                                ) {
                                    if (!(listFinal instanceof Array)) {
                                        listFinal = [listFinal];
                                    }
                                    for (const obj of listFinal) {
                                        if (child.equals(obj)) {
                                            const value = obj[rel.getField()];
                                            if (value === undefined) {
                                                if (
                                                    rel.isOverrideOnUndefined()
                                                ) {
                                                    rel.set(value);
                                                }
                                            } else {
                                                rel.set(value);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return res;
    }

    public reset() {
        super.reset();
        this.setCreate(false);
        this.setJTOIdentifier(null);
    }

    public getJTORelPropertyList(): any[] {
        const list: any[] = [];
        for (const jtoElement of this.getJTOElementList(false)) {
            if ((jtoElement as any).JTORelProperty === true) {
                list.push(jtoElement);
            }
        }
        return list;
    }

    public override toJson() {
        const res = {} as any;

        res[JTOInstance.getIdentifierField()] = this.getJTOIdentifier();
        res[JTOInstance.getTypeField()] = this.getJTOType();

        for (const obj of this.getJTOElementList(false)) {
            if (obj instanceof JTOField) {
                res[obj.getField()] = obj.toJson();
            }
        }

        return res;
    }
}
