import {
    View,
    Text,
    Pressable,
    TextInput,
    ActivityIndicator,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_REGULAR, getColor } from '../utils/theme';
import { goBackTo, height, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Keychain from 'react-native-keychain';
import { MpInput } from '../base/MpInput';
import { useEffect, useState } from 'react';
import User from '../object/User';
import { XMarkIcon } from 'react-native-heroicons/solid';
import { post } from '../utils/http';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import Post from '../object/Post';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import { applyResult } from '../utils/applyResult';
import { JTOInstance } from 'mp-ts-react-json-to-object';

export const PostRemoveFavoriteModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const id = (props as any).route.params.post.getJTOIdentifier();

    const removeFavorite = () => {
        setLoading(true);
        setError('');
        post('/event/favorite', {
            id: id + '',
            is_favorite: false,
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    JTOInstance.applyResult(HTTPUtils.getResult(res), true);
                    try {
                        (
                            (props as any).route.params.post as Post
                        ).isFavorite.set(false);
                        (
                            (props as any).route.params.post as Post
                        ).notifyViewNow();
                    } catch (e) {}
                    ToastManager.instance.addToast(
                        new Toast(
                            t('remove_favorites_success'),
                            5000,
                            'success',
                        ),
                    );
                    goBackTo(
                        props.navigation,
                        ['Tab', 'PostCommentModal'],
                        'PostRemoveFavoriteModal',
                    );
                } else {
                    setError(t(HTTPUtils.getError(res)) ?? '');
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(t('went_wrong_no_internet') ?? '');
            });
    };

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('remove_favorites')}
                line={1}
                backButton={true}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: 15,
                    }}
                >
                    {loading ? (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    height: height() * 0.5,
                                },
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View
                            style={{
                                paddingHorizontal: 20,
                            }}
                        >
                            <Text
                                style={{
                                    paddingVertical: 30,
                                    textAlign: 'center',
                                    fontFamily: FONT_REGULAR,
                                    color: getColor('text'),
                                }}
                            >
                                {t('remove_favorites_confirm')}
                            </Text>
                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.textError,
                                        {
                                            marginTop: 10,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}

                            <View
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <CartoucheButton
                                    onPress={() => {
                                        removeFavorite();
                                    }}
                                    color={getColor('error')}
                                    title={t('delete')}
                                    height={80}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
