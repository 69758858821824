import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function NextLogo(props: SvgProps) {
    return (
        <Svg
            width='322.000000pt'
            height='230.000000pt'
            viewBox='0 0 322.000000 230.000000'
            {...props}
        >
            <Path
                d='M1550 2275c-246-35-489-109-695-209C35 1666-239 901 259 399 690-35 1544-124 2276 190c705 303 1081 909 878 1416-130 324-448 552-908 650-106 23-150 26-361 30-169 2-268-1-335-11zm589-69c508-96 838-340 953-706 33-107 33-309 0-425-94-328-385-635-787-832C1463-167 423 14 112 627c-48 93-74 211-75 333-1 259 107 487 342 721 282 280 686 469 1141 533 138 20 499 15 619-8z'
                transform='matrix(.1 0 0 -.1 0 230)'
                fill={props.fill}
            />
            <Path
                d='M1705 1795c132-130 277-270 322-311l82-76 178 182c98 101 185 192 193 204 19 27-81-59-244-210l-131-122-85 82c-106 101-273 252-365 332-183 157-171 137 50-81z'
                transform='matrix(.1 0 0 -.1 0 230)'
                fill={props.fill}
            />
            <Path
                d='M1853 1524c-4-11 31-53 118-140 68-68 128-124 134-124s69 58 140 130c103 103 127 132 117 142s-37-12-132-107l-120-120-118 118c-64 64-121 117-125 117s-10-7-14-16zM1563 1504c-4-11 29-51 111-133l116-116-115-115c-112-112-126-132-104-146 12-7 269 243 269 261 0 12-251 265-263 265-4 0-10-7-14-16z'
                transform='matrix(.1 0 0 -.1 0 230)'
                fill={props.fill}
            />
            <Path
                d='M1713 1504c-4-11 29-51 111-133l116-116-115-115c-112-112-126-132-104-146 12-7 269 243 269 261 0 12-251 265-263 265-4 0-10-7-14-16zM390 1150V800h92l-4 294-3 293 38-71c21-39 102-171 179-293l140-223h88v700h-81l3-285c2-157 2-285 1-285-2 0-17 28-34 63-17 34-95 162-173 285l-141 222H390v-350zM2743 1436l-43-9v-127h-40c-39 0-40-1-40-35s1-35 39-35h39l4-179c3-164 5-182 24-208 28-38 87-58 147-49 40 6 47 11 47 29 0 35-13 45-60 49-64 4-70 21-70 206v152h130v70h-129l-3 73-3 72-42-9zM2235 1270l-130-130 132-132c106-105 135-130 145-120s-12 37-107 132l-120 120 119 119c103 104 129 141 98 141-4 0-66-59-137-130z'
                transform='matrix(.1 0 0 -.1 0 230)'
                fill={props.fill}
            />
            <Path
                d='M2385 1270l-130-130 132-132c106-105 135-130 145-120s-12 37-107 132l-120 120 119 119c103 104 129 141 98 141-4 0-66-59-137-130zM1139 1287c-141-74-171-314-54-431 63-63 204-83 314-45 29 10 32 15 26 38-4 14-8 27-9 29-2 1-34-3-72-9-78-14-124-8-172 21-34 21-71 87-72 128v22h360v43c0 91-39 167-105 204-56 32-155 32-216 0zm161-50c33-17 70-74 70-108v-29h-272l7 28c23 95 118 148 195 109zM1856 1011c-117-118-140-151-103-151 7 0 62 50 122 110 60 61 114 110 120 110s60-49 120-110c104-104 135-127 135-97 0 11-249 267-260 267-3 0-63-58-134-129z'
                transform='matrix(.1 0 0 -.1 0 230)'
                fill={props.fill}
            />
            <Path
                d='M1600 579c-212-210-353-355-315-323 160 133 398 351 642 588l72 69 83-78c77-73 257-230 303-264 11-8-70 76-180 187s-204 202-210 202c-5 0-183-172-395-381z'
                transform='matrix(.1 0 0 -.1 0 230)'
                fill={props.fill}
            />
        </Svg>
    );
}

export default NextLogo;
