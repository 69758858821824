import { View, Text, Pressable, ActivityIndicator } from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_REGULAR, getColor } from '../utils/theme';
import { goBackTo, height, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import { MpInput } from '../base/MpInput';
import { useState } from 'react';
import User from '../object/User';
import { UserIcon, XMarkIcon } from 'react-native-heroicons/solid';
import { post } from '../utils/http';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import { applyResult } from '../utils/applyResult';

interface INetworkModalProps extends IModalProps {
    network: 'linkedin' | 'facebook' | 'instagram' | 'twitter';
}

export const NetworkModal = (props: INetworkModalProps) => {
    const { t } = useTranslation();

    /*const [linkedin, setLinkedin] = useState(User._instance.linkedin.get() ?? '');
    const [facebook, setFacebook] = useState(User._instance.facebook.get() ?? '');
    const [instagram, setInstagram] = useState(User._instance.instagram.get() ?? '');
    const [twitter, setTwitter] = useState(User._instance.twitter.get() ?? '');*/

    const [network, setNetwork] = useState(
        (props as any).route.params.network === 'linkedin'
            ? User._instance.linkedin.get()
            : (props as any).route.params.network === 'facebook'
            ? User._instance.facebook.get()
            : (props as any).route.params.network === 'instagram'
            ? User._instance.instagram.get()
            : (props as any).route.params.network === 'twitter'
            ? User._instance.twitter.get()
            : '',
    );

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const changeNetwork = () => {
        setLoading(true);
        setError('');

        post('/network/update', {
            id: User._instance.getJTOIdentifier(),
            url: network,
            type: (props as any).route.params.network,
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    applyResult(HTTPUtils.getResult(res), User._instance, true);

                    goBackTo(props.navigation, 'Tab', 'NetworkModal');

                    ToastManager.instance.addToast(
                        new Toast(t('profile_edit_success'), 5000, 'success'),
                    );
                } else {
                    setError(t(HTTPUtils.getError(res)) ?? '');
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(t('went_wrong_no_internet') ?? '');
            });
    };

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={(props as any).route.params.label ?? ''}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                rounded={(User._instance.avatar.get() ?? '') !== ''}
                logo={User._instance.avatar.get() ?? ''}
                fallback={
                    <UserIcon
                        color={'white'}
                        size={35}
                    />
                }
            >
                <View
                    style={{
                        marginHorizontal: 20,
                    }}
                >
                    {loading ? (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    height: height() * 0.5,
                                },
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View>
                            <Text
                                style={{
                                    color: getColor('text'),
                                    marginTop: 15,
                                    fontFamily: FONT_REGULAR,
                                }}
                            >
                                {t('how_network', {
                                    network:
                                        (props as any).route.params.label ?? '',
                                })}
                            </Text>
                            <View
                                style={{
                                    marginTop: 10,
                                    marginBottom: 15,
                                }}
                            >
                                <Text
                                    style={[
                                        styles.text,
                                        {
                                            marginBottom: 5,
                                        },
                                    ]}
                                >
                                    {t('url_to_profil')}
                                </Text>
                                <MpInput
                                    selectTextOnFocus={true}
                                    style={styles.inputOutilined}
                                    placeholder={
                                        (props as any).route.params.label ?? ''
                                    }
                                    value={network}
                                    defaultValue={network}
                                    onChangeText={(text) => {
                                        setNetwork(text);
                                    }}
                                />
                            </View>

                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.textError,
                                        {
                                            marginTop: 10,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}

                            <View
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <CartoucheButton
                                    onPress={() => {
                                        changeNetwork();
                                    }}
                                    title={t('validate')}
                                    height={80}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
