import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function Meeting(props: any) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 135.2 135.24'
            {...props}
        >
            <defs></defs>
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    d='M135.2 9.77v3.17c-.18.53-.36 1.06-.53 1.6-1.61 5.08-6.76 8.72-11.71 8.28-5.31-.47-9.85-4.68-10.5-9.73-.78-6.09 3.47-11.77 9.66-12.92 5.52-1.03 11.35 2.96 12.79 8.75.07.29.19.58.28.86zM18.6 132.36c-1.63 1.29-3.76 1.94-5.67 2.88H9.76c-2.77-.74-5.25-1.95-7.1-4.23-4.1-5.04-3.39-11.73 1.7-15.95 4.85-4.02 11.68-3.42 15.69 1.37 4.18 5 3.67 11.87-1.44 15.93zM135.2 122.03v3.17c-.7 1.63-1.22 3.38-2.15 4.87-1.8 2.89-4.65 4.38-7.89 5.17h-3.17c-1.4-.59-2.9-1.02-4.17-1.81-4.46-2.77-6.45-7.91-5.02-12.62 1.48-4.89 6.34-8.54 11.22-8.44 5.02.11 9.57 3.8 10.88 8.82.07.28.2.56.3.84zM110.3 67.66c-.07 23.56-19.15 42.64-42.68 42.67-23.51.04-42.79-19.24-42.73-42.76.06-23.55 19.14-42.63 42.67-42.68 23.5-.05 42.81 19.26 42.73 42.76z'
                    fill='currentColor'
                />
                <path
                    d='M52.18 19.38c-7.68 2.53-14.54 6.65-20.34 12.46-5.79 5.79-9.92 12.61-12.52 20.52-.32-.27-.56-.44-.76-.64-2.52-2.52-5.07-5.01-7.55-7.57-3.61-3.73-4.84-8.16-3.63-13.23.04-.16.12-.32.2-.55 6.69 1.04 12.62-.49 17.45-5.33s6.37-10.77 5.32-17.52c4.78-1.31 9.16-.61 12.79 2.62 3.18 2.83 5.98 6.07 9.04 9.22zM126.04 41.72c-2.98 3.81-6.56 7.15-10.08 10.92-2.67-8.14-6.79-14.97-12.57-20.76-5.79-5.8-12.59-9.94-20.41-12.5.04-.17.04-.32.11-.39 2.89-2.89 5.68-5.91 8.72-8.64 3.7-3.32 8.1-4.14 13.03-2.83-1.05 6.72.48 12.65 5.29 17.49 4.83 4.85 10.74 6.42 17.52 5.36 1.19 4.07.95 8.08-1.61 11.35zM52.26 115.93c-.18.25-.32.5-.51.69-2.58 2.59-5.12 5.21-7.76 7.74-3.84 3.67-8.38 4.7-13.63 3.37 1.05-6.72-.47-12.66-5.28-17.5-4.83-4.85-10.74-6.42-17.55-5.36-1.2-4.28-.85-8.34 1.82-11.67 2.95-3.68 6.45-6.91 9.85-10.49 5.8 16.5 16.75 27.47 33.07 33.23zM127.66 104.86c-6.7-1.05-12.63.47-17.47 5.29-4.85 4.83-6.42 10.75-5.36 17.54-4.17 1.21-8.23.9-11.56-1.74-3.73-2.96-7-6.49-10.61-9.9 16.5-5.82 27.48-16.77 33.31-33.32 3.33 3.47 6.73 6.62 9.65 10.17 2.82 3.43 3.25 7.57 2.04 11.97z'
                    fill='currentColor'
                />
                <path
                    d='M22.63 11.52c-.08 6.56-4.82 11.28-11.28 11.24C4.83 22.73.18 17.93.24 11.27.29 4.82 5.04.05 11.41.05c6.45 0 11.3 4.95 11.22 11.47z'
                    fill='currentColor'
                />
            </g>
        </svg>
    );
}

export default Meeting;
