import {
    View,
    Text,
    Pressable,
    ActivityIndicator,
    Keyboard,
    ScrollView,
} from 'react-native';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import { goBackTo, isCloseToBottom, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import { MpInput } from '../base/MpInput';
import React, { useEffect, useState } from 'react';
import User from '../object/User';
import { openAction } from '../utils/utils';
import { MagnifyingGlassIcon, XMarkIcon } from 'react-native-heroicons/solid';
import { post } from '../utils/http';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import JTOListFetch from '../object/JTOListFetch';
import ChatGroup from '../object/ChatGroup';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import useJTOState from '../utils/jto/useJTOState';
import { applyResult } from '../utils/applyResult';

export const AddChatGroupModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const userList = useJTOState(new JTOListFetch('/chat/search', User));

    const group = new ChatGroup();

    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [selected, setSelected] = useState<number[]>([
        User._instance.getJTOIdentifier(),
    ]);

    const isSelected = (user: User) => {
        return selected.some((nb) => nb === user.getJTOIdentifier());
    };

    useEffect(() => {
        userList.fetch({
            search,
            id_event: User._instance.getEvent()!.getJTOIdentifier(),
        });
        setLoading(false);
    }, []);

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                backButton={true}
                full={true}
                navigation={props.navigation}
                title={t('create_chat_group')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        paddingHorizontal: 20,
                        marginTop: 20,
                        flex: 1,
                        paddingBottom: NAVIGATION_BAR_HEIGHT,
                    }}
                >
                    <View
                        style={{
                            marginTop: 15,
                            marginBottom: 15,
                        }}
                    >
                        <View
                            style={{
                                marginBottom: 15,
                            }}
                        >
                            <MpInput
                                value={name}
                                defaultValue={name}
                                onChangeText={(e) => {
                                    setName(e);
                                }}
                                center={false}
                                style={styles.inputSingleLine}
                                placeholder={t('name_create_group') ?? ''}
                            />
                        </View>

                        <MpInput
                            spaceIcon={10}
                            value={search}
                            leftIcon={
                                <MagnifyingGlassIcon
                                    size={20}
                                    color={getColor('hint')}
                                />
                            }
                            onChangeText={(e) => {
                                setSearch(e);
                                userList.reset();
                                userList.refetch({
                                    search: e,
                                    id_event: User._instance
                                        .getEvent()!
                                        .getJTOIdentifier(),
                                });
                            }}
                            placeholder={t('search_user') ?? ''}
                            style={styles.inputSingleLine}
                        />
                    </View>

                    {!loading ? (
                        <View
                            style={{
                                flex: 1,
                                display: 'flex',
                            }}
                        >
                            <ScrollView
                                /*keyboardDis ssMode='on-drag'*/
                                onScroll={({ nativeEvent }) => {
                                    if (isCloseToBottom(nativeEvent)) {
                                        userList.more({
                                            search,
                                            id_event: User._instance
                                                .getEvent()!
                                                .getJTOIdentifier(),
                                        });
                                    }
                                }}
                                scrollEventThrottle={1}
                                onMomentumScrollEnd={({ nativeEvent }) => {
                                    if (isCloseToBottom(nativeEvent)) {
                                        userList.more({
                                            search,
                                            id_event: User._instance
                                                .getEvent()!
                                                .getJTOIdentifier(),
                                        });
                                    }
                                }}
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                    flexShrink: 0,
                                }}
                            >
                                <View
                                    style={{
                                        display: 'flex',

                                        flexDirection: 'column',
                                    }}
                                >
                                    {userList.getList().map((user, index) => {
                                        return (
                                            <Pressable
                                                key={user.getJTOIdentifier()}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    padding: 5,
                                                    flex: 1,
                                                }}
                                                onPress={() => {
                                                    if (isSelected(user)) {
                                                        setSelected(
                                                            selected.filter(
                                                                (nb) =>
                                                                    nb !==
                                                                    user.getJTOIdentifier(),
                                                            ),
                                                        );
                                                    } else {
                                                        setSelected([
                                                            ...selected,
                                                            user.getJTOIdentifier(),
                                                        ]);
                                                    }
                                                }}
                                            >
                                                <input
                                                    type='checkbox'
                                                    checked={isSelected(user)}
                                                    onChange={() => {
                                                        if (isSelected(user)) {
                                                            setSelected(
                                                                selected.filter(
                                                                    (nb) =>
                                                                        nb !==
                                                                        user.getJTOIdentifier(),
                                                                ),
                                                            );
                                                        } else {
                                                            setSelected([
                                                                ...selected,
                                                                user.getJTOIdentifier(),
                                                            ]);
                                                        }
                                                    }}
                                                    style={{ marginRight: 8 }}
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: 15,
                                                        textDecorationLine:
                                                            'none',
                                                        color: getColor(
                                                            'secondary',
                                                        ),
                                                    }}
                                                >
                                                    {user.getDisplayName()}
                                                </Text>
                                            </Pressable>
                                        );
                                    })}

                                    {(userList.isLoading() ||
                                        !userList.isComplite()) && (
                                        <View
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: 70,
                                            }}
                                        >
                                            <GeSpinner
                                                color={getColor('primary')}
                                            />
                                        </View>
                                    )}

                                    {!userList.isLoading() &&
                                        userList.isComplite() &&
                                        userList.getList().length === 0 && (
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 70,
                                                }}
                                            >
                                                <Text style={styles.text}>
                                                    {t('no_user_found')}
                                                </Text>
                                            </View>
                                        )}
                                </View>
                            </ScrollView>

                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.error,
                                        {
                                            marginBottom: 15,
                                            marginLeft: 15,
                                            marginRight: 15,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}

                            <View
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <CartoucheButton
                                    onPress={() => {
                                        setLoading(true);
                                        setError('');
                                        post('/chat/group/create', {
                                            name,
                                            id_list: selected,
                                        })
                                            .then((response) => {
                                                setLoading(false);
                                                if (
                                                    HTTPUtils.isSuccess(
                                                        response,
                                                    )
                                                ) {
                                                    applyResult(
                                                        HTTPUtils.getResult(
                                                            response,
                                                        ),
                                                        group,
                                                        true,
                                                    );
                                                    User._instance.setCurrentChatId(
                                                        group.getJTOIdentifier(),
                                                    );
                                                    User._instance.setCurrentChatType(
                                                        'ChatGroup',
                                                    );
                                                    goBackTo(
                                                        props.navigation,
                                                        'Tab',
                                                        'AddChatGroupModal',
                                                    );
                                                    props.navigation.navigate(
                                                        'MessagePage',
                                                        {
                                                            group,
                                                        },
                                                    );
                                                    openAction();
                                                } else {
                                                    setError(
                                                        t(
                                                            HTTPUtils.getError(
                                                                response,
                                                            ),
                                                        ) ?? '',
                                                    );
                                                }
                                            })
                                            .catch((err) => {
                                                setError(
                                                    t(
                                                        'went_wrong_no_internet',
                                                    ) ?? '',
                                                );
                                            });
                                    }}
                                    title={t('create')}
                                    height={80}
                                />
                            </View>
                        </View>
                    ) : (
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
