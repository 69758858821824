import { getColor } from '../utils/theme';
import { hexToRgb } from '../utils/utils';

export const chatAnim = {
    v: '5.8.1',
    fr: 30,
    ip: 0,
    op: 101,
    w: 500,
    h: 500,
    nm: 'Chat',
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Dot Blue 2',
            parent: 2,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
                a: { a: 0, k: [271.453, 275.959, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 50,
                                        s: [323.015, 275.959],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 60,
                                        s: [323.015, 255.959],
                                        to: [0, 0],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 70,
                                        s: [323.015, 275.959],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 80,
                                        s: [323.015, 255.959],
                                        to: [0, 0],
                                        ti: [0, -3.333],
                                    },
                                    { t: 90, s: [323.015, 275.959] },
                                ],
                                ix: 2,
                            },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 48,
                                        s: [271.453, 275.959],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 58,
                                        s: [271.453, 255.959],
                                        to: [0, 0],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 68,
                                        s: [271.453, 275.959],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 78,
                                        s: [271.453, 255.959],
                                        to: [0, 0],
                                        ti: [0, -3.333],
                                    },
                                    { t: 88, s: [271.453, 275.959] },
                                ],
                                ix: 2,
                            },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 2',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 46,
                                        s: [219.891, 275.959],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 56,
                                        s: [219.891, 255.959],
                                        to: [0, 0],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 66,
                                        s: [219.891, 275.959],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 76,
                                        s: [219.891, 255.959],
                                        to: [0, 0],
                                        ti: [0, -3.333],
                                    },
                                    { t: 86, s: [219.891, 275.959] },
                                ],
                                ix: 2,
                            },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 3',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 3,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 50,
            op: 350,
            st: 50,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Chat Box Blue 2',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0.667, y: 1 },
                            o: { x: 0.333, y: 0 },
                            t: 100,
                            s: [378.74, 378.905, 0],
                            to: [0, -8.333, 0],
                            ti: [0, 8.333, 0],
                        },
                        { t: 110, s: [378.74, 328.905, 0] },
                    ],
                    ix: 2,
                    l: 2,
                },
                a: { a: 0, k: [107.287, 102.946, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 50,
                            s: [0, 0, 100],
                        },
                        { t: 60, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [3.108, -1.738],
                                        [19.156, 0.073],
                                        [0, 56.877],
                                        [-59.24, 0],
                                        [0, -56.898],
                                        [8.235, -14.792],
                                        [-0.984, -3.35],
                                        [0, 0],
                                        [2.269, -0.696],
                                        [0.871, 0.311],
                                    ],
                                    o: [
                                        [-3.343, -1.225],
                                        [-16.643, 9.485],
                                        [-59.24, 0],
                                        [0, -56.877],
                                        [59.24, 0],
                                        [0.016, 16.93],
                                        [-1.736, 3.029],
                                        [0, 0],
                                        [0.696, 2.269],
                                        [-0.884, 0.271],
                                        [0, 0],
                                    ],
                                    v: [
                                        [64.8, 87.773],
                                        [54.638, 88.581],
                                        [0, 102.945],
                                        [-107.287, 0.002],
                                        [0, -102.946],
                                        [107.287, 0.002],
                                        [94.753, 48.375],
                                        [93.576, 58.361],
                                        [104.748, 94.764],
                                        [101.899, 100.131],
                                        [99.196, 100.07],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: hexToRgb(getColor('primary'), 1),
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Chat Box White',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 50,
            op: 350,
            st: 50,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'White Dot 2',
            parent: 4,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.135, 0, 0], ix: 2, l: 2 },
                a: { a: 0, k: [228.618, 224.219, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 0,
                                        s: [280.18, 224.219],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 10,
                                        s: [280.18, 204.219],
                                        to: [0, 0],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 20,
                                        s: [280.18, 224.219],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 30,
                                        s: [280.18, 204.219],
                                        to: [0, 0],
                                        ti: [0, 0],
                                    },
                                    { t: 40, s: [280.18, 224.219] },
                                ],
                                ix: 2,
                            },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: -2,
                                        s: [228.618, 224.219],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 8,
                                        s: [228.618, 204.219],
                                        to: [0, 0],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 18,
                                        s: [228.618, 224.219],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 28,
                                        s: [228.618, 204.219],
                                        to: [0, 0],
                                        ti: [0, 0],
                                    },
                                    { t: 38, s: [228.618, 224.219] },
                                ],
                                ix: 2,
                            },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 2',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: -4,
                                        s: [177.056, 224.219],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 6,
                                        s: [177.056, 204.219],
                                        to: [0, 0],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 16,
                                        s: [177.056, 224.219],
                                        to: [0, -3.333],
                                        ti: [0, 0],
                                    },
                                    {
                                        i: { x: 0.667, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        t: 26,
                                        s: [177.056, 204.219],
                                        to: [0, 0],
                                        ti: [0, 0],
                                    },
                                    { t: 36, s: [177.056, 224.219] },
                                ],
                                ix: 2,
                            },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 3',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 3,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 300,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'White Chat Box 2',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0.833, y: 1 },
                            o: { x: 0.333, y: 0 },
                            t: 50,
                            s: [121.198, 377.343, 0],
                            to: [0, -8.333, 0],
                            ti: [0, 8.333, 0],
                        },
                        { t: 60, s: [121.198, 327.343, 0] },
                    ],
                    ix: 2,
                    l: 2,
                },
                a: { a: 0, k: [-107.285, 103.124, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                            o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                            t: 0,
                            s: [0, 0, 100],
                        },
                        { t: 10, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-3.112, -1.744],
                                        [-19.157, 0.073],
                                        [0, 56.976],
                                        [59.236, 0],
                                        [0, -56.98],
                                        [-8.24, -14.821],
                                        [0.982, -3.347],
                                        [0, 0],
                                        [-2.269, -0.697],
                                        [-0.873, 0.312],
                                    ],
                                    o: [
                                        [3.348, -1.232],
                                        [16.637, 9.498],
                                        [59.236, 0],
                                        [0, -56.976],
                                        [-59.236, 0],
                                        [-0.018, 16.958],
                                        [1.731, 3.028],
                                        [0, 0],
                                        [-0.697, 2.269],
                                        [0.886, 0.272],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-64.811, 87.93],
                                        [-54.632, 88.737],
                                        [0.002, 103.123],
                                        [107.285, 0],
                                        [0.002, -103.124],
                                        [-107.285, 0],
                                        [-94.747, 48.459],
                                        [-93.574, 58.436],
                                        [-104.771, 94.929],
                                        [-101.925, 100.298],
                                        [-99.215, 100.236],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: hexToRgb(getColor('success'), 1),
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Blue Chat Box',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 300,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Dot Blue',
            parent: 6,
            sr: 1,
            ks: {
                o: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            t: 50,
                            s: [100],
                        },
                        { t: 60, s: [0] },
                    ],
                    ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
                a: { a: 0, k: [271.453, 275.959, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [323.015, 275.959], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [271.453, 275.959], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 2',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [219.891, 275.959], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 3',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 3,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 51,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Chat Box Blue',
            sr: 1,
            ks: {
                o: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            t: 50,
                            s: [100],
                        },
                        { t: 60, s: [0] },
                    ],
                    ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0.667, y: 1 },
                            o: { x: 0.333, y: 0 },
                            t: 0,
                            s: [378.74, 378.905, 0],
                            to: [0, -8.333, 0],
                            ti: [0, 8.333, 0],
                        },
                        { t: 10, s: [378.74, 328.905, 0] },
                    ],
                    ix: 2,
                    l: 2,
                },
                a: { a: 0, k: [107.287, 102.946, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 0,
                            s: [100, 100, 100],
                        },
                        { t: 10, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [3.108, -1.738],
                                        [19.156, 0.073],
                                        [0, 56.877],
                                        [-59.24, 0],
                                        [0, -56.898],
                                        [8.235, -14.792],
                                        [-0.984, -3.35],
                                        [0, 0],
                                        [2.269, -0.696],
                                        [0.871, 0.311],
                                    ],
                                    o: [
                                        [-3.343, -1.225],
                                        [-16.643, 9.485],
                                        [-59.24, 0],
                                        [0, -56.877],
                                        [59.24, 0],
                                        [0.016, 16.93],
                                        [-1.736, 3.029],
                                        [0, 0],
                                        [0.696, 2.269],
                                        [-0.884, 0.271],
                                        [0, 0],
                                    ],
                                    v: [
                                        [64.8, 87.773],
                                        [54.638, 88.581],
                                        [0, 102.945],
                                        [-107.287, 0.002],
                                        [0, -102.946],
                                        [107.287, 0.002],
                                        [94.753, 48.375],
                                        [93.576, 58.361],
                                        [104.748, 94.764],
                                        [101.899, 100.131],
                                        [99.196, 100.07],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: hexToRgb(getColor('primary'), 1),
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Chat Box White',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 51,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'White Dot',
            parent: 8,
            sr: 1,
            ks: {
                o: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            t: 0,
                            s: [100],
                        },
                        { t: 10, s: [0] },
                    ],
                    ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.135, 0, 0], ix: 2, l: 2 },
                a: { a: 0, k: [228.618, 224.219, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [280.18, 224.219], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [228.618, 224.219], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 2',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [34.374, 34.374], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [177.056, 224.219], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Group 3',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 3,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 11,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'White Chat Box',
            sr: 1,
            ks: {
                o: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            t: 0,
                            s: [100],
                        },
                        { t: 10, s: [0] },
                    ],
                    ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0.667, y: 1 },
                            o: { x: 0.333, y: 0 },
                            t: 0,
                            s: [121.198, 327.343, 0],
                            to: [0, -8.333, 0],
                            ti: [0, 8.333, 0],
                        },
                        { t: 10, s: [121.198, 277.343, 0] },
                    ],
                    ix: 2,
                    l: 2,
                },
                a: { a: 0, k: [-107.285, 103.124, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 0,
                            s: [100, 100, 100],
                        },
                        { t: 10, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-3.112, -1.744],
                                        [-19.157, 0.073],
                                        [0, 56.976],
                                        [59.236, 0],
                                        [0, -56.98],
                                        [-8.24, -14.821],
                                        [0.982, -3.347],
                                        [0, 0],
                                        [-2.269, -0.697],
                                        [-0.873, 0.312],
                                    ],
                                    o: [
                                        [3.348, -1.232],
                                        [16.637, 9.498],
                                        [59.236, 0],
                                        [0, -56.976],
                                        [-59.236, 0],
                                        [-0.018, 16.958],
                                        [1.731, 3.028],
                                        [0, 0],
                                        [-0.697, 2.269],
                                        [0.886, 0.272],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-64.811, 87.93],
                                        [-54.632, 88.737],
                                        [0.002, 103.123],
                                        [107.285, 0],
                                        [0.002, -103.124],
                                        [-107.285, 0],
                                        [-94.747, 48.459],
                                        [-93.574, 58.436],
                                        [-104.771, 94.929],
                                        [-101.925, 100.298],
                                        [-99.215, 100.236],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: hexToRgb(getColor('success'), 1),
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Blue Chat Box',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 11,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
};
