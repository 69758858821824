import * as React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';

function SvgComponent(props: any) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            viewBox='0 0 127.88 115.78'
            {...props}
        >
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    d='M127.57 56.71c-2.01 12.5-3.94 25.02-5.91 37.53-.4 2.57-.75 5.15-1.25 7.7-1.51 7.75-8.38 13.63-16.32 13.75-8.64.13-17.28.05-25.92.05-6.94 0-13.9-.09-20.84.03-4.6.08-8.59-1.43-12.26-4-1.32-.92-3.04-1.69-3.7-2.98-.65-1.28-.23-3.13-.23-4.73 0-20.19 0-40.38-.03-60.57 0-.97.28-1.52 1.11-2.08 8.42-5.69 12.93-13.6 13.26-23.78.13-4.21.03-8.42.03-12.63 0-3.15 1.86-4.94 5.02-5 3.45-.06 6.85.13 10.1 1.45C79.4 5 84.17 13.63 82.74 23.18c-.65 4.3-1.52 8.51-3.71 12.55h1.61c9.72 0 19.45-.02 29.18.02 4.23.02 8.19 1.12 11.54 3.79 5.57 4.42 7.32 10.26 6.21 17.16zM29.92 76.87v26.44c0 5.27-3.46 8.74-8.7 8.75-4.26 0-8.51.01-12.77 0-4.83-.01-8.43-3.55-8.44-8.36C0 85.8 0 67.92.01 50.04c0-4.75 3.39-8.29 8.12-8.39 4.56-.1 9.12-.1 13.67 0 4.74.1 8.1 3.63 8.11 8.39.01 8.94 0 17.88 0 26.82z'
                    strokeWidth={0}
                />
            </g>
        </svg>
    );
}

export default SvgComponent;
