// tslint:disable
import React from 'react';
import { Dimensions, StyleSheet, View, Animated, Easing } from 'react-native';
import BadFace from '../svg/BadFace';
import { height, width } from '../utils/utils';

interface IBadAnimProps {
    duration: number;
    width: number;
    height: number;
    minConf: number;
    maxConf: number;
    pause?: boolean;
}

interface IBadAnimState {
    start: boolean;
    x: any[];
    y: any[];
    fadingOpacity: Animated.Value;
    movingBall: Animated.Value;
}

export default class BadAnim extends React.PureComponent<
    IBadAnimProps,
    IBadAnimState
> {
    // sound: Audio.Sound | null = null;

    constructor(props: any) {
        super(props);

        let density = 1,
            x = [],
            y = [];

        for (let i = 0; i < density; i++) {
            x[i] = this.getRandomArbitrary(0, width() - 200);
            y[i] = this.getRandomArbitrary(0, height() - 200);
        }

        this.state = {
            start: false,
            x: x,
            y: y,
            fadingOpacity: new Animated.Value(0),
            movingBall: new Animated.Value(0),
        };
    }

    componentDidMount() {
        if (!this.props.pause) {
            this.setExplosionSpots();
        }
    }

    start() {
        this.setExplosionSpots(true);
    }

    setExplosionSpots(force: boolean = false) {
        let density = 1,
            x = [],
            y = [];

        for (let i = 0; i < density; i++) {
            x[i] = this.getRandomArbitrary(width() * 0.1, width() * 0.9);
            y[i] = this.getRandomArbitrary(height() * 0.1, height() * 0.5);
        }

        if (force) {
            this.setState(
                {
                    x: x,
                    y: y,
                    start: true,
                },
                () => {
                    this.animateBall();
                },
            );
        } else {
            this.setState({
                x: x,
                y: y,
                start: false,
            });
        }
    }

    getRandom(n: number) {
        return Math.round(Math.random() * n);
    }
    getRandomArbitrary(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }

    animateBall() {
        this.state.movingBall.setValue(0);
        Animated.timing(this.state.movingBall, {
            toValue: 1,
            duration: this.props.duration,
            easing: Easing.bezier(0.25, 0.1, 0.25, 1),
            useNativeDriver: true,
        }).start();
        this.state.fadingOpacity.setValue(1);
        Animated.timing(this.state.fadingOpacity, {
            toValue: 0,
            duration: this.props.duration,
            easing: Easing.bezier(0.25, 0.1, 0.25, 1),
            useNativeDriver: true,
        }).start(() => {
            this.setExplosionSpots();
        });
    }

    explosionBox() {
        let balls: any[] = [],
            randomTops: any[] = [],
            randomLefts: any[] = [];

        for (
            let i = 0;
            i < this.getRandomArbitrary(this.props.minConf, this.props.maxConf);
            i++
        ) {
            balls.push('');

            randomTops[i] = this.state.movingBall.interpolate({
                inputRange: [0, 1],
                outputRange: [
                    this.getRandomArbitrary(0, height() * 0.5),
                    this.getRandomArbitrary(0, height()),
                ],
            });
            randomLefts[i] = this.state.movingBall.interpolate({
                inputRange: [0, 1],
                outputRange: [
                    this.getRandomArbitrary(0, width()),
                    this.getRandomArbitrary(0, width()),
                ],
            });
        }
        let ballOpacity = this.state.fadingOpacity.interpolate({
            inputRange: [0, 0.25, 1],
            outputRange: [0, 0.75, 1],
        });
        let scale = this.state.fadingOpacity.interpolate({
            inputRange: [0, 1],
            outputRange: [1, 1],
        });

        return (
            <View
                style={[
                    {
                        height: 0,
                        width: 0,
                    },
                    styles.anywhere,
                ]}
            >
                {balls.map((ball, index) => {
                    return (
                        <Animated.View
                            key={index}
                            style={[
                                {
                                    top: 0,
                                    height: 50,
                                    width: 50,
                                    right: 0,
                                    zIndex: 1000,
                                    position: 'absolute',
                                    opacity: ballOpacity,
                                },
                                {
                                    transform: [
                                        {
                                            translateX: randomLefts[index],
                                        },
                                        {
                                            translateY: randomTops[index],
                                        },
                                        {
                                            scaleX: scale,
                                        },
                                        {
                                            scaleY: scale,
                                        },
                                    ],
                                },
                            ]}
                        >
                            <BadFace
                                width={50}
                                height={50}
                            />
                        </Animated.View>
                    );
                })}
            </View>
        );
    }

    renderExplosion() {
        const { x, y } = this.state;
        return (
            <View style={styles.anywhere}>
                {x.map((item, index) => {
                    return (
                        <View
                            key={index}
                            style={[
                                {
                                    top: y[index],
                                    left: x[index],
                                },
                                styles.anywhere,
                            ]}
                        >
                            {this.explosionBox()}
                        </View>
                    );
                })}
            </View>
        );
    }

    render() {
        return this.state.start && this.renderExplosion();
    }
}

const styles = StyleSheet.create({
    anywhere: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    explosionBoundary: {
        position: 'absolute',
        height: 0,
        width: 0,
    },
    ball: {
        position: 'absolute',
        height: 9,
        width: 9,
        borderRadius: 4,
    },
});
