import { t } from 'i18next';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
import { TrophyIcon, BeakerIcon } from 'react-native-heroicons/solid';
import InviteTiny from '../object/compressed/global/InviteTiny';
import User from '../object/User';
import {
    BRONZE_COLOR,
    BRONZE_COLOR_LIGHT,
    GOLD_COLOR,
    GOLD_COLOR_LIGHT,
    SILVER_COLOR,
    SILVER_COLOR_LIGHT,
} from '../pages/BadgePage';
import { FONT_BOLD, FONT_REGULAR, getColor, FONT_MEDIUM } from '../utils/theme';
import GenericAchivement from './achivement/GenericAchivement';
import { getCurrentLevel, getXpText } from './LevelFocusView';

export interface IRankItemViewProps {
    index: number;
    invite: InviteTiny;
    navigation: any;
}

export const RankItemView = (props: IRankItemViewProps) => {
    const { invite, index } = props;
    const level = getCurrentLevel(invite?.user.get()?.xp.get() ?? 0);

    let baseColor: string = SILVER_COLOR;
    let bgColor: string = SILVER_COLOR_LIGHT;
    let levelColor = 2;
    if (index === 0) {
        baseColor = GOLD_COLOR;
        bgColor = GOLD_COLOR_LIGHT;
        levelColor = 3;
    } else if (index === 1) {
        baseColor = SILVER_COLOR;
        bgColor = SILVER_COLOR_LIGHT;
        levelColor = 2;
    } else if (index === 2) {
        baseColor = BRONZE_COLOR;
        bgColor = BRONZE_COLOR_LIGHT;
        levelColor = 1;
    }
    return (
        <Pressable
            style={{
                marginHorizontal: 20,
                borderRadius: 20,
                marginBottom: 20,
                padding: 10,
                display: 'flex',
                backgroundColor: bgColor,
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                flexDirection: 'row',
            }}
            onPress={() => {
                props.navigation.push('ProfilePage', {
                    user: invite?.user.get()?.toUser() ?? new User(),
                });
            }}
        >
            <GenericAchivement
                icon={
                    <TrophyIcon
                        height={35}
                        width={35}
                        fill={baseColor}
                    />
                }
                height={100}
                width={100}
                count={index + 1}
                level={levelColor}
                owned={index <= 2}
            />
            <View
                style={{
                    paddingLeft: 10,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {invite && (
                    <Text
                        style={{
                            fontFamily: FONT_BOLD,
                            fontSize: 20,
                            color: baseColor,
                        }}
                    >
                        {invite.user.get()?.getDisplayName()}
                    </Text>
                )}
                {invite && (
                    <View
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: FONT_REGULAR,
                                fontSize: 14,
                                color: getColor('hint'),
                            }}
                        >
                            {invite.user.get()?.email.get()}
                        </Text>
                    </View>
                )}
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Text
                        style={{
                            color: baseColor,
                            fontFamily: FONT_MEDIUM,
                            fontSize: 20,
                            flex: 1,
                        }}
                    >
                        {t('level', { nb: level + 1 })}
                    </Text>
                    <View
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            paddingLeft: 12,
                            paddingRight: 8,
                            paddingVertical: 4,
                            borderRadius: 30,
                            backgroundColor: baseColor,
                        }}
                    >
                        <Text
                            style={{
                                color: 'white',
                                fontFamily: FONT_MEDIUM,
                                fontSize: 14,
                                marginRight: 5,
                            }}
                        >
                            {getXpText(invite?.user.get()?.xp.get() ?? 0)}
                        </Text>
                        <BeakerIcon
                            color={'white'}
                            height={18}
                            width={18}
                        />
                    </View>
                </View>
            </View>
        </Pressable>
    );
};
