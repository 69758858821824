// global socket io
import * as io from 'socket.io-client';
//import * as Keychain from 'react-native-keychain';
import { SERVER_URL } from './constants';

import User from '../object/User';
import { HTTPUtils } from 'mp-ts-http';
import { Message } from '../object/Message';
import ChatGroup from '../object/ChatGroup';
import { fetchNewMessage } from './utils';
import { startConfetti } from '../component/Confetti';
import { getCurrentLevel, getXpLevel } from '../component/LevelFocusView';
import { openExperience } from '../component/ExperienceView';
import { navigationRef } from '../component/AppNavigation';
import { post } from 'mp-ts-query';
import { applyResult } from './applyResult';
import JTOInstance from './jto/JTOInstance';

export default class SocketInstance {
    private static _instance: SocketInstance;

    public socket: io.Socket | null = null;

    constructor() {
        // No need to do anything here
    }

    public close() {
        this.socket?.close();
    }

    public open() {
        this.socket?.close();

        const key = localStorage.getItem('happlyevent.access_token');

        let token;
        if (key) {
            token = key;
        } else {
            token = '';
        }
        this.socket = io.io(SERVER_URL, {
            query: {
                token,
            },
        });
        this.socket.on('connect', () => {
            // tslint:disable-next-line:no-console
            console.log('Connected');
            this.socket?.emit('init');
        });

        this.socket.on('join.quiz.backoffice', (data: any) => {
            if (HTTPUtils.isSuccess(data)) {
                JTOInstance.applyResult(HTTPUtils.getResult(data), true);
            }
        });

        this.socket.on('join.direct.backoffice', (data: any) => {
            if (HTTPUtils.isSuccess(data)) {
                JTOInstance.applyResult(HTTPUtils.getResult(data), true);
            }
        });

        this.socket.on('realtime.nextQuestion', (data: any) => {
            if (HTTPUtils.isSuccess(data)) {
                JTOInstance.applyResult(HTTPUtils.getResult(data), true);
            }
        });

        this.socket.on('update_xp', (data: any) => {
            if (HTTPUtils.isSuccess(data)) {
                const dictBagde = {} as any;
                for (const badge of User._badgeList.getList() ?? []) {
                    dictBagde[badge.key.get() ?? ''] = badge.hasBadge(
                        User._instance,
                    );
                }

                const user = User._instance;

                const befXp = user.xp.get() ?? 0;

                const befLevel = getCurrentLevel(befXp);
                const xp = HTTPUtils.getResult(data).user[0].xp ?? 0;
                user.xp.set(xp);

                const afterXp = user.xp.get() ?? 0;

                const afterLevel = getCurrentLevel(afterXp);
                if (afterLevel > befLevel) {
                    startConfetti();
                }

                openExperience(afterXp - befXp);

                applyResult(HTTPUtils.getResult(data), User._instance);

                for (const badge of User._badgeList.getList() ?? []) {
                    if (
                        !dictBagde[badge.key.get() ?? ''] &&
                        badge.hasBadge(User._instance)
                    ) {
                        navigationRef?.current?.navigate?.('BigBadgePage', {
                            badge,
                            anim: true,
                        });
                    }
                }
            }
        });

        this.socket.on('realtime.end', (data: any) => {
            if (HTTPUtils.isSuccess(data)) {
                JTOInstance.applyResult(HTTPUtils.getResult(data), true);
            }
        });

        this.socket.on('direct.end', (data: any) => {
            if (HTTPUtils.isSuccess(data)) {
                JTOInstance.applyResult(HTTPUtils.getResult(data), true);
            }
        });

        this.socket.on('join.quiz.mobile', (data: any) => {
            if (HTTPUtils.isSuccess(data)) {
                JTOInstance.applyResult(HTTPUtils.getResult(data), true);
            }
        });

        this.socket.on('join.direct.mobile', (data: any) => {
            if (HTTPUtils.isSuccess(data)) {
                JTOInstance.applyResult(HTTPUtils.getResult(data), true);
            }
        });

        this.socket.on('direct.nextQuestion', (data: any) => {
            if (HTTPUtils.isSuccess(data)) {
                JTOInstance.applyResult(HTTPUtils.getResult(data), true);
            }
        });

        this.socket.on('join.quiz', (data: any) => {
            if (HTTPUtils.isSuccess(data)) {
                const id_quiz = HTTPUtils.getResult(data).id_quiz;
                const id_questions = HTTPUtils.getResult(data).id_questions;
                const started = HTTPUtils.getResult(data).started;

                User._instance.addRealTimeOpen(id_quiz);
                User._instance.addRealTimeOpen(id_questions);
                if (started) {
                    User._instance.addRealTimeGranted(id_quiz);
                    User._instance.addRealTimeGranted(id_questions);
                }
            }
        });

        this.socket.on('chat_group_create', (data: any) => {
            User._instanceChat.reset();
            User._instanceChat.chatList.refetch(
                { search: User._search },
                (res) => {
                    applyResult(
                        HTTPUtils.getResult(res),
                        User._instanceConnect,
                        true,
                    );
                },
            );
            this.socket?.emit('init');
        });
        this.socket.on('group_update', (data: any) => {
            JTOInstance.applyResult(HTTPUtils.getResult(data), true);
        });

        this.socket.on('message.create', (data: any) => {
            // tslint:disable-next-line:no-console

            if (User._currentMessageList !== undefined) {
                User._currentMessageList._block = false;
                const index = User._currentMessageList
                    .getList()
                    .findIndex((messageCC: Message) => {
                        return messageCC.getJTOIdentifier() === undefined;
                    });

                // Remove the message from the chat list
                if (index !== -1) {
                    User._currentMessageList.getList().splice(index, 1);
                }
                if (data.result) {
                    applyResult(
                        HTTPUtils.getResult(data),
                        User._currentMessageList,
                        true,
                    );

                    if (
                        (data.result.type === 'Message' ||
                            data.result.type === 'ChatGroup') &&
                        data.result.idMessage !== undefined
                    ) {
                        post('/message/file', {
                            id: data.result.idMessage,
                            file: User._fileList,
                            idDest: User._destFile,
                        })
                            .then((res) => {
                                User._fileList = [];
                                User._destFile = '';
                            })
                            .catch((err) => {
                                User._fileList = [];
                                User._destFile = '';
                            })
                            .then((res) => {
                                User._fileList = [];
                                User._destFile = '';
                            })
                            .catch((err) => {
                                User._fileList = [];
                                User._destFile = '';
                            });
                    }
                }
            }
            // pas sur le même objet
            JTOInstance.applyResult(HTTPUtils.getResult(data), true);
            if (User._currentMessageList !== undefined) {
                User._currentMessageList._block = true;
            }

            const message = HTTPUtils.getResult(data).message[0];
            const type = HTTPUtils.getResult(data).type;

            const sendId = message.send?.[0]?.receive?.[0]?._id ?? message._id;
            const receiveId =
                message.receive?.[0]?.send?.[0]?._id ?? message._id;

            const messageDate =
                message.send?.[0]?.date ?? message.receive?.[0]?.date ?? '';

            const messageContent =
                message.send?.[0]?.content ??
                message.receive?.[0]?.content ??
                '';

            let found = false;

            for (const chat of User._instanceChat.chatList.getList()) {
                if (chat.getJTOType() === type) {
                    if (
                        chat instanceof Message &&
                        (chat.getHim().getJTOIdentifier() === sendId ||
                            chat.getHim().getJTOIdentifier() === receiveId ||
                            chat.getSender().getJTOIdentifier() === sendId ||
                            chat.getSender().getJTOIdentifier() === receiveId)
                    ) {
                        let foundConnect = false;
                        for (const user of User._instanceConnect.connectList.getList()) {
                            if (
                                user.getJTOIdentifier() ===
                                    chat.getHim().getJTOIdentifier() ||
                                user.getJTOIdentifier() ===
                                    chat.getSender().getJTOIdentifier()
                            ) {
                                foundConnect = true;
                                if (!user.isConnect()) {
                                    if (
                                        chat.getHim().nbNewMessage.get() ===
                                        undefined
                                    ) {
                                        chat.getHim().nbNewMessage.set(0);
                                    }
                                    chat.getHim().nbNewMessage.set(
                                        (chat.getHim().nbNewMessage.get() ??
                                            0) + 1,
                                    );
                                    break;
                                }
                            }
                        }
                        if (!foundConnect) {
                            chat.getHim().nbNewMessage.set(
                                (chat.getHim().nbNewMessage.get() ?? 0) + 1,
                            );
                        }

                        chat.date.set(messageDate);
                        chat.content.set(messageContent);
                        chat.notifyViewNow();

                        found = true;
                    } else if (chat instanceof ChatGroup) {
                        if (
                            chat.getJTOIdentifier() === sendId ||
                            chat.getJTOIdentifier() === receiveId
                        ) {
                            let foundConnect = false;
                            for (const user of User._instanceConnect.connectList.getList()) {
                                if (
                                    user instanceof ChatGroup &&
                                    (user.getJTOIdentifier() === sendId ||
                                        user.getJTOIdentifier() === receiveId)
                                ) {
                                    foundConnect = true;
                                    if (!user.isConnect()) {
                                        chat.nbNewMessage.set(
                                            (chat.nbNewMessage.get() ?? 0) + 1,
                                        );
                                        break;
                                    }
                                }
                            }
                            if (!foundConnect) {
                                chat.nbNewMessage.set(
                                    (chat.nbNewMessage.get() ?? 0) + 1,
                                );
                            }

                            chat.message.get()?.date.set(messageDate);
                            chat.message.get()?.content.set(messageContent);
                            chat.notifyViewNow();

                            found = true;
                        }
                    }
                }
            }

            User._instanceChat.chatList.sort();

            if (!found) {
                User._instanceChat.chatList.reset();
                User._instanceChat.chatList.refetch(
                    { search: User._search },
                    (res) => {
                        applyResult(
                            HTTPUtils.getResult(res),
                            User._instanceConnect,
                            true,
                        );
                    },
                );
            }

            // User._instanceChat.chatList.reset();
            // User._instanceChat.chatList.refetch({search: ''}),
            /*
            // UPDATE MESSAGE COUNT DON'T WORK
            const message = User._instance.messageList
                .getList()
                .find((messageCC: Message) => {
                    if (
                        HTTPUtils.getResult(data).message[0].receive !==
                        undefined
                    ) {
                        return (
                            messageCC.getReceiver().getJTOIdentifier() ===
                            HTTPUtils.getResult(data).message[0]
                                .receive[0]._id
                        );
                    } else {
                        return (
                            messageCC.getReceiver().getJTOIdentifier() ===
                            HTTPUtils.getResult(data).message[0].send[0]
                                ._id
                        );
                    }
                });
            if (message?.getReceiver().isNewMessage()) {
                message
                    .getReceiver()
                    .nbNewMessage.set(
                        (message.getReceiver().nbNewMessage.get() ?? 0) + 1,
                    );
            } */
            User._instanceChat.chatList.notifyViewNow();

            //fetchNewMessage();
        });
    }

    public static get(): SocketInstance {
        if (!SocketInstance._instance) {
            SocketInstance._instance = new SocketInstance();
        }
        return SocketInstance._instance;
    }
}
