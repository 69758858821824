import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

export default class Answer extends JTOObject {
    public static _TYPE: string = 'Answer';

    public title: JTOProperty<string>;
    public goodAnswer: JTOProperty<boolean>;

    constructor() {
        super(Answer._TYPE);

        this.title = new JTOProperty<string>('title', this);
        this.goodAnswer = new JTOProperty<boolean>('good_answer', this);
    }
}
