import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function FashionShow(props: any) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 181.58 46.87'
            {...props}
        >
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M50.66.05c-3.33 11.92-6.6 23.66-9.89 35.46H23.98c-5.61 0-11.23-.13-16.84.05-3.3.1-5.73-.89-7.14-3.95V5.72C1.43 1.47 4.44-.05 8.85 0c13.23.14 26.47.05 39.71.05h2.11zM124.9 46.86H56.64c-4.87 0-7.17-2.98-5.87-7.63 3.5-12.55 7.03-25.11 10.52-37.67.25-.9.4-1.54 1.66-1.54 18.73.05 37.47.04 56.2.04.18 0 .35.04.72.09 2.12 7.57 4.22 15.06 6.32 22.56 1.53 5.45 3.04 10.91 4.56 16.36 1.34 4.79-.91 7.8-5.85 7.8zM181.57 29.52c0 3.82-2.13 5.98-5.88 5.99-11.17.01-22.34 0-33.51 0h-1.38c-3.32-11.88-6.58-23.57-9.89-35.46h42.7c5.15 0 7.96 2.83 7.97 8.02v21.45z'
                    strokeWidth={0}
                />
            </g>
        </svg>
    );
}

export default FashionShow;
