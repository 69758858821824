import { useTranslation } from 'react-i18next';
import Question from '../object/Question';
import User from '../object/User';
import { IModalProps } from './IModalProps';
import { View, Pressable, Text } from 'react-native';
import React from 'react';
import { Header } from '../component/Header';
import { height, width } from '../utils/utils';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
//import { ScrollView } from 'react-native-gesture-handler';
import { CartoucheButton } from '../component/CartoucheButton';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import Event from '../object/Event';
import { post } from '../utils/http';
import useJTOState from '../utils/jto/useJTOState';

export const AnswerModal = (props: IModalProps) => {
    const { t } = useTranslation();
    User.translation = t;

    const quiz = useJTOState((props as any).route.params.quiz as Event);

    const question = useJTOState(
        (props as any).route.params.question as Question,
    );
    const last = (props as any).route.params.last;

    const next = () => {
        quiz.onClose();
        props.navigation.pop();
        if (last === true) {
            props.navigation.push('ScoreModal', {
                quiz,
            });
        }
    };

    return (
        <View
            style={{
                width: width(),
                paddingBottom: 0,
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                modal
                full
                onPresslogo={() => {
                    props.navigation.popToTop();
                    props.navigation.navigate('home');
                }}
                line={1}
                backButton={true}
                navigation={props.navigation}
                title={t('question_number', {
                    index: (quiz.questionCurrent as number) + 1,
                })}
                after={
                    <View
                        style={{
                            height: 10,
                            width: width(),
                        }}
                    />
                }
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        flex: 1,
                        marginTop: -10,
                    }}
                >
                    <View
                        style={{
                            top: 0,
                            transform: [{ rotate: '-3deg' }],
                            marginLeft: -width() * 0.1,
                            width: width() * 1.2,
                            zIndex: 1000,
                            backgroundColor: getColor('success'),
                        }}
                    >
                        <View
                            style={{
                                transform: [{ rotate: '3deg' }],
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: FONT_MEDIUM,
                                    color: 'white',
                                    fontSize: 20,
                                    paddingVertical: 30,
                                    paddingHorizontal: 40,
                                }}
                            >
                                Réponse correcte
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            padding: 20,
                        }}
                    >
                        <Text
                            style={{
                                marginTop: 20,
                                marginBottom: 10,
                                paddingHorizontal: 30,
                                fontFamily: FONT_BOLD,
                                fontSize: 28,
                                textAlign: 'center',
                            }}
                        >
                            {question.title.get()}
                        </Text>
                        {question.descriptionAnswer.get() &&
                            question.descriptionAnswer.get() !== '' &&
                            question.descriptionAnswer.get() !== undefined && (
                                <Text
                                    style={{
                                        paddingBottom: 10,
                                        paddingHorizontal: 30,
                                        fontFamily: FONT_REGULAR,
                                        fontSize: 14,
                                        textAlign: 'center',
                                    }}
                                >
                                    {question.descriptionAnswer.get()}
                                </Text>
                            )}
                        {question.answerList.getList().map((answer, index) => {
                            return (
                                <Pressable
                                    key={index}
                                    style={{
                                        backgroundColor: getColor('background'),
                                        padding: 5,
                                    }}
                                >
                                    <CartoucheButton
                                        iconSize={20}
                                        color={
                                            answer.goodAnswer.get() === true
                                                ? getColor('success')
                                                : getColor('error')
                                        }
                                        fontSize={
                                            (answer.title.get()?.length ?? 0) >
                                            20
                                                ? 20
                                                : 30
                                        }
                                        width={width() - 80}
                                        line={2}
                                        title={answer.title.get()!}
                                    />
                                </Pressable>
                            );
                        })}

                        <Pressable
                            style={{
                                backgroundColor: getColor('background'),
                                padding: 10,
                                margin: 10,
                            }}
                        >
                            <CartoucheButton
                                color={getColor('secondary')}
                                fontSize={30}
                                width={width() - 80}
                                line={2}
                                onPress={() => {
                                    next();
                                }}
                                title={t('next')}
                            />
                        </Pressable>

                        {!last && (
                            <CartoucheButton
                                color={getColor('primary')}
                                height={45}
                                fontSize={20}
                                iconSize={20}
                                onPress={() => {
                                    props.navigation.popToTop();
                                }}
                                title={t('leave_quiz')}
                            />
                        )}
                    </View>
                </View>
            </Header>
        </View>
    );
};
