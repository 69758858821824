import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

import Answer from './Answer';
import Question from './Question';

export default class OpenQuestion extends Question {
    public static _TYPE: string = 'OpenQuestion';

    public myStringAnswer: string | null = null;

    constructor(parent?: JTOElement | null) {
        super(parent, OpenQuestion._TYPE);
    }
}
