import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Pressable, Text, StyleSheet } from 'react-native';
import Event from '../object/Event';
import Question from '../object/Question';
import User from '../object/User';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import { IModalProps } from './IModalProps';
import { Header } from '../component/Header';
//import { ScrollView } from 'react-native-gesture-handler';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { CartoucheButton } from '../component/CartoucheButton';
//import CardsSwipe from 'react-native-cards-swipe';
import Answer from '../object/Answer';
import {
    ClockIcon,
    HandThumbDownIcon,
    HandThumbUpIcon,
} from 'react-native-heroicons/solid';
import BadAnim from '../component/BadAnim';
import SucccessAnimation from '../component/SuccessAnimation';
import SuperSucccessAnimation from '../component/SuperSuccessAnimation';
import useJTOState from '../utils/jto/useJTOState';

const LETTERS = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
];

export const QuizModal = (props: IModalProps) => {
    const { t } = useTranslation();
    User.translation = t;

    const refCard = useRef<any>();

    const goodAnimationRef = useRef<any>();
    const finalAnimationRef = useRef<any>();
    const badAnimationRef = useRef<any>();

    const quiz = useJTOState((props as any).route.params.event as Event);

    const questionList = quiz.questionList.getList();

    const next = (question: Question, answer: Answer) => {
        if (
            questionList.indexOf(questionList[quiz.questionCurrent]) + 1 >=
            questionList.length
        ) {
            lastQuestion(question, answer);
        } else {
            notLastQuestion(question, answer);
        }
        quiz.questionCurrent++;
        quiz.notifyViewNow();
        refCard.current.swipeRight(() => {
            quiz.notifyViewNow();
        });
    };

    const lastQuestion = (question: Question, answer: Answer) => {
        if (answer.goodAnswer.get() === true) {
            quiz.myScore++;
            goodAnimationRef.current?.start();
        } else {
            badAnimationRef.current?.start();
        }
        question.myAnswer = answer;
        /*props.navigation.popToTop();
        props.navigation.push('AnswerModal', {
            question: questionList[quiz.questionCurrent as number],
            quiz: quiz,
            last: true,
        });*/
    };

    const notLastQuestion = (question: Question, answer: Answer) => {
        if (answer.goodAnswer.get() === true) {
            quiz.myScore++;
            goodAnimationRef.current?.start();
        } else {
            badAnimationRef.current?.start();
        }
        question.myAnswer = answer;
        /*props.navigation.push('AnswerModal', {
            question: questionList[quiz.questionCurrent as number],
            quiz: quiz,
        });*/
    };
    const cardsData = [] as any[];
    for (let i = 0; i < quiz.questionList.getList().length; i++) {
        const question = quiz.questionList.get(i);
        cardsData.push(question);
        cardsData.push({
            type: 'answer',
            question,
            last: i + 1 === quiz.questionList.getList().length,
        });
    }

    const getScoreReaction = (score: number, total: number) => {
        const percent = (score / total) * 100;
        let reaction = '';
        if (percent < 25) {
            reaction = t('unlucky');
        } else if (percent < 50) {
            reaction = t('not_bad');
        } else if (percent < 75) {
            reaction = t('good');
        } else {
            reaction = t('well_played');
        }
        return reaction;
    };

    const getScoreReactionIcon = (score: number, total: number) => {
        const percent = (score / total) * 100;
        let reaction;
        if (percent < 25) {
            reaction = (
                <HandThumbDownIcon
                    style={{
                        paddingVertical: 15,
                    }}
                    height={75}
                    width={75}
                    color={getColor('error')}
                />
            );
        } else if (percent < 50) {
            reaction = (
                <HandThumbUpIcon
                    style={{
                        paddingVertical: 15,
                    }}
                    height={75}
                    width={75}
                    color={getColor('success')}
                />
            );
        } else if (percent < 75) {
            reaction = (
                <HandThumbUpIcon
                    style={{
                        paddingVertical: 15,
                    }}
                    height={75}
                    width={75}
                    color={getColor('success')}
                />
            );
        } else {
            reaction = (
                <HandThumbUpIcon
                    style={{
                        paddingVertical: 15,
                    }}
                    height={75}
                    width={75}
                    color={getColor('success')}
                />
            );
        }
        return reaction;
    };

    cardsData.push('score');
    return (
        <View
            style={{
                width: width(),
                height: height(),
                paddingBottom: 0,
                backgroundColor: getColor('primary'),
            }}
        >
            <Header
                modal={true}
                backButton={true}
                full
                navigation={props.navigation}
                title={quiz.name.get() ?? ''}
                line={(quiz.name.get() ?? '').length <= 25 ? 1.01 : 3}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        flex: 1,
                        marginTop: HEADER_BONUS_HEIGHT,
                        width: width(),
                    }}
                >
                    {/*<CardsSwipe
                        ref={refCard}
                        loop={false}
                        cards={cardsData}
                        onSwiped={(index: number) => {
                            if (index % 2 === 0) {
                                if (
                                    questionList.length > Math.floor(index / 2)
                                ) {
                                    if (
                                        questionList[Math.floor(index / 2)]
                                            .myAnswer === null
                                    ) {
                                        questionList[
                                            Math.floor(index / 2)
                                        ].myAnswer = 'dodge';
                                        quiz.notifyViewNow();
                                    }
                                } else {
                                    quiz.sendAnswer();
                                    props.navigation.goBack();
                                }
                            }
                        }}
                        cardContainerStyle={stylesCard.cardContainer}
                        renderCard={(card: any) => {
                            if (card === 'score') {
                                let question: Question;
                                if (card instanceof Question) {
                                    question = card;
                                } else {
                                    question = card.question;
                                }
                                return (
                                    <View style={stylesCard.card}>
                                        <Text
                                            style={{
                                                fontFamily: FONT_BOLD,
                                                fontSize: 25,
                                                color: getColor('text'),
                                            }}
                                        >
                                            {t('score')}
                                        </Text>
                                        <View
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flex: 1,
                                            }}
                                        >
                                            {getScoreReactionIcon(
                                                quiz.myScore,
                                                questionList.length,
                                            )}
                                            <Text
                                                style={{
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 25,
                                                    paddingTop: 15,
                                                    color: getColor('text'),
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {getScoreReaction(
                                                    quiz.myScore,
                                                    questionList.length,
                                                )}
                                            </Text>
                                            <Text
                                                style={{
                                                    fontFamily: FONT_MEDIUM,
                                                    fontSize: 18,
                                                    paddingTop: 15,
                                                    color: getColor('text'),
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {t('score_quiz')}
                                                {quiz.myScore +
                                                    '/' +
                                                    questionList.length}
                                            </Text>
                                            <Text
                                                style={{
                                                    fontFamily: FONT_REGULAR,
                                                    fontSize: 16,
                                                    paddingTop: 15,
                                                    color: getColor('text'),
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {t('thanks_quiz')}
                                            </Text>
                                            <View
                                                style={{
                                                    padding: 15,
                                                }}
                                            >
                                                <CartoucheButton
                                                    color={getColor(
                                                        'secondary',
                                                    )}
                                                    height={45}
                                                    fontSize={20}
                                                    iconSize={20}
                                                    onPress={() => {
                                                        props.navigation.goBack();
                                                        quiz.sendAnswer();
                                                    }}
                                                    title={t('leave')}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                );
                            } else {
                                let question: Question;
                                if (card instanceof Question) {
                                    question = card;
                                } else {
                                    question = card.question;
                                }
                                const good =
                                    question.myAnswer !== 'dodge' &&
                                    question.myAnswer?.goodAnswer.get() ===
                                        true;
                                return (
                                    <View style={stylesCard.card}>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 25,
                                                    color: getColor('text'),
                                                }}
                                            >
                                                {(question.position.get() ??
                                                    0) + ''}
                                                .
                                            </Text>
                                            {!(card instanceof Question) &&
                                                question.myAnswer !== null && (
                                                    <View
                                                        style={{
                                                            paddingHorizontal: 15,
                                                            paddingVertical: 7,
                                                            borderRadius: 20,
                                                            backgroundColor:
                                                                good
                                                                    ? getColor(
                                                                          'success',
                                                                      )
                                                                    : getColor(
                                                                          'error',
                                                                      ),
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontFamily:
                                                                    FONT_BOLD,
                                                                fontSize: 15,
                                                                color: 'white',
                                                            }}
                                                        >
                                                            {good
                                                                ? t(
                                                                      'good_answer',
                                                                  )
                                                                : t(
                                                                      'bad_answer',
                                                                  )}{' '}
                                                            {good ? '+ 1' : ''}
                                                        </Text>
                                                    </View>
                                                )}
                                        </View>
                                        <View
                                            style={{
                                                flex: 1,
                                                width: '100%',
                                                display: 'flex',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 25,
                                                    color: getColor('text'),
                                                    marginBottom: 10,
                                                }}
                                            >
                                                {question.title.get()}
                                            </Text>
                                            {card instanceof Question &&
                                                question.myAnswer !== null &&
                                                question.descriptionAnswer.get() && (
                                                    <Text
                                                        style={{
                                                            fontFamily:
                                                                FONT_MEDIUM,
                                                            fontSize: 18,
                                                            color: getColor(
                                                                'text',
                                                            ),
                                                            marginBottom: 10,
                                                        }}
                                                    >
                                                        {question.descriptionAnswer.get()}
                                                    </Text>
                                                )}
                                            {!(card instanceof Question) &&
                                                (question.myAnswer === null ? (
                                                    <View
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <ClockIcon
                                                            style={{
                                                                paddingVertical: 15,
                                                            }}
                                                            height={75}
                                                            width={75}
                                                            color={getColor(
                                                                'secondary',
                                                            )}
                                                        />
                                                        <Text
                                                            style={{
                                                                fontFamily:
                                                                    FONT_BOLD,
                                                                fontSize: 25,
                                                                paddingTop: 15,
                                                                color: getColor(
                                                                    'text',
                                                                ),
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            {t('waiting')}
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontFamily:
                                                                    FONT_REGULAR,
                                                                paddingVertical: 15,
                                                                textAlign:
                                                                    'center',
                                                                color: getColor(
                                                                    'text',
                                                                ),
                                                                fontSize: 16,
                                                            }}
                                                        >
                                                            {t(
                                                                'no_answer_at_this_time',
                                                            )}
                                                        </Text>
                                                    </View>
                                                ) : (
                                                    <View
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            flex: 1,
                                                        }}
                                                    >
                                                        {good ? (
                                                            <HandThumbUpIcon
                                                                style={{
                                                                    paddingVertical: 15,
                                                                }}
                                                                height={75}
                                                                width={75}
                                                                color={getColor(
                                                                    'success',
                                                                )}
                                                            />
                                                        ) : (
                                                            <HandThumbDownIcon
                                                                style={{
                                                                    paddingVertical: 15,
                                                                }}
                                                                height={75}
                                                                width={75}
                                                                color={getColor(
                                                                    'error',
                                                                )}
                                                            />
                                                        )}
                                                        <Text
                                                            style={{
                                                                fontFamily:
                                                                    FONT_BOLD,
                                                                fontSize: 25,
                                                                paddingTop: 15,
                                                                color: getColor(
                                                                    'text',
                                                                ),
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            {good
                                                                ? t(
                                                                      'good_answer',
                                                                  )
                                                                : t(
                                                                      'bad_answer',
                                                                  )}
                                                        </Text>
                                                    </View>
                                                ))}
                                            {question.answerList
                                                .getList()
                                                .map((a: Answer, i: number) => {
                                                    if (
                                                        card instanceof
                                                            Question ||
                                                        ((a.goodAnswer.get() ===
                                                            true ||
                                                            a ===
                                                                question.myAnswer) &&
                                                            question.myAnswer !==
                                                                null)
                                                    ) {
                                                        let borderColor =
                                                            getColor('border');
                                                        let backgroundColor =
                                                            getColor(
                                                                'background',
                                                            );
                                                        let textColor =
                                                            getColor('text');
                                                        if (
                                                            question.myAnswer !==
                                                            null
                                                        ) {
                                                            if (
                                                                question.myAnswer ===
                                                                a
                                                            ) {
                                                                if (
                                                                    a.goodAnswer.get() ===
                                                                    true
                                                                ) {
                                                                    borderColor =
                                                                        getColor(
                                                                            'success',
                                                                        );
                                                                    backgroundColor =
                                                                        getColor(
                                                                            'success',
                                                                        );
                                                                    textColor =
                                                                        'white';
                                                                } else {
                                                                    borderColor =
                                                                        getColor(
                                                                            'error',
                                                                        );
                                                                    backgroundColor =
                                                                        getColor(
                                                                            'error',
                                                                        );
                                                                    textColor =
                                                                        'white';
                                                                }
                                                            } else {
                                                                if (
                                                                    a.goodAnswer.get() ===
                                                                    true
                                                                ) {
                                                                    borderColor =
                                                                        getColor(
                                                                            'success',
                                                                        );
                                                                    backgroundColor =
                                                                        getColor(
                                                                            'success',
                                                                        );
                                                                    textColor =
                                                                        'white';
                                                                }
                                                            }
                                                        }
                                                        return (
                                                            <Pressable
                                                                onPress={() => {
                                                                    if (
                                                                        question.myAnswer ===
                                                                        null
                                                                    ) {
                                                                        next(
                                                                            card,
                                                                            a,
                                                                        );
                                                                    }
                                                                }}
                                                                style={{
                                                                    marginVertical: 10,
                                                                    width: '100%',
                                                                    borderRadius: 30,
                                                                    paddingVertical: 15,
                                                                    paddingHorizontal: 20,
                                                                    borderWidth: 1,
                                                                    borderColor,
                                                                    backgroundColor,
                                                                }}
                                                            >
                                                                <Text
                                                                    adjustsFontSizeToFit
                                                                    numberOfLines={
                                                                        1
                                                                    }
                                                                    style={{
                                                                        fontSize: 16,
                                                                        color: textColor,
                                                                        fontFamily:
                                                                            FONT_MEDIUM,
                                                                    }}
                                                                >
                                                                    {LETTERS[i]}
                                                                    .{' '}
                                                                    {a.title.get()}
                                                                </Text>
                                                            </Pressable>
                                                        );
                                                    }
                                                })}
                                        </View>
                                        <View
                                            style={{
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                                marginBottom: 10,
                                            }}
                                        >
                                            <CartoucheButton
                                                color={getColor('secondary')}
                                                height={45}
                                                fontSize={20}
                                                iconSize={20}
                                                onPress={() => {
                                                    if (
                                                        !(
                                                            card instanceof
                                                            Question
                                                        )
                                                    ) {
                                                        if (
                                                            question.myAnswer ===
                                                            null
                                                        ) {
                                                            question.myAnswer =
                                                                'dodge';
                                                        }

                                                        refCard.current.swipeRight();
                                                        if (card.last) {
                                                            setTimeout(() => {
                                                                finalAnimationRef.current?.startSuper();
                                                            }, 500);
                                                        }
                                                    }
                                                }}
                                                title={t('next')}
                                            />
                                        </View>
                                    </View>
                                );
                            }
                        }}
                    />*/}
                    <View
                        style={{
                            padding: 20,
                            marginBottom: NAVIGATION_BAR_HEIGHT + TAB_HEIGHT,
                        }}
                    >
                        <CartoucheButton
                            color={getColor('secondary')}
                            height={45}
                            fontSize={20}
                            iconSize={20}
                            onPress={() => {
                                props.navigation.goBack();
                                quiz.sendAnswer();
                            }}
                            title={t('leave_quiz')}
                        />
                    </View>

                    <BadAnim
                        pause={true}
                        duration={2000}
                        ref={badAnimationRef}
                        width={height() * 0.8}
                        height={height() * 0.8}
                        minConf={100}
                        maxConf={100}
                    />
                    <SucccessAnimation ref={goodAnimationRef} />
                    <SuperSucccessAnimation ref={finalAnimationRef} />
                </View>
            </Header>
        </View>
    );
};

export const stylesCard = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardContainer: {
        width: '92%',
        flex: 1,
    },
    card: {
        width: '100%',
        height: '100%',
        flex: 1,
        backgroundColor: getColor('background'),
        borderRadius: 25,
        paddingHorizontal: 20,
        paddingVertical: 12,
        shadowColor: '#000000',
        shadowOffset: {
            width: 8,
            height: 16,
        },
        shadowOpacity: 0.14,
        shadowRadius: 6.6,
    },
    cardImg: {
        width: '100%',
        height: '100%',
        borderRadius: 13,
    },
});
