import {
    View,
    Text,
    Pressable,
    ActivityIndicator,
    ScrollView,
    Keyboard,
    Image,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import { goBackTo, isCloseToBottom, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import { MpInput } from '../base/MpInput';
import { useEffect, useState } from 'react';
import User from '../object/User';
import {
    MagnifyingGlassIcon,
    UserIcon,
    XMarkIcon,
} from 'react-native-heroicons/solid';
import JTOListFetch from '../object/JTOListFetch';
import { GeSpinner } from '../component/GeSpinner';
import { openAction } from '../utils/utils';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import { PastilleView } from '../component/PastilleView';
import { SERVER_URL } from '../utils/constants';
import useJTOState from '../utils/jto/useJTOState';

export const SelectInviteModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const userList = useJTOState(new JTOListFetch('/chat/search', User));

    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        userList.reset();
        userList.fetch({
            search,
            id_event: User._instance.getEvent()!.getJTOIdentifier(),
        });
        setLoading(false);
    }, []);

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                backButton={true}
                full={true}
                navigation={props.navigation}
                title={t('create_chat')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        paddingHorizontal: 20,
                        marginTop: 20,
                        flex: 1,
                        paddingBottom: NAVIGATION_BAR_HEIGHT,
                    }}
                >
                    <View
                        style={{
                            marginTop: 15,
                            marginBottom: 15,
                        }}
                    >
                        <MpInput
                            spaceIcon={10}
                            value={search}
                            leftIcon={
                                <MagnifyingGlassIcon
                                    size={20}
                                    color={getColor('hint')}
                                />
                            }
                            onChangeText={(e) => {
                                setSearch(e);
                                userList.reset();
                                userList.refetch({
                                    search: e,
                                    id_event: User._instance
                                        .getEvent()!
                                        .getJTOIdentifier(),
                                });
                            }}
                            placeholder={t('search_user') ?? ''}
                            style={styles.inputSingleLine}
                        />
                    </View>

                    {!loading ? (
                        <View
                            style={{
                                flex: 1,
                            }}
                        >
                            <View
                                // @ts-ignore
                                keyboardDismissMode='on-drag'
                                // @ts-ignore
                                onScroll={({ nativeEvent }) => {
                                    if (isCloseToBottom(nativeEvent)) {
                                        userList.more({
                                            search,
                                            id_event: User._instance
                                                .getEvent()!
                                                .getJTOIdentifier(),
                                        });
                                    }
                                    Keyboard.dismiss();
                                }}
                                onMomentumScrollEnd={({ nativeEvent }) => {
                                    if (isCloseToBottom(nativeEvent)) {
                                        userList.more({
                                            search,
                                            id_event: User._instance
                                                .getEvent()!
                                                .getJTOIdentifier(),
                                        });
                                    }
                                }}
                                scrollEventThrottle={1}
                            >
                                {userList.getList().map((user, index) => {
                                    return (
                                        <Pressable
                                            key={user.getJTOIdentifier()}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                padding: 5,
                                            }}
                                            onPress={() => {
                                                goBackTo(
                                                    props.navigation,
                                                    'Tab',
                                                    'SelectInviteModal',
                                                );
                                                props.navigation.navigate(
                                                    'MessagePage',
                                                    {
                                                        user,
                                                    },
                                                );
                                                User._instance.setCurrentChatId(
                                                    user.getJTOIdentifier(),
                                                );
                                                User._instance.setCurrentChatType(
                                                    'User',
                                                );
                                                openAction();
                                            }}
                                        >
                                            {user.avatar.get() === undefined ? (
                                                <PastilleView
                                                    size={30}
                                                    icon={
                                                        <UserIcon
                                                            size={20}
                                                            color={'white'}
                                                        />
                                                    }
                                                    color={getColor(
                                                        'secondary',
                                                    )}
                                                />
                                            ) : (
                                                <Image
                                                    style={{
                                                        resizeMode: 'cover',
                                                        height: 30,
                                                        width: 30,
                                                        borderRadius: 30,
                                                    }}
                                                    source={{
                                                        uri:
                                                            SERVER_URL +
                                                            '/image/' +
                                                            user.avatar.get(),
                                                    }}
                                                />
                                            )}
                                            <Text
                                                style={[
                                                    styles.textMedium,
                                                    {
                                                        marginLeft: 10,
                                                    },
                                                ]}
                                            >
                                                {user.getDisplayName()}
                                            </Text>
                                        </Pressable>
                                    );
                                })}

                                {(userList.isLoading() ||
                                    !userList.isComplite()) && (
                                    <View
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: 70,
                                        }}
                                    >
                                        <GeSpinner
                                            color={getColor('primary')}
                                        />
                                    </View>
                                )}

                                {!userList.isLoading() &&
                                    userList.isComplite() &&
                                    userList.getList().length === 0 && (
                                        <View
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: 70,
                                            }}
                                        >
                                            <Text style={styles.text}>
                                                {t('no_user_found')}
                                            </Text>
                                        </View>
                                    )}
                            </View>

                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.error,
                                        {
                                            marginBottom: 15,
                                            marginLeft: 15,
                                            marginRight: 15,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}
                        </View>
                    ) : (
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
