import { NavigationContainer } from '@react-navigation/native';
import { getColor, getTheme } from '../utils/theme';
import { useTranslation } from 'react-i18next';
import { createStackNavigator } from '@react-navigation/stack';
import { TabNavigation } from './TabNavigation';
import { ParamModal } from '../modal/ParamModal';
import { LanguageModal } from '../modal/LanguageModal';
import { PasswordModal } from '../modal/PasswordModal';
import { LoginPage } from '../pages/LoginPage';
import { FirstLoadingPage } from '../pages/FirstLoadingPage';
//import Keychain from 'react-native-keychain';
import { BlockUserModal } from '../modal/BlockUserModal';
import { ForgetPage } from '../pages/ForgetPage';
import { CodePage } from '../pages/CodePage';
import { PasswordPage } from '../pages/PasswordPage';
import { MenuPostModal } from '../modal/MenuPostModal';
import { PostModal } from '../modal/PostModal';
import { EventModal } from '../modal/EventModal';
import { EventPage } from '../pages/EventPage';
import { MenuMyProfileModal } from '../modal/MenuMyProfileModal';
import { MenuProfileModal } from '../modal/MenuProfileModal';
import { FirstnameModal } from '../modal/FirstnameModal';
import { LastnameModal } from '../modal/LastnameModal';
import { DescriptionModal } from '../modal/DescriptionModal';
import { PostDeleteModal } from '../modal/PostDeleteModal';
import { PostFavoriteModal } from '../modal/PostFavoriteModal';
import { SubEventModal } from '../modal/SubEventModal';
import { ProfilPictureModal } from '../modal/ProfilPictureModal';
import { PostPictureModal } from '../modal/PostPictureModal';
import { RegisterDeleteModal } from '../modal/RegisterDeleteModal';
import { MenuEventModal } from '../modal/MenuEventModal';
import { ChatPage } from '../pages/ChatPage';
import { MessagePage } from '../pages/MessagePage';
import { ProfilFileModal } from '../modal/ProfilFileModal';
import { ProfilFileEditModal } from '../modal/ProfilFileEditModal';
import { ProfilFileDelModal } from '../modal/ProfilFileDelModal';
import { ContactPage } from '../pages/ContactPage';
import { NetworkModal } from '../modal/NetworkModal';
import { SelectInviteModal } from '../modal/SelectInviteModal';
import { MenuChatGroupModal } from '../modal/MenuChatGroupModal';
import { ChatUserListModal } from '../modal/ChatUserListModal';
import { MenuAddChatModal } from '../modal/MenuAddChatModal';
import { AddChatGroupModal } from '../modal/AddChatGroupModal';
import { LeaveChatGroupModal } from '../modal/LeaveChatGroupModal';
import { PostCommentModal } from '../modal/PostCommentModal';
import { ProfilePage } from '../pages/ProfilePage';
import User from '../object/User';
import { NuageModal } from '../modal/NuageModal';
import { ReservationScreen } from './profile/ReservationScreen';
import { PostProfilePage } from './profile/PostProfilePage';
import { PostEventPage } from './event/PostEventPage';
import { InscripEventPage } from './event/InscripEventPage';
import { DocumentEventModal } from '../modal/DocumentEventModal';
import { HistoryPage } from '../pages/HistoryPage';
import { ChatUserAddModal } from '../modal/ChatUserAddModal';
import { ErrorPage } from '../pages/ErrorPage';
import { GroupNameModal } from '../modal/GroupNameModal';
import { GroupPictureModal } from '../modal/GroupPictureModal';
import { TrombinoPage } from '../pages/TrombinoPage';
import { ConfidentialModal } from '../modal/ConfidentialModal';
import { GroupFilterModal } from '../modal/GroupFilterModal';
import { BlockedListModal } from '../modal/BlockedListModal';
import { ValidatePage } from '../pages/ValidatePage';
import { WordPage } from '../pages/WordPage';
import { InscripPage } from '../pages/InscripPage';
import { WelcomeModal } from '../modal/WelcomeModal';
import { QuizModal } from '../modal/QuizModal';
import { AnswerModal } from '../modal/AnswerModal';
import { ScoreModal } from '../modal/ScoreModal';
import { ProfilVisitCardModal } from '../modal/ProfilVisitCardModal';
import { ProfilVisitCardDelModal } from '../modal/ProfilVisitCardDelModal';
import { DirectQuizModal } from '../modal/DirectQuizModal';
import { BadgePage } from '../pages/BadgePage';
import { BigBadgePage } from '../pages/BigBadgePage';
import { createRef } from 'react';
import { RankPage } from '../pages/RankPage';
import { DeleteUserModal } from '../modal/DeleteUserModal';
import { VideoPage } from '../pages/VideoPage';
import { InterestPage } from '../pages/InterestPage';
import { FavoritePage } from '../pages/FavoritePage';
import { PostRemoveFavoriteModal } from '../modal/PostRemoveFavoriteModal';
import { PostReactModal } from '../pages/PostReactModal';
import { BeforeLoginPage } from '../pages/BeforeLoginPage';
import React from 'react';
import { MustLoginPage } from '../modal/MustLoginPage';
import { ScanBadgePage } from '../pages/ScanBadgePage';
import { ExposantPage } from '../pages/ExposantPage';

const Stack = createStackNavigator();

export let translate: any;
export const navigationRef: any = createRef();

export const AppNavigation = () => {
    const { t } = useTranslation();

    User.translation = t;

    const linking = {
        config: {
            screens: {
                Home: '',
                Sharing: 'sharing',
            },
        },
        prefixes: ['http://localhost:8080', 'savoiexpoconnect://'],
    };

    return (
        <NavigationContainer
            theme={getTheme()}
            ref={navigationRef}
            linking={linking}
        >
            <Stack.Navigator
                screenOptions={{
                    cardStyle: { backgroundColor: 'transparent' },
                    headerShown: false,
                }}
            >
                <Stack.Group>
                    <Stack.Screen
                        name='FirstLoading'
                        component={FirstLoadingPage}
                    />
                    <Stack.Screen
                        name='Sharing'
                        component={FirstLoadingPage}
                    />
                    <Stack.Screen
                        name='sharing'
                        component={FirstLoadingPage}
                    />
                    <Stack.Screen
                        name='Event'
                        component={EventPage}
                        options={{
                            title: 'Event',
                        }}
                    />
                    <Stack.Screen
                        name='ScanBadgePage'
                        component={ScanBadgePage}
                    />
                    <Stack.Screen
                        name='Login'
                        component={LoginPage}
                    />
                    <Stack.Screen
                        name='BeforeLogin'
                        component={BeforeLoginPage}
                    />

                    <Stack.Screen
                        name='InterestPage'
                        component={InterestPage}
                    />
                    <Stack.Screen
                        name='ExposantPage'
                        component={ExposantPage}
                    />
                    <Stack.Screen
                        name='Inscrip'
                        component={InscripPage}
                    />
                    <Stack.Screen
                        name='Forget'
                        component={ForgetPage}
                    />
                    <Stack.Screen
                        name='Code'
                        component={CodePage}
                    />
                    <Stack.Screen
                        name='Password'
                        component={PasswordPage}
                    />
                    <Stack.Screen
                        name='Tab'
                        component={TabNavigation}
                    />
                    <Stack.Screen
                        name='Contact'
                        component={ContactPage}
                    />
                    <Stack.Screen
                        name='ChatPage'
                        component={ChatPage}
                    />
                    <Stack.Screen
                        name='MessagePage'
                        component={MessagePage as any}
                    />
                    <Stack.Screen
                        name='History'
                        component={HistoryPage}
                    />
                    <Stack.Screen
                        name='Error'
                        component={ErrorPage}
                    />
                    <Stack.Screen
                        name='TrombinoPage'
                        component={TrombinoPage}
                    />
                    <Stack.Screen
                        name='WordPage'
                        component={WordPage as any}
                    />
                    <Stack.Screen
                        name='Validate'
                        component={ValidatePage}
                    />
                    <Stack.Screen
                        name='FavoritePage'
                        component={FavoritePage}
                    />
                </Stack.Group>

                <Stack.Group>
                    <Stack.Screen
                        name='MustLogin'
                        component={MustLoginPage as any}
                    />
                    <Stack.Screen
                        name='PostPictureModal'
                        component={PostPictureModal as any}
                    />
                    <Stack.Screen
                        name='BadgePage'
                        component={BadgePage}
                    />
                    <Stack.Screen
                        name='PostReactModal'
                        component={PostReactModal}
                    />
                    <Stack.Screen
                        name='VideoPage'
                        component={VideoPage as any}
                    />
                    <Stack.Screen
                        name='DeleteUserModal'
                        component={DeleteUserModal}
                    />
                    <Stack.Screen
                        name='RankPage'
                        component={RankPage}
                    />
                    <Stack.Screen
                        name='BigBadgePage'
                        component={BigBadgePage}
                    />
                    <Stack.Screen
                        name='PasswordModal'
                        component={PasswordModal}
                    />
                    <Stack.Screen
                        name='TrombinoModal'
                        component={TrombinoPage}
                    />
                    <Stack.Screen
                        name='LanguageModal'
                        component={LanguageModal}
                    />
                    <Stack.Screen
                        name='MenuMyProfileModal'
                        component={MenuMyProfileModal}
                    />
                    <Stack.Screen
                        name='MenuProfileModal'
                        component={MenuProfileModal as any}
                    />
                    <Stack.Screen
                        name='BlockUserModal'
                        component={BlockUserModal}
                    />
                    <Stack.Screen
                        name='BlockedListModal'
                        component={BlockedListModal}
                    />
                    <Stack.Screen
                        name='ProfilePage'
                        component={ProfilePage}
                    />
                    <Stack.Screen
                        name='PostModal'
                        component={PostModal}
                    />
                    <Stack.Screen
                        name='EventModal'
                        component={EventPage}
                    />
                    <Stack.Screen
                        name='Param'
                        component={ParamModal}
                    />
                    <Stack.Screen
                        name='SubEventModal'
                        component={SubEventModal}
                    />
                    <Stack.Screen
                        name='QuizModal'
                        component={QuizModal}
                    />
                    <Stack.Screen
                        name='RealTimeQuizModal'
                        component={NuageModal}
                    />
                    <Stack.Screen
                        name='DirectQuizModal'
                        component={DirectQuizModal}
                    />
                    <Stack.Screen
                        name='AnswerModal'
                        component={AnswerModal}
                    />
                    <Stack.Screen
                        name='PostCommentModal'
                        component={PostCommentModal}
                    />
                    <Stack.Screen
                        name='AddChatGroupModal'
                        component={AddChatGroupModal}
                    />
                    <Stack.Screen
                        name='ScoreModal'
                        component={ScoreModal}
                    />
                    <Stack.Screen
                        name='MenuAddChatModal'
                        component={MenuAddChatModal}
                    />
                    <Stack.Screen
                        name='SelectInviteModal'
                        component={SelectInviteModal}
                    />
                    <Stack.Screen
                        name='ProfilPictureModal'
                        component={ProfilPictureModal}
                    />
                    <Stack.Screen
                        name='FirstnameModal'
                        component={FirstnameModal}
                    />
                    <Stack.Screen
                        name='LastnameModal'
                        component={LastnameModal}
                    />
                    <Stack.Screen
                        name='DescriptionModal'
                        component={DescriptionModal}
                    />
                    <Stack.Screen
                        name='NetworkModal'
                        component={NetworkModal as any}
                    />
                    <Stack.Screen
                        name='ProfilFileModal'
                        component={ProfilFileModal}
                    />
                    <Stack.Screen
                        name='ProfilVisitCardModal'
                        component={ProfilVisitCardModal}
                    />
                    <Stack.Screen
                        name='ProfilFileEditModal'
                        component={ProfilFileEditModal}
                    />
                    <Stack.Screen
                        name='ProfilFileDelModal'
                        component={ProfilFileDelModal}
                    />
                    <Stack.Screen
                        name='MenuPostModal'
                        component={MenuPostModal}
                    />
                    <Stack.Screen
                        name='PostDeleteModal'
                        component={PostDeleteModal}
                    />
                    <Stack.Screen
                        name='PostFavoriteModal'
                        component={PostFavoriteModal}
                    />
                    <Stack.Screen
                        name='PostRemoveFavoriteModal'
                        component={PostRemoveFavoriteModal}
                    />
                    <Stack.Screen
                        name='ReservationScreen'
                        component={ReservationScreen}
                    />
                    <Stack.Screen
                        name='PostProfilePage'
                        component={PostProfilePage}
                    />
                    <Stack.Screen
                        name='RegisterDeleteModal'
                        component={RegisterDeleteModal}
                    />
                    <Stack.Screen
                        name='MenuEventModal'
                        component={MenuEventModal}
                    />
                    <Stack.Screen
                        name='PostEventPage'
                        component={PostEventPage as any}
                    />
                    <Stack.Screen
                        name='InscripEventPage'
                        component={InscripEventPage as any}
                    />
                    <Stack.Screen
                        name='DocumentEventModal'
                        component={DocumentEventModal}
                    />
                    <Stack.Screen
                        name='MenuChatGroupModal'
                        component={MenuChatGroupModal as any}
                    />
                    <Stack.Screen
                        name='ProfilVisitCardDelModal'
                        component={ProfilVisitCardDelModal}
                    />
                    <Stack.Screen
                        name='GroupNameModal'
                        component={GroupNameModal}
                    />
                    <Stack.Screen
                        name='GroupPictureModal'
                        component={GroupPictureModal}
                    />
                    <Stack.Screen
                        name='ChatUserListModal'
                        component={ChatUserListModal as any}
                    />
                    <Stack.Screen
                        name='LeaveChatGroupModal'
                        component={LeaveChatGroupModal}
                    />
                    <Stack.Screen
                        name='ChatUserAddModal'
                        component={ChatUserAddModal as any}
                    />
                    <Stack.Screen
                        name='ConfidentialModal'
                        component={ConfidentialModal}
                    />
                    <Stack.Screen
                        name='GroupFilterModal'
                        component={GroupFilterModal}
                    />
                    <Stack.Screen
                        name='WelcomeModal'
                        component={WelcomeModal}
                    />
                </Stack.Group>
            </Stack.Navigator>
        </NavigationContainer>
    );
};
