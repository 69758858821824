import { useEffect, useRef } from 'react';
import User from '../object/User';
import { FirstLoadingPage } from '../pages/FirstLoadingPage';
import { AppNavigation } from './AppNavigation';
//import * as Keychain from 'react-native-keychain';
import { QueryInstance } from 'mp-ts-query';
import { SERVER_URL } from '../utils/constants';
import Event from '../object/Event';
import { initScreen } from '../utils/screen';
import { ToastManagerView } from './toast/ToastManagerView';
import SocketInstance from '../utils/SocketInstance';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { fetchNewMessage } from '../utils/utils';

QueryInstance.setServerUrl(SERVER_URL);
QueryInstance.setAccessToken(async () => {
    const key = localStorage.getItem('happlyevent.access_token');
    return key ?? '';
    /*if (key) {
        return key.password;
    } else {
        return '';
    }*/
});
QueryInstance.setRefreshToken(async () => {
    const key = localStorage.getItem('happlyevent.refresh_token');
    return key ?? '';
});

// globale user var
const user = new User();
User._instance = user;

// global event var
const event = new Event();
Event._instance = event;
import React from 'react';
import { View } from 'react-native';

export const AppRoutes = () => {
    const insets = useSafeAreaInsets();
    initScreen(insets);

    useEffect(() => {
        SocketInstance.get().open();
        //fetchNewMessage();
    }, []);

    return (
        <View
            style={{
                flex: 1,
                display: 'flex',
                height: '100%',
                width: '100%',
            }}
        >
            <AppNavigation />
            <ToastManagerView />
        </View>
    );
};
