import {
    ActivityIndicator,
    InteractionManager,
    Pressable,
    RefreshControl,
    ScrollView,
    Text,
    View,
} from 'react-native';
import React from 'react';
import { PageHeader } from '../component/PageHeader';
import { PostView } from '../component/PostView';
import Post from '../object/Post';
import { styles } from '../utils/styles';
import { IPageProps } from './IPageProps';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { useCallback, useEffect, useState } from 'react';
import { FONT_BOLD, getColor } from '../utils/theme';
import User from '../object/User';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { height, isCloseToBottom, width } from '../utils/utils';
import { GeSpinner } from '../component/GeSpinner';
import { CartoucheButton } from '../component/CartoucheButton';
import {
    FunnelIcon,
    MagnifyingGlassIcon,
    PlusIcon,
    TrashIcon,
} from 'react-native-heroicons/solid';
import { Header } from '../component/Header';
import { t } from 'i18next';
import { PastilleView } from '../component/PastilleView';
import { HistoryView } from '../component/HistoryView';
import { InviteView } from '../component/InviteView';
import { MpInput } from '../base/MpInput';
import Group from '../object/Group';
import useJTOState from '../utils/jto/useJTOState';
import Event from '../object/Event';
import JTOListFetch from '../object/JTOListFetch';
import InviteTiny from '../object/compressed/global/InviteTiny';
import { CloseAllButton } from '../component/CloseAllButton';

export const TrombinoPage = (props: any) => {
    const [refreshing, setRefreshing] = useState(false);

    const [search, setSearch] = useState('');
    const [groupList, setGroupList] = useState([] as Group[]);

    const event = useJTOState((props as any).route.params.event);

    const trombinoscopeList = useJTOState(
        event.trombinoscopeList as JTOListFetch<InviteTiny>,
    );

    const group = [] as number[];
    for (const gr of groupList) {
        group.push(gr.getJTOIdentifier());
    }

    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            try {
                event.trombinoscopeList.reset();
                event.trombinoscopeList.refetch({
                    id: event.getJTOIdentifier(),
                    search,
                    group: group.join(','),
                });
            } catch (e) {}
        });

        return () => {
            InteractionManager.runAfterInteractions(() => {
                try {
                    event.trombinoscopeList.reset();
                } catch (e) {}
            });
        };
    }, [groupList, search]);

    const modal = props.route.params.modal ?? false;

    return (
        <View
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                modal={modal}
                title={t('trobino')}
                line={1}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
                backButton={true}
                navigation={props.navigation}
                after={
                    <View
                        style={{
                            paddingHorizontal: 10,
                            marginTop: 15,
                            marginBottom: 5,
                        }}
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                            }}
                        >
                            <MpInput
                                value={search}
                                leftIcon={
                                    <MagnifyingGlassIcon
                                        size={20}
                                        color={getColor('hint')}
                                    />
                                }
                                onChangeText={(e) => {
                                    setSearch(e);
                                }}
                                placeholder={t('search_user') ?? ''}
                                style={styles.inputHeader}
                            />
                            <Pressable
                                style={{
                                    marginLeft: 15,
                                }}
                                onPress={() => {
                                    props.navigation.navigate(
                                        'GroupFilterModal',
                                        {
                                            event,
                                            groupList,
                                            setGroupList,
                                        },
                                    );
                                }}
                            >
                                <PastilleView
                                    size={35}
                                    color={getColor('secondary')}
                                    icon={
                                        <FunnelIcon
                                            fill='white'
                                            size={23}
                                        />
                                    }
                                />
                            </Pressable>
                            {groupList.length !== 0 && (
                                <Pressable
                                    style={{
                                        marginLeft: 15,
                                    }}
                                    onPress={() => {
                                        setGroupList([]);
                                    }}
                                >
                                    <PastilleView
                                        size={35}
                                        color={getColor('secondary')}
                                        icon={
                                            <TrashIcon
                                                fill='white'
                                                size={23}
                                            />
                                        }
                                    />
                                </Pressable>
                            )}
                        </View>
                        <View
                            style={{
                                height: 10,
                            }}
                        ></View>
                    </View>
                }
            >
                <ScrollView
                    // @ts-ignore
                    // @ts-ignore
                    onScroll={({ nativeEvent }: any) => {
                        if (isCloseToBottom(nativeEvent)) {
                            trombinoscopeList.more({
                                id: event.getJTOIdentifier(),
                                search,
                                group: group.join(','),
                            });
                        }
                    }}
                    onMomentumScrollEnd={({ nativeEvent }: any) => {
                        if (isCloseToBottom(nativeEvent)) {
                            trombinoscopeList.more({
                                id: event.getJTOIdentifier(),
                                search,
                                group: group.join(','),
                            });
                        }
                    }}
                    scrollEventThrottle={1}
                    style={{
                        width: width(),
                        zIndex: 500,
                        marginTop: -HEADER_BONUS_HEIGHT,
                    }}
                    refreshControl={
                        <RefreshControl
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            onRefresh={() => {
                                setRefreshing(false);
                                trombinoscopeList.reset();
                                trombinoscopeList.refetch({
                                    id: event.getJTOIdentifier(),
                                    search,
                                    group: group.join(','),
                                });
                            }}
                        />
                    }
                >
                    <View
                        style={{
                            height: modal
                                ? HEADER_BONUS_HEIGHT
                                : HEADER_BONUS_HEIGHT + 20,
                        }}
                    ></View>
                    {trombinoscopeList.map(
                        (item: InviteTiny, index: number) => {
                            return (
                                <InviteView
                                    key={item.getJTOIdentifier()}
                                    navigation={props.navigation}
                                    invite={item}
                                />
                            );
                        },
                    )}
                    {(trombinoscopeList.isLoading() ||
                        !trombinoscopeList.isComplite()) && (
                        <View
                            style={[
                                styles.boxCenter,
                                marginBottom(50),
                                trombinoscopeList.size() === 0
                                    ? marginTop(200)
                                    : marginTop(50),
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    )}
                    {trombinoscopeList.size() === 0 &&
                        !trombinoscopeList.isLoading() &&
                        trombinoscopeList.isComplite() && (
                            <View
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height:
                                        height() -
                                        STATUS_BAR_HEIGHT -
                                        NAVIGATION_BAR_HEIGHT -
                                        TAB_HEIGHT -
                                        200,
                                    flex: 1,
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 25,
                                        fontFamily: FONT_BOLD,
                                        color: getColor('text'),
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('no_people')}
                                </Text>
                            </View>
                        )}
                </ScrollView>
            </Header>

            <CloseAllButton
                onPress={() => {
                    props.navigation.popToTop();
                }}
            />
        </View>
    );
};
