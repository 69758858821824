import JTOObject from '../../../utils/jto/JTOObject';
import JTOSimple from '../../../utils/jto/JTOSimple';
import JTOProperty from '../../../utils/jto/JTOProperty';
import JTORelProperty from '../../../utils/jto/JTORelProperty';
import JTOList from '../../../utils/jto/JTOList';
import JTOElement from '../../../utils/jto/JTOElement';
import JTOListFetch from '../../JTOListFetch';
import UserTiny from '../global/UserTiny';
import ChatGroupTiny from './ChatGroupTiny';

export default class UserConnect extends JTOObject {
    public static _TYPE: string = 'User';

    public connectList: JTOList<UserTiny | ChatGroupTiny>;

    constructor(parent?: JTOElement | null) {
        super(UserConnect._TYPE, parent);
        this.connectList = new JTOList<UserTiny | ChatGroupTiny>(
            [UserTiny, ChatGroupTiny],
            'connect',
            this,
        );
    }
}
