import {
    View,
    Text,
    Pressable,
    TextInput,
    ActivityIndicator,
    Platform,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_BOLD, FONT_REGULAR, getColor } from '../utils/theme';
import { goBackTo, height, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Keychain from 'react-native-keychain';
import { MpInput } from '../base/MpInput';
import { useEffect, useState } from 'react';
import User from '../object/User';
import {
    CalendarDaysIcon,
    ClockIcon,
    XMarkIcon,
} from 'react-native-heroicons/solid';
import { post } from '../utils/http';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import Post from '../object/Post';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import { applyResult } from '../utils/applyResult';
//import DateTimePicker from '@react-native-community/datetimepicker';
import { DateTime } from 'luxon';
import { PastilleView } from '../component/PastilleView';

export const PostFavoriteModal = (props: IModalProps) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [time, setTime] = useState<'start' | 'end'>('start');

    const [mode, setMode] = useState('date');
    const [show, setShow] = useState(false);

    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const id = (props as any).route.params.post.getJTOIdentifier();

    const addFavorite = () => {
        setLoading(true);
        post('/event/favorite/post', {
            id: id,
            id_event: User._instance.getEvent()?.getJTOIdentifier(),
            is_favorite: true,
            start_date: startDate?.toISOString(),
            end_date: endDate?.toISOString(),
        })
            .then((res) => {
                if (HTTPUtils.isSuccess(res)) {
                    setLoading(false);
                    ToastManager.instance.addToast(
                        new Toast(t('add_favorite_success'), 5000, 'success'),
                    );
                    applyResult(
                        HTTPUtils.getResult(res),
                        (props as any).route.params.post,
                        true,
                    );
                    goBackTo(
                        props.navigation,
                        ['Tab', 'PostCommentModal'],
                        'PostFavoriteModal',
                    );
                } else {
                    setLoading(false);
                    setError(t(HTTPUtils.getError(res)) ?? '');
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(t('went_wrong_no_internet') ?? '');
            });
    };

    const [date, setDate] = useState<Date | null>(null);

    const onChange = (event: any, selectedDate: Date) => {
        let currentDate = selectedDate;

        // get hour and minute from date
        const hour = currentDate.getHours();
        const minute = currentDate.getMinutes();

        // create a new date with your date and time
        if (mode === 'time') {
            if (time === 'start') {
                currentDate = new Date(
                    startDate!.getFullYear(),
                    startDate!.getMonth(),
                    startDate!.getDate(),
                    hour,
                    minute,
                );
            } else {
                currentDate = new Date(
                    endDate!.getFullYear(),
                    endDate!.getMonth(),
                    endDate!.getDate(),
                    hour,
                    minute,
                );
            }
        }

        setShow(false);
        if (time === 'start') {
            setStartDate(currentDate);
        } else {
            setEndDate(currentDate);
        }
    };

    const showMode = (currentMode: any, time: 'start' | 'end') => {
        setShow(true);
        setTime(time);
        setMode(currentMode);
    };

    const showDatepicker = (time: 'start' | 'end') => {
        showMode('date', time);
    };

    const showTimepicker = (time: 'start' | 'end') => {
        showMode('time', time);
    };

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('add_favorite')}
                line={1}
                backButton={true}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: 15,
                    }}
                >
                    {loading ? (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    height: height() * 0.5,
                                },
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View
                            style={{
                                paddingHorizontal: 20,
                            }}
                        >
                            <Text
                                style={{
                                    color: getColor('text'),
                                    fontSize: 15,
                                    paddingBottom: 20,
                                }}
                            >
                                {t('add_favorite_description')}
                            </Text>

                            <Text
                                style={{
                                    color: getColor('text'),
                                    fontSize: 15,
                                    fontFamily: FONT_BOLD,
                                }}
                            >
                                {t('start_date_favorite')}
                            </Text>

                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingBottom: 20,
                                }}
                            >
                                <Text
                                    style={{
                                        color: getColor('text'),
                                        paddingRight: 20,
                                        fontSize: 15,
                                    }}
                                >
                                    {startDate
                                        ? startDate.toLocaleString()
                                        : t('no_date')}
                                </Text>

                                <Pressable
                                    style={{
                                        paddingRight: 20,
                                    }}
                                    onPress={() => {
                                        showDatepicker('start');
                                    }}
                                >
                                    <PastilleView
                                        size={40}
                                        icon={
                                            <CalendarDaysIcon
                                                size={25}
                                                color={'white'}
                                            />
                                        }
                                        color={getColor('primary')}
                                    />
                                </Pressable>

                                {startDate && (
                                    <Pressable
                                        onPress={() => {
                                            showTimepicker('start');
                                        }}
                                    >
                                        <PastilleView
                                            size={40}
                                            icon={
                                                <ClockIcon
                                                    size={25}
                                                    color={'white'}
                                                />
                                            }
                                            color={getColor('primary')}
                                        />
                                    </Pressable>
                                )}
                            </View>

                            <Text
                                style={{
                                    color: getColor('text'),
                                    fontSize: 15,
                                    fontFamily: FONT_BOLD,
                                }}
                            >
                                {t('end_date_favorite')}
                            </Text>

                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingBottom: 20,
                                }}
                            >
                                <Text
                                    style={{
                                        color: getColor('text'),
                                        paddingRight: 20,
                                    }}
                                >
                                    {endDate
                                        ? endDate.toLocaleString()
                                        : t('no_date')}
                                </Text>

                                <Pressable
                                    style={{
                                        paddingRight: 20,
                                    }}
                                    onPress={() => {
                                        showDatepicker('end');
                                    }}
                                >
                                    <PastilleView
                                        size={40}
                                        icon={
                                            <CalendarDaysIcon
                                                size={25}
                                                color={'white'}
                                            />
                                        }
                                        color={getColor('primary')}
                                    />
                                </Pressable>

                                {endDate && (
                                    <Pressable
                                        onPress={() => {
                                            showTimepicker('end');
                                        }}
                                    >
                                        <PastilleView
                                            size={40}
                                            icon={
                                                <ClockIcon
                                                    size={25}
                                                    color={'white'}
                                                />
                                            }
                                            color={getColor('primary')}
                                        />
                                    </Pressable>
                                )}
                            </View>

                            {/*show && (
                                /DateTimePicker
                                    testID='dateTimePicker'
                                    value={
                                        time === 'start'
                                            ? startDate ?? new Date()
                                            : endDate ?? new Date()
                                    }
                                    mode={mode as any}
                                    is24Hour={true}
                                    onChange={onChange as any}
                                />
                                )*/}

                            <CartoucheButton
                                title={t('add')}
                                color={getColor('primary')}
                                onPress={() => {
                                    addFavorite();
                                }}
                                height={70}
                            />
                            <View></View>
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
