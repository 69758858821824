import * as React from 'react';
import { View } from 'react-native';
import Svg, { Path, SvgProps, Text } from 'react-native-svg';
import { FONT_BOLD, FONT_HEAVY } from '../../utils/theme';
import BronzeAchivement from './BronzeAchivement';
import GoldAchivement from './GoldAchivement';
import SilverAchivement from './SilverAchivement';

export interface GenericAchivementProps extends SvgProps {
    icon: React.ReactNode;
    height: number;
    width: number;
    count: number;
    level: number;
    owned: boolean;
    marginBottom?: number;
}

function GenericAchivement(props: GenericAchivementProps) {
    const { marginBottom = 10 } = props;
    let svgCompo: any;
    if (props.level === 1) {
        svgCompo = (
            <BronzeAchivement
                owned={props.owned}
                height={props.height}
                width={props.width}
                count={props.count}
            />
        );
    } else if (props.level === 2) {
        svgCompo = (
            <SilverAchivement
                height={props.height}
                owned={props.owned}
                width={props.width}
                count={props.count}
            />
        );
    } else if (props.level === 3) {
        svgCompo = (
            <GoldAchivement
                owned={props.owned}
                height={props.height}
                width={props.width}
                count={props.count}
            />
        );
    }
    return (
        <View
            style={{
                display: 'flex',
                height: props.height,
                width: props.width,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {svgCompo}
            <View
                style={{
                    opacity: props.owned ? 1 : 0.65,
                    marginBottom,
                }}
            >
                {props.icon}
            </View>
        </View>
    );
}

export default GenericAchivement;
