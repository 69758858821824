import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import LottieView from 'lottie-react-native';
import { Text, View } from 'react-native';
import React from 'react';
//import { ScrollView } from 'react-native-gesture-handler';
import { NewspaperIcon } from 'react-native-heroicons/solid';
import { confettiAnim } from '../anim/confetti';
import GenericAchivement from '../component/achivement/GenericAchivement';
import { Header } from '../component/Header';
import { Badge } from '../object/Badge';
import JTOListFetch from '../object/JTOListFetch';
import User from '../object/User';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { styles } from '../utils/styles';
import { FONT_BOLD, FONT_MEDIUM, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { GOLD_COLOR } from './BadgePage';
import useJTOState from '../utils/jto/useJTOState';

interface IBigBadgePageProps {
    navigation: any;
}

export const BigBadgePage = (props: IBigBadgePageProps) => {
    const { t } = useTranslation();

    const badge = useJTOState((props as any).route.params.badge);

    let badgeBef: Badge | null = null;
    const badgeMax: Badge | null = badge;
    for (const b of User._badgeList.getList()) {
        if (
            b.getBadgeKey() === badge.getBadgeKey() &&
            b.level.get() === badge.level.get() - 1
        ) {
            badgeBef = b;
        }
    }

    return (
        <View
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                modal={true}
                title={t(badge.getTitle()) + ' ' + badge.getTier()}
                line={1}
                full
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: -40,
                        flex: 1,
                    }}
                >
                    <View
                        style={{
                            height: 60,
                            width: width(),
                        }}
                    ></View>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginHorizontal: 20,
                            flexWrap: 'wrap',
                        }}
                    >
                        {(props as any).route.params.anim && (
                            <View
                                style={{
                                    display: 'flex',
                                    width: width() - 40,
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    marginBottom: -10,
                                    marginTop: 20,
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontFamily: FONT_BOLD,
                                        fontSize: 32,
                                        color: badge.getColor(),
                                    }}
                                >
                                    {t('congrat_new_badge')}
                                </Text>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontFamily: FONT_MEDIUM,
                                        fontSize: 16,
                                        marginTop: 10,
                                        color: getColor('text'),
                                    }}
                                >
                                    {t('congrat_new_badge_desc')}
                                </Text>
                            </View>
                        )}
                        <GenericAchivement
                            owned={badge.hasBadge(User._instance)}
                            height={width() - 40}
                            width={width() - 40}
                            level={badge.level.get() ?? 0}
                            marginBottom={40}
                            count={badge.getBestCount()}
                            icon={badge.getBadgeIcon((width() - 40) * 0.35)}
                        />
                        <View
                            style={{
                                display: 'flex',
                                width: width() - 40,
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    textAlign: 'center',
                                    fontFamily: FONT_BOLD,
                                    fontSize: 32,
                                    color: badge.getColor(),
                                }}
                            >
                                {t(badge.getTitle()) + ' ' + badge.getTier()}
                            </Text>
                            <Text
                                style={{
                                    textAlign: 'center',
                                    fontFamily: FONT_MEDIUM,
                                    fontSize: 16,
                                    marginTop: 10,
                                    color: getColor('text'),
                                }}
                            >
                                {t(badge.getDescription(), {
                                    badge:
                                        t(badge.getTitle()) +
                                        ' ' +
                                        badge.getTier(),
                                    nb: badge.getBestCount(),
                                })}
                            </Text>
                        </View>
                        <View
                            style={{
                                marginTop: 20,
                            }}
                        >
                            <View
                                style={{
                                    height: 5,
                                    width: width() - 40,
                                    position: 'relative',
                                    borderRadius: 20,
                                    backgroundColor: getColor('card'),
                                }}
                            >
                                {
                                    <View
                                        style={{
                                            height: 5,
                                            left: 0,
                                            top: 0,
                                            borderRadius: 20,
                                            backgroundColor:
                                                badge?.getColor() ??
                                                getColor('border'),
                                            width: badgeMax
                                                ? Math.min(
                                                      ((width() - 40) *
                                                          badgeMax.getUserCount(
                                                              User._instance,
                                                          )) /
                                                          (badgeMax.getBestCount() ??
                                                              1),
                                                      width() - 40,
                                                  )
                                                : width() - 40,
                                        }}
                                    ></View>
                                }
                                {
                                    <View
                                        style={{
                                            height: 10,
                                            position: 'absolute',
                                            width: 10,
                                            borderRadius: 10,
                                            left: 0,
                                            top: -2.5,
                                            backgroundColor:
                                                badge?.getColor() ??
                                                getColor('border'),
                                        }}
                                    ></View>
                                }
                                {badgeMax && (
                                    <View
                                        style={{
                                            height: 10,
                                            width: 10,
                                            position: 'absolute',
                                            borderRadius: 10,
                                            right: 0,
                                            top: -2.5,
                                            backgroundColor:
                                                badgeMax.getColor(),
                                        }}
                                    ></View>
                                )}
                                {!badgeMax && (
                                    <View
                                        style={{
                                            height: 10,
                                            width: 10,
                                            position: 'absolute',
                                            borderRadius: 10,
                                            right: 0,
                                            top: -2.5,
                                            backgroundColor: GOLD_COLOR,
                                        }}
                                    ></View>
                                )}
                            </View>
                        </View>
                        <View
                            style={{
                                width: width() - 40,
                                display: 'flex',
                                position: 'relative',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 20,
                                    textAlign: 'center',
                                    fontFamily: FONT_BOLD,
                                    color:
                                        badge?.getColor() ?? getColor('border'),
                                }}
                            >
                                {0}
                            </Text>
                            {badgeMax && !badgeMax.hasBadge(User._instance) && (
                                <Text
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        fontSize: 20,
                                        textAlign: 'right',
                                        fontFamily: FONT_BOLD,
                                        borderRadius: 20,
                                        color:
                                            badge?.getColor() ??
                                            getColor('border'),
                                        width:
                                            ((width() - 40) *
                                                badgeMax.getUserCount(
                                                    User._instance,
                                                )) /
                                            (badgeMax.getBestCount() ?? 1),
                                    }}
                                >
                                    {badgeMax?.getUserCount(User._instance) ??
                                        badgeBef?.getUserCount(
                                            User._instance,
                                        ) ??
                                        ''}
                                </Text>
                            )}
                            <Text
                                style={{
                                    fontSize: 20,
                                    textAlign: 'center',
                                    fontFamily: FONT_BOLD,
                                    color: badgeMax?.getColor() ?? GOLD_COLOR,
                                }}
                            >
                                {badgeMax?.getBestCount() ??
                                    badgeBef?.getUserCount(User._instance) ??
                                    ''}
                            </Text>
                        </View>
                        {!badge.hasBadge(User._instance) && (
                            <View
                                style={{
                                    display: 'flex',
                                    width: width() - 40,
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontFamily: FONT_MEDIUM,
                                        fontSize: 16,
                                        marginTop: 10,
                                        color: getColor('text'),
                                    }}
                                >
                                    {badge.getRemaining(User._instance) === 1
                                        ? t(
                                              badge.getBadgeKey() +
                                                  '_remaining',
                                              {
                                                  nb: badge.getRemaining(
                                                      User._instance,
                                                  ),
                                              },
                                          )
                                        : t(
                                              badge.getBadgeKey() +
                                                  '_remaining_plural',
                                              {
                                                  nb: badge.getRemaining(
                                                      User._instance,
                                                  ),
                                              },
                                          )}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
            </Header>
            {
                (props as any).route.params.anim && <View></View>

                /*<LottieView
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                    source={confettiAnim()}
                    autoPlay
                    loop={false}
                />*/
            }
        </View>
    );
};
