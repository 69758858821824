import { Toast } from './Toast';

export class ToastManager {
    private static _instance: ToastManager;

    private _toastList: Toast[] = [];
    private _count: number = 1;
    private _func: (count: number) => void = () => {
        return;
    };

    constructor() {
        return;
    }

    static get instance() {
        if (!ToastManager._instance) {
            ToastManager._instance = new ToastManager();
        }
        return ToastManager._instance;
    }

    addToast(toast: Toast) {
        this._toastList.push(toast);
        this._func(this._count);
        this._count++;
    }

    register(func: (count: number) => void) {
        this._func = func;
    }

    getToastList() {
        return this._toastList;
    }

    setToastList(toastList: Toast[]) {
        this._toastList = toastList;
        this._func(this._count);
        this._count++;
    }
}
