import JTOObject from '../../../utils/jto/JTOObject';
import JTOSimple from '../../../utils/jto/JTOSimple';
import JTOProperty from '../../../utils/jto/JTOProperty';
import JTORelProperty from '../../../utils/jto/JTORelProperty';
import JTOList from '../../../utils/jto/JTOList';
import JTOElement from '../../../utils/jto/JTOElement';
import JTOListFetch from '../../JTOListFetch';
import User from '../../User';
import { MessageTiny } from '../chat/MessageTiny';
export default class UserTiny extends JTOObject {
    public static _TYPE: string = 'User';

    public firstname: JTOProperty<string>;
    public lastname: JTOProperty<string>;
    public email: JTOProperty<string>;
    public xp: JTOProperty<number>;
    public description: JTOProperty<string>;

    public avatar: JTOProperty<string>;

    public connectConnect: JTORelProperty<boolean>;
    public connectDate: JTORelProperty<string>;

    public messageList: JTOListFetch<MessageTiny>;
    public nbNewMessage: JTOProperty<number>;

    public auth: boolean = false;
    public event?: string;
    public token?: string;

    public static translation: (name: string) => string | undefined = () => '';

    public static rootUpdate: () => void = () => {
        // do nothing
    };

    constructor(parent?: JTOElement | null) {
        super(User._TYPE, parent);

        this.firstname = new JTOProperty<string>('firstname', this);
        this.lastname = new JTOProperty<string>('lastname', this);
        this.email = new JTOProperty<string>('email', this);
        this.description = new JTOProperty<string>('description', this);
        this.avatar = new JTOProperty<string>('avatar', this);
        this.xp = new JTOProperty<number>('xp', this);

        this.nbNewMessage = new JTOProperty<number>(
            'nb_new_message',
            this,
            false,
        );
        this.messageList = new JTOListFetch(
            '/message/list',
            MessageTiny,
            ['send', 'receive'],
            this,
            (a: MessageTiny, b: MessageTiny) => {
                const dateA = new Date(a.date.get() ?? '');
                const dateB = new Date(b.date.get() ?? '');
                // ORDER BY date DESC
                return dateA.getTime() > dateB.getTime() ? -1 : 1;
            },
        );

        this.connectConnect = new JTORelProperty<boolean>(
            ['connect.connect'],
            this,
            false,
        );
        this.connectDate = new JTORelProperty<string>(
            ['connect.date'],
            this,
            false,
        );
    }

    public toUser(): User {
        const user = new User();
        const params = this.toJson();
        user.applyData(params);
        return user;
    }

    public isNewMessage(): boolean {
        return (this.nbNewMessage.get() ?? 0) > 0;
    }

    public getNbNewMessage(): number {
        return this.nbNewMessage.get() ?? 0;
    }

    public isConnect(): boolean {
        return this.connectConnect.get() === true;
    }
    public getDisplayName(): string {
        return (this.firstname.get() ?? '') + ' ' + (this.lastname.get() ?? '');
    }

    public isMe(): boolean {
        return this.getJTOIdentifier() === User._instance.getJTOIdentifier();
    }
}
