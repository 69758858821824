import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function AwardCeremony(props: any) {
    return (
        <svg
            viewBox='0 0 142.96 142.45'
            {...props}
        >
            <path
                fill='currentColor'
                d='M109.49,35.37c-1.81-8.31-5.74-15.56-11.68-21.65-5.62-5.76-12.27-9.89-20.08-11.93-3.15-.82-6.42-1.21-9.64-1.79h-5.12c-1.3.17-2.61.32-3.91.52-11.55,1.73-21.11,7.12-28.44,16.16-8.25,10.19-11.45,21.94-9.6,34.95,1.45,10.19,6,18.86,13.44,25.95,8.16,7.78,17.94,11.93,29.21,12.45,8.42.38,16.35-1.49,23.7-5.61,8.67-4.86,15.12-11.84,19.21-20.92,4.07-9.04,5.02-18.45,2.91-28.11ZM88.8,39.18c-2.22,2.81-4.5,5.58-6.67,8.42-.39.51-.64,1.3-.61,1.94.18,3.71.49,7.4.7,11.11.22,3.84-2.65,5.81-6.23,4.31-3.06-1.29-6.13-2.56-9.16-3.92-.95-.42-1.72-.39-2.65.03-2.96,1.32-6.02,2.4-8.91,3.84-3.43,1.72-7.04-.6-6.41-4.56.03-.17.02-.34.03-.51,0,0,0,0-.02,0,.23-3.4.51-6.8.67-10.21.03-.68-.22-1.52-.63-2.06-2.15-2.81-4.4-5.54-6.59-8.32-2.48-3.15-1.43-6.25,2.45-7.27,3.25-.86,6.52-1.66,9.75-2.59.64-.19,1.34-.72,1.7-1.3,1.79-2.85,3.66-5.67,5.13-8.69,1.81-3.72,6.55-3.78,8.39.05,1.44,2.98,3.37,5.73,5.03,8.6.44.76.97,1.18,1.83,1.39,3.26.8,6.51,1.68,9.77,2.54,3.79,1,4.86,4.13,2.45,7.2Z'
            />
            <path
                fill='currentColor'
                d='M60.88,98.12c-.17.34-.3.64-.46.93-5.66,9.81-11.33,19.63-17,29.43-1.3,2.26-3.26,3.09-5.32,2.29-1.64-.63-2.32-2.01-2.74-3.6-1.96-7.41-3.93-14.81-5.91-22.21-.07-.28-.18-.55-.31-.93-3.21.85-6.36,1.68-9.52,2.53-4.48,1.2-8.96,2.41-13.44,3.62-3.32.89-4.84.18-6.18-2.91v-1.79c6.43-11.21,12.87-22.41,19.4-33.8,9.66,15.53,23.39,24.37,41.48,26.45Z'
            />
            <path
                fill='currentColor'
                d='M130.45,108.55c-1.04,1.76-2.82,2.36-5.25,1.71-5.14-1.37-10.28-2.76-15.41-4.15-2.58-.7-5.17-1.39-7.91-2.13-.43,1.56-.85,3.06-1.25,4.57-1.65,6.21-3.3,12.42-4.94,18.64-.38,1.45-.98,2.73-2.41,3.43-1.62.8-3.57.43-4.78-.93-.31-.35-.57-.74-.81-1.14-5.74-9.92-11.47-19.84-17.2-29.76-.1-.18-.19-.37-.35-.67,18.13-2.09,31.83-10.91,41.45-26.34.23.33.44.58.6.86,6,10.41,11.99,20.81,18,31.21.89,1.54,1.22,3.07.27,4.7Z'
            />
        </svg>
    );
}

export default AwardCeremony;
