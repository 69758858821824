import {
    ActivityIndicator,
    InteractionManager,
    Pressable,
    RefreshControl,
    ScrollView,
    Text,
    View,
} from 'react-native';
import React from 'react';
import { PageHeader } from '../component/PageHeader';
import { PostView } from '../component/PostView';
import Post from '../object/Post';
import { styles } from '../utils/styles';
import { IPageProps } from './IPageProps';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { useCallback, useEffect, useState } from 'react';
import { FONT_BOLD, getColor } from '../utils/theme';
import User from '../object/User';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { height, isCloseToBottom, width } from '../utils/utils';
import { GeSpinner } from '../component/GeSpinner';
import { CartoucheButton } from '../component/CartoucheButton';
import { PlusIcon } from 'react-native-heroicons/solid';
import { Header } from '../component/Header';
import { t } from 'i18next';
import { PastilleView } from '../component/PastilleView';
import { HistoryView } from '../component/HistoryView';
import useJTOState from '../utils/jto/useJTOState';
import { NewHistoryView } from '../component/NewHistoryView';

export const HistoryPage = (props: any) => {
    const [refreshing, setRefreshing] = useState(false);

    const event = useJTOState(User._instance.getEvent()!);
    const postList = useJTOState(User._instance.getEvent()!.postList);

    const historyList = useJTOState(User._instance.historyList);

    const onRefresh = useCallback(() => {
        setRefreshing(false);
        historyList.reset();
        historyList.refetch({
            id_event: User._instance.getEvent()!.getJTOIdentifier(),
            id: User._instance.getEvent()!.getJTOIdentifier(),
        });
    }, []);

    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            try {
                User._instance.historyList.fetch({
                    id_event: User._instance.getEvent()!.getJTOIdentifier(),
                    id: User._instance.getEvent()!.getJTOIdentifier(),
                });
            } catch (e) {}
        });
        return () => {
            InteractionManager.runAfterInteractions(() => {
                try {
                    User._instance.historyList.reset();
                } catch (e) {}
            });
        };
    }, []);

    return (
        <View
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                title={t('notif')}
                line={1}
                backButton={true}
                navigation={props.navigation}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                logo={event.logo.get() ?? ''}
            >
                <View
                    // @ts-ignore
                    onScroll={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            historyList.more({
                                id_event: User._instance
                                    .getEvent()!
                                    .getJTOIdentifier(),
                                id: User._instance
                                    .getEvent()!
                                    .getJTOIdentifier(),
                            });
                        }
                    }}
                    onMomentumScrollEnd={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            historyList.more({
                                id_event: User._instance
                                    .getEvent()!
                                    .getJTOIdentifier(),
                                id: User._instance
                                    .getEvent()!
                                    .getJTOIdentifier(),
                            });
                        }
                    }}
                    scrollEventThrottle={1}
                    style={{
                        width: width(),
                        zIndex: 500,
                    }}
                    refreshControl={
                        <RefreshControl
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                >
                    <View
                        style={{
                            height: 40,
                        }}
                    ></View>
                    {historyList.map((item, index) => {
                        return (
                            <NewHistoryView
                                key={item.getJTOIdentifier()}
                                index={index}
                                newHistory={item}
                                navigation={props.navigation}
                            />
                        );
                    })}
                    {(historyList.isLoading() || !historyList.isComplite()) && (
                        <View
                            style={[
                                styles.boxCenter,
                                marginBottom(50),
                                historyList.size() === 0
                                    ? marginTop(200)
                                    : marginTop(50),
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    )}
                    {historyList.size() === 0 &&
                        !historyList.isLoading() &&
                        historyList.isComplite() && (
                            <View
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height:
                                        height() -
                                        STATUS_BAR_HEIGHT -
                                        NAVIGATION_BAR_HEIGHT -
                                        TAB_HEIGHT -
                                        200,
                                    flex: 1,
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 25,
                                        fontFamily: FONT_BOLD,
                                        color: getColor('text'),
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('no_history')}
                                </Text>
                            </View>
                        )}
                </View>
            </Header>
        </View>
    );
};
