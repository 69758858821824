import { Text, View } from 'react-native';
import React from 'react';
import Group from '../object/Group';
import { FONT_BOLD } from '../utils/theme';

interface IGroupViewProps {
    group: Group;
}

export const GroupView = (props: IGroupViewProps) => {
    const { group } = props;
    return (
        <View
            style={{
                borderRadius: 80,
                paddingHorizontal: 12,
                paddingVertical: 5,
                marginRight: 8,
                backgroundColor: group.color.get(),
                marginBottom: 10,
            }}
        >
            <Text
                style={{
                    fontFamily: FONT_BOLD,
                    color: 'white',
                }}
            >
                {group.name.get()}
            </Text>
        </View>
    );
};
