import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function KidsAnimation(props: any) {
    return (
        <svg
            viewBox='0 0 142.96 142.45'
            {...props}
        >
            <path
                fill='currentColor'
                d='M81.81,133.57c-.41-.21-.85-.37-1.27-.56-6.26-2.79-11.21-7.09-14.82-12.92-.3-.48-.49-1.03-.81-1.72.95-.16,1.67-.29,2.38-.4,3.45-.5,5.09-3.52,3.59-6.68-.93-1.96-1.94-3.88-2.9-5.83-.96-1.95-.7-2.8,1.12-3.89,20.06-11.99,31.73-36.77,28.19-59.88C94.23,21.77,79.68,5.98,59.99,1.38c-2.86-.67-5.82-.93-8.74-1.38h-4.72c-.35.08-.7.2-1.06.23C29.58,1.62,17,8.85,8.26,22.15c-9.89,15.06-10.61,31.22-3.71,47.54,7.6,17.96,20.89,29.93,39.78,35.41,1.85.54,2.33,1.33,1.98,3.29-.41,2.32-.92,4.63-1.2,6.96-.39,3.19,2.05,5.45,5.2,4.99,1.19-.17,2.41-.22,3.57-.51,1.18-.3,1.71.09,2.22,1.15,3.06,6.32,7.32,11.69,13.13,15.67,2.84,1.94,5.96,3.5,9.07,5.01,2.3,1.11,4.81-.02,5.78-2.25.96-2.19,0-4.7-2.27-5.84ZM41.56,89.51c-4.98-2.01-9.4-4.93-13.31-8.59-5.13-4.81-9.04-10.46-11.54-17.06-1.05-2.77-.13-5.27,2.31-6.23,2.45-.96,4.84.22,5.93,2.97,3.79,9.64,10.46,16.51,19.92,20.68,1.87.82,2.95,2.14,3.05,3.83.03,3.67-3.15,5.69-6.36,4.39Z'
            />
        </svg>
    );
}

export default KidsAnimation;
