import React from 'react';
import { Text, Linking, TouchableOpacity, View } from 'react-native';
import { getColor } from '../utils/theme';

export const CustomTextWithLinks = ({
    content,
    style,
    globalStyle,
}: {
    content: string;
    style: any;
    globalStyle?: any;
}) => {
    const regex = /(https?:\/\/[^\s]+)/g;
    const parts = content.split(regex);

    return (
        <View style={[{ flexDirection: 'row', flexWrap: 'wrap' }, globalStyle]}>
            {parts.map((part: any, index: number) => {
                if (regex.test(part)) {
                    return (
                        <TouchableOpacity
                            key={index}
                            onPress={() => Linking.openURL(part)}
                        >
                            <Text
                                style={[
                                    style,
                                    {
                                        color: getColor('secondary'),
                                    },
                                ]}
                            >
                                {part}
                            </Text>
                        </TouchableOpacity>
                    );
                }
                return (
                    <Text
                        style={style}
                        key={index}
                    >
                        {part}
                    </Text>
                );
            })}
        </View>
    );
};
