import { ReactNode, useEffect, useRef, useState } from 'react';
import {
    Keyboard,
    Pressable,
    Text,
    TextInput,
    TextInputProps,
    View,
} from 'react-native';
import React from 'react';

export interface IMpInputProps extends TextInputProps {
    leftIcon?: ReactNode;
    leftOnPress?: () => void;
    rightIcon?: ReactNode;
    rightOnPress?: () => void;
    center?: boolean;
    spaceIcon?: number;
}

export const MpInput = (props: IMpInputProps) => {
    const {
        leftOnPress,
        leftIcon,
        style,
        onFocus,
        onBlur,
        placeholder,
        value,
        rightIcon,
        rightOnPress,
        spaceIcon = 8,
        center = true,
        ...rest
    } = props;

    const ref = useRef<any>(null);
    const [focus, setFocus] = useState(false);

    useEffect(() => {
        const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
            ref.current.blur();
        });

        return () => {
            hideSubscription.remove();
        };
    }, []);

    return (
        <View
            style={[
                {
                    position: 'relative',
                    justifyContent: center ? 'center' : 'flex-start',
                },
                style,
                (style as any)?.containerStyle,
                focus && (style as any)?.containerFocusStyle,
            ]}
        >
            {leftIcon && (
                <Pressable
                    style={{
                        position: 'absolute',
                        left: spaceIcon,
                        bottom: 0,
                        top: 0,
                        justifyContent: center ? 'center' : 'flex-start',
                        alignItems: 'center',
                    }}
                    onPress={() => {
                        leftOnPress?.();
                    }}
                >
                    {leftIcon}
                </Pressable>
            )}
            <TextInput
                ref={ref}
                onFocus={(e) => {
                    setFocus(true);
                    onFocus?.(e);
                }}
                onBlur={(e) => {
                    setFocus(false);
                    onBlur?.(e);
                }}
                style={[
                    (style as any)?.inputStyle,
                    {
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: 0,
                    },
                    focus && (style as any)?.inputFocusStyle,
                    {
                        paddingLeft: leftIcon ? 22 : 0,
                    },
                ]}
                placeholderTextColor={'#909090'}
                placeholder={placeholder}
                {...rest}
            />
            {rightIcon && (
                <Pressable
                    style={{
                        position: 'absolute',
                        right: spaceIcon,
                    }}
                    onPress={() => {
                        rightOnPress?.();
                    }}
                >
                    {rightIcon}
                </Pressable>
            )}
        </View>
    );
};
