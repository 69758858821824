import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';
import { applyResult } from '../utils/applyResult';

import Invite from './Invite';
import { translate } from '../component/AppNavigation';
import PostFile from './PostFile';
import JTOListFetch from './JTOListFetch';
import { getDisplayDateUtils } from '../utils/utils';
import InviteTiny from './compressed/global/InviteTiny';
import User from './User';
import UserTiny from './compressed/global/UserTiny';

interface IReact {
    count: number;
    type: number | null;
}

export default class Post extends JTOObject {
    public static _TYPE: string = 'Post';

    public content: JTOProperty<string>;
    public date: JTOProperty<string>;

    public inivite: JTOSimple<InviteTiny>;

    public postFileList: JTOList<PostFile>;

    public react: JTOSimple<InviteTiny>;

    public reactCount: JTOProperty<IReact[]>;

    public commentList: JTOListFetch<Post>;
    public inviteList: JTOList<InviteTiny>;

    public commentCount: JTOProperty<number>;

    public isFavorite: JTOProperty<boolean>;
    public DateFavorite: JTOProperty<string>;

    constructor() {
        super(Post._TYPE);

        this.isFavorite = new JTOProperty<boolean>('is_favorite', this);

        this.DateFavorite = new JTOProperty<string>('favorite_date', this);

        this.inviteList = new JTOList<InviteTiny>(
            InviteTiny,
            'react',
            this
        )

        this.content = new JTOProperty<string>('content', this);
        this.date = new JTOProperty<string>('date', this);

        this.inivite = new JTOSimple<InviteTiny>(InviteTiny, 'has_post', this);

        this.commentList = new JTOListFetch<Post>(
            '/post/comment/list',
            Post,
            'got_post',
            this,
            (a: Post, b: Post) => {
                return (
                    -1 * (a.date.get()?.localeCompare(b.date.get() ?? '') ?? 0)
                );
            },
        );

        this.postFileList = new JTOList<PostFile>(
            PostFile,
            'has_postfile',
            this,
        );

        this.react = new JTOSimple<InviteTiny>(InviteTiny, 'react', this);

        this.reactCount = new JTOProperty('react_count', this);

        this.commentCount = new JTOProperty('count_comment', this);
    }

    getDisplayDate(t: (...args: any) => string): string {
        return getDisplayDateUtils(t, this.date.get() ?? '');
    }

    getResumeContent(): string {
        let res = this.content.get() ?? '';
        if (res.length > 50) {
            res = res.substring(0, 50) + '...';
        }
        return res;
    }

    getUser(): UserTiny | null {
        let res = this.inivite.get()?.user.get() ?? null;
        if (res) {
            return res;
        } else {
            if (this.getJTOParent()?.getJTOParent() instanceof Invite) {
                const invite = this.getJTOParent()?.getJTOParent() as Invite;
                if (invite.user.get()) {
                    res = invite.user.get() as any as UserTiny;
                } else {
                    res =
                        (invite.getJTOParent()?.getJTOParent() as UserTiny) ??
                        null;
                }
            }
        }
        return res;
    }
}
