import JTOInstance from './JTOInstance';
import JTOField from './JTOField';
import JTOObject from './JTOObject';
import JTOElement from './JTOElement';

export default class JTOSimple<Type extends JTOObject> extends JTOField {
    private elem: Type | null;
    private override: boolean;

    private typeList: (new (...args: any) => Type)[];

    constructor(
        typeList?:
            | (new (...args: any) => Type)
            | (new (...args: any) => Type)[],
        field?: string[] | string,
        JTOParent: JTOElement | null = null,
        override: boolean = true,
    ) {
        super(field ?? '', JTOParent);

        if (typeList === undefined) {
            this.typeList = [];
        } else if (!Array.isArray(typeList)) {
            this.typeList = [typeList];
        } else {
            this.typeList = typeList;
        }

        this.elem = null;
        this.override = override;
    }

    public get(): Type | null {
        return this.elem;
    }

    public set(elem: Type | null) {
        this.elem = elem;
    }

    public getJsonElem(
        data: { [key: string]: any } | { [key: string]: any }[],
    ): { [key: string]: any } | null {
        const currentElem = this.get();
        let elem = null;
        if (currentElem != null) {
            if (data instanceof Array) {
                let found = false;
                let i = 0;
                while (!found && i < data.length) {
                    if (currentElem.equals(data[i])) {
                        elem = data[i];
                        found = true;
                    }
                    i += 1;
                }
                if (!found && this.override) {
                    elem = data[0];
                }
            } else if (currentElem.equals(data)) {
                elem = data;
            } else if (this.override) {
                elem = data;
            }
        } else {
            if (data instanceof Array && data.length > 0) {
                elem = data[0];
            } else {
                elem = data;
            }
        }
        return elem;
    }
    /**
     * Check if elem is a type entered
     */
    public isGoodType(elem: Type) {
        let res = this.typeList.length === 0;
        let i = 0;
        while (!res && i < this.typeList.length) {
            res = elem instanceof this.typeList[i];
            i += 1;
        }
        return res;
    }

    public applyElem(data: any) {
        let res = false;
        if (data !== undefined) {
            const jsonElem = this.getJsonElem(data);

            if (jsonElem != null) {
                const elem = this.get();
                if (
                    this.isDeleteOrder(jsonElem, elem) ||
                    this.isDeleteRelationOrder(jsonElem, elem)
                ) {
                    if (elem != null) {
                        this.set(null);
                    }
                } else if (
                    elem == null ||
                    (!elem.equals(jsonElem) && this.override)
                ) {
                    res = true;
                    const JTOElem = JTOInstance.convert(
                        jsonElem,
                        this,
                        this.typeList,
                    ) as Type;
                    try {
                        if (
                            JTOElem != null &&
                            JTOElem !== undefined &&
                            this.isGoodType(JTOElem)
                        ) {
                            this.set(JTOElem as Type);
                        }
                    } catch (e) {
                        if (JTOElem == null) {
                            // TODO
                        } else {
                            throw new Error(
                                'Your JTOSimple cannot take this type : ' +
                                    (JTOElem as any).constructor?.name +
                                    '.',
                            );
                        }
                    }
                } else {
                    res =
                        (elem as JTOElement).applyDataPartiel(
                            jsonElem,
                            false,
                        ) || res;
                }
            }
        }
        return res;
    }
    public override toJson() {
        const list: any[] = [];
        if (this.elem != null) {
            list.push(this.elem.toJson());
        }
        return list;
    }
    public override getJTOElementList(recursively: boolean): JTOElement[] {
        const list: JTOElement[] = [];
        const elem = this.get();
        if (elem != null) {
            list.push(elem);
            if (recursively) {
                const recList = elem.getJTOElementList(recursively);
                for (const recElem of recList) {
                    list.push(recElem);
                }
            }
        }
        return list;
    }

    public override applyDataPartiel(
        data: { [key: string]: any },
        first: boolean,
    ): boolean {
        // boolean that indicates if the object has changed
        let res = false;
        // go throw json source only if the data was applyed to the parent
        if (!this.hasParent()) {
            res = this.applyElem(data) || res;
        } else if (first) {
            // get the json array for all field
            for (const field of this.fieldList) {
                // current json document
                if (!(data instanceof Array)) {
                    const array = data[field];
                    res = this.applyElem(array) || res;
                } else {
                    res = this.applyElem(data) || res;
                }
            }
        }

        // Make the update
        if (res) {
            this.notifyView();
        }

        return res;
    }

    public reset() {
        this.elem = null;
    }
}
