import { StyleSheet } from 'react-native';
import React from 'react';
import { NAVIGATION_BAR_HEIGHT } from './screen';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from './theme';

export const generateStyle = () => {
    return StyleSheet.create({
        text: {
            fontSize: 13,
            color: getColor('text'),
            fontFamily: FONT_REGULAR,
        },
        box: {
            display: 'flex',
            flexDirection: 'column',
        },
        boxCenter: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        textError: {
            color: getColor('error'),
            fontSize: 12,
            textAlign: 'center',
            fontFamily: FONT_REGULAR,
        },
        textMedium: {
            fontSize: 16,
            fontFamily: FONT_MEDIUM,
            color: getColor('text'),
        },
        page: {
            hieght: '100%',
            width: '100%',
            flex: 1,
            backgroundColor: getColor('background'),
        },
        h1: {
            fontSize: 45,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        h2: {
            fontSize: 40,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        h3: {
            fontSize: 30,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        h4: {
            fontSize: 20,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        h5: {
            fontSize: 18,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        h6: {
            fontSize: 16,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        input: {
            ['containerStyle' as any]: {
                width: '100%',
                backgroundColor: getColor('card'),
                borderRadius: 50,
                paddingHorizontal: 14,
                paddingVertical: 6,
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 14,
                paddingVertical: 6,
            },

            ['containerFocusStyle' as any]: {
                borderBottomColor: getColor('primary'),
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 14,
                paddingVertical: 6,
            },
        },
        inputBig: {
            ['containerStyle' as any]: {
                width: '100%',
                backgroundColor: getColor('card'),
                borderRadius: 15,
                minHeight: 100,
                paddingHorizontal: 10,
                paddingVertical: 10,
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 10,
                paddingVertical: 10,
            },

            ['containerFocusStyle' as any]: {
                borderBottomColor: getColor('primary'),
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 10,
                paddingVertical: 10,
            },
        },
        inputSingleLine: {
            ['containerStyle' as any]: {
                width: '100%',
                backgroundColor: getColor('card'),
                borderRadius: 15,
                paddingHorizontal: 14,
                paddingVertical: 10,
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 14,
                paddingVertical: 10,
            },

            ['containerFocusStyle' as any]: {
                borderBottomColor: getColor('primary'),
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 14,
                paddingVertical: 10,
            },
        },
        inputMessage: {
            ['containerStyle' as any]: {
                width: '100%',
                backgroundColor: getColor('card'),
                borderWidth: 2,
                borderRadius: 20,
                maxHeight: 100,
                paddingHorizontal: 12,
                paddingVertical: 8,
                borderColor: getColor('card'),
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                fontSize: 16,
                fontFamily: FONT_REGULAR,
            },

            ['containerFocusStyle' as any]: {
                borderColor: getColor('primary'),
                borderWidth: 2,
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                paddingHorizontal: 12,
                paddingVertical: 8,
                fontFamily: FONT_REGULAR,
            },
        },
        inputOutilined: {
            ['containerStyle' as any]: {
                width: '100%',

                paddingVertical: 6,
                paddingRight: 8,
                paddingLeft: 8,

                borderWidth: 1,
                borderRadius: 8,
                borderColor: getColor('border'),
                backgroundColor: getColor('card'),
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                paddingRight: 8,
                paddingLeft: 8,
                fontFamily: FONT_REGULAR,
            },

            ['containerFocusStyle' as any]: {
                paddingRight: 8,
                paddingLeft: 8,

                borderWidth: 1,
                borderRadius: 8,
                borderColor: getColor('primary'),
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                paddingRight: 8,
                paddingLeft: 8,
                fontFamily: FONT_REGULAR,
            },
        },
        inputHeader: {
            ['containerStyle' as any]: {
                flex: 1,

                paddingVertical: 6,
                paddingRight: 8,
                paddingLeft: 8,

                borderWidth: 2,
                borderRadius: 25,
                borderColor: 'white',
                backgroundColor: 'white',
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                paddingRight: 8,
                paddingLeft: 8,
                fontFamily: FONT_REGULAR,
            },

            ['containerFocusStyle' as any]: {
                paddingRight: 8,
                paddingLeft: 8,

                borderWidth: 2,
                borderRadius: 25,
                borderColor: getColor('secondary'),
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                paddingRight: 8,
                paddingLeft: 8,
                fontFamily: FONT_REGULAR,
            },
        },
        inputPlaceholder: {
            color: getColor('hint'),
        },
        buttonScreen: {
            borderRadius: 12,
            padding: 14,
            backgroundColor: getColor('primary'),
        },
        buttonScreenText: {
            color: getColor('textWhite'),
            fontFamily: FONT_MEDIUM,
            textAlign: 'center',
            fontSize: 19,
        },
        background: {
            backgroundColor: getColor('primary'),
        },
        buttonMenu: {
            display: 'flex',
            paddingBottom: 16,
            paddingTop: 16,
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        button: {
            backgroundColor: getColor('secondary'),
            paddingVertical: 10,
            paddingHorizontal: 14,
            borderRadius: 10,
        },
        buttonText: {
            color: 'white',
            fontSize: 16,
            fontFamily: FONT_MEDIUM,
        },
        title: {
            color: 'black',
            fontFamily: FONT_MEDIUM,
            fontSize: 18,
        },
        error: {
            color: getColor('error'),
            fontSize: 12,
            textAlign: 'center',
            fontFamily: FONT_REGULAR,
        },
    });
};

export let styles = generateStyle();
export const initStyles = () => {
    styles = generateStyle();
};
