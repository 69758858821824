import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Pressable } from 'react-native';
import React from 'react';
import { PanGestureHandler, ScrollView } from 'react-native-gesture-handler';
import Event from '../object/Event';
import Question from '../object/Question';
import User from '../object/User';
import { FONT_BOLD, FONT_REGULAR, getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import { IModalProps } from './IModalProps';
import { Header } from '../component/Header';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { CartoucheButton } from '../component/CartoucheButton';
import { MpInput } from '../base/MpInput';
import { styles } from '../utils/styles';
import OpenQuestion from '../object/OpenQuestion';
import SocketInstance from '../utils/SocketInstance';
import { Text } from 'react-native';
//import CardsSwipe from 'react-native-cards-swipe';
import { stylesCard } from './QuizModal';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { get } from '../utils/http';
import { HTTPUtils } from 'mp-ts-http';
import { GeSpinner } from '../component/GeSpinner';
import { ClockIcon, HandThumbUpIcon } from 'react-native-heroicons/solid';
import JTOInstance from '../utils/jto/JTOInstance';
import useJTOState from '../utils/jto/useJTOState';

export const NuageModal = (props: IModalProps) => {
    const { t } = useTranslation();
    User.translation = t;

    const quiz = useJTOState((props as any).route.params.event as Event);
    const event = useJTOState(User._instance.getEvent() as Event);

    const [loading, setLoading] = useState(true);
    const [block, setBlock] = useState(true);

    const [answer, setAnswer] = useState<string>('');

    const sendAnswer = () => {
        if (quiz.getActuQuestion()) {
            quiz.getActuQuestion()!.myStringAnswer = answer;
        }
        SocketInstance.get().socket?.emit('realtime.answer', {
            id_event: quiz.getJTOIdentifier(),
            id_question: quiz.currentQuestion.get()?.getJTOIdentifier(),
            answer,
        });
    };

    const questionList = quiz.questionRealTimeList.getList();
    const cardsData = [] as any[];
    cardsData.push('first');
    for (const question of quiz.questionRealTimeList.getList()) {
        cardsData.push(question);
        cardsData.push({
            type: 'send',
            question,
        });
    }
    cardsData.push('end');

    useEffect(() => {
        setLoading(true);
        get('/event/reeltimequiz/realtime', {
            id: quiz.getJTOIdentifier(),
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    const e = HTTPUtils.getResult(res);
                    JTOInstance.applyResult(e, true);
                } else {
                    ToastManager.instance.addToast(
                        new Toast(t(HTTPUtils.getError(res)), 5000, 'error'),
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet'), 5000, 'error'),
                );
            });
    }, []);

    const refCard = useRef<any>();

    useEffect(() => {
        if (quiz.currentQuestion.get() !== null) {
            if (!block) {
                setAnswer('');
                const beforeQuestion = quiz.getBeforeQuestion();
                if (beforeQuestion?.myStringAnswer !== null) {
                    refCard.current.swipeRight();
                } else {
                    refCard.current.swipeRight();
                    setTimeout(() => {
                        refCard.current.swipeRight();
                    }, 600);
                }
            } else {
                setBlock(false);
            }
            /* */
        }
    }, [quiz.currentQuestion.get()?.position.get()]);

    useEffect(() => {
        if (quiz.hasRealTimeEnded.get() === true) {
            const lastQuestion = quiz.getLastQuestion();
            if (lastQuestion?.myStringAnswer !== null) {
                refCard.current.swipeRight();
            } else {
                refCard.current.swipeRight();
                setTimeout(() => {
                    refCard.current.swipeRight();
                }, 600);
            }
        }
    }, [quiz.hasRealTimeEnded?.get()]);

    useEffect(() => {
        if (quiz.hasRealTimeStarted.get() === true) {
            setTimeout(() => {
                refCard.current.swipeRight();
            }, 500);
        }
    }, [quiz.hasRealTimeStarted?.get()]);

    return (
        <View
            style={{
                width: width(),
                height: height(),
                paddingBottom: 0,
                backgroundColor: getColor('primary'),
            }}
        >
            <Header
                modal={true}
                backButton={true}
                full
                navigation={props.navigation}
                title={quiz.name.get() ?? ''}
                line={(quiz.name.get() ?? '').length <= 25 ? 1.01 : 3}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        flex: 1,
                        marginTop: HEADER_BONUS_HEIGHT,
                        width: width(),
                    }}
                >
                    {/*<CardsSwipe
                        ref={refCard}
                        key={
                            quiz.currentQuestion.get() === null || loading
                                ? new Date().getTime()
                                : 'CardsSwipe'
                        }
                        initialIndex={
                            loading
                                ? 0
                                : (quiz.currentQuestion.get()?.position.get() ??
                                      0.5) *
                                      2 -
                                  1
                        }
                        loop={false}
                        cards={cardsData}
                        onSwiped={(index: number) => {
                            /* if (index % 2 === 0) {
                                if (
                                    questionList.length > Math.floor(index / 2)
                                ) {
                                    if (
                                        questionList[Math.floor(index / 2)]
                                            .myAnswer === null
                                    ) {
                                        questionList[
                                            Math.floor(index / 2)
                                        ].myAnswer = 'dodge';
                                        quiz.notifyViewNow();
                                    }
                                } else {
                                    quiz.sendAnswer();
                                    props.navigation.goBack();
                                }
                            } 
                        }}
                        cardContainerStyle={stylesCard.cardContainer}
                        renderCard={(card: any) => {
                            if (typeof card === 'string') {
                                if (card === 'first') {
                                    if (loading) {
                                        return (
                                            <PanGestureHandler>
                                                <View
                                                    style={[
                                                        stylesCard.card,
                                                        {
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            flex: 1,
                                                        },
                                                    ]}
                                                >
                                                    <GeSpinner
                                                        color={getColor(
                                                            'primary',
                                                        )}
                                                    />
                                                </View>
                                            </PanGestureHandler>
                                        );
                                    } else {
                                        return (
                                            <PanGestureHandler>
                                                <View style={[stylesCard.card]}>
                                                    <Text
                                                        style={{
                                                            fontFamily:
                                                                FONT_BOLD,
                                                            fontSize: 25,
                                                            color: getColor(
                                                                'text',
                                                            ),
                                                        }}
                                                    >
                                                        {t('waiting')}
                                                    </Text>
                                                    <View
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <ClockIcon
                                                            style={{
                                                                paddingVertical: 15,
                                                            }}
                                                            height={75}
                                                            width={75}
                                                            color={getColor(
                                                                'secondary',
                                                            )}
                                                        />
                                                        <Text
                                                            style={{
                                                                fontFamily:
                                                                    FONT_BOLD,
                                                                fontSize: 25,
                                                                color: getColor(
                                                                    'text',
                                                                ),
                                                                paddingTop: 15,
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            {t('waiting')}
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontFamily:
                                                                    FONT_REGULAR,
                                                                fontSize: 16,
                                                                color: getColor(
                                                                    'text',
                                                                ),
                                                                paddingTop: 15,
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            {t('quiz_waiting')}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </PanGestureHandler>
                                        );
                                    }
                                } else {
                                    return (
                                        <PanGestureHandler>
                                            <View style={[stylesCard.card]}>
                                                <Text
                                                    style={{
                                                        fontFamily: FONT_BOLD,
                                                        fontSize: 25,
                                                        color: getColor('text'),
                                                    }}
                                                >
                                                    {t('thank')}
                                                </Text>
                                                <View
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        flex: 1,
                                                    }}
                                                >
                                                    <HandThumbUpIcon
                                                        style={{
                                                            paddingVertical: 15,
                                                        }}
                                                        height={75}
                                                        width={75}
                                                        color={getColor(
                                                            'success',
                                                        )}
                                                    />
                                                    <Text
                                                        style={{
                                                            fontFamily:
                                                                FONT_BOLD,
                                                            fontSize: 25,
                                                            color: getColor(
                                                                'text',
                                                            ),
                                                            paddingTop: 15,
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {t('thank_for_playing')}
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            fontFamily:
                                                                FONT_REGULAR,
                                                            fontSize: 16,
                                                            color: getColor(
                                                                'text',
                                                            ),
                                                            paddingTop: 15,
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {t('other_action_quiz')}
                                                    </Text>
                                                    <View
                                                        style={{
                                                            padding: 15,
                                                        }}
                                                    >
                                                        <CartoucheButton
                                                            color={getColor(
                                                                'secondary',
                                                            )}
                                                            height={45}
                                                            fontSize={20}
                                                            iconSize={20}
                                                            onPress={() => {
                                                                props.navigation.goBack();
                                                                quiz.sendAnswer();
                                                            }}
                                                            title={t('leave')}
                                                        />
                                                    </View>
                                                </View>
                                            </View>
                                        </PanGestureHandler>
                                    );
                                }
                            }
                            let question: OpenQuestion;
                            if (card instanceof OpenQuestion) {
                                question = card;
                            } else {
                                question = card.question;
                            }
                            if (card instanceof OpenQuestion) {
                                return (
                                    <PanGestureHandler>
                                        <View style={[stylesCard.card]}>
                                            <Text
                                                style={{
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 25,
                                                    color: getColor('text'),
                                                }}
                                            >
                                                {(question.position.get() ??
                                                    0) + ''}
                                                .
                                            </Text>
                                            <Text
                                                style={{
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 25,
                                                    color: getColor('text'),
                                                }}
                                                adjustsFontSizeToFit
                                                numberOfLines={3}
                                            >
                                                {question.title.get()}
                                            </Text>
                                            {question.description.get() !==
                                                '' &&
                                                question.description.get() !==
                                                    undefined &&
                                                question.description.get() !==
                                                    null && (
                                                    <Text
                                                        style={{
                                                            fontFamily:
                                                                FONT_REGULAR,
                                                            fontSize: 16,
                                                            color: getColor(
                                                                'text',
                                                            ),
                                                        }}
                                                        adjustsFontSizeToFit
                                                        numberOfLines={5}
                                                    >
                                                        {question.description.get()}
                                                    </Text>
                                                )}
                                            <View
                                                style={{
                                                    paddingVertical: 15,
                                                }}
                                            >
                                                <MpInput
                                                    autoCapitalize='none'
                                                    value={answer}
                                                    onFocus={() => {
                                                        // Scroll to bottom
                                                    }}
                                                    onChangeText={(text) => {
                                                        setAnswer(text);
                                                    }}
                                                    defaultValue={answer}
                                                    keyboardType={'default'}
                                                    textContentType='givenName'
                                                    placeholder={
                                                        t('answer') as string
                                                    }
                                                    style={styles.input}
                                                    selectionColor={getColor(
                                                        'ge',
                                                    )}
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    padding: 15,
                                                }}
                                            >
                                                <CartoucheButton
                                                    color={getColor(
                                                        'secondary',
                                                    )}
                                                    height={45}
                                                    fontSize={20}
                                                    iconSize={20}
                                                    onPress={() => {
                                                        refCard.current.swipeRight();
                                                        sendAnswer();
                                                    }}
                                                    title={t('send')}
                                                />
                                            </View>
                                        </View>
                                    </PanGestureHandler>
                                );
                            } else {
                                return (
                                    <PanGestureHandler>
                                        <View style={[stylesCard.card]}>
                                            <Pressable
                                                onPress={() => {
                                                    if (
                                                        quiz.getAfterQuestion() ===
                                                        null
                                                    ) {
                                                        quiz.hasRealTimeEnded.set(
                                                            true,
                                                        );
                                                        quiz.notifyViewNow();
                                                    } else {
                                                        quiz.currentQuestion.set(
                                                            quiz.getAfterQuestion(),
                                                        );
                                                        quiz.notifyViewNow();
                                                    }
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily: FONT_BOLD,
                                                        fontSize: 25,
                                                        color: getColor('text'),
                                                    }}
                                                >
                                                    {(question.position.get() ??
                                                        0) + ''}
                                                    .
                                                </Text>
                                            </Pressable>
                                            <Text
                                                style={{
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 25,
                                                    color: getColor('text'),
                                                }}
                                                adjustsFontSizeToFit
                                                numberOfLines={3}
                                            >
                                                {question.title.get()}
                                            </Text>
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    flex: 1,
                                                }}
                                            >
                                                <ClockIcon
                                                    style={{
                                                        paddingVertical: 15,
                                                    }}
                                                    height={75}
                                                    width={75}
                                                    color={getColor(
                                                        'secondary',
                                                    )}
                                                />
                                                <Text
                                                    style={{
                                                        fontFamily: FONT_BOLD,
                                                        fontSize: 25,
                                                        paddingTop: 15,
                                                        textAlign: 'center',
                                                        color: getColor('text'),
                                                    }}
                                                >
                                                    {t('waiting')}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily:
                                                            FONT_REGULAR,
                                                        paddingVertical: 15,
                                                        textAlign: 'center',
                                                        fontSize: 16,
                                                        color: getColor('text'),
                                                    }}
                                                >
                                                    {t('answer_sent_waiting')}
                                                </Text>
                                            </View>
                                        </View>
                                    </PanGestureHandler>
                                );
                            }
                        }}
                    />*/}
                    <View
                        style={{
                            padding: 20,
                            marginBottom: NAVIGATION_BAR_HEIGHT + TAB_HEIGHT,
                        }}
                    >
                        <CartoucheButton
                            color={getColor('secondary')}
                            height={45}
                            fontSize={20}
                            iconSize={20}
                            onPress={() => {
                                props.navigation.goBack();
                            }}
                            title={t('leave_quiz')}
                        />
                    </View>
                </View>
            </Header>
            {/*<Header
                modal
                full
                onPresslogo={() => {
                    props.navigation.popToTop();
                    props.navigation.navigate('home');
                }}
                line={
                    (quiz.currentQuestion.get()?.title.get() ?? '').length <= 25
                        ? 1.01
                        : 3
                }
                title={quiz.currentQuestion.get()?.title.get() ?? ''}
                logo={User._instance.getEvent()?.logo.get() ?? ''}>
                <View
                    style={{
                        flex: 1,
                        marginTop: -10,
                    }}>
                    <MpInput
                        style={styles.inputMessage}
                        placeholder={t('answer') ?? ''}
                        value={answer}
                        multiline={true}
                        defaultValue={answer}
                        onChangeText={text => {
                            setAnswer(text);
                        }}
                    />

                    <CartoucheButton
                        color={getColor('secondary')}
                        height={100}
                        fontSize={25}
                        title={t('send')}
                        onPress={() => {
                            sendAnswer();
                        }}
                    />
                </View>
                    </Header>*/}
        </View>
    );
};
