import {
    View,
    Text,
    Pressable,
    ActivityIndicator,
    ScrollView,
    Keyboard,
    Image,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_BOLD, getColor } from '../utils/theme';
import { height, isCloseToBottom, width } from '../utils/utils';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import { MpInput } from '../base/MpInput';
import { useEffect, useState } from 'react';
import User from '../object/User';
import {
    MagnifyingGlassIcon,
    TrashIcon,
    UserIcon,
    XMarkIcon,
} from 'react-native-heroicons/solid';
import JTOListFetch from '../object/JTOListFetch';
import { GeSpinner } from '../component/GeSpinner';
import { getRestore } from '../utils/http';
import { Header } from '../component/Header';
import { PastilleView } from '../component/PastilleView';
import { SERVER_URL } from '../utils/constants';
import useJTOState from '../utils/jto/useJTOState';
import UserTiny from '../object/compressed/global/UserTiny';

export const BlockedListModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const userList = useJTOState(User._instance.blockList);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        User._instance.blockList.reset();
        userList.fetch({}, () => {
            setLoading(false);
            userList.setComplite(true);
        });
    }, []);

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('block_list')}
                line={1}
                full
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: -40,
                        paddingHorizontal: 20,
                    }}
                >
                    <View
                        style={{
                            marginTop: 70,
                        }}
                    ></View>
                    {loading ? (
                        <View
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 35,
                            }}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View>
                            {userList.getList().length === 0 ? (
                                <View
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height:
                                            height() -
                                            STATUS_BAR_HEIGHT -
                                            NAVIGATION_BAR_HEIGHT -
                                            TAB_HEIGHT -
                                            200,
                                        flex: 1,
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: 25,
                                            fontFamily: FONT_BOLD,
                                            color: getColor('text'),
                                            textAlign: 'center',
                                        }}
                                    >
                                        {t('no_blocked_user')}
                                    </Text>
                                </View>
                            ) : (
                                userList
                                    .getList()
                                    .map((user: UserTiny, index: number) => {
                                        return (
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    marginBottom: 10,
                                                }}
                                            >
                                                <Pressable
                                                    key={user.getJTOIdentifier()}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        padding: 5,
                                                        flex: 1,
                                                    }}
                                                    onPress={() => {
                                                        props.navigation.push(
                                                            'ProfilePage',
                                                            {
                                                                user: user.toUser(),
                                                            },
                                                        );
                                                    }}
                                                >
                                                    {user.avatar.get() ===
                                                    undefined ? (
                                                        <PastilleView
                                                            size={30}
                                                            icon={
                                                                <UserIcon
                                                                    size={20}
                                                                    color={
                                                                        'white'
                                                                    }
                                                                />
                                                            }
                                                            color={getColor(
                                                                'secondary',
                                                            )}
                                                        />
                                                    ) : (
                                                        <Image
                                                            style={{
                                                                resizeMode:
                                                                    'cover',
                                                                height: 30,
                                                                width: 30,
                                                                borderRadius: 30,
                                                            }}
                                                            source={{
                                                                uri:
                                                                    SERVER_URL +
                                                                    '/image/' +
                                                                    user.avatar.get(),
                                                            }}
                                                        />
                                                    )}
                                                    <Text
                                                        style={[
                                                            styles.textMedium,
                                                            {
                                                                marginLeft: 10,
                                                            },
                                                        ]}
                                                    >
                                                        {user.getDisplayName()}
                                                    </Text>
                                                </Pressable>
                                                <Pressable
                                                    onPress={() => {
                                                        props.navigation.navigate(
                                                            'BlockUserModal',
                                                            {
                                                                user,
                                                                act: 'unblock',
                                                            },
                                                        );
                                                    }}
                                                >
                                                    <PastilleView
                                                        icon={
                                                            <TrashIcon
                                                                size={30}
                                                                color={'white'}
                                                            />
                                                        }
                                                        color={getColor(
                                                            'error',
                                                        )}
                                                        size={40}
                                                    />
                                                </Pressable>
                                            </View>
                                        );
                                    })
                            )}
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
