import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'react-native';
import React from 'react';
import { FONT_BOLD, getColor } from '../utils/theme';

interface ITimerViewProps {
    date: Date;
}

export const getTimeDifference = (eventDate: Date, ccDate: Date) => {
    const diff = eventDate.getTime() - ccDate.getTime();
    const diffInSeconds = Math.floor(diff / 1000);

    const { t } = useTranslation();

    const days = Math.floor(diffInSeconds / 86400);
    const hours = Math.floor((diffInSeconds % 86400) / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    let result = '';
    if (days > 0) {
        result += `` + t('d', { count: days });
    }
    if (hours > 0) {
        result += ` ${hours} h`;
    }
    if (minutes > 0) {
        result += ` ${minutes} m`;
    }
    /*if (seconds > 0) {
        result += ` ${seconds} s`;
    }*/

    return result.trim();
};

export const TimerView = (props: ITimerViewProps) => {
    const date = new Date();
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);

        // Clear the interval on component unmount
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <Text
            style={{
                fontSize: 18,
                fontFamily: FONT_BOLD,
                color: getColor('text'),
            }}
        >
            {getTimeDifference(props.date, date)}
        </Text>
    );
};
