import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

export const MANAGE_ROLE_EVENT = 4;
export const POST_CREATE = 5;
export const POST_DELETE = 6;
export const MANAGE_GROUP = 7;
export const MANAGE_INVITE = 8;
export const MANAGE_CURRENT_EVENT = 10;
export const MANAGE_FILE = 11;
export const CAN_REGISTER = 12;
export const MANAGE_VISIBILITY = 13;
export const CAN_VOTE = 14;
export const MANAGE_CONTACT = 15;
export const CAN_REACT = 16;
export const CAN_COEUR = 17;

export default class PermissionEvent extends JTOObject {
    public static _TYPE: string = 'PermissionEvent';

    public type: JTOProperty<number>;

    constructor(parent?: JTOElement | null) {
        super(PermissionEvent._TYPE, parent);

        this.type = new JTOProperty<number>('type', this);
    }
}
