import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

import {
    ArrowLeftOnRectangleIcon,
    CalendarDaysIcon,
    ChartPieIcon,
    ChatBubbleLeftEllipsisIcon,
    ChatBubbleOvalLeftIcon,
    DocumentIcon,
    HandThumbUpIcon,
    LockOpenIcon,
    NewspaperIcon,
    UserIcon,
} from 'react-native-heroicons/solid';
import { Use } from 'react-native-svg';
import Bonheur from '../svg/Bonheur';
import User from './User';
import React from 'react';

const BRONZE_COLOR = '#C38144';
const SILVER_COLOR = '#81A5B0';
const GOLD_COLOR = '#E89B05';

export const DICT_COLOR = {
    '1': BRONZE_COLOR,
    '2': SILVER_COLOR,
    '3': GOLD_COLOR,
};

export const LIST_ACTION = {
    post: {
        big: {
            '1': (
                <NewspaperIcon
                    height={80}
                    style={{ marginBottom: 20 }}
                    width={80}
                    color={BRONZE_COLOR}
                />
            ),
            '2': (
                <NewspaperIcon
                    height={80}
                    style={{ marginBottom: 20 }}
                    width={80}
                    color={SILVER_COLOR}
                />
            ),
            '3': (
                <NewspaperIcon
                    height={80}
                    style={{ marginBottom: 20 }}
                    width={80}
                    color={GOLD_COLOR}
                />
            ),
        },
        middle: {
            '1': (
                <NewspaperIcon
                    height={40}
                    style={{ marginBottom: 10 }}
                    width={40}
                    color={BRONZE_COLOR}
                />
            ),
            '2': (
                <NewspaperIcon
                    height={40}
                    style={{ marginBottom: 10 }}
                    width={40}
                    color={SILVER_COLOR}
                />
            ),
            '3': (
                <NewspaperIcon
                    height={40}
                    style={{ marginBottom: 10 }}
                    width={40}
                    color={GOLD_COLOR}
                />
            ),
        },
    },
    react: {},
    message: {},
    open_event: {},
    open_app: {},
    retro_activite: {},
    file: {},
    response: {},
    inscrip_event: {},
};

export class Badge extends JTOObject {
    public key = new JTOProperty<string>('key', this);
    public level = new JTOProperty<number>('level', this);
    public name = new JTOProperty<string>('name', this);

    public post = new JTOProperty<number>('post', this);
    public react = new JTOProperty<number>('react', this);
    public message = new JTOProperty<number>('message', this);
    public openEvent = new JTOProperty<number>('open_event', this);
    public openApp = new JTOProperty<number>('open_app', this);
    public retroActivite = new JTOProperty<number>('retro_activite', this);
    public file = new JTOProperty<number>('file', this);
    public response = new JTOProperty<number>('response', this);
    public inscripEvent = new JTOProperty<number>('inscrip_event', this);

    constructor(parent?: JTOElement | null) {
        super('Badge', parent);
    }

    hasBadge(user: User) {
        let good = true;
        good = good && (user.post.get() ?? 0) >= (this.post.get() ?? 0);
        good = good && (user.react.get() ?? 0) >= (this.react.get() ?? 0);
        good = good && (user.message.get() ?? 0) >= (this.message.get() ?? 0);
        good =
            good && (user.openEvent.get() ?? 0) >= (this.openEvent.get() ?? 0);
        good = good && (user.openApp.get() ?? 0) >= (this.openApp.get() ?? 0);
        good =
            good &&
            (user.retroActivite.get() ?? 0) >= (this.retroActivite.get() ?? 0);
        good = good && (user.file.get() ?? 0) >= (this.file.get() ?? 0);
        good = good && (user.response.get() ?? 0) >= (this.response.get() ?? 0);
        good =
            good &&
            (user.inscripEvent.get() ?? 0) >= (this.inscripEvent.get() ?? 0);
        return good;
    }

    getRemaining(user: User) {
        let res = 0;
        res = this.getBestCount() - this.getUserCount(user);
        return res;
    }

    getBestCount() {
        let best = 0;
        best = Math.max(best, this.post.get() ?? 0);
        best = Math.max(best, this.react.get() ?? 0);
        best = Math.max(best, this.message.get() ?? 0);
        best = Math.max(best, this.openEvent.get() ?? 0);
        best = Math.max(best, this.openApp.get() ?? 0);
        best = Math.max(best, this.retroActivite.get() ?? 0);
        best = Math.max(best, this.file.get() ?? 0);
        best = Math.max(best, this.response.get() ?? 0);
        best = Math.max(best, this.inscripEvent.get() ?? 0);
        return best;
    }

    getUserCount(user: User) {
        let best = 0;
        let res = 0;
        if (this.post.get() ?? 0 > best) {
            best = this.post.get() ?? 0;
            res = user.post.get() ?? 0;
        }
        if (this.react.get() ?? 0 > best) {
            best = this.react.get() ?? 0;
            res = user.react.get() ?? 0;
        }
        if (this.message.get() ?? 0 > best) {
            best = this.message.get() ?? 0;
            res = user.message.get() ?? 0;
        }
        if (this.openEvent.get() ?? 0 > best) {
            best = this.openEvent.get() ?? 0;
            res = user.openEvent.get() ?? 0;
        }
        if (this.openApp.get() ?? 0 > best) {
            best = this.openApp.get() ?? 0;
            res = user.openApp.get() ?? 0;
        }
        if (this.retroActivite.get() ?? 0 > best) {
            best = this.retroActivite.get() ?? 0;
            res = user.retroActivite.get() ?? 0;
        }
        if (this.file.get() ?? 0 > best) {
            best = this.file.get() ?? 0;
            res = user.file.get() ?? 0;
        }
        if (this.response.get() ?? 0 > best) {
            best = this.response.get() ?? 0;
            res = user.response.get() ?? 0;
        }
        if (this.inscripEvent.get() ?? 0 > best) {
            best = this.inscripEvent.get() ?? 0;
            res = user.inscripEvent.get() ?? 0;
        }
        return res;
    }

    getBadgeKey(): string {
        return (this.key.get() ?? '').split('_')[0];
    }

    getBadgeIcon(size: number = 40) {
        if (this.getBadgeKey() === 'post') {
            return (
                <NewspaperIcon
                    fill={
                        DICT_COLOR[
                            (this.level.get() ?? '1') as '1' | '2' | '3'
                        ] as any
                    }
                    size={size}
                />
            );
        } else if (this.getBadgeKey() === 'react') {
            return (
                <HandThumbUpIcon
                    fill={
                        DICT_COLOR[
                            (this.level.get() ?? '1') as '1' | '2' | '3'
                        ] as any
                    }
                    size={size}
                />
            );
        } else if (this.getBadgeKey() === 'message') {
            return (
                <ChatBubbleOvalLeftIcon
                    fill={
                        DICT_COLOR[
                            (this.level.get() ?? '1') as '1' | '2' | '3'
                        ] as any
                    }
                    size={size}
                />
            );
        } else if (this.getBadgeKey() === 'event') {
            return (
                <CalendarDaysIcon
                    fill={
                        DICT_COLOR[
                            (this.level.get() ?? '1') as '1' | '2' | '3'
                        ] as any
                    }
                    size={size}
                />
            );
        } else if (this.getBadgeKey() === 'app') {
            return (
                <LockOpenIcon
                    fill={
                        DICT_COLOR[
                            (this.level.get() ?? '1') as '1' | '2' | '3'
                        ] as any
                    }
                    size={size - 5}
                />
            );
        } else if (this.getBadgeKey() === 'file') {
            return (
                <DocumentIcon
                    fill={
                        DICT_COLOR[
                            (this.level.get() ?? '1') as '1' | '2' | '3'
                        ] as any
                    }
                    size={size * 0.95}
                />
            );
        } else if (this.getBadgeKey() === 'response') {
            return (
                <ChartPieIcon
                    fill={
                        DICT_COLOR[
                            (this.level.get() ?? '1') as '1' | '2' | '3'
                        ] as any
                    }
                    size={size}
                />
            );
        } else if (this.getBadgeKey() === 'inscrip') {
            return (
                <ArrowLeftOnRectangleIcon
                    fill={
                        DICT_COLOR[
                            (this.level.get() ?? '1') as '1' | '2' | '3'
                        ] as any
                    }
                    size={size * 0.95}
                />
            );
        } else if (this.getBadgeKey() === 'retro') {
            return (
                <Bonheur
                    fill={
                        DICT_COLOR[
                            (this.level.get() ?? '1') as '1' | '2' | '3'
                        ] as any
                    }
                    height={size * 1.05}
                    width={size * 1.05}
                />
            );
        } else {
            return (
                <UserIcon
                    fill={
                        DICT_COLOR[
                            (this.level.get() ?? '1') as '1' | '2' | '3'
                        ] as any
                    }
                    size={size}
                />
            );
        }
    }

    getColor() {
        return DICT_COLOR[
            ((this.level.get() ?? '1') + '') as '1' | '2' | '3'
        ] as any;
    }

    getTier() {
        const level = this.level.get() + '' ?? '1';
        if (level === '3') {
            return 'III';
        } else if (level === '2') {
            return 'II';
        } else if (level === '1') {
            return 'I';
        } else {
            return 'I';
        }
    }

    getTitle() {
        if (this.getBadgeKey() === 'post') {
            return 'timeline_badge';
        } else if (this.getBadgeKey() === 'react') {
            return 'events_badge';
        } else if (this.getBadgeKey() === 'message') {
            return 'messages_badges';
        } else if (this.getBadgeKey() === 'event') {
            return 'event_badge';
        } else if (this.getBadgeKey() === 'app') {
            return 'app';
        } else if (this.getBadgeKey() === 'file') {
            return 'file';
        } else if (this.getBadgeKey() === 'response') {
            return 'response';
        } else if (this.getBadgeKey() === 'inscrip') {
            return 'inscrip_title';
        } else if (this.getBadgeKey() === 'retro') {
            return 'retro';
        } else {
            return 'other';
        }
    }

    getDescription() {
        if (this.getBadgeKey() === 'post') {
            return 'post_desc';
        } else if (this.getBadgeKey() === 'react') {
            return 'react_desc';
        } else if (this.getBadgeKey() === 'message') {
            return 'message_desc';
        } else if (this.getBadgeKey() === 'event') {
            return 'event_desc';
        } else if (this.getBadgeKey() === 'app') {
            return 'app_desc';
        } else if (this.getBadgeKey() === 'file') {
            return 'file_desc';
        } else if (this.getBadgeKey() === 'response') {
            return 'response_desc';
        } else if (this.getBadgeKey() === 'inscrip') {
            return 'inscrip_desc';
        } else if (this.getBadgeKey() === 'retro') {
            return 'retro_desc';
        } else {
            return 'other_desc';
        }
    }
}
