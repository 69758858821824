import JTOObject from '../../../utils/jto/JTOObject';
import JTOSimple from '../../../utils/jto/JTOSimple';
import JTOProperty from '../../../utils/jto/JTOProperty';
import JTORelProperty from '../../../utils/jto/JTORelProperty';
import JTOList from '../../../utils/jto/JTOList';
import JTOElement from '../../../utils/jto/JTOElement';
import { MessageTiny } from '../chat/MessageTiny';
import ChatGroupTiny from '../chat/ChatGroupTiny';
import JTOListFetch from '../../JTOListFetch';

export default class UserChat extends JTOObject {
    public static _TYPE: string = 'User';
    public chatList: JTOListFetch<MessageTiny | ChatGroupTiny>;

    constructor(parent?: JTOElement | null) {
        super(UserChat._TYPE, parent);

        this.chatList = new JTOListFetch<MessageTiny | ChatGroupTiny>(
            '/chat/list',
            [MessageTiny, ChatGroupTiny],
            ['send', 'receive', 'has_chat_group'],
            this,
            (
                a: MessageTiny | ChatGroupTiny,
                b: MessageTiny | ChatGroupTiny,
            ) => {
                let aDate: Date;
                if (a instanceof MessageTiny) {
                    aDate = new Date(a.date.get() ?? '');
                } else {
                    aDate = new Date(a.message.get()?.date.get() ?? '');
                }
                let bDate: Date;
                if (b instanceof MessageTiny) {
                    bDate = new Date(b.date.get() ?? '');
                } else {
                    bDate = new Date(b.message.get()?.date.get() ?? '');
                }
                return aDate.getTime() > bDate.getTime() ? -1 : 1;
            },
        );
    }
}
