import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Pressable, StyleSheet } from 'react-native';
import React from 'react';
import { PanGestureHandler, ScrollView } from 'react-native-gesture-handler';
import Event from '../object/Event';
import User from '../object/User';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import { IModalProps } from './IModalProps';
import { Header } from '../component/Header';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { CartoucheButton } from '../component/CartoucheButton';
import { MpInput } from '../base/MpInput';
import { styles } from '../utils/styles';
import OpenQuestion from '../object/OpenQuestion';
import SocketInstance from '../utils/SocketInstance';
import { Text } from 'react-native';
//import CardsSwipe from 'react-native-cards-swipe';
import { stylesCard } from './QuizModal';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { get } from '../utils/http';
import { HTTPUtils } from 'mp-ts-http';
import { GeSpinner } from '../component/GeSpinner';
import {
    ClockIcon,
    HandThumbDownIcon,
    HandThumbUpIcon,
} from 'react-native-heroicons/solid';
import DirectQuestion from '../object/DirectQuestion';
import Answer from '../object/Answer';
import BadAnim from '../component/BadAnim';
import SucccessAnimation from '../component/SuccessAnimation';
import SuperSucccessAnimation from '../component/SuperSuccessAnimation';
import useJTOState from '../utils/jto/useJTOState';
import JTOInstance from '../utils/jto/JTOInstance';

const LETTERS = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
];

export const DirectQuizModal = (props: IModalProps) => {
    const { t } = useTranslation();
    User.translation = t;

    const goodAnimationRef = useRef<any>();
    const finalAnimationRef = useRef<any>();
    const badAnimationRef = useRef<any>();

    const quiz = useJTOState((props as any).route.params.event as Event);

    const [loading, setLoading] = useState(true);
    const [block, setBlock] = useState(true);

    const currentQuestion = useJTOState(quiz.currentDirectQuestion);

    const [answer, setAnswer] = useState<string>('');

    const next = (question: DirectQuestion, answerChild: Answer) => {
        setAnswer('answered');

        if (question.position.get() === questionList.length) {
            lastQuestion(question, answerChild);
        } else {
            notLastQuestion(question, answerChild);
        }
        SocketInstance.get().socket?.emit('direct.answer', {
            id_event: quiz.getJTOIdentifier(),
            id_question: question.getJTOIdentifier(),
            answer: answerChild.getJTOIdentifier(),
        });
        quiz.notifyViewNow();
        refCard.current.swipeRight(() => {
            quiz.notifyViewNow();
        });
    };
    const lastQuestion = (question: DirectQuestion, answerChild: Answer) => {
        if (answerChild.goodAnswer.get() === true) {
            quiz.myScore++;
            goodAnimationRef.current?.start();
        } else {
            badAnimationRef.current?.start();
        }
        question.myAnswer = answerChild;
    };

    const notLastQuestion = (question: DirectQuestion, answerChild: Answer) => {
        if (answerChild.goodAnswer.get() === true) {
            quiz.myScore++;
            goodAnimationRef.current?.start();
        } else {
            badAnimationRef.current?.start();
        }
        question.myAnswer = answerChild;
    };

    const questionList = quiz.directQuestionList.getList();
    const cardsData = [] as any[];
    for (let i = 0; i < quiz.directQuestionList.getList().length; i++) {
        const question = quiz.directQuestionList.get(i);
        cardsData.push(question);
        cardsData.push({
            type: 'answer',
            question,
        });
    }

    useEffect(() => {
        setLoading(true);
        get('/direct/quiz/direct', {
            id: quiz.getJTOIdentifier(),
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    const e = HTTPUtils.getResult(res);
                    JTOInstance.applyResult(e, true);
                } else {
                    ToastManager.instance.addToast(
                        new Toast(t(HTTPUtils.getError(res)), 5000, 'error'),
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet'), 5000, 'error'),
                );
            });
    }, []);

    useEffect(() => {
        if (quiz.hasRealTimeEnded.get() === true) {
            if (answer === 'answered') {
                refCard.current.swipeRight();
            } else {
                refCard.current.swipeRight();
                setTimeout(() => {
                    refCard.current.swipeRight();
                }, 600);
            }
        }
    }, [quiz.hasRealTimeEnded?.get()]);

    const refCard = useRef<any>();

    useEffect(() => {
        if (currentQuestion.get() !== null) {
            if (!block) {
                if (answer === 'answered') {
                    refCard.current.swipeRight();
                } else {
                    refCard.current.swipeRight();
                    setTimeout(() => {
                        refCard.current.swipeRight();
                    }, 800);
                }
                setAnswer('');
            } else {
                setBlock(false);
            }
        }
    }, [currentQuestion.get()?.position.get()]);

    useEffect(() => {
        if (quiz.hasRealTimeEnded.get() === true) {
            const lastQuest = quiz.getLastDirectQuestion();
            if (lastQuest?.myStringAnswer !== null) {
                refCard.current.swipeRight();
            } else {
                refCard.current.swipeRight();
                setTimeout(() => {
                    refCard.current.swipeRight();
                }, 600);
            }

            setTimeout(() => {
                finalAnimationRef.current?.startSuper();
            }, 500);
        }
    }, [quiz.hasRealTimeEnded?.get()]);

    const getScoreReaction = (score: number, total: number) => {
        const percent = (score / total) * 100;
        let reaction = '';
        if (percent < 25) {
            reaction = t('unlucky');
        } else if (percent < 50) {
            reaction = t('not_bad');
        } else if (percent < 75) {
            reaction = t('good');
        } else {
            reaction = t('well_played');
        }
        return reaction;
    };

    const getScoreReactionIcon = (score: number, total: number) => {
        const percent = (score / total) * 100;
        let reaction;
        if (percent < 25) {
            reaction = (
                <HandThumbDownIcon
                    style={{
                        paddingVertical: 15,
                    }}
                    height={75}
                    width={75}
                    color={getColor('error')}
                />
            );
        } else if (percent < 50) {
            reaction = (
                <HandThumbUpIcon
                    style={{
                        paddingVertical: 15,
                    }}
                    height={75}
                    width={75}
                    color={getColor('success')}
                />
            );
        } else if (percent < 75) {
            reaction = (
                <HandThumbUpIcon
                    style={{
                        paddingVertical: 15,
                    }}
                    height={75}
                    width={75}
                    color={getColor('success')}
                />
            );
        } else {
            reaction = (
                <HandThumbUpIcon
                    style={{
                        paddingVertical: 15,
                    }}
                    height={75}
                    width={75}
                    color={getColor('success')}
                />
            );
        }
        return reaction;
    };

    cardsData.push('score');

    return <View></View>;

    return (
        <View
            style={{
                width: width(),
                height: height(),
                paddingBottom: 0,
                backgroundColor: getColor('primary'),
            }}
        >
            <Header
                modal={true}
                backButton={true}
                full
                navigation={props.navigation}
                title={quiz.name.get() ?? ''}
                line={(quiz.name.get() ?? '').length <= 25 ? 1.01 : 3}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        flex: 1,
                        marginTop: HEADER_BONUS_HEIGHT,
                        width: width(),
                    }}
                >
                    {/*<CardsSwipe
                        ref={refCard}
                        loop={false}
                        key={
                            currentQuestion.get() === null || loading
                                ? new Date().getTime()
                                : 'CardsSwipe'
                        }
                        cards={cardsData}
                        initialIndex={
                            loading
                                ? 0
                                : (currentQuestion.get()?.position.get() ??
                                      0.5) *
                                      2 -
                                  2
                        }
                        onSwiped={(index: number) => {
                            // TODO
                        }}
                        cardContainerStyle={stylesCard.cardContainer}
                        renderCard={(card: any) => {
                            if (card === 'first') {
                                if (loading) {
                                    return (
                                        <PanGestureHandler>
                                            <View
                                                style={[
                                                    stylesCard.card,
                                                    {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        flex: 1,
                                                    },
                                                ]}
                                            >
                                                <GeSpinner
                                                    color={getColor('primary')}
                                                />
                                            </View>
                                        </PanGestureHandler>
                                    );
                                } else {
                                    return (
                                        <PanGestureHandler>
                                            <View style={[stylesCard.card]}>
                                                <Text
                                                    style={{
                                                        fontFamily: FONT_BOLD,
                                                        fontSize: 25,
                                                        color: getColor('text'),
                                                    }}
                                                >
                                                    {t('waiting')}
                                                </Text>
                                                <View
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        flex: 1,
                                                    }}
                                                >
                                                    <ClockIcon
                                                        style={{
                                                            paddingVertical: 15,
                                                        }}
                                                        height={75}
                                                        width={75}
                                                        color={getColor(
                                                            'secondary',
                                                        )}
                                                    />
                                                    <Text
                                                        style={{
                                                            fontFamily:
                                                                FONT_BOLD,
                                                            fontSize: 25,
                                                            color: getColor(
                                                                'text',
                                                            ),
                                                            paddingTop: 15,
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {t('waiting')}
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            fontFamily:
                                                                FONT_REGULAR,
                                                            fontSize: 16,
                                                            color: getColor(
                                                                'text',
                                                            ),
                                                            paddingTop: 15,
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {t('quiz_waiting')}
                                                    </Text>
                                                </View>
                                            </View>
                                        </PanGestureHandler>
                                    );
                                }
                            } else if (card === 'score') {
                                let question: DirectQuestion;
                                if (card instanceof DirectQuestion) {
                                    question = card;
                                } else {
                                    question = card.question;
                                }
                                return (
                                    <PanGestureHandler>
                                        <View style={stylesCard.card}>
                                            <Text
                                                style={{
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 25,
                                                    color: getColor('text'),
                                                }}
                                            >
                                                {t('score')}
                                            </Text>
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    flex: 1,
                                                }}
                                            >
                                                {getScoreReactionIcon(
                                                    quiz.myScore,
                                                    questionList.length,
                                                )}
                                                <Text
                                                    style={{
                                                        fontFamily: FONT_BOLD,
                                                        fontSize: 25,
                                                        paddingTop: 15,
                                                        color: getColor('text'),
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {getScoreReaction(
                                                        quiz.myScore,
                                                        questionList.length,
                                                    )}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: FONT_MEDIUM,
                                                        fontSize: 18,
                                                        paddingTop: 15,
                                                        color: getColor('text'),
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {t('score_quiz')}
                                                    {quiz.myScore +
                                                        '/' +
                                                        questionList.length}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily:
                                                            FONT_REGULAR,
                                                        fontSize: 16,
                                                        paddingTop: 15,
                                                        color: getColor('text'),
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {t('thanks_quiz')}
                                                </Text>
                                                <View
                                                    style={{
                                                        padding: 15,
                                                    }}
                                                >
                                                    <CartoucheButton
                                                        color={getColor(
                                                            'secondary',
                                                        )}
                                                        height={45}
                                                        fontSize={20}
                                                        iconSize={20}
                                                        onPress={() => {
                                                            props.navigation.goBack();
                                                            quiz.sendAnswer();
                                                        }}
                                                        title={t('leave')}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    </PanGestureHandler>
                                );
                            } else {
                                let question: DirectQuestion;
                                if (card instanceof DirectQuestion) {
                                    question = card;
                                } else {
                                    question = card.question;
                                }
                                const good =
                                    question.myAnswer !== 'dodge' &&
                                    question.myAnswer?.goodAnswer.get() ===
                                        true;
                                return (
                                    <PanGestureHandler>
                                        <View style={stylesCard.card}>
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily: FONT_BOLD,
                                                        fontSize: 25,
                                                        color: getColor('text'),
                                                    }}
                                                >
                                                    {(question.position.get() ??
                                                        0) + ''}
                                                    .
                                                </Text>
                                                {!(
                                                    card instanceof
                                                    DirectQuestion
                                                ) &&
                                                    question.myAnswer !==
                                                        null && (
                                                        <View
                                                            style={{
                                                                paddingHorizontal: 15,
                                                                paddingVertical: 7,
                                                                borderRadius: 20,
                                                                backgroundColor:
                                                                    good
                                                                        ? getColor(
                                                                              'success',
                                                                          )
                                                                        : getColor(
                                                                              'error',
                                                                          ),
                                                            }}
                                                        >
                                                            <Text
                                                                style={{
                                                                    fontFamily:
                                                                        FONT_BOLD,
                                                                    fontSize: 15,
                                                                    color: 'white',
                                                                }}
                                                            >
                                                                {good
                                                                    ? t(
                                                                          'good_answer',
                                                                      )
                                                                    : t(
                                                                          'bad_answer',
                                                                      )}{' '}
                                                                {good
                                                                    ? '+ 1'
                                                                    : ''}
                                                            </Text>
                                                        </View>
                                                    )}
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    width: '100%',
                                                    display: 'flex',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily: FONT_BOLD,
                                                        fontSize: 25,
                                                        color: getColor('text'),
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    {question.title.get()}
                                                </Text>
                                                {card instanceof
                                                    DirectQuestion &&
                                                    question.myAnswer !==
                                                        null &&
                                                    question.descriptionAnswer.get() && (
                                                        <Text
                                                            style={{
                                                                fontFamily:
                                                                    FONT_MEDIUM,
                                                                fontSize: 18,
                                                                color: getColor(
                                                                    'text',
                                                                ),
                                                                marginBottom: 10,
                                                            }}
                                                        >
                                                            {question.descriptionAnswer.get()}
                                                        </Text>
                                                    )}
                                                {!(
                                                    card instanceof
                                                    DirectQuestion
                                                ) &&
                                                    (question.myAnswer ===
                                                    null ? (
                                                        <View
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                flex: 1,
                                                            }}
                                                        >
                                                            <ClockIcon
                                                                style={{
                                                                    paddingVertical: 15,
                                                                }}
                                                                height={75}
                                                                width={75}
                                                                color={getColor(
                                                                    'secondary',
                                                                )}
                                                            />
                                                            <Text
                                                                style={{
                                                                    fontFamily:
                                                                        FONT_BOLD,
                                                                    fontSize: 25,
                                                                    paddingTop: 15,
                                                                    color: getColor(
                                                                        'text',
                                                                    ),
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                            >
                                                                {t('waiting')}
                                                            </Text>
                                                            <Text
                                                                style={{
                                                                    fontFamily:
                                                                        FONT_REGULAR,
                                                                    paddingVertical: 15,
                                                                    textAlign:
                                                                        'center',
                                                                    color: getColor(
                                                                        'text',
                                                                    ),
                                                                    fontSize: 16,
                                                                }}
                                                            >
                                                                {t(
                                                                    'no_answer_at_this_time',
                                                                )}
                                                            </Text>
                                                        </View>
                                                    ) : (
                                                        <View
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                flex: 1,
                                                            }}
                                                        >
                                                            {good ? (
                                                                <HandThumbUpIcon
                                                                    style={{
                                                                        paddingVertical: 15,
                                                                    }}
                                                                    height={75}
                                                                    width={75}
                                                                    color={getColor(
                                                                        'success',
                                                                    )}
                                                                />
                                                            ) : (
                                                                <HandThumbDownIcon
                                                                    style={{
                                                                        paddingVertical: 15,
                                                                    }}
                                                                    height={75}
                                                                    width={75}
                                                                    color={getColor(
                                                                        'error',
                                                                    )}
                                                                />
                                                            )}
                                                            <Text
                                                                style={{
                                                                    fontFamily:
                                                                        FONT_BOLD,
                                                                    fontSize: 25,
                                                                    paddingTop: 15,
                                                                    color: getColor(
                                                                        'text',
                                                                    ),
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                            >
                                                                {good
                                                                    ? t(
                                                                          'good_answer',
                                                                      )
                                                                    : t(
                                                                          'bad_answer',
                                                                      )}
                                                            </Text>
                                                        </View>
                                                    ))}
                                                {question.answerList
                                                    .getList()
                                                    .map(
                                                        (
                                                            a: Answer,
                                                            i: number,
                                                        ) => {
                                                            if (
                                                                card instanceof
                                                                    DirectQuestion ||
                                                                ((a.goodAnswer.get() ===
                                                                    true ||
                                                                    a ===
                                                                        question.myAnswer) &&
                                                                    question.myAnswer !==
                                                                        null)
                                                            ) {
                                                                let borderColor =
                                                                    getColor(
                                                                        'border',
                                                                    );
                                                                let backgroundColor =
                                                                    getColor(
                                                                        'background',
                                                                    );
                                                                let textColor =
                                                                    getColor(
                                                                        'text',
                                                                    );
                                                                if (
                                                                    question.myAnswer !==
                                                                    null
                                                                ) {
                                                                    if (
                                                                        question.myAnswer ===
                                                                        a
                                                                    ) {
                                                                        if (
                                                                            a.goodAnswer.get() ===
                                                                            true
                                                                        ) {
                                                                            borderColor =
                                                                                getColor(
                                                                                    'success',
                                                                                );
                                                                            backgroundColor =
                                                                                getColor(
                                                                                    'success',
                                                                                );
                                                                            textColor =
                                                                                'white';
                                                                        } else {
                                                                            borderColor =
                                                                                getColor(
                                                                                    'error',
                                                                                );
                                                                            backgroundColor =
                                                                                getColor(
                                                                                    'error',
                                                                                );
                                                                            textColor =
                                                                                'white';
                                                                        }
                                                                    } else {
                                                                        if (
                                                                            a.goodAnswer.get() ===
                                                                            true
                                                                        ) {
                                                                            borderColor =
                                                                                getColor(
                                                                                    'success',
                                                                                );
                                                                            backgroundColor =
                                                                                getColor(
                                                                                    'success',
                                                                                );
                                                                            textColor =
                                                                                'white';
                                                                        }
                                                                    }
                                                                }
                                                                return (
                                                                    <Pressable
                                                                        onPress={() => {
                                                                            if (
                                                                                question.myAnswer ===
                                                                                null
                                                                            ) {
                                                                                next(
                                                                                    card,
                                                                                    a,
                                                                                );
                                                                            }
                                                                        }}
                                                                        style={{
                                                                            marginVertical: 10,
                                                                            width: '100%',
                                                                            borderRadius: 30,
                                                                            paddingVertical: 15,
                                                                            paddingHorizontal: 20,
                                                                            borderWidth: 1,
                                                                            borderColor,
                                                                            backgroundColor,
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            adjustsFontSizeToFit
                                                                            numberOfLines={
                                                                                1
                                                                            }
                                                                            style={{
                                                                                fontSize: 16,
                                                                                color: textColor,
                                                                                fontFamily:
                                                                                    FONT_MEDIUM,
                                                                            }}
                                                                        >
                                                                            {
                                                                                LETTERS[
                                                                                    i
                                                                                ]
                                                                            }
                                                                            .{' '}
                                                                            {a.title.get()}
                                                                        </Text>
                                                                    </Pressable>
                                                                );
                                                            }
                                                        },
                                                    )}
                                            </View>
                                            <View
                                                style={{
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    marginBottom: 10,
                                                }}
                                            ></View>
                                        </View>
                                    </PanGestureHandler>
                                );
                            }
                        }}
                    />*/}
                    <View
                        style={{
                            padding: 20,
                            marginBottom: NAVIGATION_BAR_HEIGHT + TAB_HEIGHT,
                        }}
                    >
                        <CartoucheButton
                            color={getColor('secondary')}
                            height={45}
                            fontSize={20}
                            iconSize={20}
                            onPress={() => {
                                props.navigation.goBack();
                                quiz.sendAnswer();
                            }}
                            title={t('leave_quiz')}
                        />
                    </View>

                    <BadAnim
                        pause={true}
                        duration={2000}
                        ref={badAnimationRef}
                        width={height() * 0.8}
                        height={height() * 0.8}
                        minConf={100}
                        maxConf={100}
                    />
                    <SucccessAnimation ref={goodAnimationRef} />
                    <SuperSucccessAnimation ref={finalAnimationRef} />
                </View>
            </Header>
        </View>
    );
};
