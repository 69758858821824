import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_MEDIUM, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Keychain from 'react-native-keychain';
import ChatGroup from '../object/ChatGroup';
import { Header } from '../component/Header';
import User from '../object/User';
import { PastilleView } from '../component/PastilleView';
import { UserGroupIcon, UserIcon } from 'react-native-heroicons/solid';

const actionList = [
    {
        label: 'create_chat_group',
        onClick: (navigation: any) => {
            navigation.navigate('AddChatGroupModal');
        },
        icon: (
            <UserGroupIcon
                color={'white'}
                size={22}
            />
        ),
    },
    {
        label: 'create_chat',
        onClick: (navigation: any) => {
            navigation.navigate('SelectInviteModal');
        },
        icon: (
            <UserIcon
                color={'white'}
                size={22}
            />
        ),
    },
];

export const MenuAddChatModal = (props: IModalProps) => {
    const { t } = useTranslation();

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('create_chat')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: 15,
                    }}
                >
                    {actionList.map((action, index) => {
                        return (
                            <Pressable
                                key={index}
                                style={[
                                    index !== 0 && {
                                        borderTopWidth: 1,
                                        borderColor: getColor('border'),
                                    },
                                    {
                                        flexDirection: 'row',
                                        marginHorizontal: 20,
                                        padding: 10,
                                        alignItems: 'center',
                                        backgroundColor: getColor('card'),
                                    },
                                    index === 0 && {
                                        borderTopLeftRadius: 15,
                                        borderTopRightRadius: 15,
                                    },
                                    index === actionList.length - 1 && {
                                        borderBottomLeftRadius: 15,
                                        borderBottomRightRadius: 15,
                                    },
                                ]}
                                onPress={(e) => {
                                    e.stopPropagation();
                                    action.onClick(props.navigation);
                                }}
                            >
                                <PastilleView
                                    size={32}
                                    color={getColor('secondary')}
                                    icon={action.icon}
                                />
                                <Text
                                    adjustsFontSizeToFit
                                    style={[
                                        {
                                            marginLeft: 10,
                                            color: 'black',
                                            fontFamily: FONT_MEDIUM,
                                            fontSize: 16,
                                            flex: 1,
                                        },
                                    ]}
                                >
                                    {t(action.label)}
                                </Text>
                            </Pressable>
                        );
                    })}
                </View>
            </Header>
        </View>
    );
};
