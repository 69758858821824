import * as React from 'react';
import Svg, { SvgProps } from 'react-native-svg';

function Workshop(props: any) {
    return (
        <svg
            viewBox='0 0 142.96 142.45'
            {...props}
        >
            <path
                fill='currentColor'
                d='M95.13,58.14c-7.93-10.29-18.56-16.31-31.1-18.92-14.2-2.96-27.87-1.19-40.65,5.84-10.19,5.6-17.73,13.63-21.55,24.83-.76,2.27-1.24,4.63-1.56,7.02-.03.22.07.5-.26.6v1.71c.2.48.2.98,0,1.47v2.69c.15.16.15.32,0,.48v1.23c.59,1.38.56,2.89.89,4.33,1.5,6.71,4.47,12.68,8.96,17.88.57.66.55,1.02-.03,1.66-2.88,3.21-5.69,6.48-8.54,9.7-.51.58-.86,1.25-1.28,1.87v1.96c.97,2.11,2.64,2.73,4.9,2.72,8.73-.03,17.46.06,26.2.09,3.56,0,7.14,0,10.7-.01,4.36-.02,8.72-.09,13.07-.21,18.67-.51,33.27-9.04,43.02-24.95,8.18-13.35,6.81-29.54-2.77-41.98ZM29.39,88.01c-4.02.02-7.34-3.22-7.39-7.22-.05-4.08,3.22-7.42,7.27-7.45,4.08-.02,7.39,3.27,7.39,7.34s-3.27,7.3-7.27,7.33ZM51.62,88.01c-4.06.02-7.38-3.28-7.38-7.35s3.27-7.3,7.28-7.32c4.07-.02,7.39,3.28,7.38,7.34,0,4.01-3.27,7.31-7.28,7.33ZM73.89,88.01c-4.07.03-7.4-3.26-7.4-7.32-.01-4.07,3.31-7.37,7.38-7.35,4,.02,7.28,3.31,7.28,7.32.01,4.01-3.26,7.32-7.26,7.35Z'
            />
            <path
                fill='currentColor'
                d='M124.77,78.58c-.73,1.52-2.02,2.12-3.68,2.1-3.26-.02-6.51-.01-9.77,0-.54,0-.86-.01-.89-.73-.78-15.68-8.18-27.75-20.48-37.02-9.1-6.86-19.47-10.63-30.76-11.92-9.77-1.11-19.33-.13-28.67,2.93-.11.03-.23.03-.48.06.38-2.55,1.17-4.91,2.16-7.21,3.97-9.05,10.65-15.54,19.19-20.25,6.49-3.57,13.48-5.59,20.84-6.29.22-.02.51.09.6-.25-.07.67-.63.53-1.03.58-3.48.43-6.91,1.09-10.28,2.08-6.65,1.97-12.64,5.18-17.98,9.6-4.01,3.32-7.24,7.27-9.72,11.82-1.46,2.66-2.47,5.5-3.2,8.43-.2.81-.02,1.07.83.8,4.36-1.41,8.83-2.31,13.39-2.74,3.76-.34,7.53-.42,11.32-.16,6.19.42,12.2,1.6,18.01,3.73,7.8,2.86,14.88,6.92,20.95,12.69,4.24,4.03,7.82,8.53,10.5,13.7,2.78,5.36,4.52,11.05,4.97,17.13.25,3.29.05,2.63,2.75,2.68,2.56.03,5.13-.01,7.69.01,1.57.01,2.78-.53,3.46-2.02.7-1.52.16-2.78-.84-3.92-2.53-2.93-5.03-5.88-7.55-8.81-.83-.97-.81-1.18.03-2.18,4.76-5.76,7.76-12.29,8.46-19.79.99-10.35-2.4-19.21-9.05-26.99-5.43-6.33-12.29-10.5-20.03-13.31-4.03-1.46-8.22-2.28-12.49-2.77-.43-.04-1.03.13-1.15-.57,1.48.53,3.07.45,4.6.72,12.6,2.22,23.35,7.72,31.21,18.05,10.41,13.66,9.85,31.7-1.26,44.78-.65.76-.72,1.18-.02,1.97,2.59,2.88,5.05,5.86,7.58,8.79,1.12,1.28,1.53,2.68.76,4.27Z'
            />
            <path
                fill='currentColor'
                d='M124.77,78.58c-.73,1.52-2.02,2.12-3.68,2.1-3.26-.02-6.51-.01-9.77,0-.54,0-.86-.01-.89-.73-.78-15.68-8.18-27.75-20.48-37.02-9.1-6.86-19.47-10.63-30.76-11.92-9.77-1.11-19.33-.13-28.67,2.93-.11.03-.23.03-.48.06.38-2.55,1.17-4.91,2.16-7.21,3.97-9.05,10.65-15.54,19.19-20.25,6.49-3.57,13.48-5.59,20.84-6.29.22-.02.51.09.6-.25h1.95c.57.2,1.15.2,1.71,0h1.47c.49.2.98.2,1.47,0h1.22c.16.16.33.16.49,0h.73c1.48.53,3.07.45,4.6.72,12.6,2.22,23.35,7.72,31.21,18.05,10.41,13.66,9.85,31.7-1.26,44.78-.65.76-.72,1.18-.02,1.97,2.59,2.88,5.05,5.86,7.58,8.79,1.12,1.28,1.53,2.68.76,4.27Z'
            />
            <path
                fill='currentColor'
                d='M76.51,0c-.57.37-1.14.41-1.71,0h1.71Z'
            />
            <path
                fill='currentColor'
                d='M79.44,0c-.49.39-.98.35-1.47,0h1.47Z'
            />
            <path
                fill='currentColor'
                d='M0,79.21c.41.49.34.98,0,1.47v-1.47Z'
            />
            <path
                fill='currentColor'
                d='M81.15,0c-.16.36-.33.31-.49,0h.49Z'
            />
            <path
                fill='currentColor'
                d='M0,83.37c.26.16.32.33,0,.49v-.49Z'
            />
        </svg>
    );
}

export default Workshop;
