import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';
import FileSuper from './FileSuper';

export default class ProfilFile extends FileSuper {
    public static _TYPE: string = 'ProfilFile';

    constructor(parent?: JTOElement | null) {
        super(ProfilFile._TYPE, parent);

        this.name = new JTOProperty<string>('name', this);
        this.type = new JTOProperty<string>('type', this);
        this.alias = new JTOProperty<string>('alias', this);
    }
}
