import JTOContainer from './JTOContainer';
import JTOInstance from './JTOInstance';

/**
 * JTOElement is the base class for all JTO classes.
 */
export default abstract class JTOElement {
    /**
     * All private field to prevent circular recursion
     */
    protected static PRIVATE_FIELD_LIST: string[] = [
        'JTOParent',
        'create',
        'update',
        'field',
        'next',
    ];

    /**
     * Variable that contains all JTOContainer, that be hooked
     * @type {JTOObject[]}
     */
    private containerList: JTOContainer[] = [];

    /**
     * Add a JTOObject to the global JTOContainer
     * @returns {JTOObject} the JTOObject
     */
    public removeContainer(id: string) {
        this.containerList = this.containerList.filter(
            (elem) => elem.id !== id,
        );
    }

    public removeContainerObj(object: JTOContainer) {
        this.containerList = this.containerList.filter(
            (elem) => elem !== object,
        );
    }

    /**
     * Add a JTOContainer to the global JTOInstance
     * @returns {JTOContainer} the JTOContainer
     */
    public addContainer(object: JTOContainer) {
        if (!this.containerList.find((elem) => object === elem)) {
            this.containerList.push(object);
        }
    }

    /**
     * Variable JTOParent contains the parent of this object, null if this object is the root
     * @type {JTOElement | null}
     */
    private JTOParent: JTOElement | null;

    /**
     * Constructor of JTOElement
     * @param JTOParent the parent of this object, null if the object is the root
     * @param update the update function
     */
    constructor(JTOParent: JTOElement | null = null) {
        this.JTOParent = JTOParent;
    }

    public hasParent(): boolean {
        return this.JTOParent != null;
    }

    public abstract applyDataPartiel(
        data: { [key: string]: any },
        first: boolean,
    ): boolean;
    public abstract applyData(data: { [key: string]: any }): boolean;

    /**
     * Apply data to the object and its child conform to the mode of the JTOInstance
     * return true if their is any change in the hierarchy
     * @param data the json souce
     * @param applyParent true if the json was applyed to the parent
     * @returns {boolean} true if their is any change in the object or in the child
     */
    /*public abstract applyData(data: { [key: string]: any }, applyParent: boolean): boolean;*/

    public abstract getJTOIdentifier(): any;
    public abstract getJTOType(): any;
    public abstract toJson(): any;

    public getJTOElementList(recursively: boolean): JTOElement[] {
        // the list return
        const list: JTOElement[] = [];
        // the currrent object
        const obj: JTOElement = this;
        // for all property of the object
        Object.getOwnPropertyNames(this).forEach((field) => {
            // if the property is not privateField
            if (!JTOElement.PRIVATE_FIELD_LIST.includes(field)) {
                // get the value of the property
                const value = obj[field as keyof JTOElement];
                // if the value is an JTOObject add to the list
                if (value instanceof JTOElement) {
                    // get child recursively
                    if (recursively) {
                        list.push(...value.getJTOElementList(recursively));
                    } else {
                        list.push(value);
                    }
                }
            }
        });
        return list;
    }

    /**
     * Notify the changes in the object
     */
    public makeUpdate() {
        if (this.containerList.length === 0) {
            this.JTOParent?.makeUpdate();
        } else {
            for (const container of this.containerList) {
                container.func();
            }
        }
    }

    /**
     * Notify the changes in the object
     */
    public notifyView() {
        if (this.containerList.length === 0) {
            this.JTOParent?.notifyView();
        } else {
            for (const container of this.containerList) {
                JTOInstance.addOrder(container.func);
            }
        }
    }

    /**
     * Notify the changes in the object
     */
    public notifyViewNow() {
        if (this.containerList.length === 0) {
            this.JTOParent?.notifyView();
        } else {
            for (const container of this.containerList) {
                container.func();
            }
        }
    }

    /**
     * Notify the changes in the object
     */
    public notifyRootView() {
        this.JTOParent?.notifyView();
        for (const container of this.containerList) {
            JTOInstance.addOrder(container.func);
        }
    }

    /**
     * Get the parent of this object
     * @returns {JTOElement | null} the parent of this object
     */
    public getJTOParent(): JTOElement | null {
        return this.JTOParent;
    }

    /**
     * Set the parent of this object
     * @param JTOParent the new parent
     */
    public setJTOParent(JTOParent: JTOElement | null) {
        this.JTOParent = JTOParent;
    }

    protected isDeleteOrder(
        json: { [key: string]: any },
        elem: JTOElement | null,
    ): boolean {
        let res = false;
        if (json === null || json === undefined) {
            return false;
        }
        if (
            json[JTOInstance.getDeleteField()] === true ||
            json[JTOInstance.getDeleteField()] === 'True' ||
            json[JTOInstance.getDeleteField()] === 'true'
        ) {
            res = true;
        } else if (elem != null) {
            if (JTOInstance.isDeepEqual()) {
                res =
                    json[JTOInstance.getDeleteField()] ===
                        this.getJTOIdentifier() &&
                    json[JTOInstance.getTypeField()] === elem.getJTOType();
            } else {
                res =
                    json[JTOInstance.getDeleteField()] ===
                    this.getJTOIdentifier();
            }
        } else {
            res =
                json[JTOInstance.getDeleteField()] === this.getJTOIdentifier();
        }
        return res;
    }

    protected isDeleteRelationOrder(
        json: { [key: string]: any },
        elem: JTOElement | null,
    ): boolean {
        let res = false;
        if (json === null || json === undefined) {
            return false;
        }
        if (
            json[JTOInstance.getDeleteRelationField()] === true ||
            json[JTOInstance.getDeleteRelationField()] === 'True' ||
            json[JTOInstance.getDeleteRelationField()] === 'true' ||
            json[JTOInstance.getDeleteRelationField()] ===
                (this.getJTOParent()?.getJTOIdentifier() ?? '')
        ) {
            res = true;
        }
        return res;
    }

    public reset() {
        const jtcElementList = this.getJTOElementList(false);
        for (const jtcElement of jtcElementList) {
            jtcElement.reset();
        }
    }

    public applyBlockedData(data: { [key: string]: any }) {
        return this.applyData(data);
    }
}
