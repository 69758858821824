import { Pressable, View } from 'react-native';
import React from 'react';
import { CheckCircleIcon, XMarkIcon } from 'react-native-heroicons/solid';
import { Text } from 'react-native';
import { STATUS_BAR_HEIGHT } from '../../utils/screen';
import { useEffect, useState } from 'react';
import { Toast } from './Toast';
import { ToastManager } from './ToastManager';
import { ToastView } from './ToastView';

export const ToastManagerView = () => {
    const [count, setCount] = useState(0);
    ToastManager.instance.register(setCount);

    return (
        <View
            style={{
                position: 'absolute',
                top: STATUS_BAR_HEIGHT + 20,
                left: 20,
                right: 20,
            }}
        >
            {ToastManager.instance.getToastList().map((toast, index) => {
                return (
                    <ToastView
                        index={index}
                        toast={toast}
                        key={index}
                    />
                );
            })}
        </View>
    );
};
