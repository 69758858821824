import { t } from 'i18next';
import JTOListFetch from './JTOListFetch';
import { Message } from './Message';
import User from './User';
import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';
import UserTiny from './compressed/global/UserTiny';

export default class ChatGroup extends JTOObject {
    public static _TYPE: string = 'ChatGroup';

    public name: JTOProperty<string>;
    public avatar: JTOProperty<string>;

    public messageList: JTOListFetch<Message>;
    public message: JTOSimple<Message>;

    public userList: JTOList<UserTiny>;

    public nbNewMessage: JTOProperty<number>;

    public connectConnect: JTORelProperty<boolean>;
    public connectDate: JTORelProperty<string>;

    constructor(parent?: JTOElement | null) {
        super(ChatGroup._TYPE, parent);

        this.name = new JTOProperty<string>('name', this);
        this.avatar = new JTOProperty<string>('avatar', this);

        this.userList = new JTOList(UserTiny, ['has_chat_group'], this);

        this.nbNewMessage = new JTOProperty<number>('nb_new_message', this);

        this.messageList = new JTOListFetch(
            '/message/group/list',
            Message,
            ['receive', 'send'],
            this,
            (a: Message, b: Message) => {
                const dateA = new Date(a.date.get() ?? '');
                const dateB = new Date(b.date.get() ?? '');
                // ORDER BY date DESC
                return dateA.getTime() > dateB.getTime() ? -1 : 1;
            },
        );

        this.message = new JTOSimple(Message, 'receive', this);
        this.connectConnect = new JTORelProperty<boolean>(
            ['connect.connect'],
            this,
            false,
        );
        this.connectDate = new JTORelProperty<string>(
            ['connect.date'],
            this,
            false,
        );
    }

    public getDisplayName(): string {
        return this.name.get() ?? '';
    }

    public isNewMessage(): boolean {
        return (this.nbNewMessage.get() ?? 0) > 0;
    }

    public getNbNewMessage(): number {
        return this.nbNewMessage.get() ?? 0;
    }

    public isConnect(): boolean {
        return this.connectConnect.get() === true;
    }
}
