import { HTTPUtils } from 'mp-ts-http';

import JTOObject from '../../../utils/jto/JTOObject';
import JTOSimple from '../../../utils/jto/JTOSimple';
import JTOProperty from '../../../utils/jto/JTOProperty';
import JTORelProperty from '../../../utils/jto/JTORelProperty';
import JTOList from '../../../utils/jto/JTOList';
import JTOElement from '../../../utils/jto/JTOElement';

import { getRestore, post } from '../../../utils/http';
import Answer from '../.././Answer';
import InviteTiny from '../.././compressed/global/InviteTiny';
import Contact from '../.././Contact';
import EventType from '../.././EventType';
import Invite from '../.././Invite';
import Event from '../../Event';
import FormRegistration from '../../FormRegistration';

export const compareEventTiny = (
    a: EventTiny | Event,
    b: EventTiny | Event,
    reverse: number = 1,
) => {
    if (
        a.startDatetime.get() === undefined &&
        b.startDatetime.get() === undefined &&
        a.endDatetime.get() === undefined &&
        b.endDatetime.get() === undefined
    ) {
        return 0 * reverse;
    }
    if (
        a.startDatetime.get() === null &&
        b.startDatetime.get() === null &&
        a.endDatetime.get() === null &&
        b.endDatetime.get() === null
    ) {
        return 0 * reverse;
    }

    if (
        a.startDatetime.get() === undefined &&
        a.endDatetime.get() === undefined
    )
        return 1 * reverse;
    if (
        b.startDatetime.get() === undefined &&
        b.endDatetime.get() === undefined
    )
        return 1 * reverse;

    if (a.startDatetime.get() === null && a.endDatetime.get() === null)
        return -1 * reverse;
    if (b.startDatetime.get() === null && b.endDatetime.get() === null)
        return -1 * reverse;

    let aDate: any;
    let bDate: any;

    if (a.startDatetime.get() !== undefined && a.startDatetime.get() !== null) {
        aDate = a.startDatetime.get();
    } else {
        aDate = a.endDatetime.get();
    }

    if (b.startDatetime.get() !== undefined && b.startDatetime.get() !== null) {
        bDate = b.startDatetime.get();
    } else {
        bDate = b.endDatetime.get();
    }

    return aDate.localeCompare(bDate) * reverse;
};

export default class EventTiny extends JTOObject {
    public static _TYPE: string = 'Event';

    public static _instance: EventTiny | null = null;

    public name: JTOProperty<string>;

    public eventType: JTOSimple<EventType>;
    public hasVote: JTOSimple<Invite>;

    public startDatetime: JTOProperty<string>;
    public endDatetime: JTOProperty<string>;
    public eventTypeAlias: JTOProperty<string>;

    public isFavorite: JTOProperty<boolean>;
    public dateFavorite: JTOProperty<string>;

    public isInterest: JTOProperty<boolean>;

    private _paidEvent: JTOProperty<boolean>;
    public form: JTOSimple<FormRegistration>;
    public hasRegister: JTOSimple<Invite>;

    public registrable: JTOProperty<boolean>;

    public toEvent(): Event {
        const user = new Event();
        const params = this.toJson();
        user.applyData(params);
        return user;
    }

    constructor(parent?: JTOElement | null) {
        super(EventTiny._TYPE, parent);

        this.eventTypeAlias = new JTOProperty<string>('eventtype_alias', this);

        this.startDatetime = new JTOProperty<string>('start_datetime', this);
        this.endDatetime = new JTOProperty<string>('end_datetime', this);

        this.isInterest = new JTOProperty<boolean>('is_interest', this);

        this._paidEvent = new JTOProperty<boolean>('paid_event', this);

        this.registrable = new JTOProperty<boolean>('registrable', this);
        this.hasRegister = new JTOSimple<Invite>(Invite, 'has_register', this);
        this.form = new JTOSimple<FormRegistration>(
            FormRegistration,
            'has_form_registration',
            this,
        );

        this.name = new JTOProperty<string>('name', this);

        this.hasVote = new JTOSimple<Invite>(Invite, 'has_vote', this);
        this.eventType = new JTOSimple<EventType>(
            EventType,
            'has_event_type',
            this,
        );

        this.isFavorite = new JTOProperty<boolean>('is_favorite', this);

        this.dateFavorite = new JTOProperty<string>('favorite_date', this);
    }

    public get paidEvent(): boolean {
        return this._paidEvent.get() ?? false;
    }

    public set paidEvent(value: boolean) {
        this._paidEvent.set(value);
    }

    public isRootEvent(): boolean {
        return false;
    }

    public getStartDate(): Date | null {
        try {
            let res: Date | null = null;
            res = new Date(this.startDatetime.get()!);
            if (isNaN(res.getTime())) {
                res = new Date(this.endDatetime.get() ?? '');
            }
            return res;
        } catch (e) {
            return new Date();
        }
    }

    public getEndDate(): Date | null {
        try {
            let res: Date | null = null;
            res = new Date(this.endDatetime.get()!);
            if (isNaN(res.getTime())) {
                res = new Date(this.startDatetime.get() ?? '');
            }
            return res;
        } catch (e) {
            return new Date();
        }
    }

    isNow(): boolean {
        const startTime = new Date(
            this.getStartDate() ?? this.getBetterDate() ?? '',
        );
        const endTime = new Date(
            this.getEndDate() ?? this.getBetterDate() ?? '',
        );
        const isNow =
            new Date(startTime).getTime() < new Date().getTime() &&
            new Date(endTime).getTime() > new Date().getTime();
        return isNow;
    }

    isAfterNow(): boolean {
        const startTime = new Date(
            this.getStartDate() ?? this.getBetterDate() ?? '',
        );
        return startTime.getTime() > new Date().getTime();
    }

    isNotSondage(): boolean {
        return (this.eventType.get()?.name.get() ?? '') !== 'Sondage';
    }

    isNotQuiz(): boolean {
        return (this.eventType.get()?.name.get() ?? '') !== 'Quiz';
    }

    isNotNuage(): boolean {
        return (this.eventType.get()?.name.get() ?? '') !== 'Nuage';
    }

    isBeforeNow(): boolean {
        const endTime = new Date(
            this.getEndDate() ?? this.getBetterDate() ?? '',
        );
        return endTime.getTime() < new Date().getTime();
    }

    getDiff(): number {
        const startTime = new Date(
            this.getStartDate() ?? this.getBetterDate() ?? '',
        );
        return startTime.getTime() - new Date().getTime();
    }

    getBetterDate() {
        if (
            this.startDatetime.get() !== null &&
            this.startDatetime.get() !== undefined
        ) {
            return this.startDatetime.get();
        } else {
            return this.endDatetime.get();
        }
    }

    displayDuration(lang: string): string {
        let res: string = '';

        const start = new Date(this.getStartDate() ?? '');
        const end = new Date(this.getEndDate() ?? '');

        if (
            end.getDate() === start.getDate() &&
            end.getMonth() === start.getMonth() &&
            end.getFullYear() === start.getFullYear()
        ) {
            res =
                start.toLocaleTimeString(lang, {
                    hour: 'numeric',
                    minute: 'numeric',
                }) +
                ' - ' +
                end.toLocaleTimeString(lang, {
                    hour: 'numeric',
                    minute: 'numeric',
                });
        }
        // Check if end is not valid and start
        else if (!isNaN(end.getTime()) && !isNaN(start.getTime())) {
            res =
                start.toLocaleString(lang, {
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                }) +
                ' - ' +
                end.toLocaleString(lang, {
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                });
        }
        // Check if end
        else if (!isNaN(end.getTime())) {
            res = end.toLocaleString(lang, {
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            });
        }
        // Check if end
        else if (!isNaN(start.getTime())) {
            res = start.toLocaleString(lang, {
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            });
        }

        return res;
    }

    public displayDate(key: string) {
        let str = '';

        const eventStart = new Date(
            this.getStartDate() ?? this.getBetterDate() ?? '',
        );

        const eventEnd = new Date(
            this.getEndDate() ?? this.getBetterDate() ?? '',
        );

        if (
            eventStart.getDate() === eventEnd.getDate() &&
            eventStart.getFullYear() === eventEnd.getFullYear() &&
            eventStart.getMonth() === eventEnd.getMonth()
        ) {
            str = eventStart.toLocaleString(key, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
        } else {
            if (eventStart.getMonth() === eventEnd.getMonth()) {
                str =
                    eventStart.toLocaleString(key, {
                        day: 'numeric',
                    }) +
                    ' - ' +
                    eventEnd.toLocaleString(key, {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    });
            } else if (eventStart.getFullYear() === eventEnd.getFullYear()) {
                str =
                    eventStart.toLocaleString(key, {
                        day: 'numeric',
                        month: 'long',
                    }) +
                    ' - ' +
                    eventEnd.toLocaleString(key, {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    });
            } else {
                str =
                    eventStart.toLocaleString(key, {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    }) +
                    ' - ' +
                    eventEnd.toLocaleString(key, {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    });
            }
        }
        return str;
    }

    canBeSurvey(): boolean {
        return this.isNotNuage() && this.isNotSondage() && this.isNotQuiz();
    }

    isFavoriteNow() {
        return this.isFavorite.get() === true;
    }
    isBeforeNow1Day() {
        const endTime = new Date(
            this.getEndDate() ?? this.getBetterDate() ?? '',
        );
        return (
            endTime.getTime() < new Date().getTime() &&
            endTime.getTime() > new Date().getTime() - 86400000 * 1.5
        );
    }
}
