import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Text, TouchableOpacity, View } from 'react-native';
import MpFileInput from '../MpFileInput';
import { getColor } from '../../utils/theme';

interface IImageListModalProps {
    onClose: (any) => void;
    onImageSelect?: any;
    open: boolean;
    single?: boolean;
}

export const PostFileModal = (props: IImageListModalProps) => {
    const { t } = useTranslation();
    const { onClose, open } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    let classNameBlock = '';
    if (loading) {
        classNameBlock = 'hidden';
    } else {
        classNameBlock = 'flex';
    }

    const [fileList, setFileList] = useState<File[] | null>(null);

    const [addImage, setAddImage] = useState<boolean>(false);

    const [imageSelected, setImage] = useState<any | null>(null);

    const [search, setSearch] = useState<string>('');

    const [openDelete, setOpenDelete] = useState<boolean>(false);

    return (
        <Modal
            animationType='slide'
            transparent={true}
            visible={open}
            onRequestClose={() => {
                onClose([]);
            }}
        >
            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
            >
                <View
                    style={{
                        width: '80%',
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 16,
                        shadowColor: '#000',
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.25,
                        shadowRadius: 4,
                        elevation: 5,
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderBottomColor: '#e5e7eb',
                            paddingBottom: 10,
                            marginBottom: 10,
                        }}
                    >
                        <Text style={{ fontSize: 18, fontWeight: '600' }}>
                            {t('post_file')}
                        </Text>
                        <TouchableOpacity
                            onPress={onClose}
                            style={{
                                backgroundColor: '#e5e7eb',
                                borderRadius: 8,
                                padding: 5,
                            }}
                        >
                            <Text style={{ fontSize: 16 }}>X</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginBottom: 10 }}>
                        <MpFileInput
                            value={fileList}
                            onChange={(newFile) => {
                                setFileList(newFile);
                            }}
                            t={t}
                            single={props.single}
                        />
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <TouchableOpacity
                            onPress={() => onClose(fileList)}
                            style={{
                                backgroundColor: getColor('primary'),
                                borderRadius: 8,
                                padding: 10,
                                marginRight: 10,
                            }}
                        >
                            <Text style={{ color: '#fff', fontSize: 16 }}>
                                {t('confirm')}
                            </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={onClose}
                            style={{
                                backgroundColor: '#e5e7eb',
                                borderRadius: 8,
                                padding: 10,
                            }}
                        >
                            <Text style={{ color: '#111827', fontSize: 16 }}>
                                {t('cancel')}
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    );
};
