// tslint:disable no-empty

import { get } from '../utils/http';
import { HTTPUtils } from 'mp-ts-http';

import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';
import User from './User';
import { getRestore } from '../utils/http';
import {
    applyResultBlockedNoOrder,
    applyResultNoOrder,
} from '../utils/applyResult';
import JTOInstance from '../utils/jto/JTOInstance';
import JTOUtils from '../utils/jto/JTOUtils';

export default class JTOListFetch<
    Type extends JTOObject,
> extends JTOList<Type> {
    private _url: string = '';
    private _first: boolean = true;
    private _start: boolean = false;
    private _loading: boolean = false;
    private _complite: boolean = false;
    private _tempComplite: boolean = false;
    private _restore:boolean = true;

    constructor(
        url: string,
        typeList?:
            | (new (...args: any) => Type)
            | (new (...args: any) => Type)[],
        field: string[] | string | null = null,
        JTOParent: JTOElement | null = null,
        sortFunc: ((a: Type, b: Type) => number) | null = null,
    ) {
        super(typeList, field, JTOParent, sortFunc);
        this._url = url;
        this.block = true;
    }

    public isFirst(): boolean {
        return this._first;
    }

    public setFirst(first: boolean) {
        this._first = first;
    }

    public isLoading(): boolean {
        return this._loading;
    }

    public setLoading(loading: boolean) {
        this._loading = loading;
    }

    public isStart(): boolean {
        return this._start;
    }

    public setStart(start: boolean) {
        this._start = start;
    }

    public isRestore(): boolean {
        return this._restore;
    }

    public setRestore(restore: boolean) {
        this._restore = restore;
    }

    public isComplite(): boolean {
        return this._complite;
    }

    public setComplite(complite: boolean) {
        this._complite = complite;
    }

    notifyView(): void {
        super.notifyView();
    }

    notifyRootView(): void {
        super.notifyRootView();
    }

    public applyData(data: { [key: string]: any }): boolean {
        let res = false;

        const allObject: JTOObject[] = this.getJTOObjectList(true);
        const allJson: { [key: string]: any }[] =
            JTOUtils.getAllJsonObject(data);

        for (const json of allJson) {
            for (const child of allObject) {
                if (child.equals(json)) {
                    res = child.applyDataPartiel(json, true) || res;
                }
            }
        }
        res = this.applyDataPartiel(data, false) || res;
        return res;
    }

    public reset() {
        super.reset();
        this._complite = false;
        this._start = false;
        this._loading = false;
        this._first = true;
        this.makeUpdate();
    }

    public refetch(
        params: { [key: string]: any } = {},
        success: (result: any) => void = () => {},
        error: (err: any) => void = () => {},
    ) {
        this.setComplite(false);
        this.setStart(true);
        this.setLoading(true);
        this.setFirst(true);

        this.makeUpdate();

        getRestore(
            this._url,
            {
                ...params,
                skip: this.getList().length,
                limit: 20,
                first: this.isFirst() + '',
            },
            (result) => {
                this.setFirst(false);
                this.setStart(false);
                this.setLoading(false);

                if (HTTPUtils.isSuccess(result)) {
                    const data = HTTPUtils.getResult(result);

                    const lengthBefore = this.getList().length;

                    if (this.isComplite()) {
                        this._block = false;
                        applyResultNoOrder(data, this, true);
                    } else {
                        applyResultBlockedNoOrder(data, this, true);
                    }

                    const lengthAfter = this.getList().length;

                    this.setComplite(
                        lengthBefore === lengthAfter ||
                            lengthAfter - lengthBefore < 20,
                    );
                    success(result);
                } else {
                    error(result);
                    this.setComplite(true);
                }

                this.makeUpdate();
            },
            (err) => {
                error(err);
                this.setFirst(false);
                this.setStart(false);
                this.setLoading(false);
                this.setComplite(true);

                this.makeUpdate();
            },
            (result) => {
                this.reset();
            },
            this.isRestore()
        );
    }

    public fetch(
        params: { [key: string]: any } = {},
        success: (result: any) => void = () => {},
        error: (err: any) => void = () => {},
    ) {
        if (
            this.isFirst() &&
            !this.isStart() &&
            !this.isLoading() &&
            !this.isComplite()
        ) {
            this.refetch(params, success, error);
        }
    }

    public preventBlock() {
        this._tempComplite = this.isComplite();
        this._block = false;
        this.setComplite(true);
    }

    public remainBlock() {
        this.setComplite(this._tempComplite);
        this._block = !this._tempComplite;
    }

    public async more(
        params: { [key: string]: any } = {},
        success: (result: any) => void = () => {},
        error: (err: any) => void = () => {},
    ) {
        if (
            !this.isFirst() &&
            !this.isStart() &&
            !this.isLoading() &&
            !this.isComplite()
        ) {
            this.setFirst(false);
            this.setStart(true);
            this.setLoading(true);

            this.makeUpdate();

            get(this._url, {
                ...params,
                skip: this.getList().length,
                limit: 20,
                first: this.isFirst() + '',
            })
                .then((result) => {
                    this.setFirst(false);
                    this.setStart(false);
                    this.setLoading(false);

                    if (HTTPUtils.isSuccess(result)) {
                        const data = HTTPUtils.getResult(result);

                        const lengthBefore = this.getList().length;

                        if (this.isComplite()) {
                            this._block = false;
                            applyResultNoOrder(data, this, true);
                        } else {
                            applyResultBlockedNoOrder(data, this, true);
                        }

                        const lengthAfter = this.getList().length;

                        this.setComplite(
                            lengthBefore === lengthAfter ||
                                lengthAfter - lengthBefore < 15,
                        );
                        success(result);
                    } else {
                        this.setComplite(true);
                        error(result);
                    }

                    this.makeUpdate();
                })
                .catch((err) => {
                    this.setFirst(false);
                    this.setStart(false);
                    this.setLoading(false);
                    this.setComplite(true);
                    this.makeUpdate();
                    error(err);
                });
        }
    }
}
