// tslint:disable-no-console

import { useCallback, useEffect, useRef, useState } from 'react';
import JTOContainer from './JTOContainer';
import JTOElement from './JTOElement';
import JTOInstance from './JTOInstance';
import { nanoid } from 'nanoid/non-secure';

// useJTOState hook
function useJTOState<T extends JTOElement>(
    elem: T,
    clear?: (() => void) | undefined,
): T {
    const elemSave = useRef<T>(elem);
    const [time, setTime] = useState(new Date().getTime());
    const id = useRef(nanoid());

    const func = useCallback(() => {
        setTime(new Date().getTime());
    }, []);

    useEffect(() => {
        const container = new JTOContainer(
            id.current ?? '',
            elemSave.current,
            func,
        );

        JTOInstance.addContainer(container);
        elemSave.current?.addContainer(container);
        return () => {
            elemSave.current?.removeContainerObj(container);
            JTOInstance.removeContainerObj(container);
            clear?.();
        };
    }, []);

    return elemSave.current;
}

export default useJTOState;
