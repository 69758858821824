import { post } from '../utils/http';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActivityIndicator,
    Pressable,
    Text,
    TextInput,
    View,
} from 'react-native';
import React from 'react';
import { MpInput } from '../base/MpInput';
import User from '../object/User';
import { styles } from '../utils/styles';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { APP_NAME, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { HTTPUtils } from 'mp-ts-http';
import { getErrorMessage } from 'mp-ts-error';
import { EyeIcon, EyeSlashIcon } from 'react-native-heroicons/solid';
import { CartoucheButton } from '../component/CartoucheButton';
import { HeaderSimplePage } from '../component/HeaderSimplePage';
//import { ScrollView } from 'react-native-gesture-handler';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { GeSpinner } from '../component/GeSpinner';
import { ContentCartouche } from '../component/ContentCartouche';
import { Header } from '../component/Header';
import Logo from '../svg/Logo';
import { BEFORE_LOGO } from '../utils/config';

interface IConnectionPageProps {
    navigation: any;
}
export const ForgetPage = (props: IConnectionPageProps) => {
    const { t } = useTranslation();
    User.translation = t;

    const scrollRef = useRef<any>(null);

    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const onCode = () => {
        setLoading(true);
        setMessage('');
        post(
            '/user/backoffice/forget',
            {
                identifier: email,
            },
            true,
        )
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    User._email = email;
                    props.navigation.navigate('Code');
                    setEmail('');
                    setMessage('');
                    setLoading(false);
                } else {
                    setMessage(t(HTTPUtils.getError(res)) ?? '');
                }
            })
            .catch((err) => {
                setLoading(false);

                setMessage(t('went_wrong_no_internet') ?? '');
            });
    };

    const scrollToBottom = () => {
        /*scrollRef.current.scrollToEnd({ animated: true });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 50);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 100);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 150);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 200);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 250);*/
    };

    return (
        <View style={[styles.page]}>
            <Header
                title={APP_NAME}
                beforeLogo={BEFORE_LOGO}
                fallback={
                    <Logo
                        height={50}
                        width={50}
                    />
                }
                fallbackColor={'white'}
                backButton={true}
                navigation={props.navigation}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                full
                logo={''}
            >
                <View
                    ref={scrollRef}
                    // @ts-ignore
                    automaticallyAdjustKeyboardInsets={true}
                >
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: width() * 0.15,
                            marginLeft: width() * 0.15,
                            width: width() * 0.7,
                            paddingBottom: NAVIGATION_BAR_HEIGHT,
                        }}
                    >
                        <HeaderSimplePage
                            title={t('forget_title')}
                            description={t('forget_description')}
                        />
                        {loading ? (
                            <View style={[styles.boxCenter, marginTop(75)]}>
                                <GeSpinner />
                            </View>
                        ) : (
                            <View
                                style={[
                                    styles.box,
                                    {
                                        width: width(),
                                    },
                                ]}
                            >
                                <View
                                    style={{
                                        paddingHorizontal: width() * 0.1,
                                        paddingVertical: width() * 0.1,
                                    }}
                                >
                                    <MpInput
                                        autoCapitalize='none'
                                        value={email}
                                        onChangeText={(text) => {
                                            setEmail(text);
                                        }}
                                        defaultValue={email}
                                        keyboardType={'email-address'}
                                        textContentType='emailAddress'
                                        placeholder={t('email') as string}
                                        style={styles.input}
                                        onFocus={() => {
                                            // Scroll to bottom
                                            scrollToBottom();
                                        }}
                                        selectionColor={getColor('primary')}
                                    />
                                    {message !== '' && (
                                        <Text
                                            style={[
                                                styles.textError,
                                                marginTop(25),
                                            ]}
                                        >
                                            {message}
                                        </Text>
                                    )}
                                </View>

                                <CartoucheButton
                                    onPress={() => {
                                        onCode();
                                    }}
                                    title={t('get_code')}
                                    height={80}
                                />

                                <Pressable
                                    style={{
                                        marginTop: 25,
                                    }}
                                    onPress={() => {
                                        props.navigation.goBack();
                                        setEmail('');
                                        setMessage('');
                                        setLoading(false);
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                textAlign: 'center',
                                                color: getColor('primary'),
                                            },
                                        ]}
                                    >
                                        {t('back')}
                                    </Text>
                                </Pressable>
                            </View>
                        )}
                    </View>
                </View>
            </Header>
        </View>
    );
};
