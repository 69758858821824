import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
//import { ScrollView } from 'react-native-gesture-handler';
import { NewspaperIcon } from 'react-native-heroicons/solid';
import GenericAchivement from '../component/achivement/GenericAchivement';
import { Header } from '../component/Header';
import { LevelFocusView } from '../component/LevelFocusView';
import { Badge } from '../object/Badge';
import JTOListFetch from '../object/JTOListFetch';
import User from '../object/User';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { styles } from '../utils/styles';
import { FONT_BOLD, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import useJTOState from '../utils/jto/useJTOState';

interface IBadgePageProps {
    navigation: any;
}
export const getMarginLeft = (index: number) => {
    if (index % 3 === 2) {
        return 20;
    }
    return 0;
};
export const getMarginRight = (index: number) => {
    if (index % 3 === 0) {
        return 20;
    }
};

export const BRONZE_COLOR = '#C38144';
export const SILVER_COLOR = '#81A5B0';
export const GOLD_COLOR = '#E89B05';

export const BRONZE_COLOR_LIGHT = '#FFD5AE';
export const SILVER_COLOR_LIGHT = '#D9EAEF';
export const GOLD_COLOR_LIGHT = '#FFEF9C';

export const DICT_COLOR = {
    '1': BRONZE_COLOR,
    '2': SILVER_COLOR,
    '3': GOLD_COLOR,
};

export const BadgePage = (props: IBadgePageProps) => {
    const { t } = useTranslation();

    const badgeList = useJTOState(User._badgeList);

    const BADGE_SHOW = [];
    for (let i = 0; i < badgeList.size(); i += 3) {
        BADGE_SHOW.push([
            badgeList.get(i),
            badgeList.get(i + 1),
            badgeList.get(i + 2),
        ]);
    }

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('badge')}
                line={1}
                full
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: -40,
                        flex: 1,
                    }}
                >
                    <View
                        style={{
                            height: 60,
                            width: width(),
                        }}
                    ></View>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginHorizontal: 20,
                            flexWrap: 'wrap',
                        }}
                    >
                        {BADGE_SHOW.map((badgeListRes, index) => {
                            let badgeMax: null | Badge = null;
                            for (const badge of badgeListRes) {
                                if (!badge.hasBadge(User._instance)) {
                                    badgeMax = badge;
                                    break;
                                }
                            }
                            let badgeBef: null | Badge = null;
                            for (const badge of badgeListRes) {
                                if (badge.hasBadge(User._instance)) {
                                    badgeBef = badge;
                                } else {
                                    break;
                                }
                            }
                            return (
                                <View>
                                    <Text
                                        style={{
                                            marginTop: 10,
                                            fontFamily: FONT_BOLD,
                                            fontSize: 20,
                                        }}
                                    >
                                        {t(
                                            badgeBef?.getTitle() ??
                                                badgeMax?.getTitle() ??
                                                '',
                                        )}
                                    </Text>
                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {badgeListRes.map((badgeI, i) => {
                                            return (
                                                <Pressable
                                                    onPress={() => {
                                                        props.navigation.navigate(
                                                            'BigBadgePage',
                                                            {
                                                                badge: badgeI,
                                                            },
                                                        );
                                                    }}
                                                    style={{
                                                        height:
                                                            (width() - 81) / 3,
                                                        width:
                                                            (width() - 81) / 3,
                                                        marginLeft:
                                                            getMarginLeft(i),
                                                        marginRight:
                                                            getMarginRight(i),
                                                    }}
                                                >
                                                    <GenericAchivement
                                                        owned={badgeI.hasBadge(
                                                            User._instance,
                                                        )}
                                                        height={
                                                            (width() - 81) / 3
                                                        }
                                                        width={
                                                            (width() - 81) / 3
                                                        }
                                                        level={
                                                            badgeI.level.get() ??
                                                            0
                                                        }
                                                        count={badgeI.getBestCount()}
                                                        icon={badgeI.getBadgeIcon()}
                                                    />
                                                </Pressable>
                                            );
                                        })}
                                    </View>
                                    <View
                                        style={{
                                            height: 5,
                                            width: width() - 40,
                                            position: 'relative',
                                            borderRadius: 20,
                                            backgroundColor: getColor('card'),
                                        }}
                                    >
                                        {
                                            <View
                                                style={{
                                                    height: 5,
                                                    left: 0,
                                                    top: 0,
                                                    borderRadius: 20,
                                                    backgroundColor:
                                                        badgeBef?.getColor() ??
                                                        getColor('border'),
                                                    width: badgeMax
                                                        ? ((width() - 40) *
                                                              (badgeMax.getUserCount(
                                                                  User._instance,
                                                              ) -
                                                                  (badgeBef?.getBestCount() ??
                                                                      0))) /
                                                          (badgeMax.getBestCount() ??
                                                              1)
                                                        : width() - 40,
                                                }}
                                            ></View>
                                        }
                                        {
                                            <View
                                                style={{
                                                    height: 10,
                                                    position: 'absolute',
                                                    width: 10,
                                                    borderRadius: 10,
                                                    left: 0,
                                                    top: -2.5,
                                                    backgroundColor:
                                                        badgeBef?.getColor() ??
                                                        getColor('border'),
                                                }}
                                            ></View>
                                        }
                                        {badgeMax && (
                                            <View
                                                style={{
                                                    height: 10,
                                                    width: 10,
                                                    position: 'absolute',
                                                    borderRadius: 10,
                                                    right: 0,
                                                    top: -2.5,
                                                    backgroundColor:
                                                        badgeMax.getColor(),
                                                }}
                                            ></View>
                                        )}
                                        {!badgeMax && (
                                            <View
                                                style={{
                                                    height: 10,
                                                    width: 10,
                                                    position: 'absolute',
                                                    borderRadius: 10,
                                                    right: 0,
                                                    top: -2.5,
                                                    backgroundColor: GOLD_COLOR,
                                                }}
                                            ></View>
                                        )}
                                    </View>
                                    <View
                                        style={{
                                            display: 'flex',
                                            position: 'relative',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: 20,
                                                textAlign: 'center',
                                                fontFamily: FONT_BOLD,
                                                color:
                                                    badgeBef?.getColor() ??
                                                    getColor('border'),
                                            }}
                                        >
                                            {badgeBef?.getBestCount() ?? 0}
                                        </Text>
                                        {badgeMax && (
                                            <Text
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    fontSize: 20,
                                                    textAlign: 'right',
                                                    fontFamily: FONT_BOLD,
                                                    borderRadius: 20,
                                                    color:
                                                        badgeBef?.getColor() ??
                                                        getColor('border'),
                                                    width:
                                                        ((width() - 40) *
                                                            (badgeMax.getUserCount(
                                                                User._instance,
                                                            ) -
                                                                (badgeBef?.getBestCount() ??
                                                                    0))) /
                                                        (badgeMax.getBestCount() ??
                                                            1),
                                                }}
                                            >
                                                {badgeMax?.getUserCount(
                                                    User._instance,
                                                ) ??
                                                    badgeBef?.getUserCount(
                                                        User._instance,
                                                    ) ??
                                                    ''}
                                            </Text>
                                        )}
                                        <Text
                                            style={{
                                                fontSize: 20,
                                                textAlign: 'center',
                                                fontFamily: FONT_BOLD,
                                                color:
                                                    badgeMax?.getColor() ??
                                                    GOLD_COLOR,
                                            }}
                                        >
                                            {badgeMax?.getBestCount() ??
                                                badgeBef?.getUserCount(
                                                    User._instance,
                                                ) ??
                                                ''}
                                        </Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                </View>
            </Header>
        </View>
    );
};
