import { useTranslation } from 'react-i18next';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
import ChatGroup from '../object/ChatGroup';
import History from '../object/History';
import Post from '../object/Post';
import User from '../object/User';
import { styles } from '../utils/styles';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import Event from '../object/Event';
import { PastilleView } from './PastilleView';
import { width } from '../utils/utils';
import NewHistory from '../object/NewHistory';

interface INewHistoryViewProps {
    newHistory: NewHistory;
    index: number;
    navigation: any;
}

export const NewHistoryView = (props: INewHistoryViewProps) => {
    const { t } = useTranslation();
    const { newHistory, index } = props;

    return (
        <Pressable
            onPress={() => {
                const type = newHistory.type.get();
                const action = newHistory.action.get();
                if (type === 'Event') {
                    if (action === 'Open') {
                        const eventJson = JSON.parse(
                            newHistory.event.get() ?? '',
                        );
                        const eventType = new Event();
                        eventType.applyData(eventJson);
                        props.navigation.push('SubEventModal', {
                            event: eventType,
                        });
                    }
                } else if (type === 'Post') {
                    if (action === 'Open') {
                        props.navigation.navigate('mur');
                    }
                }
            }}
            style={{
                marginHorizontal: 20,
                borderRadius: 20,
                marginBottom: 20,
                backgroundColor: getColor('background'),
                shadowColor: getColor('hint'),
                shadowOffset: {
                    width: 0,
                    height: 2,
                },
                shadowOpacity: 0.2,
                shadowRadius: 3.84,
                elevation: 5,
                padding: 10,
                paddingEnd: 5,
                flexDirection: 'row',
            }}
        >
            <PastilleView
                icon={newHistory.getViewIcon()}
                size={35}
                color={getColor('secondary')}
            />

            <View
                style={{
                    flex: 1,
                    marginHorizontal: 10,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Text
                    style={{
                        fontFamily: FONT_BOLD,
                        color: 'black',
                        fontSize: 16,
                        marginBottom: 8,
                    }}
                >
                    {newHistory.title.get()}
                </Text>
                <Text
                    style={{
                        fontFamily: FONT_REGULAR,
                        color: getColor('hint'),
                        fontSize: 14,
                    }}
                >
                    {newHistory.content.get()}
                </Text>
            </View>

            <Text
                style={[
                    styles.text,
                    { color: getColor('hint'), paddingRight: 5 },
                ]}
            >
                {newHistory.getDisplayDate(t)}
            </Text>
        </Pressable>
    );
};
