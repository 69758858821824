import JTOObject from './JTOObject';
import JTOElement from './JTOElement';
import JTOUtils from './JTOUtils';

export default abstract class JTOField extends JTOElement {
    /**
     * Variable field contains all the field in the json source
     * where the value of this JTOList is stored
     * @type {string[]}
     */
    protected fieldList: string[];

    constructor(
        field: string[] | string | null = null,
        JTOParent: JTOElement | null = null,
    ) {
        super(JTOParent);
        if (field === null) {
            this.fieldList = [];
        } else if (typeof field === 'string') {
            this.fieldList = [field];
        } else {
            this.fieldList = field;
        }
    }

    /**
     * Get all JTOObject in the object
     * @returns {JTOObject[]}
     */
    public getJTOObjectList(recursively: boolean): JTOObject[] {
        const listJTOObject: JTOObject[] = [];
        const listJTOElement: JTOElement[] =
            this.getJTOElementList(recursively);
        for (const jtoElement of listJTOElement) {
            if (jtoElement instanceof JTOObject) {
                listJTOObject.push(jtoElement as JTOObject);
            }
        }
        return listJTOObject;
    }

    public applyDataToAllChild(data: { [key: string]: any }): boolean {
        let res = false;

        const allObject: JTOObject[] = this.getJTOObjectList(true);
        const allJson: { [key: string]: any }[] =
            JTOUtils.getAllJsonObject(data);

        for (const json of allJson) {
            for (const child of allObject) {
                if (child.equals(json)) {
                    res = child.applyDataPartiel(json, true) || res;
                }
            }
        }
        return res;
    }

    public getField(): string {
        return this.fieldList[0];
    }

    public override getJTOIdentifier(): any {
        let res: any = false;
        if (this.getJTOParent() instanceof JTOElement) {
            res = (this.getJTOParent() as JTOElement).getJTOIdentifier();
        }
        return res;
    }

    public override getJTOType(): any {
        let res: any = false;
        if (this.getJTOParent() instanceof JTOElement) {
            res = (this.getJTOParent() as JTOElement).getJTOType();
        }
        return res;
    }

    public override applyData(data: { [key: string]: any }): boolean {
        let res = false;

        const allObject: JTOObject[] = this.getJTOObjectList(true);
        const allJson: { [key: string]: any }[] =
            JTOUtils.getAllJsonObject(data);

        for (const json of allJson) {
            for (const child of allObject) {
                if (child.equals(json)) {
                    res = child.applyDataPartiel(json, true) || res;
                }
            }
        }
        res = this.applyDataPartiel(data, false) || res;
        return res;
    }
}
