import JTOElement from './JTOElement';
import JTOField from './JTOField';
import JTOInstance from './JTOInstance';
import JTOObject from './JTOObject';

export default class JTOList<Type extends JTOObject> extends JTOField {
    /**
     * Variable list contains all JTOObject of this JTOList
     * @type {Type[]}
     */
    private list: Type[];

    /**
     * Variable sortFunc contains the function to sort the list on each update
     * @type {( (a: Type, b: Type) => number ) | null}
     */
    private sortFunc: ((a: Type, b: Type) => number) | null;

    /**
     * Function used to apply to check the type
     */
    private typeList: (new (...args: any) => Type)[];

    /**
     * Constructor of JTOList
     * @param fieldList list of field where the element of this JTOList is stored
     * @param JTOParent the parent of this object, null if this object is the root
     * @param sortFunc optionnal parameter, the function to sort the list on each update
     */
    constructor(
        typeList?:
            | (new (...args: any) => Type)
            | (new (...args: any) => Type)[],
        field: string[] | string | null = null,
        JTOParent: JTOElement | null = null,
        sortFunc: ((a: Type, b: Type) => number) | null = null,
    ) {
        super(field, JTOParent);

        if (typeList === undefined) {
            this.typeList = [];
        } else if (!Array.isArray(typeList)) {
            this.typeList = [typeList];
        } else {
            this.typeList = typeList;
        }

        this.sortFunc = sortFunc;
        this.list = [];
    }

    /**
     * Boolean used to block the prevent add to the JTOList
     */
    public _block: boolean = false;

    public get block(): boolean {
        return this._block;
    }

    public set block(value: boolean) {
        this._block = value;
    }

    public applyBlockedData(data: { [key: string]: any }) {
        this.block = false;

        let res = false;

        /**
         * JTO parent of this JTOList
         */
        const jtoParent = this.getJTOParent();
        if (jtoParent !== null) {
            res = jtoParent.applyData(data);
        }
        res = this.applyData(data) || res;

        this.block = true;

        return res;
    }

    public override applyData(data: { [key: string]: any }): boolean {
        let res: boolean = false;
        if (this.getJTOParent() !== null) {
            res = this.getJTOParent()?.applyData(data) ?? false;
        } else {
            res = super.applyData(data);
        }
        return res;
    }

    /**
     * Function used to sort the JTOList
     */
    public sort() {
        if (this.sortFunc != null) {
            this.list.sort(this.sortFunc);
        }
    }

    /**
     * Function used to add an Type to the list
     * @param obj the object to add
     */
    public push(obj: Type) {
        this.list.push(obj);
        this.sort();
    }

    /**
     * Function used to remove an Type from the list
     * @param i the index of the object to remove
     */
    public remove(i: number) {
        this.list.splice(i, 1);
        this.sort();
    }

    public reset() {
        this.list = [];
    }

    /**
     * Function used to get element of the list by index
     * @param i the index
     */
    public get(i: number): Type {
        return this.list[i];
    }

    /**
     * Function used to get element of the list by index
     * @param i the index
     */
    public getList(): Type[] {
        return this.list;
    }

    /**
     * Function used to get element of the list by index
     * @param i the index
     */
    public setList(list: Type[]) {
        this.list = list;
    }

    public find(
        predicate: (
            this: void,
            value: Type,
            index: number,
            obj: Type[],
        ) => value is Type,
        thisArg?: any,
    ): Type | undefined {
        return this.list.find(predicate, thisArg);
    }

    public findIndex(
        predicate: (
            this: void,
            value: Type,
            index: number,
            obj: Type[],
        ) => value is Type,
        thisArg?: any,
    ): number {
        return this.list.findIndex(predicate, thisArg);
    }

    public map(
        callbackfn: (value: Type, index: number, array: Type[]) => any,
        thisArg?: any,
    ): any[] {
        return this.list.map(callbackfn, thisArg);
    }

    /**
     * Function used to get the length of the list
     */
    public size(): number {
        return this.list.length;
    }

    /**
     * Check if elem is a type entered
     */
    public isGoodType(elem: Type) {
        let res = this.typeList.length === 0;
        let i = 0;
        while (!res && i < this.typeList.length) {
            res = elem instanceof this.typeList[i];
            i += 1;
        }
        return res;
    }

    private applyArray(array: any): boolean {
        let res = false;
        if (array !== undefined) {
            if (!(array instanceof Array)) {
                array = [array];
            }
            // go throw the json array
            for (const elem of array) {
                if (elem !== undefined) {
                    //
                    let jtoElem: Type | null = null;

                    // boolean that indicate if the object exist in the list
                    let found: boolean = false;
                    let i: number = 0;
                    while (!found && i < this.list.length) {
                        const jtoElement: Type = this.list[i];

                        // check if the object correspond to the json
                        if (jtoElement.equals(elem)) {
                            // object found
                            jtoElem = jtoElement;
                            found = true;
                        } else {
                            // we go to the next object
                            i++;
                        }
                    }
                    // if the object doesn't exist
                    if (jtoElem == null) {
                        // check if the object its not a delete order
                        if (
                            !this.isDeleteOrder(elem, null) &&
                            !this.isDeleteRelationOrder(elem, null) &&
                            this.block !== true
                        ) {
                            // Convert the json to an Type
                            try {
                                jtoElem = JTOInstance.convert(
                                    elem,
                                    this,
                                    this.typeList,
                                ) as Type;
                                if (
                                    jtoElem != null &&
                                    jtoElem !== undefined &&
                                    this.isGoodType(jtoElem)
                                ) {
                                    this.push(jtoElem);
                                }
                                res = true;
                            } catch (e) {
                                if (jtoElem == null) {
                                    throw e;
                                } else {
                                    throw e;
                                }
                            }
                        }
                    } else {
                        // element exists
                        if (
                            this.isDeleteOrder(elem, jtoElem) ||
                            this.isDeleteRelationOrder(elem, null)
                        ) {
                            // delete the element
                            res = true;
                            this.remove(i);
                        } else {
                            // update the element
                            res = jtoElem.applyDataPartiel(elem, false) || res;
                        }
                    }
                }
            }
        }
        this.sort();
        return res;
    }
    public override applyDataPartiel(
        data: { [key: string]: any },
        first: boolean,
    ): boolean {
        // boolean that indicates if the object has changed
        let res = false;

        // go throw json source only if the data was applyed to the parent
        if (!this.hasParent()) {
            res = this.applyArray(data) || res;
        } else if (first) {
            // get the json array for all field
            for (const field of this.fieldList) {
                // current json document
                if (!(data instanceof Array)) {
                    const array = data[field];
                    res = this.applyArray(array) || res;
                } else {
                    res = this.applyArray(data) || res;
                }
            }
        }

        // Make the update
        if (res) {
            this.notifyView();
        }

        return res;
    }

    public override getJTOElementList(recursively: boolean): JTOElement[] {
        const list: JTOElement[] = [...this.list];
        if (recursively) {
            for (const elem of this.list) {
                const childList = elem.getJTOElementList(recursively);
                for (const child of childList) {
                    list.push(child);
                }
            }
        }
        return list;
    }
    public override toJson() {
        const list: any[] = [];
        for (const elem of this.list) {
            list.push(elem.toJson());
        }
        return list;
    }
}
