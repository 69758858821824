import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

import Answer from './Answer';

export default class DirectQuestion extends JTOObject {
    public static _TYPE: string = 'DirectQuestion';

    public title: JTOProperty<string>;
    public description: JTOProperty<string>;
    public isMultiple: JTOProperty<boolean>;
    public position: JTOProperty<number>;
    public descriptionAnswer: JTOProperty<string>;
    public timer: JTOProperty<number>;
    public answerList: JTOList<Answer>;
    public myAnswer: Answer | null | 'dodge' = null;

    constructor(
        parent?: JTOElement | null,
        type: string = DirectQuestion._TYPE,
    ) {
        super(type, parent);

        this.title = new JTOProperty<string>('title', this);
        this.description = new JTOProperty<string>('description', this);
        this.isMultiple = new JTOProperty<boolean>('is_multiple', this);
        this.descriptionAnswer = new JTOProperty<string>(
            'description_answer',
            this,
        );
        this.timer = new JTOProperty<number>('timer', this);
        this.position = new JTOProperty<number>('position', this);
        this.position.set(0);
        this.answerList = new JTOList<Answer>(Answer, 'has_answer', this);
    }
}
