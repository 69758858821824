import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Pressable, Text, View } from 'react-native';
import React from 'react';
import { UserGroupIcon, UserIcon } from 'react-native-heroicons/solid';
import ChatGroup from '../object/ChatGroup';
import ChatGroupTiny from '../object/compressed/chat/ChatGroupTiny';
import { MessageTiny } from '../object/compressed/chat/MessageTiny';
import UserTiny from '../object/compressed/global/UserTiny';
import { Message } from '../object/Message';
import User from '../object/User';
import { SERVER_URL } from '../utils/constants';
import { styles } from '../utils/styles';
import { FONT_BOLD, getColor } from '../utils/theme';
import { openAction, width } from '../utils/utils';
import { PastilleView } from './PastilleView';
import { Toast } from './toast/Toast';
import { ToastManager } from './toast/ToastManager';
import useJTOState from '../utils/jto/useJTOState';

interface IChatViewProps {
    item: MessageTiny | ChatGroupTiny;
    navigation: any;
}
export const ChatView = (props: IChatViewProps) => {
    const { item, navigation } = props;

    const _ = useJTOState(item);

    const { t } = useTranslation();

    let content;

    const openChatUpdate = () => {
        openAction();
    };

    if (item instanceof MessageTiny) {
        const receiver = item.getReceiver();

        let profil = '';
        if (receiver.getDisplayName() !== ' ') {
            profil = (receiver.avatar.get() ?? '') + '';
        } else {
            profil = (item.getSender().avatar.get() ?? '') + '';
        }
        content = (
            <Pressable
                key={item.getJTOIdentifier()}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: width(),
                    paddingTop: 8,
                    paddingBottom: 8,
                    borderBottomColor: getColor('border'),
                    borderBottomWidth: 1,
                    paddingHorizontal: 20,
                }}
                onPress={() => {
                    if (receiver.getDisplayName() !== ' ') {
                        User._instance.setCurrentChatId(
                            receiver.getJTOIdentifier(),
                        );
                    } else {
                        User._instance.setCurrentChatId(
                            item.getSender().getJTOIdentifier(),
                        );
                    }
                    User._instance.setCurrentChatType('User');
                    props.navigation.navigate('MessagePage', {
                        user:
                            receiver.getDisplayName() !== ' '
                                ? (receiver as UserTiny).toUser()
                                : item.getSender().toUser(),
                    });
                    openChatUpdate();
                }}
            >
                <View
                    style={{
                        position: 'relative',
                        marginRight: 12,
                        marginTop: 2,
                        height: 45,
                        width: 45,
                    }}
                >
                    {profil === '' ? (
                        <PastilleView
                            size={45}
                            color={getColor('secondary')}
                            icon={
                                <UserIcon
                                    size={32.5}
                                    color={'white'}
                                />
                            }
                        />
                    ) : (
                        <Image
                            style={{
                                height: 45,
                                width: 45,
                                borderRadius: 45,
                            }}
                            source={{
                                uri: SERVER_URL + '/image/' + profil,
                            }}
                        />
                    )}
                </View>
                <View
                    style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Text style={styles.h4}>
                            {receiver.getDisplayName() !== ' '
                                ? receiver.getDisplayName()
                                : item.getSender().getDisplayName()}
                        </Text>
                        {item.getHim().isNewMessage() &&
                        item.getHim().getNbNewMessage() !== 0 ? (
                            <View
                                style={{
                                    backgroundColor: getColor('primary'),
                                    borderRadius: 450,
                                    paddingHorizontal: 2,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: FONT_BOLD,
                                        color: 'white',
                                        paddingVertical: 2,
                                        paddingHorizontal: 10,
                                        fontSize: 16,
                                    }}
                                >
                                    {item.getHim().nbNewMessage.get()}
                                </Text>
                            </View>
                        ) : (
                            <View></View>
                        )}
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between',
                        }}
                    >
                        {item.getHim().isNewMessage() ? (
                            <Text
                                style={[
                                    styles.text,
                                    {
                                        color: getColor('primary'),
                                        fontFamily: FONT_BOLD,
                                        fontSize: 14,
                                    },
                                ]}
                            >
                                {item.getContent(t) ?? t('message_not_found')}
                            </Text>
                        ) : (
                            <Text
                                style={[
                                    styles.text,
                                    {
                                        color: getColor('hint'),
                                    },
                                ]}
                            >
                                {item.getContent(t) ?? t('message_not_found')}
                            </Text>
                        )}
                        <Text
                            style={[
                                styles.text,
                                {
                                    color: item.getHim().isNewMessage()
                                        ? getColor('primary')
                                        : getColor('hint'),
                                    fontSize: 12,
                                },
                            ]}
                        >
                            {item.displayMessageDate(t)}
                        </Text>
                    </View>
                </View>
            </Pressable>
        );
    } else {
        content = (
            <Pressable
                key={item.getJTOIdentifier()}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: width(),
                    paddingTop: 8,
                    paddingBottom: 8,
                    borderBottomColor: getColor('border'),
                    borderBottomWidth: 1,
                    paddingHorizontal: 20,
                }}
                onPress={() => {
                    User._instance.setCurrentChatId(item.getJTOIdentifier());
                    User._instance.setCurrentChatType('ChatGroup');
                    props.navigation.navigate('MessagePage', {
                        group: item.toChatGroup(),
                    });
                    openChatUpdate();
                }}
            >
                <View
                    style={{
                        position: 'relative',
                        marginRight: 12,
                        marginTop: 2,
                        height: 45,
                        width: 45,
                    }}
                >
                    {item.avatar.get() === undefined ? (
                        <PastilleView
                            size={45}
                            color={getColor('secondary')}
                            icon={
                                <UserGroupIcon
                                    size={32.5}
                                    color={'white'}
                                />
                            }
                        />
                    ) : (
                        <Image
                            style={{
                                height: 45,
                                width: 45,
                                borderRadius: 45,
                            }}
                            source={{
                                uri: SERVER_URL + '/image/' + item.avatar.get(),
                            }}
                        />
                    )}
                </View>
                <View
                    style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Text style={styles.h4}>{item.name.get()}</Text>
                        {item.isNewMessage() && item.getNbNewMessage() !== 0 ? (
                            <View
                                style={{
                                    backgroundColor: getColor('primary'),
                                    borderRadius: 450,
                                    paddingHorizontal: 2,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: FONT_BOLD,
                                        color: 'white',
                                        paddingVertical: 2,
                                        paddingHorizontal: 10,
                                        fontSize: 16,
                                    }}
                                >
                                    {item.nbNewMessage.get()}
                                </Text>
                            </View>
                        ) : (
                            <View></View>
                        )}
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'space-between',
                        }}
                    >
                        {item.isNewMessage() ? (
                            <Text
                                style={[
                                    styles.text,
                                    {
                                        color: getColor('primary'),
                                        fontFamily: FONT_BOLD,
                                        fontSize: 14,
                                    },
                                ]}
                            >
                                {item.message.get() === null
                                    ? t('message_not_found')
                                    : item.message.get()?.getContent(t) ??
                                      t('group_created')}
                            </Text>
                        ) : (
                            <Text
                                style={[
                                    styles.text,
                                    {
                                        color: getColor('hint'),
                                    },
                                ]}
                            >
                                {item.message.get() === null
                                    ? t('message_not_found')
                                    : item.message.get()?.getContent(t) ??
                                      t('group_created')}
                            </Text>
                        )}
                        <Text
                            style={[
                                styles.text,
                                {
                                    color: item.isNewMessage()
                                        ? getColor('primary')
                                        : getColor('hint'),
                                    fontSize: 12,
                                },
                            ]}
                        >
                            {item.message.get() === null
                                ? ''
                                : item.message.get()?.displayMessageDate(t)}
                        </Text>
                    </View>
                </View>
            </Pressable>
        );
    }
    return content;
};
