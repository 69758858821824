import { GestureResponderEvent, Pressable, Text, View } from 'react-native';
import React from 'react';
import Cartouche from '../svg/Cartouche';
import { FONT_BOLD, getColor } from '../utils/theme';
import { width } from '../utils/utils';

interface ICartoucheButtonProps {
    onPress?: (e: GestureResponderEvent) => void;
    onLongPress?: (e: GestureResponderEvent) => void;
    height?: number;
    width?: number;
    title: string;
    color?: string;
    fontSize?: number;
    icon?: JSX.Element;
    iconSize?: number;
    line?: number;
    disabled?: boolean;
    fontColor?: string;
}

export const CartoucheButton = (props: ICartoucheButtonProps) => {
    const {
        height = (props.width ?? 0) / 3,
        onPress,
        onLongPress,
        fontSize = 25,
        icon,
        iconSize = 0,
        color = getColor('primary'),
        title,
        line = 1,
        disabled = false,
        fontColor = 'white',
    } = props;
    return (
        <View>
            <Pressable
                style={{
                    position: 'relative',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: 100,
                    backgroundColor: color,
                    height,
                }}
                onPress={(e) => {
                    onPress?.(e);
                }}
                onLongPress={(e) => {
                    onLongPress?.(e);
                }}
                disabled={disabled}
            >
                <View
                    style={{
                        paddingHorizontal: 20,
                        justifyContent: 'center',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {icon}
                    <Text
                        numberOfLines={1}
                        adjustsFontSizeToFit
                        style={{
                            fontFamily: FONT_BOLD,
                            color: fontColor,
                            width: '100%',
                            textAlign: 'center',
                            fontSize,
                        }}
                    >
                        {title}
                    </Text>
                </View>
            </Pressable>
        </View>
    );
};
