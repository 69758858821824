import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, Text, View } from 'react-native';
import React from 'react';
//import BouncyCheckbox from 'react-native-bouncy-checkbox';
import { UserIcon } from 'react-native-heroicons/solid';
import { CartoucheButton } from '../component/CartoucheButton';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import Group from '../object/Group';
import JTOListFetch from '../object/JTOListFetch';
import User from '../object/User';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { styles } from '../utils/styles';
import { FONT_BOLD, getColor } from '../utils/theme';
import { height, isCloseToBottom, openAction, width } from '../utils/utils';
import useJTOState from '../utils/jto/useJTOState';
import JTOObject from '../utils/jto/JTOObject';

interface IGroupFilterModalProps {
    navigation: any;
}

export const GroupFilterModal = (props: IGroupFilterModalProps) => {
    const { t } = useTranslation();

    const groupList = useJTOState(User._instance.getEvent()!.groupList);

    const [selected, setSelected] = useState<Group[]>([]);

    const isSelected = (user: JTOObject) => {
        return (
            selected.find((nb) => {
                return nb.getJTOIdentifier() === user.getJTOIdentifier();
            }) !== undefined
        );
    };

    useEffect(() => {
        groupList.refetch({
            id: User._instance.getEvent()?.getJTOIdentifier(),
            search: '',
        });
    }, []);

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('group_filter')}
                line={1}
                backButton={true}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        height:
                            height() -
                            NAVIGATION_BAR_HEIGHT -
                            STATUS_BAR_HEIGHT -
                            TAB_HEIGHT -
                            NAVIGATION_BAR_HEIGHT,
                        width: width(),
                        marginTop: -40,
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            paddingHorizontal: 20,
                        }}
                        /*keyboardDismissMode='on-drag'
                        onMomentumScrollEnd={({ nativeEvent }) => {
                            if (isCloseToBottom(nativeEvent)) {
                                groupList.more({
                                    id: User._instance
                                        .getEvent()
                                        ?.getJTOIdentifier(),
                                    search: '',
                                });
                            }
                        }}
                        scrollEventThrottle={1}
                        onScroll={({ nativeEvent }) => {
                            if (isCloseToBottom(nativeEvent)) {
                                groupList.more({
                                    id: User._instance
                                        .getEvent()
                                        ?.getJTOIdentifier(),
                                    search: '',
                                });
                            }
                        }}*/
                    >
                        <View
                            style={{
                                height: 50,
                            }}
                        ></View>
                        {groupList.getList().map((user, index) => {
                            return (
                                <Pressable
                                    key={user.getJTOIdentifier()}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: 5,
                                    }}
                                    onPress={() => {
                                        if (isSelected(user)) {
                                            setSelected(
                                                selected.filter(
                                                    (nb) =>
                                                        nb.getJTOIdentifier() !==
                                                        user.getJTOIdentifier(),
                                                ),
                                            );
                                        } else {
                                            setSelected([...selected, user]);
                                        }
                                    }}
                                >
                                    {/*<BouncyCheckbox
                                        fillColor={getColor('secondary')}
                                        isChecked={isSelected(user)}
                                        textStyle={[
                                            styles.text,
                                            {
                                                fontSize: 15,
                                                textDecorationLine: 'none',
                                            },
                                        ]}
                                        text={user.name.get()}
                                        onPress={() => {
                                            if (isSelected(user)) {
                                                setSelected(
                                                    selected.filter(
                                                        (nb) =>
                                                            nb.getJTOIdentifier() !==
                                                            user.getJTOIdentifier(),
                                                    ),
                                                );
                                            } else {
                                                setSelected([
                                                    ...selected,
                                                    user,
                                                ]);
                                            }
                                        }}
                                    />*/}
                                </Pressable>
                            );
                        })}

                        {(groupList.isLoading() || !groupList.isComplite()) && (
                            <View
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: 70,
                                }}
                            >
                                <GeSpinner color={getColor('primary')} />
                            </View>
                        )}

                        {!groupList.isLoading() &&
                            groupList.isComplite() &&
                            groupList.getList().length === 0 && (
                                <View
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 70,
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                fontSize: 25,
                                                fontFamily: FONT_BOLD,
                                                color: getColor('text'),
                                                textAlign: 'center',
                                            },
                                        ]}
                                    >
                                        {t('no_group_found')}
                                    </Text>
                                </View>
                            )}
                    </View>

                    <View
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <CartoucheButton
                            onPress={() => {
                                (props as any).route.params.setGroupList(
                                    selected,
                                );
                                props.navigation.goBack();
                            }}
                            title={t('select')}
                            height={80}
                        />
                    </View>
                </View>
            </Header>
        </View>
    );
};
