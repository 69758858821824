import Event from '../object/Event';
import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { FONT_BOLD, FONT_MEDIUM, getColor } from '../utils/theme';
//import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import {
    ChartPieIcon,
    MusicalNoteIcon,
    SparklesIcon,
    UserGroupIcon,
    XMarkIcon,
} from 'react-native-heroicons/solid';
import { CartoucheButton } from './CartoucheButton';

interface IEventTopPresentationProps {
    event: Event;
    navigation: any;
    noCross?: boolean;
}

export function getIcon(eventType: any, width: number = 18) {
    switch (eventType.name.get()) {
        case 'Restaurant':
            return (
                <View>
                    {/*<MaterialIcons
                    name='restaurant'
                    size={18}
                    color={'white'}
            />*/}
                </View>
            );

        case 'Conférence':
            return (
                <UserGroupIcon
                    color={'white'}
                    width={width}
                />
            );
        case 'Concert':
            return (
                <MusicalNoteIcon
                    color={'white'}
                    width={width}
                />
            );
        case 'Sondage':
            return (
                <ChartPieIcon
                    color={'white'}
                    width={width}
                />
            );
        case 'Spectacle':
            return (
                <SparklesIcon
                    color={'white'}
                    width={width}
                />
            );
        default:
            return null;
    }
}

export function getChip(eventType: any) {
    return (
        <View
            style={{
                backgroundColor: eventType.color.get() + '20',
                borderRadius: 30,
                paddingHorizontal: 15,
                paddingVertical: 10,
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <CartoucheButton
                color={eventType.color.get()}
                height={40}
                fontSize={20}
                iconSize={20}
                icon={getIcon(eventType) ?? <></>}
                title={eventType.name.get()}
            />
            <Text
                style={{
                    fontSize: 18,
                    marginLeft: 10,
                    fontFamily: FONT_BOLD,
                    color: eventType.color.get(),
                }}
            >
                {eventType.name.get()}
            </Text>
        </View>
    );
}

export const EventTopPresentation = (props: IEventTopPresentationProps) => {
    const now = new Date();

    const eventStart = new Date(props.event.getBetterDate() ?? now);
    const eventDuration = props.event.duration.get() ?? 0;
    const eventEnd = new Date(eventStart.getTime() + eventDuration * 60 * 1000);
    let eventDurationString = '';
    if (eventDuration > 1440) {
        eventDurationString += Math.floor(eventDuration / 1440) + 'j';
    } else if (eventDuration > 60) {
        eventDurationString += ' ' + Math.floor(eventDuration / 60) + 'h';
    } else {
        eventDurationString += ' ' + eventDuration + 'min';
    }

    return (
        <View>
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Text
                    style={{
                        color: getColor('hint'),
                        fontFamily: FONT_MEDIUM,
                    }}
                >
                    {eventStart !== now
                        ? eventStart.toLocaleDateString() +
                          (eventEnd.setHours(0, 0, 0, 0) !==
                          eventStart.setHours(0, 0, 0, 0)
                              ? ' - ' + eventEnd.toLocaleDateString()
                              : '')
                        : ' '}
                </Text>
                {!props.noCross && (
                    <Pressable
                        style={{
                            padding: 4,
                            backgroundColor: getColor('card'),
                            borderRadius: 50,
                        }}
                        onPress={() => {
                            props.navigation.goBack();
                        }}
                    >
                        <XMarkIcon
                            color={getColor('hint')}
                            size={20}
                        />
                    </Pressable>
                )}
            </View>

            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: 10,
                }}
            >
                <Text
                    style={{
                        flex: 1,
                        color: getColor('text'),
                        fontSize: 25,
                        fontFamily: FONT_BOLD,
                    }}
                >
                    {props.event.name.get()}
                </Text>
                <View
                    style={{
                        alignItems: 'flex-end',
                        paddingLeft: 5,
                        paddingTop: 5,
                    }}
                >
                    <Text
                        style={{
                            color: getColor('hint'),
                            fontSize: 14,
                            fontFamily: FONT_BOLD,
                        }}
                    >
                        {new Date(
                            props.event.getBetterDate()!,
                        ).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    </Text>
                    <Text
                        style={{
                            color: getColor('hint'),
                            fontSize: 14,
                            fontFamily: FONT_BOLD,
                        }}
                    >
                        {'Durée : ' + eventDurationString}
                    </Text>
                </View>
            </View>
            <View>
                <Text
                    style={{
                        paddingTop: 10,
                        color: getColor('hint'),
                        fontFamily: FONT_MEDIUM,
                    }}
                >
                    {props.event.description.get()}
                </Text>
            </View>

            {props.event.eventType.get() !== null && (
                <View
                    style={{
                        alignItems: 'flex-end',
                        paddingTop: 10,
                    }}
                >
                    {getChip(props.event.eventType.get())}
                </View>
            )}
        </View>
    );
};
