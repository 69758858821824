// tslint:disable no-empty prefer-const no-console one-variable-per-declaration no-shadowed-variable no-let no-var prefer-const no-unused-expression no-unused-variable no-conditional object-literal-sort-keys object-literal-shorthand comment-format

import React from 'react';
import {
    Dimensions,
    StyleSheet,
    View,
    Animated,
    Easing,
    Vibration,
} from 'react-native';
import { height, width } from '../utils/utils';

interface IPoint {
    x: number;
    y: number;
}

interface IFireworksProps {
    duration: number;
    width: number;
    height: number;
    minConf: number;
    maxConf: number;
    count: number;
    timediff: number;
    sound?: boolean;
    pause?: boolean;
}

interface IFireworksState {
    point: IPoint | null;
    start: boolean;
    x: any[];
    y: any[];
    fadingOpacity: Animated.Value;
    movingBall: Animated.Value;
    count: number;
}

export default class Fireworks extends React.PureComponent<
    IFireworksProps,
    IFireworksState
> {
    constructor(props: any) {
        super(props);

        let density = 1,
            x = [],
            y = [];

        for (let i = 0; i < density; i++) {
            x[i] = this.getRandomArbitrary(0, width() - 200);
            y[i] = this.getRandomArbitrary(0, height() - 200);
        }

        this.state = {
            point: {
                x: this.getRandomArbitrary(width() * 0.1, width() * 0.9),
                y: this.getRandomArbitrary(height() * 0.1, height() * 0.5),
            },
            start: false,
            x: x,
            y: y,
            fadingOpacity: new Animated.Value(0),
            movingBall: new Animated.Value(0),
            count: this.props.count,
        };
    }

    componentDidMount() {
        if (!this.props.pause) {
            if (this.props.timediff > 0) {
                setTimeout(() => {
                    this.setExplosionSpots();
                }, this.props.timediff);
            } else {
                this.setExplosionSpots();
            }
        }
    }

    start(count: number) {
        this.setState({
            count: count,
        });
        if (this.props.timediff > 0) {
            setTimeout(() => {
                this.setExplosionSpots();
            }, this.props.timediff);
        } else {
            this.setExplosionSpots(true);
        }
    }

    setExplosionSpots(force: boolean = false) {
        let density = 1,
            x = [],
            y = [];

        for (let i = 0; i < density; i++) {
            x[i] = this.getRandomArbitrary(0, width() - 200);
            y[i] = this.getRandomArbitrary(0, height() - 200);
        }

        if (this.state.count > 0 || force) {
            this.setState(
                {
                    x: x,
                    y: y,
                    start: true,
                    point: {
                        x: this.getRandomArbitrary(
                            width() * 0.1,
                            width() * 0.9,
                        ),
                        y: this.getRandomArbitrary(
                            height() * 0.1,
                            height() * 0.5,
                        ),
                    },
                    count: this.state.count - 1,
                },
                () => {
                    this.animateBall();
                },
            );
        } else {
            // WIN ONE UPDATE IF START(1)
            this.setState({
                count: 1,
            });
        }
    }

    getRandom(n: number) {
        return Math.round(Math.random() * n);
    }
    getRandomArbitrary(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }

    async playSound() {
        if (this.props.sound) {
            Vibration.vibrate([Math.ceil(this.getRandomArbitrary(5, 10))]);
            /* let i = Math.round(this.getRandomArbitrary(0.5, 3.5));
            if(i==1){
                const { sound } = await Audio.Sound.createAsync( require('../../assets/sound/firecracker1.wav'));
                await sound.setVolumeAsync(0.1);
                await sound.playAsync();
                this.sound = sound;
            }
            else if(i==2){
                const { sound } = await Audio.Sound.createAsync( require('../../assets/sound/firecracker2.wav'));
                await sound.setVolumeAsync(0.2);
                await sound.playAsync();
                this.sound = sound;
            }
            else if(i==3){
                const { sound } = await Audio.Sound.createAsync( require('../../assets/sound/firecracker3.wav'));
                await sound.setVolumeAsync(0.1);
                await sound.playAsync();
                this.sound = sound;
            } */
        }
    }

    animateBall() {
        this.playSound()
            .then(() => {})
            .catch(() => {});
        this.state.movingBall.setValue(0);
        Animated.timing(this.state.movingBall, {
            toValue: 1,
            duration: this.props.duration,
            easing: Easing.bezier(0.25, 0.1, 0.25, 1),
            useNativeDriver: true,
        }).start();
        this.state.fadingOpacity.setValue(1);
        Animated.timing(this.state.fadingOpacity, {
            toValue: 0,
            duration: this.props.duration,
            easing: Easing.bezier(0.25, 0.1, 0.25, 1),
            useNativeDriver: true,
        }).start(() => {
            if (this.props.sound) {
                // this.sound?.unloadAsync().then(()=>{
                this.setExplosionSpots();
                //}).catch(()=>{});
            } else {
                this.setExplosionSpots();
            }
        });
    }

    explosionBox() {
        let balls: any[] = [],
            randomTops: any[] = [],
            randomLefts: any[] = [],
            randomColors: any[] = [];

        for (
            let i = 0;
            i < this.getRandomArbitrary(this.props.minConf, this.props.maxConf);
            i++
        ) {
            balls.push('');

            randomTops[i] = this.state.movingBall.interpolate({
                inputRange: [0, 1],
                outputRange: [
                    this.state.point!.y,
                    this.getRandomArbitrary(
                        this.state.point!.y - this.props.height / 2,
                        this.state.point!.y + this.props.height / 2,
                    ),
                ],
            });
            randomLefts[i] = this.state.movingBall.interpolate({
                inputRange: [0, 1],
                outputRange: [
                    this.state.point!.x,
                    this.getRandomArbitrary(
                        this.state.point!.x - this.props.width / 2,
                        this.state.point!.x + this.props.width / 2,
                    ),
                ],
            });
            randomColors[i] =
                'rgb(' +
                this.getRandom(255) +
                ',' +
                this.getRandom(255) +
                ',' +
                this.getRandom(255) +
                ')';
        }
        let ballOpacity = this.state.fadingOpacity.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 1],
        });

        return (
            <View
                style={[
                    {
                        height: 0,
                        width: 0,
                    },
                    styles.anywhere,
                ]}
            >
                {balls.map((ball, index) => {
                    return (
                        <Animated.View
                            key={index}
                            style={[
                                styles.ball,
                                {
                                    top: 0,
                                    right: 0,
                                    zIndex: 1000,
                                    opacity: ballOpacity,
                                    backgroundColor: randomColors[index],
                                },
                                {
                                    transform: [
                                        {
                                            translateX: randomLefts[index],
                                        },
                                        {
                                            translateY: randomTops[index],
                                        },
                                    ],
                                },
                            ]}
                        />
                    );
                })}
            </View>
        );
    }

    renderExplosion() {
        const { x, y } = this.state;
        return (
            <View style={styles.anywhere}>
                {x.map((item, index) => {
                    return (
                        <View
                            key={index}
                            style={[
                                {
                                    top: y[index],
                                    left: x[index],
                                },
                                styles.anywhere,
                            ]}
                        >
                            {this.explosionBox()}
                        </View>
                    );
                })}
            </View>
        );
    }

    render() {
        return this.renderExplosion();
    }
}

const styles = StyleSheet.create({
    anywhere: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    explosionBoundary: {
        position: 'absolute',
        height: 0,
        width: 0,
    },
    ball: {
        position: 'absolute',
        height: 9,
        width: 9,
        borderRadius: 4,
    },
});
