import { getColor } from '../utils/theme';
import { hexToRgb } from '../utils/utils';

export const pulseAnim = () => {
    return {
        v: '4.8.0',
        meta: { g: 'LottieFiles AE 1.0.0', a: '', k: '', d: '', tc: '#FFFFFF' },
        fr: 25,
        ip: 0,
        op: 116,
        w: 470,
        h: 470,
        nm: 'Lottie_Main_Comp',
        ddd: 0,
        assets: [],
        layers: [
            {
                ddd: 0,
                ind: 1,
                ty: 3,
                nm: 'Null 1',
                sr: 1,
                ks: {
                    o: { a: 0, k: 0, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [235, 235, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 6,
                                s: [100, 100, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 29,
                                s: [120, 120, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 49,
                                s: [100, 100, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 72,
                                s: [120, 120, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 88,
                                s: [120, 120, 100],
                            },
                            { t: 109, s: [100, 100, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                ip: 0,
                op: 116,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 2,
                ty: 4,
                nm: 'pill top',
                parent: 1,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [2, -14.25, 0], ix: 2 },
                    a: { a: 0, k: [3.5, -29.75, 0], ix: 1 },
                    s: { a: 0, k: [100, -100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: 'gr',
                        it: [
                            {
                                ty: 'rc',
                                d: 1,
                                s: { a: 0, k: [26.627, 55.588], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                r: { a: 0, k: 20, ix: 4 },
                                nm: 'Rectangle Path 1',
                                mn: 'ADBE Vector Shape - Rect',
                                hd: false,
                            },
                            {
                                ty: 'tm',
                                s: { a: 0, k: 16, ix: 1 },
                                e: { a: 0, k: 61, ix: 2 },
                                o: { a: 0, k: 0, ix: 3 },
                                m: 1,
                                ix: 2,
                                nm: 'Trim Paths 1',
                                mn: 'ADBE Vector Filter - Trim',
                                hd: false,
                            },
                            {
                                ty: 'st',
                                c: {
                                    a: 0,
                                    k: [
                                        0.00784313725490196, 0.7411764705882353,
                                        0.19607843137254902, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 4, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 4,
                                bm: 0,
                                nm: 'Stroke 1',
                                mn: 'ADBE Vector Graphic - Stroke',
                                hd: false,
                            },
                            {
                                ty: 'tr',
                                p: { a: 0, k: [3.627, -30.815], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: 'Transform',
                            },
                        ],
                        nm: 'Rectangle 1',
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                    },
                ],
                ip: 0,
                op: 1000,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 3,
                ty: 4,
                nm: 'pill down',
                parent: 1,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [2, -14.25, 0], ix: 2 },
                    a: { a: 0, k: [3.5, -29.75, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: 'gr',
                        it: [
                            {
                                ty: 'rc',
                                d: 1,
                                s: { a: 0, k: [26.627, 55.588], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                r: { a: 0, k: 20, ix: 4 },
                                nm: 'Rectangle Path 1',
                                mn: 'ADBE Vector Shape - Rect',
                                hd: false,
                            },
                            {
                                ty: 'tm',
                                s: { a: 0, k: 0, ix: 1 },
                                e: { a: 0, k: 58, ix: 2 },
                                o: { a: 0, k: 0, ix: 3 },
                                m: 1,
                                ix: 2,
                                nm: 'Trim Paths 1',
                                mn: 'ADBE Vector Filter - Trim',
                                hd: false,
                            },
                            {
                                ty: 'st',
                                c: {
                                    a: 0,
                                    k: [
                                        0.00784313725490196, 0.7411764705882353,
                                        0.19607843137254902, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 4, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 4,
                                bm: 0,
                                nm: 'Stroke 1',
                                mn: 'ADBE Vector Graphic - Stroke',
                                hd: false,
                            },
                            {
                                ty: 'tr',
                                p: { a: 0, k: [3.627, -30.815], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: 'Transform',
                            },
                        ],
                        nm: 'Rectangle 1',
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                    },
                ],
                ip: 0,
                op: 1000,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 4,
                ty: 4,
                nm: 'base',
                parent: 1,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [1.802, 39, 0], ix: 2 },
                    a: { a: 0, k: [3.25, 23.5, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: 'gr',
                        it: [
                            {
                                ty: 'rc',
                                d: 1,
                                s: { a: 0, k: [3.99, 18.391], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                r: { a: 0, k: 20, ix: 4 },
                                nm: 'Rectangle Path 1',
                                mn: 'ADBE Vector Shape - Rect',
                                hd: false,
                            },
                            {
                                ty: 'fl',
                                c: {
                                    a: 0,
                                    k: [
                                        0.00784313725490196, 0.7411764705882353,
                                        0.19607843137254902, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: 'Fill 1',
                                mn: 'ADBE Vector Graphic - Fill',
                                hd: false,
                            },
                            {
                                ty: 'tr',
                                p: { a: 0, k: [3.575, 23.858], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [123.795, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: 'Transform',
                            },
                        ],
                        nm: 'Rectangle 1',
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                    },
                ],
                ip: 0,
                op: 1000,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 5,
                ty: 4,
                nm: 'Half rign',
                parent: 1,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [1.533, -0.75, 0], ix: 2 },
                    a: { a: 0, k: [3, -16.25, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: 'gr',
                        it: [
                            {
                                d: 1,
                                ty: 'el',
                                s: { a: 0, k: [44.426, 44.426], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: 'Ellipse Path 1',
                                mn: 'ADBE Vector Shape - Ellipse',
                                hd: false,
                            },
                            {
                                ty: 'tm',
                                s: { a: 0, k: 25, ix: 1 },
                                e: { a: 0, k: 75, ix: 2 },
                                o: { a: 0, k: 0, ix: 3 },
                                m: 1,
                                ix: 2,
                                nm: 'Trim Paths 1',
                                mn: 'ADBE Vector Filter - Trim',
                                hd: false,
                            },
                            {
                                ty: 'st',
                                c: {
                                    a: 0,
                                    k: [
                                        0.00784313725490196, 0.7411764705882353,
                                        0.19607843137254902, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 3, ix: 5 },
                                lc: 2,
                                lj: 1,
                                ml: 4,
                                bm: 0,
                                nm: 'Stroke 1',
                                mn: 'ADBE Vector Graphic - Stroke',
                                hd: false,
                            },
                            {
                                ty: 'tr',
                                p: { a: 0, k: [3.594, -15.692], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [137.481, 137.481], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: 'Transform',
                            },
                        ],
                        nm: 'Ellipse 1',
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                    },
                ],
                ip: 0,
                op: 116,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 6,
                ty: 4,
                nm: '01',
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [235, 235, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 0,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 22.562,
                                s: [130, 130, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 41.365,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 62.047,
                                s: [130, 130, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 80.85,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 99.651,
                                s: [130, 130, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 116.574,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 131.616,
                                s: [130, 130, 100],
                            },
                            { t: 148.5380859375, s: [100, 100, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: 'gr',
                        it: [
                            {
                                d: 1,
                                ty: 'el',
                                s: { a: 0, k: [198.609, 198.609], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: 'Ellipse Path 1',
                                mn: 'ADBE Vector Shape - Ellipse',
                                hd: false,
                            },
                            {
                                ty: 'fl',
                                c: {
                                    a: 0,
                                    k: hexToRgb(getColor('primary'), 1),
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: 'Fill 1',
                                mn: 'ADBE Vector Graphic - Fill',
                                hd: false,
                            },
                            {
                                ty: 'tr',
                                p: { a: 0, k: [1, 1], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: 'Transform',
                            },
                        ],
                        nm: 'Ellipse 1',
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                    },
                ],
                ip: 0,
                op: 116,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 7,
                ty: 4,
                nm: '02',
                sr: 1,
                ks: {
                    o: { a: 0, k: 50, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [235, 235, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 0,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 22.562,
                                s: [162, 162, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 41.365,
                                s: [99, 99, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 62.047,
                                s: [162, 162, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 80.85,
                                s: [130, 130, 100],
                            },
                            {
                                i: {
                                    x: [0.667, 0.667, 0.667],
                                    y: [0.97, 0.97, 1],
                                },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 99.651,
                                s: [162, 162, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: {
                                    x: [0.333, 0.333, 0.333],
                                    y: [-0.002, -0.002, 0],
                                },
                                t: 116,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                                t: 116.574,
                                s: [133, 133, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 131.616,
                                s: [162, 162, 100],
                            },
                            { t: 148.5380859375, s: [99, 99, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: 'gr',
                        it: [
                            {
                                d: 1,
                                ty: 'el',
                                s: { a: 0, k: [198.609, 198.609], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: 'Ellipse Path 1',
                                mn: 'ADBE Vector Shape - Ellipse',
                                hd: false,
                            },
                            {
                                ty: 'fl',
                                c: {
                                    a: 0,
                                    k: hexToRgb(getColor('primary'), 1),
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: 'Fill 1',
                                mn: 'ADBE Vector Graphic - Fill',
                                hd: false,
                            },
                            {
                                ty: 'tr',
                                p: { a: 0, k: [1, 1], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: 'Transform',
                            },
                        ],
                        nm: 'Ellipse 1',
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                    },
                ],
                ip: 0,
                op: 116,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 8,
                ty: 4,
                nm: '03',
                sr: 1,
                ks: {
                    o: { a: 0, k: 30, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [235, 235, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 0,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 22.562,
                                s: [190, 190, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 41.365,
                                s: [151, 151, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 62.047,
                                s: [195, 195, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 80.85,
                                s: [160, 160, 100],
                            },
                            {
                                i: {
                                    x: [0.667, 0.667, 0.667],
                                    y: [0.947, 0.947, 1],
                                },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 99.651,
                                s: [195, 195, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: {
                                    x: [0.333, 0.333, 0.333],
                                    y: [-0.012, -0.012, 0],
                                },
                                t: 116,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                                t: 116.574,
                                s: [115, 115, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 131.616,
                                s: [195, 195, 100],
                            },
                            { t: 148.5380859375, s: [99, 99, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: 'gr',
                        it: [
                            {
                                d: 1,
                                ty: 'el',
                                s: { a: 0, k: [198.609, 198.609], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: 'Ellipse Path 1',
                                mn: 'ADBE Vector Shape - Ellipse',
                                hd: false,
                            },
                            {
                                ty: 'fl',
                                c: {
                                    a: 0,
                                    k: hexToRgb(getColor('primary'), 1),
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: 'Fill 1',
                                mn: 'ADBE Vector Graphic - Fill',
                                hd: false,
                            },
                            {
                                ty: 'tr',
                                p: { a: 0, k: [1, 1], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: 'Transform',
                            },
                        ],
                        nm: 'Ellipse 1',
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                    },
                ],
                ip: 0,
                op: 116,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 9,
                ty: 4,
                nm: '04',
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [235, 235, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 0,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 22.562,
                                s: [130, 130, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 41.365,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 62.047,
                                s: [130, 130, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 80.85,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 99.651,
                                s: [130, 130, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 116.574,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 131.616,
                                s: [130, 130, 100],
                            },
                            { t: 148.5380859375, s: [100, 100, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: 'gr',
                        it: [
                            {
                                d: 1,
                                ty: 'el',
                                s: { a: 0, k: [198.609, 198.609], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: 'Ellipse Path 1',
                                mn: 'ADBE Vector Shape - Ellipse',
                                hd: false,
                            },
                            {
                                ty: 'fl',
                                c: {
                                    a: 0,
                                    k: hexToRgb(getColor('primary'), 1),
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: 'Fill 1',
                                mn: 'ADBE Vector Graphic - Fill',
                                hd: false,
                            },
                            {
                                ty: 'tr',
                                p: { a: 0, k: [1, 1], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: 'Transform',
                            },
                        ],
                        nm: 'Ellipse 1',
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                    },
                ],
                ip: 0,
                op: 116,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 10,
                ty: 4,
                nm: 'transparent1',
                sr: 1,
                ks: {
                    o: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 22.562,
                                s: [10],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 41.365,
                                s: [5],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 62.047,
                                s: [20],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 80.85,
                                s: [5],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 99.651,
                                s: [20],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 116.574,
                                s: [0],
                            },
                            { t: 131.6162109375, s: [20] },
                        ],
                        ix: 11,
                    },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [235, 235, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 0,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 22.562,
                                s: [224, 224, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 41.365,
                                s: [127, 127, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 62.047,
                                s: [224, 224, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 80.85,
                                s: [145, 145, 100],
                            },
                            {
                                i: {
                                    x: [0.667, 0.667, 0.667],
                                    y: [0.944, 0.944, 1],
                                },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 99.651,
                                s: [224, 224, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: {
                                    x: [0.333, 0.333, 0.333],
                                    y: [-0.016, -0.016, 0],
                                },
                                t: 116,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                                t: 116.574,
                                s: [115, 115, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 131.616,
                                s: [224, 224, 100],
                            },
                            { t: 148.5380859375, s: [99, 99, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: 'gr',
                        it: [
                            {
                                d: 1,
                                ty: 'el',
                                s: { a: 0, k: [198.609, 198.609], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: 'Ellipse Path 1',
                                mn: 'ADBE Vector Shape - Ellipse',
                                hd: false,
                            },
                            {
                                ty: 'fl',
                                c: {
                                    a: 0,
                                    k: hexToRgb(getColor('primary'), 1),
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: 'Fill 1',
                                mn: 'ADBE Vector Graphic - Fill',
                                hd: false,
                            },
                            {
                                ty: 'tr',
                                p: { a: 0, k: [1, 1], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: 'Transform',
                            },
                        ],
                        nm: 'Ellipse 1',
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                    },
                ],
                ip: 0,
                op: 116,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 11,
                ty: 4,
                nm: 'transparent2',
                sr: 1,
                ks: {
                    o: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.167], y: [0] },
                                t: 0,
                                s: [100],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 22.562,
                                s: [0],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 41.365,
                                s: [100],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 62.047,
                                s: [0],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 80.85,
                                s: [0],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 99.651,
                                s: [0],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 116.574,
                                s: [0],
                            },
                            { t: 131.6162109375, s: [0] },
                        ],
                        ix: 11,
                    },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [235, 235, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 0,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 22.562,
                                s: [234, 234, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 41.365,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 62.047,
                                s: [234, 234, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 80.85,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 99.651,
                                s: [234, 234, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                                t: 116.574,
                                s: [100, 100, 100],
                            },
                            {
                                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                                t: 131.616,
                                s: [234, 234, 100],
                            },
                            { t: 148.5380859375, s: [99, 99, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: 'gr',
                        it: [
                            {
                                d: 1,
                                ty: 'el',
                                s: { a: 0, k: [198.609, 198.609], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: 'Ellipse Path 1',
                                mn: 'ADBE Vector Shape - Ellipse',
                                hd: false,
                            },
                            {
                                ty: 'fl',
                                c: {
                                    a: 0,
                                    k: hexToRgb(getColor('primary'), 1),
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: 'Fill 1',
                                mn: 'ADBE Vector Graphic - Fill',
                                hd: false,
                            },
                            {
                                ty: 'tr',
                                p: { a: 0, k: [1, 1], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: 'Transform',
                            },
                        ],
                        nm: 'Ellipse 1',
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: 'ADBE Vector Group',
                        hd: false,
                    },
                ],
                ip: 0,
                op: 116,
                st: 0,
                bm: 0,
            },
        ],
        markers: [],
    };
};
