import { QueryInstance } from 'mp-ts-query';
import { InteractionManager } from 'react-native';
import React from 'react';
//import { storage } from '../../App';
import { SERVER_URL } from './constants';

let callback = [] as (() => void)[];

export const resumeJob = async () => {
    const token = await QueryInstance.getAccessToken()();
    if (token !== undefined && token !== null && token !== '') {
        for (const job of callback) {
            job();
        }
    }
    callback = [];
};

export const getRestore = async (
    action: string,
    params: { [key: string]: any },
    successCallback: (res: any) => void = () => {
        // Nothing
    },
    errorCalback: (res: any) => void = () => {
        // Nothing
    },
    resetCallback: (res: any) => void = () => {
        // Nothing
    },
    restore: boolean = true,
) => {
    let paramStr = '';
    let i = 0;
    for (const key of Object.keys(params)) {
        if (i === 0) {
            paramStr += '?';
        } else {
            paramStr += '&';
        }
        paramStr += key + '=' + params[key];
        i++;
    }
    let res = null;
    try {
        let make = false;
        if (restore) {
            InteractionManager.runAfterInteractions(() => {
                let storage = localStorage.getItem(action + paramStr);
                let json = JSON.parse(storage);
                if (json && !make) {
                    InteractionManager.runAfterInteractions(() => {
                        if (json && !make) {
                            resetCallback(json);
                            successCallback(json);
                        }
                    });
                }
            });
        }
        const token = await QueryInstance.getAccessToken()();
        const job = async () => {
            let returnValue: any;
            const response = await fetch(SERVER_URL + action + paramStr, {
                method: 'GET',
                headers: {
                    Authorization:
                        'Bearer ' + (await QueryInstance.getAccessToken()()),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            if (!response.ok) {
                returnValue = {
                    status: 'failed',
                    error: 'went_wrong',
                };
                errorCalback(returnValue);
            } else {
                returnValue = await response.json();
                InteractionManager.runAfterInteractions(() => {
                    make = true;
                    localStorage.setItem(
                        action + paramStr,
                        JSON.stringify(returnValue),
                    );
                    resetCallback(returnValue);
                    successCallback(returnValue);
                });
            }
            return returnValue;
        };
        if (token !== undefined && token !== null && token !== '') {
            res = await job();
        } else {
            callback.push(job);
        }
    } catch (error) {
        res = {
            status: 'failed',
            error: 'went_wrong',
        };
        errorCalback(res);
    }
    return res;
};

export const get = async (action: string, params: { [key: string]: any }) => {
    let paramStr = '';
    let i = 0;
    for (const key of Object.keys(params)) {
        if (i === 0) {
            paramStr += '?';
        } else {
            paramStr += '&';
        }
        paramStr += key + '=' + params[key];
        i++;
    }
    let res = null;
    try {
        let make = false;
        const token = await QueryInstance.getAccessToken()();
        const job = async () => {
            let resturnValue;
            const response = await fetch(SERVER_URL + action + paramStr, {
                method: 'GET',
                headers: {
                    Authorization:
                        'Bearer ' + (await QueryInstance.getAccessToken()()),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });
            if (!response.ok) {
                resturnValue = {
                    status: 'failed',
                    error: 'went_wrong',
                };
            } else {
                resturnValue = await response.json();
                make = true;
            }
            return resturnValue;
        };
        if (token !== undefined && token !== null && token !== '') {
            res = await job();
        } else {
            callback.push(job);
        }
    } catch (error) {
        res = {
            status: 'failed',
            error: 'went_wrong',
        };
    }
    return res;
};

export const post = async (
    action: string,
    params: { [key: string]: any },
    notoken: boolean = false,
) => {
    let res = null;
    try {
        let make = false;
        const token = await QueryInstance.getAccessToken()();
        const job = async () => {
            let returnValue;
            const response = await fetch(SERVER_URL + action, {
                method: 'POST',
                headers: {
                    Authorization:
                        'Bearer ' + (await QueryInstance.getAccessToken()()),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
                credentials: 'include',
            });
            if (!response.ok) {
                returnValue = {
                    status: 'failed',
                    error: 'went_wrong',
                };
            } else {
                returnValue = await response.json();
                make = true;
            }
            return returnValue;
        };
        if (
            (token !== undefined && token !== null && token.trim() !== '') ||
            notoken
        ) {
            res = await job();
        } else {
            callback.push(job);
        }
    } catch (error) {
        res = {
            status: 'failed',
            error: 'went_wrong',
        };
    }
    return res;
};

export const postRefresh = async (
    action: string,
    params: { [key: string]: any },
) => {
    let res = null;
    try {
        let make = false;
        const token = await QueryInstance.getRefreshToken()();
        const job = async () => {
            let returnValue;
            const response = await fetch(SERVER_URL + action, {
                method: 'POST',
                headers: {
                    Authorization:
                        'Bearer ' + (await QueryInstance.getRefreshToken()()),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
                credentials: 'include',
            });
            if (!response.ok) {
                returnValue = {
                    status: 'failed',
                    error: 'went_wrong',
                };
            } else {
                returnValue = await response.json();
                make = true;
            }
            return returnValue;
        };
        if (token !== undefined && token !== null && token !== '') {
            res = await job();
        } else {
            callback.push(job);
        }
    } catch (error) {
        res = {
            status: 'failed',
            error: 'went_wrong',
        };
    }
    return res;
};
