import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
    return (
        <Svg
            id='Calque_2'
            data-name='Calque 2'
            viewBox='0 0 164.91 67.1'
            {...props}
        >
            <Path
                d='M164.91 29.38l-.52 2.79c-1.31 2.65-3.58 4.07-6.2 5.25-21.12 9.46-42.22 18.98-63.3 28.53-2.46 1.12-4.85 1.41-7.49.91a5303.18 5303.18 0 00-37.65-6.95c-.99-.18-2.1-.16-3.09.06-7.36 1.65-14.71 3.39-22.07 5.09-4.43 1.02-7.41-.21-9.76-4.05C10.4 53.74 5.98 46.48 1.58 39.2c-2.78-4.59-1.9-8.65 2.51-11.73 12.23-8.53 24.48-17.03 36.69-25.6C43.17.19 45.67-.33 48.52.2c13.73 2.58 27.47 5.1 41.2 7.66 5.62 1.05 8.1 4.71 7.08 10.37-.33 1.81-.64 3.62-1 5.42-.96 4.8-4.75 7.39-9.63 6.51-7.03-1.26-14.05-2.59-21.07-3.89-.54-.1-1.08-.2-1.7-.02 4.09 1.97 8.18 3.94 12.26 5.92 4.15 2.01 8.32 4 12.44 6.07 1.03.52 1.88.53 2.95.1 19.51-7.74 39.04-15.44 58.56-23.14 5.75-2.27 9.59-.69 11.96 5.07 1.23 2.99 2.24 6.08 3.35 9.11z'
                id='Calque_1-2'
                data-name='Calque 1'
                strokeWidth={0}
            />
        </Svg>
    );
}

export default SvgComponent;
