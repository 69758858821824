import { Image, Linking, Pressable, Text, View } from 'react-native';
import React from 'react';
import PostFile from '../object/PostFile';
import {
    PresentationChartBarIcon,
    DocumentIcon,
    DocumentTextIcon,
    TableCellsIcon,
    QuestionMarkCircleIcon,
    PhotoIcon,
    MusicalNoteIcon,
    VideoCameraIcon,
    TrashIcon,
    PencilIcon,
} from 'react-native-heroicons/solid';
import FileSuper, { getColorFile, getIconFile } from '../object/FileSuper';
import { SERVER_URL } from '../utils/constants';
import { width } from '../utils/utils';
import { FONT_MEDIUM, getColor } from '../utils/theme';
import { PastilleView } from './PastilleView';

interface IFileElementViewProps {
    file?: FileSuper;
    filename?: string;
    type?: string;
    fileuri?: string;
    navigation?: any;
    imageSquare?: any;
    canAct?: boolean;
}

export const isImage = (filename: string) => {
    const extList = (filename ?? '').split('.');
    if (extList.length > 0) {
        return ['png', 'jpeg', 'jpg', 'gif'].includes(
            (filename ?? '').split('.').pop() ?? '',
        );
    } else {
        return false;
    }
};

export const FileElementView = (props: IFileElementViewProps) => {
    const { imageSquare = true } = props;
    let image = false;
    let url = '';
    if (props.file?.isImage()) {
        image = true;
        url =
            SERVER_URL +
            '/file/' +
            (props.file.name.get() ?? props.file.alias.get() ?? '');
    } else if (isImage(props.fileuri ?? '')) {
        image = true;
        url = props.fileuri ?? '';
    } else if (props.type !== undefined) {
        image = props.type.includes('image');
        if (image && props.fileuri !== undefined) {
            url = props.fileuri;
        }
    }

    const actContent = props.canAct && (
        <View
            style={{
                bottom: 0,
                left: 0,
                right: 0,
                padding: 15,
                position: 'absolute',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <Pressable
                onPress={() => {
                    props.navigation.navigate('ProfilFileDelModal', {
                        id: props.file?.getJTOIdentifier() ?? '',
                        alias: props.file?.getAlias() ?? '',
                    });
                }}
            >
                <PastilleView
                    size={35}
                    color={getColor('error')}
                    icon={
                        <TrashIcon
                            fill={'white'}
                            size={28}
                        />
                    }
                />
            </Pressable>
            <Pressable
                onPress={() => {
                    props.navigation.navigate('ProfilFileEditModal', {
                        id: props.file?.getJTOIdentifier() ?? '',
                        alias: props.file?.getAlias() ?? '',
                    });
                }}
            >
                <PastilleView
                    size={35}
                    color={getColor('secondary')}
                    icon={
                        <PencilIcon
                            fill={'white'}
                            size={25}
                        />
                    }
                />
            </Pressable>
        </View>
    );

    if (image) {
        const content = (
            <View
                style={{
                    flex: 3,
                    borderRadius: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    margin: 7.5,
                    borderColor: getColor('border'),
                    borderWidth: 1,
                    width: (width() - 70) / 3,
                    height: (width() - 70) / 3,
                    flexDirection: 'column',
                }}
            >
                <Image
                    style={{
                        borderRadius: 20,
                        width: (width() - 70) / 3,
                        overflow: 'hidden',
                        height: (width() - 70) / 3,
                        resizeMode: 'cover',
                    }}
                    source={{
                        uri: url,
                    }}
                />
            </View>
        );
        if (props.file !== undefined) {
            return (
                <Pressable
                    style={{
                        position: 'relative',
                    }}
                    onPress={() => {
                        props.navigation?.navigate('PostPictureModal', {
                            uri: url,
                        });
                    }}
                >
                    {content}
                    {actContent}
                </Pressable>
            );
        } else {
            return content;
        }
    } else {
        const content = (
            <View
                style={{
                    flex: 3,
                    borderRadius: 20,
                    paddingVertical: 10,
                    margin: 7.5,
                    width: (width() - 70) / 3,
                    height: (width() - 70) / 3,
                    backgroundColor:
                        props.file?.getColorFile() ??
                        getColorFile(props.filename ?? ''),
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                {props.file?.getIconFile() ?? getIconFile(props.filename ?? '')}
                <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={2}
                    style={{
                        textAlign: 'center',
                        color: '#ffffff',
                        paddingTop: 5,
                        fontFamily: FONT_MEDIUM,
                    }}
                >
                    {props.file !== undefined
                        ? props.file.getAlias()
                        : props.filename}
                </Text>
            </View>
        );
        if (props.file !== undefined) {
            return (
                <Pressable
                    onPress={() => {
                        Linking.openURL(
                            SERVER_URL + '/file/' + props.file?.getPath(),
                        );
                    }}
                >
                    {content}
                    {actContent}
                </Pressable>
            );
        } else {
            return content;
        }
    }
};
/*

                {props.file?.getIconFile()}
                <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={2}
                    style={{
                        textAlign: "center",
                        color: '#ffffff',
                        paddingTop: 10,
                        fontFamily: FONT_MEDIUM,
                        maxWidth: 100,
                    }}>
                    {props.file !== undefined
                        ? props.file.name.get()
                        : props.filename}
                </Text> */
