import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';
import {
    PresentationChartBarIcon,
    DocumentIcon,
    DocumentTextIcon,
    TableCellsIcon,
    QuestionMarkCircleIcon,
    PhotoIcon,
    MusicalNoteIcon,
    VideoCameraIcon,
} from 'react-native-heroicons/solid';
import React from 'react';

export const getColorFile = (filename: string) => {
    let color;
    switch (filename.split('.').pop()) {
        case 'pdf':
            color = '#ef4444';
            break;
        case 'doc':
        case 'docx':
        case 'odt':
            color = '#2563eb';
            break;
        case 'ppt':
        case 'pptx':
        case 'odp':
            color = '#f59e0b';
            break;
        case 'xls':
        case 'xlsx':
        case 'ods':
            color = '#16a34a';
            break;
        case 'txt':
            color = '#c026d3';
            break;
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'svg':
            color = '#a21caf';
            break;
        case 'mp3':
            color = '#0f766e';
            break;
        case 'mp4':
            color = '#7e22ce';
            break;
        default:
            color = '#475569';
            break;
    }
    return color;
};

export const getIconFile = (filename: string) => {
    let icon;
    switch (filename.split('.').pop()) {
        case 'pdf':
            icon = (
                <DocumentTextIcon
                    size={40}
                    color='#ffffff'
                />
            );
            break;
        case 'doc':
        case 'docx':
        case 'odt':
            icon = (
                <DocumentIcon
                    size={40}
                    color='#ffffff'
                />
            );
            break;
        case 'ppt':
        case 'pptx':
        case 'odp':
            icon = (
                <PresentationChartBarIcon
                    size={40}
                    color='#ffffff'
                />
            );
            break;
        case 'xls':
        case 'xlsx':
        case 'ods':
            icon = (
                <TableCellsIcon
                    size={40}
                    color='#ffffff'
                />
            );
            break;
        case 'txt':
            icon = (
                <DocumentTextIcon
                    size={40}
                    color='#ffffff'
                />
            );
            break;
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
            icon = (
                <PhotoIcon
                    size={40}
                    color='#ffffff'
                />
            );
            break;
        case 'mp3':
            icon = (
                <MusicalNoteIcon
                    size={40}
                    color='#ffffff'
                />
            );
            break;
        case 'mp4':
            icon = (
                <VideoCameraIcon
                    size={40}
                    color='#ffffff'
                />
            );
            break;
        default:
            icon = (
                <QuestionMarkCircleIcon
                    size={40}
                    color='#ffffff'
                />
            );
            break;
    }
    return icon;
};

export default abstract class FileSuper extends JTOObject {
    public name: JTOProperty<string>;
    public type: JTOProperty<string>;
    public alias: JTOProperty<string>;
    public path: JTOProperty<string>;

    constructor(type: string, parent?: JTOElement | null) {
        super(type, parent);

        this.name = new JTOProperty('name', this);
        this.type = new JTOProperty('type', this);
        this.alias = new JTOProperty('alias', this);
        this.path = new JTOProperty('path', this);
    }

    public getName(): string {
        let name = '';
        if (this.alias.get() !== '' && this.alias.get() !== undefined) {
            name = this.alias.get() ?? '';
        } else {
            name = this.name.get() ?? '';
        }
        return name;
    }

    public getExtension(): string {
        return this.getName()?.split('.').pop() ?? 'doc';
    }

    public isImage(): boolean {
        return ['png', 'jpeg', 'jpg', 'gif'].includes(
            this.getExtension().split('.').pop() ?? '',
        );
    }

    public getAlias(): string {
        return this.alias.get() ?? this.name.get() ?? '';
    }

    public getIconFile() {
        return getIconFile(this.getName());
    }

    public getColorFile() {
        return getColorFile(this.getName());
    }

    public getPath() {
        return this.path.get() ?? this.name.get() ?? '';
    }
}
