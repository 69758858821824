import { Text, View } from 'react-native';
import Contact from '../object/Contact';
import User from '../object/User';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { ContactView } from './ContactView';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface IContactCategoryViewProps {
    category: string;
    color?: string;
}

export const ContactCategoryView = (props: IContactCategoryViewProps) => {
    const { category, color } = props;
    const { t } = useTranslation();

    const contactList =
        User._instance
            .getEvent()
            ?.contactList.getList()
            .filter((contact) => {
                return contact.visible.get() !== false;
            })
            .filter((contact) => {
                return contact.category.get() === category;
            })
            .sort((a: Contact, b: Contact) => {
                // Sort by important boolean
                if (a.important.get() === b.important.get()) {
                    return 0;
                }
                if (a.important.get()) {
                    return -1;
                } else {
                    return 1;
                }
            })
            .sort((a: Contact, b: Contact) => {
                const lastname1 = a.lastname.get() ?? '';
                const lastname2 = b.lastname.get() ?? '';

                const firstname1 = a.firstname.get() ?? '';
                const firstname2 = b.firstname.get() ?? '';

                if (lastname1.localeCompare(lastname2) === 0) {
                    return firstname1.localeCompare(firstname2);
                }
                return lastname1.localeCompare(lastname2);
            }) ?? [];

    return (
        <>
            {contactList.length > 0 && (
                <View
                    style={{
                        marginHorizontal: 20,
                        marginTop: 15,
                        marginBottom: 10,
                        shadowColor: getColor('hint'),
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.2,
                        shadowRadius: 3.84,
                        elevation: 5,
                    }}
                >
                    <View
                        style={{
                            borderRadius: 27,
                            overflow: 'hidden',
                            backgroundColor: color ?? getColor('primary'),
                            shadowColor: getColor('hint'),
                        }}
                    >
                        <View
                            style={{
                                padding: 15,
                                paddingBottom: 0,
                                paddingTop: 10,
                            }}
                        >
                            <Text
                                style={{
                                    color: 'white',
                                    fontSize: 20,
                                    fontFamily: FONT_BOLD,
                                }}
                            >
                                {t('category.' + category)}
                            </Text>
                        </View>
                        <View>
                            {contactList.map((contact, index) => {
                                return (
                                    <ContactView
                                        index={index}
                                        key={contact.getJTOIdentifier()}
                                        contact={contact}
                                    />
                                );
                            })}
                        </View>
                    </View>
                </View>
            )}
        </>
    );
};
