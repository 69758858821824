import React, { useEffect, useRef, useState } from 'react';
import {
    AppState,
    InteractionManager,
    Keyboard,
    KeyboardAvoidingView,
    Platform,
    SafeAreaView,
    ScrollView,
    View,
} from 'react-native';
//import { FlatList, ScrollView } from 'react-native-gesture-handler';
import { BottomMessagePage } from '../component/message/BottomMessagePage';
import { TopMessagePage } from '../component/message/TopMessagePage';
import JTOListFetch from '../object/JTOListFetch';
import { Message } from '../object/Message';
import User from '../object/User';
import { SERVER_URL } from '../utils/constants';
import { NAVIGATION_BAR_HEIGHT, STATUS_BAR_HEIGHT } from '../utils/screen';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import {
    closeAction,
    isCloseToBottom,
    openAction,
    useKeyboardVisible,
    width,
} from '../utils/utils';
import { MessageView } from '../component/message/MessageView';
import { Header } from '../component/Header';
import ChatGroup from '../object/ChatGroup';
import { UserGroupIcon, UserIcon } from 'react-native-heroicons/solid';
import { HTTPUtils } from 'mp-ts-http';
import { t } from 'i18next';
import { NavigationRouteContext, useIsFocused } from '@react-navigation/native';
import useJTOState from '../utils/jto/useJTOState';

function compareDates5M(date1: string, date2: string) {
    const date1InMs = new Date(date1).getTime();
    const date2InMs = new Date(date2).getTime();

    const diffInMs = date2InMs - date1InMs;

    const fiveMinutesInMs = 5 * 60 * 1000;

    return diffInMs > fiveMinutesInMs;
}

export interface IMessagePageProps {
    navigation: any;
    params: { [key: string]: any };
}

export const MessagePage = (props: IMessagePageProps) => {
    const { navigation } = props;

    const isFocused = useIsFocused();
    const appState = useRef(AppState.currentState);
    const [appStateVisible, setAppStateVisible] = useState(appState.current);

    useEffect(() => {
        const subscription = AppState.addEventListener(
            'change',
            (nextAppState) => {
                if (
                    appState.current.match(/inactive|background/) &&
                    nextAppState === 'active'
                ) {
                    openAction();
                } else {
                    closeAction();
                }

                appState.current = nextAppState;
                setAppStateVisible(appState.current);
            },
        );

        return () => {
            subscription.remove();
        };
    }, []);

    const elemChat = useJTOState(
        (props as any).route.params.user !== undefined
            ? ((props as any).route.params.user as User)
            : ((props as any).route.params.group as ChatGroup),
    );
    const messageList = useJTOState(elemChat.messageList);
    User._currentMessageList = messageList;
    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            elemChat.messageList.reset();
            elemChat.messageList.refetch({
                id: elemChat.getJTOIdentifier(),
            });

            InteractionManager.runAfterInteractions(() => {
                messageList.more({
                    id: elemChat.getJTOIdentifier(),
                });
            });
        });
        return () => {
            InteractionManager.runAfterInteractions(() => {
                elemChat.messageList.reset();
            });
        };
    }, []);

    const elementList = [];

    for (let i = 0; i < messageList.getList().length; i++) {
        const cc = messageList.getList()[i];
        elementList.push(cc);
        if (i !== messageList.getList().length - 1) {
            const after = messageList.getList()[i + 1];
            if (compareDates5M(after.date.get() ?? '', cc.date.get() ?? '')) {
                elementList.push(new Date(cc.date.get() ?? ''));
            }
        }
    }

    if (messageList.isLoading() || !messageList.isComplite()) {
        elementList.push('loading' as any);
    } else {
        elementList.push('top' as any);
    }

    // ADD TO EACH CHANGE OF PAGE

    useEffect(() => {
        if (isFocused) {
            openAction();
        } else {
            closeAction();
        }
    }, [isFocused]);

    return (
        <View
            style={styles.page}
            //behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        >
            <Header
                line={1}
                rounded={true}
                backButton={true}
                navigation={props.navigation}
                title={elemChat.getDisplayName()}
                onBack={closeAction}
                onPresslogo={() => {
                    if (elemChat instanceof User) {
                        navigation.navigate('MenuProfileModal', {
                            user: elemChat,
                        });
                    } else {
                        navigation.navigate('MenuChatGroupModal', {
                            group: elemChat,
                        });
                    }
                }}
                logo={elemChat.avatar.get() ?? ''}
                fallback={
                    elemChat instanceof User ? (
                        <UserIcon
                            color={'white'}
                            size={35}
                        />
                    ) : (
                        <UserGroupIcon
                            color={'white'}
                            size={35}
                        />
                    )
                }
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
            >
                <View
                /*style={{
                        flex: 1,
                        marginTop: -50,
                        backgroundColor: getColor('background'),
                    }}*/
                >
                    <ScrollView
                        style={{
                            flex: 1,
                        }}
                        onScroll={({ nativeEvent }) => {
                            if (isCloseToBottom(nativeEvent)) {
                                messageList.more({
                                    id: elemChat.getJTOIdentifier(),
                                });
                            }
                            Keyboard.dismiss();
                        }}
                        onMomentumScrollEnd={({ nativeEvent }) => {
                            if (isCloseToBottom(nativeEvent)) {
                                messageList.more({
                                    id: elemChat.getJTOIdentifier(),
                                });
                            }
                        }}
                        scrollEventThrottle={1}
                        keyboardDismissMode='on-drag'
                    >
                        {[...elementList].reverse().map((item, index) => {
                            return (
                                <MessageView
                                    elem={elemChat}
                                    type={
                                        User._instance.getCurrentChatType() ===
                                        'User'
                                            ? 'user'
                                            : 'group'
                                    }
                                    item={item}
                                    index={index}
                                    listLength={elementList.length}
                                    navigation={navigation}
                                />
                            );
                        })}
                        {/*<FlatList<Message>
                        // @ts-ignore
                        onScroll={({ nativeEvent }) => {
                            if (isCloseToBottom(nativeEvent)) {
                                messageList.more({
                                    id: elemChat.getJTOIdentifier(),
                                });
                            }
                            Keyboard.dismiss();
                        }}
                        onMomentumScrollEnd={({ nativeEvent }) => {
                            if (isCloseToBottom(nativeEvent)) {
                                messageList.more({
                                    id: elemChat.getJTOIdentifier(),
                                });
                            }
                        }}
                        scrollEventThrottle={1}
                        {
                            /*keyboardDismissMode="on-drag" ...[]
                        }
                        style={{
                            flex: 1,
                        }}
                        data={elementList}
                        renderItem={({ item, index }) => {
                            return (
                                <MessageView
                                    elem={elemChat}
                                    type={
                                        User._instance.getCurrentChatType() ===
                                        'User'
                                            ? 'user'
                                            : 'group'
                                    }
                                    item={item}
                                    index={index}
                                    listLength={elementList.length}
                                    navigation={navigation}
                                />
                            );
                        }}
                        inverted={true}
                    ></FlatList>*/}
                    </ScrollView>
                    <BottomMessagePage
                        messageList={messageList}
                        receiver={elemChat}
                        type={elemChat.getJTOType()}
                        navigation={navigation}
                    />
                </View>
            </Header>
        </View>
    );
};
