import { Image, Pressable, Text, View } from 'react-native';
import React from 'react';
import { UserIcon } from 'react-native-heroicons/solid';
import InviteTiny from '../object/compressed/global/InviteTiny';
import Invite from '../object/Invite';
import User from '../object/User';
import { SERVER_URL } from '../utils/constants';
import { FONT_BOLD, FONT_REGULAR, getColor } from '../utils/theme';
import { GroupView } from './GroupView';
import { PastilleView } from './PastilleView';

interface IInviteViewProps {
    invite: InviteTiny;
    navigation: any;
}

export const InviteView = (props: IInviteViewProps) => {
    const { invite } = props;
    return (
        <Pressable
            style={{
                paddingVertical: 7,
                paddingHorizontal: 20,
                display: 'flex',
                flexDirection: 'row',
                borderBottomWidth: 1,
                borderBottomColor: getColor('border'),
            }}
            onPress={() => {
                props.navigation.push('ProfilePage', {
                    user: invite.user.get()?.toUser() ?? new User(),
                });
            }}
        >
            <View
                style={{
                    marginTop: 5,
                }}
            >
                {(invite.user.get()?.avatar.get() ?? '').length === 0 ? (
                    <PastilleView
                        color={getColor('secondary')}
                        size={50}
                        icon={
                            <UserIcon
                                height={30}
                                width={30}
                                fill={'white'}
                            />
                        }
                    />
                ) : (
                    <Image
                        style={{
                            height: 50,
                            width: 50,
                            borderRadius: 60,
                        }}
                        source={{
                            uri:
                                SERVER_URL +
                                '/image/' +
                                invite.user.get()?.avatar.get(),
                        }}
                    />
                )}
            </View>
            <View
                style={{
                    display: 'flex',
                    marginLeft: 10,
                    flex: 1,
                }}
            >
                <Text
                    style={{
                        color: getColor('text'),
                        fontFamily: FONT_BOLD,
                        fontSize: 20,
                    }}
                >
                    {invite.user.get()?.getDisplayName()}
                </Text>
                {(User._instance.getEvent()?.isShowMail() ?? true) && (
                    <Text
                        style={{
                            color: getColor('hint'),
                            fontFamily: FONT_REGULAR,
                            fontSize: 15,
                        }}
                    >
                        {invite.user.get()?.email.get()}
                    </Text>
                )}
                <View
                    style={{
                        display: 'flex',
                        marginTop: 7,
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                    }}
                >
                    {invite.groupList.map((group) => {
                        return (
                            <GroupView
                                group={group}
                                key={group.getJTOIdentifier()}
                            />
                        );
                    })}
                </View>
            </View>
        </Pressable>
    );
};
