import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

export default class EventType extends JTOObject {
    public static _TYPE: string = 'EventType';

    public name: JTOProperty<string>;
    public key: JTOProperty<string>;
    public color: JTOProperty<string>;

    constructor(parent?: JTOElement | null) {
        super(EventType._TYPE, parent);

        this.name = new JTOProperty('name', this);
        this.key = new JTOProperty('key', this);
        this.color = new JTOProperty('color', this);
    }
}
