import { applyResultBlockedNoOrder, applyResultNoOrder } from '../applyResult';
import JTOContainer from './JTOContainer';
import JTOElement from './JTOElement';
import JTOObject from './JTOObject';

/**
 * JTOInstance is the class that give you the
 * ability to customize how process JTO package
 */
export default class JTOInstance {
    /**
     * Global instance, for all class
     * @type {JTOInstance}
     */
    private static instance: JTOInstance;

    /**
     * Variable that contains all JTOObject, that can
     * created from json source
     * @type {JTOObject[]}
     */
    private list: (new (parent?: JTOElement | null) => JTOObject)[];

    /**
     * Variable that contains all JTOContainer, that be hooked
     * @type {JTOObject[]}
     */
    private containerList: JTOContainer[];

    /**
     * CATCH CHANGE IN LIST TO PREVENT MULTIPLE CHANGE
     */
    private orderList: (() => void)[] = [];

    /**
     * Variable identifierField contains the field
     * where the identifier is stored in json source
     * @type {string}
     */
    private identifierField: string;

    /**
     * Variable typeField contains the field
     * where the type is stored in json source
     * @type {string}
     */
    private typeField: string;

    /**
     * Variable deleteField contains the field
     * where the deleted information is stored in json source
     * @type {string}
     */
    private deleteField: string;

    /**
     * Variable deleteField contains the field
     * where the deleted relation information is stored in json source
     * @type {string}
     */
    private deleteRelationField: string;

    /**
     * Variable deepEqual inform the JTOInstance if
     * on how to compare JTOObject only with identifier
     * or with identifier and type
     * @type {boolean}
     */
    private deepEqual: boolean;
    public verbose: boolean;

    /**
     * Constructor of JTOInstance
     * Define all default value of JTOInstance
     */
    private constructor() {
        this.list = [];
        this.containerList = [];
        this.identifierField = '_id';
        this.typeField = '_type';
        this.deleteRelationField = '_rel_delete';
        this.deleteField = '_delete';
        this.deepEqual = true;
        this.verbose = false;
    }

    /**
     * Get the number of JTOObject in JTOInstance
     * @returns {number}
     */
    public static size(): number {
        return JTOInstance.list().length;
    }

    public static makeUpdate() {
        for (const container of JTOInstance.containerList()) {
            container.elem.notifyViewNow();
        }
    }

    /**
     * Get the global JTOInstance
     * @returns {JTOInstance}
     */
    public static get(): JTOInstance {
        // if null create JTOInstance
        if (JTOInstance.instance == null) {
            JTOInstance.instance = new JTOInstance();
        }
        return JTOInstance.instance;
    }

    /**
     * Change the identifierField of the global JTOInstance
     * @param identifierField the new identifierField
     */
    public static setIdentifierField(identifierField: string) {
        JTOInstance.get().identifierField = identifierField;
    }

    /**
     * Change the typeField of the global JTOInstance
     * @param typeField the new typeField
     */
    public static setTypeField(typeField: string) {
        JTOInstance.get().typeField = typeField;
    }

    /**
     * Change the deleteField of the global JTOInstance
     * @param deleteField the new deleteField
     */
    public static setDeleteField(deleteField: string) {
        JTOInstance.get().deleteField = deleteField;
    }

    /**
     * Change the deleteRelationField of the global JTOInstance
     * @param deleteRelationField the new deleteRelationField
     */
    public static setDeleteRelationField(deleteRelationField: string) {
        JTOInstance.get().deleteRelationField = deleteRelationField;
    }

    /**
     * Change the deleteField of the global JTOInstance
     * @param deleteField the new deleteField
     */
    public static setDeepEqual(deepEqual: boolean) {
        JTOInstance.get().deepEqual = deepEqual;
    }
    /**
     * Get the identifierField of global JTOInstance
     * @returns {string} the identifierField
     */
    public static getIdentifierField(): string {
        return JTOInstance.get().identifierField;
    }

    /**
     * Get the typeField of global JTOInstance
     * @returns {string} the typeField
     */
    public static getTypeField(): string {
        return JTOInstance.get().typeField;
    }

    /**
     * Get the deleteField of global JTOInstance
     * @returns {string} the deleteField
     */
    public static getDeleteField(): string {
        return JTOInstance.get().deleteField;
    }

    /**
     * Get the deleteRelationField of global JTOInstance
     * @returns {string} the deleteField
     */
    public static getDeleteRelationField(): string {
        return JTOInstance.get().deleteRelationField;
    }

    /**
     * Get the deepEqual of global JTOInstance
     * @returns {boolean} the deepEqual
     */
    public static isDeepEqual(): boolean {
        return JTOInstance.get().deepEqual;
    }

    /**
     * Add a JTOObject to the global JTOInstance
     * @returns {JTOObject} the JTOObject
     */
    public static add(object: new (parent?: JTOElement | null) => JTOObject) {
        if (!JTOInstance.have(object)) {
            JTOInstance.list().push(object);
        }
    }

    /**
     * Get the list of global JTOInstance
     * @returns {JTOObject[]} the list
     */
    private static list() {
        return JTOInstance.get().list;
    }

    /**
     * Add a JTOObject to the global JTOContainer
     * @returns {JTOObject} the JTOObject
     */
    public static removeContainer(id: string) {
        JTOInstance.get().containerList = JTOInstance.containerList().filter(
            (elem) => elem.id !== id,
        );
    }

    public static removeContainerObj(object: JTOContainer) {
        JTOInstance.get().containerList =
            JTOInstance.get().containerList.filter((elem) => elem !== object);
    }

    /**
     * Add a JTOContainer to the global JTOInstance
     * @returns {JTOContainer} the JTOContainer
     */
    public static addContainer(object: JTOContainer) {
        if (!JTOInstance.containerList().find((elem) => object === elem)) {
            JTOInstance.containerList().push(object);
        }
    }

    /**
     * Add a callback to the global JTCInstance
     */
    public static addOrder(func: () => void) {
        if (!JTOInstance.orderList().find((elem) => func === elem)) {
            JTOInstance.orderList().push(func);
        }
    }

    private static containerList(): JTOContainer[] {
        return JTOInstance.get().containerList;
    }

    private static orderList(): (() => void)[] {
        return JTOInstance.get().orderList;
    }

    public static resetOrderList() {
        JTOInstance.get().orderList = [];
    }

    public static applyOrderList() {
        for (const func of JTOInstance.orderList()) {
            func();
        }
    }

    /**
     * Check if the JTOObject already exists in JTOInstance
     * @param object the object to check
     * @returns {boolean}
     */
    public static have(
        object: new (parent?: JTOElement | null) => JTOObject,
    ): boolean {
        let found: boolean = false;

        // index of the element in list
        let i: number = 0;

        // list of all JTOObject
        const list = JTOInstance.list();

        while (!found && i < list.length) {
            // check if the object is the same
            found =
                new list[i](null).getJTOType() ===
                new object(null).getJTOType();
            i++;
        }

        // return the result
        return found;
    }

    /**
     * Create a JTOObject from json source
     * @param elem the json source
     */
    public static build(
        elem: { [key: string]: any },
        listStart?: (new (
            parent?: JTOElement | null | undefined,
        ) => JTOObject)[],
    ): JTOObject | null {
        // the element that will be returned
        let res: JTOObject | null = null;

        // index of the element in list
        let i: number = 0;

        // list of all JTOObject
        const list =
            listStart === undefined || listStart.length === 0
                ? JTOInstance.list()
                : listStart;

        while (res == null && i < list.length) {
            // check if the elem is the same as the element in the list
            if (
                new list[i](null).getJTOType() ===
                elem[JTOInstance.getTypeField()]
            ) {
                // create a new JTOObject with the elem in the list
                res = new list[i](null);
            }
            i++;
        }

        // the JTOObject if found
        return res;
    }

    /**
     * Convert the json source to JTOObject instance with data
     * @param json the json source
     */
    public static convert(
        json: { [key: string]: any },
        parent: JTOElement | null = null,
        listStart?: (new (
            parent?: JTOElement | null | undefined,
        ) => JTOObject)[],
    ): JTOObject | null {
        // the variable that contains the result
        let res = null;

        // Create a JTOObject with the json source
        const elem = JTOInstance.build(json, listStart);

        // If elem was found and created
        if (elem != null) {
            // set the data of the JTOObject
            elem.applyData(json);
            elem.setJTOParent(parent);
            res = elem;
        }

        // return the result
        return res;
    }

    public static applyResult(data: any, update: boolean = false) {
        JTOInstance.resetOrderList();
        for (const c of JTOInstance.containerList()) {
            applyResultNoOrder(data, c.elem, update);
        }
        JTOInstance.applyOrderList();
    }

    public static applyBlockResult(data: any, update: boolean = false) {
        JTOInstance.resetOrderList();
        for (const c of JTOInstance.containerList()) {
            applyResultBlockedNoOrder(data, c.elem, update);
        }
        JTOInstance.applyOrderList();
    }

    public static getAllJTOStateList(): JTOElement[] {
        const list: JTOElement[] = [];
        for (const c of JTOInstance.containerList()) {
            list.push(c.elem);
        }
        return list;
    }
}
