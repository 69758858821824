import React from 'react';

import { Linking, Pressable, ScrollView, Text, View } from 'react-native';
import User from '../object/User';

import { styles } from '../utils/styles';
import { useEffect, useState } from 'react';
import { HTTPUtils } from 'mp-ts-http';
import { getErrorMessage } from 'mp-ts-error';
import Event from '../object/Event';
import { Image } from 'react-native';
import {
    FONT_BOLD,
    FONT_MEDIUM,
    FONT_REGULAR,
    getColor,
    HEART_COLOR,
} from '../utils/theme';
import { EventTopPresentation } from '../component/EventTopPresentation';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import {
    CalendarDaysIcon,
    ChevronRightIcon,
    EnvelopeIcon,
    HeartIcon,
    MapPinIcon,
    PhoneIcon,
    SparklesIcon,
    UserIcon,
} from 'react-native-heroicons/solid';
import { TabNavigation } from '../component/TabNavigation';
import { Header } from '../component/Header';
import { t } from 'i18next';
import { ContactView } from '../component/ContactView';
import { GeSpinner } from '../component/GeSpinner';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import Contact from '../object/Contact';
import { PastilleView } from '../component/PastilleView';
import { height, width } from '../utils/utils';
import Bonheur from '../svg/Bonheur';
import { getRestore } from '../utils/http';
import useJTOState from '../utils/jto/useJTOState';
import { applyResult } from '../utils/applyResult';
import EventTiny from '../object/compressed/event/EventTiny';
import { CartoucheButton } from '../component/CartoucheButton';
import InterestPointTiny from '../object/compressed/event/InterestPointTiny';
//import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import InterestType from '../object/InterestType';
import openMap from 'react-native-open-maps';
import { MapEventView } from '../component/MapEventView';
import { CustomTextWithLinks } from '../component/CustomTextWithLinks';

export const InterestPage = (props: any) => {
    const [loading, setLoading] = useState(true);

    const event = useJTOState(User._instance.getEvent()!);

    const interestList = useJTOState(User._instance.getEvent()!.interestList);

    const getIconInterest = (interest: InterestType) => {
        switch (interest.key.get()) {
            case 'restaurant':
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='restaurant'
                        size={24}
                        color={'white'}
                />*/}
                    </View>
                );

            case 'cafe':
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='coffee'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );
            case 'magasin':
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='store'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );
            case 'thé':
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='localCofee'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );
            case 'salon':
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='groups'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );
            case 'place':
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='place'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );
            case 'paysage':
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='place'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );
            case 'histoire':
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='autoStories'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );
            case 'politique':
                return (
                    <SparklesIcon
                        color={'white'}
                        style={{
                            width: 24,
                            height: 24,
                        }}
                    />
                );
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='autoStories'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );
            case 'mémoire':
                return (
                    <SparklesIcon
                        color={'white'}
                        style={{
                            width: 24,
                            height: 24,
                        }}
                    />
                );
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='autoStories'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );
            case 'sport':
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='sportsBaseball'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );
            case 'randonnée':
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='directionsWalk'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );

            case 'autre':
                return (
                    <SparklesIcon
                        style={{
                            width: 24,
                            height: 24,
                        }}
                    />
                );

            case 'interet':
                return (
                    <SparklesIcon
                        color={'white'}
                        style={{
                            width: 24,
                            height: 24,
                        }}
                    />
                );

            case 'plage':
                return (
                    <View>
                        {/*
                    <MaterialIcons
                        name='beachAccess'
                        size={24}
                        color={'white'}
                    />*/}
                    </View>
                );
            default:
                // NEVER HAPPEN
                return (
                    <SparklesIcon
                        color={'white'}
                        style={{
                            width: 24,
                            height: 24,
                        }}
                    />
                );

            // car	Transport / Voyage / Transfert / Navette
            // brunch-dining	Dîner
            // glass-cheers	After
            // trophy	Tournoi
            // spa	Détente
            // modern-mic	Discours
            // futbol	Activités sportives
            // palette	Activités créatives
            // laptop	Temps de travail
        }
    };

    useEffect(() => {
        interestList.refetch(
            {
                id: event.getJTOIdentifier(),
            },
            (res) => {
                interestList.setComplite(true);
            },
            (err) => {
                interestList.setComplite(true);
            },
        );
    }, []);

    return (
        <View
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                backButton={true}
                title={t('interest')}
                line={1}
                navigation={props.navigation}
                logo={event.logo.get() ?? ''}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
            >
                {interestList.isLoading() ? (
                    <View
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: NAVIGATION_BAR_HEIGHT + TAB_HEIGHT,
                            justifyContent: 'center',
                        }}
                    >
                        <GeSpinner color={getColor('primary')} />
                    </View>
                ) : interestList.getList().length === 0 ? (
                    <View
                        style={{
                            flex: 1,
                            zIndex: 500,
                        }}
                        // @ts-ignore
                        showsHorizontalScrollIndicator={false}
                        // @ts-ignore
                        showsVerticalScrollIndicator={false}
                    >
                        <View
                            style={{
                                height: 30,
                            }}
                        ></View>

                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingHorizontal: 20,
                                height:
                                    height() -
                                    TAB_HEIGHT -
                                    STATUS_BAR_HEIGHT -
                                    NAVIGATION_BAR_HEIGHT -
                                    150,
                                backgroundColor: getColor('background'),
                            }}
                        >
                            <Text
                                style={{
                                    color: getColor('text'),
                                    fontSize: 16,
                                    fontFamily: FONT_REGULAR,
                                    textAlign: 'center',
                                }}
                            >
                                {t('no_interest')}
                            </Text>
                        </View>
                    </View>
                ) : (
                    <View
                        style={{
                            flex: 1,
                            zIndex: 500,
                        }}
                        // @ts-ignore
                        showsHorizontalScrollIndicator={false}
                        // @ts-ignore
                        showsVerticalScrollIndicator={false}
                    >
                        <View
                            style={{
                                height: 30,
                            }}
                        ></View>

                        {interestList.getList().length > 0 && (
                            <View
                                style={{
                                    marginHorizontal: 20,
                                    marginBottom: 10,
                                }}
                            >
                                <View>
                                    {interestList
                                        .getList()
                                        .map((interest, index) => {
                                            return (
                                                <Pressable
                                                    onPress={() => {
                                                        props.navigation.push(
                                                            'SubEventModal',
                                                            {
                                                                event: interest.toEvent(),
                                                            },
                                                        );
                                                    }}
                                                    key={interest.getJTOIdentifier()}
                                                    style={{
                                                        borderRadius: 25,
                                                        marginBottom: 20,
                                                        backgroundColor:
                                                            'white',
                                                        shadowColor:
                                                            getColor('hint'),
                                                        shadowOffset: {
                                                            width: 0,
                                                            height: 2,
                                                        },
                                                        shadowOpacity: 0.2,
                                                        shadowRadius: 3.84,
                                                        elevation: 5,
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            borderRadius: 24,
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <View
                                                            style={{
                                                                backgroundColor:
                                                                    getColor(
                                                                        'primary',
                                                                    ),
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                                padding: 10,
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <View
                                                                style={{
                                                                    height: 30,
                                                                    width: 30,
                                                                    backgroundColor:
                                                                        'white',
                                                                    borderRadius: 15,
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    overflow:
                                                                        'hidden',
                                                                }}
                                                            >
                                                                <Text
                                                                    style={{
                                                                        color: getColor(
                                                                            'primary',
                                                                        ),
                                                                        fontSize: 18,
                                                                        fontFamily:
                                                                            FONT_BOLD,
                                                                    }}
                                                                >
                                                                    {index + 1}
                                                                </Text>
                                                            </View>
                                                            <Text
                                                                style={{
                                                                    flex: 1,
                                                                    color: 'white',
                                                                    marginLeft: 10,
                                                                    fontSize: 20,
                                                                    fontFamily:
                                                                        FONT_BOLD,
                                                                }}
                                                            >
                                                                {interest.name.get()}
                                                            </Text>
                                                            {interest.is_favorite.get() ===
                                                                true && (
                                                                <View>
                                                                    <HeartIcon
                                                                        height={
                                                                            30
                                                                        }
                                                                        width={
                                                                            30
                                                                        }
                                                                        color={
                                                                            HEART_COLOR
                                                                        }
                                                                    />
                                                                </View>
                                                            )}
                                                        </View>
                                                        <View
                                                            style={{
                                                                display: 'flex',
                                                                padding: 10,
                                                            }}
                                                        >
                                                            {(interest.description.get() ??
                                                                '') !== '' && (
                                                                <CustomTextWithLinks
                                                                    content={
                                                                        interest.description.get() ??
                                                                        ''
                                                                    }
                                                                    style={{
                                                                        color: getColor(
                                                                            'text',
                                                                        ),
                                                                        fontSize: 15,
                                                                        fontFamily:
                                                                            FONT_REGULAR,
                                                                    }}
                                                                    globalStyle={{
                                                                        flex: 1,
                                                                    }}
                                                                />
                                                            )}
                                                            {
                                                                <MapEventView
                                                                    top={10}
                                                                    event={
                                                                        interest
                                                                    }
                                                                />
                                                            }
                                                            <View
                                                                style={{
                                                                    marginTop: 10,
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'space-between',
                                                                }}
                                                            >
                                                                <View
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                        justifyContent:
                                                                            'center',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <Text
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color: getColor(
                                                                                'secondary',
                                                                            ),
                                                                            fontFamily:
                                                                                FONT_REGULAR,
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            'open',
                                                                        )}
                                                                    </Text>
                                                                    <ChevronRightIcon
                                                                        color={getColor(
                                                                            'secondary',
                                                                        )}
                                                                    />
                                                                </View>

                                                                <View
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                        paddingStart: 10,
                                                                        paddingEnd: 14,
                                                                        borderRadius: 45,
                                                                        alignItems:
                                                                            'center',
                                                                        justifyContent:
                                                                            'center',
                                                                        paddingVertical: 6,
                                                                        backgroundColor:
                                                                            getColor(
                                                                                'secondary',
                                                                            ),
                                                                    }}
                                                                >
                                                                    {getIconInterest(
                                                                        interest.interestType.get() ??
                                                                            new InterestType(),
                                                                    )}
                                                                    <Text
                                                                        style={{
                                                                            color: 'white',
                                                                            fontSize: 15,
                                                                            marginLeft: 5,
                                                                            fontFamily:
                                                                                FONT_BOLD,
                                                                        }}
                                                                    >
                                                                        {interest.eventTypeAlias.get() ??
                                                                            t(
                                                                                interest.interestType
                                                                                    .get()
                                                                                    ?.key.get() ??
                                                                                    '',
                                                                            ) ??
                                                                            'Boutique'}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </Pressable>
                                            );
                                        })}
                                </View>
                            </View>
                        )}

                        <View
                            style={{
                                height: STATUS_BAR_HEIGHT,
                            }}
                        />
                    </View>
                )}
            </Header>
        </View>
    );
};
