// tslint:disable

import React, { Component, ReactNode } from 'react';
import { Dimensions, View } from 'react-native';
import { HEADER_BONUS_HEIGHT, STATUS_BAR_HEIGHT } from '../utils/screen';
import Fireworks from './Fireworks';

export default class SucccessAnimation extends Component {
    fireworkRef1: React.RefObject<Fireworks>;
    fireworkRef2: React.RefObject<Fireworks>;
    fireworkRef3: React.RefObject<Fireworks>;

    constructor(props: any) {
        super(props);

        this.fireworkRef1 = React.createRef();
        this.fireworkRef2 = React.createRef();
        this.fireworkRef3 = React.createRef();
    }

    start() {
        this.fireworkRef1.current?.start(1);
        this.fireworkRef2.current?.start(1);
        this.fireworkRef3.current?.start(1);
    }
    startSuper() {
        this.fireworkRef1.current?.start(6);
        this.fireworkRef2.current?.start(5);
        this.fireworkRef3.current?.start(4);
    }

    render(): ReactNode {
        return (
            <View
                style={{
                    position: 'absolute',
                    top: -STATUS_BAR_HEIGHT - HEADER_BONUS_HEIGHT,
                    left: 0,
                }}
            >
                <Fireworks
                    pause={true}
                    width={Dimensions.get('screen').height * 0.3}
                    height={Dimensions.get('screen').height * 0.3}
                    minConf={20}
                    maxConf={50}
                    timediff={0}
                    duration={1000}
                    count={1}
                    ref={this.fireworkRef1}
                />

                <Fireworks
                    pause={true}
                    width={Dimensions.get('screen').height * 0.5}
                    height={Dimensions.get('screen').height * 0.5}
                    minConf={10}
                    maxConf={50}
                    timediff={0}
                    duration={2000}
                    count={1}
                    ref={this.fireworkRef2}
                />

                <Fireworks
                    pause={true}
                    width={Dimensions.get('screen').height * 0.8}
                    height={Dimensions.get('screen').height * 0.8}
                    minConf={30}
                    maxConf={80}
                    timediff={0}
                    duration={1800}
                    count={1}
                    ref={this.fireworkRef3}
                />
            </View>
        );
    }
}
