import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

import {
    ArrowLeftOnRectangleIcon,
    ArrowRightOnRectangleIcon,
    BellIcon,
    CalendarDaysIcon,
    ChatBubbleOvalLeftIcon,
    ClockIcon,
    NewspaperIcon,
    UserGroupIcon,
    UserPlusIcon,
} from 'react-native-heroicons/solid';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
import { getDisplayDateUtils } from '../utils/utils';
import ChatGroup from './ChatGroup';
import Event from './Event';
import Group from './Group';
import Post from './Post';
import User from './User';
import { getColor } from '../utils/theme';
import { styles } from '../utils/styles';

export default class NewHistory extends JTOObject {
    public static _TYPE: string = 'NewHistory';

    public date: JTOProperty<string>;
    public title: JTOProperty<string>;
    public content: JTOProperty<string>;
    public key: JTOProperty<string>;
    public type: JTOProperty<string>;
    public action: JTOProperty<string>;
    public event: JTOProperty<string>;

    constructor(parent?: JTOElement | null) {
        super(NewHistory._TYPE, parent);

        this.date = new JTOProperty<string>('date', this);
        this.title = new JTOProperty<string>('title', this);
        this.content = new JTOProperty<string>('content', this);
        this.key = new JTOProperty<string>('key', this);
        this.type = new JTOProperty<string>('type', this);
        this.action = new JTOProperty<string>('action', this);
        this.event = new JTOProperty<string>('event', this);
    }

    getViewIcon() {
        if (this.key.get() === 'notif') {
            return (
                <BellIcon
                    height={25}
                    width={25}
                    color={'white'}
                />
            );
        } else if (this.key.get() === 'event') {
            return (
                <ClockIcon
                    height={25}
                    width={25}
                    color={'white'}
                />
            );
        } else if (this.key.get() === 'post') {
            return (
                <NewspaperIcon
                    height={25}
                    width={25}
                    color={'white'}
                />
            );
        }
        return (
            <BellIcon
                height={25}
                width={25}
                color={'white'}
            />
        );
    }

    getDisplayDate(t: (...args: any) => string): string {
        return getDisplayDateUtils(t, this.date.get() ?? '');
    }
}
