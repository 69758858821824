import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function SvgLoading4(props: any) {
    return (
        <svg
            viewBox='0 0 4668.000000 2326.000000'
            {...props}
        >
            <path
                d='M17489 20452c-2346-1538-4377-2871-4515-2961l-249-164 80-74c44-42 143-128 220-193 2020-1698 5917-2675 11210-2811 761-20 2380-3 2778 29l87 7 3314 3465c1823 1906 3313 3466 3312 3467s-74-6-162-17c-2222-271-4638-138-7014 386-1639 362-3379 958-4638 1588-83 42-153 76-154 76-2-1-1923-1260-4269-2798zM37945 21349c-279-10-537-26-835-50-243-19-661-63-716-75-24-5-456-564-2685-3473l-2656-3466 786-6c763-6 983-12 1561-44 3052-170 5504-797 7105-1818 498-317 967-732 1160-1025 15-24 31-39 36-35 11 13 4961 7387 4966 7399 5 13-239 238-433 400-1519 1266-3740 2009-6494 2174-494 29-1288 38-1795 19zM3982 11457L10 8755l47-44c144-133 736-590 1054-813 1756-1233 3888-2205 6324-2883 2490-692 5200-1074 8130-1146 660-16 1833 0 2103 29l62 7 2970 3295c1633 1812 2968 3295 2967 3297-2 1-111-4-242-12-285-17-799-40-1270-57-448-16-2060-16-2485 0-1375 52-2431 142-3540 303-3050 442-5568 1350-7270 2623-292 218-610 500-784 695-55 61-105 111-110 110-6-1-1799-1217-3984-2702zM29600 10393c-441-5-1365-44-1446-61-28-6-369-435-2774-3492-1509-1917-2756-3503-2773-3524l-31-39 184 6c662 24 2018 18 2775-13 3650-149 6291-804 7789-1933 173-130 302-245 466-413 264-271 455-559 544-820l31-91 2348 3665 2347 3665-42 76c-318 581-825 1083-1513 1498-1314 792-3336 1284-5885 1433-467 27-1386 53-1700 48-69-2-213-4-320-5z'
                transform='matrix(.1 0 0 -.1 0 2326)'
            />
        </svg>
    );
}

export default SvgLoading4;
