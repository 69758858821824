import {
    View,
    Text,
    Pressable,
    TextInput,
    ActivityIndicator,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import { goBackTo, height, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Keychain from 'react-native-keychain';
import { MpInput } from '../base/MpInput';
import { useState } from 'react';
import User from '../object/User';
import { XMarkIcon } from 'react-native-heroicons/solid';
import { post } from '../utils/http';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import { GeSpinner } from '../component/GeSpinner';
import ChatGroup from '../object/ChatGroup';
import useJTOState from '../utils/jto/useJTOState';
import JTOInstance from '../utils/jto/JTOInstance';

export const GroupNameModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const group = useJTOState((props as any).route.params.group as ChatGroup);

    const [name, setName] = useState(group.name.get() ?? '');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const changeFirstname = () => {
        setLoading(true);
        setError('');
        post('/chat/group/update', {
            id_group: group.getJTOIdentifier(),
            name,
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    JTOInstance.applyResult(HTTPUtils.getResult(res), true);

                    goBackTo(props.navigation, 'MessagePage', 'GroupNameModal');

                    ToastManager.instance.addToast(
                        new Toast(t('group_edit_success'), 5000, 'success'),
                    );
                } else {
                    setError(t(HTTPUtils.getError(res)) ?? '');
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(t('went_wrong_no_internet') ?? '');
            });
    };

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('change_name')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginHorizontal: 20,
                    }}
                >
                    {loading ? (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    height: height() * 0.5,
                                },
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View>
                            <View
                                style={{
                                    marginTop: 15,
                                    marginBottom: 15,
                                }}
                            >
                                <Text
                                    style={[
                                        styles.text,
                                        {
                                            marginBottom: 5,
                                        },
                                    ]}
                                >
                                    {t('name')}
                                </Text>
                                <MpInput
                                    style={styles.inputOutilined}
                                    placeholder={t('name') ?? ''}
                                    value={name}
                                    defaultValue={name}
                                    onChangeText={(text) => {
                                        setName(text);
                                    }}
                                />
                            </View>

                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.textError,
                                        {
                                            marginTop: 10,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}

                            <View
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <CartoucheButton
                                    onPress={() => {
                                        changeFirstname();
                                    }}
                                    title={t('change')}
                                    height={80}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
