import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

//import Keychain from 'react-native-keychain';
import {
    APP_PRIMARY_COLOR,
    APP_SECONDARY_COLOR,
    getTheme,
} from '../utils/theme';
import Invite from './Invite';
import Event from './Event';
import { post, resumeJob } from '../utils/http';
import { generateStyle, initStyles } from '../utils/styles';
import JTOListFetch from './JTOListFetch';
import { formatRemainTime } from '../utils/utils';
import { Message } from './Message';
import ChatGroup from './ChatGroup';
import History from './History';
import { MessageTiny } from './compressed/chat/MessageTiny';
import ChatGroupTiny from './compressed/chat/ChatGroupTiny';
import UserChat from './compressed/chat/UserChat';
import UserConnect from './compressed/chat/UserConnect';
//import { storage } from '../../App';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Badge } from './Badge';
import SocketInstance from '../utils/SocketInstance';
import { applyResult } from '../utils/applyResult';
import UserTiny from './compressed/global/UserTiny';
import NewHistory from './NewHistory';

export default class User extends JTOObject {
    public static _TYPE: string = 'User';

    public static _instance: User = new User();
    public static _badgeList = new JTOListFetch(
        '/badge/list',
        Badge,
        '',
        undefined,
        (a: Badge, b: Badge) => {
            if (a.name.get() === b.name.get()) {
                return (a.level.get() ?? 0) - (b.level.get() ?? 0);
            } else {
                return (a.name.get() ?? '').localeCompare(b.name.get() ?? '');
            }
        },
    );
    public static _instanceChat: UserChat = new UserChat();
    public static _instanceConnect: UserConnect = new UserConnect();

    public static _navigation: any;
    public static _email: string = '';
    public static _code: string = '';
    public static _password: string = '';
    public static _search: string = '';
    public static _currentMessageList: JTOListFetch<Message>;

    public static _fileList: File[] = [];
    public static _destFile: string = '';

    public realTimeOpen: string[];
    public realTimeGranted: string[];

    public firstname: JTOProperty<string>;
    public lastname: JTOProperty<string>;
    public description: JTOProperty<string>;
    public email: JTOProperty<string>;
    public passwordInit: JTOProperty<boolean>;
    public root: JTOProperty<boolean>;
    public avatar: JTOProperty<string>;
    public xp: JTOProperty<number>;

    public inviteList: JTOList<Invite>;

    public linkedin: JTOProperty<string>;
    public facebook: JTOProperty<string>;
    public instagram: JTOProperty<string>;
    public twitter: JTOProperty<string>;
    public youtube: JTOProperty<string>;

    public connectConnect: JTORelProperty<boolean>;
    public connectDate: JTORelProperty<string>;

    public currentInvite: JTOSimple<Invite>;
    public messageList: JTOListFetch<Message>;
    public nbNewMessage: JTOProperty<number>;

    public useFeedback: JTOProperty<boolean>;
    public useRank: JTOProperty<boolean>;
    public showEvent: JTOProperty<boolean>;
    public showProfile: JTOProperty<boolean>;
    public useMessage: JTOProperty<boolean>;
    public useNotif: JTOProperty<boolean>;
    public useMail: JTOProperty<boolean>;
    public showGroup: JTOProperty<boolean>;

    public post = new JTOProperty<number>('post', this);
    public react = new JTOProperty<number>('react', this);
    private _nothing = new JTOProperty<boolean>('nothing', this);
    public message = new JTOProperty<number>('message', this);
    public openEvent = new JTOProperty<number>('open_event', this);
    public openApp = new JTOProperty<number>('open_app', this);
    public retroActivite = new JTOProperty<number>('retro_activite', this);
    public file = new JTOProperty<number>('file', this);
    public response = new JTOProperty<number>('response', this);
    public inscripEvent = new JTOProperty<number>('inscrip_event', this);

    public generalNewMessage: JTOProperty<number>;

    public auth: boolean = false;
    public event?: string;
    public token?: string;

    public blockList: JTOListFetch<UserTiny>;
    public blockMe: JTOSimple<UserTiny>;

    public currentChatId: string;
    public currentChatType: string;

    public historyList: JTOListFetch<NewHistory>;

    public visitCard: JTOProperty<string>;

    public static translation: (name: string) => string | undefined = () => '';

    public static rootUpdate: () => void = () => {
        // do nothing
    };

    constructor(parent?: JTOElement | null) {
        super(User._TYPE, parent);

        this.firstname = new JTOProperty<string>('firstname', this);
        this.lastname = new JTOProperty<string>('lastname', this);
        this.description = new JTOProperty<string>('description', this);
        this.email = new JTOProperty<string>('email', this);
        this.root = new JTOProperty<boolean>('root', this);
        this.passwordInit = new JTOProperty<boolean>('password_init', this);

        // list des rooms où l'utilisateur est connecté
        this.realTimeOpen = [];

        // list des rooms où le backoffice est également connecté
        this.realTimeGranted = [];

        this.inviteList = new JTOList<Invite>(Invite, 'be_part', this);

        this.currentInvite = new JTOSimple<Invite>(Invite, 'invite', this);
        this.avatar = new JTOProperty<string>('avatar', this);
        this.visitCard = new JTOProperty<string>('visit_card', this);

        this.showProfile = new JTOProperty<boolean>('show_profile', this);
        this.showEvent = new JTOProperty<boolean>('show_event', this);
        this.showGroup = new JTOProperty<boolean>('show_group', this);
        this.useFeedback = new JTOProperty<boolean>('use_feedback', this);
        this.useRank = new JTOProperty<boolean>('use_rank', this);
        this.useMessage = new JTOProperty<boolean>('use_message', this);
        this.useNotif = new JTOProperty<boolean>('use_notif', this);
        this.useMail = new JTOProperty<boolean>('use_mail', this);
        this.xp = new JTOProperty<number>('xp', this);

        this.linkedin = new JTOProperty<string>('linkedin', this);
        this.facebook = new JTOProperty<string>('facebook', this);
        this.instagram = new JTOProperty<string>('instagram', this);
        this.twitter = new JTOProperty<string>('twitter', this);
        this.youtube = new JTOProperty<string>('youtube', this);
        this.blockMe = new JTOSimple(UserTiny, 'block', this);
        this.nbNewMessage = new JTOProperty<number>(
            'nb_new_message',
            this,
            false,
        );

        this.generalNewMessage = new JTOProperty<number>(
            'new_message',
            this,
            false,
        );

        this.linkedin = new JTOProperty<string>('linkedin', this);
        this.facebook = new JTOProperty<string>('facebook', this);
        this.instagram = new JTOProperty<string>('instagram', this);
        this.twitter = new JTOProperty<string>('twitter', this);
        this.messageList = new JTOListFetch(
            '/message/list',
            Message,
            ['send', 'receive'],
            this,
            (a: Message, b: Message) => {
                const dateA = new Date(a.date.get() ?? '');
                const dateB = new Date(b.date.get() ?? '');
                // ORDER BY date DESC
                return dateA.getTime() > dateB.getTime() ? -1 : 1;
            },
        );

        this.blockList = new JTOListFetch<UserTiny>(
            '/user/blocklist',
            UserTiny,
            'block',
            this,
        );

        this.currentChatId = '';
        this.currentChatType = '';

        this.historyList = new JTOListFetch(
            '/history/list2',
            NewHistory,
            'user_new_history',
            this,
            (a: NewHistory, b: NewHistory) => {
                const dateA = new Date(a.date.get() ?? '');
                const dateB = new Date(b.date.get() ?? '');
                // ORDER BY date DESC
                return dateA.getTime() > dateB.getTime() ? -1 : 1;
            },
        );
        this.connectConnect = new JTORelProperty<boolean>(
            ['connect.connect'],
            this,
            false,
        );
        this.connectDate = new JTORelProperty<string>(
            ['connect.date'],
            this,
            false,
        );
    }

    public getCurrentChatId(): string {
        return this.currentChatId;
    }

    public setCurrentChatId(id: string): void {
        this.currentChatId = id;
    }

    public getCurrentChatType(): string {
        return this.currentChatType;
    }

    public setCurrentChatType(type: string): void {
        this.currentChatType = type;
    }

    public isAuth(): boolean {
        return this.auth;
    }

    public setAuth(auth: boolean): void {
        this.auth = auth;
    }

    public getEvent(): Event | null {
        let event: Event | null = null;
        let i = 0;
        while (event === null && i < this.inviteList.size()) {
            if (
                this.inviteList.get(i).event.get()?.getJTOIdentifier() + '' ===
                this.event + ''
            ) {
                event = this.inviteList.get(i).event.get();
            }
            i++;
        }
        return event;
    }

    public addRealTimeOpen(idList: string[]): void {
        this.realTimeOpen = this.realTimeOpen.concat(idList);
    }

    public removeRealTimeOpen(idList: string[]): void {
        this.realTimeOpen = this.realTimeOpen.filter(
            (id) => idList.indexOf(id) === -1,
        );
    }

    public addRealTimeGranted(idList: string[]): void {
        this.realTimeGranted = this.realTimeGranted.concat(idList);
    }

    public removeRealTimeGranted(idList: string[]): void {
        this.realTimeGranted = this.realTimeGranted.filter(
            (id) => idList.indexOf(id) === -1,
        );
    }

    public login(res: { [key: string]: any }, reset: boolean = true): void {
        if (reset) {
            this.inviteList.setList([]);
            this.passwordInit.set(false);
            this.email.set('');
            this.firstname.set('');
            this.lastname.set('');
        }

        this.root.set(false);
        this.setAuth(true);

        if (res.accessToken !== undefined) {
            localStorage.setItem('happlyevent.access_token', res.accessToken);
            SocketInstance.get().open();
        }
        if (res.refreshToken !== undefined) {
            localStorage.setItem('happlyevent.refresh_token', res.refreshToken);
        }
        if (res.refreshToken !== undefined && res.accessToken !== undefined) {
            resumeJob();
        }
        applyResult(res, this, true);

        delete res.accessToken;
        delete res.refreshToken;
        localStorage.setItem('user', JSON.stringify(res));

        User._instanceConnect.connectList.reset();
        User._instanceConnect.setJTOIdentifier(this.getJTOIdentifier());

        User._instanceChat.chatList.reset();
        User._instanceChat.setJTOIdentifier(this.getJTOIdentifier());

        if (this.token !== undefined && this.token !== '') {
            post('/notif/update', {
                token: this.token,
                id_user: this.getJTOIdentifier(),
            })
                .then((response) => {
                    // TODO
                })
                .catch((err) => {
                    // TODO
                });
        }
    }

    public hasNetwork() {
        return (
            this.hasFacebook() ||
            this.hasTwitter() ||
            this.hasLinkedin() ||
            this.hasInstagram()
        );
    }

    public loadEvent() {
        initStyles();

        if (this.getEvent()?.getJTOIdentifier()) {
            AsyncStorage.setItem(
                'event',
                this.getEvent()?.getJTOIdentifier() + '',
            );

            getTheme().colors.primary =
                this.getEvent()?.primaryColor.get() ??
                getTheme().colors.primary;

            (getTheme().colors as any).primaryHint =
                this.getEvent()?.primaryColor.get() ??
                (getTheme().colors as any).primaryHint;

            (getTheme().colors as any).secondary =
                this.getEvent()?.secondaryColor.get() ??
                (getTheme().colors as any).secondary;

            (getTheme().colors as any).error =
                this.getEvent()?.errorColor.get() ??
                (getTheme().colors as any).error;

            (getTheme().colors as any).success =
                this.getEvent()?.successColor.get() ??
                (getTheme().colors as any).success;

            (getTheme().colors as any).warning =
                this.getEvent()?.warningColor.get() ??
                (getTheme().colors as any).warning;

            (getTheme().colors as any).card =
                this.getEvent()?.cardColor.get() ??
                (getTheme().colors as any).card;

            (getTheme().colors as any).info =
                this.getEvent()?.infoColor.get() ??
                (getTheme().colors as any).info;

            (getTheme().colors as any).border =
                this.getEvent()?.borderColor.get() ??
                (getTheme().colors as any).border;

            this.getEvent()?.fetchRole();

            generateStyle();
        }
    }

    public getDisplayName(): string {
        return (this.firstname.get() ?? '') + ' ' + (this.lastname.get() ?? '');
    }

    public hasFacebook() {
        return this.facebook.get() !== undefined && this.facebook.get() !== '';
    }

    public hasTwitter() {
        return this.twitter.get() !== undefined && this.twitter.get() !== '';
    }

    public hasLinkedin() {
        return this.linkedin.get() !== undefined && this.linkedin.get() !== '';
    }

    public hasYoutube() {
        return this.youtube.get() !== undefined && this.youtube.get() !== '';
    }

    public hasInstagram() {
        return (
            this.instagram.get() !== undefined && this.instagram.get() !== ''
        );
    }

    public isMe(): boolean {
        return this.getJTOIdentifier() === User._instance.getJTOIdentifier();
    }

    public get nothing(): boolean {
        return this._nothing.get() ?? false;
    }

    public set nothing(nothing: boolean) {
        this._nothing.set(nothing);
    }
}
