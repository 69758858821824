import { View, Text, Pressable, ActivityIndicator } from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import { goBackTo, height, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import { MpInput } from '../base/MpInput';
import { useState } from 'react';
import User from '../object/User';
import { UserIcon, XMarkIcon } from 'react-native-heroicons/solid';
import { post } from '../utils/http';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import DocumentPicker from 'react-native-document-picker';
//import RNFS from 'react-native-fs';
import { ProfilFileElementView } from '../component/ProfilFileElementView';
import { Toast } from '../component/toast/Toast';
import { ToastManager } from '../component/toast/ToastManager';
import { Header } from '../component/Header';
import { GeSpinner } from '../component/GeSpinner';
import { CartoucheButton } from '../component/CartoucheButton';
import { applyResult } from '../utils/applyResult';

export const ProfilFileEditModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const [alias, setAlias] = useState((props as any).route.params.alias);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const updateFile = () => {
        setLoading(true);
        post('/mobile/profil/update', {
            id: (props as any).route.params.id,
            new_name: alias,
        })
            .then((res) => {
                if (HTTPUtils.isSuccess(res)) {
                    applyResult(HTTPUtils.getResult(res), User._instance, true);

                    goBackTo(props.navigation, 'Tab', 'ProfilFileEditModal');

                    ToastManager.instance.addToast(
                        new Toast(t('profile_edit_success'), 5000, 'success'),
                    );
                } else {
                    setError(t(HTTPUtils.getError(res)) ?? '');
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(t('went_wrong_no_internet') ?? '');
            });
    };

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('change_alias')}
                line={1}
                backButton={true}
                navigation={props.navigation}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                rounded={(User._instance.avatar.get() ?? '') !== ''}
                logo={User._instance.avatar.get() ?? ''}
                fallback={
                    <UserIcon
                        color={'white'}
                        size={35}
                    />
                }
            >
                <View
                    style={{
                        marginTop: 15,
                        marginHorizontal: 20,
                    }}
                >
                    {loading ? (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    height: height() * 0.5,
                                },
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View>
                            <View
                                style={{
                                    marginTop: 15,
                                    marginBottom: 15,
                                }}
                            >
                                <Text
                                    style={[
                                        styles.text,
                                        {
                                            marginBottom: 5,
                                        },
                                    ]}
                                >
                                    {t('alias')}
                                </Text>
                                <MpInput
                                    style={styles.inputOutilined}
                                    placeholder={t('alias') ?? ''}
                                    value={alias}
                                    defaultValue={alias}
                                    onChangeText={(text) => {
                                        setAlias(text);
                                    }}
                                />
                            </View>

                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.textError,
                                        {
                                            marginTop: 10,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}

                            <View
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <CartoucheButton
                                    onPress={() => {
                                        updateFile();
                                    }}
                                    title={t('change')}
                                    height={80}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
