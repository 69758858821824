import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Platform,
    Text,
    TouchableOpacity,
    View,
    ScrollView,
    Linking,
} from 'react-native';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import User from '../object/User';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { styles } from '../utils/styles';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { FONT_BOLD, FONT_REGULAR, getColor } from '../utils/theme';
import { getBlob, height, width } from '../utils/utils';
import useJTOState from '../utils/jto/useJTOState';
import { CloseAllButton } from '../component/CloseAllButton';
import { QRCode } from 'react-qrcode-logo';
import { SERVER_URL, SITE_URL } from '../utils/constants';
import { QueryInstance } from 'mp-ts-query';
import React from 'react';

interface IScanBadgePageProps {
    navigation: any;
}

export const ScanBadgePage = (props: IScanBadgePageProps) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const groupList = User._instance
        .getEvent()
        ?.getInvite(User._instance)
        ?.groupList.getList();
    /* const [originalBrightness, setOriginalBrightness] = useState(0);

    // Fonction pour augmenter la luminosité
    const enhanceBrightness = async () => {
        const currentBrightness = await ScreenBrightness.getBrightness();
        setOriginalBrightness(currentBrightness);
        await ScreenBrightness.setBrightness(1.0); // Définit la luminosité à 100%
    };

    // Fonction pour réinitialiser la luminosité
    const resetBrightness = async () => {
        await ScreenBrightness.setBrightness(originalBrightness);
    };

    // Utilisez useEffect pour gérer le cycle de vie du modal
    /*useEffect(() => {
        enhanceBrightness();
        return () => {
            resetBrightness();
        };
    }, []);*/

    const url =
        'http://localhost:5100/invite/qrcode?invite_id=' +
        User._instance
            .getEvent()
            ?.getInvite(User._instance)
            ?.getJTOIdentifier();

    const generateAndDownloadPDF = useCallback(async () => {
        // Checking if the link is supported for links with custom URL scheme.
        const supported = await Linking.canOpenURL(url);

        if (supported) {
            // Opening the link wit h some app, if the URL scheme is "http" the web link should be opened
            // by some browser in the mobile
            await Linking.openURL(url);
        } else {
            console.log("Don't know how to open this URL: " + url);
        }
    }, [url]);

    return (
        <View
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                modal={true}
                title={t('scan_badge')}
                line={1}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
                backButton={true}
                navigation={props.navigation}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
            >
                <ScrollView
                    scrollEventThrottle={1}
                    style={{
                        width: width(),
                        zIndex: 500,
                        marginTop: -40,
                    }}
                >
                    <View
                        style={{
                            height: 40,
                        }}
                    ></View>
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height:
                                height() -
                                STATUS_BAR_HEIGHT -
                                NAVIGATION_BAR_HEIGHT -
                                TAB_HEIGHT -
                                200,
                            flex: 1,
                        }}
                    >
                        <Text
                            style={{
                                color: getColor('text'),
                                fontSize: 16,
                                marginBottom: 20,
                                fontFamily: FONT_REGULAR,
                                textAlign: 'center',
                            }}
                        >
                            {User._instance.firstname.get() ?? ''}{' '}
                            {User._instance.lastname.get() ?? ''}
                        </Text>
                        <Text
                            style={{
                                color: getColor('text'),
                                fontSize: 16,
                                marginBottom: 20,
                                fontFamily: FONT_REGULAR,
                                textAlign: 'center',
                            }}
                        >
                            {groupList?.length !== 0
                                ? groupList![0].name.get()
                                : ''}
                        </Text>
                        <QRCode
                            value={SITE_URL + '/qrcode/'}
                            size={200}
                        />
                    </View>
                    {loading && (
                        <View
                            style={[
                                styles.boxCenter,
                                marginBottom(50),
                                marginTop(200),
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    )}
                    <View
                        style={{
                            height:
                                NAVIGATION_BAR_HEIGHT +
                                10 +
                                STATUS_BAR_HEIGHT +
                                150,
                        }}
                    ></View>
                </ScrollView>
            </Header>
            <TouchableOpacity
                onPress={() => {
                    generateAndDownloadPDF();
                }}
                style={{
                    position: 'absolute',
                    left: 20,
                    borderRadius: 40,
                    bottom: NAVIGATION_BAR_HEIGHT + 20,
                    backgroundColor: getColor('secondary'),
                }}
            >
                <Text
                    numberOfLines={1}
                    adjustsFontSizeToFit
                    style={{
                        fontFamily: FONT_BOLD,
                        color: 'white',
                        paddingHorizontal: 20,
                        paddingVertical: 10,
                        width: '100%',
                        textAlign: 'center',
                        fontSize: 16,
                    }}
                >
                    {t('download_badge')}
                </Text>
            </TouchableOpacity>
            <CloseAllButton
                onPress={() => {
                    props.navigation.popToTop();
                }}
            />
        </View>
    );
};
