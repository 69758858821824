import { Linking, Pressable, Text, View } from 'react-native';
import React from 'react';
//import { ScrollView } from 'react-native-gesture-handler';
import User from '../object/User';
import { getColor } from '../utils/theme';
import { FacebookIcon } from './icon/FacebookIcon';
import { InstagramIcon } from './icon/InstagramIcon';
import { LinkedinIcon } from './icon/LinkedinIcon';
import { TwitterIcon } from './icon/TwitterIcon';
import { PastilleView } from './PastilleView';
//import MaterialIcons from 'react-native-vector-icons/MaterialCommunityIcons';

interface ISocialNetworkViewProps {
    user: User;
}

export const SocialNetworkView = (props: ISocialNetworkViewProps) => {
    const { user } = props;
    return (
        <View
            //horizontal
            style={{
                display: 'flex',
                paddingHorizontal: 12,
                flexDirection: 'row',
            }}
        >
            {user.hasLinkedin() && (
                <Pressable
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginHorizontal: 8,
                    }}
                    onPress={() => {
                        Linking.openURL(user.linkedin.get() as string);
                    }}
                >
                    <PastilleView
                        color={getColor('secondary')}
                        size={45}
                        icon={
                            <LinkedinIcon
                                height={22}
                                width={22}
                                fill={'white'}
                            />
                        }
                    />
                </Pressable>
            )}
            {user.hasFacebook() && (
                <Pressable
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginHorizontal: 8,
                    }}
                    onPress={() => {
                        let url = '';

                        if (
                            (user.facebook.get() as string).includes('https://')
                        ) {
                            url = user.facebook.get() as string;
                        } else {
                            url = ('https://www.' +
                                user.facebook.get()) as string;
                        }

                        Linking.openURL(url);
                    }}
                >
                    <PastilleView
                        color={getColor('secondary')}
                        size={45}
                        icon={
                            <FacebookIcon
                                height={27}
                                width={27}
                                fill={'white'}
                            />
                        }
                    />
                </Pressable>
            )}
            {user.hasInstagram() && (
                <Pressable
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginHorizontal: 8,
                    }}
                    onPress={() => {
                        Linking.openURL(user.instagram.get() as string);
                    }}
                >
                    <PastilleView
                        color={getColor('secondary')}
                        size={45}
                        icon={
                            <InstagramIcon
                                height={27}
                                width={27}
                                fill={'white'}
                            />
                        }
                    />
                </Pressable>
            )}
            {user.hasTwitter() && (
                <Pressable
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginHorizontal: 8,
                    }}
                    onPress={() => {
                        Linking.openURL(user.twitter.get() as string);
                    }}
                >
                    <PastilleView
                        color={getColor('secondary')}
                        size={45}
                        icon={
                            <TwitterIcon
                                height={28}
                                width={28}
                                fill={'white'}
                            />
                        }
                    />
                </Pressable>
            )}
            {user.hasYoutube() && (
                <Pressable
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginHorizontal: 8,
                    }}
                    onPress={() => {
                        Linking.openURL(user.youtube.get() as string);
                    }}
                >
                    <PastilleView
                        color={getColor('secondary')}
                        size={45}
                        icon={
                            <View>
                                {/*<MaterialIcons
                                name='youtube'
                                size={30}
                                color={'white'}
                            />*/}
                            </View>
                        }
                    />
                </Pressable>
            )}
        </View>
    );
};
