import React, { useEffect, useRef, useState } from 'react';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import { get, post } from '../utils/http';
import Event from '../object/Event';
import Invite from '../object/Invite';
import User from '../object/User';
import { styles } from '../utils/styles';
import { FONT_BOLD, getColor } from '../utils/theme';
import { Toast } from './toast/Toast';
import { ToastManager } from './toast/ToastManager';
import SucccessAnimation from './SuccessAnimation';
import { startSuccessAnimation } from '../../App';
import useJTOState from '../utils/jto/useJTOState';
import EventTiny from '../object/compressed/event/EventTiny';
import { useTranslation } from 'react-i18next';
import { mustLoginButton } from '../utils/utils';

// Import SVG files
import Echelle1 from '../svg/Echelle1';
import Echelle2 from '../svg/Echelle2';
import Echelle3 from '../svg/Echelle3';

interface ISondageViewProps {
    event: EventTiny;
    navigation: any;
}

export const SondageView = (props: ISondageViewProps) => {
    const event = useJTOState(props.event);
    const [loading, setLoading] = useState(true);
    const [vote, setVote] = useState('');

    const { t } = useTranslation();

    const sendVote = (currVote: string) => {
        const inviteJson = {
            _id: User._instance?.getEvent()?.getMyInvite()?.getJTOIdentifier(),
            _type: 'Invite',
        };
        const invite = new Invite();
        invite.applyData(inviteJson);

        event.hasVote.set(invite);
        invite.vote.set(currVote);
        setVote(currVote);

        event.notifyViewNow();

        startSuccessAnimation();

        post('/invite/add/event', {
            id_event: event.getJTOIdentifier(),
            vote: currVote,
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    const e = HTTPUtils.getResult(res);

                    event.notifyViewNow();
                } else {
                    ToastManager.instance.addToast(
                        new Toast(
                            t(HTTPUtils.getError(res)) ?? '',
                            5000,
                            'error',
                        ),
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet') ?? '', 5000, 'error'),
                );
            });
    };

    const badSelected = event.hasVote.get()?.vote.get() === '0';
    const averageSelected = event.hasVote.get()?.vote.get() === '1';
    const goodSelected = event.hasVote.get()?.vote.get() === '2';

    return (
        <div
            style={{
                marginTop: 5,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <button
                    style={{
                        padding: 15,
                        paddingBottom: 0,
                        alignItems: 'center',
                        border: 'none',
                        background: 'none',
                    }}
                    onClick={() => {
                        mustLoginButton(props.navigation, t, () => {
                            sendVote('0');
                        });
                    }}
                >
                    <Echelle1
                        width={badSelected ? 65 : 50}
                        height={badSelected ? 65 : 50}
                        fill={badSelected ? getColor('primary') : '#9CA3AF'}
                    />
                    <div
                        style={{
                            backgroundColor: badSelected
                                ? getColor('primary') + '20'
                                : '#E5E7EB',
                            aspectRatio: 1,
                            padding: 2,
                            justifyContent: 'center',
                            marginTop: 15,
                            borderRadius: 5,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                color: badSelected
                                    ? getColor('primary')
                                    : '#9CA3AF',
                                textAlign: 'center',
                                fontSize: badSelected ? 20 : 15,
                                fontFamily: FONT_BOLD,
                            }}
                        >
                            1
                        </span>
                    </div>
                </button>
            </div>

            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <button
                    style={{
                        padding: 15,
                        paddingBottom: 0,
                        alignItems: 'center',
                        border: 'none',
                        background: 'none',
                    }}
                    onClick={() => {
                        mustLoginButton(props.navigation, t, () => {
                            sendVote('1');
                        });
                    }}
                >
                    <Echelle2
                        width={averageSelected ? 65 : 50}
                        height={averageSelected ? 65 : 50}
                        fill={averageSelected ? getColor('primary') : '#9CA3AF'}
                    />
                    <div
                        style={{
                            backgroundColor: averageSelected
                                ? getColor('primary') + '20'
                                : '#E5E7EB',
                            aspectRatio: 1,
                            padding: 2,
                            justifyContent: 'center',
                            marginTop: 15,
                            borderRadius: 5,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                color: averageSelected
                                    ? getColor('primary')
                                    : '#9CA3AF',
                                textAlign: 'center',
                                fontSize: averageSelected ? 20 : 15,
                                fontFamily: FONT_BOLD,
                            }}
                        >
                            2
                        </span>
                    </div>
                </button>
            </div>

            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <button
                    style={{
                        padding: 15,
                        paddingBottom: 0,
                        alignItems: 'center',
                        border: 'none',
                        background: 'none',
                    }}
                    onClick={() => {
                        mustLoginButton(props.navigation, t, () => {
                            sendVote('2');
                        });
                    }}
                >
                    <Echelle3
                        width={goodSelected ? 65 : 50}
                        height={goodSelected ? 65 : 50}
                        fill={goodSelected ? getColor('primary') : '#9CA3AF'}
                    />
                    <div
                        style={{
                            backgroundColor: goodSelected
                                ? getColor('primary') + '20'
                                : '#E5E7EB',
                            aspectRatio: 1,
                            padding: 2,
                            justifyContent: 'center',
                            marginTop: 15,
                            borderRadius: 5,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                color: goodSelected
                                    ? getColor('primary')
                                    : '#9CA3AF',
                                textAlign: 'center',
                                fontSize: goodSelected ? 20 : 15,
                                fontFamily: FONT_BOLD,
                            }}
                        >
                            3
                        </span>
                    </div>
                </button>
            </div>
        </div>
    );
};
