export const marginBottom = (value: number) => {
    return {
        marginBottom: value,
    };
};
export const marginTop = (value: number) => {
    return {
        marginTop: value,
    };
};
