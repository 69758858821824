import React, { Component, ReactNode } from 'react';
import { Dimensions, View } from 'react-native';
import Fireworks from './Fireworks';

export default class SuperSucccessAnimation extends Component {
    fireworkRef1: React.RefObject<Fireworks>;
    fireworkRef2: React.RefObject<Fireworks>;
    fireworkRef3: React.RefObject<Fireworks>;

    constructor(props: any) {
        super(props);

        this.fireworkRef1 = React.createRef();
        this.fireworkRef2 = React.createRef();
        this.fireworkRef3 = React.createRef();
    }

    start() {
        this.fireworkRef1.current?.start(1);
        this.fireworkRef2.current?.start(1);
        this.fireworkRef3.current?.start(1);
    }
    startSuper() {
        this.fireworkRef1.current?.start(4);
        this.fireworkRef2.current?.start(4);
        this.fireworkRef3.current?.start(3);
    }

    render(): ReactNode {
        return (
            <View
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
            >
                <Fireworks
                    pause={true}
                    key={1}
                    ref={this.fireworkRef1}
                    width={Dimensions.get('screen').height * 0.8}
                    height={Dimensions.get('screen').height * 0.8}
                    minConf={50}
                    maxConf={100}
                    timediff={0}
                    duration={2000}
                    count={1}
                />
                <Fireworks
                    pause={true}
                    key={4}
                    ref={this.fireworkRef2}
                    width={Dimensions.get('screen').height * 0.3}
                    height={Dimensions.get('screen').height * 0.3}
                    minConf={20}
                    maxConf={40}
                    timediff={1000}
                    duration={1400}
                    count={1}
                />
                <Fireworks
                    key={5}
                    pause={true}
                    ref={this.fireworkRef3}
                    width={Dimensions.get('screen').height * 0.3}
                    height={Dimensions.get('screen').height * 0.3}
                    minConf={20}
                    maxConf={40}
                    timediff={1000}
                    duration={2000}
                    count={1}
                />
            </View>
        );
    }
}
