import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

import PermissionEvent from './PermissionEvent';

export default class RoleEvent extends JTOObject {
    public static _TYPE: string = 'RoleEvent';

    public name: JTOProperty<string>;
    public permissionEventList: JTOList<PermissionEvent>;

    constructor(parent?: JTOElement | null) {
        super(RoleEvent._TYPE, parent);

        this.name = new JTOProperty<string>('name', this);
        this.permissionEventList = new JTOList<PermissionEvent>(
            PermissionEvent,
            'has_permission_event',
            this,
        );
    }

    public has(permission: number): boolean {
        return (
            this.permissionEventList
                .getList()
                .find((p: PermissionEvent) => p.type.get() === permission) !==
            undefined
        );
    }
}
