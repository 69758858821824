import { useNavigation } from '@react-navigation/native';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActivityIndicator,
    FlatListProps,
    ListRenderItem,
    Pressable,
    ScrollViewProps,
    Text,
    View,
    VirtualizedList,
} from 'react-native';
import React from 'react';
//import { FlatList, ScrollView } from 'react-native-gesture-handler';
import Event from '../../object/Event';
import User from '../../object/User';
import { getColor } from '../../utils/theme';
import { GeSpinner } from '../GeSpinner';
import { Header } from '../Header';
import useJTOState from '../../utils/jto/useJTOState';
import EventTiny from '../../object/compressed/event/EventTiny';
import { CloseAllButton } from '../CloseAllButton';
import { height } from '../../utils/utils';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../../utils/screen';
import { ScheduleEvent } from '../ScheduleEvent';
import Invite from '../../object/Invite';
import { get } from '../../utils/http';
import { HTTPUtils } from 'mp-ts-http';
import { applyResult } from '../../utils/applyResult';

export const ReservationScreen = (props: any) => {
    const { t } = useTranslation();

    const navigation = useNavigation();

    const user = useJTOState(User._instance!);

    const invite = useJTOState(new Invite());

    const [loading, setLoading] = useState(false);

    const myEventList = invite.myEventList.getList();

    useEffect(() => {
        setLoading(true);
        get('/event/register/listperso', {
            id_event: user.getEvent()?.getJTOIdentifier(),
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    const e = HTTPUtils.getResult(res);
                    console.log(JSON.stringify(e, null, 4));
                    applyResult(e, invite, true);
                    invite.makeUpdate();
                } else {
                    console.log(HTTPUtils.generateError(res));
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log(e);
            });
    }, []);

    console.log(myEventList);

    return (
        <View
        /*style={{
                width: width(),
                paddingBottom: 0,
                backgroundColor: getColor('background'),
            }}*/
        >
            <Header
                logo={user.avatar.get() ?? ''}
                navigation={props.navigation}
                title={t('inscrip')}
                rounded={(user.avatar.get() ?? '') !== ''}
                backButton
                modal
                full
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
            >
                <View
                    style={{
                        marginTop: -20,
                        flex: 1,
                    }}
                >
                    <View
                        style={{
                            height: 60,
                        }}
                    ></View>
                    {myEventList.map((item, index) => {
                        return (
                            <View
                                key={item.getJTOIdentifier()}
                                style={{
                                    backgroundColor: getColor('background'),
                                    paddingHorizontal: 20,
                                }}
                            >
                                <ScheduleEvent
                                    navigation={navigation}
                                    event={item}
                                    last={index === myEventList.length - 1}
                                    reservationScreen
                                />
                            </View>
                        );
                    })}
                </View>
            </Header>
            <CloseAllButton
                onPress={() => {
                    props.navigation.popToTop();
                }}
            />
        </View>
    );

    /*return (
        <FlatList
            {...props}
            data={elemList}
            style={{
                backgroundColor: getColor('background'),
                paddingTop: 20
            }}
            alwaysBounceHorizontal={false}
            alwaysBounceVertical={false}
            bounces={false}
            bouncesZoom={false}
            renderItem={renderItem}
            keyExtractor={keyExtractor}></FlatList>
    );*/
};
