import JTOObject from '../../../utils/jto/JTOObject';
import JTOSimple from '../../../utils/jto/JTOSimple';
import JTOProperty from '../../../utils/jto/JTOProperty';
import JTORelProperty from '../../../utils/jto/JTORelProperty';
import JTOList from '../../../utils/jto/JTOList';
import JTOElement from '../../../utils/jto/JTOElement';
import JTOListFetch from '../../JTOListFetch';
import Group from '../../Group';
import UserTiny from './UserTiny';

export default class InviteTiny extends JTOObject {
    public static _TYPE: string = 'Invite';

    public user: JTOSimple<UserTiny>;
    public vote: JTORelProperty<string>;
    public want: JTORelProperty<boolean>;
    public invited: JTORelProperty<boolean>;
    public reaction: JTORelProperty<number>;
    public groupList: JTOList<Group>;

    constructor(parent?: JTOElement | null) {
        super(InviteTiny._TYPE, parent);

        this.user = new JTOSimple<UserTiny>(UserTiny, 'be_part', this);
        this.vote = new JTORelProperty<string>('has_vote.vote', this);

        this.reaction = new JTORelProperty<number>('react.type', this);

        this.want = new JTORelProperty<boolean>('has_register.want', this);
        this.invited = new JTORelProperty<boolean>(
            'has_register.invited',
            this,
        );

        this.groupList = new JTOList<Group>(Group, 'has_member', this);
    }

    public getDisplayName(): string {
        return this.user.get()?.getDisplayName() ?? 'Joe Doe';
    }
}
