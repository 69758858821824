import { useEffect } from 'react';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
import { CheckCircleIcon, XMarkIcon } from 'react-native-heroicons/solid';
import { FONT_MEDIUM } from '../../utils/theme';
import { Toast } from './Toast';
import { ToastManager } from './ToastManager';

interface IToastViewProps {
    toast: Toast;
    index: number;
}

export const ToastView = (props: IToastViewProps) => {
    const { toast, index } = props;

    useEffect(() => {
        // Remove toast after duration
        // Remove also if component unmount
        const timeout = setTimeout(() => {
            ToastManager.instance.setToastList(
                ToastManager.instance
                    .getToastList()
                    .filter((t, i) => t !== toast),
            );
        }, toast.duration);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <View
            style={{
                padding: 20,
                borderRadius: 20,
                marginBottom: 20,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor:
                    toast.type === 'success' ? '#059669' : '#DC2626',
            }}
        >
            <CheckCircleIcon color={'white'} />
            <Text
                style={{
                    color: 'white',
                    flex: 1,
                    fontFamily: FONT_MEDIUM,
                    fontSize: 14,
                    paddingHorizontal: 10,
                }}
            >
                {toast.message}
            </Text>
            <Pressable
                onPress={() => {
                    ToastManager.instance.setToastList(
                        ToastManager.instance
                            .getToastList()
                            .filter((_, i) => i !== index),
                    );
                }}
            >
                <XMarkIcon color={'white'} />
            </Pressable>
        </View>
    );
};
