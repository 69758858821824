import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Platform,
    Pressable,
    // @ts-ignore
    // @ts-ignore
    refreshControl,
    ScrollView,
    Text,
    View,
} from 'react-native';
import React from 'react';
import {
    PlusCircleIcon,
    ArrowPathIcon,
    PlusIcon,
} from 'react-native-heroicons/solid';
import Event from '../../object/Event';
import { POST_CREATE } from '../../object/PermissionEvent';
import User from '../../object/User';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../../utils/screen';
import { styles } from '../../utils/styles';
import { marginBottom, marginTop } from '../../utils/tailwinds';
import { FONT_BOLD, getColor } from '../../utils/theme';
import { height, isCloseToBottom, width } from '../../utils/utils';
import { GeSpinner } from '../GeSpinner';
import { Header } from '../Header';
import { PageHeader } from '../PageHeader';
import { PastilleView } from '../PastilleView';
import { PostView } from '../PostView';
import { IEventTabPageProps } from './IEventTabPageProps';
import { CartoucheButton } from '../CartoucheButton';
import useJTOState from '../../utils/jto/useJTOState';
import { CloseAllButton } from '../CloseAllButton';

export const PostEventPage = (props: IEventTabPageProps) => {
    const [refreshing, setRefreshing] = useState(false);
    const { t } = useTranslation();

    const event = useJTOState((props as any).route.params.event as Event);

    const postList = useJTOState(event.postList);

    const onRefresh = useCallback(() => {
        setRefreshing(false);
        postList.reset();
        postList.refetch({
            id: event.getJTOIdentifier(),
        });
    }, []);

    useEffect(() => {
        postList.fetch({
            id: event.getJTOIdentifier(),
        });
    }, []);

    return (
        <View
            style={{
                width: width(),
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                modal={true}
                line={1}
                navigation={props.navigation}
                backButton={true}
                full
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                title={t('post')}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: -30,
                        flex: 1,
                        backgroundColor: getColor('background'),
                    }}
                    // @ts-ignore
                    onScroll={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            postList.more({
                                id: event.getJTOIdentifier(),
                            });
                        }
                    }}
                    onMomentumScrollEnd={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            postList.more({
                                id: event.getJTOIdentifier(),
                            });
                        }
                    }}
                    scrollEventThrottle={1}
                >
                    <View
                        style={{
                            height: 50,
                        }}
                    ></View>
                    {postList.map((post, index) => {
                        return (
                            <PostView
                                key={post.getJTOIdentifier()}
                                post={post}
                                event={event}
                                navigation={props.navigation}
                            />
                        );
                    })}
                    {(postList.isLoading() || !postList.isComplite()) && (
                        <View
                            style={[
                                styles.boxCenter,
                                marginBottom(50),
                                postList.size() === 0
                                    ? marginTop(200)
                                    : marginTop(50),
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    )}
                    {postList.size() === 0 &&
                        !postList.isLoading() &&
                        postList.isComplite() && (
                            <View
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height:
                                        height() -
                                        STATUS_BAR_HEIGHT -
                                        NAVIGATION_BAR_HEIGHT -
                                        TAB_HEIGHT -
                                        200,
                                    flex: 1,
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 25,
                                        marginHorizontal: 25,
                                        fontFamily: FONT_BOLD,
                                        color: getColor('text'),
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('no_post')}
                                </Text>
                                {(User._instance
                                    .getEvent()
                                    ?.hasPermission(POST_CREATE) ??
                                    false) && (
                                    <Pressable
                                        style={[
                                            {
                                                backgroundColor:
                                                    getColor('secondary'),
                                                paddingVertical: 10,
                                                paddingHorizontal: 14,
                                                borderRadius: 10,
                                            },
                                            { marginTop: 30 },
                                        ]}
                                        onPress={() => {
                                            props.navigation.navigate(
                                                'PostModal',
                                                {
                                                    event,
                                                },
                                            );
                                        }}
                                    >
                                        <Text style={styles.buttonText}>
                                            {t('new_post')}
                                        </Text>
                                    </Pressable>
                                )}
                            </View>
                        )}
                </View>
            </Header>

            <CloseAllButton
                onPress={() => {
                    props.navigation.popToTop();
                }}
            />
            {(User._instance.getEvent()?.hasPermission(POST_CREATE) ??
                false) && (
                <Pressable
                    onPress={() => {
                        props.navigation.navigate('PostModal', {
                            event,
                        });
                    }}
                    style={{
                        position: 'absolute',
                        padding: 10,
                        bottom: NAVIGATION_BAR_HEIGHT + 20,
                        right: 10,
                        borderRadius: 1000,
                    }}
                >
                    <PastilleView
                        size={65}
                        color={getColor('secondary')}
                        icon={
                            <PlusIcon
                                size={50}
                                color={'white'}
                            />
                        }
                    />
                </Pressable>
            )}
        </View> /*}
        /*<View
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                paddingBottom: NAVIGATION_BAR_HEIGHT + TAB_HEIGHT,
            }}>
            {/*

            <View
                style={{
                    width: '100%',
                    height: STATUS_BAR_HEIGHT + 55,
                    backgroundColor: getColor('card'),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingHorizontal: 10,
                    borderBottomColor: getColor('border'),
                    borderBottomWidth: 1,
                    justifyContent: 'space-between',
                }}>
                <Pressable
                    onPress={() => {
                        onRefresh()
                    }}>
                    <ArrowPathIcon color={'black'} size={35} />
                </Pressable>
                <Pressable
                    onPress={() => {
                        props.navigation.navigate('PostModal', { event: event });
                    }}>
                    <PlusCircleIcon color={'black'} size={35} />
                </Pressable>
            </View>
            */ /*
            <View
                // @ts-ignore
onScroll={({nativeEvent}) => {
                    if (isCloseToBottom(nativeEvent)) {
                        postList.more({
                            id: event.getJTOIdentifier(),
                        });
                    }
                }}
                scrollEventThrottle={400}>
                {postList.map((post, index) => {
                    return (
                        <PostView
                            key={post.getJTOIdentifier()}
                            post={post}
                            navigation={props.navigation}
                        />
                    );
                })}
                {(postList.isLoading() || !postList.isComplite()) && (
                    <View
                        style={[
                            styles.boxCenter,
                            marginBottom(50),
                            postList.size() === 0
                                ? marginTop(200)
                                : marginTop(50),
                        ]}>
                        <GeSpinner color={getColor('primary')} />
                    </View>
                )}
            </View>

        
        </View>*/
    );
};
