import React from 'react';

import { Pressable, ScrollView, Text, View } from 'react-native';
import User from '../object/User';

import { useEffect, useState } from 'react';
import { FONT_BOLD, FONT_REGULAR, getColor } from '../utils/theme';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { Header } from '../component/Header';
import { t } from 'i18next';
import { GeSpinner } from '../component/GeSpinner';
import { height, isCloseToBottom } from '../utils/utils';
import useJTOState from '../utils/jto/useJTOState';

export const ExposantPage = (props: any) => {
    const [loading, setLoading] = useState(true);

    const event = useJTOState(User._instance.getEvent()!);

    const standList = useJTOState(User._instance.getEvent()!.stands);

    const premiumStandList = standList.getList().filter((stand) => {
        return stand.premium ?? false;
    });

    const nonPremiumStandList = standList.getList().filter((stand) => {
        return !stand.premium;
    });

    useEffect(() => {
        standList.refetch(
            {
                event_id: event.getJTOIdentifier(),
            },
            (res) => {
                standList.setComplite(true);
            },
            (err) => {
                standList.setComplite(true);
            },
        );
    }, []);

    return (
        <View
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                backButton={true}
                title={t('exposant_list')}
                line={1}
                navigation={props.navigation}
                logo={event.logo.get() ?? ''}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
            >
                {standList.isLoading() ? (
                    <View
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: NAVIGATION_BAR_HEIGHT + TAB_HEIGHT,
                            justifyContent: 'center',
                        }}
                    >
                        <GeSpinner color={getColor('primary')} />
                    </View>
                ) : standList.getList().length === 0 ? (
                    <ScrollView
                        style={{
                            flex: 1,
                            zIndex: 500,
                        }}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                    >
                        <View
                            style={{
                                height: 30,
                            }}
                        ></View>

                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingHorizontal: 20,
                                height:
                                    height() -
                                    TAB_HEIGHT -
                                    STATUS_BAR_HEIGHT -
                                    NAVIGATION_BAR_HEIGHT -
                                    150,
                                backgroundColor: getColor('background'),
                            }}
                        >
                            <Text
                                style={{
                                    color: getColor('text'),
                                    fontSize: 16,
                                    fontFamily: FONT_REGULAR,
                                    textAlign: 'center',
                                }}
                            >
                                {standList.size() === 0
                                    ? t('noStand')
                                    : standList.size()}
                            </Text>
                        </View>
                    </ScrollView>
                ) : (
                    <ScrollView
                        style={{
                            flex: 1,
                            zIndex: 500,
                        }}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        onScroll={({ nativeEvent }) => {
                            if (isCloseToBottom(nativeEvent)) {
                                //standList.setComplite(false);
                                standList.more({
                                    event_id: event.getJTOIdentifier(),
                                });
                            }
                        }}
                        onMomentumScrollEnd={({ nativeEvent }) => {
                            if (isCloseToBottom(nativeEvent)) {
                                standList.more({
                                    event_id: event.getJTOIdentifier(),
                                });
                            }
                        }}
                    >
                        <View
                            style={{
                                height: 30,
                            }}
                        ></View>

                        <Text
                            style={{
                                marginBottom: 20,
                                textAlign: 'center',
                                fontSize: 18,
                                fontFamily: FONT_BOLD,
                            }}
                        >
                            {t('exposant_premium')}
                        </Text>
                        {premiumStandList.length > 0 && (
                            <View
                                style={{
                                    marginHorizontal: 20,
                                    marginBottom: 10,
                                }}
                            >
                                <View>
                                    {premiumStandList.map((stand, index) => {
                                        return (
                                            <Pressable
                                                onPress={() => {}}
                                                key={stand.getJTOIdentifier()}
                                                style={{
                                                    borderRadius: 25,
                                                    marginBottom: 20,
                                                    backgroundColor: 'white',
                                                    shadowColor:
                                                        getColor('hint'),
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 2,
                                                    },
                                                    shadowOpacity: 0.2,
                                                    shadowRadius: 3.84,
                                                    elevation: 5,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        borderRadius: 24,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            backgroundColor:
                                                                getColor(
                                                                    'primary',
                                                                ),
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            padding: 10,
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <View
                                                            style={{
                                                                height: 30,
                                                                width: 30,
                                                                backgroundColor:
                                                                    'white',
                                                                borderRadius: 15,
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                                overflow:
                                                                    'hidden',
                                                            }}
                                                        >
                                                            <Text
                                                                style={{
                                                                    color: getColor(
                                                                        'primary',
                                                                    ),
                                                                    fontSize: 18,
                                                                    fontFamily:
                                                                        FONT_BOLD,
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Text>
                                                        </View>
                                                        <Text
                                                            style={{
                                                                flex: 1,
                                                                color: 'white',
                                                                marginLeft: 10,
                                                                fontSize: 20,
                                                                fontFamily:
                                                                    FONT_BOLD,
                                                            }}
                                                        >
                                                            {stand.exposant
                                                                .raisonSociale2 !==
                                                            ''
                                                                ? stand.exposant
                                                                      .raisonSociale1 +
                                                                  '\n' +
                                                                  stand.exposant
                                                                      .raisonSociale2
                                                                : stand.exposant
                                                                      .raisonSociale1}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </Pressable>
                                        );
                                    })}
                                </View>
                            </View>
                        )}
                        <Text
                            style={{
                                marginBottom: 20,
                                textAlign: 'center',
                                fontSize: 18,
                                fontFamily: FONT_BOLD,
                            }}
                        >
                            {t('exposant')}
                        </Text>
                        {nonPremiumStandList.length > 0 && (
                            <View
                                style={{
                                    marginHorizontal: 20,
                                    marginBottom: 10,
                                }}
                            >
                                <View>
                                    {nonPremiumStandList.map((stand, index) => {
                                        return (
                                            <Pressable
                                                onPress={() => {}}
                                                key={stand.getJTOIdentifier()}
                                                style={{
                                                    borderRadius: 25,
                                                    marginBottom: 20,
                                                    backgroundColor: 'white',
                                                    shadowColor:
                                                        getColor('hint'),
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 2,
                                                    },
                                                    shadowOpacity: 0.2,
                                                    shadowRadius: 3.84,
                                                    elevation: 5,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        borderRadius: 24,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            backgroundColor:
                                                                getColor(
                                                                    'primary',
                                                                ),
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            padding: 10,
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <View
                                                            style={{
                                                                height: 30,
                                                                width: 30,
                                                                backgroundColor:
                                                                    'white',
                                                                borderRadius: 15,
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                                overflow:
                                                                    'hidden',
                                                            }}
                                                        >
                                                            <Text
                                                                style={{
                                                                    color: getColor(
                                                                        'primary',
                                                                    ),
                                                                    fontSize: 18,
                                                                    fontFamily:
                                                                        FONT_BOLD,
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Text>
                                                        </View>
                                                        <Text
                                                            style={{
                                                                flex: 1,
                                                                color: 'white',
                                                                marginLeft: 10,
                                                                fontSize: 20,
                                                                fontFamily:
                                                                    FONT_BOLD,
                                                            }}
                                                        >
                                                            {stand.exposant
                                                                .raisonSociale2 !==
                                                            ''
                                                                ? stand.exposant
                                                                      .raisonSociale1 +
                                                                  '\n' +
                                                                  stand.exposant
                                                                      .raisonSociale2
                                                                : stand.exposant
                                                                      .raisonSociale1}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </Pressable>
                                        );
                                    })}
                                </View>
                            </View>
                        )}

                        <View
                            style={{
                                height: STATUS_BAR_HEIGHT,
                            }}
                        />
                    </ScrollView>
                )}
            </Header>
        </View>
    );
};
