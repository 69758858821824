import { Image, Platform, Pressable, Text, View } from 'react-native';
import React from 'react';
import { SERVER_URL } from '../utils/constants';
import { HEADER_BONUS_HEIGHT, STATUS_BAR_HEIGHT } from '../utils/screen';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import { PastilleView } from './PastilleView';
import { ChevronLeftIcon } from 'react-native-heroicons/solid';
import User from '../object/User';
import { CommonActions, useRoute } from '@react-navigation/native'; // Import useRoute
import { useTranslation } from 'react-i18next';

interface IHeaderProps {
    title: string;
    price?: number;
    navigation?: any;
    surtitle?: string;
    logo: string;
    beforeLogo?: JSX.Element;
    backButton?: boolean;
    agenda_savoiexpo?: boolean;
    line?: number;
    rounded?: boolean;
    children?: JSX.Element;
    after?: JSX.Element;
    before?: JSX.Element;
    modal?: boolean;
    full?: boolean;
    cartouche?: boolean;
    fallback?: JSX.Element;
    fallbackColor?: string;
    onBack?: () => boolean;
    onPresslogo?: () => void;
    onPressBackPhrase?: () => void;
}

const formatPrice = (price: number): string => {
    return price.toFixed(2).replace('.', ',');
};

export const Header = (props: IHeaderProps) => {
    const { t } = useTranslation();
    const route = useRoute(); // Get the current route
    const {
        title,
        price,
        modal = false,
        beforeLogo,
        full = false,
        cartouche = false,
        children,
        before,
        after,
        navigation,
        backButton = false,
        agenda_savoiexpo = false,
        rounded = false,
        surtitle,
        line = 2,
        onPresslogo = () => {
            if (props.navigation !== undefined) {
                props.navigation?.navigate('home');
            }
        },
        onPressBackPhrase = async () => {
            if (props.navigation !== undefined) {
                User._instance!.event = '';
                localStorage.setItem('event', '');
                props.navigation.dispatch(
                    CommonActions.reset({
                        index: 0,
                        routes: [{ name: 'Event' }],
                    }),
                );
            }
        },
        logo,
        fallback,
        onBack,
        fallbackColor = getColor('secondary'),
    } = props;

    return (
        <View
            style={{
                flex: 1,
            }}
        >
            {cartouche && <View></View>}
            <View
                style={{
                    zIndex: 1000,
                    position: 'relative',
                    backgroundColor: getColor('primary'),
                }}
            >
                {cartouche && <View></View>}

                {modal && Platform.OS === 'ios' && (
                    <View
                        style={{
                            position: 'absolute',
                            width: width() * 0.1,
                            top: 7,
                            left: (width() * 0.9) / 2,
                            height: 5,
                            borderRadius: 10,
                            zIndex: 1001,
                            backgroundColor: getColor('secondary'),
                        }}
                    ></View>
                )}

                <View
                    style={{
                        display: 'flex',
                        marginHorizontal: 20,
                        paddingTop:
                            (modal && Platform.OS === 'ios'
                                ? 20
                                : STATUS_BAR_HEIGHT) +
                            (Platform.OS === 'android' ? 10 : 0),
                        flexDirection: 'column',
                        justifyContent: 'center',
                        zIndex: 1000,
                    }}
                >
                    {before}
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {backButton && (
                            <Pressable
                                style={{
                                    marginRight: 15,
                                }}
                                onPress={() => {
                                    if (onBack) {
                                        onBack();
                                    }
                                    navigation?.goBack();
                                }}
                            >
                                <PastilleView
                                    color={getColor('secondary')}
                                    size={35}
                                    icon={
                                        <ChevronLeftIcon
                                            style={{
                                                marginRight: 3,
                                            }}
                                            color={'white'}
                                            size={25}
                                        />
                                    }
                                />
                            </Pressable>
                        )}
                        <View
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            {/*beforeLogo*/}
                            <View
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {agenda_savoiexpo && onPressBackPhrase ? (
                                    <Pressable
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                        onPress={onPressBackPhrase}
                                    >
                                        <PastilleView
                                            color={getColor('secondary')}
                                            size={15}
                                            icon={
                                                <ChevronLeftIcon
                                                    style={{
                                                        marginRight: 3,
                                                    }}
                                                    color={'white'}
                                                    size={10}
                                                />
                                            }
                                        />
                                        <Text
                                            allowFontScaling={true}
                                            adjustsFontSizeToFit={true}
                                            numberOfLines={1}
                                            style={{
                                                fontSize: 16,
                                                zIndex: 1001,
                                                fontFamily: FONT_REGULAR,
                                                opacity: 75,
                                                color: 'white',
                                                marginStart: 8,
                                            }}
                                        >
                                            {t('agenda_savoiexpo')}
                                        </Text>
                                    </Pressable>
                                ) : (
                                    <Text
                                        allowFontScaling={true}
                                        adjustsFontSizeToFit={true}
                                        numberOfLines={1}
                                        style={{
                                            fontSize: 16,
                                            zIndex: 1001,
                                            fontFamily: FONT_REGULAR,
                                            opacity: 75,
                                            color: 'white',
                                        }}
                                    >
                                        {''}
                                    </Text>
                                )}
                                {surtitle !== undefined && (
                                    <Text
                                        allowFontScaling={true}
                                        adjustsFontSizeToFit={true}
                                        numberOfLines={line}
                                        style={{
                                            fontSize: 16,
                                            zIndex: 1001,
                                            fontFamily: FONT_MEDIUM,
                                            color: 'white',
                                        }}
                                    >
                                        {surtitle}
                                    </Text>
                                )}
                                <Text
                                    allowFontScaling={true}
                                    adjustsFontSizeToFit={true}
                                    numberOfLines={line}
                                    style={{
                                        fontSize: 24,
                                        zIndex: 1001,
                                        fontFamily: FONT_BOLD,
                                        color: 'white',
                                    }}
                                >
                                    {title}
                                </Text>
                                {price !== undefined && (
                                    <Text
                                        style={{
                                            fontSize: 16,
                                            zIndex: 1001,
                                            fontFamily: FONT_MEDIUM,
                                            color: 'white',
                                        }}
                                    >
                                        Prix : {formatPrice(price)} €
                                    </Text>
                                )}
                            </View>
                        </View>

                        {onPresslogo ? (
                            <Pressable
                                onPress={onPresslogo}
                                style={{
                                    height: 58,
                                    width: 58,
                                    zIndex: 1001,
                                    marginLeft: 16,
                                    borderRadius: 50,
                                }}
                            >
                                <PastilleView
                                    size={58}
                                    color={
                                        logo !== '' && logo !== undefined
                                            ? 'white'
                                            : fallbackColor ??
                                              getColor('secondary')
                                    }
                                    icon={
                                        logo !== '' && logo !== undefined ? (
                                            <View>
                                                <Image
                                                    style={{
                                                        height: 50,
                                                        width: 50,
                                                        borderRadius: rounded
                                                            ? 50
                                                            : 50,
                                                    }}
                                                    source={{
                                                        uri:
                                                            SERVER_URL +
                                                            '/image/' +
                                                            logo,
                                                    }}
                                                />
                                            </View>
                                        ) : (
                                            fallback ?? <></>
                                        )
                                    }
                                />
                            </Pressable>
                        ) : (
                            <View
                                style={{
                                    height: 58,
                                    width: 58,
                                    zIndex: 1001,
                                    marginLeft: 16,
                                    borderRadius: 50,
                                }}
                            >
                                <PastilleView
                                    size={58}
                                    color={
                                        logo !== '' && logo !== undefined
                                            ? 'white'
                                            : fallbackColor
                                    }
                                    icon={
                                        logo !== '' && logo !== undefined ? (
                                            <Image
                                                style={{
                                                    height: 40,
                                                    width: 40,
                                                    borderRadius: rounded
                                                        ? 40
                                                        : 0,
                                                }}
                                                source={{
                                                    uri:
                                                        SERVER_URL +
                                                        '/image/' +
                                                        logo,
                                                }}
                                            />
                                        ) : (
                                            fallback ?? <></>
                                        )
                                    }
                                />
                            </View>
                        )}
                    </View>
                    {after}
                </View>
            </View>
            {children}
        </View>
    );
};
