import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View } from 'react-native';
import React from 'react';
import Event from '../object/Event';
import { FONT_BOLD, FONT_MEDIUM, getColor } from '../utils/theme';

export interface ICountDownViewProps {
    date: Date;
}

export const CountDownView = (props: ICountDownViewProps) => {
    const date = new Date();
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);

        // Clear the interval on component unmount
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const { t } = useTranslation();

    const timeLabelStyle = {
        color: getColor('text'),
        fontFamily: FONT_MEDIUM,
        fontSize: 17,
        marginTop: 2,
    };
    const digitTxtStyle = {
        color: 'white',
        width: 60,
        textAlign: 'center',
        fontFamily: FONT_BOLD,
        fontSize: 30,
    };
    const digitStyle = {
        backgroundColor: getColor('primary'),
        paddingVertical: 18,
        paddingHorizontal: 5,
        borderRadius: 20,
    };
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    const separatorStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        paddingHorizontal: 4,
        color: getColor('primary'),
        marginBottom: 35,
        fontSize: 30,
    };

    const diff = props.date.getTime() - new Date().getTime();
    const diffInSeconds = Math.floor(diff / 1000);

    const days = Math.floor(diffInSeconds / 86400);
    const hours = Math.floor((diffInSeconds % 86400) / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    return (
        <View
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
            }}
        >
            <View style={containerStyle as any}>
                <View style={digitStyle}>
                    <Text style={digitTxtStyle as any}>{days}</Text>
                </View>
                <Text style={timeLabelStyle}>{t('day') ?? ''}</Text>
            </View>
            <Text style={separatorStyle as any}>:</Text>
            <View style={containerStyle as any}>
                <View style={digitStyle}>
                    <Text style={digitTxtStyle as any}>{hours}</Text>
                </View>
                <Text style={timeLabelStyle}>{t('heure') ?? ''}</Text>
            </View>
            <Text style={separatorStyle as any}>:</Text>
            <View style={containerStyle as any}>
                <View style={digitStyle}>
                    <Text style={digitTxtStyle as any}>{minutes}</Text>
                </View>
                <Text style={timeLabelStyle}>{t('minute') ?? ''}</Text>
            </View>
        </View>
    );
};
