import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function Seminar(props: any) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 97.21 131.75'
            {...props}
        >
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M69.61 111.05c-.18 3.87.06 7.82-.66 11.57-.89 4.59-4.16 7.44-8.68 8.75-.41.12-.82.25-1.23.37h-20.8c-1.97-.96-4.15-1.64-5.86-2.94-3.01-2.29-4.18-5.74-4.44-9.4-.2-2.74-.04-5.5-.04-8.36h41.71zM95.8 37.46C89.99 11.93 65.74-3.69 39.9.75 13.46 5.3-4.48 31.63.98 57.86c2.47 11.87 8.43 21.58 17.99 29.03 4.19 3.26 6.92 7.38 8.15 12.53.08.35.2.7.3 1.07h42.31c.2-.69.36-1.32.54-1.95 1.57-5.32 5.16-9.08 9.24-12.64 14.86-12.93 20.65-29.25 16.29-48.44zM74.75 52c-2.93.08-5.22-2.31-5.31-5.58-.3-10.22-8.34-18.39-18.38-18.7-3.57-.11-5.98-2.28-5.9-5.32.08-3.14 2.59-5.17 6.26-5.09 15.33.36 28.3 13.54 28.41 28.89.03 3.28-2.11 5.71-5.08 5.79z'
                    strokeWidth={0}
                />
            </g>
        </svg>
    );
}

export default Seminar;
