import { Image } from 'react-native';
import React from 'react';
import NextLogo from '../svg/NextLogo';

export const BEFORE_LOGO = (
    <NextLogo
        fill={'white'}
        width={120}
        height={70}
    />
);

export const GLOBAL_CONFIG = {
    message: true,
};
