import { useEffect, useRef, useState } from 'react';
import {
    ActivityIndicator,
    AppState,
    BackHandler,
    Image,
    Keyboard,
    KeyboardAvoidingView,
    Platform,
    Pressable,
    SafeAreaView,
    Text,
    View,
} from 'react-native';
import React from 'react';
//import { FlatList, ScrollView } from 'react-native-gesture-handler';
import { BottomMessagePage } from '../component/message/BottomMessagePage';
import { TopMessagePage } from '../component/message/TopMessagePage';
import JTOListFetch from '../object/JTOListFetch';
import { Message } from '../object/Message';
import User from '../object/User';
import { SERVER_URL } from '../utils/constants';
import { NAVIGATION_BAR_HEIGHT, STATUS_BAR_HEIGHT } from '../utils/screen';
import { styles } from '../utils/styles';
import { FONT_BOLD, getColor, YOUTUBE_API } from '../utils/theme';
import {
    closeAction,
    isCloseToBottom,
    openAction,
    useKeyboardVisible,
    width,
} from '../utils/utils';
import { MessageView } from '../component/message/MessageView';
import { Header } from '../component/Header';
import ChatGroup from '../object/ChatGroup';
import { UserGroupIcon, UserIcon } from 'react-native-heroicons/solid';
import { HTTPUtils } from 'mp-ts-http';
import { t } from 'i18next';
import { NavigationRouteContext, useIsFocused } from '@react-navigation/native';
import Event from '../object/Event';
//import YouTube from 'react-native-youtube';
import { useJTOState } from 'mp-ts-react-json-to-object';

function compareDates5M(date1: string, date2: string) {
    const date1InMs = new Date(date1).getTime();
    const date2InMs = new Date(date2).getTime();

    const diffInMs = date2InMs - date1InMs;

    const fiveMinutesInMs = 5 * 60 * 1000;

    return diffInMs > fiveMinutesInMs;
}

export interface IMessagePageProps {
    navigation: any;
    params: { [key: string]: any };
}

export const VideoPage = (props: IMessagePageProps) => {
    const { navigation } = props;

    const event = useJTOState(
        User._instance.getEvent() ?? (new Event() as any),
    );

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('video_teasing')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={event.logo.get() ?? ''}
                fallback={
                    <UserIcon
                        color={'white'}
                        size={35}
                    />
                }
            >
                <View
                    style={{
                        marginTop: 0,
                        marginHorizontal: 20,
                    }}
                >
                    {/*<YouTube
                        apiKey={YOUTUBE_API}
                        videoId={event.video.get()} // The YouTube video ID
                        fullscreen
                        style={{
                            marginTop: 10,
                            height: ((width() - 40) * 9) / 16,
                            borderRadius: 20,
                            width: width() - 40,
                        }}
                    />*/}
                </View>
            </Header>
        </View>
    );
};
