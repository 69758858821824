import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, RefreshControl, Text, View, Image } from 'react-native';
import React from 'react';
//import { ScrollView } from 'react-native-gesture-handler';
import { NewspaperIcon } from 'react-native-heroicons/solid';
import GenericAchivement from '../component/achivement/GenericAchivement';
import { Header } from '../component/Header';
import { LevelFocusView } from '../component/LevelFocusView';
import { Badge } from '../object/Badge';
import JTOListFetch from '../object/JTOListFetch';
import User from '../object/User';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { styles } from '../utils/styles';
import { FONT_BOLD, getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import useJTOState from '../utils/jto/useJTOState';
import Post from '../object/Post';
import { get } from '../utils/http';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { HTTPUtils } from 'mp-ts-http';
import { applyResult } from '../utils/applyResult';
import { InviteView } from '../component/InviteView';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { REACTION_LIST } from '../component/ReactButton';
import { GeSpinner } from '../component/GeSpinner';

interface IReactPageProps {
    navigation: any;
}
export const getMarginLeft = (index: number) => {
    if (index % 3 === 2) {
        return 20;
    }
    return 0;
};
export const getMarginRight = (index: number) => {
    if (index % 3 === 0) {
        return 20;
    }
};

export const BRONZE_COLOR = '#C38144';
export const SILVER_COLOR = '#81A5B0';
export const GOLD_COLOR = '#E89B05';

export const BRONZE_COLOR_LIGHT = '#FFD5AE';
export const SILVER_COLOR_LIGHT = '#D9EAEF';
export const GOLD_COLOR_LIGHT = '#FFEF9C';

export const DICT_COLOR = {
    '1': BRONZE_COLOR,
    '2': SILVER_COLOR,
    '3': GOLD_COLOR,
};

export const PostReactModal = (props: IReactPageProps) => {
    const { t } = useTranslation();

    const postNew = useJTOState(new Post());

    const post = (props as any).route.params.post as Post;

    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);

    const onRefresh = useCallback(() => {
        setLoading(true);
        get('/post/react_list', {
            id: post.getJTOIdentifier(),
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    const p = HTTPUtils.getResult(res);
                    applyResult(p, postNew, true);
                    postNew.makeUpdate();
                } else {
                    ToastManager.instance.addToast(
                        new Toast(
                            User.translation(HTTPUtils.getError(res)) ?? '',
                            5000,
                            'error',
                        ),
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                ToastManager.instance.addToast(
                    new Toast(
                        User.translation('went_wrong_no_internet') ?? '',
                        5000,
                        'error',
                    ),
                );
            });
    }, []);

    useEffect(() => {
        onRefresh();
    }, [post.getJTOIdentifier()]);

    return (
        <View
            style={{
                width: width(),
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                modal={true}
                title={t('reacts')}
                line={1}
                full
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: -HEADER_BONUS_HEIGHT,
                        flex: 1,
                        backgroundColor: getColor('background'),
                    }}
                    // @ts-ignore
                    refreshControl={
                        <RefreshControl
                            progressViewOffset={HEADER_BONUS_HEIGHT}
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                    scrollEventThrottle={1}
                >
                    <View
                        style={{
                            height: HEADER_BONUS_HEIGHT + 20,
                        }}
                    ></View>
                    <View>
                        {REACTION_LIST.map((reaction, index) => {
                            const list = postNew.inviteList
                                .getList()
                                .filter((invite) => {
                                    return invite.reaction.get() === index;
                                });
                            if (list.length === 0) {
                                return null;
                            }
                            return (
                                <View>
                                    <View
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: 10,
                                            borderBottomWidth: 1,
                                            borderBottomColor:
                                                getColor('border'),
                                        }}
                                    >
                                        {typeof reaction === 'string' ? (
                                            <Text
                                                style={{
                                                    fontSize: 20,
                                                    color: getColor('text'),
                                                }}
                                            >
                                                {reaction}
                                            </Text>
                                        ) : (
                                            <Image
                                                source={
                                                    (reaction as any).source
                                                }
                                                resizeMode='contain'
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                }}
                                            />
                                        )}
                                        <Text
                                            style={{
                                                color: getColor('text'),
                                                fontFamily: FONT_BOLD,
                                                fontSize: 20,
                                            }}
                                        >
                                            {list.length}
                                        </Text>
                                    </View>

                                    {list.map((invite, index) => {
                                        return (
                                            <View
                                                key={invite.getJTOIdentifier()}
                                            >
                                                <InviteView
                                                    navigation={
                                                        props.navigation
                                                    }
                                                    invite={invite}
                                                />
                                            </View>
                                        );
                                    })}
                                </View>
                            );
                        })}
                        {loading && (
                            <View
                                style={[
                                    styles.boxCenter,
                                    marginBottom(50),
                                    post.inviteList.size() === 0
                                        ? marginTop(200)
                                        : marginTop(50),
                                ]}
                            >
                                <GeSpinner color={getColor('primary')} />
                            </View>
                        )}
                    </View>
                    {post.inviteList.size() === 0 && !loading && (
                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height:
                                    height() -
                                    STATUS_BAR_HEIGHT -
                                    NAVIGATION_BAR_HEIGHT -
                                    TAB_HEIGHT -
                                    200,
                                flex: 1,
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 25,
                                    fontFamily: FONT_BOLD,
                                    color: getColor('text'),
                                    textAlign: 'center',
                                }}
                            >
                                {t('no_reaction')}
                            </Text>
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
