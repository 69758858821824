import { View, Text, Pressable, Linking } from 'react-native';
import React from 'react';
import Event from '../object/Event';
import { FONT_BOLD, FONT_MEDIUM, getColor, HEART_COLOR } from '../utils/theme';
import {
    ArrowLeftEndOnRectangleIcon,
    ChartPieIcon,
    ChevronRightIcon,
    CurrencyEuroIcon,
    HeartIcon,
    MusicalNoteIcon,
    SparklesIcon,
    UserGroupIcon,
} from 'react-native-heroicons/solid';
import User from '../object/User';
import { useTranslation } from 'react-i18next';
import { CartoucheButton } from './CartoucheButton';
import { getIcon } from './ScheduleEvent';
import { CAN_VOTE } from '../object/PermissionEvent';
import { SondageView } from './SondageView';
import { startConfetti } from './Confetti';
import useJTOState from '../utils/jto/useJTOState';
import EventTiny from '../object/compressed/event/EventTiny';
import { BACKOFFICE_URL } from '../utils/constants';
import { post } from '../utils/http';
import { HTTPUtils } from 'mp-ts-http';
import { Toast } from './toast/Toast';
import { ToastManager } from './toast/ToastManager';
import JTOInstance from '../utils/jto/JTOInstance';

interface ScheduleEventProps {
    event: EventTiny;
    navigation: any;
    background?: boolean;
    canNow?: boolean;
    reservationScreen?: boolean;
}

export const registerAdd = (
    event: EventTiny,
    t: (name: string) => string,
    setLoading: (load: boolean) => void,
) => {
    if (event.form.get()) {
        Linking.openURL(
            BACKOFFICE_URL +
                '/inscription?id=' +
                event.getJTOIdentifier() +
                '&id_user=' +
                User._instance.getJTOIdentifier(),
        );
    } else {
        setLoading(true);
        post('/event/register/addperso', {
            event_id: event.getJTOIdentifier(),
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    const e = HTTPUtils.getResult(res);
                    JTOInstance.applyResult(e, true);
                    ToastManager.instance.addToast(
                        new Toast(t('toast.register.success'), 5000, 'success'),
                    );
                } else {
                    ToastManager.instance.addToast(
                        new Toast(t(HTTPUtils.getError(res)), 5000, 'error'),
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet'), 5000, 'error'),
                );
            });
    }
};

export const ScheduleContentEvent = (props: ScheduleEventProps) => {
    const { t } = useTranslation();

    const { background = true, canNow = true, reservationScreen } = props;

    const event = useJTOState(props.event);

    const startTime = new Date(
        props.event.getStartDate() ?? props.event.getBetterDate() ?? '',
    );
    const endTime = new Date(
        props.event.getEndDate() ?? props.event.getBetterDate() ?? '',
    );

    const isNow =
        new Date(startTime).getTime() < new Date().getTime() &&
        new Date(endTime).getTime() > new Date().getTime() &&
        canNow;

    const content = (
        <View
            style={{
                display: 'flex',
                flex: 1,
                width: '100%',
            }}
        >
            <View
                style={{
                    width: '100%',
                    marginBottom: 5,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <View
                    style={{
                        flex: 1,
                    }}
                >
                    <Text
                        style={{
                            color: isNow ? '#FFFFFF' : getColor('text'),
                            fontSize: 18,
                            fontFamily: FONT_BOLD,
                        }}
                    >
                        {props.event.name.get()}
                    </Text>
                    <Text
                        style={{
                            fontSize: 14,
                            color: isNow ? '#FFFFFF' : getColor('hint'),
                            fontFamily: FONT_MEDIUM,
                            paddingBottom: 5,
                        }}
                    >
                        {new Date(
                            props.event.getBetterDate() ?? '',
                        ).toLocaleTimeString(t('date') ?? '', {
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    </Text>
                    <View
                        style={{
                            marginBottom: 5,
                            width: '100%',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                        }}
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            {props.event.eventType.get() && (
                                <View
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <CartoucheButton
                                        color={getColor('secondary')}
                                        height={40}
                                        fontSize={16}
                                        iconSize={20}
                                        icon={
                                            <View
                                                style={{
                                                    marginEnd: 5,
                                                }}
                                            >
                                                {getIcon(
                                                    props.event.eventType.get()!,
                                                    isNow,
                                                )}
                                            </View>
                                        }
                                        title={
                                            props.event.eventTypeAlias.get() ??
                                            props.event.eventType
                                                .get()!
                                                .name.get() ??
                                            ''
                                        }
                                    />
                                </View>
                            )}
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 5,
                            }}
                        >
                            {!User._instance.nothing &&
                                props.event.registrable.get() &&
                                !props.event.isBeforeNow() &&
                                !reservationScreen && (
                                    <View
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <CartoucheButton
                                            disabled={
                                                event.hasRegister
                                                    .get()
                                                    ?.want.get() === true
                                            }
                                            color={
                                                isNow
                                                    ? getColor('secondary')
                                                    : getColor('primary')
                                            }
                                            height={40}
                                            fontSize={16}
                                            iconSize={20}
                                            onPress={() => {
                                                registerAdd(
                                                    props.event,
                                                    t,
                                                    () => {},
                                                );
                                            }}
                                            icon={
                                                <View
                                                    style={{
                                                        marginEnd: 5,
                                                    }}
                                                ></View>
                                            }
                                            title={
                                                event.hasRegister
                                                    .get()
                                                    ?.want.get() !== true
                                                    ? t('register')
                                                    : t('already_registered')
                                            }
                                        />
                                    </View>
                                )}
                        </View>
                        {(User._instance.getEvent()?.hasPermission(CAN_VOTE) ??
                            false) &&
                            props.event.isBeforeNow() &&
                            props.event.canBeSurvey() && (
                                <Pressable
                                    onPress={() => {
                                        props.navigation.push('SubEventModal', {
                                            event: props.event.toEvent(),
                                            react: true,
                                        });
                                    }}
                                    style={{
                                        alignContent: 'flex-end',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: getColor('secondary'),
                                            fontFamily: FONT_MEDIUM,
                                            textAlign: 'right',
                                        }}
                                    >
                                        {props.event.hasVote.get() === null
                                            ? t('give_feedback')
                                            : t('open')}
                                    </Text>
                                    <ChevronRightIcon
                                        color={getColor('secondary')}
                                    />
                                </Pressable>
                            )}
                    </View>
                </View>
                <View
                    style={{
                        alignItems: 'flex-end',
                        paddingLeft: 4,
                    }}
                >
                    <View style={{ alignItems: 'center' }}>
                        {props.event.isFavoriteNow() && (
                            <HeartIcon color={HEART_COLOR} />
                        )}

                        {props.event.paidEvent && (
                            <CurrencyEuroIcon
                                color={isNow ? '#FFFFFF' : getColor('text')}
                                size={26}
                            />
                        )}

                        {/*props.event.registrable.get() && (
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: isNow
                                        ? '#FFFFFF'
                                        : getColor('text'),
                                    borderRadius: 9999,
                                    padding: 3,
                                }}
                            >
                                <ArrowLeftEndOnRectangleIcon
                                    color={
                                        isNow
                                            ? getColor('primary')
                                            : getColor('card')
                                    }
                                    size={15}
                                />
                            </View>
                        )*/}
                    </View>
                </View>
            </View>

            {props.event.hasVote.get() === null &&
                ((User._instance.getEvent()?.hasPermission(CAN_VOTE) ??
                    false) ||
                    User._instance.nothing) &&
                props.event.isBeforeNow() &&
                props.event.canBeSurvey() && (
                    <SondageView
                        event={props.event}
                        navigation={props.navigation}
                    />
                )}
        </View>
    );

    return (
        <View
            style={{
                flex: 1,
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                backgroundColor: isNow ? getColor('primary') : getColor('card'),
                borderRadius: 20,
                marginLeft: 15,
                paddingVertical: 15,
                paddingHorizontal: 15,
                marginBottom: 20,
            }}
        >
            {content}
        </View>
    );
};
