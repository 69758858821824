import { HTTPUtils } from 'mp-ts-http';
import { get } from '../utils/http';
import { useEffect, useRef, useState } from 'react';
import {
    ActivityIndicator,
    Image,
    Linking,
    Pressable,
    ScrollView,
    StyleProp,
    StyleSheet,
    Text,
    View,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import {
    APP_EVENT_ID,
    APP_NAME,
    APP_PUBLIC_EVENT_ID,
    FONT_BOLD,
    FONT_HEAVY,
    FONT_MEDIUM,
    getColor,
    initTheme,
} from '../utils/theme';
import { IPageProps } from './IPageProps';
import User from '../object/User';
import { height, width } from '../utils/utils';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { useTranslation } from 'react-i18next';
//import AsyncStorage from '@react-native-async-storage/async-storage';
import Event from '../object/Event';
import { BACKOFFICE_URL, SERVER_URL } from '../utils/constants';
import Invite from '../object/Invite';
import { CartoucheButton } from '../component/CartoucheButton';
import { HeaderSimplePage } from '../component/HeaderSimplePage';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
//import { ScrollView } from 'react-native-gesture-handler';
import { CartoucheEvent } from '../component/CartoucheEvent';
import BgGe from '../svg/BgGe';
import { GeSpinner } from '../component/GeSpinner';
import { ContentCartouche } from '../component/ContentCartouche';
import { Header } from '../component/Header';
import { Logo } from '../svg/Logo';
import { CommonActions } from '@react-navigation/native';
import { getRestore } from '../utils/http';
import JTOListFetch from '../object/JTOListFetch';
import { BEFORE_LOGO } from '../utils/config';
import useJTOState from '../utils/jto/useJTOState';
import { applyResult } from '../utils/applyResult';
import { compareEventTiny } from '../object/compressed/event/EventTiny';

export const EventPage = (props: IPageProps) => {
    const { t } = useTranslation();
    User.translation = t;

    initTheme('light');

    const [loading, setLoading] = useState(true);

    const [loadingPublic, setLoadingPublic] = useState(true);

    const eventListInvite = useJTOState(
        new JTOListFetch<Event>('/user/event/list', Event),
    );
    const eventListPublic = useJTOState(
        new JTOListFetch<Event>('/event/public/list', Event),
    );

    const [type, setType] = useState<'invite' | 'public'>('invite');

    useEffect(() => {
        eventListInvite.setRestore(false);
        eventListInvite.fetch(
            {},
            () => {
                setLoading(false);
                /*if (
                    eventListInvite.size() === 1 &&
                    eventListInvite.get(0).getJTOIdentifier()
                ) {
                    if (
                        eventListInvite.get(0).getJTOIdentifier() !==
                        APP_PUBLIC_EVENT_ID
                    ) {
                        onSelectEvent(
                            eventListInvite.get(0).getJTOIdentifier(),
                        );
                    }
                }*/
            },
            (err) => {
                setLoading(false);
            },
        );
        eventListInvite.setComplite(true);

        eventListPublic.fetch(
            {},
            () => {
                setLoadingPublic(false);
            },
            () => {
                setLoadingPublic(false);
            },
        );
        eventListPublic.setComplite(true);
        /*getRestore(
            '/user/event/list',
            {},
            (res: any) => {
                if (HTTPUtils.isSuccess(res)) {
                    applyResult(
                        HTTPUtils.getResult(res),
                        User._instance!,
                        true,
                    );
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            },
            (err: any) => {
                setLoading(false);
            },
            () => {
                User._instance!.inviteList.setList([]);
            },
        );*/
    }, []);

    const onSelectEvent = (event: Event | number) => {
        eventListInvite.setLoading(true);
        eventListInvite.notifyViewNow();
        if (event instanceof Event) {
            User._instance!.event = event.getJTOIdentifier() ?? '';
        } else {
            User._instance!.event = event + '';
        }
        User._instance!.loadEvent();
        localStorage.setItem('event', User._instance!.event + '');

        get('/user/event/get', {
            id: User._instance!.event ?? '',
        }).then((res) => {
            if (HTTPUtils.isSuccess(res)) {
                setTimeout(() => {
                    eventListInvite.setLoading(false);
                    eventListInvite.notifyViewNow();
                }, 500);
                applyResult(HTTPUtils.getResult(res), User._instance!, true);
                User._instance!.loadEvent();
                if (User._instance.getEvent()) {
                    props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'Tab' }],
                        }),
                    );
                } else {
                    props.navigation.navigate('Error', {
                        title: 'no_permission_title',
                        description: 'no_permission',
                    });
                }
            } else {
                setTimeout(() => {
                    eventListInvite.setLoading(false);
                    eventListInvite.notifyViewNow();
                }, 500);
                props.navigation.navigate('Error', {
                    title: 'no_permission_title',
                    description: 'no_permission',
                });
            }
        });

        /*.catch((err) => {
                setTimeout(() => {
                    eventListInvite.setLoading(false);
                    eventListInvite.notifyViewNow();
                }, 500);
                props.navigation.navigate('Error', {
                    title: 'went_wrong_title',
                    description: 'went_wrong',
                });
            });*/
    };

    //const scrollRef = useRef<any>();

    let eventList = [];

    let listCC: JTOListFetch<Event>;
    if (type === 'invite') {
        listCC = eventListInvite;
    } else {
        listCC = eventListPublic;
    }

    eventList.push(...eventListInvite.getList());

    for (const event of eventListPublic.getList()) {
        if (
            !eventList.find(
                (e) => e.getJTOIdentifier() === event.getJTOIdentifier(),
            )
        ) {
            eventList.push(event);
        }
    }

    eventList = eventList.filter((e) => {
        return e.getJTOIdentifier() !== APP_PUBLIC_EVENT_ID;
    });

    const futurEventList = eventList
        .filter((e) => {
            return e.isAfterNow();
        })
        .sort(compareEventTiny);

    const pastEventList = eventList
        .filter((e) => {
            return e.isBeforeNow();
        })
        .sort(compareEventTiny);

    const inEventList = eventList
        .filter((e) => {
            return e.isNow();
        })
        .sort(compareEventTiny);

    return (
        <View>
            <Header
                title={APP_NAME}
                beforeLogo={<></>}
                fallback={<></>}
                fallbackColor={'white'}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                full
                logo={''}
            >
                <View
                //ref={scrollRef}
                // @ts-ignore
                //automaticallyAdjustKeyboardInsets={true}
                >
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: width() * 0.15,
                            marginLeft: width() * 0.15,
                            width: width() * 0.7,
                            height: '100%',
                            backgroundColor: getColor('white'),
                        }}
                    >
                        <HeaderSimplePage
                            color={getColor('ge')}
                            title={t('event_page_title', {
                                name: User._instance.firstname.get() ?? '',
                            })}
                            description={t('event_page_description')}
                        />

                        <View
                            style={{
                                marginTop: 20,
                            }}
                        ></View>

                        {loading ? (
                            <View style={[styles.boxCenter, marginTop(75)]}>
                                <GeSpinner color={getColor('ge')} />
                            </View>
                        ) : (
                            <ScrollView
                                scrollEventThrottle={1}
                                style={{
                                    flex: 1,
                                }}
                            >
                                {User._instance.nothing && (
                                    <CartoucheButton
                                        onPress={() => {
                                            props.navigation.navigate('Login');
                                        }}
                                        color={getColor('ge')}
                                        title={t('already_register_question')}
                                        height={70}
                                    />
                                )}

                                <View style={styles.box}>
                                    {eventList.length === 0 ? (
                                        <Text
                                            style={[
                                                styles.text,
                                                {
                                                    textAlign: 'center',
                                                    fontFamily: FONT_BOLD,
                                                    fontSize: 22,
                                                    marginTop: 50,
                                                },
                                                marginBottom(50),
                                            ]}
                                        >
                                            {t('no_event')}
                                        </Text>
                                    ) : (
                                        <View
                                            style={{
                                                display: 'flex',
                                            }}
                                        >
                                            {inEventList.length > 0 && (
                                                <View
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        marginTop: 20,
                                                        justifyContent:
                                                            'center',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            marginBottom: 0,
                                                            textAlign: 'center',
                                                            fontSize: 18,
                                                            fontFamily:
                                                                FONT_BOLD,
                                                        }}
                                                    >
                                                        {t('current_event')}
                                                    </Text>
                                                    {inEventList.map(
                                                        (event, index) => {
                                                            return (
                                                                <CartoucheEvent
                                                                    key={event.getJTOIdentifier()}
                                                                    event={
                                                                        event
                                                                    }
                                                                    onSelectEvent={
                                                                        onSelectEvent
                                                                    }
                                                                    eventPage
                                                                />
                                                            );
                                                        },
                                                    )}
                                                </View>
                                            )}
                                            {futurEventList.length > 0 && (
                                                <View
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        marginTop: 20,
                                                        justifyContent:
                                                            'center',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            marginBottom: 0,
                                                            textAlign: 'center',
                                                            fontSize: 18,
                                                            fontFamily:
                                                                FONT_BOLD,
                                                        }}
                                                    >
                                                        {t('futur_event')}
                                                    </Text>
                                                    {futurEventList.map(
                                                        (event, index) => {
                                                            return (
                                                                <CartoucheEvent
                                                                    key={event.getJTOIdentifier()}
                                                                    event={
                                                                        event
                                                                    }
                                                                    onSelectEvent={
                                                                        onSelectEvent
                                                                    }
                                                                    eventPage
                                                                />
                                                            );
                                                        },
                                                    )}
                                                </View>
                                            )}
                                            {pastEventList.length > 0 && (
                                                <View
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        marginTop: 20,
                                                        justifyContent:
                                                            'center',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            marginBottom: 0,
                                                            textAlign: 'center',
                                                            fontSize: 18,
                                                            fontFamily:
                                                                FONT_BOLD,
                                                        }}
                                                    >
                                                        {t('past_event')}
                                                    </Text>
                                                    {pastEventList.map(
                                                        (event, index) => {
                                                            return (
                                                                <CartoucheEvent
                                                                    key={event.getJTOIdentifier()}
                                                                    event={
                                                                        event
                                                                    }
                                                                    onSelectEvent={
                                                                        onSelectEvent
                                                                    }
                                                                    eventPage
                                                                />
                                                            );
                                                        },
                                                    )}
                                                </View>
                                            )}
                                        </View>
                                    )}
                                </View>
                            </ScrollView>
                        )}
                    </View>
                </View>
            </Header>
        </View>
    );
};
