import { View, Text, Pressable, AppState } from 'react-native';
import React from 'react';
import Event from '../object/Event';
import { FONT_BOLD, getColor } from '../utils/theme';
//import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
//import FontAwesome from 'react-native-vector-icons/FontAwesome';
import {
    CakeIcon,
    ChartPieIcon,
    GlobeEuropeAfricaIcon,
    MicrophoneIcon,
    MusicalNoteIcon,
    PauseCircleIcon,
    SparklesIcon,
    UserGroupIcon,
} from 'react-native-heroicons/solid';
import User from '../object/User';
import { useTranslation } from 'react-i18next';
import { CartoucheButton } from './CartoucheButton';
import { ScheduleContentEvent } from './ScheduleContentEvent';
import EventTiny from '../object/compressed/event/EventTiny';
import Conference from '../svg/Conference';
import Workshop from '../svg/Workshop';
import Concert from '../svg/Concert';
import Show from '../svg/Show';
import KidsAnimation from '../svg/KidsAnimation';
import AwardCeremony from '../svg/AwardCeremony';
import Interview from '../svg/Interview';
import Signing from '../svg/Signing';
import Panel from '../svg/Panel';
import Contest from '../svg/Contest';
import Reception from '../svg/Reception';
import Opening from '../svg/Opening';
import NetworkingEvening from '../svg/NetworkingEvening';
import FashionShow from '../svg/FashionShow';
import PressBriefing from '../svg/PressBriefing';
import BreakfastMeeting from '../svg/BreakfastMeeting';
import LunchMeeting from '../svg/LunchMeeting';
import Meeting from '../svg/Meeting';
import GuidedTour from '../svg/GuidedTour';
import Exhibition from '../svg/Exhibition';
import CookingClass from '../svg/CookingClass';
import Seminar from '../svg/Seminar';

interface ScheduleEventProps {
    event: EventTiny;
    last: boolean;
    navigation: any;
    canNow?: boolean;
    reservationScreen?: boolean;
}

const events = [
    { key: 'conference', name: 'Conférence', color: '#e52322' },
    { key: 'workshop', name: 'Atelier', color: '#e52322' },
    { key: 'concert', name: 'Concert', color: '#e52322' },
    { key: 'show', name: 'Spectacle', color: '#e52322' },
    { key: 'kids_animation', name: 'Animation enfants', color: '#e52322' },
    { key: 'award_ceremony', name: 'Remise de prix', color: '#e52322' },
    { key: 'interview', name: 'Interview', color: '#e52322' },
    { key: 'signing', name: 'Dédicaces', color: '#e52322' },
    { key: 'panel', name: 'Table ronde', color: '#e52322' },
    { key: 'contest', name: 'Concours', color: '#e52322' },
    { key: 'reception', name: 'Réception', color: '#e52322' },
    { key: 'opening', name: 'Inauguration', color: '#e52322' },
    { key: 'networking_evening', name: 'Soirée réseau', color: '#e52322' },
    { key: 'fashion_show', name: 'Défilé', color: '#e52322' },
    { key: 'press_briefing', name: 'Point presse', color: '#e52322' },
    { key: 'breakfast_meeting', name: 'Petit déjeuner', color: '#e52322' },
    { key: 'lunch_meeting', name: 'Déjeuner', color: '#e52322' },
    { key: 'meeting', name: 'Réunion', color: '#e52322' },
    { key: 'guided_tour', name: 'Visite guidée', color: '#e52322' },
    { key: 'exhibition', name: 'Exposition', color: '#e52322' },
    { key: 'cooking_class', name: 'Cours de cuisine', color: '#e52322' },
    { key: 'seminar', name: 'Séminaire', color: '#e52322' },
];

export function getIcon(eventType: any, isNow: boolean) {
    switch (eventType.key.get()) {
        case 'conference':
            return (
                <Conference
                    width={14}
                    color={'white'}
                />
            );
        case 'workshop':
            return (
                <Workshop
                    color={'white'}
                    width={14}
                />
            );
        case 'concert':
            return (
                <Concert
                    color={'white'}
                    width={14}
                />
            );
        case 'show':
            return (
                <Show
                    color={'white'}
                    width={14}
                />
            );
        case 'kids_animation':
            return (
                <KidsAnimation
                    color={'white'}
                    width={14}
                />
            );
        case 'award_ceremony':
            return (
                <AwardCeremony
                    width={14}
                    color={'white'}
                />
            );
        case 'interview':
            return (
                <Interview
                    width={14}
                    color={'white'}
                />
            );
        case 'signing':
            return (
                <Signing
                    width={14}
                    color={'white'}
                />
            );
        case 'panel':
            return (
                <Panel
                    size={14}
                    color={'white'}
                />
            );
        case 'contest':
            return (
                <Contest
                    size={14}
                    color={'white'}
                />
            );
        case 'reception':
            return (
                <Reception
                    width={14}
                    color={'white'}
                />
            );
        case 'opening':
            return (
                <Opening
                    width={14}
                    color={'white'}
                />
            );
        case 'networking_evening':
            return (
                <NetworkingEvening
                    width={14}
                    color={'white'}
                />
            );
        case 'fashion_show':
            return (
                <FashionShow
                    width={14}
                    color={'white'}
                />
            );
        case 'press_briefing':
            return (
                <PressBriefing
                    width={14}
                    color={'white'}
                />
            );
        case 'breakfast_meeting':
            return (
                <BreakfastMeeting
                    color={'white'}
                    width={14}
                />
            );
        case 'lunch_meeting':
            return (
                <LunchMeeting
                    color={'white'}
                    width={14}
                />
            );
        case 'meeting':
            return (
                <Meeting
                    width={14}
                    color={'white'}
                />
            );
        case 'guided_tour':
            return (
                <GuidedTour
                    color={'white'}
                    width={14}
                />
            );
        case 'exhibition':
            return (
                <Exhibition
                    width={14}
                    color={'white'}
                />
            );
        case 'cooking_class':
            return (
                <CookingClass
                    width={14}
                    color={'white'}
                />
            );
        case 'seminar':
            return (
                <Seminar
                    width={14}
                    color={'white'}
                />
            );
        default:
            // NEVER HAPPEN
            return (
                <SparklesIcon
                    color={'white'}
                    width={14}
                />
            );
    }
}

function getChip(eventType: any, isNow: boolean) {
    return (
        <View
            style={{
                paddingTop: 10,
                alignItems: 'flex-end',
            }}
        >
            <View
                style={{
                    backgroundColor: isNow
                        ? 'white'
                        : eventType.color.get() + '255',
                    borderRadius: 20,
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Text>TESTTT</Text>
                {getIcon(eventType, isNow)}
                <Text
                    style={{
                        fontSize: 14,
                        marginLeft: 5,
                        fontFamily: FONT_BOLD,
                        color: isNow
                            ? getColor('primary')
                            : eventType.color.get(),
                    }}
                >
                    {eventType.name.get()}
                </Text>
            </View>
        </View>
    );
}

export const ScheduleEvent = (props: ScheduleEventProps) => {
    const { t } = useTranslation();
    const { canNow = true, reservationScreen } = props;

    const startTime = new Date(
        props.event.getStartDate() ?? props.event.getBetterDate() ?? '',
    );
    const endTime = new Date(
        props.event.getEndDate() ?? props.event.getBetterDate() ?? '',
    );

    const isNow =
        startTime.getTime() < new Date().getTime() &&
        endTime.getTime() > new Date().getTime() &&
        canNow;

    return (
        <Pressable
            key={props.event.getJTOIdentifier()}
            style={{
                flexDirection: 'row',
                flex: 1,
            }}
            onPress={() => {
                props.navigation.push('SubEventModal', {
                    event: props.event.toEvent(),
                });
            }}
        >
            <View
                style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    display: 'flex',
                    width: 20,
                }}
            >
                {isNow ? (
                    <View
                        style={{
                            width: 20,
                            height: 20,
                            backgroundColor: 'white',
                            borderColor: getColor('primary'),
                            borderWidth: 2,
                            borderRadius: 10,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                width: 12,
                                height: 12,
                                backgroundColor: getColor('primary'),
                                borderColor: getColor('primary'),
                                borderWidth: 2,
                                borderRadius: 10,
                            }}
                        ></View>
                    </View>
                ) : (
                    <View
                        style={{
                            width: 12,
                            height: 12,
                            backgroundColor: 'white',
                            borderColor: getColor('primary'),
                            borderWidth: 2,
                            borderRadius: 10,
                        }}
                    ></View>
                )}
                {!props.last && (
                    <View
                        style={{
                            width: 2,
                            flex: 1,
                            backgroundColor: getColor('primary'),
                            marginVertical: 12,
                            height: '100%',
                        }}
                    ></View>
                )}
            </View>
            <ScheduleContentEvent
                canNow={canNow}
                navigation={props.navigation}
                event={props.event}
                reservationScreen={reservationScreen}
            />
        </Pressable>
    );
};
