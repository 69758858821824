import { Text, TouchableOpacity } from 'react-native';
import React from 'react';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { FONT_BOLD, getColor } from '../utils/theme';

interface ICloseAllButtonProps {
    onPress: () => void;
}

export const CloseAllButton = (props: ICloseAllButtonProps) => {
    const { t } = useTranslation();
    return (
        <TouchableOpacity
            onPress={props.onPress}
            style={{
                position: 'absolute',
                right: 20,
                borderRadius: 40,
                bottom: 50 + 20,
                backgroundColor: getColor('secondary'),
            }}
        >
            <Text
                numberOfLines={1}
                adjustsFontSizeToFit
                style={{
                    fontFamily: FONT_BOLD,
                    color: 'white',
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 16,
                }}
            >
                {t('close_all')}
            </Text>
        </TouchableOpacity>
    );
};
