import JTOElement from './JTOElement';
import JTOField from './JTOField';
import JTOObject from './JTOObject';
/**
 * JTOProperty is the class that contains simple variable in JTOObject
 */
export default class JTOProperty<T> extends JTOField {
    /**
     * Variable value contains the value of this JTOProperty
     * @type {any}
     */
    private value: T | undefined;

    /**
     * Variable overrideOnUndefined, if its at true, the value of this JTOProperty
     * will be overrided even if the value is undefined in the json source
     * @type {boolean}
     */
    private overrideOnUndefined: boolean;

    /**
     * Constructor of JTOProperty
     * @param field string field where the value of this JTOProperty is stored
     * @param JTOParent the parent of this object, null if this object is the root
     * @param overrideOnUndefined optionnal parameter, boolean for the overrideOnUndefined property, change data even if the value is undefined in the json source
     * @param value optionnal parameter, the value of this JTOProperty
     */
    public constructor(
        field: string[] | string | null = null,
        JTOParent: JTOElement | null = null,
        overrideOnUndefined: boolean = true,
        value?: T,
    ) {
        super(field, JTOParent);
        this.value = value;
        this.overrideOnUndefined = overrideOnUndefined;
    }

    /**
     * Get the value of this JTOProperty
     * @returns {string} the value of this JTOProperty
     */
    public get(): T | undefined {
        return this.value;
    }

    /**
     * Get the overrideOnUndefined of this JTOProperty
     * @returns {boolean} the overrideOnUndefined of this JTOProperty
     */
    public isOverrideOnUndefined(): boolean {
        return this.overrideOnUndefined;
    }

    /**
     * Set the overrideOnUndefined of this JTOProperty
     * @returns {boolean} the new overrideOnUndefined
     */
    public setOverrideOnUndefined(overrideOnUndefined: boolean) {
        this.overrideOnUndefined = overrideOnUndefined;
    }

    /**
     * Set the value of this JTOProperty
     * @param value the new value
     * @returns {boolean} if the value change
     */
    public set(value: any): boolean {
        // boolean that indicates if the value has changed
        let res: boolean = false;

        // if the value is different
        if (this.value !== value) {
            // set the new value
            this.value = value;
            res = true;
        }

        // Make the update
        this.notifyView();

        // return the result
        return res;
    }

    public override applyDataPartiel(
        data: { [key: string]: any },
        first: boolean,
    ): boolean {
        // boolean that indicates if the object has changed
        let res = false;

        let found = false;
        let value;
        let i = 0;
        while (!found && i < this.fieldList.length) {
            if (this.fieldList[i] in data && !this.fieldList[i].includes('.')) {
                if (
                    data[this.fieldList[i]] === undefined &&
                    this.isOverrideOnUndefined()
                ) {
                    found = true;
                    value = data[this.fieldList[i]];
                }
                if (data[this.fieldList[i]] !== undefined) {
                    found = true;
                    value = data[this.fieldList[i]];
                }
            }
            i++;
        }
        if (found) {
            res = this.set(value);
        }

        // Make the update
        if (res) {
            this.notifyView();
        }

        // return the result
        return res;
    }

    /**
     * Apply data to the object and its child conform to the mode of the JTOInstance
     * return true if their is any change in the hierarchy
     * @param data the json souce
     * @param applyParent true if the json was applyed to the parent
     * @returns {boolean} true if their is any change in the object or in the child
     */
    public override applyData(data: { [key: string]: any }): boolean {
        return this.applyDataPartiel(data, false);
    }
    public override toJson() {
        return this.get();
    }

    public reset() {
        super.reset();
        this.value = undefined;
    }
}
