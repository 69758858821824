import { AppRegistry } from 'react-native';
import { name as appName } from './app.json';
import App from './App';
import './index.web.css';
import './assets/fonts/Montserrat-Regular.ttf';

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
    initialProps: {},
    rootTag: document.getElementById('app-root'),
});

export default App;
