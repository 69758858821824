import * as React from 'react';
import { Image, View } from 'react-native';
import Svg, { G, Path, SvgProps } from 'react-native-svg';

export const Logo = (props: SvgProps) => {
    return (
        <Image
            source={require('../../assets/logo_petit.png')}
            style={{
                width: props.width ?? 120,
                height: props.height ?? 70,
            }}
            resizeMode='contain'
        />
    );
};

export default Logo;
