import * as React from 'react';
import Svg, { Path, SvgProps, Text } from 'react-native-svg';
import { FONT_HEAVY } from '../../utils/theme';

export interface IBronzeAchivementProps extends SvgProps {
    count: number;
    owned: boolean;
}

function BronzeAchivement(props: IBronzeAchivementProps) {
    return (
        <Svg
            style={{
                position: 'absolute',
                opacity: props.owned ? 1 : 0.65,
            }}
            viewBox='0 0 250 250'
            fill='none'
            {...props}
        >
            <Path
                d='M208.333 114.583c0 46.024-37.309 83.334-83.333 83.334s-83.333-37.31-83.333-83.334c0-46.023 37.31-83.333 83.333-83.333 46.024 0 83.333 37.31 83.333 83.333z'
                fill='#DCA16A'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M214.506 114.583c0 49.433-40.073 89.506-89.506 89.506s-89.506-40.073-89.506-89.506S75.567 25.077 125 25.077s89.506 40.073 89.506 89.506zM125 197.917c46.024 0 83.333-37.31 83.333-83.334 0-46.023-37.309-83.333-83.333-83.333s-83.333 37.31-83.333 83.333c0 46.024 37.31 83.334 83.333 83.334z'
                fill='#C38144'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M47.84 117.67a3.087 3.087 0 01-3.087-3.087c0-44.319 35.928-80.247 80.247-80.247a3.086 3.086 0 110 6.173c-40.91 0-74.074 33.164-74.074 74.074a3.087 3.087 0 01-3.087 3.087z'
                fill='#F0B57F'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M202.16 111.497a3.087 3.087 0 013.087 3.086c0 44.319-35.928 80.247-80.247 80.247a3.086 3.086 0 110-6.173c40.91 0 74.074-33.164 74.074-74.074a3.086 3.086 0 013.086-3.086z'
                fill='#CF945D'
            />
            <Path
                d='M186.729 114.583c0 34.092-27.637 61.729-61.729 61.729-34.091 0-61.728-27.637-61.728-61.729 0-34.091 27.637-61.728 61.728-61.728 34.092 0 61.729 27.637 61.729 61.728z'
                fill='#F7BA82'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M192.901 114.583c0 37.501-30.4 67.902-67.901 67.902-37.5 0-67.901-30.401-67.901-67.902 0-37.5 30.4-67.9 67.901-67.9s67.901 30.4 67.901 67.9zM125 176.312c34.092 0 61.729-27.637 61.729-61.729 0-34.091-27.637-61.728-61.729-61.728-34.091 0-61.728 27.637-61.728 61.728 0 34.092 27.637 61.729 61.728 61.729z'
                fill='#C38144'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M125 167.052c28.978 0 52.469-23.491 52.469-52.469a3.087 3.087 0 016.173 0c0 32.387-26.255 58.642-58.642 58.642a3.086 3.086 0 010-6.173z'
                fill='#E6A66C'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M125 62.114c-28.978 0-52.469 23.492-52.469 52.469a3.087 3.087 0 11-6.173 0c0-32.387 26.255-58.642 58.642-58.642a3.087 3.087 0 110 6.173z'
                fill='#FFD5AE'
            />
            <Path
                d='M30.668 196.752l-7.95-15.9c-.865-1.731.394-3.769 2.33-3.769h34.849a2.604 2.604 0 012.604 2.605v36.458a2.604 2.604 0 01-2.604 2.604h-34.85c-1.935 0-3.194-2.037-2.329-3.769l7.95-15.9a2.603 2.603 0 000-2.329zM219.333 196.752l7.95-15.9c.866-1.731-.393-3.769-2.329-3.769h-34.849a2.604 2.604 0 00-2.604 2.605v36.458a2.604 2.604 0 002.604 2.604h34.849c1.936 0 3.195-2.037 2.329-3.769l-7.95-15.9a2.6 2.6 0 010-2.329z'
                fill='#C52229'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M25.428 197.917l-7.368-14.736c-2.597-5.194 1.18-11.306 6.988-11.306h34.849a7.813 7.813 0 017.812 7.812v36.459a7.812 7.812 0 01-7.812 7.812h-34.85c-5.807 0-9.584-6.112-6.987-11.306l7.368-14.735zm.58 1.161l.002.003 2.33-1.164-2.33 1.164-.002-.003zm198.566-1.161l7.367-14.736c2.598-5.194-1.18-11.306-6.987-11.306h-34.849a7.813 7.813 0 00-7.813 7.812v36.459a7.812 7.812 0 007.813 7.812h34.849c5.807 0 9.585-6.111 6.987-11.306l-7.367-14.735zm2.709-17.065l-7.95 15.9a2.6 2.6 0 000 2.329l7.95 15.9c.866 1.732-.393 3.769-2.329 3.769h-34.849a2.604 2.604 0 01-2.604-2.604v-36.459a2.604 2.604 0 012.604-2.604h34.849c1.936 0 3.195 2.038 2.329 3.769zm-204.564 0l7.95 15.9a2.603 2.603 0 010 2.329l-7.95 15.9c-.866 1.732.393 3.769 2.329 3.769h34.849a2.604 2.604 0 002.604-2.604v-36.459a2.604 2.604 0 00-2.604-2.604h-34.85c-1.935 0-3.194 2.038-2.329 3.769z'
                fill='#9B0F15'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M26.443 179.438a2.605 2.605 0 012.203-1.215h11.068a2.604 2.604 0 110 5.208H32.78l5.378 11.246a2.604 2.604 0 11-4.698 2.247l-7.162-14.974a2.604 2.604 0 01.146-2.512z'
                fill='#DF3840'
            />
            <Path
                d='M197.917 205.729v-36.458a2.604 2.604 0 00-2.604-2.604H54.688a2.604 2.604 0 00-2.604 2.604v36.458a2.604 2.604 0 002.604 2.604h140.625a2.604 2.604 0 002.604-2.604z'
                fill='#F1333B'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M203.126 169.271v36.458a7.813 7.813 0 01-7.813 7.813H54.688a7.813 7.813 0 01-7.812-7.813v-36.458a7.813 7.813 0 017.812-7.813h140.625a7.813 7.813 0 017.813 7.813zm-5.209 0v36.458a2.604 2.604 0 01-2.604 2.604H54.688a2.604 2.604 0 01-2.604-2.604v-36.458a2.604 2.604 0 012.604-2.604h140.625a2.604 2.604 0 012.604 2.604z'
                fill='#9B0F15'
            />
            <Path
                d='M190.723 188.489c-1.475 0-2.67-1.166-2.67-2.605s1.195-2.605 2.67-2.605c1.474 0 2.67 1.166 2.67 2.605s-1.196 2.605-2.67 2.605zM180.563 187.447c-1.834 0-3.321-1.451-3.321-3.24 0-1.79 1.487-3.241 3.321-3.241 1.835 0 3.322 1.451 3.322 3.241 0 1.789-1.487 3.24-3.322 3.24zM185.09 194.855c-.918 0-1.661-.725-1.661-1.62 0-.895.743-1.62 1.661-1.62.917 0 1.66.725 1.66 1.62 0 .895-.743 1.62-1.66 1.62zM182.224 177.72c-.917 0-1.661-.726-1.661-1.621 0-.894.744-1.62 1.661-1.62s1.661.726 1.661 1.62c0 .895-.744 1.621-1.661 1.621zM173.498 193.235c-.918 0-1.661-.725-1.661-1.62 0-.895.743-1.62 1.661-1.62.917 0 1.66.725 1.66 1.62 0 .895-.743 1.62-1.66 1.62zM171.837 184.899c-.917 0-1.661-.725-1.661-1.62 0-.895.744-1.62 1.661-1.62s1.661.725 1.661 1.62c0 .895-.744 1.62-1.661 1.62zM160.516 203.693c-.918 0-1.661-.725-1.661-1.62 0-.895.743-1.62 1.661-1.62.917 0 1.66.725 1.66 1.62 0 .895-.743 1.62-1.66 1.62zM193.556 197.508c-.917 0-1.661-.725-1.661-1.62 0-.895.744-1.62 1.661-1.62s1.661.725 1.661 1.62c0 .895-.744 1.62-1.661 1.62zM177.861 201.056c-1.493 0-2.703-1.18-2.703-2.636 0-1.457 1.21-2.637 2.703-2.637 1.492 0 2.702 1.18 2.702 2.637 0 1.456-1.21 2.636-2.702 2.636zM189.453 203.693c-1.493 0-2.703-1.181-2.703-2.637s1.21-2.637 2.703-2.637c1.493 0 2.703 1.181 2.703 2.637s-1.21 2.637-2.703 2.637zM165.813 198.509c-1.493 0-2.703-1.181-2.703-2.637s1.21-2.637 2.703-2.637c1.493 0 2.703 1.181 2.703 2.637s-1.21 2.637-2.703 2.637zM170.176 204.493c-.917 0-1.66-.725-1.66-1.62 0-.894.743-1.62 1.66-1.62.917 0 1.661.726 1.661 1.62 0 .895-.744 1.62-1.661 1.62z'
                fill='#DB1B24'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M53.712 170.41a2.604 2.604 0 012.604-2.604h97.656a2.604 2.604 0 010 5.208H58.92v18.23a2.604 2.604 0 01-5.208 0V170.41z'
                fill='#FE666D'
            />
            <Text
                x='125'
                textAnchor='middle'
                y='200'
                fontWeight={'bold'}
                fontFamily={FONT_HEAVY}
                fill={'white'}
                fontSize={35}
            >
                {props.count}
            </Text>
        </Svg>
    );
}

export default BronzeAchivement;
