import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Linking, Pressable, Text, View } from 'react-native';
import { ChatBubbleOvalLeftEllipsisIcon } from 'react-native-heroicons/outline';
import {
    ChatBubbleOvalLeftIcon,
    EllipsisVerticalIcon,
    FaceSmileIcon,
    HeartIcon,
    UserIcon,
} from 'react-native-heroicons/solid';
import { mustLoginButton } from '../utils/utils';
import { postReact, postReactDel } from '../action/react';
import UserTiny from '../object/compressed/global/UserTiny';
import { CAN_REACT, POST_CREATE } from '../object/PermissionEvent';
import Post from '../object/Post';
import User from '../object/User';
import BgPost from '../svg/BgPost';
import Cartouche from '../svg/Cartouche';
import { SERVER_URL } from '../utils/constants';
import { styles } from '../utils/styles';
import { FONT_BOLD, HEART_COLOR, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { CartoucheButton } from './CartoucheButton';
import { FileElementView } from './FileElementView';
import { PastilleView } from './PastilleView';
import ReactButton, { REACTION_LIST } from './ReactButton';
import { TinyCartoucheButton } from './TinyCartoucheButton';
import Event from '../object/Event';
import useJTOState from '../utils/jto/useJTOState';
import { CustomTextWithLinks } from './CustomTextWithLinks';

interface IPostViewProps {
    post: Post;
    navigation: any;
    comment?: boolean;
    event?: Event;
    preventOpen?: boolean;
}

export const PostView = (props: IPostViewProps) => {
    const { t } = useTranslation();

    const post = useJTOState(props.post);

    const event = useJTOState(
        props.event ?? User._instance.getEvent() ?? new Event(),
    );

    const user = post.getUser();

    return (
        <Pressable
            style={{
                width: width(),
                borderBottomColor: getColor('border'),
                borderBottomWidth: 1,
            }}
            onPress={() => {
                if (!(props.preventOpen ?? false)) {
                    props.navigation.push('PostCommentModal', {
                        post,
                    });
                }
            }}
        >
            <View
                style={{
                    display: 'flex',
                    width: width(),
                    marginHorizontal: width() * 0.05,
                    marginVertical: 10,
                    flexDirection: 'row',
                }}
            >
                <Pressable
                    key={user?.getJTOIdentifier()}
                    style={{
                        flexDirection: 'row',
                    }}
                    onPress={() => {
                        props.navigation.push('ProfilePage', {
                            user: user instanceof User ? user : user?.toUser(),
                        });
                    }}
                >
                    <PastilleView
                        size={52}
                        color={getColor('secondary')}
                        icon={
                            user?.avatar.get() !== '' &&
                            user?.avatar.get() !== undefined ? (
                                <Image
                                    style={{
                                        height: 46,
                                        width: 46,
                                        backgroundColor: 'white',
                                        borderRadius: 27,
                                    }}
                                    source={{
                                        uri:
                                            SERVER_URL +
                                            '/image/' +
                                            user?.avatar.get(),
                                    }}
                                />
                            ) : (
                                <UserIcon
                                    color={'white'}
                                    size={30}
                                />
                            )
                        }
                    />
                </Pressable>

                <View
                    style={{
                        marginLeft: 12,
                        marginRight: 20,
                        flex: 1,
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            marginRight: 5,
                            alignItems: 'center',
                        }}
                    >
                        <Pressable
                            style={{
                                flex: 1,
                            }}
                            onPress={() => {
                                props.navigation.push('ProfilePage', {
                                    user:
                                        user instanceof User
                                            ? user
                                            : user?.toUser(),
                                });
                            }}
                        >
                            <Text
                                style={{
                                    color: getColor('text'),
                                    fontFamily: FONT_BOLD,
                                    fontSize: 18,
                                }}
                            >
                                {user?.getDisplayName()}
                            </Text>
                        </Pressable>

                        <View
                            style={{
                                alignItems: 'flex-end',
                                paddingLeft: 3,
                            }}
                        >
                            <Text
                                style={[
                                    styles.text,
                                    { color: getColor('hint') },
                                ]}
                            >
                                {post.getDisplayDate(t)}
                            </Text>

                            {post.isFavorite.get() && (
                                <HeartIcon color={HEART_COLOR} />
                            )}
                        </View>

                        <Pressable
                            style={{
                                paddingHorizontal: 5,
                                marginVertical: 1,
                            }}
                            onPress={(e) => {
                                e.stopPropagation();
                                props.navigation.navigate('MenuPostModal', {
                                    post,
                                });
                            }}
                        >
                            <EllipsisVerticalIcon
                                color={getColor('hint')}
                                size={25}
                            />
                        </Pressable>
                    </View>
                    {post.content.get() !== undefined &&
                        post.content.get() !== '' && (
                            <CustomTextWithLinks
                                content={post.content.get() ?? ''}
                                style={[styles.text]}
                                globalStyle={{
                                    marginTop: 5,
                                    marginRight: 20,
                                }}
                            />
                        )}
                    <View
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                        }}
                    >
                        {post.postFileList
                            .getList()
                            .filter((item) => {
                                return (
                                    item.type.get()?.split('/')[0] === 'image'
                                );
                            })
                            .map((item, index) => {
                                return (
                                    <Pressable
                                        key={index}
                                        onPress={(e) => {
                                            e.stopPropagation();
                                            const listUrl = [] as string[];
                                            for (const url of post.postFileList
                                                .getList()
                                                .filter((item) => {
                                                    return (
                                                        item.type
                                                            .get()
                                                            ?.split('/')[0] ===
                                                        'image'
                                                    );
                                                })) {
                                                listUrl.push(
                                                    SERVER_URL +
                                                        '/file/' +
                                                        (url.name.get() ?? ''),
                                                );
                                            }
                                            props.navigation.navigate(
                                                'PostPictureModal',
                                                {
                                                    uri:
                                                        SERVER_URL +
                                                        '/file/' +
                                                        item.name.get(),
                                                    list: listUrl,
                                                    i: index,
                                                },
                                            );
                                        }}
                                    >
                                        <Image
                                            style={{
                                                resizeMode: 'cover',
                                                height: 100,
                                                width: 100,
                                                marginTop: 10,
                                                marginBottom: 5,
                                                marginRight: 15,
                                                borderRadius: 20,
                                                borderColor: getColor('border'),
                                                borderWidth: 1,
                                            }}
                                            source={{
                                                uri:
                                                    SERVER_URL +
                                                    '/file/' +
                                                    item.name.get(),
                                            }}
                                        />
                                    </Pressable>
                                );
                            })}
                    </View>
                    <View>
                        {post.postFileList
                            .getList()
                            .filter((item) => {
                                return (
                                    (item.type.get()?.split('/')?.length ?? 0) >
                                        0 &&
                                    item.type.get()?.split('/')[0] !== 'image'
                                );
                            })
                            .map((file, index) => {
                                return (
                                    <Pressable
                                        key={index}
                                        style={{}}
                                        onPress={() => {
                                            Linking.openURL(
                                                SERVER_URL +
                                                    '/file/' +
                                                    file.name.get(),
                                            );
                                        }}
                                    >
                                        <FileElementView
                                            file={file}
                                            filename={''}
                                        />
                                    </Pressable>
                                );
                            })}
                    </View>

                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        {post.reactCount.get()?.map((react, index) => {
                            return (
                                react.type !== null &&
                                react.count !== 0 && (
                                    <View
                                        key={index}
                                        style={{
                                            flexDirection: 'row',
                                            marginRight: 8,
                                            marginTop: 10,
                                        }}
                                    >
                                        {
                                            <Pressable
                                                style={{
                                                    backgroundColor:
                                                        post.react
                                                            .get()
                                                            ?.reaction.get() ===
                                                        react.type
                                                            ? getColor(
                                                                  'secondary',
                                                              )
                                                            : getColor('hint'),
                                                    paddingRight: 12,
                                                    paddingLeft: 10,
                                                    paddingVertical: 5,
                                                    borderRadius: 10,
                                                }}
                                                onLongPress={() => {
                                                    props.navigation.navigate(
                                                        'PostReactModal',
                                                        {
                                                            post,
                                                        },
                                                    );
                                                }}
                                                onPress={() => {
                                                    if (
                                                        post.react
                                                            .get()
                                                            ?.reaction.get() ===
                                                        react.type
                                                    ) {
                                                        postReactDel(post);
                                                    } else {
                                                        postReact(
                                                            post,
                                                            react.type!,
                                                        );
                                                    }
                                                }}
                                            >
                                                {typeof REACTION_LIST[
                                                    react.type
                                                ] === 'object' ? (
                                                    <View
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                        }}
                                                    >
                                                        <Image
                                                            source={
                                                                (
                                                                    REACTION_LIST[
                                                                        react
                                                                            .type
                                                                    ] as any
                                                                ).source
                                                            }
                                                            resizeMode='contain'
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                                marginRight: 5,
                                                            }}
                                                        />
                                                        <Text
                                                            style={{
                                                                fontFamily:
                                                                    FONT_BOLD,
                                                                color: 'white',
                                                            }}
                                                        >
                                                            {react.count}
                                                        </Text>
                                                    </View>
                                                ) : (
                                                    <Text
                                                        style={{
                                                            fontFamily:
                                                                FONT_BOLD,
                                                            color: 'white',
                                                        }}
                                                    >
                                                        {REACTION_LIST[
                                                            react.type
                                                        ] +
                                                            '  ' +
                                                            react.count}
                                                    </Text>
                                                )}
                                            </Pressable>
                                        }
                                    </View>
                                )
                            );
                        })}
                        <View
                            style={{
                                flexDirection: 'row',
                                marginRight: 8,
                                marginTop: 10,
                            }}
                        ></View>
                    </View>
                    {((User._instance.getEvent()?.hasPermission(CAN_REACT) ??
                        false) ||
                        (User._instance
                            .getEvent()
                            ?.hasPermission(POST_CREATE) ??
                            false) ||
                        User._instance.nothing) && (
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 10,
                                marginRight: 20,
                            }}
                        >
                            {((User._instance
                                .getEvent()
                                ?.hasPermission(CAN_REACT) ??
                                false) ||
                                User._instance.nothing) && (
                                <ReactButton
                                    post={post}
                                    navigation={props.navigation}
                                />
                            )}
                            {((User._instance
                                .getEvent()
                                ?.hasPermission(POST_CREATE) ??
                                false) ||
                                User._instance.nothing) && (
                                <Pressable
                                    style={{
                                        backgroundColor: getColor('hint'),
                                        paddingRight: 15,
                                        paddingLeft: 15,
                                        paddingVertical: 0,
                                        borderRadius: 15,
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                    onPress={() => {
                                        if (!(props.preventOpen ?? false)) {
                                            mustLoginButton(
                                                props.navigation,
                                                t,
                                                () => {
                                                    props.navigation.push(
                                                        'PostCommentModal',
                                                        {
                                                            post,
                                                            event,
                                                        },
                                                    );
                                                },
                                            );
                                        }
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontFamily: FONT_BOLD,
                                            color: 'white',
                                        }}
                                    >
                                        {post.commentCount.get() ?? 0}
                                        {'   '}
                                        {t(
                                            post.commentCount.get() !==
                                                undefined &&
                                                post.commentCount.get()! > 1
                                                ? 'comments'
                                                : 'comment',
                                        )}
                                    </Text>
                                </Pressable>
                                /*<Pressable
                                    onPress={() => {
                                        if (!(props.preventOpen ?? false)) {
                                            props.navigation.push(
                                                'PostCommentModal',
                                                {
                                                    post,
                                                },
                                            );
                                        }
                                    }}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                    <PastilleView
                                        icon={
                                            <ChatBubbleOvalLeftIcon
                                                size={30}
                                                color={'white'}
                                            />
                                        }
                                        color={getColor('hint')}
                                        size={40}
                                    />
                                    </Pressable>*/
                            )}
                        </View>
                    )}
                </View>

                {/*<View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingHorizontal: 20,
                        width: (width()*0.9)-40,
                        height: ((width()*0.9)-40)/3
                    }}
                >
                    <Cartouche
                        style={{
                            marginTop: 0,
                            position: "absolute",
                        }}
                        width={(width()*0.8)}
                        height={((width()*0.8))/3}
                        fill={getColor('card')}
                    />
                    <CartoucheButton
                        height={35}
                        fontSize={15}
                        title="Commentaire"
                        onPress={() => {
                        }}
                        color={getColor('secondary')}
                    />
                    <CartoucheButton
                        height={35}
                        fontSize={15}
                        title="Commentaire"
                        onPress={() => {
                        }}
                        color={getColor('secondary')}
                    />
                </View>*/}
            </View>
        </Pressable>
    );
};
