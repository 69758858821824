export default class JTOUtils {
    public static getAllJsonObject(obj: {
        [key: string]: any;
    }): { [key: string]: any }[] {
        let list: { [key: string]: any }[] = [];
        if (obj != null && obj !== undefined) {
            list.push(obj);
            if (obj instanceof Array) {
                for (const elem of obj) {
                    list = list.concat(JTOUtils.getAllJsonObject(elem));
                }
            } else {
                Object.getOwnPropertyNames(obj).forEach((field: string) => {
                    if (obj[field] instanceof Array) {
                        Array.from(obj[field] as any).forEach((elem: any) => {
                            if (typeof elem === 'object') {
                                list = list.concat(
                                    JTOUtils.getAllJsonObject(elem),
                                );
                            }
                        });
                    } else if (typeof obj[field] === 'object') {
                        list = list.concat(
                            JTOUtils.getAllJsonObject(obj[field]),
                        );
                    }
                });
            }
        }
        return list;
    }
}
