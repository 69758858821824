import JTOElement from '../utils/jto/JTOElement';
import JTOObject from '../utils/jto/JTOObject';
import JTOProperty from '../utils/jto/JTOProperty';

export default class Exposant extends JTOObject {
    public static _TYPE: string = 'Exposant';

    private _endDate: JTOProperty<string>;
    private _startDate: JTOProperty<string>;
    private _idExposant: JTOProperty<number>;
    private _marques: JTOProperty<string>;
    private _raisonSociale1: JTOProperty<string>;
    private _raisonSociale2: JTOProperty<string>;

    constructor(parent?: JTOElement | null) {
        super(Exposant._TYPE, parent);

        this._endDate = new JTOProperty('end_date', this);
        this._startDate = new JTOProperty('start_date', this);
        this._idExposant = new JTOProperty('id_exposant', this);
        this._marques = new JTOProperty('marques', this);
        this._raisonSociale1 = new JTOProperty('raison_sociale1', this);
        this._raisonSociale2 = new JTOProperty('raison_sociale2', this);
    }

    public get endDate(): string {
        return this._endDate.get() ?? '';
    }

    public set endDate(value: string) {
        this._endDate.set(value);
    }

    public get startDate(): string {
        return this._startDate.get() ?? '';
    }

    public set startDate(value: string) {
        this._startDate.set(value);
    }

    public get idExposant(): number {
        return this._idExposant.get() ?? 0;
    }

    public set idExposant(value: number) {
        this._idExposant.set(value);
    }

    public get marques(): string {
        return this._marques.get() ?? '';
    }

    public set marques(value: string) {
        this._marques.set(value);
    }

    public get raisonSociale1(): string {
        return this._raisonSociale1.get() ?? '';
    }

    public set raisonSociale1(value: string) {
        this._raisonSociale1.set(value);
    }

    public get raisonSociale2(): string {
        return this._raisonSociale2.get() ?? '';
    }

    public set raisonSociale2(value: string) {
        this._raisonSociale2.set(value);
    }
}
