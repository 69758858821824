import { get, getRestore, post } from '../utils/http';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActivityIndicator,
    Pressable,
    Text,
    TextInput,
    View,
} from 'react-native';
import { MpInput } from '../base/MpInput';
import User from '../object/User';
import { styles } from '../utils/styles';
import { marginBottom, marginTop } from '../utils/tailwinds';
import {
    APP_EVENT_ID,
    APP_NAME,
    APP_NEED_PASSWORD,
    APP_PUBLIC_EVENT_ID,
    getColor,
    initTheme,
} from '../utils/theme';
import { height, width } from '../utils/utils';
import { HTTPUtils } from 'mp-ts-http';
import { getErrorMessage } from 'mp-ts-error';
import { EyeIcon, EyeSlashIcon } from 'react-native-heroicons/solid';
import { Circle, Svg, SvgUri } from 'react-native-svg';
import Ge from '../svg/Ge';
import Cartouche from '../svg/Cartouche';
import { NAVIGATION_BAR_HEIGHT, STATUS_BAR_HEIGHT } from '../utils/screen';
//import { ScrollView } from 'react-native-gesture-handler';
import BgGe from '../svg/BgGe';
import { GeSpinner } from '../component/GeSpinner';
import { HeaderSimplePage } from '../component/HeaderSimplePage';
import { CartoucheButton } from '../component/CartoucheButton';
import { ContentCartouche } from '../component/ContentCartouche';
import { Header } from '../component/Header';
import Logo from '../svg/Logo';
import { CommonActions } from '@react-navigation/native';
import { SERVER_URL } from '../utils/constants';
import { BEFORE_LOGO } from '../utils/config';
import { applyResult } from '../utils/applyResult';

interface IBeforeLoginProps {
    navigation: any;
}

export const BeforeLoginPage = (props: IBeforeLoginProps) => {
    initTheme('light');

    //const scrollRef = useRef<any>(null);

    const { t } = useTranslation();
    User.translation = t;

    /*const scrollToBottom = () => {
        scrollRef.current.scrollToEnd({ animated: true });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 50);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 100);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 150);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 200);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 250);
    };*/

    const onLogin = () => {
        props.navigation.navigate('Login');
    };
    const onRegister = () => {
        props.navigation.navigate('Inscrip');
    };
    const onSkip = () => {
        props.navigation.navigate('Home');
    };
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const connectAsGuest = async () => {
        setLoading(true);
        post(
            '/user/registernothing',
            {
                id: APP_PUBLIC_EVENT_ID,
            },
            true,
        )
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    setMessage('');

                    const data = HTTPUtils.getResult(res);
                    User._instance?.login(data);
                    props.navigation.navigate('Event');
                } else {
                    setMessage(t(HTTPUtils.getError(res)) ?? '');
                }
            })
            .catch((err) => {
                setLoading(false);
                setMessage(t('went_wrong_no_internet') ?? '');
            });
    };

    return (
        <View style={[styles.page]}>
            <Header
                title={APP_NAME}
                beforeLogo={BEFORE_LOGO}
                fallback={
                    <Logo
                        height={50}
                        width={50}
                    />
                }
                fallbackColor={'white'}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                full
                logo={''}
            >
                <View
                    style={{}}
                    //ref={scrollRef}
                    // @ts-ignore

                    // @ts-ignore
                    //automaticallyAdjustKeyboardInsets={true}
                >
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1,
                            marginRight: width() * 0.15,
                            marginLeft: width() * 0.15,
                            width: width() * 0.7,
                        }}
                    >
                        <HeaderSimplePage
                            color={getColor('ge')}
                            title={t('connection_page_title')}
                            description={t('connection_page_description')}
                        />

                        {!loading ? (
                            <View
                                style={[
                                    styles.box,
                                    {
                                        width: width(),
                                        marginTop: 20,
                                    },
                                ]}
                            >
                                <CartoucheButton
                                    onPress={() => {
                                        onLogin();
                                    }}
                                    color={getColor('ge')}
                                    title={t('connection')}
                                    height={70}
                                />

                                <View
                                    style={{
                                        marginTop: 10,
                                    }}
                                >
                                    <CartoucheButton
                                        onPress={() => {
                                            onRegister();
                                        }}
                                        color={getColor('ge')}
                                        title={t('register')}
                                        height={70}
                                    />
                                </View>

                                {message !== '' && (
                                    <Text
                                        style={[
                                            styles.textError,
                                            marginTop(25),
                                        ]}
                                    >
                                        {message}
                                    </Text>
                                )}

                                <Pressable
                                    onPress={() => {
                                        connectAsGuest();
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                textAlign: 'center',
                                                marginTop: 25,
                                                color: getColor('ge'),
                                            },
                                        ]}
                                    >
                                        {t('continue_without_login')}
                                    </Text>
                                </Pressable>
                            </View>
                        ) : (
                            <View style={[styles.boxCenter, marginTop(75)]}>
                                <GeSpinner color={getColor('ge')} />
                            </View>
                        )}
                    </View>
                </View>
            </Header>
        </View>
    );
};
