import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import fr from './translations/fr.json';

const resources = {
    en: {
        translation: en,
    },
    fr: {
        translation: fr,
    },
};

i18n.use(initReactI18next).init({
    resources,
    nonExplicitSupportedLngs: true,
    compatibilityJSON: 'v3',
    // language to use if translations in user language are not available
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
});

export default i18n;
