import {
    View,
    Text,
    Pressable,
    TextInput,
    ActivityIndicator,
} from 'react-native';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT, STATUS_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import { Image, Animated, Dimensions } from 'react-native';
import React, { useState, useRef, createRef } from 'react';
import {
    PanGestureHandler,
    PinchGestureHandler,
    State,
} from 'react-native-gesture-handler';
import ImageViewer from 'react-native-web-image-zoom-viewer';
import { SERVER_URL } from '../utils/constants';
import { XMarkIcon } from 'react-native-heroicons/solid';

interface IPostPictureModalProps extends IModalProps {
    uri: string;
    list?: string[];
    i?: number;
}

export const PostPictureModal = (props: IPostPictureModalProps) => {
    const { t } = useTranslation();
    let images = [
        {
            // Simplest usage.
            url: (props as any).route.params.uri,

            // width: number
            // height: number
            // Optional, if you know the image size, you can set the optimization performance

            // You can pass props to <Image />.
            props: {
                // headers: ...
            },
        },
    ];
    if ((props as any).route.params.list) {
        images = [];
        for (let i = 0; i < (props as any).route.params.list.length; i++) {
            images.push({
                // Simplest usage.
                url: (props as any).route.params.list[i],

                // width: number
                // height: number
                // Optional, if you know the image size, you can set the optimization performance

                // You can pass props to <Image />.
                props: {
                    // headers: ...
                },
            });
        }
    }

    return (
        <View
            style={{
                position: 'absolute',
                top: 0,
                zIndex: 100,
                left: 0,
                right: 0,
                bottom: 0,

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
            }}
        >
            <View
                style={{
                    borderRadius: 15,
                    backgroundColor: 'white',
                    width: '100%',
                    height: '100%',
                }}
            >
                {
                    <ImageViewer
                        imageUrls={images}
                        index={(props as any).route.params.i ?? 0}
                        renderHeader={() => <></>}
                        enableSwipeDown={true}
                        onSwipeDown={() => {
                            props.navigation.goBack();
                        }}
                    />
                }
            </View>

            <Pressable
                style={{
                    position: 'absolute',
                    height: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 50,
                    left: 10,
                    top: STATUS_BAR_HEIGHT + 10,
                }}
                onPress={() => {
                    props.navigation.goBack();
                }}
            >
                <XMarkIcon
                    size={35}
                    fill={'white'}
                />
            </Pressable>
        </View>
    );
};
