// tslint:disable-no-console

import { useCallback, useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid/non-secure';
import JTOElement from './jto/JTOElement';
import JTOContainer from './jto/JTOContainer';
import JTOInstance from './jto/JTOInstance';

function useNavigationJTOState<T extends JTOElement>(
    elem: T,
    navigation: any,
    restore?: () => void,
    clear?: () => void,
): T {
    const elemSave = useRef<T>(elem);
    const [time, setTime] = useState(new Date().getTime());
    const id = useRef(nanoid());

    const func = useCallback(() => {
        setTime(new Date().getTime());
    }, []);

    const container = useRef(
        new JTOContainer(id.current ?? '', elemSave.current, func),
    );

    useEffect(() => {
        const unsubscribeFocus = navigation.addListener('focus', () => {
            JTOInstance.addContainer(container.current);
            elemSave.current?.addContainer(container.current);
            restore?.();
            // Perform tasks when the screen is focused
        });

        const unsubscribeBlur = navigation.addListener('blur', () => {
            elemSave.current?.removeContainerObj(container.current);
            JTOInstance.removeContainerObj(container.current);
            clear?.();
        });

        return () => {
            unsubscribeFocus();
            unsubscribeBlur();
        };
    }, [navigation]);

    return elemSave.current;
}

export default useNavigationJTOState;
