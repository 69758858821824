import { HTTPUtils } from 'mp-ts-http';

import JTOObject from '../../../utils/jto/JTOObject';
import JTOSimple from '../../../utils/jto/JTOSimple';
import JTOProperty from '../../../utils/jto/JTOProperty';
import JTORelProperty from '../../../utils/jto/JTORelProperty';
import JTOList from '../../../utils/jto/JTOList';
import JTOElement from '../../../utils/jto/JTOElement';

import { getRestore, post } from '../../../utils/http';
import Answer from '../.././Answer';
import InviteTiny from '../.././compressed/global/InviteTiny';
import Contact from '../.././Contact';
import EventType from '../.././EventType';
import Invite from '../.././Invite';
import Event from '../../Event';
import InterestType from '../../InterestType';

export default class InterestPointTiny extends JTOObject {
    public static _TYPE: string = 'Event';

    public name: JTOProperty<string>;
    public nameAddress: JTOProperty<string>;

    public eventTypeAlias: JTOProperty<string>;

    public isFavorite: JTOProperty<boolean>;

    public typeAlias: JTOProperty<string>;

    public interestType: JTOSimple<InterestType>;

    public isInterest: JTOProperty<boolean>;

    public address: JTOProperty<string>;

    public zipcode: JTOProperty<string>;

    public city: JTOProperty<string>;

    public country: JTOProperty<string>;

    public description: JTOProperty<string>;
    public longitude: JTOProperty<string>;
    public latitude: JTOProperty<string>;

    public is_favorite: JTOProperty<boolean>;

    public toEvent(): Event {
        const user = new Event();
        const params = this.toJson();
        user.applyData(params);
        user.isInterest.set(true);
        return user;
    }

    constructor(parent?: JTOElement | null) {
        super(InterestPointTiny._TYPE, parent);

        this.typeAlias = new JTOProperty<string>('type_alias', this);
        this.interestType = new JTOSimple<InterestType>(
            InterestType,
            'has_interest_type',
            this,
        );

        this.isInterest = new JTOProperty<boolean>('is_interest', this);

        this.nameAddress = new JTOProperty<string>('name_address', this);
        this.eventTypeAlias = new JTOProperty<string>('eventtype_alias', this);

        this.name = new JTOProperty<string>('name', this);

        this.isFavorite = new JTOProperty<boolean>('is_favorite', this);

        this.address = new JTOProperty<string>('address', this);

        this.zipcode = new JTOProperty<string>('zipcode', this);

        this.city = new JTOProperty<string>('city', this);

        this.country = new JTOProperty<string>('country', this);
        this.description = new JTOProperty<string>('description', this);

        this.longitude = new JTOProperty<string>('longitude', this);
        this.latitude = new JTOProperty<string>('latitude', this);
        this.is_favorite = new JTOProperty<boolean>('is_favorite', this);
    }

    public isRootEvent(): boolean {
        return false;
    }

    getAddress() {
        let address = '';

        if (this.address.get() !== undefined) {
            address += this.address.get() + ' ';
        }
        if (this.zipcode.get() !== undefined) {
            address += this.zipcode.get() + ' ';
        }
        if (this.city.get() !== undefined) {
            address += this.city.get() + ' ';
        }
        if (this.country.get() !== undefined) {
            address += this.country.get();
        }

        return address;
    }

    getNameAddress() {
        return this.nameAddress.get() ?? '';
    }

    asAddress(){
        return this.getNameAddress() !== '' || this.getAddress().trim() !== ''
    }
}
