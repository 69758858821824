import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View, Text, Pressable, Image } from 'react-native';
import { FaceSmileIcon } from 'react-native-heroicons/solid';
//import { Portal } from 'react-native-paper';
import { postReact, postReactDel } from '../action/react';
import Event from '../object/Event';
import Post from '../object/Post';
import User from '../object/User';
import { getColor } from '../utils/theme';
import { height, mustLoginButton, width } from '../utils/utils';
import { CartoucheButton } from './CartoucheButton';
import { PastilleView } from './PastilleView';

interface IPostReactionProps {
    post: Post;
    navigation: any;
}

export const REACTION_LIST = [
    '👍',
    '❤️',
    '😍',
    '🔥',
    '👏',
    '😂',
    { type: 'image', source: require('../../assets/logo_petit.png') },
];

const ReactButton = (props: IPostReactionProps) => {
    const { t } = useTranslation();

    // X Y FOR MODAL
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    // GET MODEL
    const postObj = props.post;
    const myReaction = postObj.react.get()?.reaction.get() ?? -1;

    // STATE FOR MODAL
    const [reactionPickerVisible, setReactionPickerVisible] = useState(false);

    return (
        <View
            style={{
                position: 'relative',
                marginRight: 10,
            }}
        >
            <Pressable
                onPress={(e) => {
                    mustLoginButton(props.navigation, t, () => {
                        // GET WHERE TO OPEN MODAL
                        // WHERE THE USER CLICK
                        setX(e.nativeEvent.pageX);
                        setY(e.nativeEvent.pageY);
                        if (myReaction === -1) {
                            setReactionPickerVisible(!reactionPickerVisible);
                        } else {
                            postReactDel(postObj);
                            setReactionPickerVisible(false);
                        }
                    });
                }}
                onLongPress={(e) => {
                    mustLoginButton(props.navigation, t, () => {
                        props.navigation.navigate('PostReactModal', {
                            post: props.post,
                        });
                    });
                }}
            >
                <PastilleView
                    icon={
                        myReaction !== -1 ? (
                            typeof REACTION_LIST[myReaction] === 'object' &&
                            (REACTION_LIST[myReaction] as any).type ===
                                'image' ? (
                                <Image
                                    source={
                                        (REACTION_LIST[myReaction] as any)
                                            .source
                                    }
                                    resizeMode='contain'
                                    style={{ width: 20, height: 20 }}
                                />
                            ) : (
                                <Text>{REACTION_LIST[myReaction] as any}</Text>
                            )
                        ) : (
                            <FaceSmileIcon
                                size={30}
                                color={'white'}
                            />
                        )
                    }
                    color={
                        myReaction !== -1
                            ? getColor('secondary')
                            : getColor('hint')
                    }
                    size={40}
                />
            </Pressable>

            {reactionPickerVisible && (
                //<Portal>
                <View>
                    <View
                        style={{
                            position: 'relative',
                            zIndex: 2500,
                        }}
                    >
                        <View
                            style={{
                                backgroundColor: 'white',
                                borderRadius: 25,
                                flexDirection: 'row',
                                elevation: 6,
                                position: 'relative',
                                zIndex: 2500,
                                shadowColor: getColor('hint'),
                            }}
                        >
                            {REACTION_LIST.map((reaction, index) => (
                                <TouchableOpacity
                                    key={index}
                                    style={{
                                        padding: 10,
                                    }}
                                    onPress={() => {
                                        setReactionPickerVisible(false);
                                        postReact(postObj, index);
                                    }}
                                >
                                    {typeof reaction === 'object' &&
                                    reaction.type === 'image' ? (
                                        <Image
                                            source={reaction.source}
                                            resizeMode='contain'
                                            style={{ width: 20, height: 20 }}
                                        />
                                    ) : (
                                        <Text
                                            style={{
                                                fontSize: 18,
                                                color: getColor('text'),
                                            }}
                                        >
                                            {reaction as any}
                                        </Text>
                                    )}
                                </TouchableOpacity>
                            ))}
                        </View>
                    </View>
                </View>
                //</Portal>
            )}
        </View>
    );
};

export default ReactButton;
