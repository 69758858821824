import { Component } from 'react';
import React from 'react';
import { Text, Touchable, TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { TrashIcon } from 'react-native-heroicons/solid';
import { isDarkMode } from '../utils/theme';

export interface IFileInputProps {
    onChange: (files: File[] | null) => void;
    value: File[] | null;
    t: TFunction<'translation', undefined, 'translation'>;
    single?: boolean;
}

export interface IFileInputState {
    drag: boolean;
    isHovered: boolean;
}

export default class MpFileInput extends Component<
    IFileInputProps,
    IFileInputState
> {
    constructor(props: IFileInputProps) {
        super(props);

        this.state = {
            drag: false,
            isHovered: false,
        };
    }

    handleDrag(
        e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>,
    ) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            this.setState({ drag: true });
        } else if (e.type === 'dragleave') {
            this.setState({ drag: false });
        }
    }

    handleDrop(
        e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>,
    ) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ drag: false });
        if (e.dataTransfer?.files && e.dataTransfer.files.length) {
            const files = Array.from(e.dataTransfer.files);
            this.props.onChange(files);
        } else {
            this.props.onChange(null);
        }
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.currentTarget.files && e.currentTarget.files.length) {
            const files = Array.from(e.currentTarget.files);
            this.props.onChange(files);
        } else {
            this.props.onChange(null);
        }
    }

    render() {
        const { onChange, value, t, single } = this.props;
        const { drag, isHovered } = this.state;
        //const { t } = useTranslation();

        return (
            <form
                style={{
                    width: '100%',
                    height: 192,
                    backgroundColor: isDarkMode() ? '#000' : '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                onDragEnter={(e) => this.handleDrag(e)}
                onSubmit={(e) => e.preventDefault()}
            >
                <input
                    type='file'
                    className='hidden'
                    id='input-file-upload'
                    multiple={single ? false : true} // Permettre la sélection de fichiers multiples
                    onChange={(e) => this.handleChange(e)}
                />
                <label
                    htmlFor='input-file-upload'
                    style={{
                        height: '100%',
                        backgroundColor: drag
                            ? isDarkMode()
                                ? '#1E3A8A'
                                : '#3B82F6' // dark:bg-blue-700 dark:text-white when dragged
                            : isDarkMode()
                            ? isHovered
                                ? '#1F2937'
                                : '#374151' // dark:hover:bg-gray-800 : dark:bg-gray-700
                            : isHovered
                            ? '#F3F4F6'
                            : '#F9FAFB', // hover:bg-gray-100 : bg-gray-50
                        borderRadius: 8, // rounded-lg
                        paddingInline: 48, // py-12
                        borderWidth: 2, // border-2
                        borderColor: isDarkMode()
                            ? isHovered
                                ? '#9CA3AF'
                                : '#4B5563'
                            : '#D1D5DB', // dark:hover:border-gray-500 : dark:border-gray-600 : border-gray-300
                        borderStyle: 'dashed', // border-dashed
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center', // justify-center
                        alignItems: 'center', // items-center
                        color: isDarkMode()
                            ? drag
                                ? '#FFFFFF'
                                : '#9CA3AF'
                            : '#000000', // dark:text-gray-400 when not dragged
                    }}
                    onMouseEnter={() => this.setState({ isHovered: true })}
                    onMouseLeave={() => this.setState({ isHovered: false })}
                >
                    {/* Affichage conditionnel basé sur si des fichiers sont sélectionnés */}
                    {!value || value.length === 0 ? (
                        <p className='mb-2 text-sm'>
                            <span className='font-semibold'>
                                Cliquez pour rechercher
                            </span>{' '}
                            ou glisser-déposer
                        </p>
                    ) : (
                        <div>
                            <p
                                style={{
                                    fontWeight: '600', // font-semibold
                                    marginBottom: 8, // mb-2
                                    fontSize: 16, // text-base
                                    color: isDarkMode() ? '#FFFFFF' : '#000000', // text-black for light mode, dark:text-white for dark mode
                                }}
                            >
                                {value.map((file, index) => (
                                    <div key={index}>{file.name}</div>
                                ))}
                            </p>
                            <TouchableOpacity onPress={() => onChange(null)}>
                                <TrashIcon
                                    fill={'black'}
                                    size={28}
                                />
                                <Text>{t('delete')}</Text>
                            </TouchableOpacity>
                        </div>
                    )}
                </label>
                {drag && (
                    <div
                        style={{
                            position: 'absolute', // absolute
                            width: '100%', // w-full
                            height: '100%', // h-full
                            top: 0, // top-0
                            bottom: 0, // bottom-0
                            right: 0, // right-0
                            left: 0, // left-0
                        }}
                        onDragEnter={(e) => this.handleDrag(e)}
                        onDragLeave={(e) => this.handleDrag(e)}
                        onDragOver={(e) => this.handleDrag(e)}
                        onDrop={(e) => this.handleDrop(e)}
                    ></div>
                )}
            </form>
        );
    }
}
