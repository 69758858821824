import JTOElement from '../utils/jto/JTOElement';
import JTOObject from '../utils/jto/JTOObject';
import JTOProperty from '../utils/jto/JTOProperty';
import JTOSimple from '../utils/jto/JTOSimple';
import Exposant from './Exposant';

export default class Stand extends JTOObject {
    public static _TYPE: string = 'Stand';

    private _idStand: JTOProperty<number>;
    private _hall: JTOProperty<string>;
    private _standName: JTOProperty<string>;
    private _surface: JTOProperty<string>;
    private _solde: JTOProperty<string>;
    private _codeGSM: JTOProperty<string>;
    private _premium: JTOProperty<boolean>;

    private _exposant: JTOSimple<Exposant>;

    constructor(parent?: JTOElement | null) {
        super(Stand._TYPE, parent);

        this._idStand = new JTOProperty('id_stand', this);
        this._hall = new JTOProperty('hall', this);
        this._standName = new JTOProperty('stand_name', this);
        this._surface = new JTOProperty('surface', this);
        this._solde = new JTOProperty('solde', this);
        this._codeGSM = new JTOProperty('code', this);
        this._exposant = new JTOSimple(Exposant, 'has_exposant', this);
        this._premium = new JTOProperty('premium', this);
    }

    public get idStand(): number {
        return this._idStand.get() ?? 0;
    }

    public set idStand(value: number) {
        this._idStand.set(value);
    }

    public get hall(): string {
        return this._hall.get() ?? '';
    }

    public set hall(value: string) {
        this._hall.set(value);
    }

    public get standName(): string {
        return this._standName.get() ?? '';
    }

    public set standName(value: string) {
        this._standName.set(value);
    }

    public get surface(): string {
        return this._surface.get() ?? '';
    }

    public set surface(value: string) {
        this._surface.set(value);
    }

    public get solde(): string {
        return this._solde.get() ?? '';
    }

    public set solde(value: string) {
        this._solde.set(value);
    }

    public get codeGSM(): string {
        return this._codeGSM.get() ?? '';
    }

    public set codeGSM(value: string) {
        this._codeGSM.set(value);
    }

    public get exposant(): Exposant {
        return this._exposant.get() ?? new Exposant();
    }

    public set exposant(value: Exposant) {
        this._exposant.set(value);
    }

    public get premium(): boolean {
        return this._premium.get() ?? false;
    }

    public set premium(value: boolean) {
        this._premium.set(value);
    }
}
