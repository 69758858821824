import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation, withTranslation } from 'react-i18next';
import i18next from 'i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { IModalProps } from './IModalProps';
import User from '../object/User';
import { Header } from '../component/Header';
import { PastilleView } from '../component/PastilleView';
//import BouncyCheckbox from 'react-native-bouncy-checkbox';

const actionList = [
    {
        label: 'Français',
        flag: '🇫🇷',
        identifier: 'fr',
        onClick: (navigation: any) => {
            navigation.navigate('Français');
        },
    },

    {
        label: 'English',
        flag: '🇬🇧',
        identifier: 'en',
        onClick: (navigation: any) => {
            navigation.navigate('Anglais');
        },
    },
];

export const LanguageModal = (props: IModalProps) => {
    const { t, i18n } = useTranslation();
    User.translation = t;

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('lang')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: 15,
                    }}
                >
                    {actionList.map((action, index) => {
                        return (
                            <Pressable
                                key={index}
                                style={[
                                    index !== 0 && {
                                        borderTopWidth: 1,
                                        borderColor: getColor('border'),
                                    },
                                    {
                                        flexDirection: 'row',
                                        marginHorizontal: 20,
                                        padding: 10,
                                        paddingRight: 0,
                                        alignItems: 'center',
                                        backgroundColor: getColor('card'),
                                    },
                                    index === 0 && {
                                        borderTopLeftRadius: 15,
                                        borderTopRightRadius: 15,
                                    },
                                    index === actionList.length - 1 && {
                                        borderBottomLeftRadius: 15,
                                        borderBottomRightRadius: 15,
                                    },
                                ]}
                                onPress={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    i18next.changeLanguage(action.identifier);
                                    AsyncStorage.setItem(
                                        'lang',
                                        action.identifier,
                                    );
                                    props.navigation.goBack();
                                    props.navigation.goBack();
                                }}
                            >
                                <Text
                                    style={[
                                        styles.buttonScreenText,
                                        {
                                            color: getColor('primary'),
                                            fontFamily: FONT_REGULAR,
                                        },
                                    ]}
                                >
                                    {action.flag}
                                </Text>
                                <Text
                                    adjustsFontSizeToFit
                                    style={[
                                        {
                                            marginLeft: 10,
                                            color: 'black',
                                            fontFamily: FONT_MEDIUM,
                                            fontSize: 16,
                                            flex: 1,
                                        },
                                    ]}
                                >
                                    {t(action.label)}
                                </Text>
                                {/*<BouncyCheckbox
                                    fillColor={getColor('secondary')}
                                    isChecked={
                                        i18next.language === action.identifier
                                    }
                                    textStyle={[
                                        styles.text,
                                        {
                                            fontSize: 15,
                                            textDecorationLine: 'none',
                                        },
                                    ]}
                                    onPress={() => {
                                        i18next.changeLanguage(
                                            action.identifier,
                                        );
                                        AsyncStorage.setItem(
                                            'lang',
                                            action.identifier,
                                        );
                                        props.navigation.goBack();
                                        props.navigation.goBack();
                                    }}
                                />*/}
                            </Pressable>
                        );
                    })}
                </View>
            </Header>
        </View>
    );
};
