import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function Opening(props) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 69.58 115.93'
            {...props}
        >
            <path
                fill='currentColor'
                d='M69.58 111.47c0 1.75-.51 3.17-2.13 4.01-1.68.86-3.15.37-4.58-.71-8.9-6.7-17.82-13.38-26.74-20.07l-1.34-.99c-6.75 5.06-13.41 10.06-20.07 15.05-2.67 2-5.36 3.98-8.01 6.01-1.43 1.09-2.91 1.57-4.58.7-1.62-.84-2.12-2.26-2.12-4.02C.02 75.76 0 40.07.01 4.37.01 1.5 1.53 0 4.43 0h60.73c2.87 0 4.41 1.52 4.41 4.38 0 35.7 0 71.39.01 107.09z'
                id='Calque_1-2'
                data-name='Calque 1'
                strokeWidth={0}
            />
        </svg>
    );
}

export default Opening;
