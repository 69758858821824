import { Pressable, Text } from 'react-native';
import React from 'react';
import TinyCartouche from '../svg/TinyCartouche';
import { FONT_BOLD, getColor } from '../utils/theme';
import { width } from '../utils/utils';

interface ITinyCartoucheButtonProps {
    onPress: () => void;
    height?: number;
    title: string;
    color?: string;
    fontSize?: number;
}

export const TinyCartoucheButton = (props: ITinyCartoucheButtonProps) => {
    const {
        height,
        onPress,
        fontSize = 35,
        color = getColor('primary'),
        title,
    } = props;
    return (
        <Pressable
            style={{
                position: 'relative',
                justifyContent: 'center',
                backgroundColor: color,
                borderRadius: 100,
                paddingHorizontal: 14,
                paddingVertical: 7,
                alignItems: 'center',
            }}
            onPress={() => {
                onPress();
            }}
        >
            <Text
                adjustsFontSizeToFit={true}
                numberOfLines={1}
                style={{
                    fontFamily: FONT_BOLD,
                    color: 'white',
                    fontSize,
                    width: '100%',
                    textAlign: 'center',
                    paddingHorizontal: 4,
                }}
            >
                {title}
            </Text>
        </Pressable>
    );
};
