import JTOElement from './JTOElement';
import JTOList from './JTOList';
import JTOObject from './JTOObject';
import { get } from 'mp-ts-query';
import { HTTPUtils } from 'mp-ts-http';
import { applyResult } from '../applyResult';

export default class JTOListFetch<
    Type extends JTOObject,
> extends JTOList<Type> {
    private _url: string = '';
    private _first: boolean = true;
    private _start: boolean = false;
    private _loading: boolean = false;
    private _complite: boolean = false;

    constructor(
        url: string,
        typeList?:
            | (new (...args: any) => Type)
            | (new (...args: any) => Type)[],
        field: string[] | string | null = null,
        JTOParent: JTOElement | null = null,
        sortFunc: ((a: Type, b: Type) => number) | null = null,
    ) {
        super(typeList, field, JTOParent, sortFunc);
        this._url = url;
    }

    public isFirst(): boolean {
        return this._first;
    }

    public setFirst(first: boolean) {
        this._first = first;
    }

    public isLoading(): boolean {
        return this._loading;
    }

    public setLoading(loading: boolean) {
        this._loading = loading;
    }

    public isStart(): boolean {
        return this._start;
    }

    public setStart(start: boolean) {
        this._start = start;
    }

    public isComplite(): boolean {
        return this._complite;
    }

    public setComplite(complite: boolean) {
        this._complite = complite;
    }

    public reset() {
        super.reset();
        this._complite = false;
        this._start = false;
        this._loading = false;
        this._first = true;
    }

    public async refetch(params: { [key: string]: any } = {}) {
        this.setComplite(false);
        this.setStart(true);
        this.setLoading(true);
        this.setFirst(true);

        this.notifyView();

        const result = await get(this._url, {
            ...params,
            skip: this.getList().length,
            limit: 10,
        });

        this.setFirst(false);
        this.setStart(false);
        this.setLoading(false);

        if (HTTPUtils.isSuccess(result)) {
            const data = HTTPUtils.getResult(result);

            const lengthBefore = this.getList().length;

            /**
             * JTO parent of this JTOList
             */
            const jtoParent = this.getJTOParent();
            if (jtoParent !== null) {
                applyResult(data, jtoParent, true);
            }
            applyResult(data, this, true);

            const lengthAfter = this.getList().length;

            this.setComplite(lengthBefore === lengthAfter);
        } else {
            this.setComplite(true);
        }

        this.notifyView();
    }

    public async fetch(params: { [key: string]: any } = {}) {
        if (
            this.isFirst() &&
            !this.isStart() &&
            !this.isLoading() &&
            !this.isComplite()
        ) {
            await this.refetch(params);
        }
    }

    public async more(params: { [key: string]: any } = {}) {
        if (
            !this.isFirst() &&
            !this.isStart() &&
            !this.isLoading() &&
            !this.isComplite()
        ) {
            this.setFirst(false);
            this.setStart(true);
            this.setLoading(true);

            this.notifyView();

            const result = await get(this._url, {
                ...params,
                skip: this.getList().length,
                limit: 10,
            });

            this.setFirst(false);
            this.setStart(false);
            this.setLoading(false);

            if (HTTPUtils.isSuccess(result)) {
                const data = HTTPUtils.getResult(result);

                const lengthBefore = this.getList().length;

                /**
                 * JTO parent of this JTOList
                 */
                const jtoParent = this.getJTOParent();
                if (jtoParent !== null) {
                    applyResult(data, jtoParent, true);
                }
                applyResult(data, this, true);

                const lengthAfter = this.getList().length;

                this.setComplite(lengthBefore === lengthAfter);
            } else {
                this.setComplite(true);
            }

            this.notifyView();
        }
    }
}
