import React, { useEffect, useRef } from 'react';
import {
    AppState,
    KeyboardAvoidingView,
    Linking,
    LogBox,
    Platform,
    StatusBar,
    useColorScheme,
    View,
    Text,
} from 'react-native';
import { AppRoutes } from './src/component/AppRoutes';
import { APP_EVENT_ID, getColor, initTheme } from './src/utils/theme';
import './i18n.config';
import User from './src/object/User';
import Event from './src/object/Event';
import Invite from './src/object/Invite';
import Post from './src/object/Post';
//import { Provider } from 'react-native-paper';
//import messaging from '@react-native-firebase/messaging';
import EventType from './src/object/EventType';
import { post } from './src/utils/http';
import File from './src/object/File';
import PostFile from './src/object/PostFile';
import Group from './src/object/Group';
import ProfilFile from './src/object/ProfilFile';
//import Keychain from 'react-native-keychain';
import Contact from './src/object/Contact';
import { Message } from './src/object/Message';
import ChatGroup from './src/object/ChatGroup';
import FileMessage from './src/object/FileMessage';
import History from './src/object/History';
//import { MMKVLoader } from 'react-native-mmkv-storage';

//export const storage = new MMKVLoader().initialize();

import { closeAction } from './src/utils/utils';
import RoleEvent from './src/object/RoleEvent';
import PermissionEvent from './src/object/PermissionEvent';
import { styles } from './src/utils/styles';
import OpenQuestion from './src/object/OpenQuestion';
import Question from './src/object/Question';
import Response from './src/object/Response';
import { Confetti, startConfetti } from './src/component/Confetti';
import SucccessAnimation from './src/component/SuccessAnimation';
import DirectQuestion from './src/object/DirectQuestion';
import { ExperienceView } from './src/component/ExperienceView';
import JTOInstance from './src/utils/jto/JTOInstance';
import InterestType from './src/object/InterestType';
import InterestPointTiny from './src/object/compressed/event/InterestPointTiny';
import NewHistory from './src/object/NewHistory';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import './index.web.css';
import FormRegistration from './src/object/FormRegistration';

LogBox.ignoreLogs(['Warning: ...']); // Ignore log notification by message
LogBox.ignoreLogs(['Error: ...']); // Ignore log notification by message
LogBox.ignoreAllLogs(); // Ignore all log notifications

JTOInstance.setDeepEqual(false);
JTOInstance.setTypeField('_type');
JTOInstance.setIdentifierField('_id');
JTOInstance.setDeleteField('_delete');
JTOInstance.setDeleteRelationField('_id_del');

JTOInstance.add(User);
JTOInstance.add(Event);
JTOInstance.add(Invite);
JTOInstance.add(Post);
JTOInstance.add(EventType);
JTOInstance.add(File);
JTOInstance.add(PostFile);
JTOInstance.add(RoleEvent);
JTOInstance.add(PermissionEvent);
JTOInstance.add(Group);
JTOInstance.add(ProfilFile);
JTOInstance.add(Contact);
JTOInstance.add(ChatGroup);
JTOInstance.add(Message);
JTOInstance.add(FileMessage);
JTOInstance.add(History);
JTOInstance.add(NewHistory);
JTOInstance.add(OpenQuestion);
JTOInstance.add(Question);
JTOInstance.add(Response);
JTOInstance.add(DirectQuestion);
JTOInstance.add(InterestType);
JTOInstance.add(InterestPointTiny);
JTOInstance.add(FormRegistration);

export let startSuccessAnimation = () => {
    // TO BE OVERWRITTEN
};

const App = () => {
    const theme = useColorScheme();
    const goodAnimationRef = useRef<any>();

    initTheme(theme);
    User._instance.loadEvent();

    useEffect(() => {
        /*messaging()
            .requestPermission()
            .then((res) => {
                messaging()
                    .getToken()
                    .then((token) => {
                        User._instance.token = token;
                        post('/notif/create', {
                            token,
                            id: APP_EVENT_ID,
                        })
                            .then((result) => {
                                // TODO
                            })
                            .catch((err) => {
                                // TODO
                            });
                    })
                    .catch((err) => {
                        User._instance.token = '';
                        // TODO
                    });
            });*/
    }, []);

    AppState.addEventListener('change', (nextAppState) => {
        if (
            nextAppState === 'background' ||
            (nextAppState === 'inactive' && AppState.currentState === 'active')
        ) {
            closeAction();
        }
    });

    startSuccessAnimation = () => {
        goodAnimationRef.current?.start();
    };

    return (
        <SafeAreaProvider
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
            }}
        >
            {/*<Provider>*/}
            <View
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <StatusBar
                    translucent
                    barStyle={'light-content'}
                    backgroundColor='transparent'
                />
                {Platform.OS === 'ios' ? (
                    <KeyboardAvoidingView
                        style={styles.page}
                        behavior={'padding'}
                    >
                        <AppRoutes />
                    </KeyboardAvoidingView>
                ) : (
                    <KeyboardAvoidingView
                        style={styles.page}
                        behavior={'padding'}
                    >
                        <AppRoutes />
                    </KeyboardAvoidingView>
                )}
                <ExperienceView />
                <SucccessAnimation ref={goodAnimationRef} />
                <Confetti />
            </View>
        </SafeAreaProvider>
    );
};

export default App;
{
    /*</Provider>*/
}
