import JTOElement from './jto/JTOElement';
import JTOInstance from './jto/JTOInstance';

const applyData = (
    data: any,
    ajoElement: JTOElement,
    update: boolean = false,
) => {
    if (ajoElement !== null && ajoElement !== undefined) {
        if (data === undefined) {
            data = {};
        }
        if (data instanceof Array) {
            for (const d of data) {
                applyResult(d, ajoElement, update);
            }
        } else {
            const props = Object.keys(data).sort();
            for (const key of props) {
                /* if(data[key] instanceof Array){
                    // While element is array continue stop when its a dict
                    for(const d of data[key]){
                        if(d instanceof Array){
                            for(const d2 of d){
                                const resUpdate = ajoElement.applyData(d2)
                                if(resUpdate||update){
                                    ajoElement.notifyView();
                                }
                            }
                        }
                        else{
                            const resUpdate = ajoElement.applyData(d)
                            if(resUpdate||update){
                                ajoElement.notifyView();
                            }
                        }
                    }
                }
                else { */
                const resUpdate = ajoElement.applyData(data[key]);
                if (resUpdate || update) {
                    ajoElement.notifyView();
                }
                // }
            }
        }
    }
};

const applyBlockedData = (
    data: any,
    ajoElement: JTOElement,
    update: boolean = false,
) => {
    if (ajoElement !== null && ajoElement !== undefined) {
        if (data === undefined) {
            data = {};
        }
        if (data instanceof Array) {
            for (const d of data) {
                applyBlockedData(d, ajoElement, update);
            }
        } else {
            const props = Object.keys(data).sort();
            for (const key of props) {
                /*if(data[key] instanceof Array){
                    for(const d of data[key]){
                        if(d instanceof Array){
                            for(const d2 of d){
                                const resUpdate = ajoElement.applyData(d2)
                                if(resUpdate||update){
                                    ajoElement.notifyView();
                                }
                            }
                        }
                        else{
                            const resUpdate = ajoElement.applyData(d)
                            if(resUpdate||update){
                                ajoElement.notifyView();
                            }
                        }
                    }
                }
                else {*/
                const resUpdate = ajoElement.applyBlockedData(data[key]);
                if (resUpdate || update) {
                    ajoElement.notifyView();
                }
                // }
            }
        }
    }
};

export const applyResult = (
    data: any,
    ajoElement: JTOElement,
    update: boolean = false,
) => {
    JTOInstance.resetOrderList();
    const start = new Date().getTime()
    applyData(data, ajoElement, update);
    JTOInstance.applyOrderList();
};

export const applyResultNoOrder = (
    data: any,
    ajoElement: JTOElement,
    update: boolean = false,
) => {
    applyData(data, ajoElement, update);
};

export const applyResultBlocked = (
    data: any,
    ajoElement: JTOElement,
    update: boolean = false,
) => {
    JTOInstance.resetOrderList();
    applyBlockedData(data, ajoElement, update);
    JTOInstance.applyOrderList();
};

export const applyResultBlockedNoOrder = (
    data: any,
    ajoElement: JTOElement,
    update: boolean = false,
) => {
    applyBlockedData(data, ajoElement, update);
};
