import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function LunchMeeting(props: any) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 130.7 130.67'
            {...props}
        >
            <defs></defs>
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M130.67 122.15c0 3.11-.96 5.7-3.68 7.37-4.58 2.82-10.47.19-11.46-5.1-.15-.79-.17-1.61-.17-2.41V84.55c-.49-.02-.9-.06-1.31-.06-3.27 0-6.55.01-9.82 0-2.59-.02-4.17-1.55-4.17-4.11 0-12.63-.13-25.27.05-37.89.2-14.02 5.85-25.67 16.1-35.11 2.24-2.07 4.73-3.87 7.16-5.74.83-.64 1.81-1.09 2.72-1.63h1.79c2.2 1.03 2.84 2.78 2.84 5.15-.05 39-.04 78-.02 117zM38.27 41.51c0 4.96-2.05 8.87-6.57 11.07-1.65.8-3.65 1.15-5.52 1.22-4.67.17-9.35.07-14.03.05-6.28-.02-10.81-3.81-11.97-9.99-.03-.16-.12-.32-.18-.47V2.81C.71 1.37 1.41.63 2.81 0h2.04c2.21 1.02 2.87 2.77 2.84 5.14-.1 8.04-.04 16.07-.04 24.11v1.31h7.66v-1.44c0-7.99.06-15.99-.04-23.98-.03-2.37.64-4.12 2.84-5.14h2.04c2.21 1.02 2.87 2.77 2.84 5.14-.09 8.04-.04 16.07-.04 24.11v1.31h7.65v-1.44c0-7.99.06-15.99-.04-23.98-.03-2.37.64-4.12 2.84-5.14h2.04c2.21 1.02 2.86 2.77 2.84 5.15-.08 12.12-.02 24.24-.04 36.37zM92.97 85.99c-15.63 20.21-45.96 17.8-58.17-4.59-.23-.42-.33-.97-.33-1.45-.02-6.33 0-12.67-.04-19.01 0-.79.26-1.18.95-1.56 6.88-3.75 10.26-9.65 10.54-17.4.05-1.53-.05-3.07.04-4.59.03-.49.36-1.13.76-1.39 11.56-7.46 27.28-7.08 38.4 1.03 2.35 1.71 4.4 3.85 6.5 5.89.44.43.72 1.25.73 1.89.05 6.08.03 12.16.03 18.24 0 5.62-.06 11.23.04 16.84.03 1.63.52 3.24.74 4.87.06.4.04.94-.19 1.23z'
                />
                <path
                    fill='currentColor'
                    d='M107.67 103.86c0 .32-.26.7-.51.96-9.54 9.89-21.05 15.97-34.72 17.71-13.29 1.69-25.68-1.08-37.15-7.95-.41-.24-.8-.89-.81-1.35-.05-6.08-.04-12.16-.03-18.24 0-.16.03-.33.05-.59 1.76 1.5 3.39 3.03 5.16 4.37 8.68 6.52 18.48 9.42 29.29 8.49 11.89-1.03 21.64-6.29 29.27-15.45.46-.55.82-.64 1.59-.48 2.52.5 5.08.79 7.86 1.2 0 3.63.01 7.49-.02 11.35zM26.8 61.59v60.73c0 4.28-2.29 7.34-6.06 8.16-4.37.95-8.52-1.98-9.16-6.47-.08-.54-.09-1.1-.09-1.65V61.59H26.8zM98.2 17.8c-1.74 5.27-3.41 10.36-5.1 15.49-6.4-5.45-13.94-8.87-22.51-9.96-8.57-1.09-16.73.39-24.59 4.17V10.94c18.58-6.02 36.03-3.71 52.21 6.86z'
                />
            </g>
        </svg>
    );
}

export default LunchMeeting;
