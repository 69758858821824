import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';
import { getColor } from '../utils/theme';

export default class Contact extends JTOObject {
    public static _TYPE: string = 'Contact';

    public firstname: JTOProperty<string>;
    public lastname: JTOProperty<string>;
    public description: JTOProperty<string>;
    public email: JTOProperty<string>;
    public phone: JTOProperty<string>;
    public category: JTOProperty<string>;
    public visible: JTOProperty<boolean>;
    public important: JTOProperty<boolean>;

    constructor(parent?: JTOElement | null) {
        super(Contact._TYPE, parent);

        this.firstname = new JTOProperty<string>('firstname', this);
        this.lastname = new JTOProperty<string>('lastname', this);
        this.description = new JTOProperty<string>('description', this);
        this.email = new JTOProperty<string>('email', this);
        this.phone = new JTOProperty<string>('phone', this);
        this.category = new JTOProperty<string>('category', this);
        this.visible = new JTOProperty<boolean>('visible', this);
        this.important = new JTOProperty<boolean>('important', this);
    }

    public getDisplayName() {
        return this.firstname.get() + ' ' + this.lastname.get();
    }

    getPrimaryCategoryColor() {
        if (this.category.get() === 'client') {
            return getColor('primary');
        } else if (this.category.get() === 'organizer') {
            return getColor('secondary');
        } else {
            return getColor('success');
        }
    }

    hasThing() {
        return (
            (this.description.get() !== undefined &&
                this.description.get() !== '') ||
            (this.email.get() !== undefined && this.email.get() !== '') ||
            (this.phone.get() !== undefined && this.phone.get() !== '')
        );
    }

    getSecondaryCategoryColor() {
        if (this.category.get() === 'client') {
            return getColor('secondary');
        } else if (this.category.get() === 'organizer') {
            return getColor('primary');
        } else {
            return getColor('warning');
        }
    }
}
