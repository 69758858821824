import { t } from 'i18next';

import JTOObject from '../../../utils/jto/JTOObject';
import JTOSimple from '../../../utils/jto/JTOSimple';
import JTOProperty from '../../../utils/jto/JTOProperty';
import JTORelProperty from '../../../utils/jto/JTORelProperty';
import JTOList from '../../../utils/jto/JTOList';
import JTOElement from '../../../utils/jto/JTOElement';
import JTOListFetch from '../../JTOListFetch';

import { MessageTiny } from './MessageTiny';
import User from '../../User';
import ChatGroup from '../../ChatGroup';

export default class ChatGroupTiny extends JTOObject {
    public static _TYPE: string = 'ChatGroup';

    public name: JTOProperty<string>;
    public avatar: JTOProperty<string>;

    public message: JTOSimple<MessageTiny>;

    public nbNewMessage: JTOProperty<number>;

    public connectConnect: JTORelProperty<boolean>;
    public connectDate: JTORelProperty<string>;

    constructor(parent?: JTOElement | null) {
        super(ChatGroupTiny._TYPE, parent);

        this.name = new JTOProperty<string>('name', this);
        this.avatar = new JTOProperty<string>('avatar', this);

        this.nbNewMessage = new JTOProperty<number>('nb_new_message', this);

        this.message = new JTOSimple(MessageTiny, 'receive', this);
        this.connectConnect = new JTORelProperty<boolean>(
            ['connect.connect'],
            this,
            false,
        );
        this.connectDate = new JTORelProperty<string>(
            ['connect.date'],
            this,
            false,
        );
    }

    public getDisplayName(): string {
        return this.name.get() ?? '';
    }

    public isNewMessage(): boolean {
        return (this.nbNewMessage.get() ?? 0) > 0;
    }

    public getNbNewMessage(): number {
        return this.nbNewMessage.get() ?? 0;
    }

    public isConnect(): boolean {
        return this.connectConnect.get() === true;
    }

    public toChatGroup(): ChatGroup {
        const user = new ChatGroup();
        const params = this.toJson();
        user.applyData(params);
        return user;
    }
}
