import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function PressBriefing(props: any) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 116.96 116.99'
            {...props}
        >
            <defs></defs>
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M103.7 116.24c-7.8-2.87-11.81-8.6-11.81-16.87V11.13C91.89 4.41 87.49 0 80.79 0H11.11C4.4 0 0 4.41 0 11.12v90.59c0 8.98 6.28 15.27 15.26 15.27h87.69c.45 0 .9-.04 1.36-.06.02-.11.03-.23.06-.34-.22-.12-.43-.26-.66-.35zm-49-95.35h15.77c2.75 0 4.7 1.74 4.7 4.16.01 2.47-1.88 4.18-4.64 4.19h-7.82c-2.61 0-5.3.01-7.95 0-2.72-.02-4.66-1.8-4.64-4.23.02-2.42 1.9-4.12 4.57-4.12zm20.46 38.05c-.24 2.16-1.99 3.72-4.31 3.72-5.22.02-10.43 0-15.65.01H21.28c-3.24 0-5.35-2.75-4.32-5.56.61-1.67 1.86-2.58 3.62-2.77.39-.04.78-.01 1.17-.01h48.26c.56 0 1.14-.02 1.69.08 2.21.44 3.69 2.42 3.45 4.54zM54.7 37.61h15.77c2.75 0 4.69 1.74 4.7 4.16.01 2.47-1.88 4.18-4.64 4.19h-7.82c-2.6 0-5.3.01-7.95 0-2.72-.02-4.66-1.8-4.64-4.23.02-2.42 1.9-4.12 4.57-4.12zM16.71 25.39c0-2.77 1.71-4.48 4.47-4.49 2.69-.02 5.39 0 8.09 0s5.31-.01 7.96 0c2.82.01 4.53 1.73 4.53 4.57.01 5.31.01 10.62 0 15.93 0 2.86-1.69 4.56-4.54 4.56-5.35.01-10.7.02-16.05 0-2.74 0-4.46-1.75-4.46-4.51-.01-5.35-.01-10.7 0-16.05zm.31 48.25c.67-1.65 2.16-2.6 4.14-2.6 4.39-.01 8.79 0 13.17 0h35.74c.56 0 1.14-.02 1.69.1 2.16.46 3.59 2.36 3.41 4.44-.2 2.21-1.98 3.79-4.37 3.8-8.26.01-16.52 0-24.78 0s-16.52.02-24.79 0c-3.26 0-5.36-2.91-4.21-5.74zm58.14 18.72c-.23 2.16-1.99 3.72-4.3 3.73-5.43.02-10.87 0-16.3 0H21.29c-3.24 0-5.36-2.74-4.33-5.56.61-1.67 1.85-2.58 3.61-2.77.39-.05.78-.02 1.17-.02H70c.56 0 1.14-.02 1.69.08 2.2.44 3.69 2.41 3.47 4.53z'
                />
                <path
                    fill='currentColor'
                    d='M116.95 62.48c0 12.53.01 25.06 0 37.59-.01 4.95-3.78 8.67-8.58 8.56-4.28-.1-7.87-3.58-8.1-7.86-.02-.39-.01-.78-.01-1.17V20.95c2.58 0 4.98-.18 7.35.04 5.39.51 9.33 4.96 9.35 10.44.04 10.35.01 20.71.01 31.06z'
                />
            </g>
        </svg>
    );
}

export default PressBriefing;
