import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActivityIndicator,
    Image,
    Pressable,
    RefreshControl,
    Text,
    View,
} from 'react-native';
import React from 'react';
//import { ScrollView } from 'react-native-gesture-handler';
import { UserIcon } from 'react-native-heroicons/solid';
import Event from '../../object/Event';
import User from '../../object/User';
import { SERVER_URL } from '../../utils/constants';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../../utils/screen';
import { styles } from '../../utils/styles';
import { FONT_BOLD, getColor } from '../../utils/theme';
import { height, isCloseToBottom, width } from '../../utils/utils';
import { GeSpinner } from '../GeSpinner';
import { GroupView } from '../GroupView';
import { Header } from '../Header';
import { InviteView } from '../InviteView';
import { PastilleView } from '../PastilleView';
import { IEventTabPageProps } from './IEventTabPageProps';
import { CartoucheButton } from '../CartoucheButton';
import useJTOState from '../../utils/jto/useJTOState';
import { CloseAllButton } from '../CloseAllButton';

export const InscripEventPage = (props: IEventTabPageProps) => {
    const { t } = useTranslation();

    const event = useJTOState((props as any).route.params.event as Event);

    const inviteList = useJTOState(event.hasRegisterWant);
    const [refreshing, setRefreshing] = useState(false);

    const onRefresh = () => {
        setRefreshing(false);
        inviteList.reset();
        inviteList.refetch({
            id: event.getJTOIdentifier(),
        });
    };

    useEffect(() => {
        inviteList.fetch({
            id: event.getJTOIdentifier(),
        });
    }, []);

    return (
        <View
            style={{
                width: width(),
                paddingBottom: 0,
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                modal={true}
                line={1}
                navigation={props.navigation}
                backButton={true}
                full
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                title={t('inscrip')}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        flex: 1,
                        marginTop: -HEADER_BONUS_HEIGHT,
                        backgroundColor: getColor('background'),
                    }}
                    // @ts-ignore
                    onScroll={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            inviteList.more({
                                id: event.getJTOIdentifier(),
                            });
                        }
                    }}
                    onMomentumScrollEnd={({ nativeEvent }) => {
                        if (isCloseToBottom(nativeEvent)) {
                            inviteList.more({
                                id: event.getJTOIdentifier(),
                            });
                        }
                    }}
                    refreshControl={
                        <RefreshControl
                            progressViewOffset={HEADER_BONUS_HEIGHT}
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                    scrollEventThrottle={1}
                >
                    <View
                        style={{
                            height: HEADER_BONUS_HEIGHT + 20,
                        }}
                    ></View>
                    {inviteList.map((invite, index) => {
                        return (
                            <InviteView
                                navigation={props.navigation}
                                key={invite.getJTOIdentifier()}
                                invite={invite}
                            />
                        );
                    })}
                    {inviteList.size() === 0 && inviteList.isComplite() && (
                        <View
                            style={{
                                width: width(),
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height:
                                    height() -
                                    NAVIGATION_BAR_HEIGHT -
                                    TAB_HEIGHT -
                                    STATUS_BAR_HEIGHT -
                                    75,
                            }}
                        >
                            <Text style={[styles.text]}>
                                {t('inscrip.no_user')}
                            </Text>
                        </View>
                    )}
                    {(inviteList.isLoading() || !inviteList.isComplite()) && (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    width: width(),
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height:
                                        height() -
                                        NAVIGATION_BAR_HEIGHT -
                                        TAB_HEIGHT -
                                        STATUS_BAR_HEIGHT -
                                        75,
                                },
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    )}
                </View>
            </Header>
            <CloseAllButton
                onPress={() => {
                    props.navigation.popToTop();
                }}
            />
        </View>
        /*
        <View>
            
        </View>*/
    );
};
