import JTOElement from '../utils/jto/JTOElement';
import JTOObject from '../utils/jto/JTOObject';

export default class FormRegistration extends JTOObject {
    public static _TYPE: string = 'FormRegistration';

    constructor(parent?: JTOElement | null) {
        super(FormRegistration._TYPE, parent);
    }
}
