export class Toast {
    private _message: string;
    private _duration: number;
    private _type: 'success' | 'error';

    constructor(
        message: string,
        duration: number = 5000,
        type: 'success' | 'error' = 'success',
    ) {
        this._message = message;
        this._duration = duration;
        this._type = type;
    }

    get message() {
        return this._message;
    }

    get duration() {
        return this._duration;
    }

    get type() {
        return this._type;
    }
}
