import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';
import FileSuper from './FileSuper';

export default class PostFile extends FileSuper {
    public static _TYPE: string = 'PostFile';

    constructor(parent?: JTOElement | null) {
        super(PostFile._TYPE, parent);
    }
}
