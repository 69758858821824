import { post } from '../utils/http';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActivityIndicator,
    Pressable,
    Text,
    TextInput,
    View,
} from 'react-native';
import React from 'react';
import { MpInput } from '../base/MpInput';
import User from '../object/User';
import { styles } from '../utils/styles';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { APP_NAME, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { HTTPUtils } from 'mp-ts-http';
import { getErrorMessage } from 'mp-ts-error';
import { EyeIcon, EyeSlashIcon } from 'react-native-heroicons/solid';
import { HeaderSimplePage } from '../component/HeaderSimplePage';
//import { ScrollView } from 'react-native-gesture-handler';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { GeSpinner } from '../component/GeSpinner';
import { CartoucheButton } from '../component/CartoucheButton';
import { ContentCartouche } from '../component/ContentCartouche';
import { Header } from '../component/Header';
import Logo from '../svg/Logo';
import { BEFORE_LOGO } from '../utils/config';
import { nextLogin } from './LoginPage';
import { CommonActions } from '@react-navigation/native';

interface IConnectionPageProps {
    navigation: any;
}
export const CodePage = (props: IConnectionPageProps) => {
    const { t } = useTranslation();
    User.translation = t;

    const [code, setCode] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const [password, setPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState(false);

    const scrollRef = useRef<any>(null);

    const onFinish = () => {
        if (!loading) {
            setLoading(true);
            post('/user/login', {
                identifier: User._email,
                password: User._password,
            }).then((res) => {
                setLoading(false);
                User._password = '';
                User._email = '';
                User._password = '';
                if (HTTPUtils.isSuccess(res)) {
                    setMessage('');
                    const data = HTTPUtils.getResult(res);
                    User._instance?.login(data);

                    nextLogin(props.navigation, setMessage, setLoading, t);
                } else {
                    props.navigation.dispatch(
                        CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'BeforeLogin' }],
                        }),
                    );
                }
            });
        }
    };

    const onResetPassword = () => {
        setLoading(true);
        setMessage('');
        post(
            '/user/backoffice/reset',
            {
                identifier: User._email,
                token: code,
                password: password,
            },
            true,
        )
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    User._password = password;
                    setPassword('');
                    setMessage('');
                    onFinish();
                } else {
                    setMessage(t(HTTPUtils.getError(res)) ?? '');
                }
            })
            .catch((err) => {
                setLoading(false);
                setMessage(t('went_wrong_no_internet') ?? '');
            });
    };

    const scrollToBottom = () => {
        /*scrollRef.current.scrollToEnd({ animated: true });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        });
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 50);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 100);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 150);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 200);
        setTimeout(() => {
            scrollRef.current.scrollToEnd({ animated: true });
        }, 250);*/
    };

    return (
        <View style={[styles.page]}>
            <Header
                title={APP_NAME}
                beforeLogo={BEFORE_LOGO}
                fallback={
                    <Logo
                        height={50}
                        width={50}
                    />
                }
                fallbackColor={'white'}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                full
                logo={''}
            >
                <View
                    ref={scrollRef}
                    // @ts-ignore
                    automaticallyAdjustKeyboardInsets={true}
                >
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: width() * 0.15,
                            marginLeft: width() * 0.15,
                            width: width() * 0.7,
                            paddingBottom: NAVIGATION_BAR_HEIGHT,
                        }}
                    >
                        <HeaderSimplePage
                            title={t('code_title')}
                            description={t('code_description')}
                        />
                        {loading ? (
                            <View style={[styles.boxCenter, marginTop(75)]}>
                                <GeSpinner />
                            </View>
                        ) : (
                            <View
                                style={[
                                    styles.box,
                                    {
                                        width: width(),
                                    },
                                ]}
                            >
                                <View
                                    style={{
                                        paddingHorizontal: width() * 0.1,
                                        paddingVertical: width() * 0.1,
                                    }}
                                >
                                    <MpInput
                                        autoCapitalize='none'
                                        value={code}
                                        onChangeText={(text) => {
                                            setCode(text);
                                        }}
                                        onFocus={() => {
                                            scrollToBottom();
                                        }}
                                        defaultValue={code}
                                        keyboardType={'number-pad'}
                                        textContentType='telephoneNumber'
                                        placeholder={t('code') as string}
                                        style={styles.input}
                                        selectionColor={getColor('primary')}
                                    />

                                    <View style={[marginTop(20)]}>
                                        <MpInput
                                            rightOnPress={() => {
                                                setShowPassword(!showPassword);
                                            }}
                                            rightIcon={
                                                showPassword ? (
                                                    <EyeIcon
                                                        color={getColor('hint')}
                                                    />
                                                ) : (
                                                    <EyeSlashIcon
                                                        color={getColor('hint')}
                                                    />
                                                )
                                            }
                                            secureTextEntry={!showPassword}
                                            autoCapitalize='none'
                                            keyboardType={'default'}
                                            textContentType='password'
                                            placeholder={
                                                t('password') as string
                                            }
                                            style={styles.input}
                                            value={password}
                                            defaultValue={password}
                                            onChangeText={(text) => {
                                                setPassword(text);
                                            }}
                                            selectionColor={getColor('primary')}
                                        />
                                    </View>
                                    {message !== '' && (
                                        <Text
                                            style={[
                                                styles.textError,
                                                marginTop(25),
                                            ]}
                                        >
                                            {message}
                                        </Text>
                                    )}
                                </View>

                                <CartoucheButton
                                    onPress={() => {
                                        onResetPassword();
                                    }}
                                    height={80}
                                    title={t('reset_password')}
                                />

                                <Pressable
                                    style={{
                                        marginTop: 25,
                                    }}
                                    onPress={() => {
                                        props.navigation.goBack();
                                        setCode('');
                                        setMessage('');
                                        setLoading(false);
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                textAlign: 'center',
                                                color: getColor('primary'),
                                            },
                                        ]}
                                    >
                                        {t('back')}
                                    </Text>
                                </Pressable>
                            </View>
                        )}
                    </View>
                </View>
            </Header>
        </View>
    );
};

/*


        <View
            style={[
                styles.page,
                {
                    display: 'flex',
                    justifyContent: 'center',
                    paddingHorizontal: width() * 0.075,
                },
            ]}>
            <Text style={[styles.h3, marginBottom(25)]}>{t('code_title')}</Text>
            <Text style={[styles.text, marginBottom(50)]}>
                {t('code_description')}
            </Text>
        </View>
        */
