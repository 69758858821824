import { DefaultTheme, Theme } from '@react-navigation/native';
import { ColorSchemeName, StyleSheet } from 'react-native';
import React from 'react';

export const APP_NAME = 'Savoiexpo Connect';
export const APP_PRIMARY_COLOR = '#e52322';
export const APP_SECONDARY_COLOR = '#232136';
export const HEART_COLOR = '#dc2626';
export const APP_EVENT_ID: number = -1;
export const APP_NEED_PASSWORD: boolean = false;
export const APP_PUBLIC_EVENT_ID: number = 8160;
export const YOUTUBE_API = 'AIzaSyBiHS7AbzkTrq0Vxe7WepR2fCfAWXetZ4A';

export const FONT_HEAVY = 'Montserrat-Bold';
export const FONT_BOLD = 'Montserrat-Bold';
export const FONT_REGULAR = 'Montserrat-Regular';
export const FONT_MEDIUM = 'Montserrat-Regular';

export const LIGHT_THEME: () => Theme = () => {
    return {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            background: '#FFFFFF',
            ['backgroundLight' as any]: '#e2e8f0',
            ['top' as any]: '#f5f5f5',
            card: '#e9e9eb',
            border: '#c6c6c8',
            ['separator' as any]: '#b2b2b2',
            ['hint' as any]: '#6b7280',
            ['textSecondary' as any]: '#6b7280',
            text: '#0A0C0F',
            ['textWhite' as any]: '#FFFFFF',
            primary: APP_PRIMARY_COLOR,
            ['ge' as any]: APP_PRIMARY_COLOR,
            ['secondary' as any]: APP_SECONDARY_COLOR,
            ['primaryHint' as any]: '#0c58d7',
            ['error' as any]: '#dc2626',
            ['info' as any]: '#083a8f',
            ['warning' as any]: '#fdb336',
            ['alpha' as any]: 'rgba(16,24,32,0.5)',
            ['success' as any]: '#10b981',
            white: '#FFFFFF',
        },
    };
};

export const DARK_THEME: () => Theme = () => {
    return {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            background: '#0A0C0F',
            ['backgroundLight' as any]: '#1e293b',
            card: '#2A2C2F',
            border: '#4A4C4F',
            ['hint' as any]: '#9A9C9F',
            ['textSecondary' as any]: '#9A9C9F',
            text: '#FFFFFF',
            ['textWhite' as any]: '#FFFFFF',
            primary: '#0c58d7',
            ['secondary' as any]: '#3b82f6',
            ['primaryHint' as any]: '#3b82f6',
            ['error' as any]: '#ef4444',
            ['alpha' as any]: 'rgba(16,24,32,0.5)',
            white: '#FFFFFF',
        },
    };
};
let golbalTheme: Theme = LIGHT_THEME();

export const getColor = (key: string) => {
    if (
        golbalTheme.colors[key as keyof typeof golbalTheme.colors] === undefined
    ) {
        throw new Error('Color not found key : ' + key);
    }
    return golbalTheme.colors[key as keyof typeof golbalTheme.colors] ?? '';
};
export const initTheme = (theme: ColorSchemeName) => {
    if (theme === 'dark') {
        golbalTheme = LIGHT_THEME();
    } else {
        golbalTheme = LIGHT_THEME();
    }
    initStyles();
};
export const isDarkMode = () => {
    return false;
    // return golbalTheme === DARK_THEME;
};
export const getTheme = () => {
    return golbalTheme;
};

export const generateStyle = () => {
    return StyleSheet.create({
        text: {
            fontSize: 13,
            color: getColor('text'),
            fontFamily: FONT_REGULAR,
        },
        box: {
            display: 'flex',
            flexDirection: 'column',
        },
        boxCenter: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        textError: {
            color: getColor('error'),
            fontSize: 12,
            textAlign: 'center',
            fontFamily: FONT_REGULAR,
        },
        textMedium: {
            fontSize: 16,
            fontFamily: FONT_MEDIUM,
            color: getColor('text'),
        },
        page: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            height: '100%',
            width: '100%',
            backgroundColor: getColor('background'),
        },
        h1: {
            fontSize: 45,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        h2: {
            fontSize: 40,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        h3: {
            fontSize: 30,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        h4: {
            fontSize: 20,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        h5: {
            fontSize: 18,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        h6: {
            fontSize: 16,
            fontFamily: FONT_BOLD,
            color: getColor('text'),
        },
        input: {
            ['containerStyle' as any]: {
                width: '100%',
                backgroundColor: getColor('card'),
                borderRadius: 50,
                paddingHorizontal: 14,
                paddingVertical: 6,
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 14,
                paddingVertical: 6,
            },

            ['containerFocusStyle' as any]: {
                borderBottomColor: getColor('primary'),
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 14,
                paddingVertical: 6,
            },
        },
        inputBig: {
            ['containerStyle' as any]: {
                width: '100%',
                backgroundColor: getColor('card'),
                borderRadius: 15,
                minHeight: 100,
                paddingHorizontal: 10,
                paddingVertical: 10,
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 10,
                paddingVertical: 10,
            },

            ['containerFocusStyle' as any]: {
                borderBottomColor: getColor('primary'),
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 10,
                paddingVertical: 10,
            },
        },
        inputSingleLine: {
            ['containerStyle' as any]: {
                width: '100%',
                backgroundColor: getColor('card'),
                borderRadius: 15,
                paddingHorizontal: 14,
                paddingVertical: 10,
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 14,
                paddingVertical: 10,
            },

            ['containerFocusStyle' as any]: {
                borderBottomColor: getColor('primary'),
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                fontFamily: FONT_MEDIUM,
                paddingHorizontal: 14,
                paddingVertical: 10,
            },
        },
        inputMessage: {
            ['containerStyle' as any]: {
                width: '100%',
                backgroundColor: getColor('card'),
                borderWidth: 2,
                borderRadius: 20,
                maxHeight: 100,
                paddingHorizontal: 12,
                paddingVertical: 8,
                borderColor: getColor('card'),
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                fontSize: 16,
                fontFamily: FONT_REGULAR,
            },

            ['containerFocusStyle' as any]: {
                borderColor: getColor('primary'),
                borderWidth: 2,
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                paddingHorizontal: 12,
                paddingVertical: 8,
                fontFamily: FONT_REGULAR,
            },
        },
        inputOutilined: {
            ['containerStyle' as any]: {
                width: '100%',

                paddingVertical: 6,
                paddingRight: 8,
                paddingLeft: 8,

                borderWidth: 1,
                borderRadius: 8,
                borderColor: getColor('border'),
                backgroundColor: getColor('card'),
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                paddingRight: 8,
                paddingLeft: 8,
                fontFamily: FONT_REGULAR,
            },

            ['containerFocusStyle' as any]: {
                paddingRight: 8,
                paddingLeft: 8,

                borderWidth: 1,
                borderRadius: 8,
                borderColor: getColor('primary'),
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                paddingRight: 8,
                paddingLeft: 8,
                fontFamily: FONT_REGULAR,
            },
        },
        inputHeader: {
            ['containerStyle' as any]: {
                flex: 1,

                paddingVertical: 6,
                paddingRight: 8,
                paddingLeft: 8,

                borderWidth: 2,
                borderRadius: 25,
                borderColor: 'white',
                backgroundColor: 'white',
            },

            ['inputStyle' as any]: {
                color: getColor('text'),
                paddingRight: 8,
                paddingLeft: 8,
                fontFamily: FONT_REGULAR,
            },

            ['containerFocusStyle' as any]: {
                paddingRight: 8,
                paddingLeft: 8,

                borderWidth: 2,
                borderRadius: 25,
                borderColor: getColor('secondary'),
            },

            ['placeholderStyle' as any]: {
                color: getColor('hint'),
                paddingRight: 8,
                paddingLeft: 8,
                fontFamily: FONT_REGULAR,
            },
        },
        inputPlaceholder: {
            color: getColor('hint'),
        },
        buttonScreen: {
            borderRadius: 12,
            padding: 14,
            backgroundColor: getColor('primary'),
        },
        buttonScreenText: {
            color: getColor('textWhite'),
            fontFamily: FONT_MEDIUM,
            textAlign: 'center',
            fontSize: 19,
        },
        background: {
            backgroundColor: getColor('primary'),
        },
        buttonMenu: {
            display: 'flex',
            paddingBottom: 16,
            paddingTop: 16,
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        button: {
            backgroundColor: getColor('secondary'),
            paddingVertical: 10,
            paddingHorizontal: 14,
            borderRadius: 10,
        },
        buttonText: {
            color: 'white',
            fontSize: 16,
            fontFamily: FONT_MEDIUM,
        },
        title: {
            color: 'black',
            fontFamily: FONT_MEDIUM,
            fontSize: 18,
        },
        error: {
            color: getColor('error'),
            fontSize: 12,
            textAlign: 'center',
            fontFamily: FONT_REGULAR,
        },
    });
};

export let styles = generateStyle();
export const initStyles = () => {
    styles = generateStyle();
};
