import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function Interview(props) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 90.23 130.29'
            {...props}
        >
            <defs></defs>
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M70 67.15c-.12 7.34-3.77 13.23-9.48 17.68-13.86 10.8-33.91 4.64-39.26-12.09-.83-2.61-1.12-5.5-1.14-8.26-.11-12.93-.06-25.87-.04-38.8.01-10.94 5.2-18.69 14.86-23.5 1.97-.98 4.27-1.3 6.42-1.91.4-.11.82-.19 1.22-.28h5.09c.78.16 1.57.33 2.35.47C60.96 2.45 69.9 12.54 70.04 23.65c.19 14.5.2 29-.04 43.5z'
                />
                <path
                    fill='currentColor'
                    d='M88.75 76.83c-1.9 7.11-5.43 13.3-10.37 18.71-5.37 5.87-11.78 10.19-19.42 12.51-2.83.86-5.76 1.37-8.74 2.05v10.19h1.53c6.02 0 12.05-.02 18.07 0 3.1.02 5.32 2.11 5.34 4.93.02 2.55-1.92 4.74-4.47 5.03-.34.04-.68.04-1.02.04H20.56c-3.07 0-5.33-1.95-5.48-4.68-.15-2.98 2.04-5.28 5.2-5.3 5.34-.04 10.69-.01 16.03-.01h3.7v-10.26c-2.14-.44-4.3-.82-6.42-1.34-8.85-2.16-16.14-6.9-22.18-13.62C5.85 88.89 2.09 81.73.79 73.49c-.6-3.83-.58-7.76-.76-11.65-.1-2.2-.04-4.41 0-6.62.05-2.86 2.21-5.06 4.94-5.08 2.79-.02 4.98 2.19 5.03 5.12.07 4.16-.03 8.32.18 12.46.45 8.93 4.12 16.47 10.57 22.57 10.84 10.24 23.59 12.74 37.28 7.4 12.11-4.73 19.67-13.98 21.61-27.06.73-4.91.43-9.98.55-14.97.08-3.4 2.32-5.72 5.42-5.49 2.23.16 4.48 2.1 4.49 4.3.05 7.49.6 15.01-1.37 22.39z'
                />
            </g>
        </svg>
    );
}

export default Interview;
