import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function Reception(props) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 129.67 132.44'
            {...props}
        >
            <path
                fill='currentColor'
                d='M129.66 119.95c-.16-2.74-2.71-4.63-5.38-3.91-3.23.87-6.43 1.84-9.67 2.78-.13-.24-.24-.38-.29-.53-2.4-8.26-4.79-16.53-7.22-24.79-.26-.86.23-1.03.77-1.32 11.54-6.28 16.68-19.3 12.46-31.81-4.89-14.47-9.85-28.92-14.78-43.37-1.07-3.13-3.04-4.12-6.23-3.19-9.58 2.8-19.17 5.59-28.75 8.38-.43.12-.86.2-1.47.34.49-2.05.96-3.88 1.38-5.71.67-2.9-.47-4.88-3.33-5.72-8.84-2.58-17.69-5.14-26.52-7.73-3.75-1.1-7.49-2.24-11.22-3.36h-2.07c-1.42.7-2.56 1.6-3.11 3.21-4.99 14.76-10.15 29.46-15 44.26-3.09 9.42-1.09 18.02 5.31 25.58 2.26 2.66 5.07 4.63 8.25 6.24-2.54 8.73-5.06 17.39-7.59 26.11-.56-.15-.97-.24-1.38-.36-2.73-.78-5.45-1.6-8.19-2.35-2.48-.68-4.79.55-5.43 2.84-.66 2.34.57 4.61 3.04 5.34 9.21 2.69 18.43 5.35 27.65 8 2 .57 4.07-.49 4.91-2.38 1.05-2.35-.12-4.99-2.67-5.79-3.19-1.01-6.42-1.91-9.72-2.88 2.54-8.76 5.06-17.41 7.56-26.01.87 0 1.6-.02 2.33 0 11.13.32 21.26-6.25 25.2-16.57 1.1-2.88 1.69-5.95 2.46-8.95.76-2.95 1.44-5.92 2.24-9.23.2.65.3.93.37 1.21 2.16 8.91 4.22 17.85 6.49 26.73 2.63 10.3 9.21 16.86 19.48 19.49 2.91.75 6.03.69 9.3 1.02 2.42 8.34 4.94 17 7.48 25.72-.48.15-.84.27-1.21.38-2.69.78-5.4 1.47-8.04 2.38a6.786 6.786 0 00-2.79 1.9c-1.11 1.31-.95 3.28.03 4.71 1 1.46 2.79 2.18 4.54 1.69 3.11-.85 6.2-1.78 9.3-2.68 5.99-1.73 11.98-3.47 17.98-5.21 2.35-.68 3.69-2.37 3.57-4.45zM56.28 39.14c-2.97-.29-5.8-.88-8.6-.76-4.07.18-8.14.71-12.16 1.38-4.7.79-8.88-.16-12.7-2.94-.56-.41-.79-.74-.54-1.47 2.89-8.38 5.74-16.77 8.61-25.15.07-.2.16-.38.28-.66 10.03 2.91 20.01 5.81 30.14 8.76l-5.03 20.85zm39.12 5.23c-3.17-1.33-6.33-2.75-9.62-3.68-4.73-1.34-9.56-.92-14.34.04-.25.05-.5.08-.86.15-.74-3.04-1.46-6.03-2.22-9.15 10.08-2.93 20.06-5.83 30.2-8.78l7.71 22.59c-3.8.56-7.37.3-10.86-1.16z'
                id='Calque_1-2'
                data-name='Calque 1'
                strokeWidth={0}
            />
        </svg>
    );
}

export default Reception;
