import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_MEDIUM, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Keychain from 'react-native-keychain';
import ChatGroup from '../object/ChatGroup';
import { Header } from '../component/Header';
import User from '../object/User';
import { PastilleView } from '../component/PastilleView';
import {
    ArrowRightOnRectangleIcon,
    PencilIcon,
    PhotoIcon,
    UserGroupIcon,
    UserIcon,
    UserPlusIcon,
} from 'react-native-heroicons/solid';

interface IMenuChatGroupModalProps extends IModalProps {
    group: ChatGroup;
}

export const MenuChatGroupModal = (props: IMenuChatGroupModalProps) => {
    const { t } = useTranslation();

    const actionList = [
        {
            label: t('name'),
            icon: (
                <PencilIcon
                    color={'white'}
                    size={18}
                />
            ),
            onClick: (navigation: any) => {
                navigation.navigate('GroupNameModal', {
                    group: (props as any).route.params.group,
                });
            },
        },
        {
            label: t('picture'),
            icon: (
                <PhotoIcon
                    color={'white'}
                    size={18}
                />
            ),
            onClick: (navigation: any) => {
                navigation.navigate('GroupPictureModal', {
                    group: (props as any).route.params.group,
                });
            },
        },
        {
            label: t('members'),
            icon: (
                <UserGroupIcon
                    color={'white'}
                    size={22}
                />
            ),
            onClick: (navigation: any) => {
                navigation.navigate('ChatUserListModal', {
                    group: (props as any).route.params.group,
                });
            },
        },
        {
            label: t('add_user'),
            icon: (
                <UserPlusIcon
                    color={'white'}
                    size={22}
                />
            ),
            onClick: (navigation: any) => {
                navigation.navigate('ChatUserAddModal', {
                    group: (props as any).route.params.group,
                });
            },
        },
        {
            label: t('leave'),
            onClick: (navigation: any) => {
                navigation.navigate('LeaveChatGroupModal', {
                    group: (props as any).route.params.group,
                });
            },
            icon: (
                <ArrowRightOnRectangleIcon
                    color={'white'}
                    size={22}
                />
            ),
        },
    ];

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('edit_group')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: 15,
                    }}
                >
                    {actionList.map((action, index) => {
                        return (
                            <Pressable
                                key={index}
                                style={[
                                    index !== 0 && {
                                        borderTopWidth: 1,
                                        borderColor: getColor('border'),
                                    },
                                    {
                                        flexDirection: 'row',
                                        marginHorizontal: 20,
                                        padding: 10,
                                        alignItems: 'center',
                                        backgroundColor: getColor('card'),
                                    },
                                    index === 0 && {
                                        borderTopLeftRadius: 15,
                                        borderTopRightRadius: 15,
                                    },
                                    index === actionList.length - 1 && {
                                        borderBottomLeftRadius: 15,
                                        borderBottomRightRadius: 15,
                                    },
                                ]}
                                onPress={(e) => {
                                    e.stopPropagation();
                                    action.onClick(props.navigation);
                                }}
                            >
                                <PastilleView
                                    size={32}
                                    color={getColor('secondary')}
                                    icon={action.icon}
                                />
                                <Text
                                    adjustsFontSizeToFit
                                    style={[
                                        {
                                            marginLeft: 10,
                                            color: 'black',
                                            fontFamily: FONT_MEDIUM,
                                            fontSize: 16,
                                            flex: 1,
                                        },
                                    ]}
                                >
                                    {t(action.label)}
                                </Text>
                            </Pressable>
                        );
                    })}
                </View>
            </Header>
        </View>
    );
};
