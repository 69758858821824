import * as React from 'react';
import Svg, { SvgProps } from 'react-native-svg';

function Concert(props: any) {
    return (
        <svg
            viewBox='0 0 142.96 142.45'
            {...props}
        >
            <path
                fill='currentColor'
                d='M135.44,24.87v5.03c-.35,1.63-.63,3.27-1.05,4.87-.93,3.5-3,7.48-4.5,8.96-12.83-12.83-25.66-25.65-38.55-38.55,2.91-2.13,6.22-3.57,9.81-4.4,1.28-.3,2.57-.52,3.86-.78h5.56c.29.08.58.2.88.24,11.05,1.34,19.95,8.93,22.93,19.65.46,1.64.72,3.33,1.07,4.99Z'
            />
            <path
                fill='currentColor'
                d='M35.31,109.19c.66,1.68,2,2.47,4.11,2.48,6.75,0,13.5-.05,20.24.02,6.53.07,11.72,5.39,11.73,11.87,0,6.43-5.14,11.73-11.57,11.91-.48.01-.97.01-1.46.01H0v-7.94h1.55c19.01,0,38.02,0,57.03,0,.57,0,1.16.02,1.71-.1,2.01-.43,3.37-2.32,3.15-4.28-.23-2.06-1.89-3.54-4.09-3.55-6.66-.02-13.32,0-19.98-.01-6.71-.01-11.59-4.35-12.28-11.02-.36-3.48.84-6.5,3.22-9.05,1.05-1.13,2.15-2.22,3.27-3.28.51-.48.62-.89.38-1.6-1.53-4.47-.86-8.56,2.21-12.25,12.21-14.68,24.4-29.37,36.6-44.06.16-.2.35-.39.63-.7,4.02,12.04,12,20.04,24.1,24.08-.91.78-1.7,1.47-2.5,2.14-13.99,11.8-27.98,23.59-41.96,35.39-3.66,3.09-7.74,3.83-12.22,2.2-.7-.26-1.11-.16-1.59.36-.87.94-1.77,1.85-2.7,2.72-1.4,1.31-1.92,2.82-1.21,4.65Z'
            />
            <path
                fill='currentColor'
                d='M123.95,49.71c-9.76,7.76-25.69,7.96-36.28-3.02-10.76-11.15-9.32-26.81-2.18-35.44,12.82,12.81,25.64,25.64,38.47,38.46Z'
            />
        </svg>
    );
}

export default Concert;
