import { View, Text, Pressable, ActivityIndicator, Image } from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import { MpInput } from '../base/MpInput';
import { useEffect, useState } from 'react';
import User from '../object/User';
import { UserIcon, XMarkIcon } from 'react-native-heroicons/solid';
import JTOListFetch from '../object/JTOListFetch';
import ChatGroup from '../object/ChatGroup';
import { GeSpinner } from '../component/GeSpinner';
import { openAction } from '../utils/utils';
import { Header } from '../component/Header';
//import { ScrollView } from 'react-native-gesture-handler';
import { PastilleView } from '../component/PastilleView';
import { SERVER_URL } from '../utils/constants';
import { getRestore } from '../utils/http';
import { HTTPUtils } from 'mp-ts-http';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { applyResult } from '../utils/applyResult';
import useJTOState from '../utils/jto/useJTOState';
import UserTiny from '../object/compressed/global/UserTiny';

interface IChatUserListModalProps extends IModalProps {
    group: ChatGroup;
}

export const ChatUserListModal = (props: IChatUserListModalProps) => {
    const { t } = useTranslation();

    const groupJson = (props as any).route.params.group;

    const group = useJTOState((props as any).route.params.group as ChatGroup);

    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getRestore(
            '/message/group/userlist',
            {
                id: group.getJTOIdentifier(),
            },
            (res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    applyResult(res, group, true);
                } else {
                    ToastManager.instance.addToast(
                        new Toast(t(HTTPUtils.getError(res)), 5000, 'error'),
                    );
                }
            },
            (err) => {
                setLoading(false);
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet'), 5000, 'error'),
                );
            },
            () => {
                group.userList.setList([]);
            },
        );
    }, []);

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('members')}
                line={1}
                full
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    /*ScrollView*/
                    style={{
                        paddingHorizontal: 20,
                    }}
                >
                    <View
                        style={{
                            marginTop: 30,
                        }}
                    ></View>
                    {loading ? (
                        <View
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 35,
                            }}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View>
                            {group.userList.getList().length === 0 && (
                                <View
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingHorizontal: 20,
                                        height:
                                            height() -
                                            TAB_HEIGHT -
                                            STATUS_BAR_HEIGHT -
                                            NAVIGATION_BAR_HEIGHT -
                                            150,
                                        backgroundColor: getColor('background'),
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: getColor('text'),
                                            textAlign: 'center',
                                        }}
                                    >
                                        {t('no_member')}
                                    </Text>
                                </View>
                            )}
                            {group.userList
                                .getList()
                                .map((user: UserTiny, index: number) => {
                                    return (
                                        <Pressable
                                            key={user.getJTOIdentifier()}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                padding: 5,
                                            }}
                                            onPress={() => {
                                                props.navigation.push(
                                                    'ProfilePage',
                                                    {
                                                        user: user.toUser(),
                                                    },
                                                );
                                            }}
                                        >
                                            {user.avatar.get() === undefined ? (
                                                <PastilleView
                                                    size={30}
                                                    icon={
                                                        <UserIcon
                                                            size={20}
                                                            color={'white'}
                                                        />
                                                    }
                                                    color={getColor(
                                                        'secondary',
                                                    )}
                                                />
                                            ) : (
                                                <Image
                                                    style={{
                                                        resizeMode: 'cover',
                                                        height: 30,
                                                        width: 30,
                                                        borderRadius: 30,
                                                    }}
                                                    source={{
                                                        uri:
                                                            SERVER_URL +
                                                            '/image/' +
                                                            user.avatar.get(),
                                                    }}
                                                />
                                            )}
                                            <Text
                                                style={[
                                                    styles.textMedium,
                                                    {
                                                        marginLeft: 10,
                                                    },
                                                ]}
                                            >
                                                {user.getDisplayName()}
                                            </Text>
                                        </Pressable>
                                    );
                                })}
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
