import {
    View,
    Text,
    Pressable,
    ActivityIndicator,
    ScrollView,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import { goBackTo, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import { MpInput } from '../base/MpInput';
import { useEffect, useState } from 'react';
import User from '../object/User';
import { MagnifyingGlassIcon, XMarkIcon } from 'react-native-heroicons/solid';
import { get, post } from '../utils/http';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import JTOListFetch from '../object/JTOListFetch';
import ChatGroup from '../object/ChatGroup';
import { GeSpinner } from '../component/GeSpinner';
import { CartoucheButton } from '../component/CartoucheButton';
//import BouncyCheckbox from 'react-native-bouncy-checkbox';
import { isCloseToBottom } from '../utils/utils';
import { Header } from '../component/Header';
import Group from '../object/Group';
import { getRestore } from '../utils/http';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import useJTOState from '../utils/jto/useJTOState';
import { applyResult } from '../utils/applyResult';
import UserTiny from '../object/compressed/global/UserTiny';

interface IChatUserAddModalProps extends IModalProps {
    group: ChatGroup;
}

export const ChatUserAddModal = (props: IChatUserAddModalProps) => {
    const { t } = useTranslation();

    const group = useJTOState((props as any).route.params.group as ChatGroup);

    const newUserList = useJTOState(
        new JTOListFetch<User>('/chat/search', User),
    );

    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState<number[]>([
        User._instance.getJTOIdentifier(),
    ]);

    const isSelected = (user: User) => {
        return selected.some((nb) => nb === user.getJTOIdentifier());
    };

    const userInList = (
        user: User | UserTiny,
        list: (User | UserTiny)[],
    ): boolean => {
        let res = false;

        let i = 0;
        while (!res && i < list.length) {
            res = list[i].getJTOIdentifier() === user.getJTOIdentifier();
            i++;
        }

        return res;
    };

    useEffect(() => {
        getRestore(
            '/message/group/userlist',
            {
                id: group.getJTOIdentifier(),
            },
            (res) => {
                if (HTTPUtils.isSuccess(res)) {
                    applyResult(res, group, true);
                    setLoading(false);
                } else {
                    setLoading(false);
                    ToastManager.instance.addToast(
                        new Toast(t(HTTPUtils.getError(res)), 5000, 'error'),
                    );
                }
            },
            (err) => {
                setLoading(false);
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet'), 5000, 'error'),
                );
            },
            (res) => {
                group.userList.setList([]);
            },
        );
    }, []);

    useEffect(() => {
        newUserList.fetch({
            search,
            id_event: User._instance.getEvent()!.getJTOIdentifier(),
        });
    }, [search]);

    const finalUserList: User[] = [];
    for (const user of newUserList.getList()) {
        if (!userInList(user, group.userList.getList())) {
            finalUserList.push(user);
        }
    }

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                backButton={true}
                full={true}
                navigation={props.navigation}
                title={t('add_user')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        paddingHorizontal: 20,
                        marginTop: 40,
                        flex: 1,
                        paddingBottom: NAVIGATION_BAR_HEIGHT,
                    }}
                >
                    <MpInput
                        spaceIcon={10}
                        value={search}
                        leftIcon={
                            <MagnifyingGlassIcon
                                size={20}
                                color={getColor('hint')}
                            />
                        }
                        onChangeText={(e) => {
                            setSearch(e);
                            newUserList.reset();
                            newUserList.refetch({
                                search: e,
                                id_event: User._instance
                                    .getEvent()!
                                    .getJTOIdentifier(),
                            });
                        }}
                        placeholder={t('search_user') ?? ''}
                        style={styles.inputSingleLine}
                    />

                    {!loading ? (
                        <View
                            style={{
                                flex: 1,
                                marginTop: 10,
                            }}
                        >
                            <View
                            /*
                                keyboardDismissMode='on-drag'
                                scrollEventThrottle={1}
                                onScroll={({ nativeEvent }) => {
                                    if (isCloseToBottom(nativeEvent)) {
                                        newUserList.more({
                                            search,
                                            id_event: User._instance
                                                .getEvent()!
                                                .getJTOIdentifier(),
                                        });
                                    }
                                }}
                                onMomentumScrollEnd={({ nativeEvent }) => {
                                    if (isCloseToBottom(nativeEvent)) {
                                        newUserList.more({
                                            search,
                                            id_event: User._instance
                                                .getEvent()!
                                                .getJTOIdentifier(),
                                        });
                                    }
                                }}*/
                            >
                                <View>
                                    {finalUserList.map((user, index) => {
                                        if (
                                            !userInList(
                                                user,
                                                group.userList.getList(),
                                            )
                                        ) {
                                            return (
                                                <Pressable
                                                    key={user.getJTOIdentifier()}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        padding: 5,
                                                    }}
                                                    onPress={() => {
                                                        if (isSelected(user)) {
                                                            setSelected(
                                                                selected.filter(
                                                                    (nb) =>
                                                                        nb !==
                                                                        user.getJTOIdentifier(),
                                                                ),
                                                            );
                                                        } else {
                                                            setSelected([
                                                                ...selected,
                                                                user.getJTOIdentifier(),
                                                            ]);
                                                        }
                                                    }}
                                                >
                                                    {/*<BouncyCheckbox
                                                        fillColor={getColor(
                                                            'secondary',
                                                        )}
                                                        isChecked={isSelected(
                                                            user,
                                                        )}
                                                        textStyle={[
                                                            styles.text,
                                                            {
                                                                fontSize: 15,
                                                                textDecorationLine:
                                                                    'none',
                                                            },
                                                        ]}
                                                        text={user.getDisplayName()}
                                                        onPress={() => {
                                                            if (
                                                                isSelected(user)
                                                            ) {
                                                                setSelected(
                                                                    selected.filter(
                                                                        (nb) =>
                                                                            nb !==
                                                                            user.getJTOIdentifier(),
                                                                    ),
                                                                );
                                                            } else {
                                                                setSelected([
                                                                    ...selected,
                                                                    user.getJTOIdentifier(),
                                                                ]);
                                                            }
                                                        }}
                                                    />*/}
                                                </Pressable>
                                            );
                                        }
                                    })}

                                    {(newUserList.isLoading() ||
                                        !newUserList.isComplite()) && (
                                        <View
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: 70,
                                            }}
                                        >
                                            <GeSpinner
                                                color={getColor('primary')}
                                            />
                                        </View>
                                    )}

                                    {!newUserList.isLoading() &&
                                        newUserList.isComplite() &&
                                        finalUserList.length === 0 && (
                                            <View
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginTop: 70,
                                                }}
                                            >
                                                <Text style={styles.text}>
                                                    {t('no_user_found')}
                                                </Text>
                                            </View>
                                        )}
                                </View>
                            </View>

                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.error,
                                        {
                                            marginBottom: 15,
                                            marginLeft: 15,
                                            marginRight: 15,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}
                        </View>
                    ) : (
                        <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    )}

                    <View
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        <CartoucheButton
                            onPress={() => {
                                setLoading(true);
                                setError('');
                                post('/chat/group/addlist', {
                                    id_list: selected,
                                    id: group.getJTOIdentifier(),
                                })
                                    .then((response) => {
                                        setLoading(false);
                                        if (HTTPUtils.isSuccess(response)) {
                                            applyResult(
                                                HTTPUtils.getResult(response),
                                                group,
                                                true,
                                            );
                                            goBackTo(
                                                props.navigation,
                                                'MessagePage',
                                                'ChatUserAddModal',
                                            );
                                        } else {
                                            setError(
                                                t(
                                                    HTTPUtils.getError(
                                                        response,
                                                    ),
                                                ) ?? '',
                                            );
                                            setLoading(false);
                                        }
                                    })
                                    .catch((err) => {
                                        setError(
                                            t('went_wrong_no_internet') ?? '',
                                        );
                                        setLoading(false);
                                    });
                            }}
                            title={t('add')}
                            height={80}
                        />
                    </View>
                </View>
            </Header>
        </View>
    );
};
