import { View, Text, Pressable } from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_MEDIUM, getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Keychain from 'react-native-keychain';
import User from '../object/User';
import { Header } from '../component/Header';
import { PastilleView } from '../component/PastilleView';
import {
    DocumentIcon,
    LockClosedIcon,
    PencilIcon,
    UserIcon,
} from 'react-native-heroicons/solid';
import { LinkedinIcon } from '../component/icon/LinkedinIcon';
import { InstagramIcon } from '../component/icon/InstagramIcon';
import { FacebookIcon } from '../component/icon/FacebookIcon';
import { TwitterIcon } from '../component/icon/TwitterIcon';
import { YoutubeIcon } from '../component/icon/YoutubeIcon';
//import { ScrollView } from 'react-native-gesture-handler';
//import MaterialIcons from 'react-native-vector-icons/MaterialCommunityIcons';

const actionList = [
    {
        label: 'firstname',
        icon: (
            <PencilIcon
                color={'white'}
                size={20}
            />
        ),
        onClick: (navigation: any) => {
            navigation.navigate('FirstnameModal');
        },
    },
    {
        label: 'lastname',
        icon: (
            <PencilIcon
                color={'white'}
                size={20}
            />
        ),
        onClick: (navigation: any) => {
            navigation.navigate('LastnameModal');
        },
    },
    {
        label: 'description',
        icon: (
            <PencilIcon
                color={'white'}
                size={20}
            />
        ),
        onClick: (navigation: any) => {
            navigation.navigate('DescriptionModal');
        },
    },
    {
        label: 'profile_picture',
        icon: (
            <UserIcon
                color={'white'}
                size={22}
            />
        ),
        onClick: (navigation: any) => {
            navigation.navigate('ProfilPictureModal');
        },
    },
    {
        label: 'profile_file',
        icon: (
            <DocumentIcon
                color={'white'}
                size={20}
            />
        ),
        onClick: (navigation: any) => {
            navigation.navigate('ProfilFileModal');
        },
    },
    {
        label: 'profile_visit_card',
        icon: (
            <DocumentIcon
                color={'white'}
                size={20}
            />
        ),
        onClick: (navigation: any) => {
            navigation.navigate('ProfilVisitCardModal');
        },
    },
    {
        label: 'Linkedin',
        icon: (
            <LinkedinIcon
                fill={'white'}
                height={16}
                width={16}
            />
        ),
        onClick: (navigation: any) => {
            navigation.navigate('NetworkModal', {
                network: 'linkedin',
                label: 'Linkedin',
            });
        },
    },
    {
        label: 'Instagram',
        icon: (
            <InstagramIcon
                fill={'white'}
                height={18}
                width={18}
            />
        ),
        onClick: (navigation: any) => {
            navigation.navigate('NetworkModal', {
                network: 'instagram',
                label: 'Instagram',
            });
        },
    },
    {
        label: 'Facebook',
        icon: (
            <View>
                <FacebookIcon
                    fill={'white'}
                    height={18}
                    width={18}
                />
            </View>
        ),
        onClick: (navigation: any) => {
            navigation.navigate('NetworkModal', {
                network: 'facebook',
                label: 'Facebook',
            });
        },
    },
    {
        label: 'Twitter',
        icon: (
            <View>
                <TwitterIcon
                    fill={'white'}
                    height={18}
                    width={18}
                />
            </View>
        ),
        onClick: (navigation: any) => {
            navigation.navigate('NetworkModal', {
                network: 'twitter',
                label: 'Twitter',
            });
        },
    },
    {
        label: 'Youtube',
        icon: (
            <YoutubeIcon
                fill={'white'}
                height={18}
                width={18}
            />
        ),
        onClick: (navigation: any) => {
            navigation.navigate('NetworkModal', {
                network: 'youtube',
                label: 'Youtube',
            });
        },
    },
];

export const MenuMyProfileModal = (props: IModalProps) => {
    const { t } = useTranslation();

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('edit_profile')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                rounded={(User._instance.avatar.get() ?? '') !== ''}
                logo={User._instance.avatar.get() ?? ''}
                fallback={
                    <UserIcon
                        color={'white'}
                        size={35}
                    />
                }
            >
                <View
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    <View>
                        {actionList.map((action, index) => {
                            return (
                                <Pressable
                                    key={index}
                                    style={[
                                        index !== 0 && {
                                            borderTopWidth: 1,
                                            borderColor: getColor('border'),
                                        },
                                        {
                                            flexDirection: 'row',
                                            marginHorizontal: 20,
                                            padding: 10,
                                            alignItems: 'center',
                                            backgroundColor: getColor('card'),
                                        },
                                        index === 0 && {
                                            borderTopLeftRadius: 15,
                                            borderTopRightRadius: 15,
                                        },
                                        index === actionList.length - 1 && {
                                            borderBottomLeftRadius: 15,
                                            borderBottomRightRadius: 15,
                                        },
                                    ]}
                                    onPress={(e) => {
                                        e.stopPropagation();
                                        action.onClick(props.navigation);
                                    }}
                                >
                                    <PastilleView
                                        size={32}
                                        color={getColor('secondary')}
                                        icon={action.icon}
                                    />
                                    <Text
                                        adjustsFontSizeToFit
                                        style={[
                                            {
                                                marginLeft: 10,
                                                color: 'black',
                                                fontFamily: FONT_MEDIUM,
                                                fontSize: 16,
                                                flex: 1,
                                            },
                                        ]}
                                    >
                                        {t(action.label)}
                                    </Text>
                                </Pressable>
                            );
                        })}
                    </View>
                </View>
            </Header>
        </View>
    );
};
