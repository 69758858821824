import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function Contest(props) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 162.55 67.81'
            {...props}
        >
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M47.38 17.94v45.53c0 3.16-1.15 4.31-4.31 4.31-12.85 0-25.7-.02-38.55.03-2.04 0-3.6-.59-4.52-2.48V16.11c.85-1.96 2.36-2.57 4.44-2.57 12.85.06 25.7.03 38.55.03 3.29 0 4.39 1.08 4.39 4.36zM108.34 34.05v29.2c0 3.47-1.07 4.53-4.58 4.53H58.69c-3.38 0-4.48-1.1-4.48-4.46V4.44c0-3.34 1.11-4.44 4.5-4.44h45.23c3.28 0 4.4 1.11 4.4 4.37v29.68zM162.55 31.38v32.04c0 3.12-1.2 4.36-4.27 4.36h-38.86c-3.07 0-4.25-1.17-4.25-4.2V31.23c0-2.96 1.15-4.07 4.17-4.08h39.02c3.06 0 4.2 1.16 4.2 4.23z'
                    strokeWidth={0}
                />
            </g>
        </svg>
    );
}

export default Contest;
