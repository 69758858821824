import { useTranslation } from 'react-i18next';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
import { CalendarDaysIcon, MapPinIcon } from 'react-native-heroicons/solid';
import Event from '../object/Event';
import { FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { PastilleView } from './PastilleView';
import openMap from 'react-native-open-maps';
import { MapEventView } from './MapEventView';

interface IInfoEventViewProps {
    event: Event;
    cartouche?: boolean;
}

export const InfoEventView = (props: IInfoEventViewProps) => {
    const { t } = useTranslation();

    const event = props.event;
    const cartouche = props.cartouche ?? false;

    return (
        <View
            style={{
                width: width(),
                display: 'flex',
                paddingHorizontal: !cartouche ? 20 : 0,
            }}
        >
            {event.asDate() && (
                <View
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                    }}
                >
                    <PastilleView
                        size={40}
                        icon={<CalendarDaysIcon color={'white'} />}
                        color={
                            cartouche
                                ? event.secondaryColor.get() ?? ''
                                : getColor('secondary')
                        }
                    />
                    <View
                        style={{
                            marginLeft: 10,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: cartouche ? 16 : 18,
                                fontFamily: FONT_MEDIUM,
                                color: getColor('text'),
                            }}
                        >
                            {event.displayDate(t('date'))}
                        </Text>
                        <Text
                            style={{
                                marginTop: 3,
                                width: '100%',
                                fontSize: cartouche ? 12 : 14,
                                fontFamily: FONT_REGULAR,
                                color: getColor('hint'),
                            }}
                        >
                            {event.displayDuration(t('date'))}
                        </Text>
                    </View>
                </View>
            )}
            <MapEventView
                event={event}
                cartouche={cartouche}
            />
        </View>
    );
};
