import { t } from 'i18next';

import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

import { formatCompressRemainTime, formatRemainTime } from '../utils/utils';
import ChatGroup from './ChatGroup';
import FileMessage from './FileMessage';
import User from './User';
import Event from './Event';

export class Message extends JTOObject {
    public content: JTOProperty<string>;
    public date: JTOProperty<string>;
    public fileType: JTOProperty<string>;

    public userSend: JTOSimple<User>;
    public receiver: JTOSimple<User | ChatGroup>;

    private _event: JTOSimple<Event>;

    public fileList: JTOList<FileMessage>;

    constructor(parent?: JTOElement | null) {
        super('Message', parent);

        this.content = new JTOProperty('content', this);
        this.date = new JTOProperty('date', this);
        this.fileType = new JTOProperty('file_type', this);

        this.userSend = new JTOSimple(User, ['send'], this);

        this._event = new JTOSimple(Event, ['has_event'], this);

        this.receiver = new JTOSimple<User | ChatGroup>(
            [ChatGroup, User],
            ['receive'],
            this,
        );

        this.fileList = new JTOList(
            FileMessage,
            'HAS_MESSAGE_FILE'.toLowerCase(),
            this,
        );
    }

    public isSending(): boolean {
        return this.getJTOIdentifier() === undefined;
    }

    public displayMessageDate(translate: any): string {
        return formatCompressRemainTime(this.date.get() ?? '', translate);
    }

    public getReceiver(): User | ChatGroup {
        return this.receiver.get() ?? new User();
    }

    public isMe(): boolean {
        return (
            (this.getJTOParent()?.getJTOParent()?.getJTOType() === 'User' &&
                this.getReceiver().getJTOIdentifier() !==
                    User._instance.getJTOIdentifier()) ||
            (this.getJTOParent()?.getJTOParent()?.getJTOType() ===
                'ChatGroup' &&
                this.userSend.get()?.getJTOIdentifier() ===
                    User._instance.getJTOIdentifier())
        );
    }

    public getHim(): User | ChatGroup {
        let user: User | ChatGroup = this.getSender();
        if (!this.isMe()) {
            user = this.getReceiver();
        }
        return user;
    }

    public getSender(): User {
        return this.userSend.get() ?? new User();
    }

    public isImage(): boolean {
        let res = false;
        if (this.fileType !== undefined) {
            res = this.fileType.get()?.split('/')[0] === 'image';
        }

        return res;
    }

    public getContent(
        func: (key: string) => string | undefined,
    ): string | undefined {
        if (this.content.get() === '') {
            return func('share_a_file');
        }
        return this.content.get();
    }

    public get event(): Event {
        return this._event.get() ?? new Event();
    }

    public set event(value: Event) {
        this._event.set(value);
    }
}
