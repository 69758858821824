import { Pressable, Text, View } from 'react-native';
import React from 'react';
import Post from '../object/Post';
import EventTiny from '../object/compressed/event/EventTiny';
import { FONT_BOLD, FONT_MEDIUM, HEART_COLOR, getColor } from '../utils/theme';
import { HeartIcon, MapPinIcon } from 'react-native-heroicons/solid';
import { t } from 'i18next';
import { PastilleView } from './PastilleView';

interface IFavoriteItemProps {
    item: EventTiny | Post;
    navigation: any;
}

export const FavoriteItem = (props: IFavoriteItemProps) => {
    const { item } = props;

    return (
        <Pressable
            onPress={() => {
                if (item instanceof EventTiny) {
                    props.navigation.push('SubEventModal', {
                        event: item.toEvent(),
                        react: true,
                    });
                } else {
                    props.navigation.push('PostCommentModal', {
                        post: item,
                    });
                }
            }}
            style={{
                backgroundColor: getColor('card'),
                marginHorizontal: 20,
                paddingVertical: 15,
                paddingHorizontal: 15,
                borderRadius: 20,
            }}
        >
            {item instanceof EventTiny ? (
                <View
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    <View>
                        <View
                            style={{
                                marginBottom: 5,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text
                                style={{
                                    flex: 1,
                                    color: getColor('text'),
                                    fontSize: 18,
                                    fontFamily: FONT_BOLD,
                                }}
                            >
                                {item.name.get()}
                            </Text>

                            <HeartIcon color={HEART_COLOR} />
                        </View>
                    </View>

                    {item.isInterest.get() ? (
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <PastilleView
                                size={24}
                                icon={
                                    <MapPinIcon
                                        size={15}
                                        color={'white'}
                                    />
                                }
                                color={getColor('secondary')}
                            />
                            <Text
                                style={{
                                    fontSize: 14,
                                    color: getColor('hint'),
                                    fontFamily: FONT_MEDIUM,
                                    marginLeft: 5,
                                }}
                            >
                                {t('see_location')}
                            </Text>
                        </View>
                    ) : (
                        <Text
                            style={{
                                fontSize: 14,
                                color: getColor('hint'),
                                fontFamily: FONT_MEDIUM,
                            }}
                        >
                            {item.displayDate(t('date')) +
                                ' ' +
                                new Date(
                                    item.getBetterDate() ?? '',
                                ).toLocaleTimeString(t('date') ?? '', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                        </Text>
                    )}
                </View>
            ) : (
                <View
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    <View>
                        <View
                            style={{
                                marginBottom: 5,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text
                                style={{
                                    flex: 1,
                                    color: getColor('text'),
                                    fontSize: 18,
                                    fontFamily: FONT_BOLD,
                                }}
                            >
                                {item.getResumeContent()}
                            </Text>

                            <HeartIcon color={HEART_COLOR} />
                        </View>
                    </View>

                    <Text
                        style={{
                            fontSize: 14,
                            color: getColor('hint'),
                            fontFamily: FONT_MEDIUM,
                        }}
                    >
                        {item.getDisplayDate(t)}
                    </Text>
                </View>
            )}
        </Pressable>
    );
};
