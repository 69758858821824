import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

import {
    ArrowLeftOnRectangleIcon,
    ArrowRightOnRectangleIcon,
    CalendarDaysIcon,
    ChatBubbleOvalLeftIcon,
    NewspaperIcon,
    UserGroupIcon,
    UserPlusIcon,
} from 'react-native-heroicons/solid';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
import { getDisplayDateUtils } from '../utils/utils';
import ChatGroup from './ChatGroup';
import Event from './Event';
import Group from './Group';
import Post from './Post';
import User from './User';
import { FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { styles } from '../utils/styles';

export default class History extends JTOObject {
    public static _TYPE: string = 'History';

    public date: JTOProperty<string>;
    public type: JTOProperty<string>;

    public event: JTOSimple<Event>;

    public about: JTOSimple<Group | ChatGroup | User | Event | Post>;

    constructor(parent?: JTOElement | null) {
        super(History._TYPE, parent);

        this.date = new JTOProperty<string>('date', this);
        this.type = new JTOProperty<string>('type', this);

        this.event = new JTOSimple<Event>(Event, 'history_has_event', this);
        this.about = new JTOSimple<Group | ChatGroup | User | Event | Post>(
            [Group, ChatGroup, User, Event, Post],
            'history_about',
            this,
        );
    }

    // 1 is for description then pressable
    // 2 is for pressable then description
    // 3 is for description and pressable not in the same line
    getViewType(): number {
        let res: number = 0;

        switch (this.type.get()) {
            case 'joinGroup':
                res = 1;
                break;

            case 'sendMessage':
                res = 1;
                break;

            case 'receiveMessage':
                res = 2;
                break;

            case 'registerEvent':
                res = 1;
                break;

            case 'unregisterEvent':
                res = 1;
                break;

            case 'joinChatGroup':
                res = 1;
                break;

            case 'leaveChatGroup':
                res = 1;
                break;

            case 'createChatGroup':
                res = 1;
                break;

            case 'createPost':
                res = 3;
                break;

            case 'startEvent':
                res = 2;
                break;

            case 'endEvent':
                res = 2;
                break;

            default:
                break;
        }

        return res;
    }

    getViewText(t: any, navigation: any): JSX.Element {
        let res: JSX.Element = <></>;
        try {
            const styleView = {
                flex: 1,
                backgroundColor: 'blue',
            };

            const styleText = {
                fontSize: 14,
                fontFamily: FONT_REGULAR,
                flexWrap: 'wrap',
                width: '100%',
                backgroundColor: 'green',
            };

            switch (this.type.get()) {
                case 'joinGroup':
                    res = (
                        <View>
                            <Text
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            >
                                <Text style={{}}>
                                    {t('join_group_history')}
                                </Text>{' '}
                                <Text
                                    style={{
                                        fontFamily: FONT_MEDIUM,
                                        color: getColor('secondary'),
                                    }}
                                >
                                    {this.getAboutName(t)}
                                </Text>
                            </Text>
                        </View>
                    );
                    break;

                case 'receiveMessage':
                case 'sendMessage':
                    if (this.about.get() instanceof User) {
                        res = (
                            <Pressable
                                onPress={() => {
                                    User._instance.setCurrentChatId(
                                        this.about.get()?.getJTOIdentifier(),
                                    );
                                    User._instance.setCurrentChatType('User');
                                    navigation.navigate('MessagePage', {
                                        user: this.about.get() as ChatGroup,
                                    });
                                }}
                            >
                                <Text
                                    style={{
                                        flex: 1,
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontFamily: FONT_MEDIUM,
                                            color: getColor('secondary'),
                                        }}
                                    >
                                        {this.getAboutName(t)}
                                    </Text>{' '}
                                    <Text
                                        style={{
                                            flex: 1,
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {t('receive_message_history')}
                                    </Text>
                                </Text>
                            </Pressable>
                        );
                    } else {
                        res = (
                            <Pressable
                                onPress={() => {
                                    User._instance.setCurrentChatId(
                                        this.about.get()?.getJTOIdentifier(),
                                    );
                                    User._instance.setCurrentChatType(
                                        'ChatGroup',
                                    );
                                    navigation.navigate('MessagePage', {
                                        group: this.about.get() as ChatGroup,
                                    });
                                }}
                            >
                                <Text
                                    style={{
                                        flex: 1,
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Text
                                        style={{
                                            flex: 1,
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {t('receive_group_message_history')}
                                    </Text>{' '}
                                    <Text
                                        style={{
                                            fontFamily: FONT_MEDIUM,
                                            color: getColor('secondary'),
                                        }}
                                    >
                                        {this.getAboutName(t)}
                                    </Text>
                                </Text>
                            </Pressable>
                        );
                    }
                    break;

                case 'registerEvent':
                    res = (
                        <Pressable
                            onPress={() => {
                                navigation.push('SubEventModal', {
                                    event: this.about.get(),
                                });
                            }}
                        >
                            <Text
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            >
                                <Text style={{}}>
                                    {t('register_event_history')}
                                </Text>{' '}
                                <Text
                                    style={{
                                        fontFamily: FONT_MEDIUM,
                                        color: getColor('secondary'),
                                    }}
                                >
                                    {this.getAboutName(t)}
                                </Text>
                            </Text>
                        </Pressable>
                    );
                    break;

                case 'unregisterEvent':
                    res = (
                        <Pressable
                            onPress={() => {
                                navigation.push('SubEventModal', {
                                    event: this.about.get(),
                                });
                            }}
                        >
                            <Text
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            >
                                <Text style={{}}>
                                    {t('unregister_event_history')}
                                </Text>{' '}
                                <Text
                                    style={{
                                        fontFamily: FONT_MEDIUM,
                                        color: getColor('secondary'),
                                    }}
                                >
                                    {this.getAboutName(t)}
                                </Text>
                            </Text>
                        </Pressable>
                    );
                    break;

                case 'joinChatGroup':
                    res = (
                        <Pressable
                            onPress={() => {
                                User._instance.setCurrentChatId(
                                    this.about.get()?.getJTOIdentifier(),
                                );
                                User._instance.setCurrentChatType('ChatGroup');
                                navigation.navigate('MessagePage', {
                                    group: this.about.get() as ChatGroup,
                                });
                            }}
                        >
                            <Text
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            >
                                <Text style={{}}>
                                    {t('join_chat_group_history')}
                                </Text>{' '}
                                <Text
                                    style={{
                                        fontFamily: FONT_MEDIUM,
                                        color: getColor('secondary'),
                                    }}
                                >
                                    {this.getAboutName(t)}
                                </Text>
                            </Text>
                        </Pressable>
                    );
                    break;

                case 'leaveChatGroup':
                    res = (
                        <Pressable
                            onPress={() => {
                                User._instance.setCurrentChatId(
                                    this.about.get()?.getJTOIdentifier(),
                                );
                                User._instance.setCurrentChatType('ChatGroup');
                                navigation.navigate('MessagePage', {
                                    group: this.about.get() as ChatGroup,
                                });
                            }}
                        >
                            <Text
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            >
                                <Text style={{}}>
                                    {t('leave_chat_group_history')}
                                </Text>{' '}
                                <Text
                                    style={{
                                        fontFamily: FONT_MEDIUM,
                                        color: getColor('secondary'),
                                    }}
                                >
                                    {this.getAboutName(t)}
                                </Text>
                            </Text>
                        </Pressable>
                    );
                    break;

                case 'createChatGroup':
                    res = (
                        <Pressable
                            onPress={() => {
                                User._instance.setCurrentChatId(
                                    this.about.get()?.getJTOIdentifier(),
                                );
                                User._instance.setCurrentChatType('ChatGroup');
                                navigation.navigate('MessagePage', {
                                    group: this.about.get() as ChatGroup,
                                });
                            }}
                        >
                            <Text
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    color: getColor('text'),
                                }}
                            >
                                <Text style={{}}>
                                    {t('create_chat_group_history')}
                                </Text>{' '}
                                <Text
                                    style={{
                                        fontFamily: FONT_MEDIUM,
                                        color: getColor('secondary'),
                                    }}
                                >
                                    {this.getAboutName(t)}
                                </Text>
                            </Text>
                        </Pressable>
                    );
                    break;

                case 'createPost':
                    res = (
                        <Pressable
                            onPress={() => {
                                if (this.about.get() !== null) {
                                    navigation.push('PostCommentModal', {
                                        post: this.about.get(),
                                        event: this.event.get(),
                                    });
                                }
                            }}
                        >
                            <Text
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    color: getColor('text'),
                                }}
                            >
                                <Text style={{}}>{t('post_history')}</Text>{' '}
                                {this.about.get() !== null ? (
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                fontFamily: FONT_MEDIUM,
                                                color: getColor('secondary'),
                                                flexWrap: 'wrap',
                                                flexDirection: 'row',
                                            },
                                        ]}
                                    >
                                        {this.getAboutName(t)}
                                    </Text>
                                ) : (
                                    <Text
                                        style={[
                                            styles.text,
                                            { color: getColor('hint') },
                                        ]}
                                    >
                                        {t('post_deleted')}
                                    </Text>
                                )}
                            </Text>
                        </Pressable>
                    );
                    break;

                case 'startEvent':
                    res = (
                        <Pressable
                            onPress={() => {
                                navigation.push('SubEventModal', {
                                    event: this.about.get(),
                                });
                            }}
                        >
                            <Text
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: FONT_MEDIUM,
                                        color: getColor('secondary'),
                                    }}
                                >
                                    {this.getAboutName(t)}
                                </Text>{' '}
                                <Text style={{}}>
                                    {t('start_event_history')}
                                </Text>
                            </Text>
                        </Pressable>
                    );
                    break;

                case 'endEvent':
                    res = (
                        <Pressable
                            onPress={() => {
                                navigation.push('SubEventModal', {
                                    event: this.about.get(),
                                });
                            }}
                        >
                            <Text
                                style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: FONT_MEDIUM,
                                        color: getColor('secondary'),
                                    }}
                                >
                                    {this.getAboutName(t)}
                                </Text>{' '}
                                <Text style={{}}>{t('end_event_history')}</Text>
                            </Text>
                        </Pressable>
                    );
                    break;

                default:
                    res = <Text>other</Text>;
                    break;
            }
        } catch (e) {
            res = <Text>other</Text>;
        }
        return res;
    }

    // 1 is for description then pressable
    // 2 is for pressable then description
    // 3 is for description and pressable not in the same line
    getViewIcon(): JSX.Element {
        let res: JSX.Element;

        switch (this.type.get()) {
            case 'joinGroup':
                res = (
                    <UserPlusIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;

            case 'sendMessage':
                res = (
                    <ChatBubbleOvalLeftIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;

            case 'receiveMessage':
                res = (
                    <ChatBubbleOvalLeftIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;

            case 'registerEvent':
                res = (
                    <ArrowLeftOnRectangleIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;

            case 'unregisterEvent':
                res = (
                    <ArrowRightOnRectangleIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;

            case 'joinChatGroup':
                res = (
                    <UserPlusIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;

            case 'leaveChatGroup':
                res = (
                    <ArrowRightOnRectangleIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;

            case 'createChatGroup':
                res = (
                    <UserGroupIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;

            case 'createPost':
                res = (
                    <NewspaperIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;

            case 'startEvent':
                res = (
                    <CalendarDaysIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;

            case 'endEvent':
                res = (
                    <CalendarDaysIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;

            default:
                res = (
                    <CalendarDaysIcon
                        size={25}
                        fill={'white'}
                    />
                );
                break;
        }

        return res;
    }

    getDescription(t: (...args: any) => string): string {
        let res = '';

        switch (this.type.get()) {
            case 'joinGroup':
                res = t('join_group_history');
                break;

            case 'sendMessage':
                res = t('send_message_history');
                break;

            case 'receiveMessage':
                res = t('receive_message_history');
                break;

            case 'registerEvent':
                res = t('register_event_history');
                break;

            case 'unregisterEvent':
                res = t('unregister_event_history');
                break;

            case 'joinChatGroup':
                res = t('join_chat_group_history');
                break;

            case 'leaveChatGroup':
                res = t('leave_chat_group_history');
                break;

            case 'createChatGroup':
                res = t('create_chat_group_history');
                break;

            case 'createPost':
                res = t('post_history');
                break;

            case 'startEvent':
                res = t('start_event_history');
                break;

            case 'endEvent':
                res = t('end_event_history');
                break;

            default:
                break;
        }

        return res;
    }

    getAboutName(t: (...args: any) => string): string {
        let res = '';

        switch (this.about.get()?.getJTOType()) {
            case Group._TYPE:
                res = (this.about.get() as Group)?.name.get() ?? '';
                break;

            case ChatGroup._TYPE:
                res = (this.about.get() as ChatGroup)?.name.get() ?? '';
                break;

            case User._TYPE:
                res = (this.about.get() as User)?.getDisplayName() ?? '';
                break;

            case Event._TYPE:
                res = (this.about.get() as Event)?.name.get() ?? '';
                break;

            case Post._TYPE:
                res = t('view_post');
                break;

            default:
                res = t('delete_element');
                break;
        }

        return res;
    }

    hasEvent(): boolean {
        return this.event !== undefined;
    }

    getDisplayDate(t: (...args: any) => string): string {
        return getDisplayDateUtils(t, this.date.get() ?? '');
    }
}
