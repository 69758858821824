import {
    Alert,
    Image,
    Keyboard,
    PermissionsAndroid,
    Platform,
    Pressable,
    Text,
    View,
} from 'react-native';
import React from 'react';
import {
    CameraIcon,
    ChevronLeftIcon,
    DocumentIcon,
    PaperAirplaneIcon,
    PhotoIcon,
    TrashIcon,
} from 'react-native-heroicons/solid';
import User from '../../object/User';
import Event from '../../object/Event';
import { SERVER_URL } from '../../utils/constants';
import { NAVIGATION_BAR_HEIGHT, STATUS_BAR_HEIGHT } from '../../utils/screen';
import { getColor } from '../../utils/theme';

import { MpInput } from '../../base/MpInput';
import { styles } from '../../utils/styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import SocketInstance from '../../utils/SocketInstance';
import JTOListFetch from '../../object/JTOListFetch';
import { Message } from '../../object/Message';
import ChatGroup from '../../object/ChatGroup';
import DocumentPicker from 'react-native-document-picker';
//import RNFS from 'react-native-fs';
import { FileElementView } from '../FileElementView';
import { PastilleView } from '../PastilleView';
//import { ScrollView } from 'react-native-gesture-handler';
import {
    fileToBase64,
    getOrientationFromUri,
    resizeAndFixRotation,
    useKeyboardVisible,
    width,
} from '../../utils/utils';
import { PostFileModal } from '../modal/PostFileModal';

export interface IBottomMessagePageProps {
    receiver: User | ChatGroup;
    type: string;
    navigation: any;
    messageList: JTOListFetch<Message>;
}
export const isImageFile = (uri: string) => {
    return /\.(jpg|jpeg|png|gif|bmp)$/i.test(uri);
};

export const BottomMessagePage = (props: IBottomMessagePageProps) => {
    const { receiver, messageList, navigation } = props;

    const keyboardVisible = useKeyboardVisible();

    const { t } = useTranslation();

    const type = props.type;

    const [message, setMessage] = useState<string>('');
    const [currentFile, setCurrentFile] = useState<File[]>([]);

    const [openModal, setOpenModal] = useState<boolean>(false);

    const isImage = (fileType: string) => {
        return fileType.includes('image');
    };

    return (
        <View
            style={{
                flexDirection: 'column',
                paddingHorizontal: 0,
                backgroundColor: getColor('background'),
                borderTopColor: getColor('border'),
                borderTopWidth: 1,
            }}
        >
            <View
                //horizontal
                style={{
                    paddingHorizontal: 10,
                    height: currentFile.length === 0 ? 0 : (width() - 70) / 3,
                    width: width(),
                    flexDirection: 'row',
                    display: 'flex',
                }}
            >
                {currentFile.map((file, index) => {
                    return (
                        <View key={index}>
                            <FileElementView
                                filename={file.name}
                                fileuri={URL.createObjectURL(file)}
                                type={file.type}
                            />
                            <Pressable
                                style={{
                                    position: 'absolute',
                                    bottom: 10,
                                    left: 10,
                                }}
                                onPress={() => {
                                    setCurrentFile(
                                        currentFile.filter((elem) => {
                                            return elem.name !== file.name;
                                        }),
                                    );
                                }}
                            >
                                <PastilleView
                                    size={35}
                                    color={getColor('error')}
                                    icon={
                                        <TrashIcon
                                            color={'white'}
                                            size={25}
                                        />
                                    }
                                />
                            </Pressable>
                        </View>
                    );
                })}
            </View>

            <View
                style={{
                    flexDirection: 'row',
                    paddingHorizontal: 15,
                    backgroundColor: getColor('background'),
                    borderTopColor: getColor('border'),
                    paddingVertical: 6,
                    paddingBottom:
                        6 +
                        (Platform.OS === 'android' && !keyboardVisible
                            ? NAVIGATION_BAR_HEIGHT
                            : 0),
                    alignItems: 'flex-end',
                }}
            >
                {
                    <Pressable
                        onPress={async () => {
                            setOpenModal(true);
                        }}
                        style={{
                            marginRight: 8,
                        }}
                    >
                        <PastilleView
                            size={40}
                            color={getColor('primary')}
                            icon={<DocumentIcon color={'white'} />}
                        />
                    </Pressable>
                }

                <View
                    style={{
                        flex: 1,
                        marginRight: 8,
                    }}
                >
                    <MpInput
                        style={styles.inputMessage}
                        placeholder={t('message') ?? ''}
                        value={message}
                        multiline={true}
                        defaultValue={message}
                        onChangeText={(text) => {
                            setMessage(text);
                        }}
                    />
                </View>
                <Pressable
                    onPress={async () => {
                        let data: any;

                        if (currentFile.length > 0) {
                            currentFile.forEach(async (file) => {
                                const base64 = await fileToBase64(file);
                                data = {
                                    file: base64,
                                    content: message,
                                    id_dest: receiver.getJTOIdentifier(),
                                    id_event: User._instance.getEvent(),

                                    type,
                                    hasFile: true,
                                    fileType: file.type,
                                    fileSize: file.size,
                                    fileName: file.name,
                                };

                                if (type === 'User') {
                                    SocketInstance.get().socket?.emit(
                                        'message.create.web.user',
                                        data,
                                    );
                                    setMessage('');
                                    User._fileList = currentFile;
                                    User._destFile =
                                        receiver.getJTOIdentifier();
                                } else {
                                    SocketInstance.get().socket?.emit(
                                        'message.create.web.group',
                                        data,
                                    );
                                    setMessage('');
                                    User._fileList = currentFile;
                                    User._destFile =
                                        receiver.getJTOIdentifier();
                                }
                            });
                        } else {
                            data = {
                                content: message,
                                id_dest: receiver.getJTOIdentifier(),
                                id_event: User._instance
                                    .getEvent()
                                    ?.getJTOIdentifier(),
                                has_file: false,
                                type,
                            };

                            if (type === 'User') {
                                SocketInstance.get().socket?.emit(
                                    'message.create.web.user',
                                    data,
                                );
                                setMessage('');
                                User._fileList = currentFile;
                                User._destFile = receiver.getJTOIdentifier();
                            } else {
                                SocketInstance.get().socket?.emit(
                                    'message.create.web.group',
                                    data,
                                );
                                setMessage('');
                                User._fileList = currentFile;
                                User._destFile = receiver.getJTOIdentifier();
                            }
                        }

                        let messageObj: Message;
                        messageObj = new Message(messageList);
                        messageObj.date.set(new Date().toISOString());
                        messageObj.content.set(message);
                        messageObj.userSend.set(User._instance);
                        messageObj.receiver.set(null);

                        messageList.getList().push(messageObj);
                        messageList.sort();

                        messageList.notifyViewNow();

                        setMessage('');
                        setCurrentFile([]);
                    }}
                >
                    <PastilleView
                        size={40}
                        color={getColor('primary')}
                        icon={
                            <PaperAirplaneIcon
                                style={{
                                    paddingLeft: 2,
                                }}
                                color={'white'}
                            />
                        }
                    />
                </Pressable>
            </View>
            <PostFileModal
                open={openModal}
                onClose={(files) => {
                    setOpenModal(false);
                    //if files is not empty, add files to currentFile
                    if (files.length > 0) {
                        setCurrentFile([...currentFile, ...files]);
                    }
                }}
            />
        </View>
    );
};
