import { post } from '../utils/http';
import { useTranslation } from 'react-i18next';
import { Pressable, Switch, Text, View } from 'react-native';
import React from 'react';
//import { ScrollView } from 'react-native-gesture-handler';
import {
    BellIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    ChatBubbleOvalLeftIcon,
    EnvelopeIcon,
    HandThumbUpIcon,
    TrophyIcon,
    UserGroupIcon,
    UserIcon,
} from 'react-native-heroicons/solid';
import { Header } from '../component/Header';
import { PastilleView } from '../component/PastilleView';
import User from '../object/User';
import Bonheur from '../svg/Bonheur';
import { NAVIGATION_BAR_HEIGHT, TAB_HEIGHT } from '../utils/screen';
import { styles } from '../utils/styles';
import { FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import { IModalProps } from './IModalProps';
import useJTOState from '../utils/jto/useJTOState';

const actionList = [
    {
        label: 'show_profile_title',
        description: 'show_profile_description',
        color: getColor('primary'),
        icon: (
            <UserIcon
                color={'white'}
                size={22}
            />
        ),
        key: 'showProfile',
        keySend: 'show_profile',
    },
    {
        label: 'show_event_title',
        description: 'show_event_description',
        color: getColor('primary'),
        icon: (
            <Bonheur
                fill={'white'}
                height={35}
                width={35}
            />
        ),
        key: 'showEvent',
        keySend: 'show_event',
    },
    {
        label: 'show_group_title',
        description: 'show_group_description',
        color: getColor('primary'),
        icon: (
            <UserGroupIcon
                fill={'white'}
                height={22}
                width={22}
            />
        ),
        key: 'showGroup',
        keySend: 'show_group',
    },
    {
        label: 'use_feedback_title',
        description: 'use_feedback_description',
        color: getColor('primary'),
        icon: (
            <HandThumbUpIcon
                fill={'white'}
                height={22}
                width={22}
            />
        ),
        key: 'useFeedback',
        keySend: 'use_feedback',
    },
    {
        label: 'use_rank_title',
        description: 'use_rank_description',
        color: getColor('primary'),
        icon: (
            <TrophyIcon
                fill={'white'}
                height={20}
                width={20}
            />
        ),
        key: 'useRank',
        keySend: 'use_rank',
    },
    {
        label: 'use_message_title',
        description: 'use_message_description',
        color: getColor('primary'),
        icon: (
            <ChatBubbleOvalLeftIcon
                fill={'white'}
                height={22}
                width={22}
            />
        ),
        key: 'useMessage',
        keySend: 'use_message',
    },
    {
        label: 'use_notif_title',
        description: 'use_notif_description',
        color: getColor('primary'),
        icon: (
            <BellIcon
                fill={'white'}
                height={22}
                width={22}
            />
        ),
        key: 'useNotif',
        keySend: 'use_notif',
    },
    {
        label: 'use_mail_title',
        description: 'use_mail_description',
        color: getColor('primary'),
        icon: (
            <EnvelopeIcon
                fill={'white'}
                height={20}
                width={20}
            />
        ),
        key: 'useMail',
        keySend: 'use_mail',
    },
];

export const ConfidentialModal = (props: IModalProps) => {
    const { t } = useTranslation();
    const user = useJTOState(User._instance);
    const act = (action: any) => {
        (user as any)[action.key].set(
            !((user as any)[action.key].get() ?? true),
        );
        user.notifyViewNow();
        post('/user/privacy', {
            key: action.keySend,
            value: (user as any)[action.key].get() ?? true,
        })
            .then((res) => {
                // NOTHING
            })
            .catch((err) => {
                // NOTHING
            });
    };
    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('privacy')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: -60,
                        height: '100%',
                    }}
                >
                    <View
                        style={{
                            height: 70,
                            width: width(),
                        }}
                    ></View>
                    {actionList.map((action, index) => {
                        return (
                            <Pressable
                                style={[
                                    index !== 0 && {
                                        borderTopWidth: 1,
                                        borderColor: getColor('border'),
                                    },
                                    {
                                        flexDirection: 'row',
                                        marginHorizontal: 20,
                                        padding: 10,
                                        alignItems: 'center',
                                        backgroundColor: getColor('card'),
                                    },
                                    index === 0 && {
                                        borderTopLeftRadius: 15,
                                        borderTopRightRadius: 15,
                                    },
                                    index === actionList.length - 1 && {
                                        borderBottomLeftRadius: 15,
                                        borderBottomRightRadius: 15,
                                    },
                                ]}
                                onPress={(e) => {
                                    e.stopPropagation();
                                    act(action);
                                }}
                            >
                                <PastilleView
                                    size={35}
                                    color={getColor('secondary')}
                                    icon={action.icon}
                                />
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        paddingRight: 10,
                                        flex: 1,
                                    }}
                                >
                                    <Text
                                        style={[
                                            {
                                                marginLeft: 10,
                                                color: 'black',
                                                fontFamily: FONT_MEDIUM,
                                                fontSize: 16,
                                            },
                                        ]}
                                    >
                                        {t(action.label)}
                                    </Text>
                                    <Text
                                        style={[
                                            {
                                                marginLeft: 10,
                                                color: 'black',
                                                fontFamily: FONT_REGULAR,
                                                fontSize: 13,
                                            },
                                        ]}
                                    >
                                        {t(action.description)}
                                    </Text>
                                </View>
                                <Switch
                                    trackColor={{
                                        false: getColor('hint'),
                                        true: getColor('secondary'),
                                    }}
                                    ios_backgroundColor='#3e3e3e'
                                    onValueChange={() => {
                                        act(action);
                                    }}
                                    value={
                                        (user as any)[action.key].get() ?? true
                                    }
                                />
                            </Pressable>
                        );
                    })}
                </View>
            </Header>
        </View>
    );
};
