import { Pressable, Text, View } from 'react-native';
import React from 'react';
import Event from '../object/Event';
import openMap from 'react-native-open-maps';
import { MapPinIcon } from 'react-native-heroicons/solid';
import { getColor, FONT_MEDIUM, FONT_REGULAR } from '../utils/theme';
import { PastilleView } from './PastilleView';
import InterestPointTiny from '../object/compressed/event/InterestPointTiny';
import { width } from '../utils/utils';

export interface IMapEventViewProps {
    event: Event | InterestPointTiny;
    top?: number;
    cartouche?: boolean;
}

export const MapEventView = (props: IMapEventViewProps) => {
    const { event, top = 15, cartouche = false } = props;
    return event.asAddress() ? (
        <Pressable
            style={{
                marginTop: top,
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
            }}
            onPress={() => {
                if (
                    (event.latitude.get() ?? '') !== '' &&
                    (event.longitude.get() ?? '') !== ''
                ) {
                    openMap({
                        latitude: parseFloat(event.latitude.get() ?? '0'),
                        longitude: parseFloat(event.longitude.get() ?? '0'),
                    } as any);
                } else {
                    let query = event.getNameAddress();
                    if (event.getAddress().trim() !== '') {
                        query += ' ' + event.getAddress();
                    }
                    openMap({
                        query,
                    } as any);
                }
            }}
        >
            <PastilleView
                size={40}
                icon={<MapPinIcon color={'white'} />}
                color={
                    cartouche
                        ? (event as Event).secondaryColor.get() ?? ''
                        : getColor('secondary')
                }
            />
            <View
                style={{
                    marginLeft: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                {event.getNameAddress().trim() !== '' && (
                    <Text
                        adjustsFontSizeToFit
                        style={{
                            width: width() - 100,
                            fontSize: cartouche ? 16 : 18,
                            fontFamily: FONT_MEDIUM,
                            color: getColor('text'),
                        }}
                    >
                        {event.getNameAddress()}
                    </Text>
                )}
                {event.getAddress().trim() !== '' && (
                    <Text
                        lineBreakMode='tail'
                        numberOfLines={2}
                        adjustsFontSizeToFit
                        style={{
                            width: width() - 100,
                            marginTop: 3,
                            fontSize: cartouche ? 12 : 14,
                            fontFamily: FONT_REGULAR,
                            color: getColor('hint'),
                        }}
                    >
                        {event.getAddress()}
                    </Text>
                )}
            </View>
        </Pressable>
    ) : (
        <></>
    );
};
