import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import { post } from '../utils/http';
import { Toast } from '../component/toast/Toast';
import { ToastManager } from '../component/toast/ToastManager';
import Invite from '../object/Invite';
import Post from '../object/Post';
import User from '../object/User';
import { CAN_REACT } from '../object/PermissionEvent';
import InviteTiny from '../object/compressed/global/InviteTiny';

export const postReactDel = (postObj: Post) => {
    if (User._instance.getEvent()?.hasPermission(CAN_REACT) ?? false) {
        const myReaction = postObj.react.get()?.reaction.get() ?? -1;

        // UPDATE MODAL
        const elemOld = postObj.reactCount
            .get()
            ?.find((e) => e.type === myReaction);
        if (elemOld !== undefined) {
            elemOld!.count -= 1;
        }
        const reaction = postObj.react.get()?.reaction.get() ?? -1;
        postObj.react.get()?.reaction.set(-1);
        postObj.notifyViewNow();

        // SEND REQUEST
        sendRequest(postObj, reaction);
    }
};

export const postReact = (postObj: Post, reaction: number) => {
    if (User._instance.getEvent()?.hasPermission(CAN_REACT) ?? false) {
        const myReaction = postObj.react.get()?.reaction.get() ?? -1;

        // UPDATE MODAL
        const reactCount = postObj.reactCount.get();
        if (reactCount !== undefined) {
            // RETIRE ANCIEN REACTION
            const elemOld = postObj.reactCount
                .get()
                ?.find((e) => e.type === myReaction);
            if (elemOld !== undefined) {
                elemOld!.count -= 1;
            }

            // L'AJOUT DE REACTION
            const elem = postObj.reactCount
                .get()
                ?.find((e) => e.type === reaction);
            if (elem !== undefined) {
                elem.count += 1;
            } else {
                postObj.reactCount.get()?.push({
                    type: reaction,
                    count: 1,
                });
            }

            const inviteJson = {
                _id: User._instance
                    ?.getEvent()
                    ?.getMyInvite()
                    ?.getJTOIdentifier(),
                _type: 'Invite',
            };
            const invite = new InviteTiny();
            invite.applyData(inviteJson);

            // UPDATE MY REACTION
            postObj.react.set(invite);
            postObj.react.get()?.reaction.set(reaction);
        } else {
            // SI AUCUNE REACTION
            postObj.reactCount.set([
                {
                    type: reaction,
                    count: 1,
                },
            ]);

            const inviteJson = {
                _id: User._instance
                    ?.getEvent()
                    ?.getMyInvite()
                    ?.getJTOIdentifier(),
                _type: 'Invite',
            };
            const invite = new InviteTiny();
            invite.applyData(inviteJson);

            postObj.react.set(invite);
            postObj.react.get()?.reaction.set(reaction);
        }

        // NOTIFY VIEW
        postObj.notifyViewNow();

        // SEND REQUEST
        sendRequest(postObj, reaction);
    }
};

const sendRequest = (postObj: Post, reaction: number) => {
    if (User._instance.getEvent()?.hasPermission(CAN_REACT) ?? false) {
        post('/post/react', {
            post_id: postObj.getJTOIdentifier(),
            reaction,
        })
            .then((res) => {
                if (HTTPUtils.isSuccess(res)) {
                    // const p = HTTPUtils.getResult(res)
                    // applyResult(p, postObj, true)
                } else {
                    ToastManager.instance.addToast(
                        new Toast(
                            User.translation(HTTPUtils.getError(res)) ?? '',
                            5000,
                            'error',
                        ),
                    );
                }
            })
            .catch((err) => {
                ToastManager.instance.addToast(
                    new Toast(
                        User.translation('went_wrong_no_internet') ?? '',
                        5000,
                        'error',
                    ),
                );
            });
    }
};
