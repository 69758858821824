import { useTranslation } from 'react-i18next';
import {
    Image,
    TouchableOpacity,
    Text,
    View,
    Linking,
    AppState,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import Event from '../object/Event';
import Invite from '../object/Invite';
import Pastille from '../svg/Pastille';
import { BACKOFFICE_URL, SERVER_URL } from '../utils/constants';
import { FONT_BOLD, FONT_MEDIUM, getColor, styles } from '../utils/theme';
import { width } from '../utils/utils';
import { CartoucheButton } from './CartoucheButton';
import { TinyCartoucheButton } from './TinyCartoucheButton';
import { InfoEventView } from './InfoEventView';
import User from '../object/User';
import { HTTPUtils } from 'mp-ts-http';
import { get } from '../utils/http';
import GeSpinner from './GeSpinner';
import { marginBottom, marginTop } from '../utils/tailwinds';

interface ICartoucheEventProps {
    event: Event;
    onSelectEvent: (event: Event) => void;
    eventPage?: boolean;
}

export const CartoucheEvent = (props: ICartoucheEventProps) => {
    const { event, onSelectEvent, eventPage } = props;

    const [isRegister, setIsRegister] = useState(false);
    const [loading, setLoading] = useState(true);
    const [appState, setAppState] = useState(AppState.currentState);

    useEffect(() => {
        if (appState === 'active' && eventPage) {
            get('/event/user', {
                user_id: User._instance!.getJTOIdentifier() ?? '',
                event_id: event.getJTOIdentifier() ?? '',
            }).then((res) => {
                if (HTTPUtils.isSuccess(res)) {
                    let result = HTTPUtils.getResult(res);
                    if (result && result.path0 && result.path0.length > 0) {
                        setIsRegister(true);
                    } else {
                        setIsRegister(false);
                    }
                } else {
                    setIsRegister(false);
                }
                setLoading(false);
            });
        }

        const subscription = AppState.addEventListener(
            'change',
            (nextAppState) => {
                if (
                    appState.match(/inactive|background/) &&
                    nextAppState === 'active'
                ) {
                }
                setAppState(nextAppState);
            },
        );

        return () => {
            subscription.remove();
        };
    }, [appState, eventPage]);

    const { t } = useTranslation();
    return (
        <TouchableOpacity
            onPress={() => {
                if (isRegister) {
                    onSelectEvent(event);
                } else {
                    Linking.openURL(
                        BACKOFFICE_URL +
                            '/inscription?id=' +
                            event.getJTOIdentifier() +
                            '&id_user=' +
                            User._instance.getJTOIdentifier(),
                    );
                }
            }}
            style={{
                marginTop: 20,
            }}
        >
            {loading ? (
                <View style={[styles.boxCenter, marginTop(75)]}>
                    <GeSpinner color={getColor('ge')} />
                </View>
            ) : (
                <View
                    style={{
                        width: width() - 40,
                        borderRadius: 20,
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: getColor('card'),
                    }}
                >
                    <View
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-start',
                            flexDirection: 'row',
                        }}
                    >
                        <View
                            style={{
                                overflow: 'hidden',
                                display: 'flex',
                                padding: 6,
                                width: '100%',
                                alignItems: 'center',
                                backgroundColor:
                                    event.primaryColor.get() ?? '#000000',
                                flexDirection: 'row',
                            }}
                        >
                            <Image
                                style={{
                                    height: 30,
                                    width: 30,
                                    borderRadius: 15,
                                    backgroundColor: 'white',
                                    overflow: 'hidden',
                                    marginRight: 8,
                                }}
                                source={{
                                    uri:
                                        SERVER_URL +
                                        '/image/' +
                                        event.logo.get(),
                                }}
                            />
                            <Text
                                style={{
                                    fontFamily: FONT_BOLD,
                                    color: 'white',
                                    fontSize: 20,
                                }}
                                adjustsFontSizeToFit
                            >
                                {event.name.get() ?? ''}
                            </Text>
                        </View>
                        {/*<View
                        style={{
                            flex: 1,
                            position: 'relative',
                            display: 'flex',
                            width: width() - 100,
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                width: width() - 230,
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: FONT_MEDIUM,
                                    color: 'white',
                                    fontSize: 24,
                                }}
                                adjustsFontSizeToFit
                            >
                                {event.name.get() ?? ''}
                            </Text>
                        </View>
                        <TouchableOpacity
                            style={{
                                display: 'flex',
                                padding: 5,
                                borderRadius: 20,
                                paddingHorizontal: 15,
                                backgroundColor: event.secondaryColor.get(),
                            }}
                            onPress={() => onSelectEvent(event)}
                        >
                            <Text
                                style={{
                                    color: 'white',
                                    fontFamily: FONT_BOLD,
                                    fontSize: 16,
                                }}
                            >
                                Ouvrir
                            </Text>
                        </TouchableOpacity>
                    </View>*/}
                    </View>

                    <View
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            padding: 8,
                        }}
                    >
                        {(event.description.get() ?? '') !== '' && (
                            <Text
                                style={{
                                    fontFamily: FONT_MEDIUM,
                                    color: getColor('text'),
                                    fontSize: 14,
                                    marginBottom: 10,
                                }}
                            >
                                {event.description.get() ?? ''}
                            </Text>
                        )}
                        <InfoEventView
                            event={event}
                            cartouche
                        />
                        <View
                            style={{
                                marginTop: 10,
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                            }}
                        >
                            <TinyCartoucheButton
                                key={event.getJTOIdentifier()}
                                color={event.secondaryColor.get() ?? '#000000'}
                                title={isRegister ? t('open') : t('register')}
                                fontSize={16}
                                onPress={() => {
                                    if (isRegister) {
                                        onSelectEvent(event);
                                    } else {
                                        Linking.openURL(
                                            BACKOFFICE_URL +
                                                '/inscription?id=' +
                                                event.getJTOIdentifier() +
                                                '&id_user=' +
                                                User._instance.getJTOIdentifier(),
                                        );
                                    }
                                }}
                            />
                        </View>
                    </View>

                    {/*<View
                style={{
                    position: 'absolute',
                    right: -width() * 0.025,
                    bottom: -width() * 0.04,
                }}
            >
                <CartoucheButton
                    key={event.getJTOIdentifier()}
                    height={(width() - width() * 0.3) / 3 / 2}
                    color={event.secondaryColor.get() ?? '#000000'}
                    title={t('open')}
                    fontSize={20}
                    onPress={() => onSelectEvent(event)}
                />
            </View>*/}
                </View>
            )}
        </TouchableOpacity>
    );
};
