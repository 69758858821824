import {
    View,
    Text,
    Pressable,
    TextInput,
    ActivityIndicator,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_REGULAR, getColor } from '../utils/theme';
import { goBackTo, height, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Keychain from 'react-native-keychain';
import { MpInput } from '../base/MpInput';
import { useEffect, useState } from 'react';
import User from '../object/User';
import { XMarkIcon } from 'react-native-heroicons/solid';
import { getRestore, post } from '../utils/http';
import { getErrorMessage } from 'mp-ts-error';
import { HTTPUtils } from 'mp-ts-http';
import ChatGroup from '../object/ChatGroup';
import { GeSpinner } from '../component/GeSpinner';
import { ToastManager } from '../component/toast/ToastManager';
import { Toast } from '../component/toast/Toast';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import UserTiny from '../object/compressed/global/UserTiny';
import useJTOState from '../utils/jto/useJTOState';

export const BlockUserModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const act = (props as any).route.params.act;

    const user = useJTOState((props as any).route.params.user as User);

    const blockMan = () => {
        setLoading(true);
        setError('');
        post('/user/' + act, {
            id: user.getJTOIdentifier(),
        })
            .then((res) => {
                setLoading(false);
                if (HTTPUtils.isSuccess(res)) {
                    User._instanceChat.chatList.reset();
                    User._instanceChat.chatList.refetch({
                        search: User._search ?? '',
                    });
                    ToastManager.instance.addToast(
                        new Toast(t(act + '_user_success'), 5000, 'success'),
                    );
                    if (act === 'block') {
                        user.blockMe.set(new UserTiny());
                        user.blockMe
                            .get()
                            ?.setJTOIdentifier(
                                User._instance.getJTOIdentifier(),
                            );
                    } else {
                        user.blockMe.set(null);
                    }
                    user.makeUpdate();

                    goBackTo(
                        props.navigation,
                        ['Tab', 'ChatPage', 'ProfilePage'],
                        'BlockUserModal',
                    );
                    User._instance.blockList.reset();
                    User._instance.blockList.fetch({}, () => {
                        User._instance.blockList.setComplite(true);
                    });
                } else {
                    setError(t(HTTPUtils.getError(res)) ?? '');
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(t('went_wrong_no_internet') ?? '');
            });
    };

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t(act)}
                line={1}
                backButton={true}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: 15,
                    }}
                >
                    {loading ? (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    height: height() * 0.5,
                                },
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View
                            style={{
                                paddingHorizontal: 20,
                            }}
                        >
                            <Text
                                style={{
                                    paddingVertical: 30,
                                    textAlign: 'center',
                                    fontFamily: FONT_REGULAR,
                                }}
                            >
                                {t(act + '_user_confirm')}
                            </Text>
                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.textError,
                                        {
                                            marginTop: 10,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}

                            <View
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <CartoucheButton
                                    onPress={() => {
                                        blockMan();
                                    }}
                                    color={getColor('error')}
                                    title={t(act)}
                                    height={80}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </Header>
        </View> /*
        <Pressable
            onPress={e => {
                e.stopPropagation();
                props.navigation.goBack();
                props.navigation.goBack();
            }}
            style={{
                position: 'absolute',
                top: 0,
                zIndex: 100,
                left: 0,
                right: 0,
                bottom: 0,
                paddingStart: width() * 0.075,
                paddingEnd: width() * 0.075,
                display: 'flex',
                paddingBottom: NAVIGATION_BAR_HEIGHT + 10,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: getColor('alpha'),
            }}>
            <View
                style={{
                    borderRadius: 15,
                    backgroundColor: getColor('background'),
                    padding: 15,
                    width: '100%',
                }}>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Text style={styles.title}>{t('block_user')}</Text>
                    <Pressable
                        style={{
                            padding: 4,
                            backgroundColor: getColor('card'),
                            borderRadius: 50,
                        }}
                        onPress={() => {
                            props.navigation.goBack();
                            props.navigation.goBack();
                        }}>
                        <XMarkIcon color={getColor('hint')} size={20} />
                    </Pressable>
                </View>
                {loading ? (
                    <View
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 35,
                        }}>
                        <GeSpinner color={getColor('primary')} />
                    </View>
                ) : (
                    <View>
                        <Text
                            style={[
                                styles.text,
                                {
                                    marginBottom: 15,
                                    marginTop: 15,
                                    color: getColor('hint'),
                                },
                            ]}>
                            {t('block_user_confirm')}
                        </Text>
                        {error !== '' && (
                            <Text
                                style={[
                                    styles.error,
                                    {
                                        marginBottom: 15,
                                        marginLeft: 15,
                                        marginRight: 15,
                                    },
                                ]}>
                                {error}
                            </Text>
                        )}
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}>
                            <Pressable
                                style={[
                                    styles.button,
                                    {
                                        marginRight: 15,
                                        backgroundColor: getColor('card'),
                                    },
                                ]}
                                onPress={() => {
                                    props.navigation.goBack();
                                    props.navigation.goBack();
                                }}>
                                <Text
                                    style={[
                                        styles.buttonText,
                                        {
                                            color: getColor('hint'),
                                        },
                                    ]}>
                                    {t('cancel')}
                                </Text>
                            </Pressable>
                            <Pressable
                                style={[
                                    styles.button,
                                    {
                                        backgroundColor: getColor('error'),
                                    },
                                ]}
                                onPress={() => {
                                    s
                                }}>
                                <Text style={styles.buttonText}>
                                    {t('block')}
                                </Text>
                            </Pressable>
                        </View>
                    </View>
                )}
            </View>
        </Pressable>*/
    );
};
