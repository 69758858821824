import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function CookingClass(props: any) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 136.4 136.37'
            {...props}
        >
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M83.48 60.85c-2.77-2.49-5.42-5.12-7.98-7.84-1.52-1.62-3.08-2.51-5.35-1.93-1.11.28-2.29.28-3.42.48-8.38 1.48-16.36 4.3-24.18 7.57-9.55 4-18.77 8.62-27.29 14.57C7.95 78.81 3.04 85.58.92 94.29c-.38 1.58-.63 3.2-.93 4.8v6.12c.16.87.34 1.73.48 2.6 3.47 20.72 24.51 33.39 44.42 26.77 8.35-2.78 14.43-8.27 19.08-15.58 7.68-12.1 13.36-25.12 17.49-38.79 1.49-4.94 2.28-10.1 3.39-15.16.37-1.68 0-2.98-1.38-4.22zm-75.7 45.32c-.05.12-.11.23-.32.67-.74-4.67-.43-8.92.93-13.08 1.85-5.67 5.28-10.19 10.09-13.69 8.37-6.1 17.62-10.59 27.13-14.56 3.07-1.28 6.21-2.38 9.32-3.55.44-.17.9-.27 1.43-.23-1.66 1.01-3.34 2-4.99 3.03-9.93 6.23-19.65 12.77-28.57 20.41-6.72 5.76-11.87 12.67-15.01 21zm5.69 5.33c1.63-6.7 5.33-12.23 9.95-17.19 5.16-5.56 11.31-9.95 17.48-14.29 8.1-5.69 16.45-10.96 25.18-15.63.16-.09.33-.17.48-.26.04-.02.08-.05.11-.07.11-.07.22-.15.31-.23-17.93 17.92-35.86 35.85-53.75 53.74-.45-1.88-.28-3.98.23-6.05zm7.24 11.83c-.69.12-1.45-.15-2.22-.24l54.57-54.57c-.69 1.29-1.28 2.46-1.93 3.59-7.3 12.92-15.35 25.34-25.05 36.61-4.95 5.76-10.79 10.44-17.99 13.08-2.33.85-4.89 1.1-7.37 1.52zm49.9-33.32c-3.57 9.04-7.61 17.84-12.98 25.98-4.41 6.69-10.31 11.19-18.32 12.5-2.56.42-5.21.29-7.82.39-.32.01-.65-.14-.8-.17 3.01-1.68 6.17-3.27 9.15-5.15 6.52-4.11 11.58-9.78 16.04-15.95C61.46 99.89 66.81 92 72.26 84.19c.19-.27.39-.54.8-.72-.82 2.18-1.6 4.37-2.46 6.54zM132.5 23.31c-14.01 11.33-28.03 22.65-42.04 33.96-.34.27-.7.53-.99.74l-11.73-11.7c2.43-2.96 4.89-5.99 7.38-8.99 9.19-11.05 18.43-22.08 27.57-33.17 1.93-2.33 4.35-3.55 7.19-4.16h2.4c3.2.51 5.57 2.34 7.66 4.67 1.1 1.22 2.33 2.32 3.47 3.5 4.31 4.46 3.91 11.25-.91 15.14z'
                    strokeWidth={0}
                />
            </g>
        </svg>
    );
}

export default CookingClass;
