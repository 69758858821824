import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

export default class InterestType extends JTOObject {
    public static _TYPE: string = 'InterestType';

    public name: JTOProperty<string>;
    public key: JTOProperty<string>;

    constructor(parent?: JTOElement | null) {
        super(InterestType._TYPE, parent);

        this.name = new JTOProperty('name', this);
        this.key = new JTOProperty('key', this);
    }
}
