import { t } from 'i18next';
import { HTTPUtils } from 'mp-ts-http';
import { get } from '../utils/http';
import { useCallback, useEffect, useState } from 'react';
import {
    View,
    ScrollView,
    RefreshControl,
    Text,
    Pressable,
} from 'react-native';
import React from 'react';
import { PlusIcon } from 'react-native-heroicons/solid';
import { CartoucheButton } from '../component/CartoucheButton';
import { GeSpinner } from '../component/GeSpinner';
import { Header } from '../component/Header';
import { PastilleView } from '../component/PastilleView';
import { PostView } from '../component/PostView';
import { Toast } from '../component/toast/Toast';
import { ToastManager } from '../component/toast/ToastManager';
import Post from '../object/Post';
import User from '../object/User';
import { getRestore } from '../utils/http';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { styles } from '../utils/styles';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { FONT_BOLD, getColor } from '../utils/theme';
import { height, isCloseToBottom, width } from '../utils/utils';
import { IModalProps } from './IModalProps';
import Event from '../object/Event';
import useJTOState from '../utils/jto/useJTOState';
import { applyResult } from '../utils/applyResult';
import { CloseAllButton } from '../component/CloseAllButton';

export const PostCommentModal = (props: IModalProps) => {
    const post = useJTOState((props as any).route.params.post as Post);

    const event = useJTOState((props as any).route.params.event as Event);

    const [refreshing, setRefreshing] = useState(false);

    const commentList = useJTOState(post.commentList);

    const onRefresh = useCallback(() => {
        setRefreshing(false);
        commentList.reset();
        commentList.refetch({
            id: post.getJTOIdentifier(),
        });
        getRestore(
            '/post/get',
            {
                id: post.getJTOIdentifier(),
            },
            (res) => {
                if (HTTPUtils.isSuccess(res)) {
                    applyResult(HTTPUtils.getResult(res), post, true);
                } else {
                    ToastManager.instance.addToast(
                        new Toast(t(HTTPUtils.getError(res)), 5000, 'error'),
                    );
                }
            },
            (err) => {
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet'), 5000, 'error'),
                );
            },
            () => {
                post.reactCount.set(undefined);
            },
        );
    }, []);

    useEffect(() => {
        commentList.fetch({
            id: post.getJTOIdentifier(),
        });
        getRestore(
            '/post/get',
            {
                id: post.getJTOIdentifier(),
            },
            (res) => {
                if (HTTPUtils.isSuccess(res)) {
                    applyResult(HTTPUtils.getResult(res), post, true);
                } else {
                    ToastManager.instance.addToast(
                        new Toast(t(HTTPUtils.getError(res)), 5000, 'error'),
                    );
                }
            },
            (err) => {
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet'), 5000, 'error'),
                );
            },
            () => {
                post.reactCount.set(undefined);
            },
        );
    }, []);

    return (
        <View
            style={{
                width: width(),
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                modal={true}
                line={1}
                navigation={props.navigation}
                backButton={true}
                onPresslogo={() => {
                    props.navigation?.popToTop();
                    props.navigation?.navigate('home');
                }}
                full
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                title={t('comment')}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: -HEADER_BONUS_HEIGHT,
                        flex: 1,
                        backgroundColor: getColor('background'),
                    }}
                    // @ts-ignore
                    refreshControl={
                        <RefreshControl
                            progressViewOffset={HEADER_BONUS_HEIGHT}
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                    scrollEventThrottle={1}
                >
                    <View
                        style={{
                            height: HEADER_BONUS_HEIGHT + 20,
                        }}
                    ></View>
                    <PostView
                        preventOpen={true}
                        post={post}
                        event={event}
                        navigation={props.navigation}
                        comment={true}
                    />
                    {
                        <View
                            style={{
                                width: width(),
                                borderBottomColor: getColor('border'),
                                borderBottomWidth: 1,
                                paddingVertical: 15,
                            }}
                        >
                            <Text
                                style={{
                                    textAlign: 'center',
                                    fontSize: 20,
                                    fontFamily: FONT_BOLD,
                                }}
                            >
                                {t('comment')}
                            </Text>
                        </View>
                    }
                    {commentList.map((comment, index) => {
                        return (
                            <PostView
                                key={comment.getJTOIdentifier()}
                                post={comment}
                                event={event}
                                navigation={props.navigation}
                            />
                        );
                    })}
                    {(commentList.isLoading() || !commentList.isComplite()) && (
                        <View
                            style={[
                                styles.boxCenter,
                                marginBottom(50),
                                commentList.size() === 0
                                    ? marginTop(200)
                                    : marginTop(50),
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    )}
                    {commentList.getList().length === 0 &&
                        !commentList.isLoading() &&
                        commentList.isComplite() && (
                            <View
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: height() * 0.3,
                                    flex: 1,
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 20,
                                        fontFamily: FONT_BOLD,
                                        color: getColor('text'),
                                        textAlign: 'center',
                                    }}
                                >
                                    {t('no_comment')}
                                </Text>
                                <Pressable
                                    style={[
                                        {
                                            backgroundColor:
                                                getColor('secondary'),
                                            paddingVertical: 10,
                                            paddingHorizontal: 14,
                                            borderRadius: 10,
                                        },
                                        { marginTop: 30 },
                                    ]}
                                    onPress={() => {
                                        props.navigation.navigate('PostModal', {
                                            event,
                                            post,
                                            comment: true,
                                        });
                                    }}
                                >
                                    <Text style={styles.buttonText}>
                                        {t('new_comment')}
                                    </Text>
                                </Pressable>
                            </View>
                        )}
                </View>
            </Header>
            <Pressable
                onPress={() => {
                    props.navigation.navigate('PostModal', {
                        event,
                        post,
                        comment: true,
                    });
                }}
                style={{
                    position: 'absolute',
                    padding: 10,
                    bottom: NAVIGATION_BAR_HEIGHT + 10,
                    right: 10,
                    borderRadius: 1000,
                }}
            >
                <PastilleView
                    size={65}
                    color={getColor('secondary')}
                    icon={
                        <PlusIcon
                            size={50}
                            color={'white'}
                        />
                    }
                />
            </Pressable>
            <CloseAllButton
                onPress={() => {
                    props.navigation.popToTop();
                }}
            />
        </View>
    );
};
