import { useTranslation } from 'react-i18next';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
import { ClockIcon, HandThumbUpIcon } from 'react-native-heroicons/solid';
import Event from '../object/Event';
import { FONT_BOLD, FONT_REGULAR, getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import { useEffect } from 'react';
import SocketInstance from '../utils/SocketInstance';
import useJTOState from '../utils/jto/useJTOState';

interface IPresentQuizProps {
    event: Event;
    nextQuiz: () => void;
    joinrealTimeQuiz: () => void;
    joinDirectQuiz: () => void;
}

export const PresentQuiz = (props: IPresentQuizProps) => {
    const { event, nextQuiz, joinrealTimeQuiz, joinDirectQuiz } = props;

    const { t } = useTranslation();

    const hasRealTimeStarted = useJTOState(event.hasRealTimeStarted);

    useEffect(() => {
        if (event.eventType.get()?.name.get() === 'Nuage de mot') {
            SocketInstance.get().socket?.emit('join.quiz.mobile', {
                id_event: event.getJTOIdentifier(),
            });
        } else if (event.eventType.get()?.name.get() === 'Quiz en temps réel') {
            SocketInstance.get().socket?.emit('join.direct.mobile', {
                id_event: event.getJTOIdentifier(),
            });
        }
    }, []);

    if (
        event.eventType.get()?.name.get() === 'Quiz' ||
        event.eventType.get()?.name.get() === 'Nuage de mot' ||
        event.eventType.get()?.name.get() === 'Quiz en temps réel'
    ) {
        if (
            event.questionList.getList().length === 0 &&
            event.questionRealTimeList.getList().length === 0 &&
            event.directQuestionList.getList().length === 0
        ) {
            return (
                <View
                    style={{
                        marginTop: 20,
                        paddingHorizontal: 20,
                        paddingVertical: 20,
                        height: '100%',
                        marginHorizontal: 20,
                        backgroundColor: getColor('card'),
                        flex: 1,
                        borderRadius: 25,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flex: 1,
                        }}
                    >
                        <ClockIcon
                            style={{
                                paddingVertical: 15,
                            }}
                            height={75}
                            width={75}
                            color={getColor('secondary')}
                        />
                        <Text
                            style={{
                                fontFamily: FONT_BOLD,
                                fontSize: 25,
                                paddingTop: 15,
                                color: getColor('text'),
                                textAlign: 'center',
                            }}
                        >
                            {t('waiting')}
                        </Text>
                        <Text
                            style={{
                                fontFamily: FONT_REGULAR,
                                paddingVertical: 15,
                                textAlign: 'center',
                                color: getColor('text'),
                                fontSize: 16,
                            }}
                        >
                            {event.eventType.get()?.name.get() === 'Quiz'
                                ? t('before_quiz')
                                : t('before_reel')}
                        </Text>
                    </View>
                </View>
            );
        } else if (event.eventType.get()?.name.get() === 'Quiz') {
            if (event.hasResponse.get() === null) {
                return (
                    <View
                        style={{
                            marginTop: 20,
                            paddingHorizontal: 20,
                            paddingVertical: 20,
                            height: '100%',
                            marginHorizontal: 20,
                            backgroundColor: getColor('card'),
                            flex: 1,
                            borderRadius: 25,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                            }}
                        >
                            <ClockIcon
                                style={{
                                    paddingVertical: 15,
                                }}
                                height={75}
                                width={75}
                                color={getColor('secondary')}
                            />
                            <Text
                                style={{
                                    fontFamily: FONT_BOLD,
                                    fontSize: 25,
                                    paddingTop: 15,
                                    color: getColor('text'),
                                    textAlign: 'center',
                                }}
                            >
                                {t('waiting_for_you')}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: FONT_REGULAR,
                                    paddingVertical: 15,
                                    textAlign: 'center',
                                    color: getColor('text'),
                                    fontSize: 16,
                                }}
                            >
                                {t('insite_answer')}
                            </Text>
                        </View>
                        <Pressable
                            onPress={() => {
                                nextQuiz();
                            }}
                            style={{
                                marginVertical: 10,
                                width: '100%',
                                borderRadius: 30,
                                paddingVertical: 15,
                                paddingHorizontal: 20,
                                backgroundColor: getColor('secondary'),
                                borderWidth: 1,
                                borderColor: getColor('secondary'),
                            }}
                        >
                            <Text
                                adjustsFontSizeToFit
                                numberOfLines={1}
                                style={{
                                    fontSize: 18,
                                    color: 'white',
                                    fontFamily: FONT_BOLD,
                                    textAlign: 'center',
                                }}
                            >
                                {t('start_quiz')}
                            </Text>
                        </Pressable>
                    </View>
                );
            } else {
                return (
                    <View
                        style={{
                            marginTop: 20,
                            paddingHorizontal: 20,
                            paddingVertical: 20,
                            height: '100%',
                            marginHorizontal: 20,
                            backgroundColor: getColor('card'),
                            flex: 1,
                            borderRadius: 25,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                            }}
                        >
                            <HandThumbUpIcon
                                style={{
                                    paddingVertical: 15,
                                }}
                                height={75}
                                width={75}
                                color={getColor('success')}
                            />
                            <Text
                                style={{
                                    fontFamily: FONT_BOLD,
                                    fontSize: 25,
                                    paddingTop: 15,
                                    color: getColor('text'),
                                    textAlign: 'center',
                                }}
                            >
                                {t('thank_answer')}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: FONT_REGULAR,
                                    paddingVertical: 15,
                                    textAlign: 'center',
                                    color: getColor('text'),
                                    fontSize: 16,
                                }}
                            >
                                {t('after_answer')}
                            </Text>
                        </View>
                        <Pressable
                            onPress={() => {
                                joinrealTimeQuiz();
                            }}
                            style={{
                                marginVertical: 10,
                                width: '100%',
                                borderRadius: 30,
                                paddingVertical: 15,
                                paddingHorizontal: 20,
                                backgroundColor: getColor('secondary'),
                                borderWidth: 1,
                                borderColor: getColor('secondary'),
                            }}
                        >
                            <Text
                                adjustsFontSizeToFit
                                numberOfLines={1}
                                style={{
                                    fontSize: 18,
                                    color: 'white',
                                    fontFamily: FONT_BOLD,
                                    textAlign: 'center',
                                }}
                            >
                                {t('start_quiz')}
                            </Text>
                        </Pressable>
                    </View>
                );
            }
        } else if (event.eventType.get()?.name.get() === 'Quiz en temps réel') {
            if (event.hasRealTimeEnded.get() === true) {
                return (
                    <View
                        style={{
                            marginTop: 20,
                            paddingHorizontal: 20,
                            paddingVertical: 20,
                            height: '100%',
                            marginHorizontal: 20,
                            backgroundColor: getColor('card'),
                            flex: 1,
                            borderRadius: 25,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                            }}
                        >
                            <HandThumbUpIcon
                                style={{
                                    paddingVertical: 15,
                                }}
                                height={75}
                                width={75}
                                color={getColor('success')}
                            />
                            <Text
                                style={{
                                    fontFamily: FONT_BOLD,
                                    fontSize: 25,
                                    paddingTop: 15,
                                    color: getColor('text'),
                                    textAlign: 'center',
                                }}
                            >
                                {t('thank_reel')}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: FONT_REGULAR,
                                    paddingVertical: 15,
                                    textAlign: 'center',
                                    color: getColor('text'),
                                    fontSize: 16,
                                }}
                            >
                                {t('after_reel')}
                            </Text>
                        </View>
                    </View>
                );
            } else if (hasRealTimeStarted.get() === true) {
                return (
                    <View
                        style={{
                            marginTop: 20,
                            paddingHorizontal: 20,
                            paddingVertical: 20,
                            height: '100%',
                            marginHorizontal: 20,
                            backgroundColor: getColor('card'),
                            flex: 1,
                            borderRadius: 25,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                            }}
                        >
                            <HandThumbUpIcon
                                style={{
                                    paddingVertical: 15,
                                }}
                                height={75}
                                width={75}
                                color={getColor('success')}
                            />
                            <Text
                                style={{
                                    fontFamily: FONT_BOLD,
                                    fontSize: 25,
                                    paddingTop: 15,
                                    color: getColor('text'),
                                    textAlign: 'center',
                                }}
                            >
                                {t('now')}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: FONT_REGULAR,
                                    paddingVertical: 15,
                                    textAlign: 'center',
                                    color: getColor('text'),
                                    fontSize: 16,
                                }}
                            >
                                {t('during_reel')}
                            </Text>
                        </View>
                        <Pressable
                            onPress={() => {
                                joinDirectQuiz();
                            }}
                            style={{
                                marginVertical: 10,
                                width: '100%',
                                borderRadius: 30,
                                paddingVertical: 15,
                                paddingHorizontal: 20,
                                backgroundColor: getColor('secondary'),
                                borderWidth: 1,
                                borderColor: getColor('secondary'),
                            }}
                        >
                            <Text
                                adjustsFontSizeToFit
                                numberOfLines={1}
                                style={{
                                    fontSize: 18,
                                    color: 'white',
                                    fontFamily: FONT_BOLD,
                                    textAlign: 'center',
                                }}
                            >
                                {t('start_quiz')}
                            </Text>
                        </Pressable>
                    </View>
                );
            } else {
                return (
                    <View
                        style={{
                            marginTop: 20,
                            paddingHorizontal: 20,
                            paddingVertical: 20,
                            height: '100%',
                            marginHorizontal: 20,
                            backgroundColor: getColor('card'),
                            flex: 1,
                            borderRadius: 25,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                            }}
                        >
                            <ClockIcon
                                style={{
                                    paddingVertical: 15,
                                }}
                                height={75}
                                width={75}
                                color={getColor('secondary')}
                            />
                            <Text
                                style={{
                                    fontFamily: FONT_BOLD,
                                    fontSize: 25,
                                    paddingTop: 15,
                                    color: getColor('text'),
                                    textAlign: 'center',
                                }}
                            >
                                {t('waiting')}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: FONT_REGULAR,
                                    paddingVertical: 15,
                                    textAlign: 'center',
                                    color: getColor('text'),
                                    fontSize: 16,
                                }}
                            >
                                {t('before_reel')}
                            </Text>
                        </View>
                    </View>
                );
            }
            return <></>;
        } else {
            if (event.hasRealTimeEnded.get() === true) {
                return (
                    <View
                        style={{
                            marginTop: 20,
                            paddingHorizontal: 20,
                            paddingVertical: 20,
                            height: '100%',
                            marginHorizontal: 20,
                            backgroundColor: getColor('card'),
                            flex: 1,
                            borderRadius: 25,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                            }}
                        >
                            <HandThumbUpIcon
                                style={{
                                    paddingVertical: 15,
                                }}
                                height={75}
                                width={75}
                                color={getColor('success')}
                            />
                            <Text
                                style={{
                                    fontFamily: FONT_BOLD,
                                    fontSize: 25,
                                    paddingTop: 15,
                                    color: getColor('text'),
                                    textAlign: 'center',
                                }}
                            >
                                {t('thank_reel')}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: FONT_REGULAR,
                                    paddingVertical: 15,
                                    textAlign: 'center',
                                    color: getColor('text'),
                                    fontSize: 16,
                                }}
                            >
                                {t('after_reel')}
                            </Text>
                        </View>
                    </View>
                );
            } else if (hasRealTimeStarted.get() === true) {
                return (
                    <View
                        style={{
                            marginTop: 20,
                            paddingHorizontal: 20,
                            paddingVertical: 20,
                            height: '100%',
                            marginHorizontal: 20,
                            backgroundColor: getColor('card'),
                            flex: 1,
                            borderRadius: 25,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                            }}
                        >
                            <HandThumbUpIcon
                                style={{
                                    paddingVertical: 15,
                                }}
                                height={75}
                                width={75}
                                color={getColor('success')}
                            />
                            <Text
                                style={{
                                    fontFamily: FONT_BOLD,
                                    fontSize: 25,
                                    paddingTop: 15,
                                    color: getColor('text'),
                                    textAlign: 'center',
                                }}
                            >
                                {t('now')}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: FONT_REGULAR,
                                    paddingVertical: 15,
                                    textAlign: 'center',
                                    color: getColor('text'),
                                    fontSize: 16,
                                }}
                            >
                                {t('during_reel')}
                            </Text>
                        </View>
                        <Pressable
                            onPress={() => {
                                joinrealTimeQuiz();
                            }}
                            style={{
                                marginVertical: 10,
                                width: '100%',
                                borderRadius: 30,
                                paddingVertical: 15,
                                paddingHorizontal: 20,
                                backgroundColor: getColor('secondary'),
                                borderWidth: 1,
                                borderColor: getColor('secondary'),
                            }}
                        >
                            <Text
                                adjustsFontSizeToFit
                                numberOfLines={1}
                                style={{
                                    fontSize: 18,
                                    color: 'white',
                                    fontFamily: FONT_BOLD,
                                    textAlign: 'center',
                                }}
                            >
                                {t('start_quiz')}
                            </Text>
                        </Pressable>
                    </View>
                );
            } else {
                return (
                    <View
                        style={{
                            marginTop: 20,
                            paddingHorizontal: 20,
                            paddingVertical: 20,
                            height: '100%',
                            marginHorizontal: 20,
                            backgroundColor: getColor('card'),
                            flex: 1,
                            borderRadius: 25,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                            }}
                        >
                            <ClockIcon
                                style={{
                                    paddingVertical: 15,
                                }}
                                height={75}
                                width={75}
                                color={getColor('secondary')}
                            />
                            <Text
                                style={{
                                    fontFamily: FONT_BOLD,
                                    fontSize: 25,
                                    paddingTop: 15,
                                    color: getColor('text'),
                                    textAlign: 'center',
                                }}
                            >
                                {t('waiting')}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: FONT_REGULAR,
                                    paddingVertical: 15,
                                    textAlign: 'center',
                                    color: getColor('text'),
                                    fontSize: 16,
                                }}
                            >
                                {t('before_reel')}
                            </Text>
                        </View>
                    </View>
                );
            }
            return <></>;
        }
    } else {
        return <></>;
    }
};
