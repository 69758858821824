import { HTTPUtils } from 'mp-ts-http';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, ScrollView, Text, View } from 'react-native';
import React from 'react';
import { getRestore } from '../../utils/http';
import { NAVIGATION_BAR_HEIGHT } from '../../utils/screen';
import { styles } from '../../utils/styles';
import { FONT_BOLD, getColor } from '../../utils/theme';
import { GeSpinner } from '../GeSpinner';
import { ScheduleEvent } from '../ScheduleEvent';
import { Toast } from '../toast/Toast';
import { ToastManager } from '../toast/ToastManager';
import { IEventTabPageProps } from './IEventTabPageProps';
import useJTOState from '../../utils/jto/useJTOState';
import { applyResult } from '../../utils/applyResult';

export const UnderEventPage = (props: IEventTabPageProps) => {
    const { t } = useTranslation();

    const event = useJTOState(props.event);
    const [loadingSubEvent, setLoadingSubEvent] = useState(false);

    const loadSubEvents = () => {
        if (event.getJTOIdentifier() === undefined) return;
        getRestore(
            '/event/sub-event/list',
            {
                id: event.getJTOIdentifier(),
            },
            (res: any) => {
                setLoadingSubEvent(false);
                if (HTTPUtils.isSuccess(res)) {
                    const e = HTTPUtils.getResult(res);
                    applyResult(e, event, true);
                    event.notifyViewNow();
                } else {
                }
            },
            (err: any) => {
                ToastManager.instance.addToast(
                    new Toast(t('went_wrong_no_internet'), 5000, 'error'),
                );
            },
            (res: any) => {
                event.subEventList.setList([]);
            },
        );
    };

    useEffect(() => {
        loadSubEvents();
    }, [props.event.getJTOIdentifier()]);

    return loadingSubEvent || event.subEventList.size() !== 0 ? (
        <View>
            <View
                style={{
                    marginTop: 10,
                    paddingHorizontal: 20,
                }}
            >
                <Text
                    style={{
                        color: getColor('text'),
                        fontFamily: FONT_BOLD,
                        fontSize: 20,
                    }}
                >
                    {t('events')}
                </Text>
            </View>
            {loadingSubEvent ? (
                <View
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <GeSpinner color={getColor('primary')} />
                </View>
            ) : event.subEventList.size() === 0 ? (
                <View
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingVertical: 100,
                        justifyContent: 'center',
                    }}
                >
                    <Text
                        style={[
                            styles.text,
                            { marginBottom: NAVIGATION_BAR_HEIGHT },
                        ]}
                    >
                        {t('no_event')}
                    </Text>
                </View>
            ) : (
                <View
                    /*bounces={false}
                    bouncesZoom={false}
                    alwaysBounceHorizontal={false}
                    alwaysBounceVertical={false}*/
                    style={{
                        padding: 20,
                        paddingBottom: NAVIGATION_BAR_HEIGHT,
                    }}
                >
                    {event.subEventList.map((subEvent, i) => (
                        <ScheduleEvent
                            navigation={props.navigation}
                            last={i === event.subEventList.size() - 1}
                            event={subEvent}
                            key={subEvent.getJTOIdentifier()}
                        />
                    ))}
                </View>
            )}
        </View>
    ) : (
        <></>
    );
};
