import {
    View,
    Text,
    Pressable,
    ActivityIndicator,
    Linking,
} from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import User from '../object/User';
import { Header } from '../component/Header';
import { CartoucheButton } from '../component/CartoucheButton';
import Event from '../object/Event';
//import { ScrollView } from 'react-native-gesture-handler';
import { FileElementView } from '../component/FileElementView';
import { SERVER_URL } from '../utils/constants';
import File from '../object/File';

export const DocumentEventModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const event = (props as any).route.params.event;

    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('files')}
                line={1}
                full
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: -40,
                        marginHorizontal: 12.5,
                        flex: 1,
                    }}
                >
                    <View
                        style={{
                            height: 70,
                        }}
                    ></View>
                    <View
                        style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        {event.fileList
                            .getList()
                            .map((file: any, index: any) => (
                                <FileElementView
                                    key={file.getJTOIdentifier()}
                                    file={file}
                                    navigation={props.navigation}
                                />
                            ))}
                    </View>
                </View>
            </Header>
        </View>
    );
};
