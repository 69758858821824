import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

function BreakfastMeeting(props: any) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 107.81 118.61'
            {...props}
        >
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M107.67 71.17c-1.06-9.45-9.27-16.94-18.96-17.2-3.93-.11-7.87-.04-11.81-.04H9.62c-3.88 0-6 2.13-6.01 6.01 0 6.1-.08 12.2.04 18.29.18 9.25 3.25 17.51 9.02 24.74 1.29 1.61 2.72 3.1 4.25 4.83h-1.48c-3.59.03-7.18-.04-10.76.15-2.25.12-3.75 1.56-4.41 3.7-.65 2.14-.12 4.08 1.59 5.55.71.61 1.65.94 2.48 1.4h81.32c.3-.15.6-.3.9-.44 2.05-.94 3.19-2.53 3.27-4.78.12-3.24-2.3-5.53-5.88-5.57-3.2-.03-6.41 0-9.61 0H73.1c.05-.22.03-.31.08-.36.18-.2.37-.39.57-.57 3.66-3.57 6.59-7.65 8.7-12.31.39-.86.86-1.13 1.81-1.17 2.96-.13 6 0 8.84-.7 9.67-2.37 15.72-11.51 14.59-21.53zm-12.17 7.5c-1.94 3.03-5.89 4.57-9.3 3.9V64.73c4.01-.28 7.23.78 9.34 4.03 2.11 3.24 2.05 6.65-.04 9.91zM68.28 37.47c.06 3.03-2.12 5.46-5 5.61-3.04.16-5.52-1.88-5.67-4.99-.12-2.63-1.26-4.6-3-6.42-1.63-1.7-3.25-3.42-4.68-5.28-2.28-2.98-3.14-6.48-3.17-8.6-.03-6.38 1.62-10.5 4.84-14.01.81-.88 1.62-1.78 2.53-2.54 2.18-1.83 5.29-1.62 7.24.39 1.92 1.98 2.05 5.02.26 7.17-.94 1.13-1.98 2.16-2.87 3.32-1.72 2.25-1.75 5.92.04 8.11 1.76 2.15 3.75 4.1 5.53 6.23 2.66 3.16 3.86 6.89 3.94 11zM43.1 37.84c-.05 2.94-2.36 5.21-5.22 5.26-2.96.05-5.4-2.14-5.47-5.12-.05-2.4-1.01-4.3-2.64-5.95-.95-.96-1.87-1.95-2.8-2.92-3.22-3.34-5.17-7.25-5.3-11.52-.3-5.6 1.35-10.05 4.83-13.85.7-.77 1.41-1.53 2.18-2.24 2.18-2 5.35-1.98 7.44.03 2.03 1.96 2.18 5.09.34 7.33-.46.57-.98 1.08-1.49 1.61-3.52 3.68-3.48 7.71.02 11.45 1.87 1.99 3.75 4 5.36 6.2 2.09 2.85 2.84 6.21 2.78 9.72z'
                    strokeWidth={0}
                />
            </g>
        </svg>
    );
}

export default BreakfastMeeting;
