import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function SvgLoading6(props: any) {
    return (
        <svg
            viewBox='0 0 4668.000000 2326.000000'
            {...props}
        >
            <path
                d='M4100 11509C1974 10063 186 8847 127 8806l-109-74 79-67c414-355 1048-821 1593-1170 2371-1523 5369-2602 8835-3179 1508-251 2969-395 4650-458 661-25 2220-12 2508 21l57 6 2970 3294c1633 1812 2968 3296 2966 3298-1 2-70-1-152-7-1376-89-3075-102-4469-35-4001 194-7328 1059-9594 2492-550 348-1078 770-1371 1097-57 64-109 116-115 115-5 0-1749-1184-3875-2630zM29580 10374c-472-7-1356-46-1437-62-28-6-360-424-2801-3527-1524-1936-2769-3521-2768-3523 2-1 248 3 547 9 846 18 1884 4 2724-36 3548-172 6136-855 7575-2001 153-122 454-417 554-544 156-197 284-418 347-598 26-75 34-91 44-80 7 7 1064 1655 2349 3662l2336 3649-56 101c-389 699-1041 1270-1975 1731-1431 706-3523 1121-6074 1204-338 12-1070 19-1365 15z'
                transform='matrix(.1 0 0 -.1 0 2326)'
            />
        </svg>
    );
}
export default SvgLoading6;
