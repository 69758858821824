import { Image, Linking, Pressable } from 'react-native';
import { getColor } from '../utils/theme';
import File from '../object/File';
import { SERVER_URL } from '../utils/constants';
import React, { useEffect } from 'react';

interface IAdvertItemProps {
    item: File;
    navigation: any;
}

export const AdvertItem = (props: IAdvertItemProps) => {
    const { item } = props;

    return (
        <Pressable
            onPress={() => {
                Linking.openURL(item.link.get() ?? '');
            }}
            style={{
                backgroundColor: getColor('card'),
            }}
        >
            <Image
                source={{
                    uri: SERVER_URL + '/file/' + item.path.get(),
                }}
                style={{
                    width: '100%',
                    height: 150,
                    borderRadius: 20,
                }}
            />
        </Pressable>
    );
};
