import { Platform } from 'react-native';
import React from 'react';

//export const BACKOFFICE_URL = 'https://savoiexpointegration.happly-event.com';

export const BACKOFFICE_URL = 'https://savoiexpointegration.happly-event.com/';

export let SERVER_URL: string;
if (Platform.OS === 'ios' || Platform.OS === 'web') {
    SERVER_URL = 'https://api.savoiexpo.happly-event.com';
    //SERVER_URL = 'http://localhost:5100';

    // INCUBATEUR
    // SERVER_URL = 'http://10.7.145.71:5001';
    // SERVER_URL = 'http://10.7.147.67:5001';
    // SERVER_URL = "http://10.7.144.37:5001"
    // IPHONE
    // SERVER_URL = 'http://172.20.10.2:5001';

    // MAISON
    // SERVER_URL = 'http://192.168.0.40:5001';

    // CHINE
    // SERVER_URL = "http://192.168.253.59:5001"
} else {
    SERVER_URL = 'https://api.savoiexpo.happly-event.com';
    //SERVER_URL = 'http://localhost:5100';
}
export const SITE_URL = 'https://savoiexpointegration.happly-event.com';
