import { useTranslation } from 'react-i18next';
import Question from '../object/Question';
import User from '../object/User';
import { IModalProps } from './IModalProps';
import { View, Pressable, Text } from 'react-native';
import React from 'react';
import { Header } from '../component/Header';
import { width } from '../utils/utils';
import { getColor } from '../utils/theme';
//import { ScrollView } from 'react-native-gesture-handler';
import { CartoucheButton } from '../component/CartoucheButton';
import { NAVIGATION_BAR_HEIGHT } from '../utils/screen';
import Event from '../object/Event';
import { post } from '../utils/http';
import useJTOState from '../utils/jto/useJTOState';

export const ScoreModal = (props: IModalProps) => {
    const { t } = useTranslation();
    User.translation = t;

    const quiz = useJTOState((props as any).route.params.quiz as Event);

    const next = () => {
        props.navigation.pop();
        post('/response/add', {
            quizId: quiz.getJTOIdentifier(),
            // answerList: quiz.myAnswerList,
            eventId: User._instance.getEvent()?.getJTOIdentifier(),
            userId: User._instance.getJTOIdentifier(),
        })
            .then(() => {
                // TODO
            })
            .catch(() => {
                // TODO
            });
    };

    return (
        <View
            style={{
                width: width(),
                paddingBottom: 0,
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                modal
                full
                onPresslogo={() => {
                    props.navigation.popToTop();
                    props.navigation.navigate('home');
                }}
                line={t('score').length <= 25 ? 1.01 : 3}
                title={t('score')}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        flex: 1,
                        marginTop: -10,
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            padding: 20,
                        }}
                    >
                        <Text>
                            {quiz.myScore +
                                ' / ' +
                                quiz.questionList.getList().length}
                        </Text>
                        <Pressable
                            style={{
                                backgroundColor: getColor('background'),
                                padding: 10,
                                margin: 10,
                            }}
                        >
                            <CartoucheButton
                                color={getColor('secondary')}
                                height={100}
                                fontSize={25}
                                onPress={() => {
                                    next();
                                }}
                                title={t('close_quiz')}
                            />
                        </Pressable>
                    </View>
                </View>
            </Header>
        </View>
    );
};
