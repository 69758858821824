import {
    Text,
    View,
    Pressable,
    RefreshControl,
    SafeAreaView,
    TouchableOpacity,
    ScrollView,
} from 'react-native';
import { useCallback, useEffect, useRef, useState } from 'react';
import User from '../object/User';
import {
    CalendarDaysIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    GlobeAltIcon,
} from 'react-native-heroicons/solid';
import {
    FONT_BOLD,
    FONT_HEAVY,
    FONT_MEDIUM,
    FONT_REGULAR,
    getColor,
} from '../utils/theme';
import {
    HEADER_BONUS_HEIGHT,
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { styles } from '../utils/styles';
import { IPageProps } from './IPageProps';
//import { ScrollView } from 'react-native-gesture-handler';
import { ScheduleEvent } from '../component/ScheduleEvent';
import Event from '../object/Event';
import { Header } from '../component/Header';
import { GeSpinner } from '../component/GeSpinner';
import { height, isCloseToBottom, toNumeric, width } from '../utils/utils';
import { startConfetti } from '../component/Confetti';
import useJTOState from '../utils/jto/useJTOState';
import EventTiny from '../object/compressed/event/EventTiny';
import React from 'react';
import { WelcomeModal } from '../modal/WelcomeModal';
import { CartoucheButton } from '../component/CartoucheButton';

/*const SPECIAL_TEXT = [
    {
        title: 'JOUR 1 - MATIN',
        subtitle: 'Tous ensemble',
    },
    {
        title: 'JOUR 1 - APRÈS-MIDI',
        subtitle: 'Succès & Réussite',
    },
    {
        title: 'JOUR 2',
        subtitle: 'Place à la détente',
    },
    {
        title: 'JOUR 3',
        subtitle: 'Place à la détente',
    },
];*/
export const PlanningPage = (props: IPageProps) => {
    const { t } = useTranslation();

    const event = useJTOState(User._instance.getEvent()!);

    const today = new Date();

    const startEvent = new Date(event.getStartDate() ?? new Date());
    const endEvent = new Date(event.getEndDate() ?? new Date());

    const [displayDate, setDisplayDate] = useState(new Date(today));
    const [refreshing, setRefreshing] = useState(false);

    const [planningType, setPlanningType] = useState<'calendar' | 'my'>('my');

    const [scrollToIndex, setScrollToIndex] = useState(0);
    const ref = useRef(null);
    const [dateCords, setDateCords] = useState<number[]>([]);
    const [countRender, setCountRender] = useState(0);

    const week: Date[] = [];

    const day = displayDate.getDate();
    const month = displayDate.getMonth();
    const year = displayDate.getFullYear();
    let dayOfWeek = displayDate.getDay();
    if (dayOfWeek === 0) {
        dayOfWeek = 7;
    }

    for (let i = 0; i < 7; i++) {
        const date = new Date(year, month, day - dayOfWeek + i + 1);
        week.push(date);
    }

    const getSubEventByDate = (date: Date) => {
        const subEventListFilter = event.mySubEventList
            .getList()
            .filter((subEvent: EventTiny) => {
                // FILTER BY DATEAN
                if (subEvent.getBetterDate() === null) return false;
                if (subEvent.getBetterDate() === undefined) return false;
                if (subEvent.getBetterDate() === '') return false;

                if (planningType !== 'calendar') {
                    return true;
                }

                let res = false;

                // Variable pour le test
                const startOfDay = date;
                const endOfDay = new Date(date.getTime() + 86400000);
                const eventStart = new Date(subEvent.getStartDate() ?? today);
                const eventEnd = new Date(subEvent.getEndDate() ?? today);

                // TEST SI DATE FIT DAY
                if (startOfDay <= eventStart && endOfDay >= eventEnd) {
                    res = true;
                } else if (startOfDay > eventStart && startOfDay < eventEnd) {
                    res = true;
                } else if (endOfDay > eventStart && endOfDay < eventEnd) {
                    res = true;
                } else if (
                    startOfDay <= eventStart &&
                    endOfDay >= eventStart &&
                    endOfDay <= eventEnd
                ) {
                    res = true;
                } else if (
                    startOfDay >= eventStart &&
                    startOfDay <= eventEnd &&
                    endOfDay >= eventEnd
                ) {
                    res = true;
                }

                return res;
            });
        return subEventListFilter;
    };

    const getIndexScroll = () => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);

        const keys = Object.keys(dateCords).sort();
        let res: string = '';

        for (const key of keys) {
            if (parseInt(key, 10) < now.getTime()) {
                res = key;
            } else if (parseInt(key, 10) === now.getTime()) {
                res = key;
                return res;
            } else {
                res = key;
                break;
            }
        }
        return res;
    };

    const onRefresh = () => {
        setRefreshing(false);

        event.mySubEventList.reset();
        event.mySubEventList.refetch({
            event: User._instance.getEvent()!.getJTOIdentifier(),
        });
    };

    useEffect(() => {
        try {
            let count = 0;
            let j = 0;
            while (j < subEventList.length) {
                if (subEventList[j] instanceof Date) {
                    count++;
                }
                j++;
            }
            // tslint:disable-next-line: no-console
            if (count === countRender) {
                const index = getIndexScroll();
                // tslint:disable-next-line: no-console
                (ref.current as any)?.scrollTo({
                    x: 0,
                    y: dateCords[index as any] - 40,
                    animated: false,
                });
            }
        } catch (e) {}
    }, [countRender]);

    // SORT BY DATE AND TIME AND FILTER BY DATE
    let subEventListTemp = event.mySubEventList.getList();
    subEventListTemp = getSubEventByDate(displayDate);

    const subEventList = [] as (Date | EventTiny)[];
    for (let i = 0; i < subEventListTemp.length; i++) {
        const eventCc = subEventListTemp[i];
        const dateCc = new Date(eventCc.getBetterDate() ?? '');
        if (planningType !== 'calendar') {
            if (subEventList.length === 0) {
                subEventList.push(dateCc);
                /*const date = new Date(dateCc.getTime());
                date.setHours(13);
                date.setMinutes(30);
                subEventList.push(date);*/
            }
        }
        subEventList.push(eventCc);
        if (planningType !== 'calendar') {
            if (i !== subEventListTemp.length - 1) {
                const eventAfter = subEventListTemp[i + 1];
                const dateAfter = new Date(eventAfter.getBetterDate() ?? '');
                if (
                    dateAfter.getDate() !== dateCc.getDate() ||
                    dateAfter.getMonth() !== dateCc.getMonth() ||
                    dateAfter.getFullYear() !== dateCc.getFullYear()
                ) {
                    subEventList.push(dateAfter);
                }
            }
        }
    }
    subEventList.sort((a, b) => {
        if (a instanceof Date && b instanceof Date) {
            return a.getTime() - b.getTime();
        }
        if (a instanceof EventTiny && b instanceof Date) {
            return new Date(a.getBetterDate() ?? '').getTime() - b.getTime();
        }
        if (a instanceof Date && b instanceof EventTiny) {
            return a.getTime() - new Date(b.getBetterDate() ?? '').getTime();
        }
        if (a instanceof EventTiny && b instanceof EventTiny) {
            return (
                new Date(a.getBetterDate() ?? '').getTime() -
                new Date(b.getBetterDate() ?? '').getTime()
            );
        }
        return 0;
    });
    // ...subEventListTemp

    let content;
    if (subEventList.length === 0) {
        if (/*loading*/ false) {
            content = (
                <View
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: height(),
                        paddingBottom: NAVIGATION_BAR_HEIGHT + TAB_HEIGHT + 50,
                        paddingHorizontal: 10,
                        flex: 1,
                    }}
                >
                    <GeSpinner color={getColor('primary')} />
                </View>
            );
        } else if (displayDate.getTime() < startEvent.getTime()) {
            content = (
                <View
                    // @ts-ignore
                    showsVerticalScrollIndicator={false}
                    scrollEventThrottle={1}
                    style={{
                        flex: 1,
                        marginTop: -HEADER_BONUS_HEIGHT,
                    }}
                    refreshControl={
                        <RefreshControl
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                >
                    <View
                        style={{
                            height: HEADER_BONUS_HEIGHT,
                        }}
                    ></View>
                    <View
                        style={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingHorizontal: 10,
                            flex: 1,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 30,
                                fontFamily: FONT_BOLD,
                                color: getColor('text'),
                                textAlign: 'center',
                            }}
                        >
                            {t('before_event_title')}
                        </Text>
                        <Text
                            style={{
                                fontSize: 18,
                                paddingTop: 10,
                                fontFamily: FONT_REGULAR,
                                color: getColor('hint'),
                                textAlign: 'center',
                            }}
                        >
                            {t('before_event_description', {
                                date: startEvent.toLocaleDateString(
                                    t('date') ?? '',
                                    {
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric',
                                    },
                                ),
                                time: startEvent.toLocaleTimeString(
                                    t('date') ?? '',
                                    {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    },
                                ),
                            })}
                        </Text>
                        <Pressable
                            style={[
                                {
                                    backgroundColor: getColor('secondary'),
                                    paddingVertical: 10,
                                    paddingHorizontal: 14,
                                    borderRadius: 10,
                                },
                                { marginTop: 30 },
                            ]}
                            onPress={() => {
                                setDisplayDate(
                                    new Date(startEvent.setHours(0, 0, 0, 0)),
                                );
                            }}
                        >
                            <Text style={styles.buttonText}>
                                {t('before_event_button')}
                            </Text>
                        </Pressable>
                    </View>
                </View>
            );
        } else if (displayDate.getTime() > endEvent.getTime()) {
            content = (
                <View
                    // @ts-ignore
                    showsVerticalScrollIndicator={false}
                    scrollEventThrottle={1}
                    style={{
                        flex: 1,
                    }}
                    refreshControl={
                        <RefreshControl
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                >
                    <View
                        style={{
                            height:
                                height() -
                                TAB_HEIGHT -
                                STATUS_BAR_HEIGHT -
                                NAVIGATION_BAR_HEIGHT -
                                50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingHorizontal: 10,
                            paddingBottom:
                                NAVIGATION_BAR_HEIGHT + TAB_HEIGHT + 50,
                            flex: 1,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 30,
                                fontFamily: FONT_BOLD,
                                color: getColor('text'),
                                textAlign: 'center',
                            }}
                        >
                            {t('after_event_title')}
                        </Text>
                        <Text
                            style={{
                                fontSize: 18,
                                paddingTop: 10,
                                fontFamily: FONT_REGULAR,
                                color: getColor('hint'),
                                textAlign: 'center',
                            }}
                        >
                            {t('after_event_description', {
                                date: endEvent.toLocaleDateString(
                                    t('date') ?? '',
                                    {
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric',
                                    },
                                ),
                                time: endEvent.toLocaleTimeString(
                                    t('date') ?? '',
                                    {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    },
                                ),
                            })}
                        </Text>
                        <Pressable
                            style={[
                                {
                                    backgroundColor: getColor('secondary'),
                                    paddingVertical: 10,
                                    paddingHorizontal: 14,
                                    borderRadius: 10,
                                },
                                { marginTop: 30 },
                            ]}
                            onPress={() => {
                                setDisplayDate(
                                    new Date(endEvent.setHours(0, 0, 0, 0)),
                                );
                            }}
                        >
                            <Text style={styles.buttonText}>
                                {t('after_event_button')}
                            </Text>
                        </Pressable>
                    </View>
                </View>
            );
        } else {
            content = (
                <View
                    // @ts-ignore
                    showsVerticalScrollIndicator={false}
                    scrollEventThrottle={1}
                    style={{
                        flex: 1,
                    }}
                    refreshControl={
                        <RefreshControl
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            onRefresh={onRefresh}
                        />
                    }
                >
                    <View
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingHorizontal: 10,
                            height: '100%',
                            paddingBottom:
                                NAVIGATION_BAR_HEIGHT + TAB_HEIGHT + 50,
                            flex: 1,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 30,
                                fontFamily: FONT_BOLD,
                                color: getColor('text'),
                                textAlign: 'center',
                            }}
                        >
                            {t('during_event_title')}
                        </Text>
                        <Text
                            style={{
                                fontSize: 18,
                                paddingTop: 10,
                                fontFamily: FONT_REGULAR,
                                color: getColor('hint'),
                                textAlign: 'center',
                            }}
                        >
                            {t('during_event_description', {
                                date: displayDate.toLocaleDateString(
                                    t('date') ?? '',
                                    {
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric',
                                    },
                                ),
                                time: displayDate.toLocaleTimeString(
                                    t('date') ?? '',
                                    {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    },
                                ),
                            })}
                        </Text>
                        <Pressable
                            style={[
                                {
                                    backgroundColor: getColor('secondary'),
                                    paddingVertical: 10,
                                    paddingHorizontal: 14,
                                    borderRadius: 10,
                                },
                                { marginTop: 30 },
                            ]}
                            onPress={() => {
                                const nextDate = event.getNextDate(displayDate);
                                if (nextDate !== null) {
                                    setDisplayDate(
                                        new Date(nextDate.setHours(0, 0, 0, 0)),
                                    );
                                }
                            }}
                        >
                            <Text style={styles.buttonText}>
                                {t('during_event_button')}
                            </Text>
                        </Pressable>
                    </View>
                </View>
            );
        }
    } else {
        content = (
            <ScrollView
                style={{
                    paddingHorizontal: 10,
                    flex: 1,
                }}
                scrollEventThrottle={1}
                refreshControl={
                    <RefreshControl
                        colors={[getColor('primary')]}
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                    />
                }
                onScroll={({ nativeEvent }) => {
                    if (isCloseToBottom(nativeEvent)) {
                    }
                }}
                onMomentumScrollEnd={({ nativeEvent }) => {
                    if (isCloseToBottom(nativeEvent)) {
                    }
                }}
            >
                {subEventList.map((subEvent, index) => {
                    if (subEvent instanceof Date) {
                        const time = subEvent.getTime();

                        let numnDay = 0;
                        let i = 0;
                        while (i < subEventList.length && i <= index) {
                            if (subEventList[i] instanceof Date) {
                                numnDay++;
                            }
                            i++;
                        }

                        let count = 0;
                        i = index + 1;
                        let j = 0;
                        while (j < subEventList.length) {
                            if (subEventList[j] instanceof Date) {
                                count++;
                            }
                            j++;
                        }

                        // const val = SPECIAL_TEXT[numnDay - 1];*/

                        return (
                            <View
                                key={index}
                                onLayout={(e) => {
                                    const layout = e.nativeEvent.layout;
                                    setDateCords((cords) => ({
                                        ...cords,
                                        [time]: layout.y,
                                    }));
                                    setCountRender(countRender + 1);
                                }}
                                style={{
                                    backgroundColor:
                                        subEvent.setHours(0, 0, 0, 0) ===
                                        new Date().setHours(0, 0, 0, 0)
                                            ? getColor('secondary')
                                            : getColor('primary'),
                                    borderRadius: 20,
                                    marginBottom: 20,
                                    marginTop: 10,
                                    padding: 10,
                                }}
                            >
                                {/*<Text
                                    style={{
                                        fontFamily: FONT_MEDIUM,
                                        textAlign: 'center',
                                        fontSize: 20,
                                        color: 'white',
                                    }}
                                >
                                    {val.title}
                                </Text>*/}
                                {/*<Text
                                    style={{
                                        fontFamily: FONT_HEAVY,
                                        textAlign: 'center',
                                        fontSize: 25,
                                        color: 'white',
                                    }}
                                >
                                    {val.subtitle}
                                </Text>*/}
                                <Text
                                    style={{
                                        fontFamily: FONT_HEAVY,
                                        textAlign: 'center',
                                        fontSize: 25,
                                        color: 'white',
                                    }}
                                >
                                    {subEvent.toLocaleDateString(
                                        t('date') ?? 'en',
                                    )}
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: FONT_MEDIUM,
                                        textAlign: 'center',
                                        color: 'white',
                                    }}
                                >
                                    {(t('one_day') ?? '').toUpperCase() +
                                        ' ' +
                                        numnDay}
                                </Text>
                            </View>
                        );
                    } else {
                        return (
                            <ScheduleEvent
                                navigation={props.navigation}
                                key={subEvent.getJTOIdentifier()}
                                event={subEvent}
                                last={
                                    index === subEventList.length - 1 ||
                                    subEventList[index + 1] instanceof Date
                                }
                            />
                        );
                    }
                })}
            </ScrollView>
        );
    }

    const isToday =
        displayDate.getDate() === new Date().getDate() &&
        displayDate.getMonth() === new Date().getMonth() &&
        displayDate.getFullYear() === new Date().getFullYear();
    let title = '';
    if (isToday) {
        title = t('today');
    } else {
        title = displayDate
            .toLocaleDateString(t('date') ?? '', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            })
            .replace(/\s[a-z]/, (c) => c.toUpperCase());
    }

    const user = User._instance;

    return (
        <View
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: getColor('background'),
            }}
        >
            <Header
                line={1}
                title={title}
                navigation={props.navigation}
                surtitle={displayDate.toLocaleDateString(t('date') ?? '', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                })}
                after={
                    <View
                        style={{
                            width: '100%',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            paddingBottom: 10,
                        }}
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: 'row',
                                    backgroundColor: 'white',
                                    borderRadius: 15,
                                    elevation: 5,
                                    marginTop: 5,
                                    alignItems: 'center',
                                }}
                            >
                                <Pressable
                                    onPress={() => {
                                        setPlanningType('my');
                                        setDisplayDate(new Date());
                                    }}
                                    style={{
                                        borderRadius: 13,
                                        backgroundColor:
                                            planningType === 'my'
                                                ? getColor('primary')
                                                : 'white',
                                        margin: 2.5,
                                    }}
                                >
                                    <GlobeAltIcon
                                        style={{
                                            marginHorizontal: 10,
                                            marginVertical: 5,
                                        }}
                                        color={
                                            planningType === 'my'
                                                ? 'white'
                                                : getColor('primary')
                                        }
                                    />
                                </Pressable>
                                <Pressable
                                    onPress={() => {
                                        setPlanningType('calendar');
                                    }}
                                    style={{
                                        borderRadius: 13,
                                        backgroundColor:
                                            planningType === 'calendar'
                                                ? getColor('primary')
                                                : 'white',
                                        margin: 2.5,
                                    }}
                                >
                                    <CalendarDaysIcon
                                        style={{
                                            marginHorizontal: 10,
                                            marginVertical: 5,
                                        }}
                                        color={
                                            planningType === 'calendar'
                                                ? 'white'
                                                : getColor('primary')
                                        }
                                    />
                                </Pressable>
                            </View>

                            {user.nothing === false && user.isMe() && (
                                <CartoucheButton
                                    color='#ffffff'
                                    fontColor={getColor('primary')}
                                    onPress={() => {
                                        props.navigation.navigate(
                                            'ReservationScreen',
                                            {
                                                user,
                                            },
                                        );
                                    }}
                                    height={40}
                                    title={t('my_reservation')}
                                />
                            )}
                        </View>
                        {planningType === 'calendar' && (
                            <View
                                style={{
                                    paddingTop: 10,
                                    marginBottom: -10,
                                    marginHorizontal: -15,
                                    display: 'flex',
                                    width: width(),
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Pressable
                                    style={{
                                        flex: 1,
                                        paddingHorizontal: 0,
                                    }}
                                    onPress={() => {
                                        setDisplayDate(
                                            new Date(
                                                displayDate.setDate(
                                                    displayDate.getDate() - 7,
                                                ),
                                            ),
                                        );
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            paddingTop: 7,
                                        }}
                                    >
                                        <ChevronLeftIcon color={'white'} />
                                    </View>
                                </Pressable>
                                {week.map((jour, key) => {
                                    return (
                                        <Pressable
                                            style={{
                                                flex: 1,
                                                position: 'relative',
                                            }}
                                            key={jour.getTime()}
                                            onPress={() => {
                                                setDisplayDate(new Date(jour));
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontFamily:
                                                            displayDate.getDate() ===
                                                            jour.getDate()
                                                                ? FONT_BOLD
                                                                : FONT_MEDIUM,
                                                        color:
                                                            displayDate.getDate() ===
                                                            jour.getDate()
                                                                ? getColor(
                                                                      'secondary',
                                                                  )
                                                                : 'white',
                                                    }}
                                                >
                                                    {jour
                                                        .toLocaleDateString(
                                                            t('date') ?? '',
                                                            {
                                                                weekday:
                                                                    'short',
                                                            },
                                                        )
                                                        .replace(/\w/, (c) =>
                                                            c.toUpperCase(),
                                                        )
                                                        .match(/^[a-zA-Z]{3}/)}
                                                </Text>
                                                <Text
                                                    style={{
                                                        color:
                                                            displayDate.getDate() ===
                                                            jour.getDate()
                                                                ? getColor(
                                                                      'secondary',
                                                                  )
                                                                : 'white',
                                                        fontFamily:
                                                            displayDate.getDate() ===
                                                            jour.getDate()
                                                                ? FONT_BOLD
                                                                : FONT_MEDIUM,
                                                    }}
                                                >
                                                    {jour.getDate()}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 20,
                                                        color:
                                                            displayDate.getDate() ===
                                                                jour.getDate() ||
                                                            getSubEventByDate(
                                                                jour,
                                                            ).length !== 0
                                                                ? getColor(
                                                                      'secondary',
                                                                  )
                                                                : 'white',
                                                        fontFamily:
                                                            displayDate.getDate() ===
                                                            jour.getDate()
                                                                ? FONT_BOLD
                                                                : FONT_MEDIUM,
                                                    }}
                                                >
                                                    °
                                                </Text>
                                            </View>
                                        </Pressable>
                                    );
                                }, this)}
                                <Pressable
                                    style={{
                                        flex: 1,
                                    }}
                                    onPress={() => {
                                        setDisplayDate(
                                            new Date(
                                                displayDate.setDate(
                                                    displayDate.getDate() + 7,
                                                ),
                                            ),
                                        );
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            paddingTop: 7,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ChevronRightIcon color={'white'} />
                                    </View>
                                </Pressable>
                            </View>
                        )}
                    </View>
                }
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                {content}
            </Header>
        </View>
    );
};
