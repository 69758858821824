import { View } from 'react-native';
import React from 'react';
//import Pastille from '../svg/Pastille';
import { getColor } from '../utils/theme';
import Pastille from '../svg/Pastille';

export interface IPastilleViewProps {
    color: string;
    icon: JSX.Element;
    size: number;
}

export const PastilleView = (props: IPastilleViewProps) => {
    const { color, size, icon } = props;
    return (
        <View
            style={{
                position: 'relative',
                height: size,
                width: size,
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <View
                style={{
                    position: 'absolute',
                    height: size,
                    width: size,
                    backgroundColor: color,
                    borderRadius: size,
                }}
            ></View>
            {/*<Pastille
                fill={color}
                style={{
                    position: 'absolute',
                }}
                height={size}
                width={size}
            />*/}
            <View
                style={{
                    position: 'absolute',
                    zIndex: 1000,
                    height: size,
                    width: size,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {icon}
            </View>
        </View>
    );
};
