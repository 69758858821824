import { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import Carousel from 'react-multi-carousel';
import { FONT_BOLD, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import Event from '../object/Event';
import { t } from 'i18next';
import React from 'react';
import { AdvertItem } from './AdvertItem';

import 'react-multi-carousel/lib/styles.css';

interface IAdvertSectionProps {
    event: Event;
    navigation: any;
}

export const AdvertSection = (props: IAdvertSectionProps) => {
    const [activeSlide, setActiveSlide] = useState(0);

    const advertList = props.event.advertList;
    const listLength = advertList.getList().length;

    useEffect(() => {
        if (listLength > 1) {
            const interval = setInterval(() => {
                const nextSlide = (activeSlide + 1) % listLength;
                setActiveSlide(nextSlide);
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [activeSlide, listLength]);

    return advertList.getList().length > 0 ? (
        <View
            style={{
                marginBottom: 20,
            }}
        >
            <Carousel
                responsive={{
                    mobile: {
                        breakpoint: { max: 3000, min: 0 },
                        items: 1,
                        slidesToSlide: 1, // optional, default to 1.
                    },
                }}
                swipeable
                showDots
                infinite
                autoPlay
                autoPlaySpeed={3000}
                customTransition='all .5'
                dotListClass='custom-dot-list-style'
                transitionDuration={500}
                customLeftArrow={<></>}
                customRightArrow={<></>}
                renderDotsOutside
            >
                {advertList.getList().map((item, index) => (
                    <AdvertItem
                        key={index}
                        item={item}
                        navigation={props.navigation}
                    />
                ))}
            </Carousel>
        </View>
    ) : (
        <View></View>
    );
};
