import { View, Text, Pressable, Linking, Image } from 'react-native';
import React from 'react';
import Event from '../object/Event';
import { FONT_BOLD, FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { IModalProps } from './IModalProps';
//import LottieView from 'lottie-react-native';
import { height, hexToRgb, width } from '../utils/utils';
import { useTranslation } from 'react-i18next';
import User from '../object/User';
import { styles } from '../utils/styles';
import { planetAnim } from '../anim/planet';
import { welcomeAnim } from '../anim/welcome';
import { Header } from '../component/Header';
//import { ScrollView } from 'react-native-gesture-handler';
import { SERVER_URL, SITE_URL } from '../utils/constants';
import { CartoucheButton } from '../component/CartoucheButton';
import Logo from '../svg/Logo';
import NextLogo from '../svg/NextLogo';
import useJTOState from '../utils/jto/useJTOState';
import Lottie from 'lottie-react';

export const WelcomeModal = (props: IModalProps) => {
    const { t } = useTranslation();

    const user = useJTOState(User._instance);

    const event = useJTOState(User._instance.getEvent()!);

    return (
        <View
            style={[
                styles.page,
                {
                    height: '100%',
                },
            ]}
        >
            <Header
                modal
                full
                onPresslogo={() => {
                    props.navigation.popToTop();
                    props.navigation.navigate('home');
                }}
                navigation={props.navigation}
                backButton={true}
                line={(event.name.get() ?? '').length <= 25 ? 1.01 : 3}
                title={event.name.get() ?? ''}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        flex: 1,
                        marginTop: -10,
                    }}
                >
                    <View style={{}}>
                        <View
                            style={{
                                display: 'flex',
                                marginHorizontal: 20,
                                marginBottom: 10,
                                height: height() * 0.2,
                            }}
                        >
                            {/*<LottieView
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    height: height() * 0.2,
                                    width: width() - 40,
                                }}
                                source={welcomeAnim()}
                                autoPlay
                                loop={true}
                            />*/}
                            {/*<Lottie
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    height: height() * 0.2,
                                    width: width() - 40,
                                }}
                                autoPlay
                                loop={true}
                                animationData={welcomeAnim()}
                            />*/}
                            <Image
                                source={{
                                    uri: SITE_URL + '/bienvenue.gif',
                                }}
                                style={{
                                    height: height() * 0.2,
                                    width: width() - 40,
                                    resizeMode: 'contain',
                                }}
                            />
                        </View>
                        <View
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {
                                <Image
                                    style={{
                                        height: 200,
                                        width: 200,
                                        borderRadius: 200,
                                        backgroundColor: 'white',
                                        marginBottom: 20,
                                    }}
                                    source={{
                                        uri:
                                            SERVER_URL +
                                            '/image/' +
                                            (User._instance
                                                .getEvent()
                                                ?.logo.get() ?? ''),
                                    }}
                                />
                            }
                            <View
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginTop: 20,
                                    marginBottom: 20,
                                }}
                            >
                                {/*<NextLogo
                                    fill={getColor('text')}
                                    width={120}
                                    height={70}
                                />*/}
                                <Text
                                    numberOfLines={1}
                                    adjustsFontSizeToFit
                                    style={{
                                        fontSize: 35,
                                        paddingHorizontal: 20,
                                        fontFamily: FONT_BOLD,
                                        color: getColor('text'),
                                    }}
                                >
                                    {event.name.get()}
                                </Text>
                            </View>
                            {(event.description.get() ?? '') !== '' && (
                                <Text
                                    adjustsFontSizeToFit
                                    style={{
                                        fontSize: 16,
                                        paddingHorizontal: 20,
                                        marginBottom: 40,
                                        color: getColor('text'),
                                        textAlign: 'center',
                                        fontFamily: FONT_REGULAR,
                                    }}
                                >
                                    {event.description.get()}
                                </Text>
                            )}

                            <CartoucheButton
                                onPress={() => {
                                    props.navigation.goBack();
                                }}
                                color={getColor('primary')}
                                title={t('enter')}
                                height={80}
                            />
                        </View>
                    </View>
                </View>
            </Header>
            {/*<LottieView
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}
                source={planetAnim}
                autoPlay
                loop={true}
            />*/}
            {/*nextOrCurrentEvent.length > 0 && (
                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderRadius: 20,
                        backgroundColor: 'white',
                    }}>
                    <Header
                        modal={true}
                        navigation={props.navigation}
                        backButton={true}
                        line={(event.name.get() ?? '').length <= 25 ? 1.01 : 3}
                        after={
                            <View
                                style={{
                                    height: 10,
                                }}></View>
                        }
                        title={event.name.get() ?? ''}
                        logo={event.logo.get() ?? ''}
                        full
                        onPresslogo={() => {
                            props.navigation.goBack();
                        }}>
                        <View
                            style={{
                                flex: 1,
                                marginTop: -HEADER_BONUS_HEIGHT,
                            }}>
                            <View
                                style={{
                                    paddingTop:
                                        HEADER_BONUS_HEIGHT +
                                        HEADER_BONUS_HEIGHT,
                                }}>
                                {((User._instance.getEvent()?.wordName.get() ??
                                    '') !== '' ||
                                    (User._instance.getEvent()?.word.get() ??
                                        '') !== '') && (
                                    <Text
                                        style={{
                                            fontFamily: FONT_BOLD,
                                            color: getColor('text'),
                                            fontSize: 30,
                                            marginTop: 25,
                                            marginBottom: 15,
                                            textAlign: 'center',
                                        }}>
                                        {(User._instance
                                            .getEvent()
                                            ?.wordName.get() ?? '') === ''
                                            ? t('word_dirigeant')
                                            : User._instance
                                                  .getEvent()
                                                  ?.wordName.get()}
                                    </Text>
                                )}
                                {User._instance.getEvent()?.word.get() !== '' &&
                                    User._instance.getEvent()?.word.get() !==
                                        undefined &&
                                    User._instance.getEvent()?.word.get() !==
                                        null && (
                                        <View
                                            style={{
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                marginHorizontal: 20,
                                                width: width() - 40,
                                            }}>
                                            <View
                                                style={{
                                                    borderRadius: 20,
                                                    backgroundColor:
                                                        getColor('card'),
                                                    width: width() - 40,
                                                }}>
                                                <Text
                                                    style={{
                                                        paddingHorizontal: 13,
                                                        paddingVertical: 10,
                                                        color: getColor('text'),
                                                        fontFamily:
                                                            FONT_REGULAR,
                                                    }}>
                                                    {User._instance
                                                        .getEvent()
                                                        ?.word.get()}
                                                </Text>
                                            </View>
                                        </View>
                                    )}
                                <Text
                                    style={{
                                        fontFamily: FONT_BOLD,
                                        color: getColor('text'),
                                        fontSize: 30,
                                        marginVertical: 25,
                                        textAlign: 'center',
                                    }}>
                                    {nextOrCurrentEvent[0].isNow()
                                        ? t('now')
                                        : t('comming_next')}
                                </Text>
                                <CountDown
                                    until={Math.max(
                                        Math.round(
                                            nextOrCurrentEvent[0].getDiff() /
                                                1000,
                                        ),
                                        0,
                                    )}
                                    size={30}
                                    onFinish={() => {
                                        // Nothing to do
                                    }}
                                    digitStyle={{
                                        backgroundColor: getColor('primary'),
                                        borderRadius: 20,
                                    }}
                                    digitTxtStyle={{
                                        color: 'white',
                                        fontFamily: FONT_BOLD,
                                    }}
                                    timeLabelStyle={{
                                        color: getColor('text'),
                                        fontFamily: FONT_MEDIUM,
                                    }}
                                    separatorStyle={{
                                        color: getColor('primary'),
                                        marginBottom: 35,
                                    }}
                                    showSeparator
                                    timeToShow={['M', 'S', 'D', 'H']}
                                    timeLabels={{
                                        m: t('minute') ?? '',
                                        h: t('heure') ?? '',
                                        s: t('second') ?? '',
                                        d: t('day') ?? '',
                                    }}
                                />
                                <View
                                    style={{
                                        paddingHorizontal: 20,
                                        paddingVertical: 20,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flex: 1,
                                        flexDirection: 'column',
                                    }}>
                                    <ScheduleEvent
                                        canNow={false}
                                        navigation={props.navigation}
                                        event={nextOrCurrentEvent[0]}
                                        key={nextOrCurrentEvent[0].getJTOIdentifier()}
                                        last={true}
                                    />
                                </View>
                                <View
                                    style={{
                                        paddingHorizontal: 20,
                                        paddingVertical: 20,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flex: 1,
                                        flexDirection: 'column',
                                    }}>
                                    <CartoucheButton
                                        onPress={() => {
                                            props.navigation.goBack();
                                        }}
                                        color={getColor('secondary')}
                                        title={t('close')}
                                        height={80}
                                    />
                                </View>
                            </View>
                        </View>
                    </Header>
                </View>
                                )*/}
        </View>
    );
};
/*
<View
    style={{
        display: 'flex',
        marginTop: -20,
        height: height() * 0.45,
    }}>
    <LottieView source={planetAnim} autoPlay loop={true} />
</View>;
*/
