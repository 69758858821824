import { useTranslation } from 'react-i18next';
import { Pressable, Text, View } from 'react-native';
import React from 'react';
import { Badge } from '../object/Badge';
import User from '../object/User';
import { getMarginLeft, getMarginRight, GOLD_COLOR } from '../pages/BadgePage';
import { FONT_BOLD, FONT_MEDIUM, getColor } from '../utils/theme';
import { width } from '../utils/utils';
import GenericAchivement from './achivement/GenericAchivement';

interface ITinyBadgeProps {
    badge: Badge;
    index: number;
    navigation: any;
}

export const TinyBadge = (props: ITinyBadgeProps) => {
    const { badge, index, navigation } = props;
    const widthFinal = width() - 80;
    const widthFinalProgress = (width() - 80) / 3 - 20;
    const { t } = useTranslation();
    return (
        <Pressable
            onPress={() => {
                navigation?.navigate('BigBadgePage', {
                    badge,
                });
            }}
            style={{
                backgroundColor: 'white',
                borderRadius: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: widthFinal / 3,
                marginLeft: getMarginLeft(index) === 20 ? 10 : 0,
                marginRight: getMarginRight(index) === 20 ? 10 : 0,
            }}
        >
            <GenericAchivement
                owned={badge.hasBadge(User._instance)}
                height={widthFinal / 3}
                width={widthFinal / 3}
                level={badge.level.get() ?? 0}
                count={badge.getBestCount()}
                icon={badge.getBadgeIcon()}
            />
            <View>
                <View
                    style={{
                        height: 5,
                        width: widthFinalProgress,
                        position: 'relative',
                        borderRadius: 20,
                        backgroundColor: getColor('card'),
                    }}
                >
                    {
                        <View
                            style={{
                                height: 5,
                                left: 0,
                                top: 0,
                                borderRadius: 20,
                                backgroundColor:
                                    badge?.getColor() ?? getColor('border'),
                                width: badge
                                    ? Math.min(
                                          (widthFinalProgress *
                                              badge.getUserCount(
                                                  User._instance,
                                              )) /
                                              (badge.getBestCount() ?? 1),
                                          widthFinalProgress,
                                      )
                                    : widthFinalProgress,
                            }}
                        ></View>
                    }
                    {
                        <View
                            style={{
                                height: 10,
                                position: 'absolute',
                                width: 10,
                                borderRadius: 10,
                                left: 0,
                                top: -2.5,
                                backgroundColor:
                                    badge?.getColor() ?? getColor('border'),
                            }}
                        ></View>
                    }
                    {badge && (
                        <View
                            style={{
                                height: 10,
                                width: 10,
                                position: 'absolute',
                                borderRadius: 10,
                                right: 0,
                                top: -2.5,
                                backgroundColor: badge.getColor(),
                            }}
                        ></View>
                    )}
                    {!badge && (
                        <View
                            style={{
                                height: 10,
                                width: 10,
                                position: 'absolute',
                                borderRadius: 10,
                                right: 0,
                                top: -2.5,
                                backgroundColor: GOLD_COLOR,
                            }}
                        ></View>
                    )}
                </View>
            </View>
            <View
                style={{
                    width: widthFinalProgress,
                    display: 'flex',
                    position: 'relative',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Text
                    style={{
                        fontSize: 20,
                        textAlign: 'center',
                        fontFamily: FONT_BOLD,
                        color: badge?.getColor() ?? getColor('border'),
                    }}
                >
                    {0}
                </Text>
                <Text
                    style={{
                        fontSize: 20,
                        textAlign: 'center',
                        fontFamily: FONT_BOLD,
                        color: badge?.getColor() ?? GOLD_COLOR,
                    }}
                >
                    {badge?.getBestCount() ?? ''}
                </Text>
            </View>
            {!badge.hasBadge(User._instance) && (
                <View
                    style={{
                        display: 'flex',
                        width: widthFinalProgress,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Text
                        style={{
                            textAlign: 'center',
                            fontFamily: FONT_MEDIUM,
                            fontSize: 12,
                            marginBottom: 10,
                            color: getColor('text'),
                        }}
                    >
                        {badge.getRemaining(User._instance) === 1
                            ? t(badge.getBadgeKey() + '_remaining', {
                                  nb: badge.getRemaining(User._instance),
                              })
                            : t(badge.getBadgeKey() + '_remaining_plural', {
                                  nb: badge.getRemaining(User._instance),
                              })}
                    </Text>
                </View>
            )}
        </Pressable>
    );
};
