export const experienceAnim = {
    v: '5.6.6',
    fr: 60,
    ip: 0,
    op: 261,
    w: 800,
    h: 800,
    nm: 'Comp 8',
    ddd: 0,
    assets: [
        {
            id: 'comp_0',
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'tube Outlines',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [304.25, 207, 0], ix: 2 },
                        a: { a: 0, k: [400, 400, 0], ix: 1 },
                        s: { a: 0, k: [41, 41, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-5.154, 0],
                                                [0.223, 4.965],
                                                [5.154, 0],
                                                [-0.222, -4.965],
                                            ],
                                            o: [
                                                [4.809, 0],
                                                [-0.223, -4.981],
                                                [-4.809, 0],
                                                [0.223, 4.981],
                                            ],
                                            v: [
                                                [0, 9.194],
                                                [9.194, 0],
                                                [0, -9.194],
                                                [-9.194, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.607999973671, 0.395999983245,
                                            0.219999994016, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [337.004, 379.828], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-3.99, 0],
                                                [0.172, 3.844],
                                                [3.99, 0],
                                                [-0.172, -3.844],
                                            ],
                                            o: [
                                                [3.724, 0],
                                                [-0.173, -3.857],
                                                [-3.723, 0],
                                                [0.173, 3.856],
                                            ],
                                            v: [
                                                [0, 7.118],
                                                [7.118, 0],
                                                [0, -7.118],
                                                [-7.118, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.607999973671, 0.395999983245,
                                            0.219999994016, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [445.151, 426.638], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 2',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-3.491, 0],
                                                [0.151, 3.363],
                                                [3.492, 0],
                                                [-0.15, -3.364],
                                            ],
                                            o: [
                                                [3.258, 0],
                                                [-0.151, -3.375],
                                                [-3.257, 0],
                                                [0.151, 3.374],
                                            ],
                                            v: [
                                                [0, 6.228],
                                                [6.228, 0],
                                                [0, -6.228],
                                                [-6.229, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.607999973671, 0.395999983245,
                                            0.219999994016, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [396.494, 352.776], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 3',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 3,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-3.492, 0],
                                                [0.15, 3.364],
                                                [3.492, 0],
                                                [-0.151, -3.363],
                                            ],
                                            o: [
                                                [3.258, 0],
                                                [-0.151, -3.374],
                                                [-3.258, 0],
                                                [0.151, 3.374],
                                            ],
                                            v: [
                                                [0.001, 6.228],
                                                [6.229, 0],
                                                [0.001, -6.228],
                                                [-6.228, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.607999973671, 0.395999983245,
                                            0.219999994016, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [359.388, 418.936], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 4',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 4,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-3.492, 0],
                                                [0.151, 3.363],
                                                [3.492, 0],
                                                [-0.151, -3.364],
                                            ],
                                            o: [
                                                [3.258, 0],
                                                [-0.151, -3.375],
                                                [-3.258, 0],
                                                [0.151, 3.374],
                                            ],
                                            v: [
                                                [0, 6.228],
                                                [6.228, 0],
                                                [0, -6.228],
                                                [-6.228, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.607999973671, 0.395999983245,
                                            0.219999994016, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [390.193, 395.483], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 5',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 5,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-3.492, 0],
                                                [0.15, 3.363],
                                                [3.491, 0],
                                                [-0.151, -3.363],
                                            ],
                                            o: [
                                                [3.257, 0],
                                                [-0.151, -3.375],
                                                [-3.258, 0],
                                                [0.151, 3.374],
                                            ],
                                            v: [
                                                [0.001, 6.228],
                                                [6.229, 0],
                                                [0.001, -6.228],
                                                [-6.228, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.607999973671, 0.395999983245,
                                            0.219999994016, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [356.237, 359.427], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 6',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 6,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [7.206, 0],
                                                [0, 0],
                                                [1.746, 6.967],
                                                [0, 0],
                                                [-16.757, 0],
                                                [0, 0],
                                                [3.996, -16.229],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-7.183, 0],
                                                [0, 0],
                                                [-4.072, -16.255],
                                                [0, 0],
                                                [16.713, 0],
                                                [0, 0],
                                                [-1.723, 6.997],
                                            ],
                                            v: [
                                                [55.75, 69.25],
                                                [-55.293, 69.25],
                                                [-70.483, 57.396],
                                                [-94.194, -37.244],
                                                [-69.217, -69.25],
                                                [69.268, -69.25],
                                                [94.271, -37.345],
                                                [70.954, 57.335],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.172549019608, 0.172549019608,
                                            0.172549019608, 1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 15, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 10,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.666999966491, 0.442999985639,
                                            0.238999998803, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [400.371, 387.75], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 7',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 7,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 300,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Layer 5',
                    td: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [305, 259, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [23, 23, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 10.032],
                                                [0, 0],
                                                [-4.955, 8.722],
                                                [0, 0],
                                                [18.958, 34.403],
                                                [39.535, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [19.081, -34.626],
                                                [-20.729, -33.3],
                                                [0, 0],
                                                [0, -10.032],
                                                [0, 0],
                                                [2.066, -6.036],
                                                [0, 0],
                                                [0, 0],
                                                [-18.958, -34.404],
                                                [-39.535, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-19.081, 34.626],
                                                [20.729, 33.3],
                                            ],
                                            o: [
                                                [-4.955, -8.722],
                                                [0, 0],
                                                [0, -10.032],
                                                [0, 0],
                                                [20.729, -33.3],
                                                [-19.081, -34.626],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-39.535, 0],
                                                [-18.958, 34.403],
                                                [0, 0],
                                                [4.955, 8.722],
                                                [0, 0],
                                                [0, 6.414],
                                                [0, 0],
                                                [0, 0],
                                                [-20.729, 33.3],
                                                [19.081, 34.626],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [39.535, 0],
                                                [18.958, -34.404],
                                                [0, 0],
                                            ],
                                            v: [
                                                [125.073, 29.407],
                                                [117.5, 0.741],
                                                [117.5, -0.741],
                                                [125.073, -29.407],
                                                [442.746, -588.674],
                                                [445.594, -696.702],
                                                [351.983, -752],
                                                [20.5, -752],
                                                [-20.5, -752],
                                                [-351.983, -752],
                                                [-445.594, -696.702],
                                                [-442.746, -588.674],
                                                [-125.073, -29.407],
                                                [-117.5, -0.741],
                                                [-117.5, 0.741],
                                                [-120.642, 19.553],
                                                [-128.71, 35.81],
                                                [-442.746, 588.674],
                                                [-445.594, 696.702],
                                                [-351.983, 752],
                                                [-20.5, 752],
                                                [20.5, 752],
                                                [351.983, 752],
                                                [445.594, 696.702],
                                                [442.746, 588.674],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.156862750649, 0.698039233685,
                                            0.427450984716, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 300,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Layer 3',
                    tt: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0.833, y: 0.834 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 0,
                                    s: [473, 434, 0],
                                    to: [-62.629, -1, 0],
                                    ti: [54.028, 0, 0],
                                },
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 124,
                                    s: [344.469, 401, 0],
                                    to: [-58.172, 0, 0],
                                    ti: [65.288, 0, 0],
                                },
                                { t: 260, s: [203.5, 435, 0] },
                            ],
                            ix: 2,
                        },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [66, 66, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [111.139, 55.943],
                                                [33.5, 0],
                                                [6.039, -3.04],
                                                [12.774, -4.994],
                                                [10.288, -2.376],
                                                [0.247, -0.057],
                                                [33.077, 7.622],
                                                [0.243, 0.056],
                                                [9.833, 3.844],
                                                [14.515, 7.306],
                                                [33.5, 0],
                                                [0.5, -0.007],
                                                [0.499, 0],
                                                [6.039, -3.04],
                                                [12.774, -4.994],
                                                [10.288, -2.376],
                                                [0.247, -0.057],
                                                [33.077, 7.622],
                                                [0.243, 0.056],
                                                [9.834, 3.844],
                                                [14.515, 7.306],
                                                [35.5, 0],
                                                [6.039, -3.04],
                                                [15.896, 27.668],
                                                [0, 0],
                                                [-59.823, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 59.823],
                                                [0, 0],
                                            ],
                                            o: [
                                                [-6.039, -3.04],
                                                [-35.5, 0],
                                                [-14.515, 7.306],
                                                [-9.834, 3.844],
                                                [-0.243, 0.056],
                                                [-33.077, 7.622],
                                                [-0.247, -0.057],
                                                [-10.288, -2.376],
                                                [-12.774, -4.994],
                                                [-6.039, -3.04],
                                                [-0.499, 0],
                                                [-0.5, -0.007],
                                                [-33.5, 0],
                                                [-14.515, 7.306],
                                                [-9.834, 3.844],
                                                [-0.243, 0.056],
                                                [-33.077, 7.622],
                                                [-0.247, -0.057],
                                                [-10.288, -2.376],
                                                [-12.774, -4.994],
                                                [-6.039, -3.04],
                                                [-33.5, 0],
                                                [-111.139, 55.943],
                                                [0, 0],
                                                [0, 59.823],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [59.823, 0],
                                                [0, 0],
                                                [-15.896, 27.668],
                                            ],
                                            v: [
                                                [485.333, -161.013],
                                                [405.5, -183.098],
                                                [325.667, -161.013],
                                                [284.742, -142.63],
                                                [254.505, -133.352],
                                                [253.771, -133.183],
                                                [153.229, -133.183],
                                                [152.495, -133.352],
                                                [122.258, -142.63],
                                                [81.333, -161.013],
                                                [1.5, -183.098],
                                                [0, -183.074],
                                                [-1.5, -183.098],
                                                [-81.333, -161.013],
                                                [-122.258, -142.63],
                                                [-152.495, -133.352],
                                                [-153.229, -133.183],
                                                [-253.771, -133.183],
                                                [-254.505, -133.352],
                                                [-284.742, -142.63],
                                                [-325.667, -161.013],
                                                [-405.5, -183.098],
                                                [-485.333, -161.013],
                                                [-672, -148.427],
                                                [-672, 74.779],
                                                [-563.68, 183.098],
                                                [-268, 183.098],
                                                [-139, 183.098],
                                                [0, 183.098],
                                                [139, 183.098],
                                                [268, 183.098],
                                                [563.68, 183.098],
                                                [672, 74.779],
                                                [672, -148.427],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 100, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 3,
                                        k: {
                                            a: 0,
                                            k: [
                                                0, 0.9921568627450981,
                                                0.7764705882352941,
                                                0.7098039215686275, 0.366, 1,
                                                0.5607843137254902,
                                                0.43137254901960786, 1,
                                                0.9921568627450981,
                                                0.3176470588235294,
                                                0.11764705882352941,
                                            ],
                                            ix: 9,
                                        },
                                    },
                                    s: { a: 0, k: [-1, -182.902], ix: 5 },
                                    e: { a: 0, k: [-1, 141.689], ix: 6 },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 17], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 300,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Layer 6',
                    td: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [305, 259, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [23, 23, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 10.032],
                                                [0, 0],
                                                [-4.955, 8.722],
                                                [0, 0],
                                                [18.958, 34.403],
                                                [39.535, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [19.081, -34.626],
                                                [-20.729, -33.3],
                                                [0, 0],
                                                [0, -10.032],
                                                [0, 0],
                                                [2.066, -6.036],
                                                [0, 0],
                                                [0, 0],
                                                [-18.958, -34.404],
                                                [-39.535, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-19.081, 34.626],
                                                [20.729, 33.3],
                                            ],
                                            o: [
                                                [-4.955, -8.722],
                                                [0, 0],
                                                [0, -10.032],
                                                [0, 0],
                                                [20.729, -33.3],
                                                [-19.081, -34.626],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-39.535, 0],
                                                [-18.958, 34.403],
                                                [0, 0],
                                                [4.955, 8.722],
                                                [0, 0],
                                                [0, 6.414],
                                                [0, 0],
                                                [0, 0],
                                                [-20.729, 33.3],
                                                [19.081, 34.626],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [39.535, 0],
                                                [18.958, -34.404],
                                                [0, 0],
                                            ],
                                            v: [
                                                [125.073, 29.407],
                                                [117.5, 0.741],
                                                [117.5, -0.741],
                                                [125.073, -29.407],
                                                [442.746, -588.674],
                                                [445.594, -696.702],
                                                [351.983, -752],
                                                [20.5, -752],
                                                [-20.5, -752],
                                                [-351.983, -752],
                                                [-445.594, -696.702],
                                                [-442.746, -588.674],
                                                [-125.073, -29.407],
                                                [-117.5, -0.741],
                                                [-117.5, 0.741],
                                                [-120.642, 19.553],
                                                [-128.71, 35.81],
                                                [-442.746, 588.674],
                                                [-445.594, 696.702],
                                                [-351.983, 752],
                                                [-20.5, 752],
                                                [20.5, 752],
                                                [351.983, 752],
                                                [445.594, 696.702],
                                                [442.746, 588.674],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.156862750649, 0.698039233685,
                                            0.427450984716, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 300,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Layer 4',
                    tt: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0.833, y: 0.837 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 0,
                                    s: [561, 411, 0],
                                    to: [-1.421, 0, 0],
                                    ti: [127.584, 0, 0],
                                },
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.17 },
                                    t: 124,
                                    s: [302.231, 426, 0],
                                    to: [-133.295, 0, 0],
                                    ti: [6.472, 0, 0],
                                },
                                { t: 260, s: [31, 411, 0] },
                            ],
                            ix: 2,
                        },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [66, 66, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [111.139, 55.943],
                                                [33.5, 0],
                                                [6.039, -3.04],
                                                [12.774, -4.994],
                                                [10.288, -2.376],
                                                [0.247, -0.057],
                                                [33.077, 7.622],
                                                [0.243, 0.056],
                                                [9.833, 3.844],
                                                [14.515, 7.306],
                                                [33.5, 0],
                                                [0.5, -0.007],
                                                [0.499, 0],
                                                [6.039, -3.04],
                                                [12.774, -4.994],
                                                [10.288, -2.376],
                                                [0.247, -0.057],
                                                [33.077, 7.622],
                                                [0.243, 0.056],
                                                [9.834, 3.844],
                                                [14.515, 7.306],
                                                [35.5, 0],
                                                [6.039, -3.04],
                                                [15.896, 27.668],
                                                [0, 0],
                                                [-59.823, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 59.823],
                                                [0, 0],
                                            ],
                                            o: [
                                                [-6.039, -3.04],
                                                [-35.5, 0],
                                                [-14.515, 7.306],
                                                [-9.834, 3.844],
                                                [-0.243, 0.056],
                                                [-33.077, 7.622],
                                                [-0.247, -0.057],
                                                [-10.288, -2.376],
                                                [-12.774, -4.994],
                                                [-6.039, -3.04],
                                                [-0.499, 0],
                                                [-0.5, -0.007],
                                                [-33.5, 0],
                                                [-14.515, 7.306],
                                                [-9.834, 3.844],
                                                [-0.243, 0.056],
                                                [-33.077, 7.622],
                                                [-0.247, -0.057],
                                                [-10.288, -2.376],
                                                [-12.774, -4.994],
                                                [-6.039, -3.04],
                                                [-33.5, 0],
                                                [-111.139, 55.943],
                                                [0, 0],
                                                [0, 59.823],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [59.823, 0],
                                                [0, 0],
                                                [-15.896, 27.668],
                                            ],
                                            v: [
                                                [485.333, -161.013],
                                                [405.5, -183.098],
                                                [325.667, -161.013],
                                                [284.742, -142.63],
                                                [254.505, -133.352],
                                                [253.771, -133.183],
                                                [153.229, -133.183],
                                                [152.495, -133.352],
                                                [122.258, -142.63],
                                                [81.333, -161.013],
                                                [1.5, -183.098],
                                                [0, -183.074],
                                                [-1.5, -183.098],
                                                [-81.333, -161.013],
                                                [-122.258, -142.63],
                                                [-152.495, -133.352],
                                                [-153.229, -133.183],
                                                [-253.771, -133.183],
                                                [-254.505, -133.352],
                                                [-284.742, -142.63],
                                                [-325.667, -161.013],
                                                [-405.5, -183.098],
                                                [-485.333, -161.013],
                                                [-672, -148.427],
                                                [-672, 74.779],
                                                [-563.68, 183.098],
                                                [-268, 183.098],
                                                [-139, 183.098],
                                                [0, 183.098],
                                                [139, 183.098],
                                                [268, 183.098],
                                                [563.68, 183.098],
                                                [672, 74.779],
                                                [672, -148.427],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 100, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 3,
                                        k: {
                                            a: 0,
                                            k: [
                                                0, 1, 0.6862745098039216,
                                                0.5882352941176471, 0.366, 1,
                                                0.5607843137254902,
                                                0.43137254901960786, 1, 1,
                                                0.2784313725490196,
                                                0.06666666666666667,
                                            ],
                                            ix: 9,
                                        },
                                    },
                                    s: { a: 0, k: [-1, -182.902], ix: 5 },
                                    e: { a: 0, k: [-1, 141.689], ix: 6 },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0.5], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 300,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 12,
                    ty: 4,
                    nm: 'Layer 2',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [305, 257.5, 0], ix: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1 },
                        s: { a: 0, k: [23, 23, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-18.958, -34.404],
                                                [-39.535, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-19.081, 34.626],
                                                [20.729, 33.3],
                                            ],
                                            o: [
                                                [-4.955, -8.722],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-20.729, 33.3],
                                                [19.081, 34.626],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [39.535, 0],
                                                [18.958, -34.404],
                                                [0, 0],
                                            ],
                                            v: [
                                                [125.073, 29.407],
                                                [120.348, -256.174],
                                                [-125, -256.174],
                                                [-128.71, 35.81],
                                                [-442.746, 588.674],
                                                [-445.594, 696.702],
                                                [-351.983, 752],
                                                [-20.5, 752],
                                                [20.5, 752],
                                                [351.983, 752],
                                                [445.594, 696.702],
                                                [442.746, 588.674],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 100, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 5,
                                        k: {
                                            a: 0,
                                            k: [
                                                0, 0.471, 0.595, 1, 0.084,
                                                0.471, 0.595, 1, 0.168, 0.471,
                                                0.595, 1, 0.413, 0.58, 0.723,
                                                0.982, 0.658, 0.689, 0.851,
                                                0.965,
                                            ],
                                            ix: 9,
                                        },
                                    },
                                    s: { a: 0, k: [0, 419.565], ix: 5 },
                                    e: { a: 0, k: [-4.348, -100], ix: 6 },
                                    t: 1,
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Group 1',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [
                                    0.172549019608, 0.172549019608,
                                    0.172549019608, 1,
                                ],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 50, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 300,
                    st: 0,
                    bm: 0,
                },
            ],
        },
    ],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Shape Layer 1',
            parent: 2,
            sr: 1,
            ks: {
                o: { a: 0, k: 85, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [301, 302, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-39.75, 14.25],
                                        [-88.75, 99.5],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 10, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'gf',
                            o: { a: 0, k: 100, ix: 10 },
                            r: 1,
                            bm: 0,
                            g: {
                                p: 3,
                                k: {
                                    a: 0,
                                    k: [
                                        0, 1, 0.761, 0.986, 0.366, 0.805, 0.578,
                                        0.993, 1, 0.609, 0.396, 1,
                                    ],
                                    ix: 9,
                                },
                            },
                            s: { a: 0, k: [0, 0], ix: 5 },
                            e: { a: 0, k: [100, 0], ix: 6 },
                            t: 1,
                            nm: 'Gradient Fill 1',
                            mn: 'ADBE Vector Graphic - G-Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 2',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-13.5, -53.5],
                                        [-13.5, -32],
                                        [-22.625, -16.25],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 10, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 620,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 0,
            nm: 'Comp 7',
            refId: 'comp_0',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            t: 130,
                            s: [0],
                        },
                        {
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            t: 144,
                            s: [26],
                        },
                        {
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.333], y: [0] },
                            t: 157,
                            s: [-20],
                        },
                        {
                            i: { x: [0.667], y: [1] },
                            o: { x: [0.167], y: [0] },
                            t: 170,
                            s: [5],
                        },
                        { t: 179, s: [0] },
                    ],
                    ix: 10,
                },
                p: { a: 0, k: [400, 400, 0], ix: 2 },
                a: { a: 0, k: [300, 300, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            w: 600,
            h: 600,
            ip: 0,
            op: 360,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Shape Layer 9',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [400, 400, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [],
            ip: 0,
            op: 620,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Shape Layer 3',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [328.75, 491.313, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [42, -184.5],
                                        [-9, -184.938],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 166,
                                        s: [0],
                                    },
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 182,
                                        s: [55.308],
                                    },
                                    { t: 200, s: [100] },
                                ],
                                ix: 1,
                            },
                            e: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 166,
                                        s: [0],
                                    },
                                    { t: 182, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Trim Paths 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [1, 0.627543251187, 0.188235279158, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 14, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.941176470588, 0.114417521159,
                                    0.27355891209, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 1',
                    np: 4,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 32,
            op: 2732,
            st: 32,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Shape Layer 8',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [320.5, 480.5, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [52, -199],
                                        [23.5, -231.25],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 166,
                                        s: [0],
                                    },
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 182,
                                        s: [55.308],
                                    },
                                    { t: 200, s: [100] },
                                ],
                                ix: 1,
                            },
                            e: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 166,
                                        s: [0],
                                    },
                                    { t: 182, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Trim Paths 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [1, 0.627543251187, 0.188235279158, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 14, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.941176470588, 0.114417521159,
                                    0.27355891209, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 1',
                    np: 4,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 32,
            op: 2732,
            st: 32,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Shape Layer 4',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [351.5, 470, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [52, -199],
                                        [52, -269],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 166,
                                        s: [0],
                                    },
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 182,
                                        s: [55.308],
                                    },
                                    { t: 200, s: [100] },
                                ],
                                ix: 1,
                            },
                            e: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 166,
                                        s: [0],
                                    },
                                    { t: 182, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Trim Paths 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [1, 0.627543251187, 0.188235279158, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 14, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.941176470588, 0.114417521159,
                                    0.27355891209, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 1',
                    np: 4,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 32,
            op: 2732,
            st: 32,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Shape Layer 5',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [385, 475, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [52, -199],
                                        [77, -222.5],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 166,
                                        s: [0],
                                    },
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 182,
                                        s: [55.308],
                                    },
                                    { t: 200, s: [100] },
                                ],
                                ix: 1,
                            },
                            e: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 166,
                                        s: [0],
                                    },
                                    { t: 182, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Trim Paths 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [1, 0.627543251187, 0.188235279158, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 14, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.941176470588, 0.114417521159,
                                    0.27355891209, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 1',
                    np: 4,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 32,
            op: 2732,
            st: 32,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'Shape Layer 7',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [377.75, 484.438, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [66.75, -179],
                                        [108, -179.063],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 166,
                                        s: [0],
                                    },
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 182,
                                        s: [55.308],
                                    },
                                    { t: 200, s: [100] },
                                ],
                                ix: 1,
                            },
                            e: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 166,
                                        s: [0],
                                    },
                                    { t: 182, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Trim Paths 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [1, 0.627543251187, 0.188235279158, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 14, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.941176470588, 0.114417521159,
                                    0.27355891209, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Shape 1',
                    np: 4,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 32,
            op: 2732,
            st: 32,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'Shape Layer 10',
            sr: 1,
            ks: {
                o: { a: 0, k: 28, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [392.5, 344, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [244, 72], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 0, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [11, 189], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Ellipse 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'fl',
                    c: {
                        a: 0,
                        k: [0.647058823529, 0.663667746151, 1, 1],
                        ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                },
            ],
            ip: 0,
            op: 620,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: 'Shape Layer 2',
            sr: 1,
            ks: {
                o: { a: 0, k: 28, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [392.5, 362, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [244, 72], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 0, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [11, 189], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Ellipse 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'fl',
                    c: {
                        a: 0,
                        k: [0.647058823529, 0.663667746151, 1, 1],
                        ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                },
            ],
            ip: 0,
            op: 620,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
};
