import JTOObject from '../utils/jto/JTOObject';
import JTOSimple from '../utils/jto/JTOSimple';
import JTOProperty from '../utils/jto/JTOProperty';
import JTORelProperty from '../utils/jto/JTORelProperty';
import JTOList from '../utils/jto/JTOList';
import JTOElement from '../utils/jto/JTOElement';

export default class Response extends JTOObject {
    public static _TYPE: string = 'Response';

    constructor() {
        super(Response._TYPE);
    }
}
