import {
    ActivityIndicator,
    Image,
    Linking,
    Pressable,
    Text,
    View,
} from 'react-native';
import React, { useEffect } from 'react';
import { SERVER_URL } from '../../utils/constants';
import { Message } from '../../object/Message';
import { height, width } from '../../utils/utils';
import { FONT_REGULAR, getColor } from '../../utils/theme';
import { IModalProps } from '../../modal/IModalProps';
import { styles } from '../../utils/styles';
import { UserGroupIcon, UserIcon } from 'react-native-heroicons/solid';
import { GeSpinner } from '../GeSpinner';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../../utils/screen';
import { PastilleView } from '../PastilleView';
import FileMessage from '../../object/FileMessage';
import { FileElementView } from '../FileElementView';
import ChatGroup from '../../object/ChatGroup';
import User from '../../object/User';
import { CustomTextWithLinks } from '../CustomTextWithLinks';

interface IMessageViewProps extends IModalProps {
    item: Message;
    listLength: number;
    elem: User | ChatGroup;
    index: number;
    type: 'user' | 'group';
}

function formatDate(dateString: string) {
    const date = new Date(dateString);
    const today = new Date();

    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    let hours = date.getHours() + '';
    let minutes = date.getMinutes() + '';
    if ((minutes + '').length === 1) {
        minutes = '0' + minutes;
    }
    if ((hours + '').length === 1) {
        hours = '0' + hours;
    }

    if (
        year === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        day === today.getDate()
    ) {
        return `${hours}:${minutes}`;
    } else if (year === today.getFullYear()) {
        return `${day} ${month} ${hours}:${minutes}`;
    } else {
        return `${day} ${month} ${year} ${hours}:${minutes}`;
    }
}

export const MessageView = (props: IMessageViewProps) => {
    const item = (props as any).item;
    const listLength = (props as any).listLength;
    const index = (props as any).index;
    const type = (props as any).type;
    const elem = props.elem;

    let content = <></>;

    useEffect(() => {}, []);

    if ((item as any) === 'top') {
        if (elem?.avatar.get() === '' || elem?.avatar.get() === undefined) {
            content = (
                <View
                    style={{
                        marginTop: 150,
                        marginBottom: listLength === 1 ? height() * 0.3 : 50,
                        paddingHorizontal: 50,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <PastilleView
                        size={150}
                        color={getColor('secondary')}
                        icon={
                            elem instanceof User ? (
                                <UserIcon
                                    size={95}
                                    color={'white'}
                                />
                            ) : (
                                <UserGroupIcon
                                    size={95}
                                    color={'white'}
                                />
                            )
                        }
                    />
                    <Text
                        style={{
                            ...styles.h3,
                            textAlign: 'center',
                            marginTop: 15,
                        }}
                    >
                        {elem.getDisplayName()}
                    </Text>
                </View>
            );
        } else {
            content = (
                <View
                    style={{
                        marginTop: 150,
                        marginBottom: 50,
                        paddingHorizontal: 50,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Image
                        style={{
                            height: 150,
                            width: 150,
                            borderRadius: 100,
                        }}
                        source={{
                            uri: SERVER_URL + '/image/' + elem?.avatar.get(),
                        }}
                    />
                    <Text
                        style={{
                            ...styles.h3,
                            textAlign: 'center',
                            marginTop: 15,
                        }}
                    >
                        {elem.getDisplayName()}
                    </Text>
                </View>
            );
        }
    } else if ((item as any) === 'loading') {
        if (listLength === 1) {
            content = (
                <View
                    style={{
                        width: width(),
                        height:
                            height() -
                            NAVIGATION_BAR_HEIGHT -
                            STATUS_BAR_HEIGHT -
                            TAB_HEIGHT -
                            100,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <GeSpinner color={getColor('primary')} />
                </View>
            );
        } else {
            content = (
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 25,
                        marginBottom: 25,
                    }}
                >
                    <GeSpinner color={getColor('primary')} />
                </View>
            );
        }
    } else if (item instanceof Date) {
        return (
            <Text
                style={[
                    styles.text,
                    {
                        fontSize: 12,
                        color: getColor('hint'),
                        textAlign: 'center',
                        marginBottom: 5,
                    },
                ]}
            >
                {formatDate(item.toISOString())}
            </Text>
        );
    } else if (item instanceof Message) {
        const fileContent = item.fileList.getList().map((file) => {
            return (
                <View
                    key={file.getJTOIdentifier()}
                    style={[
                        {
                            marginHorizontal: 5,
                            marginBottom: 5,
                            height: (width() - 30) / 3,
                            paddingRight: 5,
                        },
                    ]}
                >
                    <FileElementView
                        navigation={props.navigation}
                        imageSquare={true}
                        file={file}
                    />
                </View>
            );
        });
        if (item.isMe() === false) {
            content = (
                <View
                    key={item.getJTOIdentifier() ?? index}
                    style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    {item.content.get() !== '' &&
                        item.content.get() !== undefined &&
                        item.content.get() !== null && (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    marginBottom: 10,
                                    marginLeft: 5,
                                }}
                            >
                                <View
                                    style={{
                                        marginTop: 5,
                                    }}
                                >
                                    <PastilleView
                                        color={getColor('secondary')}
                                        size={30}
                                        icon={
                                            <UserIcon
                                                size={23}
                                                color={'white'}
                                            />
                                        }
                                    />
                                </View>
                                <View
                                    style={{
                                        width: '70%',
                                        marginLeft: 5,
                                    }}
                                >
                                    <CustomTextWithLinks
                                        content={item.event.name.get() ?? ''}
                                        style={{
                                            color: getColor('text'),
                                            fontFamily: FONT_REGULAR,
                                        }}
                                        globalStyle={{
                                            paddingHorizontal: 13,
                                            paddingVertical: 2,
                                        }}
                                    />

                                    {type === 'group' && (
                                        <Text
                                            style={[
                                                styles.text,
                                                {
                                                    marginBottom: 5,
                                                    marginLeft: 5,
                                                    color: getColor('hint'),
                                                },
                                            ]}
                                        >
                                            {item.getSender().getDisplayName()}
                                        </Text>
                                    )}
                                    <View
                                        style={{
                                            borderRadius: 20,
                                            backgroundColor: getColor('card'),
                                        }}
                                    >
                                        <CustomTextWithLinks
                                            content={item.content.get() ?? ''}
                                            style={{
                                                color: getColor('text'),
                                                fontFamily: FONT_REGULAR,
                                            }}
                                            globalStyle={{
                                                paddingHorizontal: 13,
                                                paddingVertical: 10,
                                            }}
                                        />
                                    </View>
                                </View>
                            </View>
                        )}
                    {fileContent}
                </View>
            );
        } else {
            content = (
                <View
                    key={item.getJTOIdentifier() ?? index}
                    style={{
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                    }}
                >
                    {item.content.get() !== '' &&
                        item.content.get() !== undefined &&
                        item.content.get() !== null && (
                            <View
                                style={{
                                    opacity: item.isSending() ? 0.75 : 1,
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <CustomTextWithLinks
                                    content={item.event.name.get() ?? ''}
                                    style={{
                                        color: getColor('text'),
                                        fontFamily: FONT_REGULAR,
                                    }}
                                    globalStyle={{
                                        paddingHorizontal: 13,
                                        paddingVertical: 2,
                                    }}
                                />
                                <View
                                    style={{
                                        width: '70%',
                                        marginRight: 10,
                                        marginTop: 10,
                                        marginBottom: 10,
                                        borderRadius: 20,
                                        backgroundColor: getColor('primary'),
                                    }}
                                >
                                    <CustomTextWithLinks
                                        content={item.content.get() ?? ''}
                                        style={{
                                            color: 'white',
                                            fontFamily: FONT_REGULAR,
                                        }}
                                        globalStyle={{
                                            paddingHorizontal: 13,
                                            paddingVertical: 10,
                                        }}
                                    />
                                </View>
                            </View>
                        )}
                    {fileContent}
                </View>
            );
        }
    }

    return content;
};
