import { View, Text, Pressable, ScrollView } from 'react-native';
import React from 'react';
import { styles } from '../utils/styles';
import { FONT_MEDIUM, FONT_REGULAR, getColor } from '../utils/theme';
import { height, width } from '../utils/utils';
import { NAVIGATION_BAR_HEIGHT, TAB_HEIGHT } from '../utils/screen';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './IModalProps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Keychain from 'react-native-keychain';
import { useEffect, useState } from 'react';
import User from '../object/User';
import { Header } from '../component/Header';
import { NoSymbolIcon, UserIcon } from 'react-native-heroicons/solid';
import { PastilleView } from '../component/PastilleView';
import { getRestore } from '../utils/http';
import { HTTPUtils } from 'mp-ts-http';
import { Toast } from '../component/toast/Toast';
import { ToastManager } from '../component/toast/ToastManager';
import useJTOState from '../utils/jto/useJTOState';
import { applyResult } from '../utils/applyResult';

interface IMenuChatGroupModalProps extends IModalProps {
    user: User;
}

export const MenuProfileModal = (props: IMenuChatGroupModalProps) => {
    const { t } = useTranslation();

    const user = useJTOState((props as any).route.params.user as User);
    const [notIn, setNotIn] = useState(false);

    useEffect(() => {
        getRestore(
            '/user/event/him',
            {
                user: user.getJTOIdentifier() + '',
                event: User._instance.getEvent()?.getJTOIdentifier() + '',
            },
            (res: any) => {
                if (HTTPUtils.isSuccess(res)) {
                    applyResult(HTTPUtils.getResult(res), user, true);
                } else {
                    setNotIn(true);
                }
            },
            (err: any) => {
                setNotIn(true);
            },
            (res: any) => {
                user.blockMe.set(null);
                // TODO
            },
        );
    }, []);

    const actionList = [
        {
            label: 'see_profile',
            icon: (
                <UserIcon
                    color={'white'}
                    size={22}
                />
            ),
            onClick: (navigation: any) => {
                navigation.push('ProfilePage', {
                    user,
                });
            },
        },
        {
            label: user.blockMe.get() === null ? 'block' : 'unblock',
            icon: (
                <NoSymbolIcon
                    color={'white'}
                    size={22}
                />
            ),
            onClick: (navigation: any) => {
                navigation.navigate('BlockUserModal', {
                    user,
                    act: user.blockMe.get() === null ? 'block' : 'unblock',
                });
            },
        },
    ];
    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('edit_profile')}
                line={1}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                backButton={true}
                navigation={props.navigation}
                rounded={(user.avatar.get() ?? '') !== ''}
                logo={user.avatar.get() ?? ''}
                fallback={
                    <UserIcon
                        color={'white'}
                        size={35}
                    />
                }
            >
                {notIn ? (
                    <View
                        style={{
                            height: height(),
                            width: width(),
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Text>{t('not_in_event')}</Text>
                    </View>
                ) : (
                    <View
                        style={{
                            marginTop: -50,
                            height: height() - NAVIGATION_BAR_HEIGHT,
                        }}
                    >
                        <View
                            style={{
                                marginTop: 60,
                            }}
                        >
                            {actionList.map((action, index) => {
                                return (
                                    <Pressable
                                        key={index}
                                        style={[
                                            index !== 0 && {
                                                borderTopWidth: 1,
                                                borderColor: getColor('border'),
                                            },
                                            {
                                                flexDirection: 'row',
                                                marginHorizontal: 20,
                                                padding: 10,
                                                alignItems: 'center',
                                                backgroundColor:
                                                    getColor('card'),
                                            },
                                            index === 0 && {
                                                borderTopLeftRadius: 15,
                                                borderTopRightRadius: 15,
                                            },
                                            index === actionList.length - 1 && {
                                                borderBottomLeftRadius: 15,
                                                borderBottomRightRadius: 15,
                                            },
                                        ]}
                                        onPress={(e) => {
                                            e.stopPropagation();
                                            action.onClick(props.navigation);
                                        }}
                                    >
                                        <PastilleView
                                            size={32}
                                            color={getColor('secondary')}
                                            icon={action.icon}
                                        />
                                        <Text
                                            adjustsFontSizeToFit
                                            style={[
                                                {
                                                    marginLeft: 10,
                                                    color: 'black',
                                                    fontFamily: FONT_MEDIUM,
                                                    fontSize: 16,
                                                    flex: 1,
                                                },
                                            ]}
                                        >
                                            {t(action.label)}
                                        </Text>
                                    </Pressable>
                                );
                            })}
                        </View>
                        <View
                            style={{
                                height: NAVIGATION_BAR_HEIGHT + TAB_HEIGHT,
                                width: width(),
                            }}
                        ></View>
                    </View>
                )}
            </Header>
        </View>
        /*<Pressable
            onPress={e => {
                e.stopPropagation();
                props.navigation.goBack();
            }}
            style={{
                position: 'absolute',
                top: 0,
                zIndex: 100,
                left: 0,
                right: 0,
                bottom: 0,
                paddingStart: 10,
                paddingEnd: 10,
                display: 'flex',
                paddingBottom: NAVIGATION_BAR_HEIGHT + 10,
                justifyContent: 'flex-end',
                backgroundColor: getColor('alpha'),
            }}>
            <View
                style={{
                    borderRadius: 13,
                    backgroundColor: getColor('backgroundLight'),
                    marginBottom: 10,
                }}>
                {actionList.map((action, index) => {
                    return (
                        <Pressable
                            key={index}
                            style={[
                                styles.buttonMenu,
                                index !== 0 && {
                                    borderTopWidth: 1,
                                    borderColor: getColor('border'),
                                },
                            ]}
                            onPress={e => {
                                e.stopPropagation();
                                action.onClick(props.navigation);
                            }}>
                            <Text
                                style={[
                                    styles.buttonScreenText,
                                    {
                                        color:
                                            action.color ?? getColor('primary'),
                                        fontFamily: FONT_REGULAR,
                                    },
                                ]}>
                                {t(action.label)}
                            </Text>
                        </Pressable>
                    );
                })}
            </View>
            <Pressable
                onPress={e => {
                    e.stopPropagation();
                    props.navigation.goBack();
                }}
                style={[
                    styles.buttonMenu,
                    {
                        borderRadius: 13,
                        backgroundColor: getColor('background'),
                    },
                ]}>
                <Text
                    style={[
                        styles.buttonScreenText,
                        {color: getColor('primary')},
                    ]}>
                    {t('cancel')}
                </Text>
            </Pressable>
        </Pressable>*/
    );
};
