import { useTranslation } from 'react-i18next';
import { Linking, Pressable, Text, View } from 'react-native';
import React from 'react';
import {
    EnvelopeIcon,
    PhoneIcon,
    StarIcon,
} from 'react-native-heroicons/solid';
import Contact from '../object/Contact';
import { styles } from '../utils/styles';
import { FONT_BOLD, getColor } from '../utils/theme';
import { PastilleView } from './PastilleView';
import { CustomTextWithLinks } from './CustomTextWithLinks';

interface IContactViewProps {
    contact: Contact;
    index: number;
}

export const ContactView = (props: IContactViewProps) => {
    const { t } = useTranslation();
    const { contact, index } = props;

    const primaryColor = getColor('primary');
    const secondaryColor = getColor('secondary');

    return (
        <View
            style={{
                backgroundColor: getColor('background'),
                overflow: 'hidden',
            }}
        >
            <View
                style={{
                    backgroundColor: primaryColor,
                    flexDirection: 'row',
                    padding: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <View
                    style={{
                        aspectRatio: 1,
                        height: 25,
                        backgroundColor: 'white',
                        borderRadius: 14,
                        overflow: 'hidden',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Text
                        style={{
                            color: primaryColor,
                            fontFamily: FONT_BOLD,
                            fontSize: 16,
                        }}
                    >
                        {index + 1}
                    </Text>
                </View>
                <Text
                    adjustsFontSizeToFit
                    style={[
                        styles.h5,
                        {
                            flex: 1,
                            marginLeft: 10,
                            color: 'white',
                        },
                    ]}
                >
                    {contact.getDisplayName()}
                </Text>
                {contact.important.get() === true && (
                    <StarIcon color={secondaryColor} />
                )}
            </View>

            {contact.hasThing() && (
                <View
                    style={{
                        padding: 12,
                    }}
                >
                    {contact.description.get() !== '' &&
                        contact.description.get() !== undefined && (
                            <CustomTextWithLinks
                                content={contact.description.get() ?? ''}
                                style={styles.text}
                            />
                        )}
                    {contact.email.get() !== '' &&
                        contact.email.get() !== undefined && (
                            <Pressable
                                style={{
                                    marginTop:
                                        contact.description.get() !== '' &&
                                        contact.description.get() !== undefined
                                            ? 12
                                            : 0,
                                    flexDirection: 'row',
                                }}
                                onPress={() => {
                                    Linking.openURL(
                                        'mailto:' + contact.email.get(),
                                    );
                                }}
                            >
                                <PastilleView
                                    color={secondaryColor}
                                    size={35}
                                    icon={
                                        <EnvelopeIcon
                                            size={20}
                                            color={'white'}
                                        />
                                    }
                                />

                                <View
                                    style={{
                                        display: 'flex',
                                        marginLeft: 10,
                                        flex: 1,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.h6,
                                            {
                                                color: getColor('hint'),
                                            },
                                        ]}
                                    >
                                        {t('email').toUpperCase()}
                                    </Text>
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                fontSize: 15,
                                            },
                                        ]}
                                    >
                                        {contact.email.get()}
                                    </Text>
                                </View>
                            </Pressable>
                        )}

                    {contact.phone.get() !== '' &&
                        contact.phone.get() !== undefined && (
                            <Pressable
                                style={{
                                    marginTop:
                                        (contact.description.get() !== '' &&
                                            contact.description.get() !==
                                                undefined) ||
                                        (contact.email.get() !== '' &&
                                            contact.email.get() !== undefined)
                                            ? 12
                                            : 0,
                                    flexDirection: 'row',
                                }}
                                onPress={() => {
                                    Linking.openURL(
                                        'tel:' + contact.phone.get(),
                                    );
                                }}
                            >
                                <PastilleView
                                    color={secondaryColor}
                                    size={35}
                                    icon={
                                        <PhoneIcon
                                            size={20}
                                            color={'white'}
                                        />
                                    }
                                />

                                <View
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        marginLeft: 10,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Text
                                        style={[
                                            styles.h6,
                                            {
                                                color: getColor('hint'),
                                            },
                                        ]}
                                    >
                                        {t('phone').toUpperCase()}
                                    </Text>
                                    <Text
                                        style={[
                                            styles.text,
                                            {
                                                fontSize: 15,
                                            },
                                        ]}
                                    >
                                        {contact.phone.get()}
                                    </Text>
                                </View>
                            </Pressable>
                        )}
                </View>
            )}
        </View>
    );
};
