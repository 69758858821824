import * as React from 'react';
import Svg, { Path, SvgProps, Text } from 'react-native-svg';
import { FONT_HEAVY } from '../../utils/theme';
import { IBronzeAchivementProps } from './BronzeAchivement';
import { GenericAchivementProps } from './GenericAchivement';

function SilverAchivement(props: IBronzeAchivementProps) {
    return (
        <Svg
            style={{
                position: 'absolute',
                opacity: props.owned ? 1 : 0.65,
            }}
            viewBox='0 0 250 250'
            fill='none'
            {...props}
        >
            <Path
                d='M208.496 114.333c0 46.024-37.309 83.334-83.332 83.334-46.023 0-83.332-37.31-83.332-83.334C41.832 68.31 79.142 31 125.164 31c46.023 0 83.332 37.31 83.332 83.333z'
                fill='#BCCCD2'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M214.669 114.333c0 49.433-40.073 89.506-89.505 89.506-49.432 0-89.505-40.073-89.505-89.506s40.073-89.506 89.505-89.506c49.432 0 89.505 40.073 89.505 89.506zm-89.505 83.334c46.023 0 83.332-37.31 83.332-83.334C208.496 68.31 171.187 31 125.164 31c-46.023 0-83.332 37.31-83.332 83.333 0 46.024 37.31 83.334 83.332 83.334z'
                fill='#81A5B0'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M48.005 117.42a3.087 3.087 0 01-3.086-3.087c0-44.319 35.927-80.247 80.245-80.247a3.087 3.087 0 110 6.173c-40.909 0-74.072 33.164-74.072 74.074a3.087 3.087 0 01-3.087 3.087z'
                fill='#D0DEE3'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M202.324 111.247a3.086 3.086 0 013.086 3.086c0 44.319-35.927 80.247-80.246 80.247a3.086 3.086 0 010-6.173c40.91 0 74.073-33.164 74.073-74.074a3.087 3.087 0 013.087-3.086z'
                fill='#A2B9C1'
            />
            <Path
                d='M186.892 114.333c0 34.092-27.637 61.729-61.728 61.729-34.09 0-61.727-27.637-61.727-61.729 0-34.091 27.636-61.728 61.727-61.728 34.091 0 61.728 27.637 61.728 61.728z'
                fill='#C7D4D8'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M193.064 114.333c0 37.501-30.399 67.902-67.9 67.902-37.5 0-67.9-30.401-67.9-67.902 0-37.5 30.4-67.9 67.9-67.9 37.501 0 67.9 30.4 67.9 67.9zm-67.9 61.729c34.091 0 61.728-27.637 61.728-61.729 0-34.091-27.637-61.728-61.728-61.728-34.09 0-61.727 27.637-61.727 61.728 0 34.092 27.636 61.729 61.727 61.729z'
                fill='#81A5B0'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M125.164 166.802c28.978 0 52.469-23.491 52.469-52.469a3.086 3.086 0 116.172 0c0 32.387-26.254 58.642-58.641 58.642a3.086 3.086 0 010-6.173z'
                fill='#B5C3C8'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M125.164 61.864c-28.977 0-52.468 23.492-52.468 52.469a3.087 3.087 0 11-6.173 0c0-32.387 26.255-58.642 58.641-58.642a3.087 3.087 0 110 6.173z'
                fill='#D9EAEF'
            />
            <Path
                d='M30.834 196.502l-7.95-15.9c-.865-1.731.394-3.769 2.33-3.769h34.848a2.604 2.604 0 012.604 2.605v36.458a2.604 2.604 0 01-2.604 2.604H25.214c-1.936 0-3.195-2.037-2.33-3.769l7.95-15.9a2.603 2.603 0 000-2.329zM219.496 196.502l7.95-15.9c.865-1.731-.394-3.769-2.33-3.769h-34.848a2.604 2.604 0 00-2.604 2.605v36.458a2.604 2.604 0 002.604 2.604h34.848c1.936 0 3.195-2.037 2.33-3.769l-7.95-15.9a2.6 2.6 0 010-2.329z'
                fill='#C52229'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M25.594 197.667l-7.368-14.736c-2.597-5.194 1.18-11.306 6.988-11.306h34.848a7.812 7.812 0 017.812 7.812v36.459a7.812 7.812 0 01-7.812 7.812H25.214c-5.808 0-9.585-6.112-6.988-11.306l7.368-14.735zm.58 1.161l.002.003 2.33-1.164-2.33 1.164-.002-.003zm198.563-1.161l7.367-14.736c2.597-5.194-1.18-11.306-6.987-11.306h-34.849a7.813 7.813 0 00-7.812 7.812v36.459a7.812 7.812 0 007.812 7.812h34.849c5.807 0 9.584-6.111 6.987-11.306l-7.367-14.735zm2.709-17.065l-7.95 15.9a2.6 2.6 0 000 2.329l7.95 15.9c.865 1.732-.394 3.769-2.329 3.769h-34.849a2.604 2.604 0 01-2.604-2.604v-36.459a2.604 2.604 0 012.604-2.604h34.849c1.935 0 3.194 2.038 2.329 3.769zm-204.562 0l7.95 15.9a2.603 2.603 0 010 2.329l-7.95 15.9c-.865 1.732.394 3.769 2.33 3.769h34.848a2.604 2.604 0 002.604-2.604v-36.459a2.604 2.604 0 00-2.604-2.604H25.214c-1.936 0-3.195 2.038-2.33 3.769z'
                fill='#9B0F15'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M26.61 179.188a2.605 2.605 0 012.202-1.215H39.88a2.604 2.604 0 010 5.208h-6.936l5.379 11.246a2.604 2.604 0 11-4.699 2.247l-7.16-14.974a2.604 2.604 0 01.145-2.512z'
                fill='#DF3840'
            />
            <Path
                d='M198.081 205.479v-36.458a2.604 2.604 0 00-2.605-2.604H54.854a2.604 2.604 0 00-2.604 2.604v36.458a2.604 2.604 0 002.604 2.604h140.622a2.604 2.604 0 002.605-2.604z'
                fill='#F1333B'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M203.289 169.021v36.458a7.813 7.813 0 01-7.813 7.813H54.854a7.813 7.813 0 01-7.813-7.813v-36.458a7.813 7.813 0 017.813-7.813h140.622a7.813 7.813 0 017.813 7.813zm-5.208 0v36.458a2.604 2.604 0 01-2.605 2.604H54.854a2.604 2.604 0 01-2.604-2.604v-36.458a2.604 2.604 0 012.604-2.604h140.622a2.604 2.604 0 012.605 2.604z'
                fill='#9B0F15'
            />
            <Path
                d='M190.886 188.239c-1.475 0-2.67-1.166-2.67-2.605s1.195-2.605 2.67-2.605c1.475 0 2.67 1.166 2.67 2.605s-1.195 2.605-2.67 2.605zM180.727 187.197c-1.834 0-3.321-1.451-3.321-3.24 0-1.79 1.487-3.241 3.321-3.241s3.321 1.451 3.321 3.241c0 1.789-1.487 3.24-3.321 3.24zM185.253 194.605c-.917 0-1.661-.725-1.661-1.62 0-.895.744-1.62 1.661-1.62s1.661.725 1.661 1.62c0 .895-.744 1.62-1.661 1.62zM182.387 177.47c-.917 0-1.66-.726-1.66-1.621 0-.894.743-1.62 1.66-1.62.918 0 1.661.726 1.661 1.62 0 .895-.743 1.621-1.661 1.621zM173.661 192.985c-.917 0-1.66-.725-1.66-1.62 0-.895.743-1.62 1.66-1.62.917 0 1.661.725 1.661 1.62 0 .895-.744 1.62-1.661 1.62zM172.001 184.649c-.918 0-1.661-.725-1.661-1.62 0-.895.743-1.62 1.661-1.62.917 0 1.66.725 1.66 1.62 0 .895-.743 1.62-1.66 1.62zM160.679 203.443c-.917 0-1.66-.725-1.66-1.62 0-.895.743-1.62 1.66-1.62.917 0 1.661.725 1.661 1.62 0 .895-.744 1.62-1.661 1.62zM193.719 197.258c-.917 0-1.661-.725-1.661-1.62 0-.895.744-1.62 1.661-1.62s1.661.725 1.661 1.62c0 .895-.744 1.62-1.661 1.62zM178.024 200.806c-1.492 0-2.702-1.18-2.702-2.636 0-1.457 1.21-2.637 2.702-2.637 1.493 0 2.703 1.18 2.703 2.637 0 1.456-1.21 2.636-2.703 2.636zM189.616 203.443c-1.493 0-2.703-1.181-2.703-2.637s1.21-2.637 2.703-2.637c1.493 0 2.703 1.181 2.703 2.637s-1.21 2.637-2.703 2.637zM165.977 198.259c-1.493 0-2.703-1.181-2.703-2.637s1.21-2.637 2.703-2.637c1.492 0 2.702 1.181 2.702 2.637s-1.21 2.637-2.702 2.637zM170.34 204.243c-.917 0-1.661-.725-1.661-1.62 0-.894.744-1.62 1.661-1.62s1.66.726 1.66 1.62c0 .895-.743 1.62-1.66 1.62z'
                fill='#DB1B24'
            />
            <Path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M53.877 170.16a2.604 2.604 0 012.604-2.604h97.655a2.604 2.604 0 010 5.208h-95.05v18.23a2.604 2.604 0 01-5.209 0V170.16z'
                fill='#FE666D'
            />
            <Text
                x='125'
                textAnchor='middle'
                y='200'
                fontWeight={'bold'}
                fontFamily={FONT_HEAVY}
                fill={'white'}
                fontSize={35}
            >
                {props.count}
            </Text>
        </Svg>
    );
}

export default SilverAchivement;
