import React from 'react';
import { View, Text } from 'react-native';
import { FONT_REGULAR, getColor, styles } from '../utils/theme';
import { Header } from '../component/Header';
import { useTranslation } from 'react-i18next';
import User from '../object/User';
import { height } from '../utils/utils';
import { GeSpinner } from '../component/GeSpinner';
import { CartoucheButton } from '../component/CartoucheButton';

export const MustLoginPage = (props: any) => {
    const { navigation } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    return (
        <View style={styles.page}>
            <Header
                modal={true}
                title={t('must_login_for_this_action_title')}
                line={1}
                backButton={true}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
                navigation={props.navigation}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
            >
                <View
                    style={{
                        marginTop: 15,
                    }}
                >
                    {loading ? (
                        <View
                            style={[
                                styles.boxCenter,
                                {
                                    height: height() * 0.5,
                                },
                            ]}
                        >
                            <GeSpinner color={getColor('primary')} />
                        </View>
                    ) : (
                        <View
                            style={{
                                paddingHorizontal: 20,
                            }}
                        >
                            <Text
                                style={{
                                    paddingVertical: 30,
                                    textAlign: 'center',
                                    fontFamily: FONT_REGULAR,
                                    color: getColor('text'),
                                }}
                            >
                                {t('must_login_for_this_action_description')}
                            </Text>
                            {error !== '' && (
                                <Text
                                    style={[
                                        styles.textError,
                                        {
                                            marginTop: 10,
                                        },
                                    ]}
                                >
                                    {error}
                                </Text>
                            )}

                            <View
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <CartoucheButton
                                    onPress={() => {
                                        navigation.navigate('Login');
                                    }}
                                    color={getColor('secondary')}
                                    title={t('login')}
                                    height={80}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </Header>
        </View>
    );
};
