import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function NetworkingEvening(props: any) {
    return (
        <svg
            id='Calque_2'
            data-name='Calque 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 158.36 89.15'
            {...props}
        >
            <defs></defs>
            <g
                id='Calque_1-2'
                data-name='Calque 1'
            >
                <path
                    fill='currentColor'
                    d='M113.26 68.82c-1.29 4.26-6.15 6.98-11.26 6.25-1.23-.17-2.44-.5-3.64-.84-2.32-.65-3.57-.04-4.96 1.93-1.52 2.15-3.03 4.45-5.03 6.09-4.52 3.72-9.71 3.9-15.03 1.76-3.57-1.44-4.35-1.27-6.6 1.75-1.81 2.43-4.31 3.45-7.22 3.38-5.73-.14-10.86-1.86-14.95-6.12-5.08-5.28-10.25-10.48-15.27-15.81-1.57-1.67-3.3-2.63-5.55-3.04-7.52-1.36-15.02-2.85-22.53-4.25-.8-.15-1.22-.36-1.22-1.3.03-14.33.02-28.66.03-42.99 0-.2.07-.41.14-.78 3.14.51 6.23 1 9.32 1.51 6.2 1.03 12.41 2.03 18.6 3.14 2.03.36 3.53-.29 5.03-1.59 6.4-5.55 13.43-10.08 21.52-12.84 5.77-1.97 11.7-2.77 17.78-2.44.68.04 1.36.17 1.56-.08-2.26 2.55-4.39 5.23-6.82 7.62-6.47 6.34-12.95 12.7-19.68 18.77-5.15 4.64-4.71 11.03.42 15.04 3.74 2.93 7.53 5.48 12.32 6.2 6.97 1.07 13.15-.72 18.39-5.42 4.2-3.76 8.25-7.71 12.36-11.57.42-.4.86-.78 1.43-1.29 3.24 14.6 9.23 27.39 20.88 36.93z'
                />
                <path
                    fill='currentColor'
                    d='M158.36 14.92v43.82c-2.63.33-5.26.66-7.87.99-10.07 1.25-20.14 2.48-30.2 3.8-1.29.17-2.15-.23-3.06-.99-6.66-5.59-11.06-12.73-14.21-20.72-2.22-5.63-3.66-11.45-4.25-17.48-.16-1.59-.83-2.83-2.33-3.45-1.58-.64-2.95-.19-4.17.95-5.81 5.48-11.74 10.82-17.41 16.44-5.99 5.93-15.27 7.29-23.22-.88-.93-.95-.5-1.72.31-2.49 2.99-2.84 5.97-5.7 8.95-8.55 6.98-6.68 13.98-13.35 20.95-20.03 7.9-7.57 18.32-8.41 27.34-2.17 6.78 4.69 13.58 9.36 20.3 14.14 1.65 1.17 3.24 1.47 5.18 1.09 7.82-1.52 15.65-2.96 23.68-4.47z'
                />
            </g>
        </svg>
    );
}

export default NetworkingEvening;
