import { useEffect, useState } from 'react';
import {
    InteractionManager,
    RefreshControl,
    ScrollView,
    Text,
    View,
} from 'react-native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useJTOState from '../utils/jto/useJTOState';
import { height, width } from '../utils/utils';
import InviteTiny from '../object/compressed/global/InviteTiny';
import { RankItemView } from '../component/RankItemView';
import {
    NAVIGATION_BAR_HEIGHT,
    STATUS_BAR_HEIGHT,
    TAB_HEIGHT,
} from '../utils/screen';
import { FONT_BOLD, getColor, styles } from '../utils/theme';
import { marginBottom, marginTop } from '../utils/tailwinds';
import { GeSpinner } from '../component/GeSpinner';
import { CloseAllButton } from '../component/CloseAllButton';
import { Header } from '../component/Header';
import User from '../object/User';

interface IRankPageProps {
    navigation: any;
}

export const RankPage = (props: IRankPageProps) => {
    const { t } = useTranslation();

    const [refreshing, setRefreshing] = useState(false);
    const [loading, setLoading] = useState(true);

    const event = useJTOState((props as any).route.params.event);

    const levelList = useJTOState(event.levelList);

    useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            try {
                setLoading(true);
                event.levelList.reset();
                event.levelList.refetch(
                    {
                        id: event.getJTOIdentifier(),
                    },
                    () => {
                        setLoading(false);
                        event.levelList.setComplite(true);
                    },
                    () => {
                        setLoading(false);
                        event.levelList.setComplite(true);
                    },
                );
            } catch (e) {}
        });

        return () => {
            InteractionManager.runAfterInteractions(() => {
                try {
                    event.levelList.reset();
                } catch (e) {}
            });
        };
    }, []);
    return (
        <View
            style={{
                backgroundColor: getColor('background'),
                flex: 1,
                width: '100%',
            }}
        >
            <Header
                modal={true}
                title={t('rank')}
                line={1}
                logo={User._instance.getEvent()?.logo.get() ?? ''}
                backButton={true}
                navigation={props.navigation}
                after={
                    <View
                        style={{
                            height: 10,
                        }}
                    ></View>
                }
            >
                <ScrollView
                    // @ts-ignore

                    //scrollEventThrottle={1}
                    style={{
                        flex: 1,
                    }}
                    refreshControl={
                        <RefreshControl
                            colors={[getColor('primary')]}
                            refreshing={refreshing}
                            progressViewOffset={40}
                            onRefresh={() => {
                                setRefreshing(false);
                                setLoading(true);
                                event.levelList.reset();
                                event.levelList.refetch(
                                    {
                                        id: event.getJTOIdentifier(),
                                    },
                                    () => {
                                        setLoading(false);
                                        event.levelList.setComplite(true);
                                    },
                                    () => {
                                        setLoading(false);
                                        event.levelList.setComplite(true);
                                    },
                                );
                            }}
                        />
                    }
                >
                    <View
                        style={{
                            flex: 1,
                            paddingVertical: 20,
                        }}
                    >
                        {levelList
                            .getList()
                            .map((invite: InviteTiny, index: number) => {
                                return (
                                    <View>
                                        <RankItemView
                                            index={index}
                                            key={invite.getJTOIdentifier()}
                                            invite={invite}
                                            navigation={props.navigation}
                                        />
                                    </View>
                                );
                            })}
                        {levelList.size() === 0 &&
                            !levelList.isComplite() &&
                            !loading && (
                                <View
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height:
                                            height() -
                                            STATUS_BAR_HEIGHT -
                                            NAVIGATION_BAR_HEIGHT -
                                            TAB_HEIGHT -
                                            200,
                                        flex: 1,
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: 25,
                                            fontFamily: FONT_BOLD,
                                            color: getColor('text'),
                                            textAlign: 'center',
                                        }}
                                    >
                                        {t('no_people')}
                                    </Text>
                                </View>
                            )}
                        {(loading || !levelList.isComplite()) && (
                            <View
                                style={[
                                    styles.boxCenter,
                                    marginBottom(50),
                                    levelList.size() === 0
                                        ? marginTop(200)
                                        : marginTop(50),
                                ]}
                            >
                                <GeSpinner color={getColor('primary')} />
                            </View>
                        )}
                    </View>
                </ScrollView>
            </Header>
            <CloseAllButton
                onPress={() => {
                    props.navigation.popToTop();
                }}
            />
        </View>
    );
};
